import React, { useState, useMemo, useCallback } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    TextCell,
    DateTimeCell,
    ChipCell,
    AvatarCell,
    DeleteActionButton,
} from "../../../components/tables/MaterialTableCells";
import { Link } from "react-router-dom";
import { COURSE_ATTENDEE_STATUS_CONFIRMED, COURSE_ATTENDEE_STATUS_DELETED } from "../utils";
import CourseLeaderAddModal from "./CourseLeaderAddModal";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import CourseResourceListToolbar from "../../../components/tables/toolbar/CourseResourceListToolbar";

function CoursePageResources({ getCourseResources, addCourseResource, deleteCourseResource }) {
    const { course, courseResourcesPagination, isLoading, isUpdating } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const serviceId = course?.id;

    const [status, setStatus] = useState("attending");

    const [showModalAddLeader, setShowModalAddLeader] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getCourseResources(serviceId, pageIndex + 1, pageSize, status);
            }
        },
        [serviceId, getCourseResources, status]
    );

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.RESOURCES.TITLE" />
                        </h3>
                    </div>
                    {course && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddLeader(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceId}
                        data={useMemo(() => courseResourcesPagination.data, [courseResourcesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={courseResourcesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        renderToolbar={() => {
                            return <CourseResourceListToolbar onStatusChanged={onStatusChanged} />;
                        }}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.RESOURCES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.RESOURCES.LIST.HEADER.RESOURCE" />,
                                    accessor: "resource",
                                    Cell: (props) => {
                                        const resource = props.value;
                                        return (
                                            <Link to={`/resources/${resource.id}/${resource.type}`}>
                                                {AvatarCell(resource.avatarUrl, resource.name)}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.RESOURCES.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.RESOURCES.LIST.HEADER.OCCASIONS" />,
                                    accessor: "occasionCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === COURSE_ATTENDEE_STATUS_CONFIRMED) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.CONFIRMED" />
                                            );
                                        } else if (props.value === COURSE_ATTENDEE_STATUS_DELETED) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.DELETED" />
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "delete",
                                    Cell: ({ row: { original: courseAttendee } }) => {
                                        const intl = useIntl();
                                        if (courseAttendee.deletedAt) return <></>;
                                        const title = intl.formatMessage(
                                            {
                                                id: "SERVICE.COURSE.RESOURCES.DELETE.TITLE",
                                            },
                                            { firstName: courseAttendee.resource.firstName }
                                        );

                                        const content = <FormattedMessage id="SERVICE.COURSE.RESOURCES.DELETE.TEXT" />;
                                        return DeleteActionButton(title, content, () =>
                                            deleteCourseResource(serviceId, courseAttendee.id)
                                        );
                                    },
                                },
                            ],
                            [deleteCourseResource, serviceId]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {showModalAddLeader && (
                <CourseLeaderAddModal
                    show={showModalAddLeader}
                    serviceId={serviceId}
                    onCloseClicked={() => setShowModalAddLeader(false)}
                    onLeaderAdded={(data) => {
                        addCourseResource(serviceId, data, () => {
                            fetchData({ pageIndex: 0, pageSize: 10 });
                        });
                        setShowModalAddLeader(false);
                    }}
                />
            )}
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(CoursePageResources);
