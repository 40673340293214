import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import DefaultAsyncTypeaheadMultiple from "../../../components/DefaultAsyncTypeaheadMultiple";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    descriptionTextContainer: {
        marginBottom: theme.spacing(4),
    },
    formContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({});

function SelectBankIDServicesModal({
    getServicesAndCategories,
    onSaveClicked,
    onCloseClicked,
    show,
    titleText,
    descriptionText,
    saveButtonText,
    isLoading,
    serviceStatusFilter = null,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { servicesAndCategoriesPagination } = useSelector((state) => state.services);
    const classes = useStyles();
    const intl = useIntl();

    useEffect(() => {
        getServicesAndCategories(profile.id, 1, 500, null, serviceStatusFilter);
    }, [getServicesAndCategories, profile.id, serviceStatusFilter]);

    const { register, setValue, watch, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        let addedItems = [];

        for (let i = 0; i < values.selectedItems.length; i++) {
            const item = values.selectedItems[i];
            if (item.type === "service") {
                addedItems.push({ serviceId: item.id });
            } else if (item.type === "category") {
                addedItems.push({ serviceCategoryId: item.id });
            }
        }

        if (addedItems.length === 0) return;

        onSaveClicked(addedItems, values.userVisibleData);
    };

    const selectedItems = watch("selectedItems");
    if (!selectedItems) {
        register("selectedItems");
    }

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {descriptionText && <div className={classes.descriptionTextContainer}>{descriptionText}</div>}
                    <div className={classes.formContent}>
                        <Form.Group>
                            <DefaultAsyncTypeaheadMultiple
                                id="typeahead-services-and-categories"
                                options={getServiceAndCategoriesOptions(servicesAndCategoriesPagination)}
                                selected={selectedItems}
                                clearButton={false}
                                onChange={(items) => {
                                    if (selectedItems && items.length > selectedItems.length) {
                                        // Don't add duplicates
                                        const addedItem = items[items.length - 1];
                                        if (addedItem) {
                                            const existingIndex = selectedItems.findIndex((x) => x.id === addedItem.id);
                                            if (existingIndex >= 0) return;
                                        }
                                    }
                                    setValue("selectedItems", items);
                                }}
                                onSearch={(search) =>
                                    getServicesAndCategories(profile.id, 1, 500, search, serviceStatusFilter)
                                }
                                placeholder={intl.formatMessage({
                                    id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                })}
                            />
                            <Form.Control.Feedback type="invalid">{errors.serviceId?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="INTEGRATION.BANKID.SERVICES.ADD.USER_VISIBLE_DATA" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className="form-control form-control-lg"
                                name="userVisibleData"
                                ref={register}
                            />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="INTEGRATION.BANKID.SERVICES.ADD.USER_VISIBLE_DATA.DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isLoading}
                        onClick={handleSubmit(onFormSubmit)}
                        label={saveButtonText}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, serviceActions)(SelectBankIDServicesModal);

function getServiceAndCategoriesOptions(listPagination) {
    let options = [];
    if (!listPagination?.data?.length) return options;
    listPagination.data.forEach((item) => {
        options.push(getServiceOrCategoryOption(item));
    });

    return options;
}

function getServiceOrCategoryOption(item) {
    return {
        id: item.service ? item.service.id : item.category.id,
        name: item.service ? item.service.name : item.category.name,
        type: item.service ? "service" : "category",
    };
}
