import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as fortnoxActions } from "../../../../redux/integrations/fortnox/fortnoxRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    formContent: {
        padding: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    service: yup
        .object()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .nullable(),
    article: yup.object().nullable(),
    account: yup.object().nullable(),
    costCenter: yup.object().nullable(),
});

function getServiceOptions(servicePagination) {
    let options = [];
    if (!servicePagination?.data?.length) return options;
    servicePagination.data.forEach((service) => {
        options.push(getServiceOption(service));
    });
    return options;
}

function getServiceOption(service) {
    return {
        id: service.id,
        name: service.name,
        type: service.type,
    };
}

function getArticleOptions(articlePagination) {
    let options = [];
    if (!articlePagination?.data?.length) return options;
    articlePagination.data.forEach((article) => {
        options.push(getArticleOption(article));
    });
    return options;
}

function getArticleOption(article) {
    return {
        id: article.articleNumber,
        name: article.description ? `${article.articleNumber} (${article.description})` : `${article.articleNumber}`,
    };
}

function getAccountOptions(accountPagination) {
    let options = [];
    if (!accountPagination?.data?.length) return options;
    accountPagination.data.forEach((account) => {
        options.push(getAccountOption(account));
    });
    return options;
}

function getAccountOption(account) {
    return {
        id: account.number,
        name:
            account.sRU && account.description
                ? `${account.number} (SRU ${account.sRU}, ${account.description})`
                : `${account.number}`,
    };
}

function getCostCenterOptions(costCentersPagination) {
    let options = [];
    if (!costCentersPagination?.data?.length) return options;
    costCentersPagination.data.forEach((costCenter) => {
        options.push(getCostCenterOption(costCenter));
    });
    return options;
}

function getCostCenterOption(costCenter) {
    return {
        id: costCenter.code,
        name: costCenter.description ? `${costCenter.code} (${costCenter.description})` : `${costCenter.code}`,
    };
}

const SERVICES_PER_PAGE = 100;
const ARTICLES_PER_PAGE = 100;
const ACCOUNTS_PER_PAGE = 100;
const COST_CENTERS_PER_PAGE = 100;

function FortnoxServiceModal({
    show,
    fortnoxServiceToEdit,
    onSaveClicked,
    onCloseClicked,
    getServices,
    getFortnoxArticles,
    getFortnoxAccounts,
    getFortnoxCostCenters,
}) {
    const { profile } = useSelector((state) => state.auth);
    const {
        integration,
        isUpdating,
        isFortnoxArticlesLoading,
        fortnoxArticlesPagination,
        isFortnoxAccountsLoading,
        fortnoxAccountsPagination,
        isFortnoxCostCentersLoading,
        fortnoxCostCentersPagination,
    } = useSelector((state) => state.fortnox);
    const { isLoading: isServicesLoading, listPagination: serviceListPagination } = useSelector(
        (state) => state.services
    );
    const classes = useStyles();
    const intl = useIntl();
    const integrationId = integration?.id;

    const { handleSubmit, watch, errors, control, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        getServices(profile.id, 1, SERVICES_PER_PAGE);
    }, [profile.id, getServices]);

    useEffect(() => {
        getFortnoxArticles(integrationId, 1, ARTICLES_PER_PAGE);
    }, [integrationId, getFortnoxArticles]);

    useEffect(() => {
        getFortnoxAccounts(integrationId, 1, ACCOUNTS_PER_PAGE);
    }, [integrationId, getFortnoxAccounts]);

    useEffect(() => {
        getFortnoxCostCenters(integrationId, 1, COST_CENTERS_PER_PAGE);
    }, [integrationId, getFortnoxCostCenters]);

    useEffect(() => {
        if (fortnoxServiceToEdit) {
            if (fortnoxServiceToEdit.service) {
                setValue("service", getServiceOption(fortnoxServiceToEdit.service));
            }

            if (fortnoxServiceToEdit.fortnoxArticleNumber) {
                setValue("article", getArticleOption({ articleNumber: fortnoxServiceToEdit.fortnoxArticleNumber }));
            }

            if (fortnoxServiceToEdit.fortnoxAccountNumber) {
                setValue("account", getAccountOption({ number: fortnoxServiceToEdit.fortnoxAccountNumber }));
            }

            if (fortnoxServiceToEdit.fortnoxCostCenter) {
                setValue("costCenter", getCostCenterOption({ code: fortnoxServiceToEdit.fortnoxCostCenter }));
            }
        }
    }, [fortnoxServiceToEdit, setValue]);

    const onServiceSearch = (search) => getServices(profile.id, 1, SERVICES_PER_PAGE, search);

    const onServiceChanged = (services) => {
        if (services && services.length > 0) {
            setValue("service", services[0]);
        } else {
            setValue("service", null);
        }
    };

    const onArticleSearch = (search) => getFortnoxArticles(integrationId, 1, ARTICLES_PER_PAGE, search);

    const onArticleChanged = (articles) => {
        if (articles && articles.length > 0) {
            setValue("article", articles[0]);
        } else {
            setValue("article", null);
        }
    };

    const onAccountSearch = (search) => getFortnoxAccounts(integrationId, 1, ACCOUNTS_PER_PAGE, search);

    const onAccountChanged = (accounts) => {
        if (accounts && accounts.length > 0) {
            setValue("account", accounts[0]);
        } else {
            setValue("account", null);
        }
    };

    const onCostCenterChanged = (costCenters) => {
        if (costCenters && costCenters.length > 0) {
            setValue("costCenter", costCenters[0]);
        } else {
            setValue("costCenter", null);
        }
    };

    const onFormSubmit = (values) => {
        let data = {
            serviceId: values.service.id,
        };

        if (values.article) {
            data.fortnoxArticleNumber = values.article.id;
        }

        if (values.account) {
            data.fortnoxAccountNumber = values.account.id;
        }

        if (values.costCenter) {
            data.fortnoxCostCenter = values.costCenter.id;
        }

        onSaveClicked(data);
    };

    const service = watch("service");
    const article = watch("article");
    const account = watch("account");
    const costCenter = watch("costCenter");

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id={"INTEGRATION.FORTNOX.SERVICES.DETAILS.MODAL_TITLE"} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_SERVICE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="service"
                                defaultValue={null}
                                render={() => (
                                    <div>
                                        <AsyncTypeahead
                                            id="typeahead-services"
                                            labelKey={"name"}
                                            minLength={0}
                                            clearButton={true}
                                            onChange={onServiceChanged}
                                            onSearch={onServiceSearch}
                                            onInputChange={(input) => {
                                                if (!input) {
                                                    onServiceSearch(input);
                                                }
                                            }}
                                            useCache={false}
                                            isLoading={isServicesLoading}
                                            options={getServiceOptions(serviceListPagination)}
                                            placeholder={intl.formatMessage({
                                                id: "INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_SERVICE_PLACEHOLDER",
                                            })}
                                            selected={service ? [service] : []}
                                            filterBy={() => true}
                                            isInvalid={!!errors.service}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                        {errors.service?.message && (
                                            <div
                                                className="text-danger"
                                                style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}
                                            >
                                                {errors.service.message}
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_ARTICLE_NUMBER" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="article"
                                defaultValue={null}
                                render={() => (
                                    <div>
                                        <AsyncTypeahead
                                            id="typeahead-articles"
                                            labelKey={"name"}
                                            minLength={0}
                                            clearButton={true}
                                            onChange={onArticleChanged}
                                            onSearch={onArticleSearch}
                                            onInputChange={(input) => {
                                                if (!input) {
                                                    onArticleSearch(input);
                                                }
                                            }}
                                            useCache={false}
                                            isLoading={isFortnoxArticlesLoading}
                                            options={getArticleOptions(fortnoxArticlesPagination)}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    "INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_ARTICLE_NUMBER_PLACEHOLDER",
                                            })}
                                            selected={article ? [article] : []}
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                        <Form.Text className="text-muted">
                                            <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_ARTICLE_NUMBER_DESCRIPTION" />
                                        </Form.Text>
                                    </div>
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_ACCOUNT_NUMBER" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="account"
                                defaultValue={null}
                                render={() => (
                                    <div>
                                        <AsyncTypeahead
                                            id="typeahead-accounts"
                                            labelKey={"name"}
                                            minLength={0}
                                            clearButton={true}
                                            onChange={onAccountChanged}
                                            onSearch={onAccountSearch}
                                            onInputChange={(input) => {
                                                if (!input) {
                                                    onAccountSearch(input);
                                                }
                                            }}
                                            useCache={false}
                                            isLoading={isFortnoxAccountsLoading}
                                            options={getAccountOptions(fortnoxAccountsPagination)}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    "INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_ACCOUNT_NUMBER_PLACEHOLDER",
                                            })}
                                            selected={account ? [account] : []}
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                        <Form.Text className="text-muted">
                                            <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_ACCOUNT_NUMBER_DESCRIPTION" />
                                        </Form.Text>
                                    </div>
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_COST_CENTER" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="costCenter"
                                defaultValue={null}
                                render={() => (
                                    <div>
                                        <AsyncTypeahead
                                            id="typeahead-accounts"
                                            labelKey={"name"}
                                            minLength={0}
                                            clearButton={true}
                                            onChange={onCostCenterChanged}
                                            onSearch={() => {}}
                                            onInputChange={(input) => {}}
                                            useCache={false}
                                            isLoading={isFortnoxCostCentersLoading}
                                            options={getCostCenterOptions(fortnoxCostCentersPagination)}
                                            placeholder={intl.formatMessage({
                                                id:
                                                    "INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_COST_CENTER_PLACEHOLDER",
                                            })}
                                            selected={costCenter ? [costCenter] : []}
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                        <Form.Text className="text-muted">
                                            <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DETAILS.CHOOSE_COST_CENTER_DESCRIPTION" />
                                        </Form.Text>
                                    </div>
                                )}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isUpdating}
                        onClick={handleSubmit(onFormSubmit)}
                        label={<FormattedMessage id="COMMON.ADD" />}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, { ...fortnoxActions, ...serviceActions })(FortnoxServiceModal);
