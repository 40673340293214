import React, { useMemo, useCallback } from "react";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { LinkCell, AvatarCell, TextCell } from "../../components/tables/MaterialTableCells";
import { Link } from "react-router-dom";

function PromotionCodePageCustomers({ getPromotionCodeCustomers }) {
    const { promotionCode, promotionCodeCustomersPagination, isLoading } = useSelector((state) => state.promotionCodes);
    const { displayError } = useSelector((state) => state.errors);
    const promotionCodeId = promotionCode?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (promotionCodeId) {
                getPromotionCodeCustomers(promotionCodeId, pageIndex + 1, pageSize);
            }
        },
        [promotionCodeId, getPromotionCodeCustomers]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROMOTIONCODE.CUSTOMERS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROMOTIONCODE.CUSTOMERS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={promotionCodeId}
                        data={useMemo(() => promotionCodeCustomersPagination.data, [
                            promotionCodeCustomersPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={promotionCodeCustomersPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PROMOTIONCODE.CUSTOMERS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        return LinkCell(
                                            "/customers/" + row.original.customer.id,
                                            row.original.customer.id
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PROMOTIONCODE.CUSTOMERS.LIST.HEADER.CUSTOMER" />,
                                    accessor: "customer",
                                    Cell: (props) => {
                                        return (
                                            <Link to={`/customers/${props.value.id}`}>
                                                {AvatarCell(
                                                    props.value.avatarUrl,
                                                    `${props.value.firstName} ${props.value.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PROMOTIONCODE.CUSTOMERS.LIST.HEADER.USAGE_COUNT" />,
                                    accessor: "usageCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...promotionCodesAction, ...errorActions })(PromotionCodePageCustomers);
