import { put, takeLatest } from "redux-saga/effects";
import {
    getProfilePricingTemplates,
    getPricingTemplate,
    addProfilePricingTemplate,
    updatePricingTemplate,
    deletePricingTemplate,
    getPricingTemplateRefundRules,
    updatePricingTemplateTimeRules,
} from "./pricingTemplateCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PROFILE_PRICING_TEMPLATES_REQUEST: "GET_PROFILE_PRICING_TEMPLATES_REQUEST",
    GET_PROFILE_PRICING_TEMPLATES_SUCCESS: "GET_PROFILE_PRICING_TEMPLATES_SUCCESS",
    GET_PRICING_TEMPLATE_REQUEST: "GET_PRICING_TEMPLATE_REQUEST",
    GET_PRICING_TEMPLATE_SUCCESS: "GET_PRICING_TEMPLATE_SUCCESS",
    ADD_PROFILE_PRICING_TEMPLATE_REQUEST: "ADD_PROFILE_PRICING_TEMPLATE_REQUEST",
    ADD_PROFILE_PRICING_TEMPLATE_SUCCESS: "ADD_PROFILE_PRICING_TEMPLATE_SUCCESS",
    UPDATE_PRICING_TEMPLATE_REQUEST: "UPDATE_PRICING_TEMPLATE_REQUEST",
    UPDATE_PRICING_TEMPLATE_SUCCESS: "UPDATE_PRICING_TEMPLATE_SUCCESS",
    DELETE_PRICING_TEMPLATE_REQUEST: "DELETE_PRICING_TEMPLATE_REQUEST",
    DELETE_PRICING_TEMPLATE_SUCCESS: "DELETE_PRICING_TEMPLATE_SUCCESS",
    GET_PRICING_TEMPLATE_TIME_RULES_REQUEST: "GET_PRICING_TEMPLATE_TIME_RULES_REQUEST",
    GET_PRICING_TEMPLATE_TIME_RULES_SUCCESS: "GET_PRICING_TEMPLATE_TIME_RULES_SUCCESS",
    UPDATE_PRICING_TEMPLATE_TIME_RULES_REQUEST: "UPDATE_PRICING_TEMPLATE_TIME_RULES_REQUEST",
    UPDATE_PRICING_TEMPLATE_TIME_RULES_SUCCESS: "UPDATE_PRICING_TEMPLATE_TIME_RULES_SUCCESS",
    CLEAR_PRICING_TEMPLATES_STATE: "CLEAR_PRICING_TEMPLATES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    timeRulesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_PRICING_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_PRICING_TEMPLATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PRICING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PRICING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                pricingTemplate: action.payload.response,
            };
        }

        case actionTypes.ADD_PROFILE_PRICING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PROFILE_PRICING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                pricingTemplate: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PRICING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PRICING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                pricingTemplate: action.payload.response,
            };
        }

        case actionTypes.DELETE_PRICING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PRICING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                pricingTemplate: action.payload.response,
            };
        }

        case actionTypes.GET_PRICING_TEMPLATE_TIME_RULES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PRICING_TEMPLATE_TIME_RULES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                timeRulesPagination: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PRICING_TEMPLATE_TIME_RULES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PRICING_TEMPLATE_TIME_RULES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                timeRulesPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_PRICING_TEMPLATES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getProfilePricingTemplates: (profileId, page, perPage) => ({
        type: actionTypes.GET_PROFILE_PRICING_TEMPLATES_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getPricingTemplate: (id) => ({
        type: actionTypes.GET_PRICING_TEMPLATE_REQUEST,
        payload: { id },
    }),

    addProfilePricingTemplate: (profileId, data, callback) => ({
        type: actionTypes.ADD_PROFILE_PRICING_TEMPLATE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updatePricingTemplate: (id, originalPricingTemplate, updatedPricingTemplate) => ({
        type: actionTypes.UPDATE_PRICING_TEMPLATE_REQUEST,
        payload: { id, originalPricingTemplate, updatedPricingTemplate },
    }),

    deletePricingTemplate: (id) => ({
        type: actionTypes.DELETE_PRICING_TEMPLATE_REQUEST,
        payload: { id },
    }),

    getPricingTemplateTimeRules: (pricingTemplateId, page, perPage) => ({
        type: actionTypes.GET_PRICING_TEMPLATE_TIME_RULES_REQUEST,
        payload: { pricingTemplateId, page, perPage },
    }),

    updatePricingTemplateTimeRules: (pricingTemplateId, data, callback) => ({
        type: actionTypes.UPDATE_PRICING_TEMPLATE_TIME_RULES_REQUEST,
        payload: { pricingTemplateId, data, callback },
    }),

    clearPricingTemplateState: () => ({
        type: actionTypes.CLEAR_PRICING_TEMPLATES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROFILE_PRICING_TEMPLATES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfilePricingTemplates(
                payload.profileId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_PROFILE_PRICING_TEMPLATES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PRICING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPricingTemplate(payload.id);
            yield put({
                type: actionTypes.GET_PRICING_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROFILE_PRICING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addProfilePricingTemplate(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PROFILE_PRICING_TEMPLATE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PRICING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updatePricingTemplate(payload.id, payload.originalPricingTemplate, {
                ...payload.originalPricingTemplate,
                ...payload.updatedPricingTemplate,
            });
            yield put({
                type: actionTypes.UPDATE_PRICING_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PRICING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deletePricingTemplate(payload.id);
            yield put({
                type: actionTypes.DELETE_PRICING_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PRICING_TEMPLATE_TIME_RULES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPricingTemplateRefundRules(
                payload.pricingTemplateId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_PRICING_TEMPLATE_TIME_RULES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PRICING_TEMPLATE_TIME_RULES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updatePricingTemplateTimeRules(payload.pricingTemplateId, payload.data);
            yield put({
                type: actionTypes.UPDATE_PRICING_TEMPLATE_TIME_RULES_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
