import React, { useState, useEffect, useMemo, useCallback } from "react";
import { actions as reminderTemplateActions } from "../../../redux/remindertemplates/reminderTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell, TranslationCell } from "../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import ReminderTemplateRuleModal from "./ReminderTemplateRuleModal";
import {
    RULE_TYPE_EMAIL,
    RULE_TYPE_SMS,
    RULE_CONDITION_BEFORE_BOOKING_START_TIME,
    RULE_CONDITION_BEFORE_BOOKING_END_TIME,
    RULE_CONDITION_BEFORE_BOOKING_END_TIME_NO_UPCOMING,
} from "./utils";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        margin: theme.spacing(2),
    },
}));

function ReminderTemplateRules({
    getReminderTemplatePreview,
    getReminderTemplateRules,
    addReminderTemplateRule,
    updateReminderTemplateRule,
    deleteReminderTemplateRule,
}) {
    const { reminderTemplate, rulesPagination, reminderTemplatePreview, isLoading } = useSelector(
        (state) => state.reminderTemplates
    );
    const { displayError } = useSelector((state) => state.errors);
    const reminderTemplateId = reminderTemplate?.id;

    const classes = useStyles();

    const [showAddModal, setShowAddModal] = useState(false);
    const [ruleToEdit, setRuleToEdit] = useState(null);
    const [ruleToDelete, setRuleToDelete] = useState(null);

    useEffect(() => {
        if (reminderTemplateId) {
            getReminderTemplatePreview(reminderTemplateId);
        }
    }, [getReminderTemplatePreview, reminderTemplateId]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (reminderTemplateId) {
                getReminderTemplateRules(reminderTemplateId, pageIndex + 1, pageSize);
            }
        },
        [reminderTemplateId, getReminderTemplateRules]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="REMINDER_TEMPLATE.RULES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="REMINDER_TEMPLATE.RULES.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            label={<FormattedMessage id="COMMON.ADD" />}
                            onClick={() => setShowAddModal(true)}
                        ></BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <>
                        {reminderTemplatePreview && reminderTemplate && rulesPagination.data.length === 0 && (
                            <Alert severity="info" className={classes.alertContainer}>
                                <FormattedMessage id="REMINDER_TEMPLATE.RULES.PREVIEW_NO_RULES" />
                            </Alert>
                        )}
                        {reminderTemplatePreview && reminderTemplate && rulesPagination.data.length > 0 && (
                            <Alert severity="info" className={classes.alertContainer}>
                                <FormattedMessage
                                    id="REMINDER_TEMPLATE.RULES.PREVIEW"
                                    values={{
                                        price: reminderTemplatePreview.totalPrice,
                                        currency: reminderTemplatePreview.currency,
                                    }}
                                />
                            </Alert>
                        )}
                        <MaterialReactTable
                            key={reminderTemplateId}
                            data={useMemo(() => rulesPagination.data, [rulesPagination.data])}
                            fetchData={fetchData}
                            pageSize={10}
                            rowCount={rulesPagination.totalRows}
                            isLoading={isLoading}
                            error={displayError}
                            columns={useMemo(
                                () => [
                                    {
                                        Header: <FormattedMessage id="REMINDER_TEMPLATE.RULES.LIST.HEADER.ID" />,
                                        accessor: "id",
                                        width: "7%",
                                        Cell: (props) => {
                                            return TextCell(props.value);
                                        },
                                    },
                                    {
                                        Header: <FormattedMessage id="REMINDER_TEMPLATE.RULES.LIST.HEADER.TYPE" />,
                                        accessor: "type",
                                        Cell: (props) => {
                                            if (props.value === RULE_TYPE_EMAIL) {
                                                return TranslationCell("REMINDER_TEMPLATE.RULES.TYPE.EMAIL");
                                            } else if (props.value === RULE_TYPE_SMS) {
                                                return TranslationCell("REMINDER_TEMPLATE.RULES.TYPE.SMS");
                                            }
                                            return TextCell();
                                        },
                                    },
                                    {
                                        Header: <FormattedMessage id="REMINDER_TEMPLATE.RULES.LIST.HEADER.MINUTES" />,
                                        accessor: "minutes",
                                        Cell: (props) => {
                                            return TextCell(props.value);
                                        },
                                    },
                                    {
                                        Header: <FormattedMessage id="REMINDER_TEMPLATE.RULES.LIST.HEADER.CONDITION" />,
                                        accessor: "condition",
                                        Cell: (props) => {
                                            if (props.value === RULE_CONDITION_BEFORE_BOOKING_START_TIME) {
                                                return TranslationCell(
                                                    "REMINDER_TEMPLATE.RULES.CONDITION.BEFORE_START_TIME"
                                                );
                                            } else if (props.value === RULE_CONDITION_BEFORE_BOOKING_END_TIME) {
                                                return TranslationCell(
                                                    "REMINDER_TEMPLATE.RULES.CONDITION.BEFORE_END_TIME"
                                                );
                                            } else if (
                                                props.value === RULE_CONDITION_BEFORE_BOOKING_END_TIME_NO_UPCOMING
                                            ) {
                                                return TranslationCell(
                                                    "REMINDER_TEMPLATE.RULES.CONDITION.BEFORE_END_TIME_NO_UPCOMING"
                                                );
                                            }
                                            return TextCell();
                                        },
                                    },
                                    {
                                        Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                        id: "actions",
                                        textAlign: "center",
                                        Cell: ({ row }) => {
                                            return (
                                                <ListActionsButton
                                                    menuItems={[
                                                        {
                                                            icon: <LinkIcon fontSize="small" />,
                                                            label: <FormattedMessage id="COMMON.OPEN" />,
                                                            onClick: () => {
                                                                setRuleToEdit(row.original);
                                                                setShowAddModal(true);
                                                            },
                                                        },
                                                        {
                                                            icon: <LinkIcon fontSize="small" />,
                                                            label: <FormattedMessage id="COMMON.DELETE" />,
                                                            onClick: () => setRuleToDelete(row.original),
                                                        },
                                                    ]}
                                                />
                                            );
                                        },
                                    },
                                ],
                                []
                            )}
                            getCustomCellProps={() => ({
                                style: { height: "64px" },
                            })}
                        />
                    </>
                </Card.Body>
            </div>
            {showAddModal && (
                <ReminderTemplateRuleModal
                    show={showAddModal ? true : false}
                    ruleToEdit={ruleToEdit}
                    onCloseClicked={() => {
                        setRuleToEdit(null);
                        setShowAddModal(false);
                    }}
                    onSaveClicked={(data) => {
                        if (!ruleToEdit) {
                            addReminderTemplateRule(reminderTemplateId, data, () => {
                                fetchData({ pageIndex: 0, pageSize: 10 });
                                getReminderTemplatePreview(reminderTemplateId);
                                setShowAddModal(false);
                            });
                        } else {
                            const originalData = {
                                reminderTitle: ruleToEdit.reminderTitle,
                                reminderText: ruleToEdit.reminderText,
                            };

                            const updatedData = {
                                reminderTitle: data.reminderTitle,
                                reminderText: data.reminderText,
                            };

                            updateReminderTemplateRule(ruleToEdit.id, originalData, updatedData, () => {
                                fetchData({ pageIndex: 0, pageSize: 10 });
                                getReminderTemplatePreview(reminderTemplateId);
                                setShowAddModal(false);
                                setRuleToEdit(null);
                            });
                        }
                    }}
                />
            )}
            <YesNoDialog
                title={<FormattedMessage id="REMINDER_TEMPLATE.RULES.DELETE.TEXT" />}
                open={ruleToDelete ? true : false}
                onOpenChanged={() => setRuleToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteReminderTemplateRule(ruleToDelete.id, () => {
                            getReminderTemplatePreview(reminderTemplateId);
                        });
                    }
                    setRuleToDelete(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...reminderTemplateActions, ...errorActions })(ReminderTemplateRules);
