import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as paymentMethodActions } from "../../../redux/paymentmethods/paymentMethodRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import { getPaymentMethodStatusCell } from "./utils";
import NavigatorOptionsMenu from "../../components/buttons/NavigatorOptionsMenu";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "325px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(1),
        alignItems: "center",
    },
}));

function PaymentMethodPageNavigator({ paymentMethodId, deletePaymentMethod }) {
    const { paymentMethod } = useSelector((state) => state.paymentMethods);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        <NavigatorOptionsMenu
                            menuItems={[
                                {
                                    icon: <DeleteOutlinedIcon fontSize="small" />,
                                    label: <FormattedMessage id="COMMON.DELETE" />,
                                    onClick: () => setShowDeleteConfirmation(true),
                                },
                            ]}
                        />
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        {paymentMethod && (
                            <>
                                <span
                                    className="font-weight-bolder font-size-h6 text-dark-75 flex-grow-1"
                                    style={{ marginRight: "24px" }}
                                >
                                    <FormattedMessage
                                        id="PAYMENT_METHOD.DETAILS.TITLE_NAVIGATOR"
                                        values={{ name: paymentMethod.name }}
                                    />
                                </span>
                                {getPaymentMethodStatusCell(paymentMethod.status)}
                            </>
                        )}
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        {paymentMethod?.createdAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT_METHOD.DETAILS.CREATED_AT" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {moment(paymentMethod.createdAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {paymentMethod?.createdBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT_METHOD.DETAILS.CREATED_BY" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${paymentMethod.createdBy.firstName} ${paymentMethod.createdBy.surname}`}
                                </span>
                            </div>
                        )}
                        {paymentMethod?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT_METHOD.DETAILS.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(paymentMethod.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {paymentMethod?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="PAYMENT_METHOD.DETAILS.DELETED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${paymentMethod.deletedBy.firstName} ${paymentMethod.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/payment-methods/${paymentMethodId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYMENT_METHOD.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="PAYMENT_METHOD.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deletePaymentMethod(paymentMethodId);
                    }
                    setShowDeleteConfirmation(false);
                }}
            />
        </div>
    );
}

export default connect(null, paymentMethodActions)(PaymentMethodPageNavigator);
