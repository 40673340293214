import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as payoutActions } from "../../../redux/payouts/payoutsRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import PayoutPageNavigator from "./PayoutPageNavigator";
import PayoutPageDetails from "./PayoutPageDetails";
import PayoutPageTransactions from "./PayoutPageTransactions";
import PayoutPageRefunds from "./PayoutPageRefunds";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function PayoutPage({ match, getPayout, clearPayoutState, clearDisplayError, clearErrorState }) {
    const payoutId = parseInt(match.params.id, 10);
    const { payout, isLoading, isUpdating } = useSelector((state) => state.payouts);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!payout || payout.id !== payoutId)) {
            getPayout(payoutId);
        }
    }, [getPayout, payout, payoutId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearPayoutState();
            clearErrorState();
        };
    }, [clearPayoutState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <PayoutPageNavigator payoutId={payoutId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect from={`/payouts/${payoutId}`} exact={true} to={`/payouts/${payoutId}/details`} />
                        <Route path={`/payouts/${payoutId}/details`} component={PayoutPageDetails} />
                        <Route path={`/payouts/${payoutId}/transactions`} component={PayoutPageTransactions} />
                        <Route path={`/payouts/${payoutId}/refunds`} component={PayoutPageRefunds} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...payoutActions, ...errorActions })(PayoutPage);
