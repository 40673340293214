import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Switch } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import DefaultAsyncTypeahead from "../../../components/DefaultAsyncTypeahead";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    descriptionTextContainer: {
        marginBottom: theme.spacing(4),
    },
    formContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    service: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    discountPercentage: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
        .max(100, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 100 }} />),
});

function SelectMembershipServiceModal({
    getServices,
    onSaveClicked,
    onCloseClicked,
    show,
    titleText,
    descriptionText,
    saveButtonText,
    isLoading,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { listPagination } = useSelector((state) => state.services);
    const classes = useStyles();
    const intl = useIntl();

    useEffect(() => {
        getServices(profile.id, 1, 500);
    }, [getServices, profile.id]);

    const { register, control, setValue, watch, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        let data = {
            serviceId: values.service.id,
            discountPercentage: values.discountPercentage,
            offerMandatory: values.offerMandatory,
        };

        onSaveClicked(data);
    };

    let service = watch("service");
    if (!service) {
        register("service");
    }

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {descriptionText && <div className={classes.descriptionTextContainer}>{descriptionText}</div>}
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.ADD.SERVICE" />
                            </Form.Label>
                            <DefaultAsyncTypeahead
                                id="typeahead-max-booking-minutes-units"
                                options={getServiceOptions(listPagination)}
                                selected={service}
                                clearButton={false}
                                onChange={(services) => {
                                    setValue("service", getServiceOption(services[0], intl), {
                                        shouldValidate: true,
                                    });
                                }}
                                onSearch={() => {}}
                                isInvalid={errors.service ? true : false}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.ADD.DISCOUNT_PERCENTAGE" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg col-xl-2"
                                name="discountPercentage"
                                ref={register}
                                isInvalid={errors.discountPercentage}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.discountPercentage?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.ADD.OFFER_MANDATORY" />
                            </Form.Label>
                            <Controller
                                name="offerMandatory"
                                control={control}
                                defaultValue={false}
                                render={({ value, onChange }) => {
                                    return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                                }}
                            />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.ADD.OFFER_MANDATORY_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isLoading}
                        onClick={handleSubmit(onFormSubmit)}
                        label={saveButtonText}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, serviceActions)(SelectMembershipServiceModal);

function getServiceOptions(servicePagination) {
    let options = [];
    if (!servicePagination?.data?.length) return options;
    servicePagination.data.forEach((item) => {
        options.push(getServiceOption(item));
    });

    return options;
}

function getServiceOption(item) {
    return {
        id: item.id,
        name: item.name,
    };
}
