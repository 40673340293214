import React from "react";
import { PERSPECTIVE_TODAY, PERSPECTIVE_WEEKLY, PERSPECTIVE_MONTHLY } from "./utils";
import { FormattedMessage } from "react-intl";

export default function OverviewCardStatsDropdown({ onItemClicked }) {
    return (
        <>
            {/*begin::Navigation*/}
            <ul className="navi navi-hover">
                <li className="navi-item" onClick={() => onItemClicked(PERSPECTIVE_TODAY)}>
                    <span className="navi-link" style={{ cursor: "pointer" }}>
                        <span className="navi-icon">
                            <i className="flaticon2-calendar-8"></i>
                        </span>
                        <span className="navi-text">
                            <FormattedMessage id="OVERVIEW.PERSPECTIVE.TODAY" />
                        </span>
                    </span>
                </li>
                <li className="navi-item" onClick={() => onItemClicked(PERSPECTIVE_WEEKLY)}>
                    <span href="#" className="navi-link" style={{ cursor: "pointer" }}>
                        <span className="navi-icon">
                            <i className="flaticon2-calendar-8"></i>
                        </span>
                        <span className="navi-text">
                            <FormattedMessage id="OVERVIEW.PERSPECTIVE.WEEKLY" />
                        </span>
                    </span>
                </li>
                <li className="navi-item" onClick={() => onItemClicked(PERSPECTIVE_MONTHLY)}>
                    <span href="#" className="navi-link" style={{ cursor: "pointer" }}>
                        <span className="navi-icon">
                            <i className="flaticon2-calendar-8"></i>
                        </span>
                        <span className="navi-text">
                            <FormattedMessage id="OVERVIEW.PERSPECTIVE.MONTHLY" />
                        </span>
                    </span>
                </li>
            </ul>
            {/*end::Navigation*/}
        </>
    );
}
