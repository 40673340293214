import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapAddButtons from "../../components/buttons/BootstrapAddButtons";
import SnackbarError from "../../components/SnackbarError";
import moment from "moment";
import PromotionCodeForm from "./PromotionCodeForm";

const schema = yup.object().shape({
    code: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    type: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    value: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    validFrom: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("validTo", (validTo, schema) => {
            if (validTo) {
                return schema.max(new Date(validTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        }),
    validTo: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null)),
    bookingFrom: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("bookingTo", (bookingTo, schema) => {
            if (bookingTo) {
                return schema.max(new Date(bookingTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        }),
    bookingTo: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null)),
    maxUsagesPerCustomer: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    maxUsages: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    subscriptionTransactionsCount: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
});

function PromotionCodeAddPage({ addPromotionCode, clearPromotionCodeState, clearDisplayError, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { promotionCode, isLoading } = useSelector((state) => state.promotionCodes);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        return () => {
            clearPromotionCodeState();
            clearErrorState();
        };
    }, [clearPromotionCodeState, clearErrorState]);

    const { register, handleSubmit, setValue, control, errors, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmitRedirect = (values) => {
        addPromotionCodeFromForm(values, true);
    };

    const onFormSubmitAddAnother = (values) => {
        addPromotionCodeFromForm(values, false);
    };

    const addPromotionCodeFromForm = (formData, redirect) => {
        var requestData = { ...formData };
        requestData.validFrom = requestData.validFrom ? moment(requestData.validFrom).format() : null;
        requestData.validTo = requestData.validTo ? moment(requestData.validTo).format() : null;
        requestData.bookingFrom = requestData.bookingFrom ? moment(requestData.bookingFrom).format() : null;
        requestData.bookingTo = requestData.bookingTo ? moment(requestData.bookingTo).format() : null;

        addPromotionCode(profile.id, requestData, () => {
            if (redirect) {
                setRedirect(true);
            } else {
                reset();
            }
        });
    };

    if (redirect && promotionCode) {
        return <Redirect to={`/promotion-codes/${promotionCode.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>

            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">
                        <FormattedMessage id="PROMOTIONCODE.CREATE.TITLE" />
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <PromotionCodeForm
                            register={register}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            showDescription={true}
                        />
                        <BootstrapAddButtons
                            addButtonIsLoading={isLoading}
                            addAnotherButtonIsLoading={isLoading}
                            addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                            addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                        />
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, { ...promotionCodesAction, ...errorActions })(PromotionCodeAddPage);
