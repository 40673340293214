import React from "react";
import { Form, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import * as reportUtils from "./utils";
import ReportFilterFormTransactions from "./ReportFilterFormTransactions";
import ReportFilterFormGroupsAttendeesCount from "./ReportFilterFormGroupsAttendeesCount";
import ReportFilterFormGroupsAttendees from "./ReportFilterFormGroupsAttendees";
import ReportFilterFormGroupOccasionAttendees from "./ReportFilterFormGroupOccasionAttendees";
import ReportFilterFormCustomerAppointmentBookings from "./ReportFilterFormCustomerAppointmentBookings";
import ReportFilterFormCustomerAppointmentBookingsAggregated from "./ReportFilterFormCustomerAppointmentBookingsAggregated";
import ReportFilterFormResources from "./ReportFilterFormResources";
import ReportFilterFormResourceAppointmentBookings from "./ReportFilterFormResourceAppointmentBookings";
import { Controller } from "react-hook-form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

function getReportOptions(intl) {
    let options = [];
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_TRANSACTIONS,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_TRANSACTIONS, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_GROUP_ATTENDEES_COUNT,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_GROUP_ATTENDEES_COUNT, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_GROUP_ATTENDEES,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_GROUP_ATTENDEES, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_GROUP_OCCASION_ATTENDEES,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_GROUP_OCCASION_ATTENDEES, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_RESOURCES,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_RESOURCES, intl)
        )
    );
    options.push(
        getReportOption(
            reportUtils.REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS,
            reportUtils.getReportNameFromType(reportUtils.REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS, intl)
        )
    );
    return options;
}

function getReportOption(type, name) {
    return {
        id: type,
        name: name,
    };
}

const useStyles = makeStyles((theme) => ({
    infoAlertContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

function ReportFilterValuesForm({ onSaveHasBeenClicked, report, showReportInfo, isExistingReport }) {
    const { errors, control, watch, setValue } = useFormContext();

    const intl = useIntl();
    const classes = useStyles();
    const reportType = watch("reportType");

    const onReportTypeChanged = (types) => {
        if (types && types.length > 0) {
            setValue("reportType", types[0]);
        } else {
            setValue("reportType", null);
        }
    };

    const getFilterValuesForReportType = (type) => {
        if (type === reportUtils.REPORT_TYPE_TRANSACTIONS) {
            return (
                <ReportFilterFormTransactions
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_GROUP_ATTENDEES_COUNT) {
            return (
                <ReportFilterFormGroupsAttendeesCount
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_GROUP_ATTENDEES) {
            return (
                <ReportFilterFormGroupsAttendees
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_GROUP_OCCASION_ATTENDEES) {
            return (
                <ReportFilterFormGroupOccasionAttendees
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS) {
            return (
                <ReportFilterFormCustomerAppointmentBookings
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_CUSTOMER_APPOINTMENT_BOOKINGS_AGGREGATED) {
            return (
                <ReportFilterFormCustomerAppointmentBookingsAggregated
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_RESOURCES) {
            return (
                <ReportFilterFormResources
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        } else if (type === reportUtils.REPORT_TYPE_RESOURCE_APPOINTMENT_BOOKINGS) {
            return (
                <ReportFilterFormResourceAppointmentBookings
                    report={report}
                    onSaveHasBeenClicked={onSaveHasBeenClicked}
                    isDisabled={isExistingReport}
                />
            );
        }

        return <></>;
    };

    if (report) {
        if (!reportType) {
            setValue("reportType", getReportOption(report.type, reportUtils.getReportNameFromType(report.type, intl)));
        }
    }

    const reportInfo = reportType?.id ? reportUtils.getReportInfoFromType(reportType.id, intl) : "";

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="REPORT.DETAILS.TYPE" />
                </Form.Label>
                <Form.Row>
                    <Col xl={!report ? 4 : 5} xs={12}>
                        <Controller
                            control={control}
                            name="reportType"
                            defaultValue={null}
                            render={() => (
                                <div>
                                    <AsyncTypeahead
                                        id="typeahead-report-types"
                                        labelKey={"name"}
                                        minLength={0}
                                        clearButton={report ? false : true}
                                        onChange={onReportTypeChanged}
                                        onSearch={() => {}}
                                        useCache={false}
                                        isLoading={false}
                                        options={getReportOptions(intl)}
                                        filterBy={() => true}
                                        selected={reportType ? [reportType] : []}
                                        inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        isInvalid={errors.reportType?.message ? true : false}
                                        disabled={report ? true : false}
                                    />
                                    {errors.reportType?.message && (
                                        <div
                                            className="text-danger"
                                            style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}
                                        >
                                            <FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </Col>
                </Form.Row>
            </Form.Group>

            {reportInfo && showReportInfo && (
                <Alert severity="info" className={classes.infoAlertContainer}>
                    <span>{reportInfo}</span>
                </Alert>
            )}

            {getFilterValuesForReportType(reportType?.id)}
        </div>
    );
}

export default ReportFilterValuesForm;
