import React, { useMemo, useCallback } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { LinkCell, AvatarCell, TextCell, DateTimeCell } from "../../components/tables/MaterialTableCells";
import * as resourceUtils from "../resources/utils";
import { Link } from "react-router-dom";

function CustomerPageBookingResources({ getCustomerBookingResources }) {
    const { customer, customerBookingResourcesPagination, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const customerId = customer?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerId) {
                getCustomerBookingResources(customerId, pageIndex + 1, pageSize);
            }
        },
        [customerId, getCustomerBookingResources]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customer?.id}
                        data={useMemo(() => customerBookingResourcesPagination.data, [
                            customerBookingResourcesPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={customerBookingResourcesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        const { resource } = row.original;
                                        return LinkCell(`/resources/${resource.id}/${resource.type}`, resource.id);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.LIST.HEADER.RESOURCE" />,
                                    accessor: "name",
                                    Cell: ({ row }) => {
                                        const { resource } = row.original;
                                        let resourceName;
                                        if (resourceUtils.isStaff(resource)) {
                                            resourceName = `${resource.firstName} ${resource.surname}`;
                                        } else if (
                                            resourceUtils.isSpace(resource) ||
                                            resourceUtils.isArticle(resource)
                                        ) {
                                            resourceName = resource.name;
                                        }
                                        return (
                                            <Link to={`/resources/${resource.id}/${resource.type}`}>
                                                {AvatarCell(resource.avatarUrl, resourceName)}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.LIST.HEADER.BOOKED_COUNT" />
                                    ),
                                    accessor: "bookedCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.LIST.HEADER.LAST_BOOKED" />
                                    ),
                                    accessor: "lastBookedAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...customerActions, ...errorActions })(CustomerPageBookingResources);
