import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { makeStyles, Popover, Avatar, FormControlLabel, Switch, Divider } from "@material-ui/core";
import { ClearButton } from "react-bootstrap-typeahead";
import { FormattedMessage, useIntl } from "react-intl";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { actions as customerActions } from "../../../../redux/customers/customerRedux";
import { Form } from "react-bootstrap";
import DefaultAsyncTypeaheadMultiple from "../../../components/DefaultAsyncTypeaheadMultiple";
import { getCalendarFilter } from "../entryUtils";

const useStyles = makeStyles((theme) => ({
    popupRoot: {
        marginTop: "8px",
        width: "300px",
        height: "500px",
    },
    filterPopup: {},

    switchContainer: {
        padding: "12px",
    },

    bottomContainer: {
        marginTop: "8px",
        padding: "12px",
    },
    dropdownContainer: {
        marginBottom: "12px",
    },

    listItemAvatarContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

const defaultValues = {
    includeRemovedServices: false,
    includeRemovedBookings: false,
    resources: [],
    customers: [],
};

function CalendarFilterPopup({ isOpen, anchor, onClose, filterData, onFilterUpdated, getResources, getCustomers }) {
    const { profile } = useSelector((state) => state.auth);
    const { listPagination: resourceListPagination, isLoading: isResourcesLoading } = useSelector(
        (state) => state.resources
    );
    const { listPagination: customerListPagination, isLoading: isCustomersLoading } = useSelector(
        (state) => state.customers
    );

    const [initialFetchMade, setInitialFetchMade] = useState(false);
    const [includeRemovedServices, setIncludeRemovedServices] = useState(defaultValues.includeRemovedServices);
    const [includeRemovedBookings, setIncludeRemovedBookings] = useState(defaultValues.includeRemovedBookings);
    const [selectedResources, setSelectedResources] = useState(defaultValues.resources);
    const [selectedCustomers, setSelectedCustomers] = useState(defaultValues.customers);

    const profileId = profile.id;
    const classes = useStyles();
    const intl = useIntl();

    useEffect(() => {
        const savedCalendarFilter = getCalendarFilter(profileId);
        if (savedCalendarFilter) {
            setIncludeRemovedServices(savedCalendarFilter.includeRemovedServices);
            setIncludeRemovedBookings(savedCalendarFilter.includeRemovedBookings);
            setSelectedResources(savedCalendarFilter.resources);
            setSelectedCustomers(savedCalendarFilter.customers);
        }
    }, [profileId]);

    useEffect(() => {
        if (isOpen && !initialFetchMade) {
            getResources(profileId, 1, 20);
            getCustomers(profileId, 1, 20);
            setInitialFetchMade(true);
        }
    }, [profileId, isOpen, initialFetchMade, getResources, getCustomers]);

    const onResourceSearch = (search) => {
        getResources(profileId, 1, 20, search);
    };

    const onResourceChanged = (resources) => {
        setSelectedResources(resources);
        const resourceIds = resources.map((x) => x.id);
        updateFilter({ ...filterData, resourceIds });
        updateLocalStorageFilter("resources", resources);
    };

    const onCustomerSearch = (search) => {
        getCustomers(profileId, 1, 20, search);
    };

    const onCustomerChanged = (customers) => {
        setSelectedCustomers(customers);
        const customerIds = customers.map((x) => x.id);
        updateFilter({ ...filterData, customerIds });
        updateLocalStorageFilter("customers", customers);
    };

    const updateFilter = (updatedFilter) => {
        if (
            !updatedFilter?.resourceIds?.length &&
            !updatedFilter?.customerIds?.length &&
            !updatedFilter.includeRemovedServices &&
            !updatedFilter.includeRemovedBookings
        ) {
            onFilterUpdated(null);
            return;
        }
        onFilterUpdated(updatedFilter);
    };

    const updateLocalStorageFilter = (property, newValue) => {
        const values = {
            profileId,
            includeRemovedServices,
            includeRemovedBookings,
            resources: selectedResources.map((x) => {
                return { id: x.id, name: x.name };
            }),
            customers: selectedCustomers.map((x) => {
                return { id: x.id, name: x.name };
            }),
        };

        if (property === "resources") {
            newValue = newValue.map((x) => {
                return { id: x.id, name: x.name };
            });
        } else if (property === "customers") {
            newValue = newValue.map((x) => {
                return { id: x.id, name: x.name };
            });
        }

        values[property] = newValue;

        const allLocalStorageFiltersJson = localStorage.getItem("calendarFilters");
        let allLocalStorageFilters = allLocalStorageFiltersJson ? JSON.parse(allLocalStorageFiltersJson) : null;

        const valuesJson = JSON.stringify(values);
        if (valuesJson === JSON.stringify({ profileId, ...defaultValues }) && allLocalStorageFilters) {
            // Default values, don't keep in local storage
            let storageFiltersWithoutDefault = allLocalStorageFilters.filter((x) => x.profileId !== profileId);
            if (storageFiltersWithoutDefault.length > 0) {
                localStorage.setItem("calendarFilters", JSON.stringify(storageFiltersWithoutDefault));
            } else {
                localStorage.removeItem("calendarFilters");
            }
            return;
        }

        let currentLocalStorageFilter;
        if (allLocalStorageFilters) {
            currentLocalStorageFilter = allLocalStorageFilters.find((x) => x.profileId === profileId);
        }

        if (currentLocalStorageFilter) {
            const existingIndex = allLocalStorageFilters.findIndex((x) => x.profileId === profileId);
            allLocalStorageFilters[existingIndex] = values;
        } else if (allLocalStorageFilters) {
            allLocalStorageFilters.push(values);
        } else {
            allLocalStorageFilters = [values];
        }

        localStorage.setItem("calendarFilters", JSON.stringify(allLocalStorageFilters));
    };

    return (
        <Popover
            id={"calendar-filter-popup"}
            open={isOpen}
            anchorEl={anchor}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            PaperProps={{ className: classes.popupRoot }}
        >
            <div className={classes.filterPopup}>
                <div className={classes.switchContainer}>
                    <FormControlLabel
                        control={<Switch color="primary" />}
                        label={<FormattedMessage id="CALENDAR.FILTER.SHOW_REMOVED_SERVICES" />}
                        labelPlacement="end"
                        checked={includeRemovedServices}
                        onChange={(event, value) => {
                            updateFilter({ ...filterData, includeRemovedServices: value });
                            setIncludeRemovedServices(value);
                            updateLocalStorageFilter("includeRemovedServices", value);
                        }}
                        style={{ userSelect: "none" }}
                    />

                    <FormControlLabel
                        control={<Switch color="primary" />}
                        label={<FormattedMessage id="CALENDAR.FILTER.SHOW_REMOVED_BOOKINGS" />}
                        labelPlacement="end"
                        checked={includeRemovedBookings}
                        onChange={(event, value) => {
                            updateFilter({ ...filterData, includeRemovedBookings: value });
                            setIncludeRemovedBookings(value);
                            updateLocalStorageFilter("includeRemovedBookings", value);
                        }}
                        style={{ userSelect: "none" }}
                    />
                </div>

                <Divider />

                <div className={classes.bottomContainer}>
                    <div className={classes.dropdownContainer}>
                        <Form.Label>
                            <FormattedMessage id="CALENDAR.FILTER.RESOURCES.TITLE" />
                        </Form.Label>

                        <DefaultAsyncTypeaheadMultiple
                            id="typeahead-filter-resources"
                            labelKey={"name"}
                            minLength={0}
                            onChange={onResourceChanged}
                            onSearch={onResourceSearch}
                            onInputChange={(input) => {
                                if (!input) {
                                    onResourceSearch(input);
                                }
                            }}
                            useCache={false}
                            isLoading={isResourcesLoading}
                            options={getResourcesOptions(resourceListPagination)}
                            placeholder={intl.formatMessage({
                                id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                            })}
                            emptyLabel={intl.formatMessage({
                                id: "CALENDAR.FILTER.RESOURCES.SEARCH_EMPTY",
                            })}
                            selected={selectedResources}
                            multiple
                            renderMenuItemChildren={(option, props) => (
                                <div className={classes.listItemAvatarContainer}>
                                    <Avatar
                                        alt={option.name}
                                        src={option.avatarUrl}
                                        className={classes.listItemAvatar}
                                    />
                                    <span>{option.name}</span>
                                </div>
                            )}
                        >
                            {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                    {!!selected.length && (
                                        <ClearButton
                                            onClick={() => {
                                                setSelectedResources([]);
                                                onClear();
                                                updateFilter({ ...filterData, resourceIds: [] });
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </DefaultAsyncTypeaheadMultiple>
                    </div>

                    <div className={classes.dropdownContainer}>
                        <Form.Label>
                            <FormattedMessage id="CALENDAR.FILTER.CUSTOMERS.TITLE" />
                        </Form.Label>

                        <DefaultAsyncTypeaheadMultiple
                            id="typeahead-filter-customers"
                            labelKey={"name"}
                            minLength={0}
                            onChange={onCustomerChanged}
                            onSearch={onCustomerSearch}
                            onInputChange={(input) => {
                                if (!input) {
                                    onCustomerSearch(input);
                                }
                            }}
                            useCache={false}
                            isLoading={isCustomersLoading}
                            options={getCustomersOptions(customerListPagination)}
                            placeholder={intl.formatMessage({
                                id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                            })}
                            emptyLabel={intl.formatMessage({
                                id: "CALENDAR.FILTER.CUSTOMERS.SEARCH_EMPTY",
                            })}
                            selected={selectedCustomers}
                            multiple
                            renderMenuItemChildren={(option, props) => (
                                <div className={classes.listItemAvatarContainer}>
                                    <Avatar
                                        alt={option.name}
                                        src={option.avatarUrl}
                                        className={classes.listItemAvatar}
                                    />
                                    <span>{option.name}</span>
                                </div>
                            )}
                        >
                            {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                    {!!selected.length && (
                                        <ClearButton
                                            onClick={() => {
                                                setSelectedCustomers([]);
                                                onClear();
                                                updateFilter({ ...filterData, customerIds: [] });
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </DefaultAsyncTypeaheadMultiple>
                    </div>
                </div>
            </div>
        </Popover>
    );
}

function getResourcesOptions(resourcesPagination) {
    let options = [];
    if (!resourcesPagination?.data?.length) return options;
    resourcesPagination.data.forEach((resource) => {
        options.push(getResourceOption(resource));
    });

    return options;
}

function getResourceOption(resource) {
    return {
        id: resource.id,
        name: resource.name,
        avatarUrl: resource.avatarUrl,
    };
}

function getCustomersOptions(customerPagination) {
    let options = [];
    if (!customerPagination?.data?.length) return options;
    customerPagination.data.forEach((customer) => {
        options.push(getCustomerOption(customer));
    });

    return options;
}

function getCustomerOption(customer) {
    return {
        id: customer.id,
        name: `${customer.firstName} ${customer.surname}`,
        avatarUrl: customer.avatarUrl,
        email: customer.email,
    };
}

export default connect(null, { ...resourceActions, ...customerActions })(CalendarFilterPopup);
