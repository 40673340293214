import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { useIntl } from "react-intl";
import moment from "moment";
import { getWeekListItems, getWeekNameAbbreviated, getResourceWeekYearAndWeek } from "./utils";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    weekFrom: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    weekUntil: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function CopyWorkingHoursModal({
    defaultFromWeekDate,
    show,
    onCloseClicked,
    onHoursCopied,
    resourceId,
    copyResourceHours,
    resourceGroupId,
    copyResourceGroupHours,
}) {
    const { isUpdating } = useSelector((state) => state.resources);
    const [selectedFromWeekDate, setSelectedFromWeekDate] = useState();
    const classes = useStyles();
    const intl = useIntl();

    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const onFromWeekChanged = (value) => {
        setSelectedFromWeekDate(moment(value));
    };

    const onFormSubmit = (values) => {
        const fromYearValues = getResourceWeekYearAndWeek(moment(values.weekFrom));
        const untilYearValues = getResourceWeekYearAndWeek(moment(values.weekUntil));

        if (resourceId) {
            copyResourceHours(
                resourceId,
                fromYearValues.year,
                fromYearValues.week,
                untilYearValues.year,
                untilYearValues.week,
                () => {
                    onHoursCopied();
                }
            );
        } else if (resourceGroupId) {
            copyResourceGroupHours(
                resourceGroupId,
                fromYearValues.year,
                fromYearValues.week,
                untilYearValues.year,
                untilYearValues.week,
                () => {
                    onHoursCopied();
                }
            );
        }
    };

    const todayDate = moment();

    // Available from weeks
    const firstFromWeekDate = moment(todayDate).subtract(6, "months").startOf("isoWeek");
    const lastFromWeekDate = moment(todayDate).add(6, "months").startOf("isoWeek");
    const fromWeeks = getWeekListItems(firstFromWeekDate, lastFromWeekDate, moment);
    const defaultFromWeek = defaultFromWeekDate || todayDate.startOf("isoWeek");

    // Available until weeks
    const firstUntilWeekDate = moment(todayDate).startOf("isoWeek");
    const lastUntilWeekDate = moment(todayDate).add(1, "years").startOf("isoWeek");
    const untilWeeks = getWeekListItems(firstUntilWeekDate, lastUntilWeekDate, moment);
    const defaultUntilWeek = defaultFromWeek.clone().add(1, "weeks");

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id={"RESOURCE.HOURS.COPY.TITLE"} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={"RESOURCE.HOURS.COPY.DESCRIPTION"} />
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id={"RESOURCE.HOURS.COPY.WEEK.FROM.TITLE"} />
                            </Form.Label>
                            <Form.Control
                                as="select"
                                ref={register}
                                name="weekFrom"
                                defaultValue={defaultFromWeek.format("YYYY-MM-DD")}
                                onChange={(val) => onFromWeekChanged(val.target.value)}
                            >
                                {fromWeeks.map((x) => {
                                    const dateFormat = x.weekDate.format("YYYY-MM-DD");
                                    return (
                                        <option key={`from_${dateFormat}`} value={dateFormat}>
                                            {getWeekNameAbbreviated(x.weekDate, todayDate, intl)}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage
                                    id={"RESOURCE.HOURS.COPY.WEEK.UNTIL.TITLE"}
                                    values={{
                                        week: getWeekNameAbbreviated(
                                            selectedFromWeekDate || defaultFromWeek,
                                            todayDate,
                                            intl
                                        ),
                                    }}
                                />
                            </Form.Label>
                            <Form.Control
                                as="select"
                                ref={register}
                                name="weekUntil"
                                defaultValue={defaultUntilWeek.format("YYYY-MM-DD")}
                            >
                                {untilWeeks.map((x) => {
                                    const dateFormat = x.weekDate.format("YYYY-MM-DD");
                                    return (
                                        <option key={`until_${dateFormat}`} value={dateFormat}>
                                            {getWeekNameAbbreviated(x.weekDate, todayDate, intl)}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)} />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, resourceActions)(CopyWorkingHoursModal);
