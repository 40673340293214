import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useFormContext } from "react-hook-form";

function ReportCommonDetailsForm({ report }) {
    const { register, errors } = useFormContext();

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="REPORT.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-6"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={report?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="REPORT.DETAILS.NAME_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default connect(null, {})(ReportCommonDetailsForm);
