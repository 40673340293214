import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReportFilterDropdownMultiple from "./filters/ReportFilterDropdownMultiple";
import ReportFilterDateInterval from "./filters/ReportFilterDateInterval";
import ReportFilterSwitch from "./filters/ReportFilterSwitch";
import moment from "moment";

const ReportFilterFormResourceAppointmentBookings = ({ report, onSaveHasBeenClicked, isDisabled }) => {
    const intl = useIntl();

    const filterValues = report?.filterValues;
    const filterValuesObj = filterValues ? JSON.parse(filterValues) : {};
    let startTime = filterValuesObj.bookingStartTime
        ? moment(filterValuesObj.bookingStartTime).format("YYYY-MM-DD HH:mm")
        : null;
    let endTime = filterValuesObj.bookingEndTime
        ? moment(filterValuesObj.bookingEndTime).format("YYYY-MM-DD HH:mm")
        : null;
    let onlyLatestBooking = filterValuesObj.onlyLatestBooking;
    let includeRemovedBookings = filterValuesObj.includeRemovedBookings;

    const resourceTypeOptions = [
        {
            id: "staff",
            name: intl.formatMessage({ id: "RESOURCE.TYPE.STAFF.STAFF" }),
        },
        {
            id: "space",
            name: intl.formatMessage({ id: "RESOURCE.TYPE.SPACE" }),
        },
        {
            id: "article",
            name: intl.formatMessage({ id: "RESOURCE.TYPE.ARTICLE" }),
        },
    ];

    let defaultResourceTypeOptions = [];
    if (filterValuesObj.resourceTypes) {
        if (filterValuesObj.resourceTypes.includes("staff")) {
            defaultResourceTypeOptions.push({
                id: "staff",
                name: intl.formatMessage({ id: "RESOURCE.TYPE.STAFF.STAFF" }),
            });
        }
        if (filterValuesObj.resourceTypes.includes("space")) {
            defaultResourceTypeOptions.push({
                id: "space",
                name: intl.formatMessage({ id: "RESOURCE.TYPE.SPACE" }),
            });
        }
        if (filterValuesObj.resourceTypes.includes("article")) {
            defaultResourceTypeOptions.push({
                id: "article",
                name: intl.formatMessage({ id: "RESOURCE.TYPE.ARTICLE" }),
            });
        }
    }

    return (
        <>
            <ReportFilterDateInterval
                startTimeName="bookingStartTime"
                endTimeName="bookingEndTime"
                defaultStartTime={startTime}
                defaultEndTime={endTime}
                showWithTime={true}
                title={<FormattedMessage id="REPORT.RESOURCE_APPOINTMENT_BOOKINGS.FILTER.BOOKING_PERIOD.TITLE" />}
                isStartTimeMandatory={true}
                isEndTimeMandatory={false}
                onSaveHasBeenClicked={onSaveHasBeenClicked}
                isDisabled={isDisabled}
            />
            <ReportFilterDropdownMultiple
                name="resourceTypes"
                options={resourceTypeOptions}
                defaultValues={defaultResourceTypeOptions}
                title={<FormattedMessage id="REPORT.RESOURCE_APPOINTMENT_BOOKINGS.FILTER.RESOURCE_TYPES.TITLE" />}
                isDisabled={isDisabled}
            />
            <ReportFilterSwitch
                name="onlyLatestBooking"
                defaultValue={onlyLatestBooking}
                title={<FormattedMessage id="REPORT.RESOURCE_APPOINTMENT_BOOKINGS.FILTER.ONLY_LATEST_BOOKING.TITLE" />}
                isDisabled={isDisabled}
            />
            <ReportFilterSwitch
                name="includeRemovedBookings"
                defaultValue={includeRemovedBookings}
                title={
                    <FormattedMessage id="REPORT.RESOURCE_APPOINTMENT_BOOKINGS.FILTER.INCLUDE_REMOVED_SERVICES.TITLE" />
                }
                isDisabled={isDisabled}
            />
        </>
    );
};

export default ReportFilterFormResourceAppointmentBookings;
