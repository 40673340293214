import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as reportActions } from "../../../redux/reports/reportRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import ReportPageNavigator from "./ReportPageNavigator";
import ReportPageDetails from "./ReportPageDetails";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function ReportPage({ match, getReport, clearReportState, clearDisplayError, clearErrorState }) {
    const reportId = parseInt(match.params.id, 10);
    const { report, isLoading, isUpdating } = useSelector((state) => state.reports);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!report || report.id !== reportId)) {
            getReport(reportId);
        }
    }, [getReport, report, reportId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearErrorState();
            clearReportState();
        };
    }, [clearErrorState, clearReportState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <ReportPageNavigator reportId={reportId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect from={`/reports/${reportId}`} exact={true} to={`/reports/${reportId}/details`} />
                        <Route path={`/reports/${reportId}/details`} component={ReportPageDetails} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...reportActions,
    ...errorActions,
})(ReportPage);
