import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    button: {
        display: "flex",
        alignItems: "center",
    },
    spinner: {
        marginRight: theme.spacing(1),
    },
}));

function BootstrapSaveButton(props) {
    const classes = useStyles();
    return (
        <Button
            variant="primary"
            type="submit"
            size={props.size}
            onClick={props.onClick}
            className={props.className || classes.button}
            disabled={props.isLoading}
        >
            {props.isLoading && (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                    className={classes.spinner}
                />
            )}
            {props.label && props.label}
            {!props.label && <FormattedMessage id="COMMON.SAVE" />}
        </Button>
    );
}

export default BootstrapSaveButton;
