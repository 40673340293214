import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROFILE_BILLING_TEMPLATES_URL = "v1/admin/profiles/:id/billing-templates";

export const BILLING_TEMPLATE_DETAILS_URL = "v1/admin/billing-templates/:id";

export function getProfileBillingTemplates(profileId, page, perPage) {
    let url = `${PROFILE_BILLING_TEMPLATES_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getProfileBillingTemplate(id) {
    return axios.get(getApiUrl() + BILLING_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function addProfileBillingTemplate(profileId, data) {
    return axios.post(getApiUrl() + PROFILE_BILLING_TEMPLATES_URL.replace(":id", profileId), data);
}

export function updateProfileBillingTemplate(id, originalBillingTemplate, changedBillingTemplate) {
    let url = BILLING_TEMPLATE_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalBillingTemplate, changedBillingTemplate), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteProfileBillingTemplate(id) {
    return axios.delete(getApiUrl() + BILLING_TEMPLATE_DETAILS_URL.replace(":id", id));
}
