import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as reportActions } from "../../../redux/reports/reportRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper, IconButton } from "@material-ui/core";
import { LinkCell, DateTimeCell, TextCell } from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import * as reportUtils from "./utils";
import ReportListToolbar from "../../components/tables/toolbar/ReportListToolbar";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

function ReportListPage({ getReports, downloadReportDocument, deleteReport, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination, isDownloadingDocument } = useSelector((state) => state.reports);
    const { displayError } = useSelector((state) => state.errors);

    const history = useHistory();
    const intl = useIntl();

    const [reportToDelete, setReportToDelete] = useState(null);

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getReports(profile.id, pageIndex + 1, pageSize);
        },
        [getReports, profile.id]
    );

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return <ReportListToolbar />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="REPORT.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: ({ row }) => {
                                return LinkCell(`/reports/${row.original.id}`, row.original.id);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REPORT.LIST.HEADER.TYPE" />,
                            accessor: "type",
                            Cell: (props) => {
                                return TextCell(reportUtils.getReportNameFromType(props.value, intl));
                            },
                        },
                        {
                            Header: <FormattedMessage id="REPORT.LIST.HEADER.NAME" />,
                            accessor: "name",
                            Cell: ({ row }) => {
                                return LinkCell(`/reports/${row.original.id}`, row.original.name);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REPORT.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REPORT.LIST.HEADER.FINISHED_AT" />,
                            accessor: "finishedAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REPORT.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return reportUtils.getReportStatusCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                const report = row.original;

                                let menuItems = [
                                    {
                                        icon: <LinkIcon fontSize="small" />,
                                        label: <FormattedMessage id="COMMON.OPEN" />,
                                        onClick: () => {},
                                    },
                                ];

                                menuItems.push({
                                    icon: <CopyIcon fontSize="small" />,
                                    label: <FormattedMessage id="COMMON.COPY" />,
                                    onClick: () => {
                                        const url = reportUtils.getReportCopyUrl(row.original.id);
                                        if (url) {
                                            history.push(url);
                                        }
                                    },
                                });

                                menuItems.push({
                                    icon: <DeleteOutlinedIcon fontSize="small" />,
                                    label: <FormattedMessage id="COMMON.DELETE" />,
                                    onClick: () => setReportToDelete(report),
                                });

                                if (report.status !== reportUtils.REPORT_STATUS_FINISHED) {
                                    return <ListActionsButton menuItems={menuItems} />;
                                } else {
                                    return (
                                        <div>
                                            <IconButton
                                                disabled={isDownloadingDocument}
                                                onClick={() => downloadReportDocument(report.id, `Report ${report.id}`)}
                                            >
                                                <CloudDownloadOutlinedIcon />
                                            </IconButton>
                                            <ListActionsButton menuItems={menuItems} />
                                        </div>
                                    );
                                }
                            },
                        },
                    ],
                    [intl, downloadReportDocument, isDownloadingDocument, history]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
            <YesNoDialog
                title={<FormattedMessage id="REPORT.DELETE.TEXT" />}
                open={reportToDelete ? true : false}
                onOpenChanged={() => setReportToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteReport(reportToDelete.id);
                    }
                    setReportToDelete(null);
                }}
            />
        </Paper>
    );
}

export default connect(null, { ...reportActions, ...errorActions })(ReportListPage);
