import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import PricingTemplateTimeRulesForm from "./PricingTemplateTimeRulesForm";
import { areHoursOverlapping } from "../../utils/DateUtils";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),

        [theme.breakpoints.down("sm")]: {
            padding: "0px",
        },
    },
}));

const schema = yup.object().shape({});

function PricingTemplateTimeRulesAdjustModal({ show, onSaveClicked, onCloseClicked }) {
    const { timeRulesPagination, isLoading } = useSelector((state) => state.pricingTemplates);
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState(null);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (formValues) => {
        const timeRules = JSON.parse(JSON.stringify(formValues.timeRules));
        for (let i = 0; i < timeRules.length; i++) {
            const timeRule = timeRules[i];

            let hours = [];
            for (let periodIndex = 0; periodIndex < timeRule.periods.length; periodIndex++) {
                var timeRulePeriod = timeRule.periods[periodIndex];

                const startTimeHour = parseInt(timeRulePeriod.startTime.substring(0, 2), 10);
                const startTimeMinute = parseInt(timeRulePeriod.startTime.substring(3), 10);
                let endTimeHour = parseInt(timeRulePeriod.endTime.substring(0, 2), 10);
                const endTimeMinute = parseInt(timeRulePeriod.endTime.substring(3), 10);
                if (endTimeHour === 0) {
                    // If last minute of day, adjust so that its not considered earlier than start time
                    endTimeHour = 24;
                    if (endTimeMinute > 0) {
                        setErrorMessage(
                            <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ERROR.HOURS_END_AFTER_MIDNIGHT" />
                        );
                        return;
                    }
                }

                if (
                    startTimeHour > endTimeHour ||
                    (startTimeHour === endTimeHour && startTimeMinute >= endTimeMinute)
                ) {
                    setErrorMessage(<FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ERROR.HOURS_START_AFTER_END" />);
                    return;
                } else if (
                    typeof timeRulePeriod.price !== "number" ||
                    isNaN(timeRulePeriod.price) ||
                    timeRulePeriod.price < 0
                ) {
                    setErrorMessage(<FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ERROR.PRICE_MISSING" />);
                    return;
                }

                hours.push([timeRulePeriod.startTime, timeRulePeriod.endTime]);
            }

            if (areHoursOverlapping(hours)) {
                setErrorMessage(<FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ERROR.HOURS_OVERLAPPING" />);
                return;
            }
        }

        onSaveClicked(timeRules);
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ADJUST.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...formMethods}>
                    <Form>
                        <div className={classes.formContent}>
                            <PricingTemplateTimeRulesForm
                                savedTimeRules={timeRulesPagination.data}
                                errorMessage={errorMessage}
                            />
                        </div>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="COMMON.SAVE" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default PricingTemplateTimeRulesAdjustModal;
