import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import PromotionCodePageNavigator from "./PromotionCodePageNavigator";
import PromotionCodePageDetails from "./PromotionCodePageDetails";
import PromotionCodePageServices from "./PromotionCodePageServices";
import PromotionCodePageTransactions from "./PromotionCodePageTransactions";
import PromotionCodePageCustomers from "./PromotionCodePageCustomers";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function PromotionCodePage({ match, getPromotionCode, clearPromotionCodeState, clearDisplayError, clearErrorState }) {
    const promotionCodeId = parseInt(match.params.id, 10);
    const { promotionCode, isLoading, isUpdating } = useSelector((state) => state.promotionCodes);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!promotionCode || promotionCode.id !== promotionCodeId)) {
            getPromotionCode(promotionCodeId);
        }
    }, [getPromotionCode, promotionCode, promotionCodeId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearPromotionCodeState();
            clearErrorState();
        };
    }, [clearPromotionCodeState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <PromotionCodePageNavigator promotionCodeId={promotionCodeId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/promotion-codes/${promotionCodeId}`}
                            exact={true}
                            to={`/promotion-codes/${promotionCodeId}/details`}
                        />
                        <Route
                            path={`/promotion-codes/${promotionCodeId}/details`}
                            component={PromotionCodePageDetails}
                        />
                        <Route
                            path={`/promotion-codes/${promotionCodeId}/services`}
                            component={PromotionCodePageServices}
                        />
                        <Route
                            path={`/promotion-codes/${promotionCodeId}/transactions`}
                            component={PromotionCodePageTransactions}
                        />
                        <Route
                            path={`/promotion-codes/${promotionCodeId}/customers`}
                            component={PromotionCodePageCustomers}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...promotionCodesAction, ...errorActions })(PromotionCodePage);
