import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";

export const EMAIL_LOGIN_URL = "v1/auth/login";
export const GOOGLE_LOGIN_URL = "v1/auth/google";
export const APPLE_LOGIN_URL = "v1/auth/apple";
export const FACEBOOK_LOGIN_URL = "v1/auth/facebook";
export const REGISTER_URL = "v1/auth/register";
export const STAFF_INVITATION_URL = "v1/auth/staff-invitation";
export const STAFF_INVITATION_ACCEPT_URL = "v1/auth/staff-invitation/{hash}/accept";
export const REQUEST_PASSWORD_URL = "v1/auth/forgot-password";
export const LOGOUT_URL = "v1/auth/logout";
export const USER_PROFILES_URL = "v1/users/me/profiles";

export function login(loginUrl, credentials) {
    return axios.post(getApiUrl() + loginUrl, credentials);
}

export function register(email, firstName, surname, password) {
    return axios.post(getApiUrl() + REGISTER_URL, {
        email,
        firstName,
        surname,
        password,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
}

export function getStaffInvitation(hash) {
    return axios.get(getApiUrl() + STAFF_INVITATION_URL + "?hash=" + hash);
}

export function acceptStaffInvitation(hash, email, firstName, surname, password) {
    const url = STAFF_INVITATION_ACCEPT_URL.replace("{hash}", hash);
    return axios.post(getApiUrl() + url, {
        email,
        firstName,
        surname,
        password,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
}

export function requestPassword(email) {
    return axios.get(getApiUrl() + REQUEST_PASSWORD_URL + "?email=" + email);
}

export function resetPassword(password, queryString) {
    const hash = new URLSearchParams(queryString).get("hash");
    return axios.post(getApiUrl() + REQUEST_PASSWORD_URL, { hash, password });
}

export function getUserProfiles(search) {
    return axios.get(getApiUrl() + USER_PROFILES_URL + (search ? `?search=${search}` : ""));
}

export function logout() {
    return axios.post(getApiUrl() + LOGOUT_URL);
}
