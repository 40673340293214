import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as billingTemplateActions } from "../../../redux/billingtemplates/billingTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import BillingTemplatePageNavigator from "./BillingTemplatePageNavigator";
import BillingTemplatePageDetails from "./BillingTemplatePageDetails";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function BillingTemplatePage({
    match,
    getProfileBillingTemplate,
    clearBillingTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const billingTemplateId = parseInt(match.params.id, 10);
    const { billingTemplate, isLoading, isUpdating } = useSelector((state) => state.billingTemplates);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!billingTemplate || billingTemplate.id !== billingTemplateId)) {
            getProfileBillingTemplate(billingTemplateId);
        }
    }, [getProfileBillingTemplate, billingTemplate, billingTemplateId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearBillingTemplateState();
            clearErrorState();
        };
    }, [clearBillingTemplateState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <BillingTemplatePageNavigator billingTemplateId={billingTemplateId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/billing-templates/${billingTemplateId}`}
                            exact={true}
                            to={`/billing-templates/${billingTemplateId}/details`}
                        />
                        <Route
                            path={`/billing-templates/${billingTemplateId}/details`}
                            component={BillingTemplatePageDetails}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...billingTemplateActions,
    ...errorActions,
})(BillingTemplatePage);
