import { put, takeLatest, select } from "redux-saga/effects";
import {
    getServices,
    getServicesAndCategories,
    getServiceResources,
    getServiceOptions,
    getServiceAddons,
    addServiceAddon,
    deleteServiceAddon,
    updateServiceVisibility,
    getAppointment,
    addAppointment,
    patchAppointment,
    deleteAppointment,
    getAppointmentResources,
    addAppointmentResource,
    patchAppointmentResource,
    deleteAppointmentResource,
    getAppointmentBookings,
    getCourse,
    addCourse,
    patchCourse,
    deleteCourse,
    getCourseResources,
    addCourseResource,
    deleteCourseResource,
    getCourseAttendees,
    deleteCourseAttendee,
    getCourseOccasions,
    addCourseOccasions,
    getWaitingListMembers,
    acceptWaitingListMember,
    deleteWaitingListMember,
    getMembership,
    addMembership,
    patchMembership,
    deleteMembership,
    getMembershipServices,
    addMembershipService,
    deleteMembershipService,
    getMembershipBookings,
    getVoucher,
    addVoucher,
    patchVoucher,
    deleteVoucher,
    getVoucherServices,
    addVoucherService,
    deleteVoucherService,
    getVoucherBookings,
    getProduct,
    addProduct,
    patchProduct,
    deleteProduct,
    updateServiceListSorting,
} from "./serviceCrud";
import { findAndSpliceArrayByProperty } from "../reduxUtils";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import {
    SERVICE_TYPE_APPOINTMENT,
    SERVICE_TYPE_COURSE,
    SERVICE_TYPE_VOUCHER,
    SERVICE_TYPE_MEMBERSHIP,
    SERVICE_TYPE_PRODUCT,
} from "../../app/pages/services/utils";

export const actionTypes = {
    GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
    GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
    GET_SERVICES_AND_CATEGORIES_REQUEST: "GET_SERVICES_AND_CATEGORIES_REQUEST",
    GET_SERVICES_AND_CATEGORIES_SUCCESS: "GET_SERVICES_AND_CATEGORIES_SUCCESS",
    GET_SERVICE_RESOURCES_REQUEST: "GET_SERVICE_RESOURCES_REQUEST",
    GET_SERVICE_RESOURCES_SUCCESS: "GET_SERVICE_RESOURCES_SUCCESS",
    GET_SERVICE_OPTIONS_REQUEST: "GET_SERVICE_OPTIONS_REQUEST",
    GET_SERVICE_OPTIONS_SUCCESS: "GET_SERVICE_OPTIONS_SUCCESS",
    GET_SERVICE_ADDONS_REQUEST: "GET_SERVICE_ADDONS_REQUEST",
    GET_SERVICE_ADDONS_SUCCESS: "GET_SERVICE_ADDONS_SUCCESS",
    ADD_SERVICE_ADDON_REQUEST: "ADD_SERVICE_ADDON_REQUEST",
    ADD_SERVICE_ADDON_SUCCESS: "ADD_SERVICE_ADDON_SUCCESS",
    DELETE_SERVICE_ADDON_REQUEST: "DELETE_SERVICE_ADDON_REQUEST",
    DELETE_SERVICE_ADDON_SUCCESS: "DELETE_SERVICE_ADDON_SUCCESS",
    UPDATE_SERVICE_VISIBILITY_REQUEST: "UPDATE_SERVICE_VISIBILITY_REQUEST",
    UPDATE_SERVICE_VISIBILITY_SUCCESS: "UPDATE_SERVICE_VISIBILITY_SUCCESS",
    GET_SERVICE_COPY_REQUEST: "GET_SERVICE_COPY_REQUEST",
    GET_SERVICE_COPY_SUCCESS: "GET_SERVICE_COPY_SUCCESS",
    GET_APPOINTMENT_REQUEST: "GET_APPOINTMENT_REQUEST",
    GET_APPOINTMENT_SUCCESS: "GET_APPOINTMENT_SUCCESS",
    ADD_APPOINTMENT_REQUEST: "ADD_APPOINTMENT_REQUEST",
    ADD_APPOINTMENT_SUCCESS: "ADD_APPOINTMENT_SUCCESS",
    UPDATE_APPOINTMENT_REQUEST: "UPDATE_APPOINTMENT_REQUEST",
    UPDATE_APPOINTMENT_SUCCESS: "UPDATE_APPOINTMENT_SUCCESS",
    DELETE_APPOINTMENT_REQUEST: "DELETE_APPOINTMENT_REQUEST",
    DELETE_APPOINTMENT_SUCCESS: "DELETE_APPOINTMENT_SUCCESS",
    GET_APPOINTMENT_RESOURCES_REQUEST: "GET_APPOINTMENT_RESOURCES_REQUEST",
    GET_APPOINTMENT_RESOURCES_SUCCESS: "GET_APPOINTMENT_RESOURCES_SUCCESS",
    ADD_APPOINTMENT_RESOURCE_REQUEST: "ADD_APPOINTMENT_RESOURCE_REQUEST",
    ADD_APPOINTMENT_RESOURCE_SUCCESS: "ADD_APPOINTMENT_RESOURCE_SUCCESS",
    UPDATE_APPOINTMENT_RESOURCE_REQUEST: "UPDATE_APPOINTMENT_RESOURCE_REQUEST",
    UPDATE_APPOINTMENT_RESOURCE_SUCCESS: "UPDATE_APPOINTMENT_RESOURCE_SUCCESS",
    DELETE_APPOINTMENT_RESOURCE_REQUEST: "DELETE_APPOINTMENT_RESOURCE_REQUEST",
    DELETE_APPOINTMENT_RESOURCE_SUCCESS: "DELETE_APPOINTMENT_RESOURCE_SUCCESS",
    GET_APPOINTMENT_BOOKINGS_REQUEST: "GET_APPOINTMENT_BOOKINGS_REQUEST",
    GET_APPOINTMENT_BOOKINGS_SUCCESS: "GET_APPOINTMENT_BOOKINGS_SUCCESS",
    GET_COURSE_REQUEST: "GET_COURSE_REQUEST",
    GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
    ADD_COURSE_REQUEST: "ADD_COURSE_REQUEST",
    ADD_COURSE_SUCCESS: "ADD_COURSE_SUCCESS",
    UPDATE_COURSE_REQUEST: "UPDATE_COURSE_REQUEST",
    UPDATE_COURSE_SUCCESS: "UPDATE_COURSE_SUCCESS",
    DELETE_COURSE_REQUEST: "DELETE_COURSE_REQUEST",
    DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",
    GET_COURSE_RESOURCES_REQUEST: "GET_COURSE_RESOURCES_REQUEST",
    GET_COURSE_RESOURCES_SUCCESS: "GET_COURSE_RESOURCES_SUCCESS",
    ADD_COURSE_RESOURCE_REQUEST: "ADD_COURSE_RESOURCE_REQUEST",
    ADD_COURSE_RESOURCE_SUCCESS: "ADD_COURSE_RESOURCE_SUCCESS",
    DELETE_COURSE_RESOURCE_REQUEST: "DELETE_COURSE_RESOURCE_REQUEST",
    DELETE_COURSE_RESOURCE_SUCCESS: "DELETE_COURSE_RESOURCE_SUCCESS",
    GET_COURSE_ATTENDEES_REQUEST: "GET_COURSE_ATTENDEES_REQUEST",
    GET_COURSE_ATTENDEES_SUCCESS: "GET_COURSE_ATTENDEES_SUCCESS",
    DELETE_COURSE_ATTENDEE_REQUEST: "DELETE_COURSE_ATTENDEES_REQUEST",
    DELETE_COURSE_ATTENDEE_SUCCESS: "DELETE_COURSE_ATTENDEES_SUCCESS",
    GET_COURSE_OCCASIONS_REQUEST: "GET_COURSE_OCCASIONS_REQUEST",
    GET_COURSE_OCCASIONS_SUCCESS: "GET_COURSE_OCCASIONS_SUCCESS",
    ADD_COURSE_OCCASIONS_REQUEST: "ADD_COURSE_OCCASIONS_REQUEST",
    ADD_COURSE_OCCASIONS_SUCCESS: "ADD_COURSE_OCCASIONS_SUCCESS",
    GET_COURSE_WAITING_LIST_MEMBERS_REQUEST: "GET_COURSE_WAITING_LIST_MEMBERS_REQUEST",
    GET_COURSE_WAITING_LIST_MEMBERS_SUCCESS: "GET_COURSE_WAITING_LIST_MEMBERS_SUCCESS",
    ACCEPT_WAITING_LIST_MEMBER_REQUEST: "ACCEPT_WAITING_LIST_MEMBER_REQUEST",
    ACCEPT_WAITING_LIST_MEMBER_SUCCESS: "ACCEPT_WAITING_LIST_MEMBER_SUCCESS",
    DELETE_WAITING_LIST_MEMBER_REQUEST: "DELETE_WAITING_LIST_MEMBER_REQUEST",
    DELETE_WAITING_LIST_MEMBER_SUCCESS: "DELETE_WAITING_LIST_MEMBER_SUCCESS",
    GET_MEMBERSHIP_REQUEST: "GET_MEMBERSHIP_REQUEST",
    GET_MEMBERSHIP_SUCCESS: "GET_MEMBERSHIP_SUCCESS",
    ADD_MEMBERSHIP_REQUEST: "ADD_MEMBERSHIP_REQUEST",
    ADD_MEMBERSHIP_SUCCESS: "ADD_MEMBERSHIP_SUCCESS",
    UPDATE_MEMBERSHIP_REQUEST: "UPDATE_MEMBERSHIP_REQUEST",
    UPDATE_MEMBERSHIP_SUCCESS: "UPDATE_MEMBERSHIP_SUCCESS",
    DELETE_MEMBERSHIP_REQUEST: "DELETE_MEMBERSHIP_REQUEST",
    DELETE_MEMBERSHIP_SUCCESS: "DELETE_MEMBERSHIP_SUCCESS",
    GET_MEMBERSHIP_SERVICES_REQUEST: "GET_MEMBERSHIP_SERVICES_REQUEST",
    GET_MEMBERSHIP_SERVICES_SUCCESS: "GET_MEMBERSHIP_SERVICES_SUCCESS",
    ADD_MEMBERSHIP_SERVICE_REQUEST: "ADD_MEMBERSHIP_SERVICE_REQUEST",
    ADD_MEMBERSHIP_SERVICE_SUCCESS: "ADD_MEMBERSHIP_SERVICE_SUCCESS",
    DELETE_MEMBERSHIP_SERVICE_REQUEST: "DELETE_MEMBERSHIP_SERVICE_REQUEST",
    DELETE_MEMBERSHIP_SERVICE_SUCCESS: "DELETE_MEMBERSHIP_SERVICE_SUCCESS",
    GET_MEMBERSHIP_BOOKINGS_REQUEST: "GET_MEMBERSHIP_BOOKINGS_REQUEST",
    GET_MEMBERSHIP_BOOKINGS_SUCCESS: "GET_MEMBERSHIP_BOOKINGS_SUCCESS",
    GET_VOUCHER_REQUEST: "GET_VOUCHER_REQUEST",
    GET_VOUCHER_SUCCESS: "GET_VOUCHER_SUCCESS",
    ADD_VOUCHER_REQUEST: "ADD_VOUCHER_REQUEST",
    ADD_VOUCHER_SUCCESS: "ADD_VOUCHER_SUCCESS",
    UPDATE_VOUCHER_REQUEST: "UPDATE_VOUCHER_REQUEST",
    UPDATE_VOUCHER_SUCCESS: "UPDATE_VOUCHER_SUCCESS",
    DELETE_VOUCHER_REQUEST: "DELETE_VOUCHER_REQUEST",
    DELETE_VOUCHER_SUCCESS: "DELETE_VOUCHER_SUCCESS",
    GET_VOUCHER_SERVICES_REQUEST: "GET_VOUCHER_SERVICES_REQUEST",
    GET_VOUCHER_SERVICES_SUCCESS: "GET_VOUCHER_SERVICES_SUCCESS",
    ADD_VOUCHER_SERVICE_REQUEST: "ADD_VOUCHER_SERVICE_REQUEST",
    ADD_VOUCHER_SERVICE_SUCCESS: "ADD_VOUCHER_SERVICE_SUCCESS",
    DELETE_VOUCHER_SERVICE_REQUEST: "DELETE_VOUCHER_SERVICE_REQUEST",
    DELETE_VOUCHER_SERVICE_SUCCESS: "DELETE_VOUCHER_SERVICE_SUCCESS",
    GET_VOUCHER_BOOKINGS_REQUEST: "GET_VOUCHER_BOOKINGS_REQUEST",
    GET_VOUCHER_BOOKINGS_SUCCESS: "GET_VOUCHER_BOOKINGS_SUCCESS",
    GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
    GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
    ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
    UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    GET_SERVICES_BY_CATEGORY_REQUEST: "GET_SERVICES_BY_CATEGORY_REQUEST",
    GET_SERVICES_BY_CATEGORY_SUCCESS: "GET_SERVICES_BY_CATEGORY_SUCCESS",
    CHANGE_SERVICES_SORTING_REQUEST: "CHANGE_SERVICES_SORTING_REQUEST",
    CHANGE_SERVICES_SORTING_SUCCESS: "CHANGE_SERVICES_SORTING_SUCCESS",
    UPDATE_SERVICES_SORTING_REQUEST: "UPDATE_SERVICES_SORTING_REQUEST",
    UPDATE_SERVICES_SORTING_SUCCESS: "UPDATE_SERVICES_SORTING_SUCCESS",
    CLEAR_SERVICES_STATE: "CLEAR_SERVICES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    isSortingUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    listByCategoryPagination: {
        data: [],
        totalRows: 0,
    },
    servicesAndCategoriesPagination: {
        data: [],
        totalRows: 0,
    },
    serviceResourcesPagination: null,
    serviceOptions: null,
    serviceToCopy: null,
    serviceAddonsPagination: {
        data: [],
        totalRows: 0,
    },
    appointmentResourcesPagination: {
        data: [],
        totalRows: 0,
    },
    appointmentBookingsPagination: {
        data: [],
        totalRows: 0,
    },
    courseResourcesPagination: {
        data: [],
        totalRows: 0,
    },
    courseAttendeesPagination: {
        data: [],
        totalRows: 0,
    },
    courseOccasionsPagination: {
        data: [],
        totalRows: 0,
    },
    waitingListMembersPagination: {
        data: [],
        totalRows: 0,
    },
    membershipServicesPagination: {
        data: [],
        totalRows: 0,
    },
    membershipBookingsPagination: {
        data: [],
        totalRows: 0,
    },
    voucherServicesPagination: {
        data: [],
        totalRows: 0,
    },
    voucherBookingsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_SERVICES_AND_CATEGORIES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICES_AND_CATEGORIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                servicesAndCategoriesPagination: action.payload.response,
            };
        }

        case actionTypes.GET_SERVICE_RESOURCES_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_SERVICE_RESOURCES_SUCCESS: {
            return {
                ...state,
                serviceResourcesPagination: action.payload.response,
            };
        }

        case actionTypes.GET_SERVICE_OPTIONS_SUCCESS: {
            return {
                ...state,
                serviceOptions: action.payload.response,
            };
        }

        case actionTypes.GET_SERVICE_ADDONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICE_ADDONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                serviceAddonsPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_SERVICE_ADDON_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_SERVICE_ADDON_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.DELETE_SERVICE_ADDON_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_SERVICE_ADDON_SUCCESS: {
            const removedAddon = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.serviceAddonsPagination.data,
                "id",
                removedAddon.id
            );
            return {
                ...state,
                isLoading: false,
                serviceAddonsPagination: {
                    ...state.serviceAddonsPagination,
                    data: alteredStateArray,
                    totalRows: state.serviceAddonsPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.UPDATE_SERVICE_VISIBILITY_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionTypes.UPDATE_SERVICE_VISIBILITY_SUCCESS: {
            const changedService = action.payload.response;
            var stateChange = { ...getRemovedServiceListState(changedService.id, state), isLoading: false };
            if (changedService.type === SERVICE_TYPE_APPOINTMENT) {
                stateChange.appointment = changedService;
            } else if (changedService.type === SERVICE_TYPE_COURSE) {
                stateChange.course = changedService;
            } else if (changedService.type === SERVICE_TYPE_VOUCHER) {
                stateChange.voucher = changedService;
            } else if (changedService.type === SERVICE_TYPE_MEMBERSHIP) {
                stateChange.membership = changedService;
            }
            return stateChange;
        }

        case actionTypes.GET_SERVICE_COPY_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionTypes.GET_SERVICE_COPY_SUCCESS: {
            return {
                ...state,
                serviceToCopy: action.payload.response,
                isLoading: false,
            };
        }

        case actionTypes.GET_APPOINTMENT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                appointment: action.payload.response,
            };
        }

        case actionTypes.ADD_APPOINTMENT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                appointment: action.payload.response,
            };
        }

        case actionTypes.UPDATE_APPOINTMENT_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                appointment: action.payload.response,
            };
        }

        case actionTypes.DELETE_APPOINTMENT_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_APPOINTMENT_SUCCESS: {
            const appointment = action.payload.response;
            return { ...getRemovedServiceListState(appointment.id, state), appointment };
        }

        case actionTypes.GET_APPOINTMENT_RESOURCES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_APPOINTMENT_RESOURCES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                appointmentResourcesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_APPOINTMENT_RESOURCE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_APPOINTMENT_RESOURCE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.UPDATE_APPOINTMENT_RESOURCE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_APPOINTMENT_RESOURCE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.DELETE_APPOINTMENT_RESOURCE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_APPOINTMENT_RESOURCE_SUCCESS: {
            const removedResource = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.appointmentResourcesPagination.data,
                "id",
                removedResource.id
            );
            return {
                ...state,
                isLoading: false,
                appointmentResourcesPagination: {
                    ...state.appointmentResourcesPagination,
                    data: alteredStateArray,
                    totalRows: state.appointmentResourcesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_APPOINTMENT_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_APPOINTMENT_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                appointmentBookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_COURSE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_COURSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                course: action.payload.response,
            };
        }

        case actionTypes.ADD_COURSE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_COURSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                course: action.payload.response,
            };
        }

        case actionTypes.UPDATE_COURSE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_COURSE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                course: action.payload.response,
            };
        }

        case actionTypes.DELETE_COURSE_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_COURSE_SUCCESS: {
            const course = action.payload.response;
            return { ...getRemovedServiceListState(course.id, state), course };
        }

        case actionTypes.GET_COURSE_RESOURCES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_COURSE_RESOURCES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                courseResourcesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_COURSE_RESOURCE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_COURSE_RESOURCE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.DELETE_COURSE_RESOURCE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_COURSE_RESOURCE_SUCCESS: {
            const removedAttendee = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.courseResourcesPagination.data,
                "id",
                removedAttendee.id
            );
            return {
                ...state,
                isLoading: false,
                courseResourcesPagination: {
                    ...state.courseResourcesPagination,
                    data: alteredStateArray,
                    totalRows: state.courseResourcesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_COURSE_ATTENDEES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_COURSE_ATTENDEES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                courseAttendeesPagination: action.payload.response,
            };
        }

        case actionTypes.DELETE_COURSE_ATTENDEE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_COURSE_ATTENDEE_SUCCESS: {
            const removedAttendee = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.courseAttendeesPagination.data,
                "id",
                removedAttendee.id
            );
            return {
                ...state,
                isLoading: false,
                courseAttendeesPagination: {
                    ...state.courseAttendeesPagination,
                    data: alteredStateArray,
                    totalRows: state.courseAttendeesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_COURSE_OCCASIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_COURSE_OCCASIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                courseOccasionsPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_COURSE_OCCASIONS_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_COURSE_OCCASIONS_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.GET_COURSE_WAITING_LIST_MEMBERS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_COURSE_WAITING_LIST_MEMBERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                waitingListMembersPagination: action.payload.response,
            };
        }

        case actionTypes.ACCEPT_WAITING_LIST_MEMBER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ACCEPT_WAITING_LIST_MEMBER_SUCCESS: {
            const waitingListMember = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.waitingListMembersPagination.data,
                "id",
                waitingListMember.id
            );
            return {
                ...state,
                isLoading: false,
                waitingListMembersPagination: {
                    ...state.waitingListMembersPagination,
                    data: alteredStateArray,
                    totalRows: state.waitingListMembersPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.DELETE_WAITING_LIST_MEMBER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_WAITING_LIST_MEMBER_SUCCESS: {
            const removedWaitingListMember = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.waitingListMembersPagination.data,
                "id",
                removedWaitingListMember.id
            );
            return {
                ...state,
                isLoading: false,
                waitingListMembersPagination: {
                    ...state.waitingListMembersPagination,
                    data: alteredStateArray,
                    totalRows: state.waitingListMembersPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_MEMBERSHIP_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membership: action.payload.response,
            };
        }

        case actionTypes.ADD_MEMBERSHIP_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membership: action.payload.response,
            };
        }

        case actionTypes.UPDATE_MEMBERSHIP_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                membership: action.payload.response,
            };
        }

        case actionTypes.DELETE_MEMBERSHIP_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_MEMBERSHIP_SUCCESS: {
            const membership = action.payload.response;
            return { ...getRemovedServiceListState(membership.id, state), membership };
        }

        case actionTypes.GET_MEMBERSHIP_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_MEMBERSHIP_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membershipServicesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_MEMBERSHIP_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_MEMBERSHIP_SERVICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.DELETE_MEMBERSHIP_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_MEMBERSHIP_SERVICE_SUCCESS: {
            const removedOfferService = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.membershipServicesPagination.data,
                "id",
                removedOfferService.id
            );
            return {
                ...state,
                isLoading: false,
                membershipServicesPagination: {
                    ...state.membershipServicesPagination,
                    data: alteredStateArray,
                    totalRows: state.membershipServicesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_MEMBERSHIP_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_MEMBERSHIP_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membershipBookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_VOUCHER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_VOUCHER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucher: action.payload.response,
            };
        }

        case actionTypes.ADD_VOUCHER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_VOUCHER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucher: action.payload.response,
            };
        }

        case actionTypes.UPDATE_VOUCHER_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_VOUCHER_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                voucher: action.payload.response,
            };
        }

        case actionTypes.DELETE_VOUCHER_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_VOUCHER_SUCCESS: {
            const voucher = action.payload.response;
            return { ...getRemovedServiceListState(voucher.id, state), voucher };
        }

        case actionTypes.GET_VOUCHER_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_VOUCHER_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucherServicesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_VOUCHER_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_VOUCHER_SERVICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.DELETE_VOUCHER_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_VOUCHER_SERVICE_SUCCESS: {
            const removedOfferService = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.voucherServicesPagination.data,
                "id",
                removedOfferService.id
            );
            return {
                ...state,
                isLoading: false,
                voucherServicesPagination: {
                    ...state.voucherServicesPagination,
                    data: alteredStateArray,
                    totalRows: state.voucherServicesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_VOUCHER_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_VOUCHER_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucherBookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PRODUCT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                product: action.payload.response,
            };
        }

        case actionTypes.ADD_PRODUCT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                product: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PRODUCT_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                product: action.payload.response,
            };
        }

        case actionTypes.DELETE_PRODUCT_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_PRODUCT_SUCCESS: {
            const product = action.payload.response;
            return { ...getRemovedServiceListState(product.id, state), product };
        }

        case actionTypes.GET_SERVICES_BY_CATEGORY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICES_BY_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listByCategoryPagination: action.payload.response,
            };
        }

        case actionTypes.CHANGE_SERVICES_SORTING_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CHANGE_SERVICES_SORTING_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listByCategoryPagination: action.payload.sortedList,
            };
        }

        case actionTypes.UPDATE_SERVICES_SORTING_REQUEST: {
            return { ...state, isSortingUpdating: true };
        }

        case actionTypes.UPDATE_SERVICES_SORTING_SUCCESS: {
            return {
                ...state,
                isSortingUpdating: false,
                listByCategoryPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false, isSortingUpdating: false };
        }

        case actionTypes.CLEAR_SERVICES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

function getRemovedServiceListState(serviceId, state) {
    const alteredStateArray = findAndSpliceArrayByProperty(state.listPagination.data, "id", serviceId);
    return {
        ...state,
        isLoading: false,
        listPagination: {
            ...state.listPagination,
            data: alteredStateArray,
            totalRows: state.listPagination.totalRows - 1,
        },
    };
}

export const actions = {
    getServices: (profileId, page, perPage, search, status, type) => ({
        type: actionTypes.GET_SERVICES_REQUEST,
        payload: { profileId, page, perPage, search, status, type },
    }),

    getServicesAndCategories: (profileId, page, perPage, search, status) => ({
        type: actionTypes.GET_SERVICES_AND_CATEGORIES_REQUEST,
        payload: { profileId, page, perPage, search, status },
    }),

    getServiceResources: (serviceId, search) => ({
        type: actionTypes.GET_SERVICE_RESOURCES_REQUEST,
        payload: { serviceId, search },
    }),

    getServiceOptions: (profileId) => ({
        type: actionTypes.GET_SERVICE_OPTIONS_REQUEST,
        payload: { profileId },
    }),

    getServiceAddons: (serviceId, page, perPage, search, type) => ({
        type: actionTypes.GET_SERVICE_ADDONS_REQUEST,
        payload: { serviceId, page, perPage, search, type },
    }),

    addServiceAddon: (serviceId, addonServiceId, callback) => ({
        type: actionTypes.ADD_SERVICE_ADDON_REQUEST,
        payload: { serviceId, addonServiceId, callback },
    }),

    deleteServiceAddon: (serviceId, serviceAddonId) => ({
        type: actionTypes.DELETE_SERVICE_ADDON_REQUEST,
        payload: { serviceId, serviceAddonId },
    }),

    updateServiceVisibility: (serviceId, isVisible) => ({
        type: actionTypes.UPDATE_SERVICE_VISIBILITY_REQUEST,
        payload: { serviceId, isVisible },
    }),

    getServiceCopy: (serviceId, serviceType) => ({
        type: actionTypes.GET_SERVICE_COPY_REQUEST,
        payload: { serviceId, serviceType },
    }),

    getAppointment: (serviceId) => ({
        type: actionTypes.GET_APPOINTMENT_REQUEST,
        payload: { serviceId },
    }),

    addAppointment: (profileId, data, callback) => ({
        type: actionTypes.ADD_APPOINTMENT_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateAppointment: (id, originalAppointment, updatedAppointment) => ({
        type: actionTypes.UPDATE_APPOINTMENT_REQUEST,
        payload: { id, originalAppointment, updatedAppointment },
    }),

    deleteAppointment: (id) => ({
        type: actionTypes.DELETE_APPOINTMENT_REQUEST,
        payload: { id },
    }),

    getAppointmentResources: (id, page, perPage, status) => ({
        type: actionTypes.GET_APPOINTMENT_RESOURCES_REQUEST,
        payload: { id, page, perPage, status },
    }),

    addAppointmentResource: (id, data, callback) => ({
        type: actionTypes.ADD_APPOINTMENT_RESOURCE_REQUEST,
        payload: { id, data, callback },
    }),

    updateAppointmentResource: (
        serviceId,
        serviceResourceId,
        originalAppointmentResource,
        updatedAppointmentResource,
        callback
    ) => ({
        type: actionTypes.UPDATE_APPOINTMENT_RESOURCE_REQUEST,
        payload: { serviceId, serviceResourceId, originalAppointmentResource, updatedAppointmentResource, callback },
    }),

    deleteAppointmentResource: (serviceId, serviceResourceId) => ({
        type: actionTypes.DELETE_APPOINTMENT_RESOURCE_REQUEST,
        payload: { serviceId, serviceResourceId },
    }),

    getAppointmentBookings: (id, page, perPage, status) => ({
        type: actionTypes.GET_APPOINTMENT_BOOKINGS_REQUEST,
        payload: { id, page, perPage, status },
    }),

    getCourse: (serviceId) => ({
        type: actionTypes.GET_COURSE_REQUEST,
        payload: { serviceId },
    }),

    addCourse: (profileId, data, callback) => ({
        type: actionTypes.ADD_COURSE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateCourse: (id, originalCourse, updatedCourse) => ({
        type: actionTypes.UPDATE_COURSE_REQUEST,
        payload: { id, originalCourse, updatedCourse },
    }),

    deleteCourse: (id) => ({
        type: actionTypes.DELETE_COURSE_REQUEST,
        payload: { id },
    }),

    getCourseResources: (id, page, perPage, status) => ({
        type: actionTypes.GET_COURSE_RESOURCES_REQUEST,
        payload: { id, page, perPage, status },
    }),

    addCourseResource: (id, data, callback) => ({
        type: actionTypes.ADD_COURSE_RESOURCE_REQUEST,
        payload: { id, data, callback },
    }),

    deleteCourseResource: (serviceId, attendeeId) => ({
        type: actionTypes.DELETE_COURSE_RESOURCE_REQUEST,
        payload: { serviceId, attendeeId },
    }),

    getCourseAttendees: (id, page, perPage, status) => ({
        type: actionTypes.GET_COURSE_ATTENDEES_REQUEST,
        payload: { id, page, perPage, status },
    }),

    deleteCourseAttendee: (serviceId, attendeeId) => ({
        type: actionTypes.DELETE_COURSE_ATTENDEE_REQUEST,
        payload: { serviceId, attendeeId },
    }),

    getCourseOccasions: (id, page, perPage, status) => ({
        type: actionTypes.GET_COURSE_OCCASIONS_REQUEST,
        payload: { id, page, perPage, status },
    }),

    addCourseOccasions: (id, occasion, callback) => ({
        type: actionTypes.ADD_COURSE_OCCASIONS_REQUEST,
        payload: { id, occasion, callback },
    }),

    getWaitingListMembers: (id, page, perPage) => ({
        type: actionTypes.GET_COURSE_WAITING_LIST_MEMBERS_REQUEST,
        payload: { id, page, perPage },
    }),

    acceptWaitingListMember: (waitingListMemberId, isMarkedAsPaid, callback) => ({
        type: actionTypes.ACCEPT_WAITING_LIST_MEMBER_REQUEST,
        payload: { waitingListMemberId, isMarkedAsPaid, callback },
    }),

    deleteWaitingListMember: (waitingListMemberId) => ({
        type: actionTypes.DELETE_WAITING_LIST_MEMBER_REQUEST,
        payload: { waitingListMemberId },
    }),

    getMembership: (serviceId) => ({
        type: actionTypes.GET_MEMBERSHIP_REQUEST,
        payload: { serviceId },
    }),

    addMembership: (profileId, data, callback) => ({
        type: actionTypes.ADD_MEMBERSHIP_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateMembership: (id, originalMembership, updatedMembership) => ({
        type: actionTypes.UPDATE_MEMBERSHIP_REQUEST,
        payload: { id, originalMembership, updatedMembership },
    }),

    deleteMembership: (id) => ({
        type: actionTypes.DELETE_MEMBERSHIP_REQUEST,
        payload: { id },
    }),

    getMembershipServices: (id, page, perPage) => ({
        type: actionTypes.GET_MEMBERSHIP_SERVICES_REQUEST,
        payload: { id, page, perPage },
    }),

    addMembershipService: (id, data, callback) => ({
        type: actionTypes.ADD_MEMBERSHIP_SERVICE_REQUEST,
        payload: { id, data, callback },
    }),

    deleteMembershipService: (serviceId, offerServiceId) => ({
        type: actionTypes.DELETE_MEMBERSHIP_SERVICE_REQUEST,
        payload: { serviceId, offerServiceId },
    }),

    getMembershipBookings: (id, page, perPage) => ({
        type: actionTypes.GET_MEMBERSHIP_BOOKINGS_REQUEST,
        payload: { id, page, perPage },
    }),

    getVoucher: (serviceId) => ({
        type: actionTypes.GET_VOUCHER_REQUEST,
        payload: { serviceId },
    }),

    addVoucher: (profileId, data, callback) => ({
        type: actionTypes.ADD_VOUCHER_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateVoucher: (id, originalVoucher, updatedVoucher) => ({
        type: actionTypes.UPDATE_VOUCHER_REQUEST,
        payload: { id, originalVoucher, updatedVoucher },
    }),

    deleteVoucher: (id) => ({
        type: actionTypes.DELETE_VOUCHER_REQUEST,
        payload: { id },
    }),

    getVoucherServices: (id, page, perPage) => ({
        type: actionTypes.GET_VOUCHER_SERVICES_REQUEST,
        payload: { id, page, perPage },
    }),

    addVoucherService: (id, data, callback) => ({
        type: actionTypes.ADD_VOUCHER_SERVICE_REQUEST,
        payload: { id, data, callback },
    }),

    deleteVoucherService: (serviceId, offerServiceId) => ({
        type: actionTypes.DELETE_VOUCHER_SERVICE_REQUEST,
        payload: { serviceId, offerServiceId },
    }),

    getVoucherBookings: (id, page, perPage) => ({
        type: actionTypes.GET_VOUCHER_BOOKINGS_REQUEST,
        payload: { id, page, perPage },
    }),

    getProduct: (serviceId) => ({
        type: actionTypes.GET_PRODUCT_REQUEST,
        payload: { serviceId },
    }),

    addProduct: (profileId, data, callback) => ({
        type: actionTypes.ADD_PRODUCT_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateProduct: (id, originalProduct, updatedProduct) => ({
        type: actionTypes.UPDATE_PRODUCT_REQUEST,
        payload: { id, originalProduct, updatedProduct },
    }),

    deleteProduct: (id) => ({
        type: actionTypes.DELETE_PRODUCT_REQUEST,
        payload: { id },
    }),

    getServicesByCategory: (profileId, page, perPage, search, status) => ({
        type: actionTypes.GET_SERVICES_BY_CATEGORY_REQUEST,
        payload: { profileId, page, perPage, search, status },
    }),

    changeServiceSorting: (item, direction, level) => ({
        type: actionTypes.CHANGE_SERVICES_SORTING_REQUEST,
        payload: { item, direction, level },
    }),

    updateServiceListSorting: (profileId) => ({
        type: actionTypes.UPDATE_SERVICES_SORTING_REQUEST,
        payload: { profileId },
    }),

    clearServiceState: () => ({
        type: actionTypes.CLEAR_SERVICES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServices(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.type
            );
            yield put({
                type: actionTypes.GET_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICES_AND_CATEGORIES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServicesAndCategories(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status
            );
            yield put({
                type: actionTypes.GET_SERVICES_AND_CATEGORIES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICE_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceResources(payload.serviceId, 1, 20, payload.search);
            response.search = payload.search;
            yield put({
                type: actionTypes.GET_SERVICE_RESOURCES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put({ type: actionTypes.ERROR_SERVICES, payload: error });
        }
    });

    yield takeLatest(actionTypes.GET_SERVICE_OPTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceOptions(payload.profileId);
            yield put({
                type: actionTypes.GET_SERVICE_OPTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICE_ADDONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceAddons(
                payload.serviceId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.type
            );
            yield put({
                type: actionTypes.GET_SERVICE_ADDONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_SERVICE_ADDON_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addServiceAddon(payload.serviceId, { serviceId: payload.addonServiceId });

            yield put({
                type: actionTypes.ADD_SERVICE_ADDON_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_SERVICE_ADDON_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteServiceAddon(payload.serviceId, payload.serviceAddonId);

            yield put({
                type: actionTypes.DELETE_SERVICE_ADDON_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_SERVICE_VISIBILITY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateServiceVisibility(payload.serviceId, payload.isVisible);
            yield put({
                type: actionTypes.UPDATE_SERVICE_VISIBILITY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICE_COPY_REQUEST, function* ({ payload }) {
        try {
            let copiedService = null;
            if (payload.serviceType === SERVICE_TYPE_APPOINTMENT) {
                const apiResponse = yield getAppointment(payload.serviceId);
                copiedService = apiResponse.data;
            } else if (payload.serviceType === SERVICE_TYPE_COURSE) {
                const apiResponse = yield getCourse(payload.serviceId);
                copiedService = apiResponse.data;
            } else if (payload.serviceType === SERVICE_TYPE_VOUCHER) {
                const apiResponse = yield getVoucher(payload.serviceId);
                copiedService = apiResponse.data;
            } else if (payload.serviceType === SERVICE_TYPE_MEMBERSHIP) {
                const apiResponse = yield getMembership(payload.serviceId);
                copiedService = apiResponse.data;
            } else if (payload.serviceType === SERVICE_TYPE_PRODUCT) {
                const apiResponse = yield getProduct(payload.serviceId);
                copiedService = apiResponse.data;
            }

            yield put({
                type: actionTypes.GET_SERVICE_COPY_SUCCESS,
                payload: { response: copiedService },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getAppointment(payload.serviceId);
            yield put({
                type: actionTypes.GET_APPOINTMENT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addAppointment(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_APPOINTMENT_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchAppointment(payload.id, payload.originalAppointment, {
                ...payload.originalAppointment,
                ...payload.updatedAppointment,
            });
            yield put({
                type: actionTypes.UPDATE_APPOINTMENT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteAppointment(payload.id);
            yield put({
                type: actionTypes.DELETE_APPOINTMENT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_APPOINTMENT_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getAppointmentResources(
                payload.id,
                payload.page,
                payload.perPage,
                payload.status
            );

            yield put({
                type: actionTypes.GET_APPOINTMENT_RESOURCES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_APPOINTMENT_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addAppointmentResource(payload.id, payload.data);

            yield put({
                type: actionTypes.ADD_APPOINTMENT_RESOURCE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_APPOINTMENT_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchAppointmentResource(
                payload.serviceId,
                payload.serviceResourceId,
                payload.originalAppointmentResource,
                {
                    ...payload.originalAppointmentResource,
                    ...payload.updatedAppointmentResource,
                }
            );
            yield put({
                type: actionTypes.UPDATE_APPOINTMENT_RESOURCE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            console.error.log(error);
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_APPOINTMENT_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteAppointmentResource(payload.serviceId, payload.serviceResourceId);

            yield put({
                type: actionTypes.DELETE_APPOINTMENT_RESOURCE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_APPOINTMENT_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getAppointmentBookings(
                payload.id,
                payload.page,
                payload.perPage,
                payload.status
            );

            yield put({
                type: actionTypes.GET_APPOINTMENT_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_COURSE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCourse(payload.serviceId);
            yield put({
                type: actionTypes.GET_COURSE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_COURSE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCourse(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_COURSE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_COURSE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchCourse(payload.id, payload.originalCourse, {
                ...payload.originalCourse,
                ...payload.updatedCourse,
            });
            yield put({
                type: actionTypes.UPDATE_COURSE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_COURSE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteCourse(payload.id);
            yield put({
                type: actionTypes.DELETE_COURSE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_COURSE_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCourseResources(
                payload.id,
                payload.page,
                payload.perPage,
                payload.status
            );

            yield put({
                type: actionTypes.GET_COURSE_RESOURCES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_COURSE_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCourseResource(payload.id, payload.data);

            yield put({
                type: actionTypes.ADD_COURSE_RESOURCE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_COURSE_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteCourseResource(payload.serviceId, payload.attendeeId);

            yield put({
                type: actionTypes.DELETE_COURSE_RESOURCE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_COURSE_ATTENDEES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCourseAttendees(
                payload.id,
                payload.page,
                payload.perPage,
                payload.status
            );

            yield put({
                type: actionTypes.GET_COURSE_ATTENDEES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_COURSE_ATTENDEE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteCourseAttendee(payload.serviceId, payload.attendeeId);

            yield put({
                type: actionTypes.DELETE_COURSE_ATTENDEE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_COURSE_OCCASIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCourseOccasions(
                payload.id,
                payload.page,
                payload.perPage,
                payload.status
            );

            yield put({
                type: actionTypes.GET_COURSE_OCCASIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put({ type: actionTypes.ERROR_SERVICES, payload: error });
        }
    });

    yield takeLatest(actionTypes.ADD_COURSE_OCCASIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCourseOccasions(payload.id, payload.occasion);

            yield put({
                type: actionTypes.ADD_COURSE_OCCASIONS_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_COURSE_WAITING_LIST_MEMBERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getWaitingListMembers(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_COURSE_WAITING_LIST_MEMBERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ACCEPT_WAITING_LIST_MEMBER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield acceptWaitingListMember(payload.waitingListMemberId, {
                isMarkedAsPaid: payload.isMarkedAsPaid,
            });

            yield put({
                type: actionTypes.ACCEPT_WAITING_LIST_MEMBER_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_WAITING_LIST_MEMBER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteWaitingListMember(payload.waitingListMemberId);

            yield put({
                type: actionTypes.DELETE_WAITING_LIST_MEMBER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getMembership(payload.serviceId);
            yield put({
                type: actionTypes.GET_MEMBERSHIP_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addMembership(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_MEMBERSHIP_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchMembership(payload.id, payload.originalMembership, {
                ...payload.originalMembership,
                ...payload.updatedMembership,
            });
            yield put({
                type: actionTypes.UPDATE_MEMBERSHIP_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteMembership(payload.id);
            yield put({
                type: actionTypes.DELETE_MEMBERSHIP_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_MEMBERSHIP_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getMembershipServices(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_MEMBERSHIP_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_MEMBERSHIP_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addMembershipService(payload.id, payload.data);

            yield put({
                type: actionTypes.ADD_MEMBERSHIP_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_MEMBERSHIP_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteMembershipService(payload.serviceId, payload.offerServiceId);

            yield put({
                type: actionTypes.DELETE_MEMBERSHIP_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_MEMBERSHIP_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getMembershipBookings(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_MEMBERSHIP_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getVoucher(payload.serviceId);
            yield put({
                type: actionTypes.GET_VOUCHER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addVoucher(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_VOUCHER_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchVoucher(payload.id, payload.originalVoucher, {
                ...payload.originalVoucher,
                ...payload.updatedVoucher,
            });
            yield put({
                type: actionTypes.UPDATE_VOUCHER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteVoucher(payload.id);
            yield put({
                type: actionTypes.DELETE_VOUCHER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_VOUCHER_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getVoucherServices(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_VOUCHER_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_VOUCHER_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addVoucherService(payload.id, payload.data);

            yield put({
                type: actionTypes.ADD_VOUCHER_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_VOUCHER_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteVoucherService(payload.serviceId, payload.offerServiceId);

            yield put({
                type: actionTypes.DELETE_VOUCHER_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_VOUCHER_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getVoucherBookings(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_VOUCHER_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PRODUCT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProduct(payload.serviceId);
            yield put({
                type: actionTypes.GET_PRODUCT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PRODUCT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addProduct(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PRODUCT_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PRODUCT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchProduct(payload.id, payload.originalProduct, {
                ...payload.originalProduct,
                ...payload.updatedProduct,
            });
            yield put({
                type: actionTypes.UPDATE_PRODUCT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PRODUCT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteProduct(payload.id);
            yield put({
                type: actionTypes.DELETE_PRODUCT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICES_BY_CATEGORY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServices(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status
            );

            const categoryData = restructureServiceListToCategories(response.data);

            yield put({
                type: actionTypes.GET_SERVICES_BY_CATEGORY_SUCCESS,
                payload: { response: { ...response, data: categoryData } },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CHANGE_SERVICES_SORTING_REQUEST, function* ({ payload }) {
        try {
            const state = yield select();
            let serviceListPagination = state.services.listByCategoryPagination;
            let serviceListData = [...serviceListPagination.data];
            serviceListPagination.data = serviceListData;

            if (payload.level === 1) {
                // Top level item
                let itemToMoveIndex;
                if (payload.item.categoryId) {
                    itemToMoveIndex = serviceListData.findIndex((x) => x.categoryId === payload.item.categoryId);
                } else {
                    itemToMoveIndex = serviceListData.findIndex((x) => x.serviceId === payload.item.serviceId);
                }

                if (itemToMoveIndex > -1) {
                    const moveToIndex = payload.direction === "up" ? itemToMoveIndex - 1 : itemToMoveIndex + 1;
                    if (moveToIndex > -1 && moveToIndex < serviceListData.length) {
                        const element = serviceListData[itemToMoveIndex];
                        serviceListData.splice(itemToMoveIndex, 1);
                        serviceListData.splice(moveToIndex, 0, element);
                    }
                }
            } else {
                // Nested level (service in category)
                const categoryIndex = serviceListData.findIndex((x) => x.categoryId === payload.item.categoryId);
                const category = serviceListData[categoryIndex];
                const itemToMoveIndex = category.services.findIndex((x) => x.id === payload.item.serviceId);

                if (itemToMoveIndex > -1) {
                    const moveToIndex = payload.direction === "up" ? itemToMoveIndex - 1 : itemToMoveIndex + 1;
                    if (moveToIndex > -1 && moveToIndex < category.services.length) {
                        const element = category.services[itemToMoveIndex];
                        category.services.splice(itemToMoveIndex, 1);
                        category.services.splice(moveToIndex, 0, element);
                    }
                }
            }

            yield put({
                type: actionTypes.CHANGE_SERVICES_SORTING_SUCCESS,
                payload: { sortedList: serviceListPagination },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_SERVICES_SORTING_REQUEST, function* ({ payload }) {
        try {
            const state = yield select();
            let serviceListPagination = state.services.listByCategoryPagination;
            let serviceListData = [...serviceListPagination.data];

            let requestData = {
                items: [],
            };
            for (let i = 0; i < serviceListData.length; i++) {
                var sortItem = serviceListData[i];
                if (sortItem.categoryId) {
                    requestData.items.push({
                        category: { id: sortItem.categoryId, serviceIds: sortItem.services.map((x) => x.id) },
                    });
                } else {
                    requestData.items.push({ serviceId: sortItem.serviceId });
                }
            }

            const { data: response } = yield updateServiceListSorting(payload.profileId, requestData);
            const categoryData = restructureServiceListToCategories(response.data);

            yield put({
                type: actionTypes.UPDATE_SERVICES_SORTING_SUCCESS,
                payload: { response: { ...response, data: categoryData } },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}

function restructureServiceListToCategories(serviceList) {
    // Change structure of service list to a category based one
    const categoryData = [];
    for (let i = 0; i < serviceList.length; i++) {
        const service = serviceList[i];
        const category = service.category;
        if (!category) {
            categoryData.push({
                serviceId: service.id,
                name: service.name,
                serviceType: service.type,
                startTime: service.startTime,
            });
            continue;
        }

        const existingCategory = categoryData.find((x) => x.categoryId === category.id);
        if (existingCategory) {
            existingCategory.services.push({
                id: service.id,
                name: service.name,
                serviceType: service.type,
                startTime: service.startTime,
            });
        } else {
            let newCategory = {
                categoryId: category.id,
                name: category.name,
                services: [],
            };
            newCategory.services.push({
                id: service.id,
                name: service.name,
                serviceType: service.type,
                startTime: service.startTime,
            });
            categoryData.push(newCategory);
        }
    }

    return categoryData;
}
