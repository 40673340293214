import axios from "axios";
import { getApiUrl } from "../../../app/utils/ApiUtils";

export const INTEGRATIONS_URL = "v1/admin/profiles/{profileId}/integrations";
export const ACCESS_DEVICES_URL = "v1/admin/integrations/{id}/accessy/access-devices";
export const ACCESS_DEVICE_SERVICE_URL = "v1/admin/integrations/{id}/accessy/access-devices-services";
export const ACCESS_DEVICE_SERVICE_DETAILS_URL = "v1/admin/accessy/access-devices-services/{id}";

export function createAccessyIntegration(profileId, clientId, clientSecret, organizationId) {
    let url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "/accessy";
    return axios.post(getApiUrl() + url, { clientId, clientSecret, organizationId });
}

export function getAccessyAccessDevices(id) {
    let url = ACCESS_DEVICES_URL.replace("{id}", id);
    return axios.get(getApiUrl() + url);
}

export function createAccessyAccessDeviceService(
    integrationId,
    externalId,
    serviceId,
    isAllResources,
    resourceIds,
    prefixSeconds,
    postfixSeconds,
    messageToCustomer
) {
    let url = ACCESS_DEVICE_SERVICE_URL.replace("{id}", integrationId);
    return axios.post(getApiUrl() + url, {
        externalId,
        serviceId,
        isAllResources,
        resourceIds,
        prefixSeconds,
        postfixSeconds,
        messageToCustomer,
    });
}

export function updateAccessDeviceService(
    id,
    serviceId,
    isAllResources,
    resourceIds,
    prefixSeconds,
    postfixSeconds,
    messageToCustomer
) {
    let url = ACCESS_DEVICE_SERVICE_DETAILS_URL.replace("{id}", id);
    return axios.put(getApiUrl() + url, {
        serviceId,
        isAllResources,
        resourceIds,
        prefixSeconds,
        postfixSeconds,
        messageToCustomer,
    });
}

export function deleteAccessyAccessDeviceService(id) {
    let url = ACCESS_DEVICE_SERVICE_DETAILS_URL.replace("{id}", id);
    return axios.delete(getApiUrl() + url);
}
