import React, { useState, useMemo, useCallback } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell, ChipCell } from "../../../components/tables/MaterialTableCells";
import { COURSE_OCCASION_STATUS_ACTIVE, COURSE_OCCASION_STATUS_CANCELLED } from "../utils";
import CourseOccasionAddModal from "./CourseOccasionAddModal";
import { ENTRY_TYPE_COURSE_OCCASION } from "../../bookings/entryUtils";
import CourseOccasionListToolbar from "../../../components/tables/toolbar/CourseOccasionListToolbar";

function CoursePageOccasions({ getCourseOccasions, addCourseOccasions, getEntry, showDrawerEntry }) {
    const { course, courseOccasionsPagination, isLoading, isUpdating } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const serviceId = course?.id;

    const [showModalAddOccasion, setShowModalAddOccasion] = useState(false);
    const [status, setStatus] = useState("upcoming");

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getCourseOccasions(serviceId, pageIndex + 1, pageSize, status);
            }
        },
        [serviceId, getCourseOccasions, status]
    );

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.OCCASIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.COURSE.OCCASIONS.SUBTITLE" />
                        </span>
                    </div>
                    {course && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddOccasion(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceId}
                        data={useMemo(() => courseOccasionsPagination.data, [courseOccasionsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={courseOccasionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        renderToolbar={() => {
                            return <CourseOccasionListToolbar onStatusChanged={onStatusChanged} />;
                        }}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        return (
                                            <Typography
                                                variant="body1"
                                                style={{ color: "#0073e9", cursor: "pointer" }}
                                                onClick={() => {
                                                    const occasion = row.original;
                                                    const entry = {
                                                        id: occasion.id,
                                                        type: ENTRY_TYPE_COURSE_OCCASION,
                                                        color: occasion.color,
                                                        startTime: occasion.startTime,
                                                        endTime: occasion.endTime,
                                                    };
                                                    getEntry(entry);
                                                    showDrawerEntry(entry);
                                                }}
                                            >
                                                {row.original.id}
                                            </Typography>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.START_TIME" />,
                                    accessor: "startTime",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.END_TIME" />,
                                    accessor: "endTime",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.ATTENDEES" />,
                                    accessor: "attendeeCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.MAX_ATTENDEES" />
                                    ),
                                    accessor: "maxAttendeeCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.WAITING_LIST_MEMBERS" />
                                    ),
                                    accessor: "waitingListMemberCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.OCCASIONS.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === COURSE_OCCASION_STATUS_ACTIVE) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="SERVICE.COURSE.OCCASIONS.STATUS.ACTIVE" />
                                            );
                                        } else if (props.value === COURSE_OCCASION_STATUS_CANCELLED) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="SERVICE.COURSE.OCCASIONS.STATUS.CANCELLED" />
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                            ],
                            [getEntry, showDrawerEntry]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {showModalAddOccasion && (
                <CourseOccasionAddModal
                    show={showModalAddOccasion}
                    onCloseClicked={() => setShowModalAddOccasion(false)}
                    onOccasionAdded={(occasionData) => {
                        setShowModalAddOccasion(false);
                        occasionData.startTime = occasionData.startTime.format();
                        occasionData.endTime = occasionData.endTime.format();
                        if (occasionData.repeatUntil) {
                            occasionData.repeatUntil = occasionData.repeatUntil.format();
                        }
                        addCourseOccasions(serviceId, occasionData, () => {
                            fetchData({ pageIndex: 0, pageSize: 10 });
                        });
                    }}
                />
            )}
        </Card>
    );
}

export default connect(null, {
    ...serviceActions,
    ...bookingManagerActions,
    ...errorActions,
})(CoursePageOccasions);
