import React from "react";
import { actions as paymentMethodActions } from "../../../redux/paymentmethods/paymentMethodRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { TYPE_INVOICE, TYPE_CARD } from "./utils";

const schema = yup.object().shape({
    email: yup.string(),
});

function PaymentMethodPageDetails({ updatePaymentMethod }) {
    const { paymentMethod, isUpdating } = useSelector((state) => state.paymentMethods);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        let originalPaymentMethod = { ...paymentMethod };

        let updatedPaymentMethod = { ...paymentMethod };
        updatedPaymentMethod.email = values.email;

        updatePaymentMethod(paymentMethod.id, originalPaymentMethod, updatedPaymentMethod);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT_METHOD.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT_METHOD.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {paymentMethod && !paymentMethod?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-2 col-form-label">
                                <FormattedMessage id="PAYMENT_METHOD.DETAILS.EMAIL" />
                            </label>
                            <div className="col-lg-12 col-xl-5">
                                <div>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        className="form-control-lg"
                                        ref={register}
                                        isInvalid={errors.email}
                                        defaultValue={paymentMethod?.email}
                                    />
                                    {(paymentMethod?.type === TYPE_INVOICE || paymentMethod?.type === TYPE_CARD) && (
                                        <Form.Text className="text-muted">
                                            {paymentMethod.type === TYPE_INVOICE ? (
                                                <FormattedMessage id="PAYMENT_METHOD.DETAILS.EMAIL_DESCRIPTION_INVOICE" />
                                            ) : (
                                                <FormattedMessage id="PAYMENT_METHOD.DETAILS.EMAIL_DESCRIPTION_CARD" />
                                            )}
                                        </Form.Text>
                                    )}
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, paymentMethodActions)(PaymentMethodPageDetails);
