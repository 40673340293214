import React from "react";
import { connect, useSelector } from "react-redux";
import { actions as bankIDActions } from "../../../../redux/integrations/bankid/bankIDRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router";
import SnackbarError from "../../../components/SnackbarError";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    alertContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

function BankIDCreatePage({ createBankIDIntegration, clearDisplayError }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, redirectToDetails } = useSelector((state) => state.bankid);
    const { displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    if (redirectToDetails) {
        return <Redirect to={"/integrations/bankid/" + integration.id} />;
    }

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.BANKID.CREATE.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.BANKID.CREATE.DESCRIPTION" />
                </Typography>
                <Alert severity="info" className={classes.alertContainer}>
                    <FormattedMessage id="INTEGRATION.BANKID.CREATE.ALERT_INFO" />
                </Alert>
                <Button variant="primary" onClick={() => createBankIDIntegration(profile.id)}>
                    <FormattedMessage id="COMMON.ADD" />
                </Button>
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, { ...bankIDActions, ...errorActions })(BankIDCreatePage);
