import React, { useState, useMemo, useCallback, useEffect } from "react";
import { actions as parakeyActions } from "../../../../redux/integrations/parakey/parakeyRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    Typography,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import EditKeyServiceIcon from "@material-ui/icons/EditOutlined";
import DeleteKeyServiceIcon from "@material-ui/icons/DeleteOutlined";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import AccessDeviceServiceModal from "../AccessDeviceServiceModal";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 40px 0px 40px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemStyled = withStyles({
    gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
})(ListItem);

const KeyAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const KeyAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        justifyContent: "space-between",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

function ParakeyPageAccesses({
    getParakeyAccesses,
    getServices,
    getServiceResources,
    createParakeyKeyService,
    updateParakeyKeyService,
    deleteParakeyKeyService,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { integration, keyServicesPagination, isLoading } = useSelector((state) => state.parakey);
    const { listPagination: serviceListPagination, serviceResourcesPagination } = useSelector(
        (state) => state.services
    );
    const { displayError } = useSelector((state) => state.errors);
    const intl = useIntl();
    const integrationId = integration?.id;

    const [managingAccessDevice, setManagingAccessDevice] = useState(null);
    const [managingAccessDeviceService, setManagingAccessDeviceService] = useState(null);
    const [deletingAccessDeviceService, setDeletingAccessDeviceService] = useState(null);

    useEffect(() => {
        getServices(profile.id, 1, 9999, null, "adminBookable");
    }, [getServices, profile.id]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (integrationId) {
                getParakeyAccesses(integrationId, pageIndex + 1, pageSize);
            }
        },
        [integrationId, getParakeyAccesses]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="INTEGRATION.PARAKEY.ACCESSES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="INTEGRATION.PARAKEY.ACCESSES.DESCRIPTION" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={integrationId}
                        data={useMemo(() => keyServicesPagination.data, [keyServicesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={keyServicesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="INTEGRATION.PARAKEY.ACCESSES.LIST.HEADER" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        const accessDevice = row.original;
                                        const accessDeviceServices = accessDevice.deviceServices || [];
                                        const serviceCount = accessDeviceServices.length;
                                        const isApiEnabled = accessDevice.apiEnabled;
                                        return (
                                            <KeyAccordionStyled elevation={0} variant={"elevation"}>
                                                <KeyAccordionSummaryStyled
                                                    expandIcon={serviceCount > 0 ? <ExpandMoreIcon /> : null}
                                                >
                                                    <Typography variant="subtitle1">{`${accessDevice.name}${
                                                        isApiEnabled
                                                            ? ""
                                                            : " " +
                                                              intl.formatMessage({
                                                                  id: "INTEGRATION.PARAKEY.ACCESSES.NOT_API_ENABLED",
                                                              })
                                                    }`}</Typography>

                                                    <div>
                                                        {serviceCount > 0 && (
                                                            <ChipSuccess
                                                                label={
                                                                    <FormattedMessage
                                                                        id={
                                                                            serviceCount === 1
                                                                                ? "INTEGRATION.PARAKEY.ACCESSES.SERVICE_COUNT_SINGLE"
                                                                                : "INTEGRATION.PARAKEY.ACCESSES.SERVICE_COUNT_MULTIPLE"
                                                                        }
                                                                        values={{ serviceCount }}
                                                                    />
                                                                }
                                                                style={{ marginRight: "8px" }}
                                                                size="small"
                                                            />
                                                        )}
                                                        {isApiEnabled && (
                                                            <IconButton
                                                                onClick={(event) => {
                                                                    setManagingAccessDevice(accessDevice);
                                                                    event.stopPropagation();
                                                                }}
                                                            >
                                                                <AddIcon fontSize="medium" />
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                </KeyAccordionSummaryStyled>
                                                <AccordionDetailsStyled>
                                                    <ListStyled>
                                                        {accessDeviceServices.map((x, i, items) => {
                                                            return (
                                                                <div
                                                                    key={accessDevice.id + "_" + x.service.id}
                                                                    style={{
                                                                        marginBottom:
                                                                            i === items.length - 1 ? "8px" : "0px",
                                                                    }}
                                                                >
                                                                    <ListItemStyled>
                                                                        <ListItemText
                                                                            primary={x.service.name}
                                                                            primaryTypographyProps={{
                                                                                variant: "body1",
                                                                            }}
                                                                        ></ListItemText>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setDeletingAccessDeviceService(x);
                                                                            }}
                                                                            style={{ marginRight: "8px" }}
                                                                        >
                                                                            <DeleteKeyServiceIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setManagingAccessDevice(accessDevice);
                                                                                setManagingAccessDeviceService(x);
                                                                            }}
                                                                        >
                                                                            <EditKeyServiceIcon />
                                                                        </IconButton>
                                                                    </ListItemStyled>
                                                                    {i < items.length - 1 && <Divider />}
                                                                </div>
                                                            );
                                                        })}
                                                    </ListStyled>
                                                </AccordionDetailsStyled>
                                            </KeyAccordionStyled>
                                        );
                                    },
                                },
                            ],
                            [intl]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {managingAccessDevice && (
                <AccessDeviceServiceModal
                    show={managingAccessDevice ? true : false}
                    managingAccessDevice={managingAccessDevice}
                    managingAccessDeviceService={managingAccessDeviceService}
                    services={serviceListPagination.data}
                    serviceResourcesPagination={serviceResourcesPagination}
                    getServiceResources={getServiceResources}
                    showMessageToCustomer={false}
                    isLoading={isLoading}
                    onCloseClicked={() => {
                        setManagingAccessDevice(null);
                        setManagingAccessDeviceService(null);
                    }}
                    onSaveClicked={(argManagingAccessDevice, argManagingAccessDeviceService, data) => {
                        if (!argManagingAccessDeviceService) {
                            createParakeyKeyService(
                                parseInt(integrationId, 10),
                                argManagingAccessDevice.externalId,
                                data,
                                () => {
                                    setManagingAccessDevice(null);
                                    setManagingAccessDeviceService(null);
                                }
                            );
                        } else {
                            updateParakeyKeyService(argManagingAccessDeviceService.id, data, () => {
                                setManagingAccessDevice(null);
                                setManagingAccessDeviceService(null);
                            });
                        }
                    }}
                />
            )}
            <YesNoDialog
                title={<FormattedMessage id="INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.DELETE.TITLE" />}
                open={deletingAccessDeviceService ? true : false}
                onOpenChanged={() => setDeletingAccessDeviceService(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteParakeyKeyService(deletingAccessDeviceService.id);
                    }
                    setDeletingAccessDeviceService(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...parakeyActions, ...serviceActions, ...errorActions })(ParakeyPageAccesses);
