/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkMenuItemIsActive } from "../../../../_helpers";
import { FormattedMessage } from "react-intl";

function getMenuItemActive(location, url, menuLevel, hasSubmenu = false) {
    return checkMenuItemIsActive(location, url, menuLevel)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
}

export default function AsideMenuListAdmin({ layoutProps, location }) {
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/overview", 1)}`}>
                    <NavLink className="menu-link" to="/overview">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.OVERVIEW" />
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/bookings", 1, true)}`}>
                    <NavLink className="menu-link" to="/bookings/calendar">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.BOOKINGS" />
                        </span>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent">
                                <span className="menu-link">
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.BOOKINGS" />
                                    </span>
                                </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li className={`menu-item ${getMenuItemActive(location, "/bookings/calendar", 2)}`}>
                                <NavLink className="menu-link" to="/bookings/calendar">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.BOOKINGS.CALENDAR" />
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`menu-item ${getMenuItemActive(location, "/bookings/list", 2)}`}>
                                <NavLink className="menu-link" to="/bookings/list">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.BOOKINGS.BOOKING_LIST" />
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`menu-item ${getMenuItemActive(location, "/bookings/resources", 2)}`}>
                                <NavLink className="menu-link" to="/bookings/resources">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.BOOKINGS.RESOURCE_LIST" />
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/service", 1, true)}`}>
                    <NavLink className="menu-link" to="/services">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.SERVICES" />
                        </span>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent">
                                <span className="menu-link">
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.SERVICES" />
                                    </span>
                                </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li className={`menu-item ${getMenuItemActive(location, "/services", 2)}`}>
                                <NavLink className="menu-link" to="/services">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.SERVICES" />
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`menu-item ${getMenuItemActive(location, "/service-categories", 2)}`}>
                                <NavLink className="menu-link" to="/service-categories">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.SERVICES.CATEGORIES" />
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/resource", 1, true)}`}>
                    <NavLink className="menu-link" to="/resources">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Flatten.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.RESOURCES" />
                        </span>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent">
                                <span className="menu-link">
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.RESOURCES" />
                                    </span>
                                </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li className={`menu-item ${getMenuItemActive(location, "/resources", 2)}`}>
                                <NavLink className="menu-link" to="/resources">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.RESOURCES.RESOURCES" />
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`menu-item ${getMenuItemActive(location, "/resource-groups", 2)}`}>
                                <NavLink className="menu-link" to="/resource-groups">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.RESOURCES.GROUPS" />
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                <li className={`menu-item ${getMenuItemActive(location, "/customers", 1)}`}>
                    <NavLink className="menu-link" to="/customers">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.CUSTOMERS" />
                        </span>
                    </NavLink>
                </li>

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/transaction", 1, true)}`}>
                    <NavLink className="menu-link" to="/transactions">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.TRANSACTIONS" />
                        </span>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent">
                                <span className="menu-link">
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.TRANSACTIONS" />
                                    </span>
                                </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li className={`menu-item ${getMenuItemActive(location, "/transactions", 2)}`}>
                                <NavLink className="menu-link" to="/transactions">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.TRANSACTIONS" />
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`menu-item ${getMenuItemActive(location, "/transaction-refunds", 2)}`}>
                                <NavLink className="menu-link" to="/transaction-refunds">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">
                                        <FormattedMessage id="MENU.REFUNDS" />
                                    </span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/subscriptions", 1)}`}>
                    <NavLink className="menu-link" to="/subscriptions">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.SUBSCRIPTIONS" />
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/payouts", 1)}`}>
                    <NavLink className="menu-link" to="/payouts">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Calculator.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.PAYOUTS" />
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li className={`menu-item ${getMenuItemActive(location, "/promotion-codes", 1)}`}>
                    <NavLink className="menu-link" to="/promotion-codes">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.PROMOTIONCODES" />
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                <li className={`menu-item ${getMenuItemActive(location, "/campaigns", 1)}`}>
                    <NavLink className="menu-link" to="/campaigns">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat6.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.CAMPAIGNS" />
                        </span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive(location, "/reports", 1)}`}>
                    <NavLink className="menu-link" to="/reports">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Archive.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.REPORTS" />
                        </span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive(location, "/integrations", 1)}`}>
                    <NavLink className="menu-link" to="/integrations">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Interselect.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.INTEGRATIONS" />
                        </span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive(location, "/settings", 1)}`}>
                    <NavLink className="menu-link" to="/settings">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.SETTINGS" />
                        </span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive(location, "/widget", 1)}`}>
                    <NavLink className="menu-link" to="/widget">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Code.svg")} />
                        </span>
                        <span className="menu-text">
                            <FormattedMessage id="MENU.WIDGET" />
                        </span>
                    </NavLink>
                </li>
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
