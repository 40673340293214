import React, { useState, useMemo, useCallback } from "react";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell, LinkCell } from "../../components/tables/MaterialTableCells";
import * as transactionUtils from "../transactions/utils";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { IconButton } from "@material-ui/core";
import SubscriptionPaymentListToolbar from "../../components/tables/toolbar/SubscriptionPaymentListToolbar";
import moment from "moment";

function SubscriptionPageTransactions({ getSubscriptionTransactions, downloadTransactionPdf }) {
    const { subscription, subscriptionTransactionsPagination, isLoading } = useSelector((state) => state.subscriptions);
    const { displayError } = useSelector((state) => state.errors);
    const { isDownloadingPdf } = useSelector((state) => state.transactions);
    const subscriptionId = subscription?.id;

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("all");
    const [paymentType, setPaymentType] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (subscriptionId) {
                getSubscriptionTransactions(
                    subscriptionId,
                    pageIndex + 1,
                    pageSize,
                    search,
                    status,
                    paymentType,
                    paymentStatus
                );
            }
        },
        [subscriptionId, getSubscriptionTransactions, search, status, paymentType, paymentStatus]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    const onPaymentTypeChanged = useCallback((value) => {
        setPaymentType(value);
    }, []);

    const onPaymentStatusChanged = useCallback((value) => {
        setPaymentStatus(value);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={subscriptionId}
                        data={useMemo(() => subscriptionTransactionsPagination.data, [
                            subscriptionTransactionsPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={subscriptionTransactionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        renderToolbar={() => {
                            return (
                                <SubscriptionPaymentListToolbar
                                    onSearchChanged={onSearchChanged}
                                    onStatusChanged={onStatusChanged}
                                    onPaymentTypeChanged={onPaymentTypeChanged}
                                    onPaymentStatusChanged={onPaymentStatusChanged}
                                />
                            );
                        }}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/transactions/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        return transactionUtils.getTransactionListStatusCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_TYPE" />,
                                    accessor: "paymentType",
                                    Cell: (props) => {
                                        return transactionUtils.getTransactionListPaymentTypeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.SERIAL_NUMBER" />,
                                    accessor: "serialNumber",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.AMOUNT" />,
                                    accessor: "amount",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.REFUNDED_AMOUNT" />,
                                    accessor: "refundedAmount",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.TRANSACTION_DAY" />,
                                    accessor: "paidAt",
                                    Cell: ({ row }) => {
                                        return DateTimeCell(row.original.paidAt || row.original.createdAt);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_STATUS" />,
                                    accessor: "paymentStatus",
                                    Cell: ({ row }) => {
                                        return transactionUtils.getTransactionStatusCell(
                                            row.original.paymentStatus,
                                            row.original.status
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PERIOD" />,
                                    accessor: "period",
                                    Cell: ({ row }) => {
                                        const periodStart = row.original.periodStart;
                                        const periodEnd = row.original.periodEnd;
                                        if (periodStart && periodEnd) {
                                            return TextCell(
                                                `${moment(periodStart).format("YYYY-MM-DD")} - ${moment(
                                                    periodEnd
                                                ).format("YYYY-MM-DD")}`
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    Cell: ({ row }) => {
                                        return (
                                            <IconButton
                                                disabled={isDownloadingPdf}
                                                onClick={() =>
                                                    downloadTransactionPdf(row.original.id, row.original.serialNumber)
                                                }
                                            >
                                                <CloudDownloadOutlinedIcon />
                                            </IconButton>
                                        );
                                    },
                                },
                            ],
                            [downloadTransactionPdf, isDownloadingPdf]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}

export default connect(null, {
    ...subscriptionActions,
    ...transactionActions,
    ...errorActions,
})(SubscriptionPageTransactions);
