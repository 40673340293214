import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import ServiceNavigatorOptionsMenu from "../ServiceNavigatorOptionsMenu";
import { getServiceStatusCell, STATUS_HIDDEN } from "../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "325px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(1),
        alignItems: "center",
    },
}));

function VoucherPageNavigator({ serviceId, updateServiceVisibility, deleteVoucher }) {
    const { voucher } = useSelector((state) => state.services);
    const [showChangeVisibilityConfirmation, setShowChangeVisibilityConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        <ServiceNavigatorOptionsMenu
                            service={voucher}
                            onServiceDeleteClicked={() => setShowDeleteConfirmation(true)}
                            onServiceChangeVisibilityClicked={() => setShowChangeVisibilityConfirmation(true)}
                        />
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        {voucher && (
                            <>
                                <span
                                    className="font-weight-bolder font-size-h6 text-dark-75 flex-grow-1"
                                    style={{ marginRight: "24px" }}
                                >
                                    {voucher.name}
                                </span>
                                {getServiceStatusCell(voucher.status)}
                            </>
                        )}
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        {voucher?.createdAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SERVICE.VOUCHER.DETAILS.CREATED_AT" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {moment(voucher.createdAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {voucher?.createdBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SERVICE.VOUCHER.DETAILS.CREATED_BY" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${voucher.createdBy.firstName} ${voucher.createdBy.surname}`}
                                </span>
                            </div>
                        )}
                        {voucher?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="SERVICE.VOUCHER.DETAILS.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(voucher.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {voucher?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="SERVICE.VOUCHER.DETAILS.DELETED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${voucher.deletedBy.firstName} ${voucher.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/services/${serviceId}/voucher/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SERVICE.VOUCHER.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/services/${serviceId}/voucher/booking-settings`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/services/${serviceId}/voucher/checkout-settings`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/shopping/Bag1.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SERVICE.VOUCHER.PAYMENT_SETTINGS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/services/${serviceId}/voucher/addons`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Puzzle.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SERVICE.ADDONS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/services/${serviceId}/voucher/services`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SERVICE.VOUCHER.SERVICES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/services/${serviceId}/voucher/bookings`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SERVICE.VOUCHER.BOOKINGS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="SERVICE.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteVoucher(serviceId);
                    }
                    setShowDeleteConfirmation(false);
                }}
            />
            <YesNoDialog
                title={<FormattedMessage id="SERVICE.VISBILITY.CONFIRM.TITLE" />}
                description={
                    voucher?.status === STATUS_HIDDEN ? (
                        <FormattedMessage id="SERVICE.VISBILITY.CONFIRM.SHOW_TEXT" />
                    ) : (
                        <FormattedMessage id="SERVICE.VISBILITY.CONFIRM.HIDE_TEXT" />
                    )
                }
                open={showChangeVisibilityConfirmation ? true : false}
                onOpenChanged={() => setShowChangeVisibilityConfirmation(false)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        const shouldBeVisible = voucher.status === STATUS_HIDDEN;
                        updateServiceVisibility(voucher.id, shouldBeVisible);
                    }
                    setShowChangeVisibilityConfirmation(false);
                }}
            />
        </div>
    );
}

export default connect(null, serviceActions)(VoucherPageNavigator);
