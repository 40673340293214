import React from "react";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import PromotionCodeForm from "./PromotionCodeForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import moment from "moment";

const schema = yup.object().shape({
    code: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    type: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    value: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    validFrom: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("validTo", (validTo, schema) => {
            if (validTo) {
                return schema.max(new Date(validTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        }),
    validTo: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null)),
    bookingFrom: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("bookingTo", (bookingTo, schema) => {
            if (bookingTo) {
                return schema.max(new Date(bookingTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        }),
    bookingTo: yup
        .date()
        .nullable()
        .transform((_, val) => (val ? new Date(val) : null)),
    maxUsagesPerCustomer: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    maxUsages: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    subscriptionTransactionsCount: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
});

function PromotionCodePageDetails({ updatePromotionCode }) {
    const { promotionCode, isUpdating } = useSelector((state) => state.promotionCodes);

    const { register, handleSubmit, setValue, control, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        var requestData = { ...values };
        requestData.validFrom = requestData.validFrom ? moment(requestData.validFrom).format() : null;
        requestData.validTo = requestData.validTo ? moment(requestData.validTo).format() : null;
        requestData.bookingFrom = requestData.bookingFrom ? moment(requestData.bookingFrom).format() : null;
        requestData.bookingTo = requestData.bookingTo ? moment(requestData.bookingTo).format() : null;
        updatePromotionCode(promotionCode.id, promotionCode, requestData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROMOTIONCODE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROMOTIONCODE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {promotionCode && !promotionCode?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <PromotionCodeForm
                            register={register}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            showDescription={false}
                            promotionCode={promotionCode}
                        />
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, promotionCodesAction)(PromotionCodePageDetails);
