import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import { Paper } from "@material-ui/core";
import { LinkCell, TextCell, TranslationCell } from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import PromotionCodeListToolbar from "../../components/tables/toolbar/PromotionCodeListToolbar";
import { TYPE_DISCOUNT_PERCENTAGE, TYPE_DISCOUNT_AMOUNT, TYPE_FINAL_PRICE } from "./utils";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";

function PromotionCodeListPage({ getPromotionCodes, deletePromotionCode, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.promotionCodes);
    const { displayError } = useSelector((state) => state.errors);

    const [search, setSearch] = useState("");
    const [promotionCodeToDelete, setPromotionCodeToDelete] = useState(null);

    const history = useHistory();

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getPromotionCodes(profile.id, pageIndex + 1, pageSize, search);
        },
        [getPromotionCodes, profile.id, search]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return <PromotionCodeListToolbar onSearchChanged={onSearchChanged} />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="PROMOTIONCODE.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                return LinkCell("/promotion-codes/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PROMOTIONCODE.LIST.HEADER.CODE" />,
                            accessor: "code",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PROMOTIONCODE.LIST.HEADER.TYPE" />,
                            accessor: "type",
                            Cell: (props) => {
                                if (props.value === TYPE_DISCOUNT_PERCENTAGE) {
                                    return TranslationCell("PROMOTIONCODE.TYPE.DISCOUNT_PERCENTAGE");
                                } else if (props.value === TYPE_DISCOUNT_AMOUNT) {
                                    return TranslationCell("PROMOTIONCODE.TYPE.DISCOUNT_AMOUNT");
                                } else if (props.value === TYPE_FINAL_PRICE) {
                                    return TranslationCell("PROMOTIONCODE.TYPE.FINAL_PRICE");
                                }
                                return TextCell("-");
                            },
                        },
                        {
                            Header: <FormattedMessage id="PROMOTIONCODE.LIST.HEADER.VALUE" />,
                            accessor: "value",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PROMOTIONCODE.LIST.HEADER.SERVICES" />,
                            accessor: "serviceCount",
                            Cell: ({ row }) => {
                                if (row.original.isAllServices) {
                                    return TranslationCell("PROMOTIONCODE.LIST.HEADER.SERVICES.ALL");
                                }
                                return TextCell(row.original.serviceCount);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PROMOTIONCODE.LIST.HEADER.USAGE_COUNT" />,
                            accessor: "usageCount",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                return (
                                    <ListActionsButton
                                        menuItems={[
                                            {
                                                icon: <LinkIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.OPEN" />,
                                                onClick: () => {
                                                    history.push(`/promotion-codes/${row.original.id}`);
                                                },
                                            },
                                            {
                                                icon: <DeleteOutlinedIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.DELETE" />,
                                                onClick: () => setPromotionCodeToDelete(row.original),
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                    [history]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
            <YesNoDialog
                title={<FormattedMessage id="PROMOTIONCODE.DELETE.TEXT" />}
                open={promotionCodeToDelete ? true : false}
                onOpenChanged={() => setPromotionCodeToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deletePromotionCode(promotionCodeToDelete.id);
                    }
                    setPromotionCodeToDelete(null);
                }}
            />
        </Paper>
    );
}

export default connect(null, { ...promotionCodesAction, ...errorActions })(PromotionCodeListPage);
