import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as paymentMethodActions } from "../../../redux/paymentmethods/paymentMethodRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import PaymentMethodPageNavigator from "./PaymentMethodPageNavigator";
import PaymentMethodPageDetails from "./PaymentMethodPageDetails";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function PaymentMethodPage({ match, getPaymentMethod, clearPaymentMethodState, clearDisplayError, clearErrorState }) {
    const paymentMethodId = parseInt(match.params.id, 10);
    const { paymentMethod, isLoading, isUpdating } = useSelector((state) => state.paymentMethods);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!paymentMethod || paymentMethod.id !== paymentMethodId)) {
            getPaymentMethod(paymentMethodId);
        }
    }, [getPaymentMethod, paymentMethod, paymentMethodId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearPaymentMethodState();
            clearErrorState();
        };
    }, [clearPaymentMethodState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <PaymentMethodPageNavigator paymentMethodId={paymentMethodId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/payment-methods/${paymentMethodId}`}
                            exact={true}
                            to={`/payment-methods/${paymentMethodId}/details`}
                        />
                        <Route
                            path={`/payment-methods/${paymentMethodId}/details`}
                            component={PaymentMethodPageDetails}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...paymentMethodActions,
    ...errorActions,
})(PaymentMethodPage);
