import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReportFilterDropdownMultiple from "./filters/ReportFilterDropdownMultiple";
import ReportFilterSwitch from "./filters/ReportFilterSwitch";

const ReportFilterFormResources = ({ report, onSaveHasBeenClicked, isDisabled }) => {
    const intl = useIntl();

    const filterValues = report?.filterValues;
    const filterValuesObj = filterValues ? JSON.parse(filterValues) : {};
    let includeRemovedResources = filterValuesObj.includeRemovedResources;

    const resourceTypeOptions = [
        {
            id: "staff",
            name: intl.formatMessage({ id: "RESOURCE.TYPE.STAFF.STAFF" }),
        },
        {
            id: "space",
            name: intl.formatMessage({ id: "RESOURCE.TYPE.SPACE" }),
        },
        {
            id: "article",
            name: intl.formatMessage({ id: "RESOURCE.TYPE.ARTICLE" }),
        },
    ];

    let defaultResourceTypeOptions = [];
    if (filterValuesObj.resourceTypes) {
        if (filterValuesObj.resourceTypes.includes("staff")) {
            defaultResourceTypeOptions.push({
                id: "staff",
                name: intl.formatMessage({ id: "RESOURCE.TYPE.STAFF.STAFF" }),
            });
        }
        if (filterValuesObj.resourceTypes.includes("space")) {
            defaultResourceTypeOptions.push({
                id: "space",
                name: intl.formatMessage({ id: "RESOURCE.TYPE.SPACE" }),
            });
        }
        if (filterValuesObj.resourceTypes.includes("article")) {
            defaultResourceTypeOptions.push({
                id: "article",
                name: intl.formatMessage({ id: "RESOURCE.TYPE.ARTICLE" }),
            });
        }
    }

    return (
        <>
            <ReportFilterDropdownMultiple
                name="resourceTypes"
                options={resourceTypeOptions}
                defaultValues={defaultResourceTypeOptions}
                title={<FormattedMessage id="REPORT.RESOURCES.FILTER.RESOURCE_TYPES.TITLE" />}
                isDisabled={isDisabled}
            />
            <ReportFilterSwitch
                name="includeRemovedResources"
                defaultValue={includeRemovedResources}
                title={<FormattedMessage id="REPORT.RESOURCES.FILTER.INCLUDE_REMOVED_SERVICES.TITLE" />}
                isDisabled={isDisabled}
            />
        </>
    );
};

export default ReportFilterFormResources;
