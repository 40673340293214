import React from "react";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";

export const MODE_DONT_ASK = "dontAsk";
export const MODE_OPTIONAL = "optional";
export const MODE_MANDATORY = "mandatory";

export function ModeSettingDropdown({ register, name, onChange, includeDefaultGlobal, colSize }) {
    const intl = useIntl();

    const options = [
        {
            id: MODE_DONT_ASK,
            value: MODE_DONT_ASK,
            name: intl.formatMessage({
                id: "COMMON.MODE.DONT_ASK",
            }),
        },
        {
            id: MODE_OPTIONAL,
            value: MODE_OPTIONAL,
            name: intl.formatMessage({
                id: "COMMON.MODE.OPTIONAL",
            }),
        },
        {
            id: MODE_MANDATORY,
            value: MODE_MANDATORY,
            name: intl.formatMessage({
                id: "COMMON.MODE.MANDATORY",
            }),
        },
    ];

    if (includeDefaultGlobal) {
        options.unshift({
            id: "",
            value: "",
            name: intl.formatMessage({
                id: "COMMON.MODE.GLOBAL_SETTING",
            }),
        });
    }

    return (
        <Form.Control as="select" ref={register} name={name} onChange={onChange} className={`form-control ${colSize}`}>
            {options.map((x) => {
                return (
                    <option key={x.id} value={x.id}>
                        {x.name}
                    </option>
                );
            })}
        </Form.Control>
    );
}
