import React, { useMemo, useCallback } from "react";
import { actions as reminderTemplateActions } from "../../../redux/remindertemplates/reminderTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { LinkCell, TranslationCell } from "../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";

function SettingsPageReminderTemplates({ getProfileReminderTemplates }) {
    const { profile } = useSelector((state) => state.profiles);
    const { listPagination, isLoading } = useSelector((state) => state.reminderTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const profileId = profile?.id;

    const history = useHistory();

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (profileId) {
                getProfileReminderTemplates(profileId, pageIndex + 1, pageSize);
            }
        },
        [profileId, getProfileReminderTemplates]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROFILE.REMINDER_TEMPLATES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROFILE.REMINDER_TEMPLATES.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            label={<FormattedMessage id="COMMON.ADD" />}
                            onClick={() => history.push("/reminder-templates/create")}
                        ></BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={profileId}
                        data={useMemo(() => listPagination.data, [listPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={listPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PROFILE.REMINDER_TEMPLATES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/reminder-templates/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PROFILE.REMINDER_TEMPLATES.LIST.HEADER.NAME" />,
                                    accessor: "name",
                                    Cell: ({ row }) => {
                                        const template = row.original;
                                        return LinkCell("/reminder-templates/" + template.id, template.name);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PROFILE.REMINDER_TEMPLATES.LIST.HEADER.IS_DEFAULT" />,
                                    accessor: "isDefault",
                                    Cell: (props) => {
                                        if (props.value) {
                                            return TranslationCell("COMMON.YES");
                                        }
                                        return TranslationCell("COMMON.NO");
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...reminderTemplateActions, ...errorActions })(SettingsPageReminderTemplates);
