import React from "react";
import { withStyles, Chip } from "@material-ui/core";

const ChipStyled = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.info.main,
    color: "white",
  },
}))(Chip);

export default function ChipInfo(props) {
  return (
    <ChipStyled color="primary" label={props.label} size={props.size || "medium"} {...props} />
  );
}
