import React from "react";
import { actions as cancellationTemplateActions } from "../../../redux/cancellationtemplates/cancellationTemplateRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import CancellationTemplateDetailsForm from "./CancellationTemplateDetailsForm";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    minuteLimit: yup
        .number()
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
});

function CancellationTemplatePageDetails({ updateProfileCancellationTemplate }) {
    const { cancellationTemplate, isUpdating } = useSelector((state) => state.cancellationTemplates);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalCancellationTemplate = { ...cancellationTemplate };

        let updatedCancellationTemplate = { ...cancellationTemplate };
        updatedCancellationTemplate.name = values.name;
        updatedCancellationTemplate.isDefault = values.isDefault;
        updatedCancellationTemplate.minuteLimit = values.minuteLimit;
        updatedCancellationTemplate.isAutomaticRefund = values.isAutomaticRefund;

        updateProfileCancellationTemplate(
            cancellationTemplate.id,
            originalCancellationTemplate,
            updatedCancellationTemplate
        );
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {cancellationTemplate && !cancellationTemplate?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <CancellationTemplateDetailsForm cancellationTemplate={cancellationTemplate} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, cancellationTemplateActions)(CancellationTemplatePageDetails);
