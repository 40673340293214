import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROFILE_REMINDER_TEMPLATES_URL = "v1/admin/profiles/:id/reminder-templates";

export const REMINDER_TEMPLATE_DETAILS_URL = "v1/admin/reminder-templates/:id";

export const REMINDER_TEMPLATE_PREVIEW_URL = "v1/admin/reminder-templates/:id/preview";

export const REMINDER_TEMPLATE_RULES_URL = "v1/admin/reminder-templates/:id/rules";
export const REMINDER_TEMPLATE_RULE_DETAILS_URL = "v1/admin/reminder-template-rules/:id";

export function getProfileReminderTemplates(profileId, page, perPage) {
    let url = `${PROFILE_REMINDER_TEMPLATES_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getProfileReminderTemplate(id) {
    return axios.get(getApiUrl() + REMINDER_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function addProfileReminderTemplate(profileId, data) {
    return axios.post(getApiUrl() + PROFILE_REMINDER_TEMPLATES_URL.replace(":id", profileId), data);
}

export function updateProfileReminderTemplate(id, originalCancellationTemplate, changedCancellationTemplate) {
    let url = REMINDER_TEMPLATE_DETAILS_URL.replace(":id", id);
    return axios.patch(
        getApiUrl() + url,
        jsonpatch.compare(originalCancellationTemplate, changedCancellationTemplate),
        {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        }
    );
}

export function deleteProfileReminderTemplate(id) {
    return axios.delete(getApiUrl() + REMINDER_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function getProfileReminderTemplateRules(reminderTemplateId, page, perPage) {
    let url = `${REMINDER_TEMPLATE_RULES_URL.replace(":id", reminderTemplateId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getReminderTemplatePreview(id) {
    return axios.get(getApiUrl() + REMINDER_TEMPLATE_PREVIEW_URL.replace(":id", id));
}

export function addReminderTemplateRule(reminderTemplateId, data) {
    return axios.post(getApiUrl() + REMINDER_TEMPLATE_RULES_URL.replace(":id", reminderTemplateId), data);
}

export function updateReminderTemplateRule(id, originalReminderTemplateRule, changedReminderTemplateRule) {
    let url = REMINDER_TEMPLATE_RULE_DETAILS_URL.replace(":id", id);
    return axios.patch(
        getApiUrl() + url,
        jsonpatch.compare(originalReminderTemplateRule, changedReminderTemplateRule),
        {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        }
    );
}

export function deleteProfileReminderTemplateRule(id) {
    return axios.delete(getApiUrl() + REMINDER_TEMPLATE_RULE_DETAILS_URL.replace(":id", id));
}
