import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { actions as authActions } from "../../../../redux/auth/authRedux";

function LogoutPage({ logout }) {
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        logout();
        localStorage.removeItem("calendarSettings");
        localStorage.removeItem("calendarFilters");

        // Legacy key no longer in use, this code can be removed after june 2024
        localStorage.removeItem("calendarFilter");
    }, [logout]);

    return (
        <>
            {user && <LayoutSplashScreen />}
            {!user && <Redirect to="/auth/login" />}
        </>
    );
}

export default connect(null, authActions)(LogoutPage);
