import { put, takeLatest, select } from "redux-saga/effects";
import {
    getResources,
    getResourcesAndGroups,
    getResource,
    updateResource,
    createResource,
    getStaffAddPermissions,
    getStaffAvailablePermissions,
    deleteResource,
    getResourceGroups,
    createResourceGroup,
    getResourceGroup,
    patchResourceGroup,
    deleteResourceGroup,
    getResourceGroupResources,
    createResourceGroupResource,
    deleteResourceGroupResource,
    getResourceHours,
    saveResourceHours,
    copyResourceHours,
    getResourceGroupHours,
    saveResourceGroupHours,
    copyResourceGroupHours,
    getResourceBookings,
} from "./resourceCrud";
import { findAndSpliceArrayByProperty } from "../reduxUtils";
import {
    mergeResourceHourDays,
    addResourceHourToDays,
    updateResourceHour,
    deleteResourceHourFromDays,
} from "../../app/pages/resources/utils";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_RESOURCE_REQUEST: "GET_RESOURCE_REQUEST",
    GET_RESOURCE_SUCCESS: "GET_RESOURCE_SUCCESS",
    GET_RESOURCES_REQUEST: "GET_RESOURCES_REQUEST",
    GET_RESOURCES_SUCCESS: "GET_RESOURCES_SUCCESS",
    GET_RESOURCES_AND_GROUPS_REQUEST: "GET_RESOURCES_AND_GROUPS_REQUEST",
    GET_RESOURCES_AND_GROUPS_SUCCESS: "GET_RESOURCES_AND_GROUPS_SUCCESS",
    ADD_RESOURCE_REQUEST: "ADD_RESOURCE_REQUEST",
    ADD_RESOURCE_SUCCESS: "ADD_RESOURCE_SUCCESS",
    GET_STAFF_ADD_PERMISSIONS_REQUEST: "GET_STAFF_ADD_PERMISSIONS_REQUEST",
    GET_STAFF_ADD_PERMISSIONS_SUCCESS: "GET_STAFF_ADD_PERMISSIONS_SUCCESS",
    GET_STAFF_AVAILABLE_PERMISSIONS_REQUEST: "GET_STAFF_AVAILABLE_PERMISSIONS_REQUEST",
    GET_STAFF_AVAILABLE_PERMISSIONS_SUCCESS: "GET_STAFF_AVAILABLE_PERMISSIONS_SUCCESS",
    UPDATE_RESOURCE_REQUEST: "UPDATE_RESOURCE_REQUEST",
    UPDATE_RESOURCE_SUCCESS: "UPDATE_RESOURCE_SUCCESS",
    DELETE_RESOURCE_REQUEST: "DELETE_RESOURCE_REQUEST",
    DELETE_RESOURCE_SUCCESS: "DELETE_RESOURCE_SUCCESS",
    GET_RESOURCE_GROUPS_REQUEST: "GET_RESOURCE_GROUPS_REQUEST",
    GET_RESOURCE_GROUPS_SUCCESS: "GET_RESOURCE_GROUPS_SUCCESS",
    GET_RESOURCE_GROUP_REQUEST: "GET_RESOURCE_GROUP_REQUEST",
    GET_RESOURCE_GROUP_SUCCESS: "GET_RESOURCE_GROUP_SUCCESS",
    ADD_RESOURCE_GROUP_REQUEST: "ADD_RESOURCE_GROUP_REQUEST",
    ADD_RESOURCE_GROUP_SUCCESS: "ADD_RESOURCE_GROUP_SUCCESS",
    UPDATE_RESOURCE_GROUP_REQUEST: "UPDATE_RESOURCE_GROUP_REQUEST",
    UPDATE_RESOURCE_GROUP_SUCCESS: "UPDATE_RESOURCE_GROUP_SUCCESS",
    DELETE_RESOURCE_GROUP_REQUEST: "DELETE_RESOURCE_GROUP_REQUEST",
    DELETE_RESOURCE_GROUP_SUCCESS: "DELETE_RESOURCE_GROUP_SUCCESS",
    GET_RESOURCE_GROUP_RESOURCES_REQUEST: "GET_RESOURCE_GROUP_RESOURCES_REQUEST",
    GET_RESOURCE_GROUP_RESOURCES_SUCCESS: "GET_RESOURCE_GROUP_RESOURCES_SUCCESS",
    ADD_RESOURCE_GROUP_RESOURCES_REQUEST: "ADD_RESOURCE_GROUP_RESOURCES_REQUEST",
    ADD_RESOURCE_GROUP_RESOURCES_SUCCESS: "ADD_RESOURCE_GROUP_RESOURCES_SUCCESS",
    DELETE_RESOURCE_GROUP_RESOURCE_REQUEST: "DELETE_RESOURCE_GROUP_RESOURCE_REQUEST",
    DELETE_RESOURCE_GROUP_RESOURCE_SUCCESS: "DELETE_RESOURCE_GROUP_RESOURCE_SUCCESS",
    MODAL_ADD_RESOURCE_GROUP_RESOURCE: "MODAL_ADD_RESOURCE_GROUP_RESOURCE",
    GET_RESOURCE_HOURS_REQUEST: "GET_RESOURCE_HOURS_REQUEST",
    GET_RESOURCE_HOURS_SUCCESS: "GET_RESOURCE_HOURS_SUCCESS",
    ADD_RESOURCE_HOUR_REQUEST: "ADD_RESOURCE_HOUR_REQUEST",
    UPDATE_RESOURCE_HOUR_REQUEST: "UPDATE_RESOURCE_HOUR_REQUEST",
    DELETE_RESOURCE_HOUR_REQUEST: "DELETE_RESOURCE_HOUR_REQUEST",
    SAVE_RESOURCE_HOUR_REQUEST: "SAVE_RESOURCE_HOUR_REQUEST",
    SAVE_RESOURCE_HOUR_SUCCESS: "SAVE_RESOURCE_HOUR_SUCCESS",
    COPY_RESOURCE_HOURS_REQUEST: "COPY_RESOURCE_HOURS_REQUEST",
    COPY_RESOURCE_HOURS_SUCCESS: "COPY_RESOURCE_HOURS_SUCCESS",
    GET_RESOURCE_GROUP_HOURS_REQUEST: "GET_RESOURCE_GROUP_HOURS_REQUEST",
    GET_RESOURCE_GROUP_HOURS_SUCCESS: "GET_RESOURCE_GROUP_HOURS_SUCCESS",
    ADD_RESOURCE_GROUP_HOUR_REQUEST: "ADD_RESOURCE_GROUP_HOUR_REQUEST",
    UPDATE_RESOURCE_GROUP_HOUR_REQUEST: "UPDATE_RESOURCE_GROUP_HOUR_REQUEST",
    DELETE_RESOURCE_GROUP_HOUR_REQUEST: "DELETE_RESOURCE_GROUP_HOUR_REQUEST",
    SAVE_RESOURCE_GROUP_HOUR_REQUEST: "SAVE_RESOURCE_GROUP_HOUR_REQUEST",
    SAVE_RESOURCE_GROUP_HOUR_SUCCESS: "SAVE_RESOURCE_GROUP_HOUR_SUCCESS",
    COPY_RESOURCE_GROUP_HOURS_REQUEST: "COPY_RESOURCE_GROUP_HOURS_REQUEST",
    COPY_RESOURCE_GROUP_HOURS_SUCCESS: "COPY_RESOURCE_GROUP_HOURS_SUCCESS",
    GET_RESOURCE_BOOKINGS_REQUEST: "GET_RESOURCE_BOOKINGS_REQUEST",
    GET_RESOURCE_BOOKINGS_SUCCESS: "GET_RESOURCE_BOOKINGS_SUCCESS",
    CLEAR_RESOURCES_STATE: "CLEAR_RESOURCES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    resourcesAndGroupsListPagination: {
        data: [],
        totalRows: 0,
    },
    groupsListPagination: {
        data: [],
        totalRows: 0,
    },
    groupResourcesListPagination: {
        data: [],
        totalRows: 0,
    },
    resourceBookingsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_RESOURCES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_RESOURCES_AND_GROUPS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCES_AND_GROUPS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourcesAndGroupsListPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_RESOURCE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_RESOURCE_SUCCESS: {
            return { ...state, isLoading: false, resource: action.payload.response };
        }

        case actionTypes.DELETE_RESOURCE_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_RESOURCE_SUCCESS: {
            const resource = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(state.listPagination.data, "id", resource.id);
            return {
                ...state,
                isLoading: false,
                resource: state.resource && state.resource.id === resource.id ? resource : state.resource,
                listPagination: {
                    ...state.listPagination,
                    data: alteredStateArray,
                    totalRows: state.listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_RESOURCE_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionTypes.GET_RESOURCE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resource: action.payload.response,
            };
        }

        case actionTypes.GET_STAFF_ADD_PERMISSIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_STAFF_ADD_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                staffPermissions: action.payload.response,
            };
        }
        case actionTypes.GET_STAFF_AVAILABLE_PERMISSIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_STAFF_AVAILABLE_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                staffPermissionsDetails: action.payload.response,
            };
        }

        case actionTypes.UPDATE_RESOURCE_REQUEST: {
            return {
                ...state,
                isUpdating: true,
            };
        }

        case actionTypes.UPDATE_RESOURCE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                resource: action.payload.response,
            };
        }

        case actionTypes.GET_RESOURCE_GROUPS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_GROUPS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                groupsListPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_RESOURCE_GROUP_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_RESOURCE_GROUP_SUCCESS: {
            return { ...state, isUpdating: false, resourceGroup: action.payload.response };
        }

        case actionTypes.GET_RESOURCE_GROUP_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_GROUP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceGroup: action.payload.response,
            };
        }

        case actionTypes.UPDATE_RESOURCE_GROUP_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_RESOURCE_GROUP_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                resourceGroup: action.payload.response,
            };
        }

        case actionTypes.DELETE_RESOURCE_GROUP_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.DELETE_RESOURCE_GROUP_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.groupsListPagination.data,
                "id",
                action.payload.response.id
            );
            let resourceGroup = state.resourceGroup;
            if (resourceGroup && resourceGroup.id === action.payload.response.id) {
                resourceGroup = action.payload.response;
            }

            return {
                ...state,
                isUpdating: false,
                groupsListPagination: {
                    ...state.groupsListPagination,
                    data: alteredStateArray,
                    totalRows: state.groupsListPagination.totalRows - 1,
                },
                resourceGroup,
            };
        }

        case actionTypes.GET_RESOURCE_GROUP_RESOURCES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_GROUP_RESOURCES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                groupResourcesListPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_RESOURCE_GROUP_RESOURCES_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_RESOURCE_GROUP_RESOURCES_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                showModalAddResourceGroupResource: false,
            };
        }

        case actionTypes.DELETE_RESOURCE_GROUP_RESOURCE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.DELETE_RESOURCE_GROUP_RESOURCE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.groupResourcesListPagination.data,
                "id",
                action.payload.response.id
            );
            let resourceGroup = state.resourceGroup;
            if (resourceGroup && resourceGroup.id === action.payload.response.id) {
                resourceGroup = action.payload.response;
            }

            return {
                ...state,
                isUpdating: false,
                groupResourcesListPagination: {
                    ...state.groupResourcesListPagination,
                    data: alteredStateArray,
                    totalRows: state.groupResourcesListPagination.totalRows - 1,
                },
                resourceGroup,
            };
        }

        case actionTypes.MODAL_ADD_RESOURCE_GROUP_RESOURCE: {
            return {
                ...state,
                showModalAddResourceGroupResource: action.payload.show,
                error: null,
            };
        }

        case actionTypes.GET_RESOURCE_HOURS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_HOURS_SUCCESS: {
            const resource = state.resource;
            const alteredHourDays = mergeResourceHourDays(resource.hourDays, action.payload.response);
            return {
                ...state,
                isLoading: false,
                resource: {
                    ...state.resource,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.ADD_RESOURCE_HOUR_REQUEST: {
            const resource = state.resource;
            const alteredHourDays = addResourceHourToDays(resource.hourDays, action.payload.date);
            return {
                ...state,
                resource: {
                    ...state.resource,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.UPDATE_RESOURCE_HOUR_REQUEST: {
            const resource = state.resource;
            const alteredHourDays = updateResourceHour(
                resource.hourDays,
                action.payload.field,
                action.payload.date,
                action.payload.oldTime,
                action.payload.newTime
            );
            return {
                ...state,
                resource: {
                    ...state.resource,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.DELETE_RESOURCE_HOUR_REQUEST: {
            const resource = state.resource;
            const alteredHourDays = deleteResourceHourFromDays(
                resource.hourDays,
                action.payload.date,
                action.payload.startTime,
                action.payload.endTime
            );
            return {
                ...state,
                resource: {
                    ...state.resource,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.SAVE_RESOURCE_HOUR_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.SAVE_RESOURCE_HOUR_SUCCESS: {
            return { ...state, isUpdating: false };
        }

        case actionTypes.COPY_RESOURCE_HOURS_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.COPY_RESOURCE_HOURS_SUCCESS: {
            return { ...state, isUpdating: false };
        }

        case actionTypes.GET_RESOURCE_GROUP_HOURS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_GROUP_HOURS_SUCCESS: {
            const resourceGroup = state.resourceGroup;
            const alteredHourDays = mergeResourceHourDays(resourceGroup.hourDays, action.payload.response);
            return {
                ...state,
                isLoading: false,
                resourceGroup: {
                    ...state.resourceGroup,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.ADD_RESOURCE_GROUP_HOUR_REQUEST: {
            const resourceGroup = state.resourceGroup;
            const alteredHourDays = addResourceHourToDays(resourceGroup.hourDays, action.payload.date);
            return {
                ...state,
                resourceGroup: {
                    ...state.resourceGroup,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.UPDATE_RESOURCE_GROUP_HOUR_REQUEST: {
            const resourceGroup = state.resourceGroup;
            const alteredHourDays = updateResourceHour(
                resourceGroup.hourDays,
                action.payload.field,
                action.payload.date,
                action.payload.oldTime,
                action.payload.newTime
            );
            return {
                ...state,
                resourceGroup: {
                    ...state.resourceGroup,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.DELETE_RESOURCE_GROUP_HOUR_REQUEST: {
            const resourceGroup = state.resourceGroup;
            const alteredHourDays = deleteResourceHourFromDays(
                resourceGroup.hourDays,
                action.payload.date,
                action.payload.startTime,
                action.payload.endTime
            );
            return {
                ...state,
                resourceGroup: {
                    ...state.resourceGroup,
                    hourDays: alteredHourDays,
                },
            };
        }

        case actionTypes.SAVE_RESOURCE_GROUP_HOUR_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.SAVE_RESOURCE_GROUP_HOUR_SUCCESS: {
            return { ...state, isUpdating: false };
        }

        case actionTypes.COPY_RESOURCE_GROUP_HOURS_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.COPY_RESOURCE_GROUP_HOURS_SUCCESS: {
            return { ...state, isUpdating: false };
        }

        case actionTypes.GET_RESOURCE_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceBookingsPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_RESOURCES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getResources: (profileId, page, perPage, search, status, type) => ({
        type: actionTypes.GET_RESOURCES_REQUEST,
        payload: { profileId, page, perPage, search, status, type },
    }),
    getResourcesSuccess: (response) => ({
        type: actionTypes.GET_RESOURCES_SUCCESS,
        payload: { response },
    }),

    getResourcesAndGroups: (profileId, page, perPage, search, status, type) => ({
        type: actionTypes.GET_RESOURCES_AND_GROUPS_REQUEST,
        payload: { profileId, page, perPage, search, status, type },
    }),

    getResource: (type, id) => ({
        type: actionTypes.GET_RESOURCE_REQUEST,
        payload: { type, id },
    }),
    getResourceSuccess: (response) => ({
        type: actionTypes.GET_RESOURCE_SUCCESS,
        payload: { response },
    }),

    getStaffAddPermissions: (profileId) => ({
        type: actionTypes.GET_STAFF_ADD_PERMISSIONS_REQUEST,
        payload: { profileId },
    }),

    getStaffAvailablePermissions: (resourceId, profileId) => ({
        type: actionTypes.GET_STAFF_AVAILABLE_PERMISSIONS_REQUEST,
        payload: { resourceId, profileId },
    }),

    updateResource: (type, id, properties) => ({
        type: actionTypes.UPDATE_RESOURCE_REQUEST,
        payload: { type, id, properties },
    }),

    addResource: (type, profileId, properties, callback) => ({
        type: actionTypes.ADD_RESOURCE_REQUEST,
        payload: { type, profileId, properties, callback },
    }),

    deleteResource: (id) => ({
        type: actionTypes.DELETE_RESOURCE_REQUEST,
        payload: { id },
    }),

    getResourceGroups: (profileId, page, perPage) => ({
        type: actionTypes.GET_RESOURCE_GROUPS_REQUEST,
        payload: { profileId, page, perPage },
    }),
    getResourceGroupsSuccess: (response) => ({
        type: actionTypes.GET_RESOURCE_GROUPS_SUCCESS,
        payload: { response },
    }),

    addResourceGroup: (profileId, name, description) => ({
        type: actionTypes.ADD_RESOURCE_GROUP_REQUEST,
        payload: { profileId, name, description },
    }),
    addResourceGroupSuccess: (response) => ({
        type: actionTypes.ADD_RESOURCE_GROUP_SUCCESS,
        payload: { response },
    }),

    getResourceGroup: (id) => ({
        type: actionTypes.GET_RESOURCE_GROUP_REQUEST,
        payload: { id },
    }),
    getResourceGroupSuccess: (response) => ({
        type: actionTypes.GET_RESOURCE_GROUP_SUCCESS,
        payload: { response },
    }),

    updateResourceGroup: (id, originalResourceGroup, name, description) => ({
        type: actionTypes.UPDATE_RESOURCE_GROUP_REQUEST,
        payload: { id, originalResourceGroup, name, description },
    }),
    updateResourceGroupSuccess: (response) => ({
        type: actionTypes.UPDATE_RESOURCE_GROUP_SUCCESS,
        payload: { response },
    }),

    deleteResourceGroup: (id) => ({
        type: actionTypes.DELETE_RESOURCE_GROUP_REQUEST,
        payload: { id },
    }),
    deleteResourceGroupSuccess: (response) => ({
        type: actionTypes.DELETE_RESOURCE_GROUP_SUCCESS,
        payload: { response },
    }),

    getResourceGroupResources: (id, page, perPage) => ({
        type: actionTypes.GET_RESOURCE_GROUP_RESOURCES_REQUEST,
        payload: { id, page, perPage },
    }),
    getResourceGroupResourcesSuccess: (response) => ({
        type: actionTypes.GET_RESOURCE_GROUP_RESOURCES_SUCCESS,
        payload: { response },
    }),

    addResourceGroupResources: (resourceGroupId, resourceIds) => ({
        type: actionTypes.ADD_RESOURCE_GROUP_RESOURCES_REQUEST,
        payload: { resourceGroupId, resourceIds },
    }),

    deleteResourceGroupResource: (id, page, perPage) => ({
        type: actionTypes.DELETE_RESOURCE_GROUP_RESOURCE_REQUEST,
        payload: { id, page, perPage },
    }),
    deleteResourceGroupResourceSuccess: (response) => ({
        type: actionTypes.DELETE_RESOURCE_GROUP_RESOURCE_SUCCESS,
        payload: { response },
    }),

    setShowModalAddResourceGroupResource: (show) => ({
        type: actionTypes.MODAL_ADD_RESOURCE_GROUP_RESOURCE,
        payload: { show },
    }),

    getResourceHours: (id, year, week) => ({
        type: actionTypes.GET_RESOURCE_HOURS_REQUEST,
        payload: { id, year, week },
    }),

    addResourceHour: (date) => ({
        type: actionTypes.ADD_RESOURCE_HOUR_REQUEST,
        payload: { date },
    }),

    updateResourceHour: (field, date, oldTime, newTime) => ({
        type: actionTypes.UPDATE_RESOURCE_HOUR_REQUEST,
        payload: { field, date, oldTime, newTime },
    }),

    deleteResourceHour: (date, startTime, endTime) => ({
        type: actionTypes.DELETE_RESOURCE_HOUR_REQUEST,
        payload: { date, startTime, endTime },
    }),

    saveResourceHours: (resourceId) => ({
        type: actionTypes.SAVE_RESOURCE_HOUR_REQUEST,
        payload: { resourceId },
    }),

    copyResourceHours: (id, fromYear, fromWeek, untilYear, untilWeek, callback) => ({
        type: actionTypes.COPY_RESOURCE_HOURS_REQUEST,
        payload: { id, fromYear, fromWeek, untilYear, untilWeek, callback },
    }),

    getResourceGroupHours: (id, year, week) => ({
        type: actionTypes.GET_RESOURCE_GROUP_HOURS_REQUEST,
        payload: { id, year, week },
    }),

    addResourceGroupHour: (date) => ({
        type: actionTypes.ADD_RESOURCE_GROUP_HOUR_REQUEST,
        payload: { date },
    }),

    updateResourceGroupHour: (field, date, oldTime, newTime) => ({
        type: actionTypes.UPDATE_RESOURCE_GROUP_HOUR_REQUEST,
        payload: { field, date, oldTime, newTime },
    }),

    deleteResourceGroupHour: (date, startTime, endTime) => ({
        type: actionTypes.DELETE_RESOURCE_GROUP_HOUR_REQUEST,
        payload: { date, startTime, endTime },
    }),

    saveResourceGroupHours: (resourceGroupId) => ({
        type: actionTypes.SAVE_RESOURCE_GROUP_HOUR_REQUEST,
        payload: { resourceGroupId },
    }),

    copyResourceGroupHours: (id, fromYear, fromWeek, untilYear, untilWeek, callback) => ({
        type: actionTypes.COPY_RESOURCE_GROUP_HOURS_REQUEST,
        payload: { id, fromYear, fromWeek, untilYear, untilWeek, callback },
    }),

    getResourceBookings: (id, page, perPage) => ({
        type: actionTypes.GET_RESOURCE_BOOKINGS_REQUEST,
        payload: { id, page, perPage },
    }),

    clearResourceState: () => ({
        type: actionTypes.CLEAR_RESOURCES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResources(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.type
            );
            yield put(actions.getResourcesSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCES_AND_GROUPS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourcesAndGroups(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.type
            );

            yield put({
                type: actionTypes.GET_RESOURCES_AND_GROUPS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResource(payload.type, payload.id);
            yield put(actions.getResourceSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createResource(payload.type, payload.profileId, payload.properties);

            yield put({
                type: actionTypes.ADD_RESOURCE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_STAFF_ADD_PERMISSIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getStaffAddPermissions(payload.profileId);
            yield put({
                type: actionTypes.GET_STAFF_ADD_PERMISSIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_STAFF_AVAILABLE_PERMISSIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getStaffAvailablePermissions(payload.resourceId, payload.profileId);
            yield put({
                type: actionTypes.GET_STAFF_AVAILABLE_PERMISSIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateResource(payload.type, payload.id, payload.properties);
            yield put({
                type: actionTypes.UPDATE_RESOURCE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteResource(payload.id);
            yield put({
                type: actionTypes.DELETE_RESOURCE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_GROUPS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceGroups(payload.profileId, payload.page, payload.perPage);
            yield put(actions.getResourceGroupsSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_RESOURCE_GROUP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createResourceGroup(payload.profileId, payload.name, payload.description);
            yield put(actions.addResourceGroupSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_GROUP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceGroup(payload.id);
            yield put(actions.getResourceGroupSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_RESOURCE_GROUP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchResourceGroup(payload.id, payload.originalResourceGroup, {
                ...payload.originalResourceGroup,
                name: payload.name || payload.originalResourceGroup.name,
                description: payload.description,
            });
            yield put(actions.updateResourceGroupSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_RESOURCE_GROUP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteResourceGroup(payload.id);
            yield put(actions.deleteResourceGroupSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_GROUP_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceGroupResources(payload.id, payload.page, payload.perPage);
            yield put(actions.getResourceGroupResourcesSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_RESOURCE_GROUP_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createResourceGroupResource(payload.resourceGroupId, payload.resourceIds);

            yield put({
                type: actionTypes.ADD_RESOURCE_GROUP_RESOURCES_SUCCESS,
                payload: { response },
            });

            // Refetch group resources
            yield put(actions.getResourceGroupResources(payload.resourceGroupId, 1, 10));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_RESOURCE_GROUP_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteResourceGroupResource(payload.id);
            yield put(actions.deleteResourceGroupResourceSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_HOURS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceHours(payload.id, payload.year, payload.week);
            yield put({
                type: actionTypes.GET_RESOURCE_HOURS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.SAVE_RESOURCE_HOUR_REQUEST, function* ({ payload }) {
        try {
            const state = yield select();
            let editedDays = [];
            if (state.resources.resource && state.resources.resource.hourDays) {
                editedDays = state.resources.resource.hourDays.filter((x) => x.isEdited);
            }

            if (editedDays.length > 0) {
                yield saveResourceHours(payload.resourceId, editedDays);
            }

            for (let i = 0; i < editedDays.length; i++) {
                editedDays[i].isEdited = false;
            }

            yield put({
                type: actionTypes.SAVE_RESOURCE_HOUR_SUCCESS,
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.COPY_RESOURCE_HOURS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield copyResourceHours(
                payload.id,
                payload.fromYear,
                payload.fromWeek,
                payload.untilYear,
                payload.untilWeek
            );
            yield put({
                type: actionTypes.COPY_RESOURCE_HOURS_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_GROUP_HOURS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceGroupHours(payload.id, payload.year, payload.week);
            yield put({
                type: actionTypes.GET_RESOURCE_GROUP_HOURS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.SAVE_RESOURCE_GROUP_HOUR_REQUEST, function* ({ payload }) {
        try {
            const state = yield select();
            let editedDays = [];
            if (state.resources.resourceGroup && state.resources.resourceGroup.hourDays) {
                editedDays = state.resources.resourceGroup.hourDays.filter((x) => x.isEdited);
            }

            if (editedDays.length > 0) {
                yield saveResourceGroupHours(payload.resourceGroupId, editedDays);
            }

            for (let i = 0; i < editedDays.length; i++) {
                editedDays[i].isEdited = false;
            }

            yield put({
                type: actionTypes.SAVE_RESOURCE_GROUP_HOUR_SUCCESS,
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.COPY_RESOURCE_GROUP_HOURS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield copyResourceGroupHours(
                payload.id,
                payload.fromYear,
                payload.fromWeek,
                payload.untilYear,
                payload.untilWeek
            );
            yield put({
                type: actionTypes.COPY_RESOURCE_GROUP_HOURS_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceBookings(payload.id, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_RESOURCE_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
