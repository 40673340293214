import React from "react";
import { actions as billingTemplateActions } from "../../../redux/billingtemplates/billingTemplateRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BillingTemplateDetailsForm from "./BillingTemplateDetailsForm";
import moment from "moment";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    organisationName: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    organisationNumber: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    vatNumber: yup.string(),
    invoiceExpirationDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    invoiceFee: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    adminFee: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
});

function BillingTemplatePageDetails({ updateProfileBillingTemplate }) {
    const { billingTemplate, isUpdating } = useSelector((state) => state.billingTemplates);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalBillingTemplate = { ...billingTemplate };

        let updatedBillingTemplate = { ...billingTemplate };
        updatedBillingTemplate.name = values.name;
        updatedBillingTemplate.isDefault = values.isDefault;
        updatedBillingTemplate.organisationName = values.organisationName;
        updatedBillingTemplate.organisationNumber = values.organisationNumber;
        updatedBillingTemplate.vatNumber = values.vatNumber;
        updatedBillingTemplate.invoiceExpirationDays = values.invoiceExpirationDays;
        updatedBillingTemplate.invoiceExpirationDate = values.invoiceExpirationDate
            ? moment(values.invoiceExpirationDate).format("YYYY-MM-DD")
            : null;
        updatedBillingTemplate.invoiceFee = values.invoiceFee;
        updatedBillingTemplate.adminFee = values.adminFee;

        updateProfileBillingTemplate(billingTemplate.id, originalBillingTemplate, updatedBillingTemplate);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT_METHOD.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT_METHOD.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {billingTemplate && !billingTemplate?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <BillingTemplateDetailsForm billingTemplate={billingTemplate} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, billingTemplateActions)(BillingTemplatePageDetails);
