import React from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import ServiceCommonDetailsForm from "../ServiceCommonDetailsForm";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
});

function AppointmentPageDetails({ updateAppointment }) {
    const { appointment, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalAppointment = { ...appointment };
        originalAppointment.categoryId = originalAppointment?.category?.id;

        let updatedAppointmentData = { ...appointment };
        updatedAppointmentData.name = values.name;
        updatedAppointmentData.description = values.description;
        updatedAppointmentData.categoryId = values?.category?.id;
        updatedAppointmentData.color = values.color;

        updateAppointment(appointment.id, originalAppointment, updatedAppointmentData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.APPOINTMENT.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.APPOINTMENT.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {appointment && !appointment?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceCommonDetailsForm service={appointment} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(AppointmentPageDetails);
