import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";

export default function SearchField({ onSearchChanged, searchDelay, textFieldStyles, stopPropagation = false }) {
    const intl = useIntl();

    const [text, setText] = useState("");

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (!onSearchChanged) return;
        const timeOutId = setTimeout(() => onSearchChanged(text), searchDelay || 200);
        return () => clearTimeout(timeOutId);
    }, [text, onSearchChanged, searchDelay]);

    return (
        <TextField
            className={textFieldStyles}
            placeholder={intl.formatMessage({ id: "COMMON.LIST.SEARCH" })}
            size={"medium"}
            value={text}
            onChange={(event) => setText(event.target.value)}
            onKeyDown={(e) => {
                if (stopPropagation) {
                    e.stopPropagation();
                }
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <IconButton size={"small"} disabled={text.length < 1} onClick={() => setText("")}>
                        <ClearIcon />
                    </IconButton>
                ),
            }}
        />
    );
}
