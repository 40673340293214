import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    amount: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    weekUntil: yup.string(),
});

function TransactionAddRefundModal({ show, onCloseClicked, addTransactionRefund, onRefundCreated, maxRefundAmount }) {
    const { isUpdating, transaction } = useSelector((state) => state.transactions);
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        addTransactionRefund(transaction.id, values.amount, values.reason, () => {
            onRefundCreated();
        });
    };

    if (!transaction) return <></>;

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id={"PAYMENT.REFUNDS.NEW.TITLE"} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={"PAYMENT.REFUNDS.NEW.DESCRIPTION"} />
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage
                                    id="PAYMENT.REFUNDS.NEW.AMOUNT"
                                    values={{ maxAmount: maxRefundAmount, currency: transaction.currency }}
                                />
                            </Form.Label>
                            <Form.Control type="number" name="amount" ref={register} isInvalid={errors.amount} />
                            <Form.Control.Feedback type="invalid">{errors.amount?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PAYMENT.REFUNDS.NEW.REASON" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                name="reason"
                                ref={register}
                                isInvalid={errors.reason}
                            />
                            <Form.Control.Feedback type="invalid">{errors.reason?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)} />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, transactionActions)(TransactionAddRefundModal);
