import React from "react";
import { makeStyles } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import AddResourceGroupButton from "./buttons/AddResourceGroupButton";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "flex-end",
        padding: theme.spacing(1),
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
}));

function ResourceGroupListToolbar() {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.buttonContainer}>
                <AddResourceGroupButton />
            </div>
        </Toolbar>
    );
}

export default ResourceGroupListToolbar;
