import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Switch } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapDeleteButton from "../../../components/buttons/BootstrapDeleteButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    notifyUser: yup.bool(),
    cancellationMessage: yup.string(),
});

function DeleteBookingModal({
    show,
    onCloseClicked,
    onDeleteClicked,
    titleLabel,
    notifyLabel,
    messageLabel,
    messageDescription,
    showCancellationMessage,
}) {
    const { isLoading } = useSelector((state) => state.customers);
    const classes = useStyles();

    const { register, handleSubmit, watch, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            notifyUser: true,
        },
    });

    const onFormSubmit = (values) => {
        onDeleteClicked(values);
    };

    const notifyUser = watch("notifyUser");

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleLabel}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>{notifyLabel}</Form.Label>
                            <Controller
                                name="notifyUser"
                                control={control}
                                render={({ value, onChange }) => {
                                    return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                                }}
                            />
                        </Form.Group>
                        {notifyUser && showCancellationMessage && (
                            <Form.Group>
                                <Form.Label>{messageLabel}</Form.Label>
                                <Form.Control as="textarea" rows={3} name="cancellationMessage" ref={register} />
                                <Form.Text className="text-muted">{messageDescription}</Form.Text>
                            </Form.Group>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapDeleteButton
                        isLoading={isLoading}
                        onClick={handleSubmit(onFormSubmit)}
                        label={<FormattedMessage id="COMMON.DELETE" />}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default DeleteBookingModal;
