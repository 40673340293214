import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as reminderTemplateActions } from "../../../redux/remindertemplates/reminderTemplateRedux";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";
import {
    RULE_TYPE_EMAIL,
    RULE_TYPE_SMS,
    RULE_CONDITION_BEFORE_BOOKING_START_TIME,
    RULE_CONDITION_BEFORE_BOOKING_END_TIME,
    RULE_CONDITION_BEFORE_BOOKING_END_TIME_NO_UPCOMING,
} from "./utils";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    minutes: yup.number().when("formMode", (formMode) => {
        if (formMode === "add") {
            return yup
                .number()
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
                .min(15, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 15 }} />);
        }
        return yup.number();
    }),
    reminderTitle: yup.string().when("type", (ruleType) => {
        if (ruleType.id === RULE_TYPE_SMS) {
            return yup
                .string()
                .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
                .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
                .max(11, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 11 }} />)
                .matches(/^[A-Za-z0-9]+$/, { message: <FormattedMessage id="FORM.ERROR.REGEX_MISMATCH" /> });
        }
        return yup.string();
    }),
    reminderText: yup.string().when("type", (ruleType) => {
        if (ruleType.id === RULE_TYPE_SMS) {
            return yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />);
        }
        return yup.string();
    }),
});

function ReminderTemplateRuleModal({ show, ruleToEdit, onSaveClicked, onCloseClicked }) {
    const { isLoading } = useSelector((state) => state.cancellationTemplates);
    const classes = useStyles();
    const intl = useIntl();

    const { register, handleSubmit, watch, setValue, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (formValues) => {
        let ruleData = {
            type: formValues.type.id,
            minutes: formValues.minutes,
            condition: formValues.condition.id,
        };

        if (ruleData.type === RULE_TYPE_SMS) {
            ruleData.reminderTitle = formValues.reminderTitle;
            ruleData.reminderText = formValues.reminderText;
        }

        onSaveClicked(ruleData);
    };

    const ruleType = watch("type");
    if (!ruleType) {
        register("type");

        if (ruleToEdit) {
            setValue("type", getRuleTypeOption(ruleToEdit.type, intl));
        } else {
            setValue("type", getRuleTypeOption(RULE_TYPE_EMAIL, intl));
        }
    }

    const condition = watch("condition");
    if (!condition) {
        register("condition");

        if (ruleToEdit) {
            setValue("condition", getRuleConditionOption(ruleToEdit.condition, intl));
        } else {
            setValue("condition", getRuleConditionOption(RULE_CONDITION_BEFORE_BOOKING_START_TIME, intl));
        }
    }

    const isEditing = watch("formMode");
    if (!isEditing) {
        // Form is acting up a bit when editing and its value schema checks.
        // Save move as a form value to check with schema conditions
        register("formMode");
        if (ruleToEdit) {
            setValue("formMode", "edit");
        } else {
            setValue("formMode", "add");
        }
    }

    const reminderText = watch("reminderText");
    const reminderTextLength = reminderText
        ? reminderText.length
        : ruleToEdit?.reminderText
        ? ruleToEdit.reminderText.length
        : 0;

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage
                        id={!ruleToEdit ? "REMINDER_TEMPLATE.RULES.ADD.TITLE" : "REMINDER_TEMPLATE.RULES.EDIT.TITLE"}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className={classes.formContent}>
                        <Form.Row>
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="REMINDER_TEMPLATE.RULES.TYPE" />
                                </Form.Label>
                                <DefaultAsyncTypeahead
                                    id="typeahead-types"
                                    onChange={(types) => {
                                        setValue("type", types[0], {
                                            shouldValidate: true,
                                        });
                                    }}
                                    options={[
                                        getRuleTypeOption(RULE_TYPE_EMAIL, intl),
                                        getRuleTypeOption(RULE_TYPE_SMS, intl),
                                    ]}
                                    selected={ruleType}
                                    clearButton={false}
                                    disabled={ruleToEdit ? true : false}
                                    onSearch={() => {}}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="REMINDER_TEMPLATE.RULES.MINUTES" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg col-xl-2"
                                name="minutes"
                                ref={register}
                                isInvalid={errors.minutes}
                                defaultValue={ruleToEdit?.minutes}
                                disabled={ruleToEdit ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">{errors.minutes?.message}</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage id="REMINDER_TEMPLATE.RULES.MINUTES_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>

                        <Form.Row>
                            <Form.Group as={Col} xl={6}>
                                <Form.Label>
                                    <FormattedMessage id="REMINDER_TEMPLATE.RULES.CONDITIONS" />
                                </Form.Label>
                                <DefaultAsyncTypeahead
                                    id="typeahead-conditions"
                                    onChange={(conditions) => {
                                        setValue("condition", conditions[0], {
                                            shouldValidate: true,
                                        });
                                    }}
                                    options={[
                                        getRuleConditionOption(RULE_CONDITION_BEFORE_BOOKING_START_TIME, intl),
                                        getRuleConditionOption(RULE_CONDITION_BEFORE_BOOKING_END_TIME, intl),
                                        getRuleConditionOption(
                                            RULE_CONDITION_BEFORE_BOOKING_END_TIME_NO_UPCOMING,
                                            intl
                                        ),
                                    ]}
                                    selected={condition}
                                    clearButton={false}
                                    disabled={ruleToEdit ? true : false}
                                    onSearch={() => {}}
                                />
                            </Form.Group>
                        </Form.Row>

                        {ruleType?.id === RULE_TYPE_SMS && (
                            <>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="REMINDER_TEMPLATE.RULES.REMINDER_TITLE" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control form-control-lg col-xl-4"
                                        name="reminderTitle"
                                        ref={register}
                                        isInvalid={errors.reminderTitle}
                                        defaultValue={ruleToEdit?.reminderTitle}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.reminderTitle?.message}
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        <FormattedMessage id="REMINDER_TEMPLATE.RULES.REMINDER_TITLE_DESCRIPTION.SMS" />
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="REMINDER_TEMPLATE.RULES.REMINDER_TEXT" />
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className="form-control form-control-lg col-xl-8"
                                        name="reminderText"
                                        ref={register}
                                        isInvalid={errors.reminderText}
                                        defaultValue={ruleToEdit?.reminderText}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.reminderText?.message}
                                    </Form.Control.Feedback>
                                    <Form.Text>
                                        <FormattedMessage
                                            id="REMINDER_TEMPLATE.RULES.REMINDER_TEXT_COUNTER"
                                            values={{ length: reminderTextLength }}
                                        />
                                    </Form.Text>
                                    <Form.Text className="text-muted">
                                        <FormattedMessage id="REMINDER_TEMPLATE.RULES.REMINDER_TEXT_DESCRIPTION" />
                                    </Form.Text>
                                </Form.Group>
                            </>
                        )}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id={!ruleToEdit ? "COMMON.ADD" : "COMMON.SAVE"} />}
                />
            </Modal.Footer>
        </Modal>
    );
}

function getRuleTypeOption(ruleType, intl) {
    if (ruleType === RULE_TYPE_EMAIL) {
        return {
            id: RULE_TYPE_EMAIL,
            name: intl.formatMessage({ id: "REMINDER_TEMPLATE.RULES.TYPE.EMAIL" }),
        };
    } else if (ruleType === RULE_TYPE_SMS) {
        return {
            id: RULE_TYPE_SMS,
            name: intl.formatMessage({ id: "REMINDER_TEMPLATE.RULES.TYPE.SMS" }),
        };
    }
}

function getRuleConditionOption(ruleType, intl) {
    if (ruleType === RULE_CONDITION_BEFORE_BOOKING_START_TIME) {
        return {
            id: RULE_CONDITION_BEFORE_BOOKING_START_TIME,
            name: intl.formatMessage({ id: "REMINDER_TEMPLATE.RULES.CONDITION.BEFORE_START_TIME" }),
        };
    } else if (ruleType === RULE_CONDITION_BEFORE_BOOKING_END_TIME) {
        return {
            id: RULE_CONDITION_BEFORE_BOOKING_END_TIME,
            name: intl.formatMessage({ id: "REMINDER_TEMPLATE.RULES.CONDITION.BEFORE_END_TIME" }),
        };
    } else if (ruleType === RULE_CONDITION_BEFORE_BOOKING_END_TIME_NO_UPCOMING) {
        return {
            id: RULE_CONDITION_BEFORE_BOOKING_END_TIME_NO_UPCOMING,
            name: intl.formatMessage({ id: "REMINDER_TEMPLATE.RULES.CONDITION.BEFORE_END_TIME_NO_UPCOMING" }),
        };
    }
}

export default connect(null, { ...reminderTemplateActions })(ReminderTemplateRuleModal);
