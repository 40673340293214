import React from "react";
import { makeStyles } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const useStyles = makeStyles((theme) => ({
    phoneContainer: {
        "&.react-tel-input .form-control:focus": {
            borderColor: "#69b3ff !important",
        },
    },
    phoneInput: {
        width: "100% !important",
        height: "calc(1.5em + 1.65rem + 2px)",
        borderColor: "#E4E6EF !important",
        fontSize: "1.08rem !important",
        fontFamily: theme.typography.fontFamily,
        backgroundColor: "#FFFFFF !important", // Keep white even if disabled
    },
}));

function BootstrapPhoneInput(props) {
    const classes = useStyles();

    return <PhoneInput containerClass={classes.phoneContainer} inputClass={classes.phoneInput} {...props} />;
}

export default BootstrapPhoneInput;
