import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";
import { Switch } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/sv";
import MomentUtils from "@date-io/moment";

function BillingTemplateDetailsForm({ billingTemplate }) {
    const { register, control, setValue, errors } = useFormContext();

    const [isInvoiceDatePickerOpen, setIsInvoiceDatePickerOpen] = useState(false);

    useEffect(() => {
        if (billingTemplate) {
            setValue("isDefault", billingTemplate.isDefault);
        }
    }, [billingTemplate, setValue]);

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={billingTemplate?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.NAME_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.ORGANISATION_NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="organisationName"
                    ref={register}
                    isInvalid={errors.organisationName}
                    defaultValue={billingTemplate?.organisationName}
                />
                <Form.Control.Feedback type="invalid">{errors.organisationName?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.ORGANISATION_NUMBER" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control-lg"
                        name="organisationNumber"
                        ref={register}
                        isInvalid={errors.organisationNumber}
                        defaultValue={billingTemplate?.organisationNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.organisationNumber?.message}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.ORGANISATION_NUMBER_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.VAT_NUMBER" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control-lg"
                        name="vatNumber"
                        ref={register}
                        isInvalid={errors.vatNumber}
                        defaultValue={billingTemplate?.vatNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.vatNumber?.message}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.VAT_NUMBER_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
            </Form.Row>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_EXPIRATION_DAYS" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-2"
                    name="invoiceExpirationDays"
                    ref={register}
                    isInvalid={errors.invoiceExpirationDays}
                    defaultValue={billingTemplate?.invoiceExpirationDays}
                />
                <Form.Control.Feedback type="invalid">{errors.invoiceExpirationDays?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_EXPIRATION_DAYS_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_EXPIRATION_DATE" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-2"
                    name="invoiceExpirationDate"
                    ref={register}
                    isInvalid={errors.invoiceExpirationDate}
                    defaultValue={
                        billingTemplate?.invoiceExpirationDate
                            ? moment(billingTemplate.invoiceExpirationDate).format("YYYY-MM-DD")
                            : null
                    }
                    onClick={() => setIsInvoiceDatePickerOpen(true)}
                    readOnly={true}
                />
                <Form.Control.Feedback type="invalid">{errors.invoiceExpirationDate?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_EXPIRATION_DATE_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} xl={3}>
                    <Form.Label>
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_FEE" />
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control-lg"
                        name="invoiceFee"
                        ref={register}
                        isInvalid={errors.invoiceFee}
                        defaultValue={billingTemplate?.invoiceFee}
                    />
                    <Form.Control.Feedback type="invalid">{errors.invoiceFee?.message}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.INVOICE_FEE_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
                <Form.Group as={Col} xl={3}>
                    <Form.Label>
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.ADMIN_FEE" />
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control-lg"
                        name="adminFee"
                        ref={register}
                        isInvalid={errors.adminFee}
                        defaultValue={billingTemplate?.adminFee}
                    />
                    <Form.Control.Feedback type="invalid">{errors.adminFee?.message}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="BILLING_TEMPLATE.DETAILS.ADMIN_FEE_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
            </Form.Row>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.IS_DEFAULT" />
                </Form.Label>
                <Controller
                    name="isDefault"
                    control={control}
                    defaultValue={billingTemplate ? billingTemplate.isDefault : false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="BILLING_TEMPLATE.DETAILS.IS_DEFAULT_DESCRIPTION" />
                </Form.Text>
            </Form.Group>

            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DatePicker
                        disablePast
                        showTodayButton
                        clearable={true}
                        ampm={false}
                        open={isInvoiceDatePickerOpen}
                        onClose={() => setIsInvoiceDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                "invoiceExpirationDate",
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD") : null,
                                {
                                    shouldValidate: true,
                                }
                            );
                        }}
                        value={""}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}

export default connect(null, serviceCategoriesActions)(BillingTemplateDetailsForm);
