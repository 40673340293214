import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { useIntl } from "react-intl";
import { getCustomerLanguageOptions, getCustomerLanguageDefault } from "../../utils/LocaleUtils";
import BootstrapPhoneInput from "../../components/inputs/BootstrapPhoneInput";
import { getDefaultPhoneCountry } from "../../utils/LocaleUtils";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    email: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    firstName: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    surname: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    phoneNumber: yup.string(),
    language: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    notes: yup.string(),
});

function AddCustomerModal(props) {
    const { isLoading } = useSelector((state) => state.customers);
    const classes = useStyles();
    const intl = useIntl();

    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        props.onSaveClicked(values);
    };

    return (
        <Form>
            <Modal size="lg" show={props.show} onHide={props.onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id={"BOOKING.ADD.CUSTOMER.MODAL.TITLE"} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.EMAIL" />
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                className="form-control form-control-lg col-xl-12"
                                ref={register}
                                isInvalid={errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.FIRST_NAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                className="form-control form-control-lg col-xl-12"
                                ref={register}
                                isInvalid={errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.SURNAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="surname"
                                className="form-control form-control-lg col-xl-12"
                                ref={register}
                                isInvalid={errors.surname}
                            />
                            <Form.Control.Feedback type="invalid">{errors.surname?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.PHONE" />
                            </Form.Label>
                            <Col xl={6} lg={12} style={{ paddingLeft: "0", paddingRight: "0" }}>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    defaultValue={""}
                                    render={(props) => {
                                        return (
                                            <BootstrapPhoneInput
                                                country={getDefaultPhoneCountry()}
                                                onChange={(v) => props.onChange(v)}
                                                masks={{ se: ".. ... .. .." }}
                                                value={props.value}
                                            />
                                        );
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="COMMON.LANGUAGE" />
                            </Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control form-control-lg col-xl-12"
                                ref={register}
                                name="language"
                                defaultValue={getCustomerLanguageDefault()}
                            >
                                {getCustomerLanguageOptions(intl).map((x) => {
                                    return (
                                        <option key={x.key} value={x.key}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.NOTES" />
                            </Form.Label>
                            <Form.Control as="textarea" rows={5} name="notes" ref={register} isInvalid={errors.notes} />
                            <Form.Control.Feedback type="invalid">{errors.notes?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={props.onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isLoading}
                        onClick={handleSubmit(onFormSubmit)}
                        label={<FormattedMessage id="COMMON.ADD" />}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default AddCustomerModal;
