import React from "react";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import BootstrapPhoneInput from "../../../components/inputs/BootstrapPhoneInput";
import { getDefaultPhoneCountry } from "../../../utils/LocaleUtils";

function BookingPageCheckout() {
    const { booking } = useSelector((state) => state.bookings);

    const { control } = useForm({});

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="BOOKING.CHECKOUT.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="BOOKING.CHECKOUT.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="BOOKING.CHECKOUT.PHONE" />
                            </Form.Label>
                            <Col xl={6} lg={12} style={{ paddingLeft: "0", paddingRight: "0" }}>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    defaultValue={""}
                                    className="form-control form-control-lg col-xl-6"
                                    render={(props) => {
                                        return (
                                            <BootstrapPhoneInput
                                                country={getDefaultPhoneCountry()}
                                                onChange={(v) => props.onChange(v)}
                                                masks={{ se: ".. ... .. .." }}
                                                value={booking?.checkout?.phoneNumber}
                                                disabled
                                            />
                                        );
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="BOOKING.CHECKOUT.MESSAGE" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={8}
                                className="form-control-lg"
                                name="customerMessage"
                                readOnly
                                disabled
                                defaultValue={booking?.checkout?.customerMessage}
                            />
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, bookingActions)(BookingPageCheckout);
