/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { LogoutPage, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import DefaultKioskPage from "./pages/bookings/kiosk/DefaultKioskPage";
import FastOfficeKioskPage from "./pages/bookings/kiosk/FastOfficeKioskPage";

export function Routes() {
    const { isAuthorized, profile } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            profile: auth.profile,
        }),
        shallowEqual
    );

    const hasLoadedProfile = typeof profile !== "undefined";
    const hasProfile = profile?.id > 0;

    return (
        <Switch>
            {!isAuthorized || !hasLoadedProfile ? (
                /*Render auth page when user at `/auth` and not authorized or if profile has not been loaded into storage yet.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : /* Otherwise redirect to root page if user has a company profile or to error if user had no company profile (`/`)*/
            hasProfile ? (
                <Redirect from="/auth" to="/" />
            ) : (
                <Redirect from="/auth" to="/error/no-company" />
            )}

            <Route path="/kiosk" component={DefaultKioskPage} />
            <Route path="/fast-office-kiosk" component={FastOfficeKioskPage} />
            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={LogoutPage} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : hasProfile ? (
                <Layout>
                    <BasePage />
                </Layout>
            ) : (
                <Redirect to="/error/no-company" />
            )}
        </Switch>
    );
}
