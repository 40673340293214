import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";
import { Switch } from "@material-ui/core";

function ReminderTemplateDetailsForm({ reminderTemplate }) {
    const { register, control, setValue, errors } = useFormContext();

    useEffect(() => {
        if (reminderTemplate) {
            setValue("isDefault", reminderTemplate.isDefault);
        }
    }, [reminderTemplate, setValue]);

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="REMINDER_TEMPLATE.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={reminderTemplate?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="REMINDER_TEMPLATE.DETAILS.NAME_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="REMINDER_TEMPLATE.DETAILS.IS_DEFAULT" />
                </Form.Label>
                <Controller
                    name="isDefault"
                    control={control}
                    defaultValue={reminderTemplate ? reminderTemplate.isDefault : false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="REMINDER_TEMPLATE.DETAILS.IS_DEFAULT_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default connect(null, serviceCategoriesActions)(ReminderTemplateDetailsForm);
