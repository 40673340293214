import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapAddButtons from "../../components/buttons/BootstrapAddButtons";
import SnackbarError from "../../components/SnackbarError";
import { useIntl } from "react-intl";
import { getCustomerLanguageOptions, getCustomerLanguageDefault } from "../../utils/LocaleUtils";
import BootstrapPhoneInput from "../../components/inputs/BootstrapPhoneInput";
import { getDefaultPhoneCountry } from "../../utils/LocaleUtils";

const schema = yup.object().shape({
    email: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    firstName: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    surname: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    phoneNumber: yup.string(),
    language: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    notes: yup.string(),
});

function CustomerPageAdd({ addCustomer, clearCustomerState, clearDisplayError, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { customer, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        return () => {
            clearCustomerState();
            clearErrorState();
        };
    }, [clearCustomerState, clearErrorState]);

    const { register, handleSubmit, control, errors, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmitRedirect = (values) => {
        addResourceFromForm(values, true);
    };

    const onFormSubmitAddAnother = (values) => {
        addResourceFromForm(values, false);
    };

    const addResourceFromForm = (formData, redirect) => {
        addCustomer(profile.id, formData, () => {
            if (redirect) {
                setRedirect(true);
            } else {
                reset();
            }
        });
    };

    if (redirect && customer) {
        return <Redirect to={`/customers/${customer.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>

            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">
                        <FormattedMessage id="CUSTOMER.CREATE.TITLE" />
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.EMAIL" />
                            </Form.Label>
                            <Form.Control
                                type="email"
                                className="form-control form-control-lg col-xl-6"
                                name="email"
                                ref={register}
                                isInvalid={errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.FIRST_NAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-6"
                                name="firstName"
                                ref={register}
                                isInvalid={errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.SURNAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-6"
                                name="surname"
                                ref={register}
                                isInvalid={errors.surname}
                            />
                            <Form.Control.Feedback type="invalid">{errors.surname?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.PHONE" />
                            </Form.Label>
                            <Col xl={4} lg={12} style={{ paddingLeft: "0", paddingRight: "0" }}>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    defaultValue={""}
                                    render={(props) => {
                                        return (
                                            <BootstrapPhoneInput
                                                country={getDefaultPhoneCountry()}
                                                onChange={(v) => props.onChange(v)}
                                                masks={{ se: ".. ... .. .." }}
                                                value={props.value}
                                            />
                                        );
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="COMMON.LANGUAGE" />
                            </Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control form-control-lg col-xl-4"
                                ref={register}
                                name="language"
                                defaultValue={getCustomerLanguageDefault()}
                            >
                                {getCustomerLanguageOptions(intl).map((x) => {
                                    return (
                                        <option key={x.key} value={x.key}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.DETAILS.NOTES" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className="form-control form-control-lg col-xl-6"
                                name="notes"
                                ref={register}
                                isInvalid={errors.notes}
                            />
                            <Form.Control.Feedback type="invalid">{errors.notes?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <BootstrapAddButtons
                            addButtonIsLoading={isLoading}
                            addAnotherButtonIsLoading={isLoading}
                            addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                            addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                        />
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, { ...customerActions, ...errorActions })(CustomerPageAdd);
