import React, { useState, useEffect } from "react";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Switch } from "@material-ui/core";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { getColorSettingsOptions, RESOURCE_COLOR_MODE_OWN, getPermissionName } from "./utils";
import { getRGBACssFromARGBHex } from "../../utils/ColorUtils";
import { SERVICE_COLOR_DEFAULT } from "../services/utils";
import SelectColorModal from "../../components/SelectColorModal";

const schema = yup.object().shape({
    firstName: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    surname: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    email: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    permissionId: yup.number().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
});

function ResourcePageDetailsStaff({ getStaffAvailablePermissions, updateResource }) {
    const intl = useIntl();
    const { resource, staffPermissionsDetails, isUpdating } = useSelector((state) => state.resources);
    const { profile } = useSelector((state) => state.auth);
    const resourceId = resource?.id;

    const [showColorPicker, setShowColorPicker] = useState(false);

    const { register, handleSubmit, errors, control, setValue, watch } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (resourceId) {
            getStaffAvailablePermissions(resourceId, profile.id);
        }
    }, [getStaffAvailablePermissions, resourceId, profile.id]);

    useEffect(() => {
        if (resource) {
            setValue("isSupport", resource.isSupport);
            setValue("permissionId", resource.permission.id);

            if (resource.color) {
                setValue("colorMode", RESOURCE_COLOR_MODE_OWN);
                setValue("color", resource.color);
            }
        }
    }, [resource, setValue]);

    const onFormSubmit = (values) => {
        let data = values;
        data.permission = { id: data.permissionId };
        data.color = data.colorMode === RESOURCE_COLOR_MODE_OWN ? values.color : null;

        delete data.permissionId;
        delete data.colorMode;
        updateResource(resource.type, resource.id, data);
    };

    const colorMode = watch("colorMode");
    const color = watch("color");
    if (!color) {
        register("color");
        setValue("color", SERVICE_COLOR_DEFAULT);
    }

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.STAFF.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.STAFF.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                            <FormattedMessage id="COMMON.SAVE" />
                        </BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.STAFF.DETAILS.FIRST_NAME" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="firstName"
                                        ref={register}
                                        isInvalid={errors.firstName}
                                        defaultValue={resource?.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.STAFF.DETAILS.SURNAME" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="surname"
                                        ref={register}
                                        isInvalid={errors.surname}
                                        defaultValue={resource?.surname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.surname?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.STAFF.DETAILS.EMAIL" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="email"
                                        className="form-control-lg"
                                        name="email"
                                        ref={register}
                                        isInvalid={errors.email}
                                        defaultValue={resource?.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.STAFF.DETAILS.PERMISSION" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Controller
                                        name="permissionId"
                                        control={control}
                                        defaultValue={resource?.permission?.id || 0}
                                        render={({ onChange, value }) => {
                                            return (
                                                <Form.Control
                                                    as="select"
                                                    value={value}
                                                    onChange={(val) => onChange(val.target.value)}
                                                >
                                                    {getPermissionOptions(staffPermissionsDetails, intl)}
                                                </Form.Control>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.STAFF.DETAILS.SUPPORT" />
                            </label>
                            <div>
                                <Controller
                                    name="isSupport"
                                    control={control}
                                    defaultValue={false}
                                    render={({ value, onChange }) => {
                                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.STAFF.DETAILS.DESCRIPTION" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        className="form-control-lg"
                                        name="description"
                                        ref={register}
                                        isInvalid={errors.description}
                                        defaultValue={resource?.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.DETAILS.COLOR" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <Controller
                                    name="colorMode"
                                    control={control}
                                    defaultValue={resource?.permission?.id || 0}
                                    render={({ onChange, value }) => {
                                        return (
                                            <Form.Control
                                                as="select"
                                                value={value}
                                                onChange={(val) => onChange(val.target.value)}
                                            >
                                                {getColorSettingsOptions(intl).map((x) => {
                                                    return (
                                                        <option key={x.id} value={x.id}>
                                                            {x.name}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        );
                                    }}
                                />
                            </div>
                            {colorMode === RESOURCE_COLOR_MODE_OWN && (
                                <div
                                    style={{
                                        backgroundColor: `${getRGBACssFromARGBHex(color)}`,
                                        width: 100,
                                        height: 44,
                                        borderRadius: "0.42rem",
                                    }}
                                    onClick={() => setShowColorPicker(true)}
                                ></div>
                            )}
                        </div>
                    </Form>
                </Card.Body>
            </div>
            {showColorPicker && (
                <SelectColorModal
                    show={showColorPicker}
                    onSaveClicked={(colorARBGHex) => {
                        setShowColorPicker(false);
                        setValue("color", colorARBGHex);
                    }}
                    onCloseClicked={() => setShowColorPicker(false)}
                    defaultColorHex={color}
                />
            )}
        </Card>
    );
}
export default connect(null, resourceActions)(ResourcePageDetailsStaff);

function getPermissionOptions(permissions, intl) {
    if (!permissions) return null;
    return permissions.map((x) => {
        return (
            <option key={x.name} value={x.id}>
                {getPermissionName(x.id, intl)}
            </option>
        );
    });
}
