import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
        // direction: "rtl",
        typography: {
            fontFamily: ["Poppins"].join(","),
        },

        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: "#17c191",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: "#3783e7",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            error: {
                // light: will be calculated from palette.primary.main,
                main: "#f44336",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
        },

        /**
         * Custom breakpoints: https://material-ui.com/customization/breakpoints/#custom-breakpoints
         * Same as bootstrap: https://getbootstrap.com/docs/5.0/layout/breakpoints/
         */
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
            },
        },

        /**
         * @see https://material-ui.com/customization/globals/#default-props
         */
        props: {
            // Name of the component ⚛️
            MuiButtonBase: {
                // The properties to apply
                disableRipple: false, // No more ripple, on the whole application 💣!
            },

            // Set default elevation to 1 for popovers.
            MuiPopover: {
                elevation: 1,
            },
        },

        overrides: {
            MuiTableCell: {
                root: {
                    padding: "4px 20px",
                },
            },
        },
    }
);

export function MaterialThemeProvider(props) {
    const { children } = props;

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
