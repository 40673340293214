import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Paper, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { AvatarCell, DateTimeCell, TextCell, ChipCell } from "../../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import * as resourceBookingUtils from "./utils";
import ResourceBookingListToolbar from "../../../components/tables/toolbar/ResourceBookingListToolbar";
import ContentDrawer from "../../../components/drawer/ContentDrawer";
import * as drawerUtils from "../../../components/drawer/utils";
import DrawerAddBooking from "../DrawerAddBooking";
import { isAtLeastStaffPermission, isAtLeastBookingPermission } from "../../resources/utils";

const drawerWidth = drawerUtils.getDrawerDefaultWidth();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    content: {
        flexGrow: 1,
    },
}));

function ResourceBookingListPage({ getResourceListBookings, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, resourceBookingsPagination } = useSelector((state) => state.bookings);
    const { displayError } = useSelector((state) => state.errors);

    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");

    const contentContainerRef = useRef(null);

    // Determine if small screen for the sake of drawer behaviour (push main content or go above)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getResourceListBookings(profile.id, pageIndex + 1, pageSize, search, status, type);
        },
        [getResourceListBookings, profile.id, search, status, type]
    );

    const onDrawerToggled = (open) => {
        setIsDrawerOpen(open);
    };

    const onSearchChanged = useCallback((value) => {
        setSearch(value);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    const onTypeChanged = useCallback((type) => {
        setType(type);
    }, []);

    const onAddBookingClicked = () => {
        setIsDrawerOpen(true);
    };

    return (
        <div className={classes.root} ref={contentContainerRef}>
            <Paper
                className={classes.content}
                style={
                    isSmallScreen
                        ? {}
                        : drawerUtils.getDrawerOffsetsStyle(
                              drawerWidth,
                              isDrawerOpen,
                              contentContainerRef,
                              document.getElementsByClassName("container")[0]
                          )
                }
            >
                <MaterialReactTable
                    key={profile.id}
                    data={useMemo(() => resourceBookingsPagination.data, [resourceBookingsPagination.data])}
                    fetchData={fetchData}
                    rowCount={resourceBookingsPagination.totalRows}
                    isLoading={isLoading}
                    error={displayError}
                    renderToolbar={() => {
                        return (
                            <ResourceBookingListToolbar
                                onSearchChanged={onSearchChanged}
                                onStatusChanged={onStatusChanged}
                                onTypeChanged={onTypeChanged}
                                onAddBookingClicked={
                                    isAtLeastBookingPermission(profile.permissionId) ? onAddBookingClicked : null
                                }
                            />
                        );
                    }}
                    columns={useMemo(
                        () => [
                            {
                                Header: <FormattedMessage id="BOOKING.RESOURCE_LIST.LIST.HEADER.RESOURCE" />,
                                accessor: "resourceName",
                                Cell: ({ row }) => {
                                    if (isAtLeastStaffPermission(profile.permissionId)) {
                                        return (
                                            <Link
                                                to={`/resources/${row.original.resourceId}/${row.original.resourceType}`}
                                            >
                                                {AvatarCell(row.original.resourceAvatarUrl, row.original.resourceName)}
                                            </Link>
                                        );
                                    }

                                    return AvatarCell(row.original.resourceAvatarUrl, row.original.resourceName);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.RESOURCE_LIST.LIST.HEADER.CUSTOMER" />,
                                accessor: "customer",
                                maxWidth: 275,
                                Cell: ({ row }) => {
                                    if (!row.original.customerId) return <></>;
                                    return (
                                        <Link to={`/customers/${row.original.customerId}`}>
                                            {AvatarCell(row.original.customerAvatarUrl, row.original.customerName)}
                                        </Link>
                                    );
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.RESOURCE_LIST.LIST.HEADER.START_TIME" />,
                                accessor: "bookingStartTime",
                                Cell: (props) => {
                                    return DateTimeCell(props.value);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.RESOURCE_LIST.LIST.HEADER.END_TIME" />,
                                accessor: "bookingEndTime",
                                Cell: (props) => {
                                    return DateTimeCell(props.value);
                                },
                            },
                            {
                                Header: <FormattedMessage id="BOOKING.RESOURCE_LIST.LIST.HEADER.STATUS" />,
                                accessor: "resourceBookingStatus",
                                Cell: (props) => {
                                    if (props.value === resourceBookingUtils.RESOURCE_BOOKING_STATUS_UNAVAILABLE) {
                                        return ChipCell(
                                            "error",
                                            <FormattedMessage id="BOOKING.RESOURCE_LIST.STATUS.UNAVAILABLE" />
                                        );
                                    } else if (
                                        props.value === resourceBookingUtils.RESOURCE_BOOKING_STATUS_UPCOMING_BOOKING
                                    ) {
                                        return ChipCell(
                                            "warning",
                                            <FormattedMessage id="BOOKING.RESOURCE_LIST.STATUS.UPCOMING_BOOKING" />
                                        );
                                    } else if (props.value === resourceBookingUtils.RESOURCE_BOOKING_STATUS_AVAILABLE) {
                                        return ChipCell(
                                            "success",
                                            <FormattedMessage id="BOOKING.RESOURCE_LIST.STATUS.AVAILABLE" />
                                        );
                                    }
                                    return TextCell();
                                },
                            },
                        ],
                        [profile.permissionId]
                    )}
                />
            </Paper>

            <ContentDrawer
                isOpen={isDrawerOpen}
                onDrawerToggle={onDrawerToggled}
                width={drawerWidth}
                paperProps={{ style: { backgroundColor: "#FAFAFA" } }}
            >
                {isAtLeastBookingPermission(profile.permissionId) && (
                    <DrawerAddBooking
                        onDrawerToggle={onDrawerToggled}
                        onBookingAdded={() => getResourceListBookings(profile.id, 1, 10)}
                    />
                )}
            </ContentDrawer>
        </div>
    );
}

export default connect(null, { ...bookingActions, ...errorActions })(ResourceBookingListPage);
