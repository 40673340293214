import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core";
import BootstrapSaveButton from "./BootstrapSaveButton";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
    },
    button: {
        marginRight: "20px",
    },
    spinner: {
        marginRight: theme.spacing(1),
    },
}));

function BootstrapAddButtons({
    addButtonLabel,
    addButtonIsLoading,
    addButtonOnClick,
    addAnotherButtonLabel,
    addAnotherButtonIsLoading,
    addAnotherButtonOnClick,
}) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <BootstrapSaveButton
                label={addButtonLabel || <FormattedMessage id="COMMON.ADD" />}
                className={classes.button}
                isLoading={addButtonIsLoading}
                onClick={addButtonOnClick}
            ></BootstrapSaveButton>
            <BootstrapSaveButton
                label={addAnotherButtonLabel || <FormattedMessage id="COMMON.ADD.ANOTHER" />}
                className={classes.button}
                isLoading={addAnotherButtonIsLoading}
                onClick={addAnotherButtonOnClick}
            ></BootstrapSaveButton>
        </div>
    );
}

export default BootstrapAddButtons;
