import React, { useEffect, useState } from "react";
import { actions as mediaActions } from "../../../redux/media/mediaRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import "./resourcehours.css";
import MediaContainer from "./MediaContainer";
import MediaContainerUploadModal from "./MediaContainerUploadModal";

function ResourceGroupPageMedia({
    getMediaContainerItems,
    updateMediaContainerItem,
    deleteMediaContainerItem,
    clearMediaState,
}) {
    const { resourceGroup } = useSelector((state) => state.resources);
    const { listPagination } = useSelector((state) => state.media);
    const [showUploadModal, setShowUploadModal] = useState();
    const resourceGroupId = resourceGroup?.id;
    const mediaContainerId = resourceGroup?.mediaContainerId;

    useEffect(() => {
        if (mediaContainerId) {
            getMediaContainerItems(mediaContainerId);
        }
    }, [mediaContainerId, getMediaContainerItems]);

    useEffect(() => {
        return () => {
            clearMediaState();
        };
    }, [clearMediaState]);

    const mediaContainerItems = listPagination ? listPagination.data : [];

    const onUpdateCoverClicked = (mediaItemId, isCover) => {
        updateMediaContainerItem(mediaItemId, mediaContainerId, null, isCover, null, resourceGroupId);
    };

    const onRemoveMediaClicked = (mediaItemId) => {
        deleteMediaContainerItem(mediaItemId, mediaContainerId);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.GROUP.MEDIA.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.GROUP.MEDIA.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            label={<FormattedMessage id="COMMON.UPLOAD" />}
                            onClick={() => setShowUploadModal(true)}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <MediaContainer
                        mediaItems={mediaContainerItems}
                        onUpdateCoverClicked={onUpdateCoverClicked}
                        onRemoveMediaClicked={onRemoveMediaClicked}
                        supportAvatarImage={false}
                    />
                </Card.Body>
                <MediaContainerUploadModal
                    resourceGroupId={resourceGroupId}
                    show={showUploadModal}
                    onCloseClicked={() => setShowUploadModal(false)}
                />
            </div>
        </Card>
    );
}
export default connect(null, mediaActions)(ResourceGroupPageMedia);
