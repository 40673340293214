import React from "react";
import FacebookLogin from "react-facebook-login-typed";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import logo from "./logo-icons/facebook.svg";
import * as auth from "../../../../redux/auth/authRedux";
import { FACEBOOK_LOGIN_URL } from "../../../../redux/auth/authCrud";

const getFacebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

function FacebookButton(props) {
    const { intl } = props;
    const responseFacebook = ({ accessToken }) => {
        props.onLoginRequest(FACEBOOK_LOGIN_URL, { accessToken }, () => {
            props.onLoginSuccessful();
        });
    };

    return (
        <FacebookLogin
            appId={getFacebookAppId}
            scope="public_profile, email"
            responseType="code"
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
                <FacebookBtn onClick={renderProps.onClick}>
                    <img
                        src={logo}
                        style={{
                            color: "white",
                            width: "20px",
                            height: "20px",
                            marginRight: "20px",
                        }}
                        alt=""
                    />
                    {intl.formatMessage({
                        id: "AUTH.BUTTON.FACEBOOK",
                    })}
                </FacebookBtn>
            )}
        />
    );
}
const FacebookBtn = styled.button`
    background-color: #3a5a97;
    color: #fff;
    text-decoration: none;
    margin-top: 0px !important;
`;
export default injectIntl(connect(null, auth.actions)(FacebookButton));
