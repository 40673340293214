import React, { useEffect, useState } from "react";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { connect, useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import ResourceHours from "./ResourceHours";
import "./resourcehours.css";
import moment from "moment";
import CopyHoursModal from "./CopyHoursModal";
import SnackbarWarning from "../../components/SnackbarWarning";
import { useIntl } from "react-intl";
import { getResourceWeekYearAndWeek } from "./utils";

function ResourcePageHours({
    getResourceHours,
    addResourceHour,
    updateResourceHour,
    deleteResourceHour,
    saveResourceHours,
}) {
    const { resource, isUpdating } = useSelector((state) => state.resources);
    const [selectedWeekDate, setSelectedWeekDate] = useState();
    const [showCopyModal, setShowCopyModal] = useState();
    const [warningMessage, setWarningMessage] = useState();
    const intl = useIntl();
    const resourceId = resource?.id;

    useEffect(() => {
        if (resourceId) {
            const yearAndWeekValues = getResourceWeekYearAndWeek(moment());
            getResourceHours(resourceId, yearAndWeekValues.year, yearAndWeekValues.week);
        }
    }, [resourceId, getResourceHours]);

    const onWeekChanged = (weekDate) => {
        setSelectedWeekDate(weekDate);
        const yearAndWeekValues = getResourceWeekYearAndWeek(weekDate);
        getResourceHours(resourceId, yearAndWeekValues.year, yearAndWeekValues.week);
    };

    const onHourAdded = (date) => {
        addResourceHour(date);
    };

    const onHourUpdated = (field, date, oldTime, newTime) => {
        updateResourceHour(field, date, oldTime, newTime);
    };

    const onHourRemoved = (date, startTime, endTime) => {
        deleteResourceHour(date, startTime, endTime);
    };

    const onShowCopyModalClicked = () => {
        let editedDays = [];
        if (resource && resource.hourDays) {
            editedDays = resource.hourDays.filter((x) => x.isEdited);
        }

        if (editedDays.length > 0) {
            setWarningMessage(
                intl.formatMessage({
                    id: "RESOURCE.HOURS.COPY.WARNING.UNSAVED_CHANGES",
                })
            );
        } else {
            setShowCopyModal(true);
        }
    };

    const onHoursCopied = () => {
        setShowCopyModal(false);
        const currentWeekSelection = selectedWeekDate || moment();
        const yearAndWeekValues = getResourceWeekYearAndWeek(currentWeekSelection);
        getResourceHours(resourceId, yearAndWeekValues.year, yearAndWeekValues.week);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.HOURS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.HOURS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <Button
                            variant="secondary"
                            style={{ marginRight: "10px" }}
                            onClick={() => onShowCopyModalClicked()}
                        >
                            <FormattedMessage id="COMMON.COPY" />
                        </Button>
                        <BootstrapSaveButton isLoading={isUpdating} onClick={() => saveResourceHours(resourceId)}>
                            <FormattedMessage id="COMMON.SAVE" />
                        </BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <ResourceHours
                        hourDays={resource?.hourDays || []}
                        selectedWeekDate={selectedWeekDate}
                        onWeekChanged={onWeekChanged}
                        onHourAdded={onHourAdded}
                        onHourUpdated={onHourUpdated}
                        onHourRemoved={onHourRemoved}
                    />
                </Card.Body>
                <CopyHoursModal
                    resourceId={resourceId}
                    show={showCopyModal}
                    defaultFromWeekDate={selectedWeekDate}
                    onCloseClicked={() => setShowCopyModal(false)}
                    onHoursCopied={() => onHoursCopied()}
                />
            </div>

            <SnackbarWarning
                message={warningMessage}
                onSnackBarClosed={() => {
                    setWarningMessage(null);
                }}
            />
        </Card>
    );
}
export default connect(null, resourceActions)(ResourcePageHours);
