import React, { useState } from "react";
import { actions as customerOfferActions } from "../../../../redux/customeroffers/customerOffersRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";

const schema = yup.object().shape({
    balance: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    validTo: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function VoucherPageDetails({ updateVoucher }) {
    const { voucher, isUpdating } = useSelector((state) => state.customerOffers);

    const [isValidToDatePickerOpen, setIsValidToDatePickerOpen] = useState(false);

    const { register, handleSubmit, setValue, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        var requestData = { ...values };
        requestData.validTo = requestData.validTo ? moment(requestData.validTo).format() : null;
        updateVoucher(voucher.id, voucher, requestData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMEROFFER.VOUCHER.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMEROFFER.VOUCHER.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {voucher && !voucher?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMEROFFER.VOUCHER.DETAILS.BALANCE" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="number"
                                        name="balance"
                                        className="form-control-lg"
                                        ref={register}
                                        isInvalid={errors.balance}
                                        defaultValue={voucher?.balance}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.balance?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMEROFFER.VOUCHER.DETAILS.VALID_TO" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control form-control-lg col-xl-6"
                                        name="validTo"
                                        ref={register}
                                        onClick={() => setIsValidToDatePickerOpen(true)}
                                        defaultValue={
                                            voucher?.validTo ? moment(voucher.validTo).format("YYYY-MM-DD HH:mm") : null
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.validTo?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMEROFFER.VOUCHER.DETAILS.BALANCE_ORIGINAL" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="number"
                                        className="form-control-lg"
                                        defaultValue={voucher?.originalBalance}
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </div>
            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast={false}
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={isValidToDatePickerOpen}
                        onClose={() => setIsValidToDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue("validTo", selectedMomentDate.format("YYYY-MM-DD HH:mm"));
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </Card>
    );
}
export default connect(null, customerOfferActions)(VoucherPageDetails);
