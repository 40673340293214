import React from "react";
import { connect, useSelector } from "react-redux";
import { actions as accessyActions } from "../../../../redux/integrations/accessy/accessyRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Redirect } from "react-router";
import SnackbarError from "../../../components/SnackbarError";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    clientId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    clientSecret: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    organizationId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function AccessyCreatePage({ createAccessyIntegration, clearDisplayError }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, redirectToDetails } = useSelector((state) => state.accessy);
    const { displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

    const onFormSubmit = (values) => {
        createAccessyIntegration(profile.id, values.clientId, values.clientSecret, values.organizationId);
    };

    if (redirectToDetails) {
        return <Redirect to={"/integrations/accessy/" + integration.id} />;
    }

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.ACCESSY.CREATE.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.ACCESSY.CREATE.DESCRIPTION" />
                    <a href="https://accessy.se/">www.accessy.se</a>
                </Typography>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.ACCESSY.CREATE.CLIENT_ID" />
                        </Form.Label>
                        <Form.Control name="clientId" ref={register} isInvalid={errors.clientId} />
                        <Form.Control.Feedback type="invalid">{errors.clientId?.message}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.ACCESSY.CREATE.CLIENT_SECRET" />
                        </Form.Label>
                        <Form.Control name="clientSecret" ref={register} isInvalid={errors.clientSecret} />
                        <Form.Control.Feedback type="invalid">{errors.clientSecret?.message}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.ACCESSY.CREATE.ORGANIZATION_ID" />
                        </Form.Label>
                        <Form.Control name="organizationId" ref={register} isInvalid={errors.organizationId} />
                        <Form.Control.Feedback type="invalid">{errors.organizationId?.message}</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        <FormattedMessage id="COMMON.ADD" />
                    </Button>
                </Form>
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, { ...accessyActions, ...errorActions })(AccessyCreatePage);
