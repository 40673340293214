import React from "react";
import { connect, useSelector } from "react-redux";
import { actions as parakeyActions } from "../../../../redux/integrations/parakey/parakeyRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Redirect } from "react-router";
import SnackbarError from "../../../components/SnackbarError";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    accessToken: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function ParakeyCreatePage({ createParakeyIntegration, clearDisplayError }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, redirectToDetails } = useSelector((state) => state.parakey);
    const { displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

    const onFormSubmit = (values) => {
        createParakeyIntegration(profile.id, values.accessToken);
    };

    if (redirectToDetails) {
        return <Redirect to={"/integrations/parakey/" + integration.id} />;
    }

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.PARAKEY.CREATE.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.PARAKEY.CREATE.DESCRIPTION" />
                    <a href="https://www.parakey.co/" target="_blank" rel="noreferrer">
                        www.parakey.co
                    </a>
                </Typography>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.PARAKEY.CREATE.ACCESS_TOKEN" />
                        </Form.Label>
                        <Form.Control type="text" name="accessToken" ref={register} isInvalid={errors.accessToken} />
                        <Form.Control.Feedback type="invalid">{errors.accessToken?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        <FormattedMessage id="COMMON.ADD" />
                    </Button>
                </Form>
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, { ...parakeyActions, ...errorActions })(ParakeyCreatePage);
