import React, { useState } from "react";
import { withStyles, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
    list: {
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledListItemIcon = withStyles((theme) => ({
    root: {
        minWidth: "32px",
    },
}))(ListItemIcon);

function NavigatorOptionsMenu({ menuItems }) {
    const [anchorElement, setAnchorElement] = useState(null);

    return (
        <>
            <IconButton onClick={(event) => setAnchorElement(event.currentTarget)}>
                <MoreVertIcon />
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorElement}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={() => setAnchorElement(null)}
            >
                {menuItems.map((menuItem, i) => {
                    return (
                        <div key={i}>
                            <MenuItem
                                onClick={() => {
                                    if (menuItem.onClick) menuItem.onClick();
                                    setAnchorElement(null);
                                }}
                            >
                                <StyledListItemIcon>{menuItem.icon}</StyledListItemIcon>
                                <ListItemText primary={menuItem.label} />
                            </MenuItem>
                            {i < menuItems.length - 1 && <Divider />}
                        </div>
                    );
                })}
            </StyledMenu>
        </>
    );
}

export default NavigatorOptionsMenu;
