import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as bankIDActions } from "../../../../redux/integrations/bankid/bankIDRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import BankIDPageNavigator from "./BankIDPageNavigator";
import BankIDPageServices from "./BankIDPageServices";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function BankIDPage({ match, getBankIDIntegration, clearBankIDState, clearDisplayError, clearErrorState }) {
    const integrationId = parseInt(match.params.id, 10);
    const { isLoading, integration } = useSelector((state) => state.bankid);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!integration || integration.id !== integrationId)) {
            getBankIDIntegration(integrationId);
        }
    }, [getBankIDIntegration, integration, integrationId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearBankIDState();
            clearErrorState();
        };
    }, [clearBankIDState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <BankIDPageNavigator integrationId={integrationId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/integrations/bankid/${integrationId}`}
                            exact={true}
                            to={`/integrations/bankid/${integrationId}/services`}
                        />
                        <Route path={`/integrations/bankid/${integrationId}/services`} component={BankIDPageServices} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...bankIDActions,
    ...errorActions,
})(BankIDPage);
