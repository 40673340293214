import React, { useState } from "react";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import { isAtLeastBookingPermission } from "../../resources/utils";

const schema = yup.object().shape({
    paymentUrlExpiresAt: yup.string(),
});

function BookingPageTransactionLink({ updateBooking }) {
    const { profile } = useSelector((state) => state.auth);
    const { booking, isUpdating } = useSelector((state) => state.bookings);

    const [expiresAtDatePickerOpen, setExpiresAtDatePickerOpen] = useState(false);

    const { register, handleSubmit, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        let updatedBooking = { ...booking };

        updatedBooking.paymentUrlExpiresAt = moment(values.paymentUrlExpiresAt).format();

        updateBooking(booking.id, booking, updatedBooking);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="BOOKING.TRANSACTION_LINK.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="BOOKING.TRANSACTION_LINK.SUBTITLE" />
                        </span>
                    </div>
                    {booking && !booking?.deletedAt && isAtLeastBookingPermission(profile.permissionId) && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="BOOKING.TRANSACTION_LINK.PAYMENT_URL" />
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="paymentUrl"
                                    className="form-control form-control-lg col-xl-8"
                                    readOnly
                                    disabled
                                    defaultValue={booking?.paymentUrl}
                                />
                                <InputGroup.Text
                                    onClick={() => navigator.clipboard.writeText(booking?.paymentUrl)}
                                    style={{
                                        borderTopLeftRadius: "0rem",
                                        borderBottomLeftRadius: "0rem",
                                        marginLeft: "-1px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <FormattedMessage id="COMMON.COPY" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="BOOKING.TRANSACTION_LINK.VALID_TO" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-4"
                                name="paymentUrlExpiresAt"
                                ref={register}
                                onClick={() => setExpiresAtDatePickerOpen(true)}
                                defaultValue={
                                    booking?.paymentUrlExpiresAt
                                        ? moment(booking?.paymentUrlExpiresAt).format("YYYY-MM-DD HH:mm")
                                        : ""
                                }
                            />
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    showTodayButton
                    ampm={false}
                    style={{ display: "none" }}
                    open={expiresAtDatePickerOpen}
                    onClose={() => setExpiresAtDatePickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue("paymentUrlExpiresAt", selectedMomentDate.format("YYYY-MM-DD HH:mm"), {
                            shouldValidate: true,
                        });
                    }}
                />
            </MuiPickersUtilsProvider>
        </Card>
    );
}
export default connect(null, bookingActions)(BookingPageTransactionLink);
