import React from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import MembershipBookingSettingsForm from "./MembershipBookingSettingsForm";
import {
    OFFER_VALID_FOR_TYPE_ALL_SERVICES,
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE,
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION,
    OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME,
} from "../utils";
import moment from "moment";

const schema = yup.object().shape({
    discountPercentage: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    maxBookingMinutes: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />),
    validUntilValue: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    validUntilTimeUnit: yup.string(),
    fixedEndTime: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode !== OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .date()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .date()
                .transform((_, val) => (val ? new Date(val) : null))
                .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
                .typeError(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />);
        }
    }),
    activeLimit: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
});

function MembershipPageBookingSettings({ updateMembership }) {
    const { membership, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalMembership = { ...membership };
        originalMembership.categoryId = originalMembership?.category?.id;

        let updatedMembershipData = { ...membership };
        updatedMembershipData.isValidForAllServices =
            values.validForType === OFFER_VALID_FOR_TYPE_ALL_SERVICES ? true : false;
        updatedMembershipData.discountPercentage = values.discountPercentage;
        updatedMembershipData.validUntilType = values.validUntilType;
        updatedMembershipData.activeLimit = values.activeLimit;

        if (
            updatedMembershipData.validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE ||
            updatedMembershipData.validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION
        ) {
            updatedMembershipData.validUntilValue = values.validUntilValue;
            updatedMembershipData.validUntilTimeUnit = values.validUntilTimeUnit;
        } else if (updatedMembershipData.validUntilType === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            updatedMembershipData.fixedEndTime = moment(values.fixedEndTime).format();
        }

        updatedMembershipData.maxBookingMinutes = values.maxBookingMinutes ? values.maxBookingMinutes : null;
        updatedMembershipData.maxBookingMinutesPerUnit = values.maxBookingMinutes
            ? values.maxBookingMinutesPerUnit.id
            : null;

        updateMembership(membership.id, originalMembership, updatedMembershipData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.MEMBERSHIP.BOOKING_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {membership && !membership?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <MembershipBookingSettingsForm service={membership} showResources={false} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(MembershipPageBookingSettings);
