import React from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import ProductBookingSettingsForm from "./ProductBookingSettingsForm";

const schema = yup.object().shape({
    minQuantity: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    maxQuantity: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
});

function ProductPageBookingSettings({ updateProduct }) {
    const { product, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalProduct = { ...product };
        originalProduct.categoryId = originalProduct?.category?.id;

        let updatedProductData = { ...product };
        updatedProductData.minQuantity = values.minQuantity;
        updatedProductData.maxQuantity = values.maxQuantity;

        updateProduct(product.id, originalProduct, updatedProductData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {product && !product?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <ProductBookingSettingsForm service={product} showResources={false} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(ProductPageBookingSettings);
