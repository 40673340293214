import { put, takeLatest } from "redux-saga/effects";
import {
    getProfileReminderTemplates,
    getProfileReminderTemplate,
    addProfileReminderTemplate,
    updateProfileReminderTemplate,
    deleteProfileReminderTemplate,
    getReminderTemplatePreview,
    getProfileReminderTemplateRules,
    addReminderTemplateRule,
    updateReminderTemplateRule,
    deleteProfileReminderTemplateRule,
} from "./reminderTemplateCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import { findAndSpliceArrayByProperty } from "../reduxUtils";

export const actionTypes = {
    GET_PROFILE_REMINDER_TEMPLATES_REQUEST: "GET_PROFILE_REMINDER_TEMPLATES_REQUEST",
    GET_PROFILE_REMINDER_TEMPLATES_SUCCESS: "GET_PROFILE_REMINDER_TEMPLATES_SUCCESS",
    GET_PROFILE_REMINDER_TEMPLATE_REQUEST: "GET_PROFILE_REMINDER_TEMPLATE_REQUEST",
    GET_PROFILE_REMINDER_TEMPLATE_SUCCESS: "GET_PROFILE_REMINDER_TEMPLATE_SUCCESS",
    ADD_PROFILE_REMINDER_TEMPLATE_REQUEST: "ADD_PROFILE_REMINDER_TEMPLATE_REQUEST",
    ADD_PROFILE_REMINDER_TEMPLATE_SUCCESS: "ADD_PROFILE_REMINDER_TEMPLATE_SUCCESS",
    UPDATE_PROFILE_REMINDER_TEMPLATE_REQUEST: "UPDATE_PROFILE_REMINDER_TEMPLATE_REQUEST",
    UPDATE_PROFILE_REMINDER_TEMPLATE_SUCCESS: "UPDATE_PROFILE_REMINDER_TEMPLATE_SUCCESS",
    DELETE_PROFILE_REMINDER_TEMPLATE_REQUEST: "DELETE_PROFILE_REMINDER_TEMPLATE_REQUEST",
    DELETE_PROFILE_REMINDER_TEMPLATE_SUCCESS: "DELETE_PROFILE_REMINDER_TEMPLATE_SUCCESS",
    GET_REMINDER_TEMPLATE_PREVIEW_REQUEST: "GET_REMINDER_TEMPLATE_PREVIEW_REQUEST",
    GET_REMINDER_TEMPLATE_PREVIEW_SUCCESS: "GET_REMINDER_TEMPLATE_PREVIEW_SUCCESS",
    GET_REMINDER_TEMPLATE_RULES_REQUEST: "GET_REMINDER_TEMPLATE_RULES_REQUEST",
    GET_REMINDER_TEMPLATE_RULES_SUCCESS: "GET_REMINDER_TEMPLATE_RULES_SUCCESS",
    ADD_REMINDER_TEMPLATE_RULE_REQUEST: "ADD_REMINDER_TEMPLATE_RULE_REQUEST",
    ADD_REMINDER_TEMPLATE_RULE_SUCCESS: "ADD_REMINDER_TEMPLATE_RULE_SUCCESS",
    UPDATE_REMINDER_TEMPLATE_RULE_REQUEST: "UPDATE_REMINDER_TEMPLATE_RULE_REQUEST",
    UPDATE_REMINDER_TEMPLATE_RULE_SUCCESS: "UPDATE_REMINDER_TEMPLATE_RULE_SUCCESS",
    DELETE_REMINDER_TEMPLATE_RULE_REQUEST: "DELETE_REMINDER_TEMPLATE_RULE_REQUEST",
    DELETE_REMINDER_TEMPLATE_RULE_SUCCESS: "DELETE_REMINDER_TEMPLATE_RULE_SUCCESS",
    CLEAR_REMINDER_TEMPLATES_STATE: "CLEAR_REMINDER_TEMPLATES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    rulesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_REMINDER_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_REMINDER_TEMPLATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PROFILE_REMINDER_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_REMINDER_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reminderTemplate: action.payload.response,
            };
        }

        case actionTypes.ADD_PROFILE_REMINDER_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PROFILE_REMINDER_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reminderTemplate: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PROFILE_REMINDER_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PROFILE_REMINDER_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reminderTemplate: action.payload.response,
            };
        }

        case actionTypes.DELETE_PROFILE_REMINDER_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PROFILE_REMINDER_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reminderTemplate: action.payload.response,
            };
        }

        case actionTypes.GET_REMINDER_TEMPLATE_PREVIEW_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_REMINDER_TEMPLATE_PREVIEW_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reminderTemplatePreview: action.payload.response,
            };
        }

        case actionTypes.GET_REMINDER_TEMPLATE_RULES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_REMINDER_TEMPLATE_RULES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                rulesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_REMINDER_TEMPLATE_RULE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_REMINDER_TEMPLATE_RULE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.UPDATE_REMINDER_TEMPLATE_RULE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_REMINDER_TEMPLATE_RULE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reminderTemplateRule: action.payload.response,
            };
        }

        case actionTypes.DELETE_REMINDER_TEMPLATE_RULE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_REMINDER_TEMPLATE_RULE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.rulesPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                rulesPagination: {
                    ...state.rulesPagination,
                    data: alteredStateArray,
                    totalRows: state.rulesPagination.totalRows - 1,
                },
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_REMINDER_TEMPLATES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getProfileReminderTemplates: (profileId, page, perPage) => ({
        type: actionTypes.GET_PROFILE_REMINDER_TEMPLATES_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getProfileReminderTemplate: (id) => ({
        type: actionTypes.GET_PROFILE_REMINDER_TEMPLATE_REQUEST,
        payload: { id },
    }),

    addProfileReminderTemplate: (profileId, data, callback) => ({
        type: actionTypes.ADD_PROFILE_REMINDER_TEMPLATE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateProfileReminderTemplate: (id, originalReminderTemplate, updatedReminderTemplate) => ({
        type: actionTypes.UPDATE_PROFILE_REMINDER_TEMPLATE_REQUEST,
        payload: { id, originalReminderTemplate, updatedReminderTemplate },
    }),

    deleteProfileReminderTemplate: (id) => ({
        type: actionTypes.DELETE_PROFILE_REMINDER_TEMPLATE_REQUEST,
        payload: { id },
    }),

    getReminderTemplatePreview: (id) => ({
        type: actionTypes.GET_REMINDER_TEMPLATE_PREVIEW_REQUEST,
        payload: { id },
    }),

    getReminderTemplateRules: (reminderTemplateId, page, perPage) => ({
        type: actionTypes.GET_REMINDER_TEMPLATE_RULES_REQUEST,
        payload: { reminderTemplateId, page, perPage },
    }),

    addReminderTemplateRule: (profileId, data, callback) => ({
        type: actionTypes.ADD_REMINDER_TEMPLATE_RULE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateReminderTemplateRule: (id, originalReminderTemplateRule, updatedReminderTemplateRule, callback) => ({
        type: actionTypes.UPDATE_REMINDER_TEMPLATE_RULE_REQUEST,
        payload: { id, originalReminderTemplateRule, updatedReminderTemplateRule, callback },
    }),

    deleteReminderTemplateRule: (id, callback) => ({
        type: actionTypes.DELETE_REMINDER_TEMPLATE_RULE_REQUEST,
        payload: { id, callback },
    }),

    clearReminderTemplateState: () => ({
        type: actionTypes.CLEAR_REMINDER_TEMPLATES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROFILE_REMINDER_TEMPLATES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileReminderTemplates(
                payload.profileId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_PROFILE_REMINDER_TEMPLATES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROFILE_REMINDER_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileReminderTemplate(payload.id);

            yield put({
                type: actionTypes.GET_PROFILE_REMINDER_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROFILE_REMINDER_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addProfileReminderTemplate(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PROFILE_REMINDER_TEMPLATE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PROFILE_REMINDER_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateProfileReminderTemplate(
                payload.id,
                payload.originalReminderTemplate,
                {
                    ...payload.originalReminderTemplate,
                    ...payload.updatedReminderTemplate,
                }
            );
            yield put({
                type: actionTypes.UPDATE_PROFILE_REMINDER_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PROFILE_REMINDER_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteProfileReminderTemplate(payload.id);
            yield put({
                type: actionTypes.DELETE_PROFILE_REMINDER_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_REMINDER_TEMPLATE_PREVIEW_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getReminderTemplatePreview(payload.id);

            yield put({
                type: actionTypes.GET_REMINDER_TEMPLATE_PREVIEW_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_REMINDER_TEMPLATE_RULES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileReminderTemplateRules(
                payload.reminderTemplateId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_REMINDER_TEMPLATE_RULES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_REMINDER_TEMPLATE_RULE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addReminderTemplateRule(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_REMINDER_TEMPLATE_RULE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_REMINDER_TEMPLATE_RULE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateReminderTemplateRule(
                payload.id,
                payload.originalReminderTemplateRule,
                {
                    ...payload.originalReminderTemplateRule,
                    ...payload.updatedReminderTemplateRule,
                }
            );

            yield put({
                type: actionTypes.UPDATE_REMINDER_TEMPLATE_RULE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_REMINDER_TEMPLATE_RULE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteProfileReminderTemplateRule(payload.id);

            yield put({
                type: actionTypes.DELETE_REMINDER_TEMPLATE_RULE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
