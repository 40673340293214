export const TYPE_DISCOUNT_PERCENTAGE = "discountPercentage";
export const TYPE_DISCOUNT_AMOUNT = "discountAmount";
export const TYPE_FINAL_PRICE = "finalPrice";

export const CHECKOUT_ITEM_RULE_NONE = "";
export const CHECKOUT_ITEM_RULE_APPLY_TO_CHEAPEST = "applyToCheapest";

export const getPromotionCodeTypeOptions = (intl) => {
    return [
        {
            name: intl.formatMessage({
                id: "PROMOTIONCODE.TYPE.DISCOUNT_PERCENTAGE",
            }),
            key: TYPE_DISCOUNT_PERCENTAGE,
        },
        {
            name: intl.formatMessage({
                id: "PROMOTIONCODE.TYPE.DISCOUNT_AMOUNT",
            }),
            key: TYPE_DISCOUNT_AMOUNT,
        },
        {
            name: intl.formatMessage({
                id: "PROMOTIONCODE.TYPE.FINAL_PRICE",
            }),
            key: TYPE_FINAL_PRICE,
        },
    ];
};

export const getCheckoutItemRulesTypeOptions = (intl) => {
    return [
        {
            name: intl.formatMessage({
                id: "PROMOTIONCODE.CHECKOUT_ITEM_RULE.NONE",
            }),
            key: CHECKOUT_ITEM_RULE_NONE,
        },
        {
            name: intl.formatMessage({
                id: "PROMOTIONCODE.CHECKOUT_ITEM_RULE.APPLY_TO_CHEAPEST",
            }),
            key: CHECKOUT_ITEM_RULE_APPLY_TO_CHEAPEST,
        },
    ];
};
