import React, { useState, useMemo, useCallback } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    TextCell,
    DateTimeCell,
    ChipCell,
    AvatarCell,
    DeleteActionButton,
} from "../../../components/tables/MaterialTableCells";
import { Link } from "react-router-dom";
import {
    COURSE_ATTENDEE_STATUS_CONFIRMED,
    COURSE_ATTENDEE_STATUS_UNCONFIRMED,
    COURSE_ATTENDEE_STATUS_UNPAID,
    COURSE_ATTENDEE_STATUS_CANCELLED,
    COURSE_ATTENDEE_STATUS_DELETED,
} from "../utils";
import CourseAttendeeListToolbar from "../../../components/tables/toolbar/CourseAttendeeListToolbar";

function CoursePageAttendees({ getCourseAttendees, deleteCourseAttendee }) {
    const { course, courseAttendeesPagination, isLoading } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const serviceId = course?.id;

    const [status, setStatus] = useState("attending");

    const isBookPerOccasion = course && course.isBookPerOccasion;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getCourseAttendees(serviceId, pageIndex + 1, pageSize, !isBookPerOccasion ? status : null);
            }
        },
        [serviceId, getCourseAttendees, status, isBookPerOccasion]
    );

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.ATTENDEES.TITLE" />
                        </h3>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceId}
                        data={useMemo(() => courseAttendeesPagination.data, [courseAttendeesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={courseAttendeesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        renderToolbar={() => {
                            return !isBookPerOccasion ? (
                                <CourseAttendeeListToolbar onStatusChanged={onStatusChanged} />
                            ) : (
                                <></>
                            );
                        }}
                        columns={useMemo(() => {
                            let columns = [
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.ATTENDEES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.ATTENDEES.LIST.HEADER.CUSTOMER" />,
                                    accessor: "customer",
                                    Cell: (props) => {
                                        return (
                                            <Link to={`/customers/${props.value.id}`}>
                                                {AvatarCell(
                                                    props.value.avatarUrl,
                                                    `${props.value.firstName} ${props.value.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.ATTENDEES.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.ATTENDEES.LIST.HEADER.OCCASIONS" />,
                                    accessor: "occasionCount",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                            ];

                            if (!isBookPerOccasion) {
                                columns.push({
                                    Header: <FormattedMessage id="SERVICE.COURSE.ATTENDEES.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === COURSE_ATTENDEE_STATUS_CONFIRMED) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.CONFIRMED" />
                                            );
                                        } else if (props.value === COURSE_ATTENDEE_STATUS_UNCONFIRMED) {
                                            return ChipCell(
                                                "warning",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.UNCONFIRMED" />
                                            );
                                        } else if (props.value === COURSE_ATTENDEE_STATUS_UNPAID) {
                                            return ChipCell(
                                                "warning",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.UNPAID" />
                                            );
                                        } else if (props.value === COURSE_ATTENDEE_STATUS_CANCELLED) {
                                            return ChipCell(
                                                "info",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.CANCELLED" />
                                            );
                                        } else if (props.value === COURSE_ATTENDEE_STATUS_DELETED) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.DELETED" />
                                            );
                                        }
                                        return TextCell();
                                    },
                                });
                            }

                            columns.push({
                                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                id: "delete",
                                Cell: ({ row: { original: courseAttendee } }) => {
                                    const intl = useIntl();
                                    if (courseAttendee.deletedAt) return <></>;
                                    const title = intl.formatMessage(
                                        {
                                            id: "SERVICE.COURSE.ATTENDEES.DELETE.TITLE",
                                        },
                                        { firstName: courseAttendee.customer.firstName }
                                    );

                                    const content = <FormattedMessage id="SERVICE.COURSE.ATTENDEES.DELETE.TEXT" />;
                                    return DeleteActionButton(title, content, () =>
                                        deleteCourseAttendee(serviceId, courseAttendee.id)
                                    );
                                },
                            });

                            return columns;
                        }, [isBookPerOccasion, deleteCourseAttendee, serviceId])}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                        emptyMessage={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.NO_RESULT" />}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(CoursePageAttendees);
