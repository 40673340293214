import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper } from "@material-ui/core";
import {
    LinkCell,
    AvatarCell,
    DateTimeCell,
    DateCell,
    TextCell,
    TranslationCell,
} from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import SubscriptionListToolbar from "../../components/tables/toolbar/SubscriptionListToolbar";
import * as subscriptionUtils from "./utils";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_INVOICE } from "../transactions/utils";

function SubscriptionListPage({ getSubscriptions, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.subscriptions);
    const { displayError } = useSelector((state) => state.errors);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [paymentType, setPaymentType] = useState("");

    const intl = useIntl();
    const history = useHistory();

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getSubscriptions(profile.id, pageIndex + 1, pageSize, search, status, paymentType);
        },
        [getSubscriptions, profile.id, search, status, paymentType]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    const onPaymentTypeChanged = useCallback((value) => {
        setPaymentType(value);
    }, []);

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return (
                        <SubscriptionListToolbar
                            onSearchChanged={onSearchChanged}
                            onStatusChanged={onStatusChanged}
                            onPaymentTypeChanged={onPaymentTypeChanged}
                        />
                    );
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                return LinkCell("/subscriptions/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.CUSTOMER" />,
                            accessor: "customer",
                            maxWidth: 250,
                            Cell: (props) => {
                                return (
                                    <Link to={`/customers/${props.value.id}`}>
                                        {AvatarCell(
                                            props.value.avatarUrl,
                                            props.value.firstName + " " + props.value.surname
                                        )}
                                    </Link>
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.AMOUNT" />,
                            accessor: "amount",
                            Cell: (props) => {
                                return TextCell(props.value + " " + props.row.original.currency);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.PAYMENT_TYPE" />,
                            accessor: "paymentType",
                            maxWidth: 100,
                            Cell: (props) => {
                                if (props.value === PAYMENT_TYPE_CARD) {
                                    return TranslationCell("PAYMENT.PAYMENT_TYPE.CARD");
                                } else if (props.value === PAYMENT_TYPE_INVOICE) {
                                    return TranslationCell("PAYMENT.PAYMENT_TYPE.INVOICE");
                                }
                                return TextCell();
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.RECURRING_UNIT" />,
                            accessor: "recurringUnit",
                            Cell: (props) => {
                                return TextCell(subscriptionUtils.getRecurringUnitText(props.value, intl));
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.PAID_TRANSACTIONS" />,
                            accessor: "paidTransactionsCount",
                            maxWidth: 90,
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.NEXT_AT" />,
                            accessor: "nextAt",
                            maxWidth: 100,
                            Cell: (props) => {
                                return DateCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.LAST_AT" />,
                            accessor: "lastAt",
                            maxWidth: 100,
                            Cell: (props) => {
                                return DateCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return subscriptionUtils.getSubscriptionStatusCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                return (
                                    <ListActionsButton
                                        menuItems={[
                                            {
                                                icon: <LinkIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.OPEN" />,
                                                onClick: () => {
                                                    const url = "/subscriptions/" + row.original.id;
                                                    history.push(url);
                                                },
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                    [intl, history]
                )}
            />
        </Paper>
    );
}

export default connect(null, { ...subscriptionActions, ...errorActions })(SubscriptionListPage);
