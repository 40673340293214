import { convertApiColorToHexColor, convertHexColorToApiColor } from "../../utils/ColorUtils";

export const ENTRY_TYPE_APPOINTMENT = "appointment";
export const ENTRY_TYPE_COURSE_OCCASION = "course_occasion";

export const BOOKING_STATUS_UNCONFIRMED = "unconfirmed";
export const BOOKING_STATUS_CONFIRMED = "confirmed";
export const BOOKING_STATUS_UNPAID = "unpaid";
export const BOOKING_STATUS_CANCELLED = "cancelled";
export const BOOKING_STATUS_DELETED = "deleted";

export const ATTENDEE_STATUS_UNCONFIRMED = "unconfirmed";
export const ATTENDEE_STATUS_CONFIRMED = "confirmed";
export const ATTENDEE_STATUS_UNPAID = "unpaid";
export const ATTENDEE_STATUS_CANCELLED = "cancelled";
export const ATTENDEE_STATUS_DELETED = "deleted";

export const APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME = "customerName";
export const APPOINTMENT_TITLE_FORMAT_ID_RESOURCENAME = "resourceName";
export const APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME_RESOURCENAME = "customerName - resourceName";

export function isAppointmentEntry(entry) {
    return entry ? entry.type === ENTRY_TYPE_APPOINTMENT : false;
}

export function isCourseOccasionEntry(entry) {
    return entry ? entry.type === ENTRY_TYPE_COURSE_OCCASION : false;
}

export function transformEntryToEvent(entry, calendarSettings) {
    return {
        id: `${entry.type}:${entry.id}`,
        title: getEntryTitle(
            entry.type,
            calendarSettings,
            entry.customer || entry.booking?.customer,
            entry.service,
            entry.resource
        ),
        start: entry.startTime,
        end: entry.endTime,
        backgroundColor: convertApiColorToHexColor(entry.color),
        resourceId: entry?.resource?.id,
    };
}

export function transformEventToEntry(event) {
    return {
        id: parseInt(event.id.split(":")[1], 10),
        type: event.id.split(":")[0],
        title: event.title,
        startTime: event.start,
        endTime: event.end,
        color: convertHexColorToApiColor(event.backgroundColor),
    };
}

export function getCalendarSettings(profileId) {
    const allCalendarSettingsJson = localStorage.getItem("calendarSettings");
    if (allCalendarSettingsJson) {
        const allCalendarSettings = JSON.parse(allCalendarSettingsJson);
        return allCalendarSettings.find((x) => x.profileId === profileId);
    }

    return null;
}

export function getCalendarFilter(profileId) {
    const allCalendarSettingsJson = localStorage.getItem("calendarFilters");
    if (allCalendarSettingsJson) {
        const allCalendarSettings = JSON.parse(allCalendarSettingsJson);
        return allCalendarSettings.find((x) => x.profileId === profileId);
    }

    return null;
}

export function getEntryTitle(entryType, calendarSettings, entryCustomer, entryService, entryResource) {
    let title = "";
    if (entryType === ENTRY_TYPE_APPOINTMENT) {
        if (calendarSettings?.appointmentTitleFormat) {
            if (calendarSettings.appointmentTitleFormat === APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME && entryCustomer) {
                title = `${entryCustomer.firstName} ${entryCustomer.surname}`;
            } else if (
                calendarSettings.appointmentTitleFormat === APPOINTMENT_TITLE_FORMAT_ID_RESOURCENAME &&
                entryResource
            ) {
                title = entryResource.name;
            } else if (
                calendarSettings.appointmentTitleFormat === APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME_RESOURCENAME &&
                entryCustomer &&
                entryResource
            ) {
                title = title = `${entryCustomer.firstName} ${entryCustomer.surname} - ${entryResource.name}`;
            }
        } else if (entryCustomer) {
            title = `${entryCustomer.firstName} ${entryCustomer.surname}`;
        }
    } else if (entryType === ENTRY_TYPE_COURSE_OCCASION && entryService) {
        title = entryService.name;
    }

    return title;
}
