import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper } from "@material-ui/core";
import {
    AvatarCell,
    DateTimeCell,
    LinkCell,
    TextCell,
    DeleteActionButton,
} from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import CustomerListToolbar from "../../components/tables/toolbar/CustomerListToolbar";

function CustomerListPage({ getCustomers, deleteCustomer, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getCustomers(profile.id, pageIndex + 1, pageSize, search, status);
        },
        [getCustomers, profile.id, search, status]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return <CustomerListToolbar onSearchChanged={onSearchChanged} onStatusChanged={onStatusChanged} />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="CUSTOMER.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                return LinkCell("/customers/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CUSTOMER.LIST.HEADER.NAME" />,
                            accessor: "name",
                            maxWidth: 275,
                            Cell: (props) => {
                                const row = props.row;
                                return (
                                    <Link to={`/customers/${row.values.id}`}>
                                        {AvatarCell(
                                            row.original.avatarUrl,
                                            row.original.firstName + " " + row.original.surname
                                        )}
                                    </Link>
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="CUSTOMER.LIST.HEADER.EMAIL" />,
                            accessor: "email",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CUSTOMER.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CUSTOMER.LIST.HEADER.LAST_ACTIVITY" />,
                            accessor: "latestActivityAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "delete",
                            Cell: ({ row: { original: customer } }) => {
                                const intl = useIntl();

                                const { id, firstName, surname } = customer;

                                const title = `${intl.formatMessage({
                                    id: "CUSTOMER.DELETE.TITLE",
                                })} 
              ${firstName} ${surname}`;

                                const content = <FormattedMessage id="CUSTOMER.DELETE.TEXT" />;
                                return DeleteActionButton(title, content, () => deleteCustomer(id));
                            },
                        },
                    ],
                    [deleteCustomer]
                )}
            />
        </Paper>
    );
}

export default connect(null, { ...customerActions, ...errorActions })(CustomerListPage);
