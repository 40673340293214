import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function SubscriptionPaymentTypeFilter({ onPaymentTypeChanged }) {
    const classes = useStyles();
    const [paymentType, setPaymentType] = useState("all");

    const handleChange = ({ target: { value } }) => {
        setPaymentType(value);
        onPaymentTypeChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">
                <FormattedMessage id="SUBSCRIPTION.FILTER.PAYMENT_TYPE.TITLE" />
            </InputLabel>
            <Select labelId="status-filter-label" id="status-filter" value={paymentType} onChange={handleChange}>
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={"card"}>
                    <FormattedMessage id="SUBSCRIPTION.FILTER.PAYMENT_TYPE.CARD" />
                </MenuItem>
                <MenuItem value={"invoice"}>
                    <FormattedMessage id="SUBSCRIPTION.FILTER.PAYMENT_TYPE.INVOICE" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default SubscriptionPaymentTypeFilter;
