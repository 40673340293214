import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Highlight from "react-highlight";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
}));

function WidgetPage() {
    const { profile } = useSelector((state) => state.auth);
    const classes = useStyles();

    const loaderUrl =
        process.env.REACT_APP_API_URL.indexOf("stage") < 0
            ? "https://booking.agendo.io/agendo_loader.js"
            : "https://stage.booking.agendo.io/agendo_loader.js";

    useEffect(() => {
        const widgetScript = document.createElement("script");
        widgetScript.id = "agendo-widget-loader";
        widgetScript.src = loaderUrl;
        widgetScript.setAttribute("data-profile-id", profile.id);
        widgetScript.async = true;

        document.body.appendChild(widgetScript);

        return () => {
            document.body.removeChild(widgetScript);
            var existingIframe = document.getElementById("agendo-iframe-container");
            if (existingIframe) {
                existingIframe.parentNode.removeChild(existingIframe);
            }
        };
    }, [loaderUrl, profile.id]);

    return (
        <PageBackdrop isLoading={false}>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="WIDGET.TEST.TITLE" />
                </Typography>
                <div className="agendo-button-container" key={profile.id}></div>
            </Paper>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.SUBTITLE" />
                </Typography>
                <Typography variant="h6" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.STEP1.TITLE" />
                </Typography>
                <Typography variant="body1" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.STEP1.PART1" />
                </Typography>
                <Highlight>{`<script src="${loaderUrl}" data-profile-id="${profile.id}" defer></script>`}</Highlight>
                <Typography variant="h6" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.STEP2.TITLE" />
                </Typography>
                <Typography variant="body1" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.STEP2.PART1" />
                </Typography>
                <Highlight>{`<div class="agendo-button-container"></div>`}</Highlight>
                <Typography variant="body1" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.STEP2.PART2" />
                </Typography>
                <Typography variant="h5" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.STEP2.PART3" />
                </Typography>
                <Typography variant="body1" className={classes.header}>
                    <FormattedMessage id="WIDGET.IMPLEMENTATION.DOWNLOAD_TEXT" />
                    <a
                        href="https://agendo.blob.core.windows.net/misc/widget/agendo_booking_widget.pdf"
                        download="newfilename"
                    >
                        <FormattedMessage id="WIDGET.IMPLEMENTATION.DOWNLOAD_DOCUMENTATION" />
                    </a>
                </Typography>
            </Paper>
        </PageBackdrop>
    );
}

export default WidgetPage;
