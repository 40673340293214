import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";

import OverviewPage from "./pages/overview/OverviewPage";

import CalendarPage from "./pages/bookings/calendar/CalendarPage";
import BookingListPage from "./pages/bookings/bookings/BookingListPage";
import ResourceBookingListPage from "./pages/bookings/resources/ResourceBookingListPage";
import BookingPage from "./pages/bookings/bookings/BookingPage";

import ServicePageList from "./pages/services/ServicePageList";
import ServiceSortPageList from "./pages/services/ServiceSortPageList";
import AppointmentPage from "./pages/services/appointments/AppointmentPage";
import ServicePageAppointmentAdd from "./pages/services/appointments/ServicePageAppointmentAdd";
import CoursePage from "./pages/services/courses/CoursePage";
import ServicePageCourseAdd from "./pages/services/courses/ServicePageCourseAdd";
import MembershipPage from "./pages/services/memberships/MembershipPage";
import ServicePageMembershipAdd from "./pages/services/memberships/ServicePageMembershipAdd";
import VoucherPage from "./pages/services/vouchers/VoucherPage";
import ServicePageVoucherAdd from "./pages/services/vouchers/ServicePageVoucherAdd";
import ProductPage from "./pages/services/products/ProductPage";
import ServicePageProductAdd from "./pages/services/products/ServicePageProductAdd";

import CategoryPageList from "./pages/services/categories/CategoryPageList";
import CategoryPageAdd from "./pages/services/categories/CategoryPageAdd";
import CategoryPage from "./pages/services/categories/CategoryPage";

import ResourcePageList from "./pages/resources/ResourcePageList";
import ResourcePage from "./pages/resources/ResourcePage";
import ResourcePageAdd from "./pages/resources/ResourcePageAdd";

import ResourceGroupPageList from "./pages/resources/ResourceGroupPageList";
import ResourceGroupPage from "./pages/resources/ResourceGroupPage";
import ResourceGroupPageAdd from "./pages/resources/ResourceGroupPageAdd";

import CustomerPageList from "./pages/customers/CustomerPageList";
import CustomerPage from "./pages/customers/CustomerPage";
import CustomerPageAdd from "./pages/customers/CustomerPageAdd";

import CustomerOfferVoucherPage from "./pages/customeroffers/vouchers/VoucherPage";
import CustomerOfferMembershipPage from "./pages/customeroffers/memberships/MembershipPage";

import TransactionListPage from "./pages/transactions/TransactionListPage";
import TransactionPage from "./pages/transactions/TransactionPage";
import TransactionRefundsListPage from "./pages/transactions/TransactionRefundsListPage";

import SubscriptionListPage from "./pages/subscriptions/SubscriptionListPage";
import SubscriptionPage from "./pages/subscriptions/SubscriptionPage";
import SubscriptionPageAdd from "./pages/subscriptions/SubscriptionPageAdd";

import PaymentMethodPage from "./pages/paymentmethods/PaymentMethodPage";

import PayoutListPage from "./pages/payouts/PayoutListPage";
import PayoutPage from "./pages/payouts/PayoutPage";

import PromotionCodeListPage from "./pages/promotioncodes/PromotionCodeListPage";
import PromotionCodePage from "./pages/promotioncodes/PromotionCodePage";
import PromotionCodeAddPage from "./pages/promotioncodes/PromotionCodeAddPage";

import CampaignListPage from "./pages/campaigns/CampaignListPage";
import CampaignSMSPageAdd from "./pages/campaigns/sms/CampaignSMSPageAdd";
import CampaignSMSPage from "./pages/campaigns/sms/CampaignSMSPage";

import ReportListPage from "./pages/reports/ReportListPage";
import ReportPageAdd from "./pages/reports/ReportPageAdd";
import ReportPage from "./pages/reports/ReportPage";

import PaymentTemplatePage from "./pages/paymenttemplates/PaymentTemplatePage";
import PaymentTemplatePageAdd from "./pages/paymenttemplates/PaymentTemplatePageAdd";

import BillingTemplatePageAdd from "./pages/billingtemplates/BillingTemplatePageAdd";
import BillingTemplatePage from "./pages/billingtemplates/BillingTemplatePage";

import PricingTemplatePage from "./pages/pricingtemplates/PricingTemplatePage";
import PricingTemplatePageAdd from "./pages/pricingtemplates/PricingTemplatePageAdd";

import CancellationTemplatePageAdd from "./pages/cancellationtemplates/CancellationTemplatePageAdd";
import CancellationTemplatePage from "./pages/cancellationtemplates/CancellationTemplatePage";

import ReminderTemplatePageAdd from "./pages/remindertemplates/ReminderTemplatePageAdd";
import ReminderTemplatePage from "./pages/remindertemplates/ReminderTemplatePage";

import IntegrationListPage from "./pages/integrations/IntegrationListPage";

import ParakeyCreatePage from "./pages/integrations/parakey/ParakeyCreatePage";
import ParakeyPage from "./pages/integrations/parakey/ParakeyPage";

import ZesecCreatePage from "./pages/integrations/zesec/ZesecCreatePage";
import ZesecDetailsPage from "./pages/integrations/zesec/ZesecDetailsPage";

import AccessyCreatePage from "./pages/integrations/accessy/AccessyCreatePage";
import AccessyDetailsPage from "./pages/integrations/accessy/AccessyDetailsPage";

import DormakabaCreatePage from "./pages/integrations/dormakaba/DormakabaCreatePage";
import DormakabaDetailsPage from "./pages/integrations/dormakaba/DormakabaDetailsPage";

import ScriveDetailsPage from "./pages/integrations/scrive/ScriveDetailsPage";

import FortnoxCreatePage from "./pages/integrations/fortnox/FortnoxCreatePage";
import FortnoxActivationPage from "./pages/integrations/fortnox/FortnoxActivationPage";
import FortnoxPage from "./pages/integrations/fortnox/FortnoxPage";

import BankIDCreatePage from "./pages/integrations/bankid/BankIDCreatePage";
import BankIDPage from "./pages/integrations/bankid/BankIDPage";

import SettingsPage from "./pages/settings/SettingsPage";
import WidgetPage from "./pages/widget/WidgetPage";

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /overview. */
                    <Redirect exact from="/" to="/overview" />
                }

                {/* Overview routes */}
                <ContentRoute path="/overview" component={OverviewPage} />

                {/* Booking routes */}
                <ContentRoute path="/bookings/calendar" component={CalendarPage} />
                <ContentRoute path="/bookings/list" component={BookingListPage} />
                <ContentRoute path="/bookings/resources" component={ResourceBookingListPage} />
                <ContentRoute path="/bookings/:id" component={BookingPage} />

                {/* Service routes */}
                <ContentRoute path="/services" exact component={ServicePageList} />
                <ContentRoute path="/services/sort" exact component={ServiceSortPageList} />
                <ContentRoute path="/services/create/appointment" exact component={ServicePageAppointmentAdd} />
                <ContentRoute path="/services/:id/appointment" component={AppointmentPage} />
                <ContentRoute path="/services/create/group-service" exact component={ServicePageCourseAdd} />
                <ContentRoute path="/services/:id/group-service" component={CoursePage} />
                <ContentRoute path="/services/create/membership" exact component={ServicePageMembershipAdd} />
                <ContentRoute path="/services/:id/membership" component={MembershipPage} />
                <ContentRoute path="/services/create/voucher" exact component={ServicePageVoucherAdd} />
                <ContentRoute path="/services/:id/voucher" component={VoucherPage} />
                <ContentRoute path="/services/create/product" exact component={ServicePageProductAdd} />
                <ContentRoute path="/services/:id/product" component={ProductPage} />

                {/* Service category routes */}
                <ContentRoute path="/service-categories" exact component={CategoryPageList} />
                <ContentRoute path="/service-categories/create" exact component={CategoryPageAdd} />
                <ContentRoute path="/service-categories/:id" component={CategoryPage} />

                {/* Resource routes */}
                <ContentRoute path="/resources" exact component={ResourcePageList} />
                <ContentRoute path="/resources/create/:type" exact component={ResourcePageAdd} />
                <ContentRoute path="/resources/:id/:type" component={ResourcePage} />

                {/* Resource group routes */}
                <ContentRoute path="/resource-groups" exact component={ResourceGroupPageList} />
                <ContentRoute path="/resource-groups/create" exact component={ResourceGroupPageAdd} />
                <ContentRoute path="/resource-groups/:id" component={ResourceGroupPage} />

                {/* Customer routes */}
                <ContentRoute path="/customers" exact component={CustomerPageList} />
                <ContentRoute path="/customers/create" exact component={CustomerPageAdd} />
                <ContentRoute path="/customers/:id" component={CustomerPage} />

                {/* Customer offer routes */}
                <ContentRoute path="/vouchers/:id" component={CustomerOfferVoucherPage} />
                <ContentRoute path="/memberships/:id" component={CustomerOfferMembershipPage} />

                {/* Transaction routes */}
                <ContentRoute path="/transactions" exact component={TransactionListPage} />
                <ContentRoute path="/transactions/:id" component={TransactionPage} />
                <ContentRoute path="/transaction-refunds" exact component={TransactionRefundsListPage} />

                {/* Subscription routes */}
                <ContentRoute path="/subscriptions" exact component={SubscriptionListPage} />
                <ContentRoute path="/subscriptions/create" exact component={SubscriptionPageAdd} />
                <ContentRoute path="/subscriptions/:id" component={SubscriptionPage} />

                {/* Payment method routes */}
                <ContentRoute path="/payment-methods/:id" component={PaymentMethodPage} />

                {/* Payouts routes */}
                <ContentRoute path="/payouts" exact component={PayoutListPage} />
                <ContentRoute path="/payouts/:id" component={PayoutPage} />

                {/* Promotion code routes */}
                <ContentRoute path="/promotion-codes" exact component={PromotionCodeListPage} />
                <ContentRoute path="/promotion-codes/create" exact component={PromotionCodeAddPage} />
                <ContentRoute path="/promotion-codes/:id" component={PromotionCodePage} />

                {/* Campaign routes */}
                <ContentRoute path="/campaigns" exact component={CampaignListPage} />
                <ContentRoute path="/campaigns/create/sms" exact component={CampaignSMSPageAdd} />
                <ContentRoute path="/campaigns/:id/sms" component={CampaignSMSPage} />

                {/* Report routes */}
                <ContentRoute path="/reports" exact component={ReportListPage} />
                <ContentRoute path="/reports/create" exact component={ReportPageAdd} />
                <ContentRoute path="/reports/:id" component={ReportPage} />

                {/* Integration routes */}
                <ContentRoute path="/integrations" exact component={IntegrationListPage} />

                {/* Payment templates routes */}
                <ContentRoute path="/payment-templates/create" exact component={PaymentTemplatePageAdd} />
                <ContentRoute path="/payment-templates/:id" component={PaymentTemplatePage} />

                {/* Billing templates routes */}
                <ContentRoute path="/billing-templates/create" exact component={BillingTemplatePageAdd} />
                <ContentRoute path="/billing-templates/:id" component={BillingTemplatePage} />

                {/* Pricing templates routes */}
                <ContentRoute path="/pricing-templates/create" exact component={PricingTemplatePageAdd} />
                <ContentRoute path="/pricing-templates/:id" component={PricingTemplatePage} />

                {/* Cancellation templates routes */}
                <ContentRoute path="/cancellation-templates/create" exact component={CancellationTemplatePageAdd} />
                <ContentRoute path="/cancellation-templates/:id" component={CancellationTemplatePage} />

                {/* Cancellation templates routes */}
                <ContentRoute path="/reminder-templates/create" exact component={ReminderTemplatePageAdd} />
                <ContentRoute path="/reminder-templates/:id" component={ReminderTemplatePage} />

                <ContentRoute path="/integrations/accessy/create" exact component={AccessyCreatePage} />
                <ContentRoute path="/integrations/accessy/:id" exact component={AccessyDetailsPage} />

                <ContentRoute path="/integrations/dormakaba/create" exact component={DormakabaCreatePage} />
                <ContentRoute path="/integrations/dormakaba/:id" exact component={DormakabaDetailsPage} />

                <ContentRoute path="/integrations/fortnox/create" exact component={FortnoxCreatePage} />
                <ContentRoute path="/integrations/fortnox/activate" exact component={FortnoxActivationPage} />
                <ContentRoute path="/integrations/fortnox/:id" component={FortnoxPage} />

                <ContentRoute path="/integrations/scrive/:id" exact component={ScriveDetailsPage} />

                <ContentRoute path="/integrations/parakey/create" exact component={ParakeyCreatePage} />
                <ContentRoute path="/integrations/parakey/:id" component={ParakeyPage} />

                <ContentRoute path="/integrations/zesec/create" exact component={ZesecCreatePage} />
                <ContentRoute path="/integrations/zesec/:id" exact component={ZesecDetailsPage} />

                <ContentRoute path="/integrations/bankid/create" exact component={BankIDCreatePage} />
                <ContentRoute path="/integrations/bankid/:id" component={BankIDPage} />

                <ContentRoute path="/settings" component={SettingsPage} />
                <ContentRoute path="/widget" component={WidgetPage} />

                <ContentRoute path="/builder" component={BuilderPage} />
                <ContentRoute path="/my-page" component={MyPage} />
                <Redirect to="/error/error-v1" />
            </Switch>
        </Suspense>
    );
}
