import React, { useEffect } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import CourseBookingSettingsForm from "./CourseBookingSettingsForm";
import moment from "moment";

const schema = yup.object().shape({
    bookableFrom: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .when("bookableTo", (bookableTo, schema) => {
            if (bookableTo) {
                return schema.max(new Date(bookableTo), <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />);
            }
        })
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    bookableTo: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    maxAttendees: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .when("courseType", (courseType) => {
            if (courseType === "allOccasions") {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
                    .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />);
            }

            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        })
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function CoursePageBookingSettings({ updateCourse, getServiceOptions }) {
    const { profile } = useSelector((state) => state.auth);
    const { course, serviceOptions, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!serviceOptions) {
            getServiceOptions(profile.id);
        }
    }, [profile.id, serviceOptions, getServiceOptions]);

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalCourse = { ...course };
        originalCourse.categoryId = originalCourse?.category?.id;
        originalCourse.reminderTemplateId = originalCourse?.reminderTemplate?.id || null;
        originalCourse.cancellationTemplateId = originalCourse?.cancellationTemplate?.id || null;

        let updatedCourseData = { ...course };
        updatedCourseData.isBookPerOccasion = values.courseType === "bookPerOccasion";
        updatedCourseData.bookableFrom = moment(values.bookableFrom).format();
        updatedCourseData.bookableTo = moment(values.bookableTo).format();
        updatedCourseData.reminderTemplateId = values?.reminderTemplate?.id;
        updatedCourseData.cancellationTemplateId = values?.cancellationTemplate?.id;

        if (!updatedCourseData.isBookPerOccasion) {
            updatedCourseData.maxAttendees = values.maxAttendees;
            updatedCourseData.isWaitingListEnabled = values.isWaitingListEnabled;
            updatedCourseData.isScheduleVisible = originalCourse.isScheduleVisible;
        } else {
            updatedCourseData.maxAttendees = originalCourse.maxAttendees;
            updatedCourseData.isWaitingListEnabled = originalCourse.isWaitingListEnabled;
            updatedCourseData.isScheduleVisible = values.isScheduleVisible;
        }

        updateCourse(course.id, originalCourse, updatedCourseData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {course && !course?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <CourseBookingSettingsForm
                                serviceOptions={serviceOptions}
                                service={course}
                                showResources={false}
                            />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(CoursePageBookingSettings);
