import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const MEDIA_CONTAINER_URL = "v1/admin/media-containers/:id";
export const MEDIA_CONTAINER_ITEM_URL = "v1/admin/media-container-items/:id";
export const RESOURCE_MEDIA_CONTAINER_URL = "v1/admin/resources/:id/media-containers";
export const RESOURCE_GROUP_MEDIA_CONTAINER_URL = "v1/admin/resource-groups/:id/media-containers";

export function getMediaContainerItems(mediaContainerId) {
    let url = MEDIA_CONTAINER_URL.replace(":id", mediaContainerId);
    return axios.get(getApiUrl() + url);
}

export function patchMediaContainerItem(mediaContainerItemId, isAvatarImage, isCoverImage) {
    let url = MEDIA_CONTAINER_ITEM_URL.replace(":id", mediaContainerItemId);
    const original = {
        isAvatarImage: null,
        isCoverImage: null,
    };
    return axios.patch(getApiUrl() + url, jsonpatch.compare(original, { isAvatarImage, isCoverImage }), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function addResourceMediaContainerItem(resourceId, fileArrayBuffer, fileName) {
    let url = RESOURCE_MEDIA_CONTAINER_URL.replace(":id", resourceId);
    const formData = new FormData();
    formData.append("file", new Blob([fileArrayBuffer]), fileName);
    return axios.post(getApiUrl() + url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function addResourceGroupMediaContainerItem(resourceGroupId, fileArrayBuffer, fileName) {
    let url = RESOURCE_GROUP_MEDIA_CONTAINER_URL.replace(":id", resourceGroupId);
    const formData = new FormData();
    formData.append("file", new Blob([fileArrayBuffer]), fileName);
    return axios.post(getApiUrl() + url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export function deleteMediaContainerItem(id) {
    let url = MEDIA_CONTAINER_ITEM_URL.replace(":id", id);
    return axios.delete(getApiUrl() + url);
}
