import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Col } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import PaymentMethodInvoiceForm from "./PaymentMethodInvoiceForm";
import { actions as paymentMethodActions } from "../../../redux/paymentmethods/paymentMethodRedux";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";
import { TYPE_INVOICE, INVOICE_INDIVIDUAL, INVOICE_COMPANY } from "./utils";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    personalIdentityNumber: yup.string().when("invoiceType", (invoiceType) => {
        if (invoiceType.id === INVOICE_INDIVIDUAL) {
            return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }
        return yup.string();
    }),
    companyName: yup.string().when("invoiceType", (invoiceType) => {
        if (invoiceType.id === INVOICE_COMPANY) {
            return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }
        return yup.string();
    }),
    companyNumber: yup.string().when("invoiceType", (invoiceType) => {
        if (invoiceType.id === INVOICE_COMPANY) {
            return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }
        return yup.string();
    }),
    firstName: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    surname: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    email: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    phoneNumber: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    address: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    postalCode: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    city: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
});

function PaymentMethodAddModal({ show, customerId, addCustomerPaymentMethod, onPaymentMethodAdded, onCloseClicked }) {
    const { isUpdating } = useSelector((state) => state.paymentMethods);
    const classes = useStyles();
    const intl = useIntl();

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (formValues) => {
        let requestData = {
            ...formValues,
        };

        requestData.countryCode = requestData.countryCode.id;
        requestData.invoiceType = requestData.invoiceType.id;

        if (requestData.phoneNumber.indexOf("+") < 0) {
            requestData.phoneNumber = `+${requestData.phoneNumber}`;
        }

        addCustomerPaymentMethod(customerId, requestData, (paymentMethod) => {
            onPaymentMethodAdded(paymentMethod);
        });
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="PAYMENT_METHOD.ADD.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...formMethods}>
                    <Form>
                        <div className={classes.formContent}>
                            <Form.Row>
                                <Form.Group as={Col} xl={4}>
                                    <Form.Label>
                                        <FormattedMessage id="PAYMENT_METHOD.ADD.TYPE" />
                                    </Form.Label>
                                    <DefaultAsyncTypeahead
                                        id="typeahead-types"
                                        options={[
                                            {
                                                id: TYPE_INVOICE,
                                                name: intl.formatMessage({ id: "SERVICE.PAYMENT.METHOD.INVOICE" }),
                                            },
                                        ]}
                                        selected={{
                                            id: TYPE_INVOICE,
                                            name: intl.formatMessage({ id: "SERVICE.PAYMENT.METHOD.INVOICE" }),
                                        }}
                                        clearButton={false}
                                        disabled={true}
                                        onChange={() => {}}
                                        onSearch={() => {}}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <PaymentMethodInvoiceForm />
                        </div>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isUpdating}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="COMMON.ADD" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, { ...paymentMethodActions })(PaymentMethodAddModal);
