import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Switch, Avatar, FormControlLabel } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Row, Col } from "react-bootstrap";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";

function getResourceOptions(resourcesPagination) {
    let options = [];
    if (!resourcesPagination?.data?.length) return options;
    resourcesPagination.data.forEach((resource) => {
        options.push(getResourceOption(resource));
    });

    return options;
}

function getResourceOption(resource) {
    return {
        id: resource.id,
        name: resource.name,
        avatarUrl: resource.avatarUrl,
    };
}

function getCourseOccasionsOptions(courseOccasionsPagination) {
    let options = [];
    if (!courseOccasionsPagination?.data?.length) return options;
    courseOccasionsPagination.data.forEach((occasion) => {
        options.push({
            id: occasion.id,
            name:
                moment(occasion.startTime).format("YYYY-MM-DD HH:mm") +
                " - " +
                moment(occasion.endTime).format("YYYY-MM-DD HH:mm"),
        });
    });

    return options;
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    descriptionTextContainer: {
        marginBottom: theme.spacing(4),
    },
    formContent: {},
    listItemResourceContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemResourceAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

function CourseLeaderAddModal({
    onLeaderAdded,
    getResources,
    getCourseOccasions,
    serviceId,
    showOccasions = true,
    onCloseClicked,
    show,
    isLoading,
}) {
    const classes = useStyles();
    const intl = useIntl();

    const { profile } = useSelector((state) => state.auth);
    const { listPagination: resourceListPagination, isLoading: isResourcesLoading } = useSelector(
        (state) => state.resources
    );
    const { courseOccasionsPagination } = useSelector((state) => state.services);

    const profileId = profile.id;

    const [chosenResource, setChosenResource] = useState(null);
    const [isAllOccasions, setIsAllOccasions] = useState(true);
    const [chosenOccasions, setChosenOccasions] = useState([]);

    useEffect(() => {
        getResources(profileId, 1, 20);
    }, [profileId, getResources]);

    useEffect(() => {
        if (isAllOccasions && showOccasions) {
            getCourseOccasions(serviceId, 1, 30, "active");
        }
    }, [serviceId, getCourseOccasions, isAllOccasions, showOccasions]);

    const onSaveClicked = () => {
        if (!chosenResource) return;
        if (showOccasions && !isAllOccasions && chosenOccasions.length === 0) return;

        let addLeaderData = {
            resourceId: chosenResource.id,
        };

        if (showOccasions) {
            addLeaderData.isAddOnAllOccasions = isAllOccasions;

            if (!isAllOccasions) {
                addLeaderData.entryIds = chosenOccasions.map((x) => x.id);
            }
        }

        onLeaderAdded(addLeaderData);
    };

    const onResourceSearch = (search) => {
        getResources(profileId, 1, 20, search, null, "staff");
    };

    const onResourceChanged = (resources) => {
        setChosenResource(resources[0]);
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="SERVICE.COURSE.RESOURCES.ADD_MODAL.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.formContent}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.COURSE.RESOURCES.ADD_MODAL.CHOOSE_RESOURCE" />
                        </Form.Label>
                        <Row noGutters>
                            <Col xl={6}>
                                <AsyncTypeahead
                                    id="typeahead-add-course-resource"
                                    labelKey={"name"}
                                    minLength={0}
                                    onChange={onResourceChanged}
                                    onSearch={onResourceSearch}
                                    onInputChange={(input) => {
                                        if (!input) {
                                            onResourceSearch(input);
                                        }
                                    }}
                                    useCache={false}
                                    isLoading={isResourcesLoading}
                                    options={getResourceOptions(resourceListPagination)}
                                    emptyLabel={intl.formatMessage({
                                        id: "SERVICE.COURSE.BOOKING_SETTINGS.RESOURCES.SEARCH_EMPTY",
                                    })}
                                    renderMenuItemChildren={(option, props) => (
                                        <div className={classes.listItemResourceContainer}>
                                            <Avatar
                                                alt={option.name}
                                                src={option.avatarUrl}
                                                className={classes.listItemResourceAvatar}
                                            />
                                            <span>{option.name}</span>
                                        </div>
                                    )}
                                    selected={chosenResource ? [chosenResource] : []}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    {showOccasions && (
                        <>
                            <Form.Group>
                                <FormControlLabel
                                    control={<Switch color="primary" />}
                                    label={
                                        <FormattedMessage id="SERVICE.COURSE.RESOURCES.ADD_MODAL.IS_ALL_OCCASIONS" />
                                    }
                                    labelPlacement="start"
                                    checked={isAllOccasions}
                                    onChange={(event, value) => setIsAllOccasions(value)}
                                    style={{ userSelect: "none", marginLeft: "0px" }}
                                />
                            </Form.Group>
                            {!isAllOccasions && (
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="SERVICE.COURSE.RESOURCES.ADD_MODAL.CHOOSE_OCCASIONS" />
                                    </Form.Label>
                                    <Row noGutters>
                                        <Col xl={6}>
                                            <Typeahead
                                                id="typeahead-select-course-occasion"
                                                labelKey={"name"}
                                                options={getCourseOccasionsOptions(courseOccasionsPagination)}
                                                onChange={(occasions) => setChosenOccasions(occasions)}
                                                emptyLabel={intl.formatMessage({
                                                    id:
                                                        "SERVICE.COURSE.RESOURCES.ADD_MODAL.CHOOSE_OCCASIONS.SEARCH_EMPTY",
                                                })}
                                                selected={chosenOccasions.length ? chosenOccasions : []}
                                                multiple
                                                filterBy={() => true}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={() => onSaveClicked()}
                    label={<FormattedMessage id="COMMON.SAVE" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

const mapDispatchToProps = {
    ...resourceActions,
    ...serviceActions,
};

export default connect(null, mapDispatchToProps)(CourseLeaderAddModal);
