import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";

export const OVERVIEW_URL = "v1/admin/profiles/:id/stats/overview";

export function getOverviewStats(profileId, perspective) {
    let url = `${OVERVIEW_URL.replace(":id", profileId)}?perspective=${perspective}`;
    return axios.get(getApiUrl() + url, {
        headers: { "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
}
