import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    drawerHeaderContainer: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
        boxShadow: "0 0 4px 1px rgba(0,0,0,0.2)",
        zIndex: 1000,
        backgroundColor: "#ffffff",
    },
    drawerHeaderLabel: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    drawerHeaderActionButtons: {
        marginLeft: "auto",
        minWidth: "100px",
        textAlign: "end",
    },
}));

export default function DrawerHeader({ onDrawerToggle, onSaveClicked, onDeleteClicked, showEditButtons, title }) {
    const classes = useStyles();
    const { entry } = useSelector((state) => state.entries);

    return (
        <div className={classes.drawerHeaderContainer}>
            <IconButton onClick={() => onDrawerToggle(false)}>
                <ChevronRightIcon />
            </IconButton>
            <Typography variant="h5" className={classes.drawerHeaderLabel}>
                {title}
            </Typography>
            {showEditButtons && (
                <div className={classes.drawerHeaderActionButtons}>
                    {!entry.deletedAt && (
                        <IconButton onClick={() => onDeleteClicked()}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                    <IconButton onClick={() => onSaveClicked()}>
                        <SaveIcon />
                    </IconButton>
                </div>
            )}
        </div>
    );
}
