import React from "react";
import { connect } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";
import { INVOICE_INDIVIDUAL, INVOICE_COMPANY } from "./utils";
import BootstrapPhoneInput from "../../components/inputs/BootstrapPhoneInput";
import { getDefaultPhoneCountry } from "../../utils/LocaleUtils";

function PaymentMethodInvoiceForm() {
    const { register, control, setValue, watch, errors } = useFormContext();

    const intl = useIntl();

    const countryCode = watch("countryCode");
    if (!countryCode) {
        register("countryCode");
        setValue("countryCode", {
            id: "SE",
            name: intl.formatMessage({ id: "COMMON.COUNTRY.SWEDEN" }),
        });
    }

    const invoiceType = watch("invoiceType");
    if (!invoiceType) {
        register("invoiceType");
        setValue("invoiceType", {
            id: INVOICE_INDIVIDUAL,
            name: intl.formatMessage({ id: "PAYMENT_METHOD.INVOICE.INDIVIDUAL" }),
        });
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PAYMENT_METHOD.ADD.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.COUNTRY" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="countryCode"
                        defaultValue={INVOICE_INDIVIDUAL}
                        render={() => (
                            <div>
                                <DefaultAsyncTypeahead
                                    id="typeahead-invoice-countries"
                                    onChange={(countries) => {
                                        setValue("countryCode", countries[0], {
                                            shouldValidate: true,
                                        });
                                    }}
                                    onSearch={() => {}}
                                    options={[
                                        {
                                            id: "SE",
                                            name: intl.formatMessage({ id: "COMMON.COUNTRY.SWEDEN" }),
                                        },
                                    ]}
                                    selected={countryCode}
                                    filterBy={() => true}
                                    clearButton={false}
                                    placeholder={intl.formatMessage({
                                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                    })}
                                    emptyLabel={intl.formatMessage({
                                        id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                    })}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.TYPES" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="invoiceType"
                        defaultValue={INVOICE_INDIVIDUAL}
                        render={() => (
                            <div>
                                <DefaultAsyncTypeahead
                                    id="typeahead-invoice-types"
                                    onChange={(invoiceTypes) => {
                                        setValue("invoiceType", invoiceTypes[0], {
                                            shouldValidate: true,
                                        });
                                    }}
                                    onSearch={() => {}}
                                    options={[
                                        {
                                            id: INVOICE_INDIVIDUAL,
                                            name: intl.formatMessage({ id: "PAYMENT_METHOD.INVOICE.INDIVIDUAL" }),
                                        },
                                        {
                                            id: INVOICE_COMPANY,
                                            name: intl.formatMessage({ id: "PAYMENT_METHOD.INVOICE.COMPANY" }),
                                        },
                                    ]}
                                    selected={invoiceType}
                                    filterBy={() => true}
                                    clearButton={false}
                                    placeholder={intl.formatMessage({
                                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                    })}
                                    emptyLabel={intl.formatMessage({
                                        id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                    })}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
            </Form.Row>
            {invoiceType?.id === INVOICE_INDIVIDUAL && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.SSN" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg col-xl-4"
                        name="personalIdentityNumber"
                        ref={register}
                        isInvalid={errors.personalIdentityNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.personalIdentityNumber?.message}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.SSN_DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
            )}
            {invoiceType?.id === INVOICE_COMPANY && (
                <>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.COMPANY" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control form-control-lg col-xl-4"
                            name="companyName"
                            ref={register}
                            isInvalid={errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.companyName?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.COMPANY_NUMBER" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control form-control-lg col-xl-4"
                            name="companyNumber"
                            ref={register}
                            isInvalid={errors.companyNumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.companyNumber?.message}</Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.COMPANY_NUMBER_DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.COMPANY_REFERENCE" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control form-control-lg col-xl-4"
                            name="companyReference"
                            ref={register}
                            isInvalid={errors.companyReference}
                        />
                        <Form.Control.Feedback type="invalid">{errors.companyReference?.message}</Form.Control.Feedback>
                    </Form.Group>
                </>
            )}
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.FIRST_NAME" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg"
                        name="firstName"
                        ref={register}
                        isInvalid={errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.SURNAME" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg"
                        name="surname"
                        ref={register}
                        isInvalid={errors.surname}
                    />
                    <Form.Control.Feedback type="invalid">{errors.surname?.message}</Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.EMAIL" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg"
                        name="email"
                        ref={register}
                        isInvalid={errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.PHONE" />
                    </Form.Label>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue={""}
                        className="form-control form-control-lg"
                        render={(props) => {
                            return (
                                <BootstrapPhoneInput
                                    country={getDefaultPhoneCountry()}
                                    onChange={(v) => props.onChange(v)}
                                    masks={{ se: ".. ... .. .." }}
                                    value={props.value}
                                    defaultErrorMessage={intl.formatMessage({ id: "FORM.ERROR.REQUIRED_FIELD" })}
                                    isValid={(value, country) => {
                                        return errors.phoneNumber?.message ? false : true;
                                    }}
                                />
                            );
                        }}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.ADDRESS" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg"
                        name="address"
                        ref={register}
                        isInvalid={errors.address}
                    />
                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.POSTAL_CODE" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg"
                        name="postalCode"
                        ref={register}
                        isInvalid={errors.postalCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.postalCode?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="PAYMENT_METHOD.ADD.INVOICE.CITY" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg"
                        name="city"
                        ref={register}
                        isInvalid={errors.city}
                    />
                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
        </div>
    );
}

export default connect(null, serviceCategoriesActions)(PaymentMethodInvoiceForm);
