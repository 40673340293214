import React from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import VoucherBookingSettingsForm from "./VoucherBookingSettingsForm";
import {
    OFFER_VALID_FOR_TYPE_ALL_SERVICES,
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE,
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION,
    OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME,
} from "../utils";
import moment from "moment";

const schema = yup.object().shape({
    balance: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    validUntilValue: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    validUntilTimeUnit: yup.string(),
    fixedEndTime: yup.number().when("validUntilType", (paymentMode) => {
        if (paymentMode !== OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            return yup
                .date()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .date()
                .transform((_, val) => (val ? new Date(val) : null))
                .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
                .typeError(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />);
        }
    }),
    activeLimit: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
});

function VoucherPageBookingSettings({ updateVoucher }) {
    const { voucher, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalVoucher = { ...voucher };
        originalVoucher.categoryId = originalVoucher?.category?.id;

        let updatedVoucherData = { ...voucher };
        updatedVoucherData.isValidForAllServices =
            values.validForType === OFFER_VALID_FOR_TYPE_ALL_SERVICES ? true : false;
        updatedVoucherData.balance = values.balance;
        updatedVoucherData.validUntilType = values.validUntilType;
        updatedVoucherData.activeLimit = values.activeLimit;

        if (
            updatedVoucherData.validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE ||
            updatedVoucherData.validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION
        ) {
            updatedVoucherData.validUntilValue = values.validUntilValue;
            updatedVoucherData.validUntilTimeUnit = values.validUntilTimeUnit;
        } else if (updatedVoucherData.validUntilType === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME) {
            updatedVoucherData.fixedEndTime = moment(values.fixedEndTime).format();
        }

        updateVoucher(voucher.id, originalVoucher, updatedVoucherData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {voucher && !voucher?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <VoucherBookingSettingsForm service={voucher} showResources={false} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(VoucherPageBookingSettings);
