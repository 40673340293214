import React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FormattedMessage } from "react-intl";
import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
    },
    typeahead: {
        flexGrow: 1,
    },
    addButton: {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
        borderTopRightRadius: "0.42rem",
        borderBottomRightRadius: "0.42rem",
        height: "100%",
        minWidth: "52px",

        "& .MuiButton-startIcon": {
            marginLeft: 0,
            marginRight: 0,
        },
    },
}));

export const DefaultAsyncTypeahead = ({
    id,
    labelKey,
    options,
    selected,
    placeholder,
    emptyLabel,
    onChange,
    onSearch,
    renderMenuItemChildren,
    isLoading = false,
    minLength = 0,
    clearButton = true,
    disabled = false,
    isInvalid = false,
    invalidMessage,
    highlightMatches = false,
    onAddButtonClicked,
}) => {
    const classes = useStyles();

    const inputPropsStyleNoButtons = { height: "calc(1.5em + 1.65rem + 2px)" };
    const inputPropsStyleWithButtons = {
        height: "calc(1.5em + 1.65rem + 2px)",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 0,
    };

    return (
        <div>
            <div className={classes.container}>
                <AsyncTypeahead
                    id={id}
                    labelKey={labelKey || "name"}
                    minLength={minLength}
                    clearButton={clearButton}
                    onChange={onChange}
                    onSearch={onSearch}
                    onInputChange={(input) => {
                        if (!input) {
                            onSearch(input);
                        }
                    }}
                    useCache={false}
                    isLoading={isLoading}
                    options={options}
                    placeholder={placeholder}
                    emptyLabel={emptyLabel}
                    renderMenuItemChildren={
                        renderMenuItemChildren || highlightMatches ? renderMenuItemChildren : getNoHighlightRenderMenu
                    }
                    selected={selected ? [selected] : []}
                    filterBy={() => true}
                    inputProps={{ style: onAddButtonClicked ? inputPropsStyleWithButtons : inputPropsStyleNoButtons }}
                    disabled={disabled}
                    isInvalid={isInvalid}
                    className={classes.typeahead}
                />
                <div>
                    {onAddButtonClicked && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<AddIcon />}
                            className={classes.addButton}
                            onClick={onAddButtonClicked}
                        ></Button>
                    )}
                </div>
            </div>
            {isInvalid && (
                <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                    {invalidMessage || <FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />}
                </div>
            )}
        </div>
    );
};

function getNoHighlightRenderMenu(option, props) {
    return <span>{option.name}</span>;
}

export default DefaultAsyncTypeahead;
