import React, { useEffect } from "react";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { actions as authActions } from "../../../../redux/auth/authRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";

const schema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(50, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 50 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    lastname: Yup.string()
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(50, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 50 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    email: Yup.string()
        .email(<FormattedMessage id="FORM.ERROR.REGEX_MISMATCH" />)
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(60, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 60 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    password: Yup.string()
        .min(8, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 8 }} />)
        .max(60, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 60 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    confirmpassword: Yup.string()
        .min(8, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 8 }} />)
        .max(60, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 60 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                <FormattedMessage id="AUTH.VALIDATION.PASSWORD_MISMATCH" />
            ),
        }),
});

function StaffInvitation({
    intl,
    isLoading,
    isSubmitting,
    getStaffInvitation,
    acceptStaffInvitation,
    staffInvitation,
    onLoginSuccessful,
    clearDisplayError,
}) {
    const { displayError } = useSelector((state) => state.errors);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryHash = queryParams.get("hash");

    useEffect(() => {
        if (queryHash) {
            getStaffInvitation(queryHash);
        }
    }, [getStaffInvitation, queryHash]);

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: {
            email: staffInvitation?.email || "",
            firstname: staffInvitation?.firstName || "",
            lastname: staffInvitation?.surname || "",
            password: "",
            confirmpassword: "",
            acceptTerms: false,
        },
        enableReinitialize: true, // enabled initialValues to be re set
        validationSchema: schema,
        onSubmit: (values) => {
            clearDisplayError();
            acceptStaffInvitation(queryHash, values.email, values.firstname, values.lastname, values.password, () => {
                onLoginSuccessful();
            });
        },
    });

    return (
        <div className="login-form login-signin" style={{ display: "block" }}>
            {staffInvitation && (
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage
                            id="AUTH.INVITATION.TITLE"
                            values={{ profileName: staffInvitation.profileName }}
                        />
                    </h3>
                </div>
            )}

            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                {/* end: Alert */}

                {/* begin: Email */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Email */}

                {/* begin: First name */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.FIRST_NAME" })}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("firstname")}`}
                        name="firstname"
                        {...formik.getFieldProps("firstname")}
                    />
                    {formik.touched.firstname && formik.errors.firstname ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.firstname}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: First name */}

                {/* begin: Last name */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.LAST_NAME" })}
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("lastname")}`}
                        name="lastname"
                        {...formik.getFieldProps("lastname")}
                    />
                    {formik.touched.lastname && formik.errors.lastname ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.lastname}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Last name */}

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({
                            id: "AUTH.INPUT.CONFIRM_PASSWORD",
                        })}
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "confirmpassword"
                        )}`}
                        name="confirmpassword"
                        {...formik.getFieldProps("confirmpassword")}
                    />
                    {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.confirmpassword}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Confirm Password */}

                {displayError && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {displayError.message
                                ? displayError.message
                                : intl.formatMessage({
                                      id: "COMMON.ERROR.GENERAL",
                                  })}
                        </div>
                    </div>
                )}

                {/* begin: Terms and Conditions */}
                <div className="form-group">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            className="m-1"
                            {...formik.getFieldProps("acceptTerms")}
                        />
                        <span className="m-3" />
                        <p className="m-3">
                            <FormattedMessage id="AUTH.GENERAL.POLICIES_TERMS_I_HAVE_READ" />{" "}
                            <a
                                href="https://www.agendo.io/privacy-policy"
                                target="_blank"
                                className="mr-1 text-warning"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage id="AUTH.GENERAL.POLICIES_CONFIRM" />
                            </a>
                            <FormattedMessage id="AUTH.GENERAL.POLICIES_TERMS_I_HAVE_READ_AND" />{" "}
                            <a
                                href="https://www.agendo.io/terms-and-conditions"
                                target="_blank"
                                className="mr-1 text-warning"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage id="AUTH.GENERAL.TERMS_CONFIRM" />
                            </a>
                        </p>
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Terms and Conditions */}

                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        type="submit"
                        disabled={isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                        <span>
                            <FormattedMessage id="AUTH.INVITATION.BUTTON_ACCEPT" />
                        </span>
                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>

                    <Link to="/auth/login">
                        <button type="button" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                            <FormattedMessage id="COMMON.CANCEL" />
                        </button>
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default injectIntl(connect((state) => state.auth, { ...authActions, ...errorActions })(StaffInvitation));
