import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as reminderTemplateActions } from "../../../redux/remindertemplates/reminderTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import ReminderTemplatePageNavigator from "./ReminderTemplatePageNavigator";
import ReminderTemplatePageDetails from "./ReminderTemplatePageDetails";
import ReminderTemplateRules from "./ReminderTemplateRules";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function ReminderTemplatePage({
    match,
    getProfileReminderTemplate,
    clearReminderTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const reminderTemplateId = parseInt(match.params.id, 10);
    const { reminderTemplate, isLoading, isUpdating } = useSelector((state) => state.reminderTemplates);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!reminderTemplate || reminderTemplate.id !== reminderTemplateId)) {
            getProfileReminderTemplate(reminderTemplateId);
        }
    }, [getProfileReminderTemplate, reminderTemplate, reminderTemplateId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearReminderTemplateState();
            clearErrorState();
        };
    }, [clearReminderTemplateState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <ReminderTemplatePageNavigator reminderTemplateId={reminderTemplateId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/reminder-templates/${reminderTemplateId}`}
                            exact={true}
                            to={`/reminder-templates/${reminderTemplateId}/details`}
                        />
                        <Route
                            path={`/reminder-templates/${reminderTemplateId}/details`}
                            component={ReminderTemplatePageDetails}
                        />
                        <Route
                            path={`/reminder-templates/${reminderTemplateId}/rules`}
                            component={ReminderTemplateRules}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...reminderTemplateActions,
    ...errorActions,
})(ReminderTemplatePage);
