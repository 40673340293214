import React, { useMemo, useCallback, useState } from "react";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import {
    DateTimeCell,
    TextCell,
    LinkCell,
    DeleteActionButton,
    TranslationCell,
} from "../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SelectServicesAndCategoriesModal from "../services/SelectServicesAndCategoriesModal";
import { makeStyles } from "@material-ui/core";
import { getServiceDetailsPageUrl } from "../services/utils";

const useStyles = makeStyles((theme) => ({
    isAllServicesContainer: {
        display: "flex",
        padding: "100px",
        justifyContent: "center",
        textAlign: "center",
    },
}));

function PromotionCodePageServices({
    getPromotionCodeServices,
    addPromotionCodeServicesAndCategories,
    deletePromotionCodeService,
}) {
    const { promotionCode, promotionCodeServicesPagination, isLoading, isUpdating } = useSelector(
        (state) => state.promotionCodes
    );
    const { displayError } = useSelector((state) => state.errors);
    const promotionCodeId = promotionCode?.id;
    const isAllServices = promotionCode?.isAllServices;
    const classes = useStyles();

    const [showModalAddService, setShowModalAddService] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (promotionCodeId && !isAllServices) {
                getPromotionCodeServices(promotionCodeId, pageIndex + 1, pageSize);
            }
        },
        [promotionCodeId, isAllServices, getPromotionCodeServices]
    );

    const onAddModalSaveClicked = (items) => {
        addPromotionCodeServicesAndCategories(promotionCodeId, items, () => {
            setShowModalAddService(false);
            getPromotionCodeServices(promotionCodeId, 1, 10);
        });
    };

    const tableData = useMemo(() => promotionCodeServicesPagination.data, [promotionCodeServicesPagination.data]);

    const tableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="PROMOTIONCODE.SERVICES.LIST.HEADER.ID" />,
                accessor: "id",
                width: "7%",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="PROMOTIONCODE.SERVICES.LIST.HEADER.TYPE" />,
                accessor: "type",
                maxWidth: 100,
                Cell: ({ row }) => {
                    const type = row.original.type;
                    if (type === "service") {
                        return TranslationCell("PROMOTIONCODE.SERVICES.TYPE.SERVICE");
                    } else if (type === "service_category") {
                        return TranslationCell("PROMOTIONCODE.SERVICES.TYPE.SERVICE_CATEGORY");
                    }
                    return TextCell();
                },
            },
            {
                Header: <FormattedMessage id="PROMOTIONCODE.SERVICES.LIST.HEADER.NAME" />,
                accessor: "service",
                Cell: ({ row }) => {
                    if (row.original.service) {
                        const service = row.original.service;
                        const url = getServiceDetailsPageUrl(service.type, service.id);
                        if (!url) return TextCell(service.name);
                        return LinkCell(url, service.name);
                    } else if (row.original.serviceCategory) {
                        const serviceCategory = row.original.serviceCategory;
                        return LinkCell("/service-categories/" + serviceCategory.id, serviceCategory.name);
                    }

                    return TextCell();
                },
            },
            {
                Header: <FormattedMessage id="PROMOTIONCODE.SERVICES.LIST.HEADER.CREATED_AT" />,
                accessor: "createdAt",
                Cell: (props) => {
                    return DateTimeCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                id: "delete",
                Cell: ({ row }) => {
                    const content = <FormattedMessage id="PROMOTIONCODE.SERVICES.DELETE.TEXT" />;
                    const name = row.original.service ? row.original.service.name : row.original.serviceCategory.name;
                    return DeleteActionButton(name, content, () => deletePromotionCodeService(row.original.id));
                },
            },
        ],
        [deletePromotionCodeService]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROMOTIONCODE.SERVICES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROMOTIONCODE.SERVICES.SUBTITLE" />
                        </span>
                    </div>
                    {promotionCode && !promotionCode.isAllServices && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddService(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    {!isAllServices && (
                        <MaterialReactTable
                            key={promotionCodeId}
                            data={tableData}
                            fetchData={fetchData}
                            pageSize={10}
                            rowCount={promotionCodeServicesPagination.totalRows}
                            isLoading={isLoading}
                            error={displayError}
                            columns={tableColumns}
                            getCustomCellProps={() => ({
                                style: { height: "64px" },
                            })}
                        />
                    )}
                    {isAllServices && (
                        <div className={classes.isAllServicesContainer}>
                            <FormattedMessage
                                id="PROMOTIONCODE.SERVICES.ALL_SERVICES.TEXT"
                                values={{
                                    p: (...chunks) => <p>{chunks}</p>,
                                    a: (msg) => (
                                        <Link to={`details`} key={1}>
                                            <FormattedMessage id={"PROMOTIONCODE.DETAILS.TITLE"} />
                                        </Link>
                                    ),
                                }}
                            />
                        </div>
                    )}
                </Card.Body>
            </div>
            {showModalAddService && (
                <SelectServicesAndCategoriesModal
                    show={showModalAddService}
                    onCloseClicked={() => setShowModalAddService(false)}
                    onSaveClicked={onAddModalSaveClicked}
                    titleText={<FormattedMessage id={"PROMOTIONCODE.SERVICES.ADD.TITLE"} />}
                    descriptionText={<FormattedMessage id={"PROMOTIONCODE.SERVICES.ADD.DESCRIPTION"} />}
                    saveButtonText={<FormattedMessage id="COMMON.SAVE" />}
                    isLoading={isUpdating}
                    serviceStatusFilter="adminBookable"
                />
            )}
        </Card>
    );
}
export default connect(null, { ...promotionCodesAction, ...errorActions })(PromotionCodePageServices);
