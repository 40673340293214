import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const getErrorMessage = (error) => {
    if (!error) return null;
    if (typeof error === "string") return error;
    if (error.message) return error.message;
    if (error.code) return error.code;
    if (error.response) {
        // API error
        if (error.response?.data?.message) {
            return error.response.data.message;
        } else if (error.response?.data?.code) {
            return error.response.data.code;
        }
    }

    return null;
};

export default function SnackbarError({ error, onErrorSnackBarClosed }) {
    return (
        <Snackbar
            open={error ? true : false}
            onClose={onErrorSnackBarClosed}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <MuiAlert elevation={6} variant="filled" severity="error">
                {getErrorMessage(error)}
            </MuiAlert>
        </Snackbar>
    );
}
