import moment from "moment";

export const RESOURCE_STAFF_TYPE = "staff";
export const RESOURCE_SPACE_TYPE = "space";
export const RESOURCE_ARTICLE_TYPE = "article";

export const RESOURCE_COLOR_MODE_SERVICE = "serviceColor";
export const RESOURCE_COLOR_MODE_OWN = "ownColor";

export const PERMISSION_OWNER_ID = 1;
export const PERMISSION_ADMIN_ID = 2;
export const PERMISSION_STAFF_ID = 3;
export const PERMISSION_BOOKINGS_ID = 4;
export const PERMISSION_BOOKINGS_READONLY_ID = 5;

export function isStaff(resource) {
    return resource ? resource.type === RESOURCE_STAFF_TYPE : false;
}

export function isSpace(resource) {
    return resource ? resource.type === RESOURCE_SPACE_TYPE : false;
}

export function isArticle(resource) {
    return resource ? resource.type === RESOURCE_ARTICLE_TYPE : false;
}

export function isAtLeastAdminPermission(permissionId) {
    return permissionId === PERMISSION_OWNER_ID || permissionId === PERMISSION_ADMIN_ID;
}

export function isAtLeastStaffPermission(permissionId) {
    return isAtLeastAdminPermission(permissionId) || permissionId === PERMISSION_STAFF_ID;
}

export function isAtLeastBookingPermission(permissionId) {
    return (
        isAtLeastAdminPermission(permissionId) ||
        isAtLeastStaffPermission(permissionId) ||
        permissionId === PERMISSION_BOOKINGS_ID
    );
}

export function hasOwnerPermission(staff) {
    return staff?.permission?.id === PERMISSION_OWNER_ID;
}

export function hasAdminPermission(staff) {
    return staff?.permission?.id === PERMISSION_ADMIN_ID;
}

export function hasStaffPermission(staff) {
    return staff?.permission?.id === PERMISSION_STAFF_ID;
}

export function hasBookingsPermission(staff) {
    return staff?.permission?.id === PERMISSION_BOOKINGS_ID;
}

export function hasBookingsReadOnlyPermission(staff) {
    return staff?.permission?.id === PERMISSION_BOOKINGS_READONLY_ID;
}

export function getPermissionName(permissionId, intl) {
    if (permissionId === PERMISSION_OWNER_ID) {
        return intl.formatMessage({
            id: "RESOURCE.TYPE.STAFF.OWNER",
        });
    } else if (permissionId === PERMISSION_ADMIN_ID) {
        return intl.formatMessage({
            id: "RESOURCE.TYPE.STAFF.ADMIN",
        });
    } else if (permissionId === PERMISSION_STAFF_ID) {
        return intl.formatMessage({
            id: "RESOURCE.TYPE.STAFF.STAFF",
        });
    } else if (permissionId === PERMISSION_BOOKINGS_ID) {
        return intl.formatMessage({
            id: "RESOURCE.TYPE.STAFF.BOOKINGS",
        });
    } else if (permissionId === PERMISSION_BOOKINGS_READONLY_ID) {
        return intl.formatMessage({
            id: "RESOURCE.TYPE.STAFF.BOOKINGS_READONLY",
        });
    }
}

export function getWeekListItems(fromDate, toDate, moment) {
    const weekItems = [];
    const totalWeekCount = toDate.diff(fromDate, "week");
    for (let i = 0; i < totalWeekCount; i++) {
        const currentDate = moment(fromDate).add(i, "week");
        weekItems.push({
            weekDate: currentDate,
        });
    }

    return weekItems;
}

export function getWeekNameAbbreviated(weekDate, todayDate, intl) {
    let name = `${intl.formatMessage({
        id: "RESOURCE.HOURS.WEEK_ABBREVATION",
    })}${weekDate.isoWeek()}`;

    if (weekDate.year() !== todayDate.year()) {
        name += ` (${weekDate.year()})`;
    } else if (weekDate.isoWeek() === 1) {
        // First week of the year, make sure year is at the latest year of the potential two
        const potentialNextYearDate = moment(weekDate).add(7, "days");
        if (potentialNextYearDate.year() !== todayDate.year()) {
            name += ` (${potentialNextYearDate.year()})`;
        }
    }

    return name;
}

export function mergeResourceHourDays(original, additions) {
    const newHourDays = original ? [...original] : [];
    if (!additions || additions.length === 0) return newHourDays;

    for (let i = 0; i < additions.length; i++) {
        const dayAddition = additions[i];
        const existingDay = newHourDays.find((x) => x.date === dayAddition.date);
        if (!existingDay) {
            newHourDays.push(dayAddition);
        } else {
            // If no hours has been edited we can just overwrite it, otherwise keep local edited version
            if (!existingDay.isEdited) {
                existingDay.hours = dayAddition.hours;
            }
        }
    }

    var moment = require("moment");
    newHourDays.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? -1 : 1));

    return newHourDays;
}

export function addResourceHourToDays(hourDays, addToDate) {
    if (!hourDays || hourDays.length === 0) return [];
    const dateIndex = hourDays.findIndex((x) => x.date === addToDate);
    if (dateIndex > -1) {
        const alteredHourDays = [...hourDays];
        const alteredHourDay = alteredHourDays[dateIndex];
        if (alteredHourDay.hours.length === 0) {
            alteredHourDay.hours.push({
                startTime: "08:00",
                endTime: "17:00",
            });
        } else {
            // Grab latest time and add new one later
            const latestHour = alteredHourDay.hours[alteredHourDay.hours.length - 1];

            // If latest time already is at end of day, don't add more
            if (latestHour.endTime === "23:59") {
                return [...hourDays];
            }

            let startTimeHour = parseInt(latestHour.endTime.substring(0, 2), 10) + 1;
            if (startTimeHour >= 24) startTimeHour = 23;
            let endTimeHour = startTimeHour + 1;
            if (endTimeHour >= 24) endTimeHour = 23;
            const startTimeHourString = startTimeHour >= 10 ? `${startTimeHour}` : `0${startTimeHour}`;
            const endTimeHourString = endTimeHour >= 10 ? `${endTimeHour}` : `0${endTimeHour}`;
            const startTimeMinuteString = "00";
            const endTimeMinuteString = startTimeHour < 23 ? "00" : "59";
            alteredHourDay.hours.push({
                startTime: `${startTimeHourString}:${startTimeMinuteString}`,
                endTime: `${endTimeHourString}:${endTimeMinuteString}`,
            });
        }
        alteredHourDay.isEdited = true;
        return alteredHourDays;
    }

    return hourDays;
}

export function updateResourceHour(hourDays, field, date, oldTime, newTime) {
    if (!hourDays || hourDays.length === 0) return [];
    const dateIndex = hourDays.findIndex((x) => x.date === date);
    if (dateIndex > -1) {
        const alteredHourDays = [...hourDays];
        const alteredHourDay = alteredHourDays[dateIndex];

        let hourToUpdate;
        if (field === "startTime") {
            hourToUpdate = alteredHourDay.hours.find((x) => x.startTime === oldTime);
            if (hourToUpdate) {
                hourToUpdate.startTime = newTime;
            }
        } else if (field === "endTime") {
            hourToUpdate = alteredHourDay.hours.find((x) => x.endTime === oldTime);
            if (hourToUpdate) {
                hourToUpdate.endTime = newTime;
            }
        }

        // If hour not found, return without change
        if (!hourToUpdate) return hourDays;

        alteredHourDay.isEdited = true;
        return alteredHourDays;
    }

    return hourDays;
}

export function deleteResourceHourFromDays(hourDays, addToDate, startTime, endTime) {
    if (!hourDays || hourDays.length === 0) return [];
    const dateIndex = hourDays.findIndex((x) => x.date === addToDate);
    if (dateIndex > -1) {
        const hourIndex = hourDays[dateIndex].hours.findIndex(
            (x) => x.startTime === startTime && x.endTime === endTime
        );
        if (hourIndex > -1) {
            const alteredHourDays = [...hourDays];
            const alteredHourDay = alteredHourDays[dateIndex];
            alteredHourDay.hours.splice(hourIndex, 1);
            alteredHourDay.isEdited = true;
            return alteredHourDays;
        }
    }

    return [...hourDays];
}

export function getResourcesAndGroupsOptions(pagination) {
    let options = [];
    if (!pagination?.data?.length) return options;
    pagination.data.forEach((data) => {
        if (data.resource) {
            options.push(getResourceOption(data.resource));
        } else if (data.resourceGroup) {
            options.push(getResourceGroupOption(data.resourceGroup));
        }
    });

    return options;
}

export function getResourceOption(resource) {
    return {
        id: resource.id,
        name: resource.name,
        avatarUrl: resource.avatarUrl,
    };
}

export function getResourceGroupOption(resourceGroup) {
    return {
        id: resourceGroup.id,
        name: resourceGroup.name,
        isGroup: true,
    };
}

export function getColorSettingsOptions(intl) {
    return [
        {
            id: RESOURCE_COLOR_MODE_SERVICE,
            value: RESOURCE_COLOR_MODE_SERVICE,
            name: intl.formatMessage({
                id: "RESOURCE.DETAILS.COLOR.MODE.SERVICE",
            }),
        },
        {
            id: RESOURCE_COLOR_MODE_OWN,
            value: RESOURCE_COLOR_MODE_OWN,
            name: intl.formatMessage({
                id: "RESOURCE.DETAILS.COLOR.MODE.OWN",
            }),
        },
    ];
}

export function getResourceWeekYearAndWeek(originDate) {
    let year = originDate.year();
    let week = originDate.isoWeek();

    if (week === 1) {
        // First week of the year, make sure year is at the latest year of the potential two
        year = moment(originDate).add(7, "days").year();
    }

    return {
        year,
        week,
    };
}
