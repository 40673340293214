import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles, Switch, Avatar } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { Form, InputGroup, FormControl, Row, Col } from "react-bootstrap";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import MaterialRadioDescriptionButton from "../../../components/inputs/MaterialRadioDescriptionButton";
import DefaultAsyncTypeahead from "../../../components/DefaultAsyncTypeahead";

function CourseBookingSettingsForm({ serviceOptions, getResources, service, isAddingService, showResources = true }) {
    const classes = useStyles();
    const intl = useIntl();

    const { register, control, setValue, watch, errors } = useFormContext();

    const { profile } = useSelector((state) => state.auth);
    const { listPagination: resourceListPagination, isLoading: isResourcesLoading } = useSelector(
        (state) => state.resources
    );

    const profileId = profile.id;

    const [bookableFromDatePickerOpen, setBookableFromDatePickerOpen] = useState(false);
    const [bookableToDatePickerOpen, setBookableToDatePickerOpen] = useState(false);

    useEffect(() => {
        if (showResources) {
            getResources(profileId, 1, 20);
        }
    }, [profileId, getResources, showResources]);

    useEffect(() => {
        if (service) {
            setValue("courseType", !service.isBookPerOccasion ? "allOccasions" : "bookPerOccasion");
            setValue("isWaitingListEnabled", service.isWaitingListEnabled);
            setValue("isScheduleVisible", service.isScheduleVisible);

            if (service.reminderTemplate) {
                setValue("reminderTemplate", getReminderTemplateOption(service.reminderTemplate));
            }

            if (service.cancellationTemplate) {
                setValue("cancellationTemplate", getCancellationTemplateOption(service.cancellationTemplate));
            }
        }
    }, [service, setValue]);

    const onResourceSearch = (search) => {
        getResources(profileId, 1, 20, search);
    };

    const onResourceChanged = (resources) => {
        setValue("resources", resources, { shouldValidate: true });
    };

    const courseType = watch("courseType");
    const resources = watch("resources");
    const bookableFrom = watch("bookableFrom");
    const bookableTo = watch("bookableTo");

    // Add default null option first on cancellation templates since its optional
    let allReminderTemplates = serviceOptions?.reminderTemplates ? [...serviceOptions.reminderTemplates] : [];
    allReminderTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "SERVICE.REMINDER_TEMPLATE.DEFAULT_OPTION",
        }),
    });

    const reminderTemplate = watch("reminderTemplate");
    if (!reminderTemplate) {
        register("reminderTemplate");
        setValue("reminderTemplate", getReminderTemplateOption(allReminderTemplates[0]), {
            shouldValidate: true,
        });
    }

    // Add default null option first on cancellation templates since its optional
    let allCancellationTemplates = serviceOptions?.cancellationTemplates
        ? [...serviceOptions.cancellationTemplates]
        : [];
    allCancellationTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "SERVICE.CANCELLATION_TEMPLATE.DEFAULT_OPTION",
        }),
    });

    const cancellationTemplate = watch("cancellationTemplate");
    if (!cancellationTemplate) {
        register("cancellationTemplate");
        setValue("cancellationTemplate", getCancellationTemplateOption(allCancellationTemplates[0]), {
            shouldValidate: true,
        });
    }

    return (
        <div>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.COURSE_TYPE" />
                </Form.Label>
                <Controller
                    control={control}
                    name="courseType"
                    defaultValue={"allOccasions"}
                    render={({ onChange, value }) => (
                        <>
                            <Col sm={4}>
                                <MaterialRadioDescriptionButton
                                    checked={value === "allOccasions"}
                                    value={"allOccasions"}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={"SERVICE.COURSE.BOOKING_SETTINGS.COURSE_TYPE.ALL_OCCASIONS"}
                                    descriptionIntlId={
                                        "SERVICE.COURSE.BOOKING_SETTINGS.COURSE_TYPE.ALL_OCCASIONS_DESCRIPTION"
                                    }
                                />
                            </Col>
                            <Col sm={4}>
                                <MaterialRadioDescriptionButton
                                    checked={value === "bookPerOccasion"}
                                    value={"bookPerOccasion"}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={"SERVICE.COURSE.BOOKING_SETTINGS.COURSE_TYPE.BOOK_PER_OCCASION"}
                                    descriptionIntlId={
                                        "SERVICE.COURSE.BOOKING_SETTINGS.COURSE_TYPE.BOOK_PER_OCCASION_DESCRIPTION"
                                    }
                                />
                            </Col>
                        </>
                    )}
                />
            </Form.Group>
            {showResources && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.RESOURCES" />
                    </Form.Label>
                    <Row noGutters>
                        <Col xl={6}>
                            <Controller
                                control={control}
                                name="resources"
                                defaultValue={[]}
                                render={() => (
                                    <div>
                                        <AsyncTypeahead
                                            id="typeahead-course-resources"
                                            labelKey={"name"}
                                            minLength={0}
                                            onChange={onResourceChanged}
                                            onSearch={onResourceSearch}
                                            onInputChange={(input) => {
                                                if (!input) {
                                                    onResourceSearch(input);
                                                }
                                            }}
                                            useCache={false}
                                            isLoading={isResourcesLoading}
                                            options={getResourceOptions(resourceListPagination)}
                                            placeholder={intl.formatMessage({
                                                id: "SERVICE.COURSE.BOOKING_SETTINGS.RESOURCES.PLACEHOLDER",
                                            })}
                                            emptyLabel={intl.formatMessage({
                                                id: "SERVICE.COURSE.BOOKING_SETTINGS.RESOURCES.SEARCH_EMPTY",
                                            })}
                                            renderMenuItemChildren={(option, props) => (
                                                <div className={classes.listItemResourceContainer}>
                                                    <Avatar
                                                        alt={option.name}
                                                        src={option.avatarUrl}
                                                        className={classes.listItemResourceAvatar}
                                                    />
                                                    <span>{option.name}</span>
                                                </div>
                                            )}
                                            selected={resources?.length ? resources : []}
                                            multiple
                                            isInvalid={!!errors.resources}
                                        />
                                        {errors.resources?.message && (
                                            <div
                                                className="text-danger"
                                                style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}
                                            >
                                                {errors.resources.message}
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.BOOKABLE_BETWEEN" />
                </Form.Label>
                <InputGroup className="col-xl-6 px-0">
                    <FormControl
                        type="text"
                        className="form-control form-control-lg"
                        name="bookableFrom"
                        ref={register}
                        onClick={() => setBookableFromDatePickerOpen(true)}
                        isInvalid={errors.bookableFrom}
                        defaultValue={
                            service?.bookableFrom ? moment(service.bookableFrom).format("YYYY-MM-DD HH:mm") : null
                        }
                    />
                    <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                        <InputGroup.Text id="basic-addon1">
                            <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        className="form-control form-control-lg"
                        name="bookableTo"
                        ref={register}
                        onClick={() => setBookableToDatePickerOpen(true)}
                        isInvalid={errors.bookableTo}
                        defaultValue={
                            service?.bookableTo ? moment(service.bookableTo).format("YYYY-MM-DD HH:mm") : null
                        }
                        style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.bookableFrom?.message ? errors.bookableFrom?.message : errors.bookableTo?.message}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            {(!service || isAddingService || courseType === "allOccasions") && (
                <>
                    <Form.Group>
                        <Form.Label>
                            {courseType === "allOccasions" ? (
                                <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.MAX_ATTENDEES" />
                            ) : (
                                <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.MAX_ATTENDEES_PER_OCCASION" />
                            )}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control form-control-lg col-xl-6"
                            name="maxAttendees"
                            ref={register}
                            defaultValue={service?.maxAttendees}
                            isInvalid={errors.maxAttendees}
                        />
                        <Form.Control.Feedback type="invalid">{errors.maxAttendees?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.WAITING_LIST" />
                        </Form.Label>
                        <Controller
                            name="isWaitingListEnabled"
                            control={control}
                            defaultValue={service ? service.isWaitingListEnabled : false}
                            render={({ value, onChange }) => {
                                return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                            }}
                        />
                    </Form.Group>
                </>
            )}

            {courseType === "bookPerOccasion" && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.IS_VISIBLE_IN_SCHEDULE" />
                    </Form.Label>
                    <Controller
                        name="isScheduleVisible"
                        control={control}
                        defaultValue={service ? service.isScheduleVisible : false}
                        render={({ value, onChange }) => {
                            return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                        }}
                    />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.IS_VISIBLE_IN_SCHEDULE.DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
            )}

            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.REMINDER_TEMPLATE" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="reminderTemplate"
                        defaultValue={null}
                        render={() => (
                            <DefaultAsyncTypeahead
                                id="typeahead-reminder-template"
                                onChange={(reminderTemplates) =>
                                    setValue("reminderTemplate", reminderTemplates[0], {
                                        shouldValidate: true,
                                    })
                                }
                                onSearch={() => {}}
                                options={allReminderTemplates.map((rt) => getReminderTemplateOption(rt))}
                                selected={reminderTemplate}
                                clearButton={false}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.CANCELLATION_TEMPLATE" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="cancellationTemplate"
                        defaultValue={null}
                        render={() => (
                            <DefaultAsyncTypeahead
                                id="typeahead-cancellation-template"
                                onChange={(cancellationTemplates) =>
                                    setValue("cancellationTemplate", cancellationTemplates[0], {
                                        shouldValidate: true,
                                    })
                                }
                                onSearch={() => {}}
                                options={allCancellationTemplates.map((ct) => getCancellationTemplateOption(ct))}
                                selected={cancellationTemplate}
                                clearButton={false}
                            />
                        )}
                    />
                </Form.Group>
            </Form.Row>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    showTodayButton
                    ampm={false}
                    style={{ display: "none" }}
                    open={bookableFromDatePickerOpen}
                    onClose={() => setBookableFromDatePickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue("bookableFrom", selectedMomentDate.format("YYYY-MM-DD HH:mm"), {
                            shouldValidate: true,
                        });
                    }}
                    maxDate={bookableTo ? moment(bookableTo) : new Date("2100-01-01")}
                />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    showTodayButton
                    ampm={false}
                    style={{ display: "none" }}
                    open={bookableToDatePickerOpen}
                    onClose={() => setBookableToDatePickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue("bookableTo", selectedMomentDate.format("YYYY-MM-DD HH:mm"), { shouldValidate: true });
                    }}
                    minDate={bookableFrom ? moment(bookableFrom) : new Date("2022-01-01")}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

function getResourceOptions(resourcesPagination) {
    let options = [];
    if (!resourcesPagination?.data?.length) return options;
    resourcesPagination.data.forEach((resource) => {
        options.push(getResourceOption(resource));
    });

    return options;
}

function getResourceOption(resource) {
    return {
        id: resource.id,
        name: resource.name,
        avatarUrl: resource.avatarUrl,
    };
}

const useStyles = makeStyles((theme) => ({
    listItemResourceContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemResourceAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

function getReminderTemplateOption(reminderTemplate) {
    return { id: reminderTemplate.id, name: reminderTemplate.name };
}

function getCancellationTemplateOption(cancellationTemplate) {
    return { id: cancellationTemplate.id, name: cancellationTemplate.name };
}

export default connect(null, resourceActions)(CourseBookingSettingsForm);
