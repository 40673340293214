import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions as fortnoxActions } from "../../../../redux/integrations/fortnox/fortnoxRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { Redirect } from "react-router";

function FortnoxActivationPage({ location, createFortnoxIntegration, clearDisplayError }) {
    const queryParams = new URLSearchParams(location.search);
    const queryCode = queryParams.get("code");
    const queryError = queryParams.get("error");
    const queryStateJson = JSON.parse(queryParams.get("state"));
    const profileId = queryStateJson?.profileId;

    // Only included if this is a re auth
    const integrationId = queryStateJson?.integrationId;

    const { integration, redirectToDetails } = useSelector((state) => state.fortnox);
    const { displayError } = useSelector((state) => state.errors);

    useEffect(() => {
        if (profileId && queryCode) {
            createFortnoxIntegration(profileId, queryCode, integrationId);
        }
    }, [createFortnoxIntegration, profileId, queryCode, integrationId]);

    if (queryError) {
        // For example if user presses cancel before accepting permissions, this is in query:
        // ?error=access_denied&error_description=The+user+denied+access+to+your+application
        return <Redirect to={"/integrations/fortnox/create"} />;
    }

    if (redirectToDetails) {
        return <Redirect to={"/integrations/fortnox/" + integration.id} />;
    }

    return (
        <PageBackdrop isLoading={true}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
        </PageBackdrop>
    );
}

export default connect(null, { ...fortnoxActions, ...errorActions })(FortnoxActivationPage);
