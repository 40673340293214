import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        position: "absolute",
        left: "50%",
        top: "50%",
        maxWidth: "400px",
        textAlign: "center",
    },
    spinner: {
        position: "relative",
        left: "-50%",
    },
}));

export default function MaterialSpinner() {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <CircularProgress className={classes.spinner} />
        </div>
    );
}
