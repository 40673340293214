import React, { useState, useCallback, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { FormattedMessage, useIntl } from "react-intl";
import {
    makeStyles,
    withStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Divider,
    Paper,
    IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import "moment/locale/sv";

import HistoryIcon from "@material-ui/icons/History";
import LinkIcon from "@material-ui/icons/Link";
import AddResourceIcon from "@material-ui/icons/AddOutlined";
import DeleteAttendeeIcon from "@material-ui/icons/DeleteOutlined";
import AttendeePresenceIcon from "@material-ui/icons/Beenhere";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { AvatarCell } from "../../../components/tables/MaterialTableCells";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import ChipWarning from "../../../components/chips/ChipWarning";
import ChipInfo from "../../../components/chips/ChipInfo";
import ChipError from "../../../components/chips/ChipError";
import * as entryUtils from "../entryUtils";
import { COURSE_ATTENDEE_STATUS_DELETED } from "../../services/utils";
import CourseAttendeeStatusFilter from "../../../components/tables/toolbar/filter/CourseAttendeeStatusFilter";
import CourseLeaderAddModal from "../../services/courses/CourseLeaderAddModal";
import { isAtLeastStaffPermission, isAtLeastBookingPermission } from "../../resources/utils";

const useStyles = makeStyles((theme) => ({
    staffAccordion: {
        "&.MuiAccordion-root:before": {
            height: "0px",
        },
    },
    attendeesToolbarContainer: {
        padding: "12px",
    },
    resourceSectionHeader: {
        display: "flex",
        alignItems: "center",
    },
    addResourceIcon: {
        fontSize: "18px",
    },
    attendeePresenceIconWrapper: {
        margin: theme.spacing(1),
        height: "30px",
        width: "30px",
        fontSize: "1.75rem",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    attendeePresenceIconNotPresent: {
        color: "#0000008A",
    },
    attendeePresenceIconPresent: {
        color: "#4caf50",
    },
    deleteAttendeeIconWrapper: {
        margin: theme.spacing(1),
        height: "30px",
        width: "30px",
        "&:hover": {
            backgroundColor: "#f4516c",
            color: "white",
        },
    },
}));

const AccordionSummaryStyled = withStyles({
    root: {
        minHeight: "56px",
        "&$expanded": {
            minHeight: "56px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 0px 0px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemIconStyled = withStyles({
    root: {
        minWidth: "40px",
        justifyContent: "center",
    },
})(ListItemIcon);

const AttendeeAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const AttendeeAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        justifyContent: "space-between",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

function DrawerCourseOccasionDetailsTab({
    getEntryAttendees,
    addEntryOccasionResource,
    updateEntryOccasionAttendeePresence,
    setAttendeeToBeDeleted,
    parentClasses,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { entry, customerAttendeesListPagination, staffAttendeesListPagination } = useSelector(
        (state) => state.entries
    );
    const { isAttendeesLoading } = useSelector((state) => state.bookingManager);
    const classes = useStyles(entry);
    const intl = useIntl();

    // Info tab
    const [attendeeStatusFilter, setAttendeeStatusFilter] = useState("attending");
    const [showModalAddLeader, setShowModalAddLeader] = useState(false);

    useEffect(() => {
        getEntryAttendees(entry.id, 1, 20, "staff");
    }, [getEntryAttendees, entry.id]);

    const fetchAttendees = useCallback(
        ({ pageIndex, pageSize, type }) => {
            getEntryAttendees(entry.id, pageIndex + 1, pageSize, type ? type : "customer", attendeeStatusFilter);
        },
        [getEntryAttendees, entry.id, attendeeStatusFilter]
    );

    const getAttendeeStatusChip = (status) => {
        if (status === entryUtils.ATTENDEE_STATUS_CONFIRMED) {
            return (
                <ChipSuccess label={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.CONFIRMED" />} />
            );
        } else if (status === entryUtils.ATTENDEE_STATUS_UNPAID) {
            return <ChipWarning label={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.UNPAID" />} />;
        } else if (status === entryUtils.ATTENDEE_STATUS_UNCONFIRMED) {
            return (
                <ChipWarning label={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.UNCONFIRMED" />} />
            );
        } else if (status === entryUtils.ATTENDEE_STATUS_CANCELLED) {
            return <ChipInfo label={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.CANCELLED" />} />;
        } else if (status === entryUtils.ATTENDEE_STATUS_DELETED) {
            return <ChipError label={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.STATUS.DELETED" />} />;
        }
    };

    const onAttendeeStatusChanged = useCallback((value) => {
        setAttendeeStatusFilter(value);
    }, []);

    const customerAttendeeListData = useMemo(() => customerAttendeesListPagination.data, [
        customerAttendeesListPagination.data,
    ]);

    const listItemSecondaryProps = useMemo(() => {
        return {
            variant: "body1",
        };
    }, []);
    const sectionPaperProps = { elevation: 0, variant: "outlined" };

    const attendeeListColumns = useMemo(
        () => [
            {
                accessor: "name",
                Cell: ({ row }) => {
                    const attendee = row.original;
                    const booking = attendee.booking;
                    const customer = booking ? booking.customer : null;

                    return (
                        <AttendeeAccordionStyled elevation={0} variant={"elevation"}>
                            <AttendeeAccordionSummaryStyled>
                                <Link to={`/customers/${customer.id}`}>
                                    <div style={{ maxWidth: "275px" }}>
                                        {AvatarCell(
                                            customer.avatarUrl,
                                            `${customer.firstName} ${customer.surname}`,
                                            customer.email
                                        )}
                                    </div>
                                </Link>
                                <div style={{ flex: "1" }}></div>
                                {attendee.status !== entryUtils.ATTENDEE_STATUS_CANCELLED &&
                                    attendee.status !== entryUtils.ATTENDEE_STATUS_DELETED && (
                                        <IconButton
                                            className={classes.attendeePresenceIconWrapper}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (isAtLeastBookingPermission(profile.permissionId)) {
                                                    attendee.isPresent = !attendee.isPresent;
                                                    updateEntryOccasionAttendeePresence(
                                                        attendee.id,
                                                        attendee.isPresent
                                                    );
                                                }
                                            }}
                                            title={intl.formatMessage({
                                                id: "CALENDAR.COURSE_OCCASION.ATTENDEES.PRESENCE_DESCRIPTION",
                                            })}
                                        >
                                            <ListItemIconStyled>
                                                <AttendeePresenceIcon
                                                    fontSize="inherit"
                                                    className={
                                                        !attendee.isPresent
                                                            ? classes.attendeePresenceIconNotPresent
                                                            : classes.attendeePresenceIconPresent
                                                    }
                                                />
                                            </ListItemIconStyled>
                                        </IconButton>
                                    )}
                                {getAttendeeStatusChip(attendee.status)}
                            </AttendeeAccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <ListStyled>
                                    <ListItem>
                                        <ListItemIconStyled>
                                            <HistoryIcon />
                                        </ListItemIconStyled>
                                        <ListItemText
                                            secondary={
                                                <FormattedMessage
                                                    id="CALENDAR.COURSE_OCCASION.ATTENDEES.CREATED_BY"
                                                    values={{
                                                        date: moment(attendee.createdAt).format("YYYY-MM-DD HH:mm"),
                                                    }}
                                                />
                                            }
                                            secondaryTypographyProps={listItemSecondaryProps}
                                        ></ListItemText>
                                        <ListItemIconStyled>
                                            <LinkIcon />
                                        </ListItemIconStyled>
                                        <ListItemText
                                            secondary={
                                                <Link to={`/bookings/${booking.id}/details`}>
                                                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.GO_TO_BOOKING" />
                                                </Link>
                                            }
                                            secondaryTypographyProps={listItemSecondaryProps}
                                        ></ListItemText>
                                        {attendee.status !== COURSE_ATTENDEE_STATUS_DELETED &&
                                            isAtLeastBookingPermission(profile.permissionId) && (
                                                <IconButton
                                                    className={classes.deleteAttendeeIconWrapper}
                                                    onClick={() => setAttendeeToBeDeleted(attendee)}
                                                >
                                                    <ListItemIconStyled>
                                                        <DeleteAttendeeIcon />
                                                    </ListItemIconStyled>
                                                </IconButton>
                                            )}
                                    </ListItem>
                                </ListStyled>
                            </AccordionDetailsStyled>
                        </AttendeeAccordionStyled>
                    );
                },
            },
        ],
        [
            listItemSecondaryProps,
            classes.attendeePresenceIconWrapper,
            classes.attendeePresenceIconNotPresent,
            classes.attendeePresenceIconPresent,
            classes.deleteAttendeeIconWrapper,
            intl,
            updateEntryOccasionAttendeePresence,
            setAttendeeToBeDeleted,
            profile.permissionId,
        ]
    );

    return (
        <div className={parentClasses.tabContainer}>
            {entry.deletedAt && (
                <div className={parentClasses.sectionContainer}>
                    <Alert severity="info">
                        <FormattedMessage
                            id="CALENDAR.ENTRY.REMOVED_BY"
                            values={{
                                date: moment(entry.deletedAt).format("YYYY-MM-DD HH:mm"),
                                name: entry.deletedBy.firstName + " " + entry.deletedBy.surname,
                            }}
                        />
                    </Alert>
                </div>
            )}

            <div className={parentClasses.sectionContainer}>
                <Typography className={parentClasses.sectionLabel} variant="subtitle1">
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.COURSE.SECTION_TITLE" />
                </Typography>
                <div>
                    <Accordion {...sectionPaperProps}>
                        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                            <div className={parentClasses.accordionHeaderContainer}>
                                <Typography>{entry.service.name}</Typography>
                            </div>
                        </AccordionSummaryStyled>
                        <AccordionDetailsStyled>
                            <ListStyled>
                                <ListItem>
                                    <ListItemIconStyled>
                                        <HistoryIcon />
                                    </ListItemIconStyled>
                                    <ListItemText
                                        secondary={
                                            <FormattedMessage
                                                id="CALENDAR.COURSE_OCCASION.COURSE.RUNS_BETWEEN"
                                                values={{
                                                    startDate: moment(entry.service.startTime).format("YYYY-MM-DD"),
                                                    endDate: moment(entry.service.endTime).format("YYYY-MM-DD"),
                                                }}
                                            />
                                        }
                                        secondaryTypographyProps={listItemSecondaryProps}
                                    ></ListItemText>
                                </ListItem>
                                {isAtLeastStaffPermission(profile.permissionId) && (
                                    <>
                                        <Divider />
                                        <ListItem button>
                                            <ListItemIconStyled>
                                                <LinkIcon />
                                            </ListItemIconStyled>
                                            <ListItemText
                                                secondary={
                                                    <Link to={`/services/${entry.service.id}/group-service`}>
                                                        <FormattedMessage id="CALENDAR.COURSE_OCCASION.COURSE.GO_TO_SERVICE" />
                                                    </Link>
                                                }
                                                secondaryTypographyProps={listItemSecondaryProps}
                                            ></ListItemText>
                                        </ListItem>
                                    </>
                                )}
                            </ListStyled>
                        </AccordionDetailsStyled>
                    </Accordion>
                </div>
            </div>

            <div className={parentClasses.sectionContainer}>
                <div className={classes.resourceSectionHeader}>
                    <Typography
                        className={parentClasses.sectionLabel}
                        variant="subtitle1"
                        style={{ flexGrow: 1, alignSelf: "end" }}
                    >
                        <FormattedMessage id="CALENDAR.COURSE_OCCASION.RESOURCES" />
                    </Typography>
                    {isAtLeastStaffPermission(profile.permissionId) && (
                        <IconButton onClick={() => setShowModalAddLeader(true)}>
                            <AddResourceIcon color="action" className={classes.addResourceIcon} />
                        </IconButton>
                    )}
                </div>
                <div>
                    {staffAttendeesListPagination.data.map((staffAttendee, i) => (
                        <Accordion
                            {...sectionPaperProps}
                            key={staffAttendee.id}
                            className={classes.staffAccordion}
                            style={{ marginTop: i === 0 ? "0px" : "8px" }}
                        >
                            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                                <div className={parentClasses.accordionHeaderContainer}>
                                    <Avatar alt={staffAttendee.resource.name} src={staffAttendee.resource.avatarUrl} />
                                    <Typography className={parentClasses.accordionIconHeaderLabel}>
                                        {staffAttendee.resource.name}
                                    </Typography>
                                </div>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <ListStyled component="nav" aria-label="main mailbox folders">
                                    {isAtLeastStaffPermission(profile.permissionId) && (
                                        <>
                                            <ListItem button>
                                                <ListItemIconStyled>
                                                    <LinkIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        <Link
                                                            to={`/resources/${staffAttendee.resource.id}/${staffAttendee.resource.type}`}
                                                        >
                                                            <FormattedMessage id="CALENDAR.COURSE_OCCASION.RESOURCES.GO_TO_PROFILE" />
                                                        </Link>
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                ></ListItemText>
                                            </ListItem>
                                            <Divider />
                                            <ListItem button onClick={() => setAttendeeToBeDeleted(staffAttendee)}>
                                                <ListItemIconStyled>
                                                    <DeleteAttendeeIcon />
                                                </ListItemIconStyled>
                                                <ListItemText
                                                    secondary={
                                                        <FormattedMessage id="CALENDAR.COURSE_OCCASION.RESOURCES.DELETE" />
                                                    }
                                                    secondaryTypographyProps={listItemSecondaryProps}
                                                ></ListItemText>
                                            </ListItem>
                                        </>
                                    )}
                                </ListStyled>
                            </AccordionDetailsStyled>
                        </Accordion>
                    ))}
                </div>
            </div>

            <div className={parentClasses.sectionContainer}>
                <Typography className={parentClasses.sectionLabel} variant="subtitle1">
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES" />
                </Typography>
                <Paper {...sectionPaperProps}>
                    <div className={classes.attendeesToolbarContainer}>
                        <CourseAttendeeStatusFilter onStatusChanged={onAttendeeStatusChanged} />
                    </div>
                    <Divider />
                    <MaterialReactTable
                        key={entry.id}
                        data={customerAttendeeListData}
                        fetchData={fetchAttendees}
                        pageSize={20}
                        rowCount={customerAttendeesListPagination.totalRows}
                        isLoading={isAttendeesLoading}
                        columns={attendeeListColumns}
                        showHeader={false}
                        getCustomCellProps={() => ({
                            style: { paddingLeft: "0px", paddingRight: "0px" },
                        })}
                        emptyMessage={<FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.NO_RESULT" />}
                    />
                </Paper>
            </div>

            {showModalAddLeader && (
                <CourseLeaderAddModal
                    show={showModalAddLeader}
                    showOccasions={false}
                    onCloseClicked={() => setShowModalAddLeader(false)}
                    onLeaderAdded={(data) => {
                        addEntryOccasionResource(entry.id, data.resourceId);
                        setShowModalAddLeader(false);
                    }}
                />
            )}
        </div>
    );
}

export default injectIntl(connect(null, bookingManagerActions)(DrawerCourseOccasionDetailsTab));
