import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as pricingTemplateActions } from "../../../redux/pricingtemplates/pricingTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../components/SnackbarError";
import PricingTemplateDetailsForm from "./PricingTemplateDetailsForm";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function PricingTemplatePageAdd({
    addProfilePricingTemplate,
    clearPricingTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { pricingTemplate, isLoading } = useSelector((state) => state.pricingTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        return () => {
            clearPricingTemplateState();
            clearErrorState();
        };
    }, [clearPricingTemplateState, clearErrorState]);

    const onFormSubmit = (values) => {
        var pricingTemplateData = {
            name: values.name,
        };

        addProfilePricingTemplate(profileId, pricingTemplateData, () => {
            setRedirect(true);
        });
    };

    if (redirect && pricingTemplate) {
        return <Redirect to={`/pricing-templates/${pricingTemplate.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="PRICING_TEMPLATE.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="PROFILE.PRICING_TEMPLATES.SUBTITLE" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <PricingTemplateDetailsForm />

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...pricingTemplateActions,
    ...errorActions,
})(PricingTemplatePageAdd);
