import React from "react";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
});

function ResourceGroupPageDetails({ updateResourceGroup }) {
    const { resourceGroup, isUpdating } = useSelector((state) => state.resources);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        updateResourceGroup(resourceGroup.id, resourceGroup, values.name, values.description);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.GROUP.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.GROUP.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            isLoading={isUpdating}
                            onClick={handleSubmit(onFormSubmit)}
                        >
                            <FormattedMessage id="COMMON.SAVE" />
                        </BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.GROUP.DETAILS.NAME" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="name"
                                        ref={register}
                                        isInvalid={errors.name}
                                        defaultValue={resourceGroup?.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.GROUP.DETAILS.DESCRIPTION" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        as="textarea"
                                        rows={8}
                                        className="form-control-lg"
                                        name="description"
                                        ref={register}
                                        isInvalid={errors.description}
                                        defaultValue={resourceGroup?.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, resourceActions)(ResourceGroupPageDetails);
