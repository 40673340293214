import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Switch } from "@material-ui/core";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({});

function WaitingListMemberMoveModal({
    waitingListMember,
    moveToName,
    show,
    onCloseClicked,
    acceptWaitingListMember,
    onMoved,
}) {
    const { isLoading } = useSelector((state) => state.services);
    const classes = useStyles();

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        acceptWaitingListMember(waitingListMember.id, values.isMarkedAsPaid, () => {
            onMoved(waitingListMember);
        });
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage
                        id={"WAITING_LIST.MEMBER.MOVE.MODAL.TITLE"}
                        values={{ name: waitingListMember.name, moveTo: moveToName }}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.formContent}>
                    <Form.Group>
                        <Alert severity="info">
                            <FormattedMessage id="WAITING_LIST.MEMBER.MOVE.MODAL.INFO" />
                        </Alert>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="WAITING_LIST.MEMBER.MOVE.MODAL.MARK_AS_PAID" />
                        </Form.Label>
                        <Controller
                            name="isMarkedAsPaid"
                            control={control}
                            defaultValue={false}
                            render={({ value, onChange }) => {
                                return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                            }}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="WAITING_LIST.MEMBER.MOVE.MODAL.MOVE_BUTTON" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, serviceActions)(WaitingListMemberMoveModal);
