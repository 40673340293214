import React, { useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as mediaActions } from "../../../redux/media/mediaRedux";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    dropzone: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
    },
    acceptedFileList: {
        marginBottom: "0px",
    },
}));

const schema = yup.object().shape({});

function MediaContainerUploadModal({
    show,
    onCloseClicked,
    resourceId,
    resourceGroupId,
    addResourceMediaContainerItem,
    addResourceGroupMediaContainerItem,
}) {
    const { isUpdating } = useSelector((state) => state.media);
    const [fileArrayBuffer, setFileArrayBuffer] = useState();
    const [fileName, setFileName] = useState();
    const classes = useStyles();

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => {};
            reader.onerror = () => {};
            reader.onload = () => {
                setFileArrayBuffer(reader.result);
                setFileName(file.path);
            };
            reader.readAsArrayBuffer(file);
        });
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        maxFiles: 1,
        onDrop,
    });

    const { handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        if (!fileArrayBuffer || fileArrayBuffer.length === 0) {
            return;
        }
        if (resourceId) {
            addResourceMediaContainerItem(resourceId, fileArrayBuffer, fileName, () => {
                setFileArrayBuffer(null);
                setFileName(null);
                onCloseClicked();
            });
        } else if (resourceGroupId) {
            addResourceGroupMediaContainerItem(resourceGroupId, fileArrayBuffer, fileName, () => {
                setFileArrayBuffer(null);
                setFileName(null);
                onCloseClicked();
            });
        }
    };

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id={"COMMON.UPLOAD"} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.formContent}>
                        <div {...getRootProps({ className: classes.dropzone })}>
                            <input {...getInputProps()} />
                            {acceptedFiles.length === 0 && (
                                <FormattedMessage id={"RESOURCE.MEDIA.UPLOAD.DROPZONE.TEXT"} />
                            )}
                            <ul className={classes.acceptedFileList}>
                                {acceptedFiles.map((file) => (
                                    <li key={file.path}>{file.path}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isUpdating}
                        onClick={handleSubmit(onFormSubmit)}
                        label={<FormattedMessage id={"COMMON.UPLOAD"} />}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, mediaActions)(MediaContainerUploadModal);
