import React, { useState, useEffect } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./resourcehours.css";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { makeStyles, Typography, Divider } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getWeekListItems, getWeekNameAbbreviated } from "./utils";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    dayListContainer: {
        padding: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: "0px",
            paddingRight: "0px",
        },
    },
    dayContainer: {
        display: "flex",
        padding: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1),
        },
    },
    dayNameContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    dayNameLabel: {
        [theme.breakpoints.down("md")]: {
            fontSize: "1.2rem",
        },
    },
    dayDateLabel: {},
    dayAllHoursContainer: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        marginRight: "32px",
        [theme.breakpoints.down("md")]: {
            marginRight: "16px",
        },
    },
    dayHourContainer: {
        display: "flex",
        alignItems: "center",
    },
    dayHourIntervalContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2px",
        width: "110px",
        [theme.breakpoints.down("md")]: {
            width: "95px",
        },
    },
    resourceGroupIcon: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    dayHourText: {
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
        },
    },
    dayButtonsContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
    },
    addHourButton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    removeHourButton: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    noHoursLabel: {
        [theme.breakpoints.down("md")]: {
            fontSize: "1.2rem",
        },
    },
}));

const todayDate = moment();
const firstWeekDate = moment(todayDate).subtract(1, "years").startOf("isoWeek");
const lastWeekDate = moment(todayDate).add(2, "years").startOf("isoWeek");
const weekList = getWeekListItems(firstWeekDate, lastWeekDate, moment);
const currentWeekDate = moment(todayDate).startOf("isoWeek");

const Menu = (list, selected, todayDate, intl) =>
    list.map((el) => {
        const { weekDate } = el;
        return (
            <MenuItem
                key={weekDate.format("YYYY-MM-DD")}
                text={getWeekNameAbbreviated(weekDate, todayDate, intl)}
                selected={selected.format("YYYY-MM-DD")}
            />
        );
    });

const MenuItem = ({ text, selected }) => {
    return <div className={`menu-item ${selected ? "active" : ""}`}>{text}</div>;
};

const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
};

function ResourceHours({ hourDays, selectedWeekDate, onWeekChanged, onHourAdded, onHourUpdated, onHourRemoved }) {
    const [timePickerOpen, setTimePickerOpen] = useState(false);
    const [timePickerField, setTimePickerField] = useState();
    const [timePickerDateTime, setTimePickerDateTime] = useState();
    const classes = useStyles();
    const intl = useIntl();
    selectedWeekDate = selectedWeekDate || currentWeekDate;

    const openTimePicker = (field, momentTime) => {
        setTimePickerField(field);
        setTimePickerDateTime(momentTime);
    };

    useEffect(() => {
        // Picker does not update to clicked time properly if not put here in effect
        if (timePickerDateTime) {
            setTimePickerOpen(true);
        }
    }, [timePickerDateTime]);

    const DayItem = ({ dayIndex }) => {
        const date = moment(selectedWeekDate).add(dayIndex, "days");
        const dayDate = date.format("YYYY-MM-DD");
        const dayName = date.format("dddd");
        const hourDay = hourDays.find((x) => x.date === dayDate);
        const hoursForDay = hourDay ? hourDay.hours : [];
        return (
            <>
                <div className={classes.dayContainer}>
                    <div className={classes.dayNameContainer}>
                        <Typography variant="h5" className={classes.dayNameLabel}>
                            {dayName.charAt(0).toUpperCase() + dayName.slice(1)}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.dayDateLabel}>
                            {dayDate}
                        </Typography>
                    </div>
                    <div className={classes.dayAllHoursContainer}>
                        {hoursForDay.length > 0 &&
                            hoursForDay.map((x) => {
                                return (
                                    <div
                                        className={classes.dayHourContainer}
                                        key={`${dayDate}-${x.startTime}-${x.endTime}`}
                                    >
                                        {x.resourceGroup && (
                                            <a
                                                href={`/resource-groups/${x.resourceGroup.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={classes.resourceGroupIcon}
                                            >
                                                <i className="fas fa-users"></i>
                                            </a>
                                        )}
                                        <div className={classes.dayHourIntervalContainer}>
                                            <span
                                                className={classes.dayHourText}
                                                onClick={() => {
                                                    openTimePicker("startTime", moment(`${dayDate}T${x.startTime}`));
                                                }}
                                            >
                                                {`${x.startTime}`}
                                            </span>
                                            <span
                                                className={classes.dayHourText}
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                }}
                                            >
                                                {" - "}
                                            </span>
                                            <span
                                                className={classes.dayHourText}
                                                onClick={() => {
                                                    openTimePicker("endTime", moment(`${dayDate}T${x.endTime}`));
                                                }}
                                            >
                                                {`${x.endTime}`}
                                            </span>
                                        </div>
                                        <div
                                            className={classes.removeHourButton}
                                            onClick={() => onHourRemoved(dayDate, x.startTime, x.endTime)}
                                        >
                                            <i className="fas fa-times-circle"></i>
                                        </div>
                                    </div>
                                );
                            })}
                        {hoursForDay.length === 0 && (
                            <Typography variant="h6" className={classes.noHoursLabel}>
                                <FormattedMessage id="RESOURCE.GROUP.HOURS.NO_HOURS" />
                            </Typography>
                        )}
                    </div>
                    <div className={classes.dayButtonsContainer}>
                        <div className={classes.addHourButton} onClick={() => onHourAdded(dayDate)}>
                            <i className="fas fa-plus fa-2x"></i>
                        </div>
                    </div>
                </div>
                <Divider />
            </>
        );
    };

    const dayItems = [];
    for (let i = 0; i < 7; i++) {
        dayItems.push(<DayItem dayIndex={i} key={`day-${i}`}></DayItem>);
    }

    return (
        <>
            <ScrollMenu
                data={Menu(weekList, selectedWeekDate, todayDate, intl)}
                arrowLeft={Arrow({
                    text: "<",
                    className: "arrow-prev",
                })}
                arrowRight={Arrow({
                    text: ">",
                    className: "arrow-next",
                })}
                selected={selectedWeekDate.format("YYYY-MM-DD")}
                onSelect={(weekDate) => {
                    onWeekChanged(moment(weekDate));
                }}
                scrollToSelected={true}
                dragging={false}
            />
            <div className={classes.dayListContainer}>{dayItems}</div>
            {/* Since input fields of pickers are harder to customize, keep track of open state*/}
            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <TimePicker
                        ampm={false}
                        open={timePickerOpen}
                        onClose={() => setTimePickerOpen(false)}
                        onChange={(time) => {
                            onHourUpdated(
                                timePickerField,
                                timePickerDateTime.format("YYYY-MM-DD"),
                                `${timePickerDateTime.format("HH")}:${timePickerDateTime.format("mm")}`,
                                `${time.format("HH")}:${time.format("mm")}`
                            );
                        }}
                        value={
                            timePickerDateTime
                                ? moment()
                                      .set("hour", timePickerDateTime.hour())
                                      .set("minute", timePickerDateTime.minute())
                                : moment()
                        }
                    />
                </MuiPickersUtilsProvider>
            </div>
        </>
    );
}
export default ResourceHours;
