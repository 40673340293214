import React, { useEffect, useState } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapPhoneInput from "../../components/inputs/BootstrapPhoneInput";
import { getDefaultPhoneCountry } from "../../utils/LocaleUtils";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "@material-ui/pickers";
import { Switch } from "@material-ui/core";
import { isAtLeastStaffPermission } from "../resources/utils";

const schema = yup.object().shape({
    firstName: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    surname: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    email: yup.string(),
    phone: yup.string(),
    notes: yup.string(),
});

function CustomerPageDetails({ updateCustomer }) {
    const { profile } = useSelector((state) => state.auth);
    const { customer, isUpdating } = useSelector((state) => state.customers);

    const { register, handleSubmit, errors, control, getValues, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const [birthDayPickerOpen, setBirthDayPickerOpen] = useState(false);

    useEffect(() => {
        if (customer) {
            if (customer.phoneNumber) {
                setValue("phoneNumber", customer.phoneNumber);
            }

            if (customer.isCampaignExcluded) {
                setValue("isCampaignExcluded", customer.isCampaignExcluded);
            }
        }
    }, [customer, getValues, setValue]);

    const onFormSubmit = (values) => {
        updateCustomer(customer.id, values);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {isAtLeastStaffPermission(profile.permissionId) && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.FIRST_NAME" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="firstName"
                                        ref={register}
                                        isInvalid={errors.firstName}
                                        defaultValue={customer?.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.SURNAME" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="surname"
                                        ref={register}
                                        isInvalid={errors.surname}
                                        defaultValue={customer?.surname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.surname?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.EMAIL" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="email"
                                        className="form-control-lg"
                                        name="email"
                                        ref={register}
                                        isInvalid={errors.email}
                                        defaultValue={customer?.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.PHONE" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        defaultValue={""}
                                        render={(props) => {
                                            return (
                                                <BootstrapPhoneInput
                                                    country={getDefaultPhoneCountry()}
                                                    onChange={(v) => props.onChange(v)}
                                                    masks={{ se: ".. ... .. .." }}
                                                    value={props.value}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.BIRTHDAY" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="birthDay"
                                        ref={register}
                                        onClick={() => setBirthDayPickerOpen(true)}
                                        defaultValue={customer?.birthDay}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.NOTES" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        as="textarea"
                                        rows={8}
                                        className="form-control-lg"
                                        name="notes"
                                        ref={register}
                                        isInvalid={errors.notes}
                                        defaultValue={customer?.notes}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.notes?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="CUSTOMER.DETAILS.CAMPAIGN_EXCLUDE" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Controller
                                        name="isCampaignExcluded"
                                        control={control}
                                        defaultValue={customer ? customer.isCampaignExcluded : false}
                                        render={({ value, onChange }) => {
                                            return (
                                                <Switch checked={value} onChange={(event, value) => onChange(value)} />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </div>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                    showTodayButton
                    style={{ display: "none" }}
                    open={birthDayPickerOpen}
                    onClose={() => setBirthDayPickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue("birthDay", selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD") : null, {
                            shouldValidate: true,
                        });
                    }}
                    maxDate={new Date()}
                    clearable={true}
                    value={customer?.birthDay}
                />
            </MuiPickersUtilsProvider>
        </Card>
    );
}
export default connect(null, customerActions)(CustomerPageDetails);
