import React, { useState, useEffect } from "react";
import { makeStyles, Popover } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import DefaultAsyncTypeahead from "../../../components/DefaultAsyncTypeahead";
import {
    APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME,
    APPOINTMENT_TITLE_FORMAT_ID_RESOURCENAME,
    APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME_RESOURCENAME,
    getCalendarSettings,
} from "../entryUtils";

const useStyles = makeStyles((theme) => ({
    popupRoot: {
        marginTop: "8px",
        width: "300px",
        height: "500px",
    },
    filterPopup: {},

    switchContainer: {
        padding: "12px",
    },

    bottomContainer: {
        marginTop: "8px",
        padding: "12px",
    },
    dropdownContainer: {
        padding: "12px",
        marginBottom: "12px",
    },

    listItemAvatarContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

const defaultValues = {
    appointmentTitleFormat: {},
};

function CalendarSettingsPopup({ isOpen, anchor, onClose, profileId, settingsData, onSettingsUpdated }) {
    const classes = useStyles();
    const intl = useIntl();

    defaultValues.appointmentTitleFormat = getAppointmentTitleFormatOptions(intl)[0];
    const [selectedAppointmentTitleFormat, setSelectedAppointmentTitleFormat] = useState(
        defaultValues.appointmentTitleFormat
    );

    useEffect(() => {
        const calendarSettings = getCalendarSettings(profileId);
        if (calendarSettings) {
            const appointmentTitleFormats = getAppointmentTitleFormatOptions(intl);
            const selectedAppointmentFormat = appointmentTitleFormats.find(
                (x) => x.id === calendarSettings.appointmentTitleFormat
            );
            setSelectedAppointmentTitleFormat(selectedAppointmentFormat);
        }
    }, [profileId, intl]);

    const onTitleFormatChanged = (formats) => {
        if (!formats || formats.length === 0) return;
        const selectedFormat = formats[0];
        setSelectedAppointmentTitleFormat(selectedFormat);
        updateFilter({ ...settingsData, appointmentTitleFormat: selectedFormat.id });
        updateLocalStorageSettings("appointmentTitleFormat", selectedFormat);
    };

    const updateFilter = (updatedFilter) => {
        onSettingsUpdated(updatedFilter);
    };

    const updateLocalStorageSettings = (property, newValue) => {
        const values = {
            profileId,
            appointmentTitleFormat: selectedAppointmentTitleFormat.id,
        };

        if (property === "appointmentTitleFormat") {
            newValue = newValue.id;
        }

        values[property] = newValue;

        const allLocalStorageSettingsJson = localStorage.getItem("calendarSettings");
        let allLocalStorageSettings = allLocalStorageSettingsJson ? JSON.parse(allLocalStorageSettingsJson) : null;
        let currentLocalStorageSettings;
        if (allLocalStorageSettings) {
            currentLocalStorageSettings = allLocalStorageSettings.find((x) => x.profileId === profileId);
        }

        if (currentLocalStorageSettings) {
            const existingIndex = allLocalStorageSettings.findIndex((x) => x.profileId === profileId);
            allLocalStorageSettings[existingIndex] = values;
        } else if (allLocalStorageSettings) {
            allLocalStorageSettings.push(values);
        } else {
            allLocalStorageSettings = [values];
        }

        localStorage.setItem("calendarSettings", JSON.stringify(allLocalStorageSettings));
    };

    return (
        <Popover
            id={"calendar-filter-popup"}
            open={isOpen}
            anchorEl={anchor}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            PaperProps={{ className: classes.popupRoot }}
        >
            <div className={classes.filterPopup}>
                <div className={classes.dropdownContainer}>
                    <Form.Label>
                        <FormattedMessage id="CALENDAR.SETTINGS.APPOINTMENT_TITLE_FORMAT.TITLE" />
                    </Form.Label>

                    <DefaultAsyncTypeahead
                        id="typeahead-filter-resources"
                        labelKey={"name"}
                        minLength={0}
                        clearButton={false}
                        onChange={onTitleFormatChanged}
                        onSearch={() => {}}
                        onInputChange={(input) => {}}
                        useCache={false}
                        options={getAppointmentTitleFormatOptions(intl)}
                        selected={selectedAppointmentTitleFormat}
                    ></DefaultAsyncTypeahead>
                </div>
            </div>
        </Popover>
    );
}

function getAppointmentTitleFormatOptions(intl) {
    let options = [];

    options.push({
        id: APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME,
        name: intl.formatMessage({
            id: "CALENDAR.SETTINGS.APPOINTMENT_TITLE_FORMAT.CUSTOMERNAME",
        }),
    });

    options.push({
        id: APPOINTMENT_TITLE_FORMAT_ID_RESOURCENAME,
        name: intl.formatMessage({
            id: "CALENDAR.SETTINGS.APPOINTMENT_TITLE_FORMAT.RESOURCENAME",
        }),
    });

    options.push({
        id: APPOINTMENT_TITLE_FORMAT_ID_CUSTOMERNAME_RESOURCENAME,
        name: intl.formatMessage({
            id: "CALENDAR.SETTINGS.APPOINTMENT_TITLE_FORMAT.CUSTOMERNAME_RESOURCENAME",
        }),
    });

    return options;
}

export default CalendarSettingsPopup;
