import React from "react";
import { makeStyles } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import ToolbarSearchField from "./ToolbarSearchField";
import ServiceStatusFilter from "./filter/ServiceStatusFilter";
import ServiceTypeFilter from "./filter/ServiceTypeFilter";
import AddServiceButton from "./buttons/AddServiceButton";
import SortServiceButton from "./buttons/SortServiceButton";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: theme.spacing(1),
    },
    searchAndFilterContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        "& > div": { margin: theme.spacing(1) },
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
    sortServicesButton: {
        display: "inline",
        marginRight: theme.spacing(1),
    },
}));

function ServiceListToolbar({ onSearchChanged, onStatusChanged, onTypeChanged }) {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.searchAndFilterContainer}>
                <ToolbarSearchField onSearchChanged={onSearchChanged} />
                <ServiceStatusFilter onStatusChanged={onStatusChanged} />
                <ServiceTypeFilter onTypeChanged={onTypeChanged} />
            </div>
            <div className={classes.buttonContainer}>
                <div className={classes.sortServicesButton}>
                    <SortServiceButton />
                </div>
                <AddServiceButton />
            </div>
        </Toolbar>
    );
}

export default ServiceListToolbar;
