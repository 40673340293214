import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";
import { Switch } from "@material-ui/core";

function CancellationTemplateDetailsForm({ cancellationTemplate }) {
    const { register, control, setValue, errors } = useFormContext();

    useEffect(() => {
        if (cancellationTemplate) {
            setValue("isDefault", cancellationTemplate.isDefault);
            setValue("isAutomaticRefund", cancellationTemplate.isAutomaticRefund);
        }
    }, [cancellationTemplate, setValue]);

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={cancellationTemplate?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.NAME_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.IS_DEFAULT" />
                </Form.Label>
                <Controller
                    name="isDefault"
                    control={control}
                    defaultValue={cancellationTemplate ? cancellationTemplate.isDefault : false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.IS_DEFAULT_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.MINUTE_LIMIT" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-2"
                    name="minuteLimit"
                    ref={register}
                    isInvalid={errors.minuteLimit}
                    defaultValue={cancellationTemplate?.minuteLimit}
                />
                <Form.Control.Feedback type="invalid">{errors.minuteLimit?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.MINUTE_LIMIT_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.IS_AUTOMATIC_REFUND" />
                </Form.Label>
                <Controller
                    name="isAutomaticRefund"
                    control={control}
                    defaultValue={cancellationTemplate ? cancellationTemplate.isAutomaticRefund : false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="CANCELLATION_TEMPLATE.DETAILS.IS_AUTOMATIC_REFUND_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default connect(null, serviceCategoriesActions)(CancellationTemplateDetailsForm);
