import React, { useState, useMemo, useCallback } from "react";
import { actions as pricingTemplateActions } from "../../../redux/pricingtemplates/pricingTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell } from "../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import PricingTemplateTimeRulesAdjustModal from "./PricingTemplateTimeRulesAdjustModal";
import { getDayName } from "../../utils/DateUtils";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { PRICE_PER_UNIT_MINUTE, PRICE_PER_UNIT_HOUR } from "../services/utils";

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        margin: theme.spacing(2),
    },
}));

function PricingTemplateTimeRules({ getPricingTemplateTimeRules, updatePricingTemplateTimeRules }) {
    const { pricingTemplate, timeRulesPagination, isLoading } = useSelector((state) => state.pricingTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const pricingTemplateId = pricingTemplate?.id;

    const intl = useIntl();
    const classes = useStyles();

    const [showAddModal, setShowAddModal] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (pricingTemplateId) {
                getPricingTemplateTimeRules(pricingTemplateId, pageIndex + 1, pageSize);
            }
        },
        [pricingTemplateId, getPricingTemplateTimeRules]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        {pricingTemplate && !pricingTemplate.deletedAt && (
                            <BootstrapSaveButton
                                label={<FormattedMessage id="COMMON.ADJUST" />}
                                onClick={() => setShowAddModal(true)}
                            ></BootstrapSaveButton>
                        )}
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <Alert severity="info" className={classes.alertContainer}>
                        <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ALERT_INFO" />
                    </Alert>
                    <MaterialReactTable
                        key={pricingTemplateId}
                        data={useMemo(() => timeRulesPagination.data, [timeRulesPagination.data])}
                        fetchData={fetchData}
                        pageSize={100}
                        rowCount={timeRulesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: (
                                        <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.LIST.HEADER.DAY_OF_WEEK" />
                                    ),
                                    accessor: "dayOfWeek",
                                    Cell: (props) => {
                                        return TextCell(getDayName(props.value, intl));
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.LIST.HEADER.PERIOD" />,
                                    accessor: "periodTimes",
                                    Cell: ({ row }) => {
                                        const dayOfWeek = row.original.dayOfWeek;
                                        const periods = row.original.periods;
                                        if (periods) {
                                            return periods.map((period) => (
                                                <div key={`${dayOfWeek}_period_${period.startTime}`}>
                                                    {TextCell(`${period.startTime} - ${period.endTime}`)}
                                                </div>
                                            ));
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.LIST.HEADER.PRICE" />,
                                    accessor: "periodPrices",
                                    Cell: ({ row }) => {
                                        const dayOfWeek = row.original.dayOfWeek;
                                        const periods = row.original.periods;
                                        if (periods) {
                                            return periods.map((period) => {
                                                let priceText = `${period.price}`;
                                                if (period.pricePerUnit === PRICE_PER_UNIT_MINUTE) {
                                                    priceText += `/${intl
                                                        .formatMessage({
                                                            id: "SERVICE.PAYMENT.PRICE_PER_UNIT.MINUTE",
                                                        })
                                                        .toLocaleLowerCase()}`;
                                                } else if (period.pricePerUnit === PRICE_PER_UNIT_HOUR) {
                                                    priceText += `/${intl
                                                        .formatMessage({
                                                            id: "SERVICE.PAYMENT.PRICE_PER_UNIT.HOUR",
                                                        })
                                                        .toLocaleLowerCase()}`;
                                                }
                                                return (
                                                    <div key={`${dayOfWeek}_period_${period.startTime}_${priceText}`}>
                                                        {TextCell(priceText)}
                                                    </div>
                                                );
                                            });
                                        }

                                        return TextCell();
                                    },
                                },
                            ],
                            [intl]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                        showFooter={false}
                    />
                </Card.Body>
            </div>
            {showAddModal && (
                <PricingTemplateTimeRulesAdjustModal
                    show={showAddModal ? true : false}
                    onCloseClicked={() => setShowAddModal(false)}
                    onSaveClicked={(timeRules) => {
                        updatePricingTemplateTimeRules(pricingTemplateId, timeRules, () => {
                            setShowAddModal(false);
                        });
                    }}
                />
            )}
        </Card>
    );
}
export default connect(null, { ...pricingTemplateActions, ...errorActions })(PricingTemplateTimeRules);
