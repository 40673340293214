import React, { useMemo, useCallback } from "react";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { LinkCell, DateTimeCell, TextCell, ChipCell, AvatarCell } from "../../components/tables/MaterialTableCells";
import * as bookingUtils from "../bookings/bookings/utils";
import { Link } from "react-router-dom";

function ResourcePageBookings({ getResourceBookings }) {
    const { resource, resourceBookingsPagination, isLoading } = useSelector((state) => state.resources);
    const { displayError } = useSelector((state) => state.errors);
    const resourceId = resource?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (resourceId) {
                getResourceBookings(resourceId, pageIndex + 1, pageSize);
            }
        },
        [resourceId, getResourceBookings]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.BOOKINGS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.BOOKINGS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={resourceId?.id}
                        data={useMemo(() => resourceBookingsPagination.data, [resourceBookingsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={resourceBookingsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/bookings/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.CUSTOMER" />,
                                    accessor: "customer",
                                    Cell: ({ row }) => {
                                        return (
                                            <Link to={`/customers/${row.original.customer.id}`}>
                                                {AvatarCell(
                                                    row.original.customer.avatarUrl,
                                                    `${row.original.customer.firstName} ${row.original.customer.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.SERVICE" />,
                                    accessor: "service",
                                    Cell: ({ row }) => {
                                        return TextCell(row.original.service.name);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.PRICE" />,
                                    accessor: "price",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === bookingUtils.BOOKING_STATUS_DELETED) {
                                            return ChipCell("error", <FormattedMessage id="BOOKING.STATUS.DELETED" />);
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_CANCELLED) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="BOOKING.STATUS.CANCELLED" />
                                            );
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_UNCONFIRMED) {
                                            return ChipCell(
                                                "warning",
                                                <FormattedMessage id="BOOKING.STATUS.UNCONFIRMED" />
                                            );
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_UNPAID) {
                                            return ChipCell("warning", <FormattedMessage id="BOOKING.STATUS.UNPAID" />);
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_CONFIRMED) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="BOOKING.STATUS.CONFIRMED" />
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...resourceActions, ...errorActions })(ResourcePageBookings);
