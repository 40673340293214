import React, { useCallback, useMemo } from "react";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import {
    AvatarCell,
    TranslationCell,
    DateTimeCell,
    DeleteActionButton,
    TextCell,
} from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import * as resourceUtils from "./utils";
import SelectMultipleResourcesModal from "./SelectMultipleResourcesModal";

function ResourceGroupPageResources({
    getResourceGroupResources,
    addResourceGroupResources,
    deleteResourceGroupResource,
    setShowModalAddResourceGroupResource,
}) {
    const {
        resourceGroup,
        groupResourcesListPagination,
        showModalAddResourceGroupResource,
        isLoading,
        isUpdating,
        error,
    } = useSelector((state) => state.resources);

    const intl = useIntl();

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (resourceGroup) {
                getResourceGroupResources(resourceGroup.id, pageIndex + 1, pageSize);
            }
        },
        [getResourceGroupResources, resourceGroup]
    );

    const onAddModalSaveClicked = (resourceIds) => {
        addResourceGroupResources(resourceGroup.id, resourceIds);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.GROUP.RESOURCES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.GROUP.RESOURCES.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            isLoading={isUpdating}
                            label={<FormattedMessage id="COMMON.ADD" />}
                            onClick={() => setShowModalAddResourceGroupResource(true)}
                        ></BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <MaterialReactTable
                        key={resourceGroup?.id}
                        data={useMemo(() => groupResourcesListPagination.data, [groupResourcesListPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={groupResourcesListPagination.totalRows}
                        isLoading={isLoading}
                        error={error}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="RESOURCE.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="RESOURCE.LIST.HEADER.NAME" />,
                                    accessor: "name",
                                    Cell: ({ row }) => {
                                        let resourceName;
                                        const { resource } = row.original;
                                        if (resourceUtils.isStaff(resource)) {
                                            resourceName = resource.firstName + " " + resource.surname;
                                        } else if (
                                            resourceUtils.isSpace(resource) ||
                                            resourceUtils.isArticle(resource)
                                        ) {
                                            resourceName = resource.name;
                                        }
                                        return (
                                            <Link to={`/resources/${resource.id}/${resource.type}`}>
                                                {AvatarCell(resource.avatarUrl, resourceName)}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="RESOURCE.LIST.HEADER.TYPE" />,
                                    accessor: "type",
                                    Cell: ({ row }) => {
                                        const { resource } = row.original;
                                        if (resourceUtils.isStaff(resource)) {
                                            return TextCell(
                                                resourceUtils.getPermissionName(resource?.permission?.id, intl)
                                            );
                                        } else if (resourceUtils.isSpace(resource)) {
                                            return TranslationCell("RESOURCE.TYPE.SPACE");
                                        } else if (resourceUtils.isArticle(resource)) {
                                            return TranslationCell("RESOURCE.TYPE.ARTICLE");
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="RESOURCE.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "delete",
                                    Cell: ({ row }) => {
                                        const { name, firstName, surname } = row.original.resource;

                                        const title = `${intl.formatMessage({
                                            id: "RESOURCE.GROUP.RESOURCES.DELETE.TITLE",
                                        })} ${name ? name : `${firstName} ${surname}`}`;

                                        const content = <FormattedMessage id="RESOURCE.GROUP.RESOURCES.DELETE.TEXT" />;
                                        return DeleteActionButton(title, content, () =>
                                            deleteResourceGroupResource(row.original.id)
                                        );
                                    },
                                },
                            ],
                            [intl, deleteResourceGroupResource]
                        )}
                    />
                </Card.Body>
            </div>
            {showModalAddResourceGroupResource && (
                <SelectMultipleResourcesModal
                    show={showModalAddResourceGroupResource}
                    onCloseClicked={() => setShowModalAddResourceGroupResource(false)}
                    onSaveClicked={onAddModalSaveClicked}
                    titleText={<FormattedMessage id={"RESOURCE.GROUP.RESOURCES.ADD.TITLE"} />}
                    descriptionText={<FormattedMessage id={"RESOURCE.GROUP.RESOURCES.ADD.DESCRIPTION"} />}
                    saveButtonText={<FormattedMessage id="COMMON.ADD" />}
                />
            )}
        </Card>
    );
}
export default connect(null, resourceActions)(ResourceGroupPageResources);
