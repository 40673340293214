import React, { useEffect } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import AppointmentBookingSettingsForm from "./AppointmentBookingSettingsForm";
import {
    APPOINTMENT_DURATION_TYPE_FIXED,
    APPOINTMENT_DURATION_TYPE_FLEXIBLE,
    APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION,
    APPOINTMENT_DURATION_TYPE_DAYS,
} from "../utils";
import moment from "moment";

const schema = yup.object().shape(
    {
        duration: yup.number().when("durationType", (durationType) => {
            if (durationType !== APPOINTMENT_DURATION_TYPE_FIXED) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(5, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 5 }} />);
            }
        }),
        extendedDuration: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
            .min(5, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 5 }} />),
        minDuration: yup.number().when(["durationType", "checkinTime", "checkoutTime"], {
            is: (durationType, checkinTime, checkoutTime) => {
                return durationType === APPOINTMENT_DURATION_TYPE_DAYS && (checkinTime || checkoutTime);
            },
            then: (schema) => {
                // Doesn't really makes sense to have checkin times with min 1 day so force min 2
                return yup
                    .number()
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
                    .min(2, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 2 }} />);
            },
            otherwise: (schema) => {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />);
            },
        }),
        maxDuration: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
            .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />),
        timePickerRangeIncrement: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
        checkinTime: yup.string().when("checkoutTime", (checkoutTime) => {
            if (!checkoutTime) {
                return yup.string();
            } else {
                return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
            }
        }),
        checkoutTime: yup.string().when("checkinTime", (checkinTime) => {
            if (!checkinTime) {
                return yup.string();
            } else {
                return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
            }
        }),
    },
    ["checkinTime", "checkoutTime"]
);

function AppointmentPageBookingSettings({ updateAppointment, getServiceOptions }) {
    const { profile } = useSelector((state) => state.auth);
    const { appointment, serviceOptions, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!serviceOptions) {
            getServiceOptions(profile.id);
        }
    }, [profile.id, serviceOptions, getServiceOptions]);

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalAppointment = { ...appointment };
        originalAppointment.categoryId = originalAppointment?.category?.id;
        originalAppointment.reminderTemplateId = originalAppointment?.reminderTemplate?.id || null;
        originalAppointment.cancellationTemplateId = originalAppointment?.cancellationTemplate?.id || null;

        let updatedAppointmentData = { ...appointment };
        updatedAppointmentData.durationType = values.durationType;
        updatedAppointmentData.reminderTemplateId = values?.reminderTemplate?.id;
        updatedAppointmentData.cancellationTemplateId = values?.cancellationTemplate?.id;
        updatedAppointmentData.noAvailabilityText = values.noAvailabilityText;

        const durationType = updatedAppointmentData.durationType;
        if (durationType === APPOINTMENT_DURATION_TYPE_FIXED) {
            updatedAppointmentData.duration = values.duration;
            updatedAppointmentData.extendedDuration = values.extendedDuration;
            updatedAppointmentData.isOvernightBookingsAllowed = values.isOvernightBookingsAllowed;
            updatedAppointmentData.isShowStartedSlot = values.isShowStartedSlot;
        } else if (durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE) {
            updatedAppointmentData.minDuration = values.minDuration;
            updatedAppointmentData.maxDuration = values.maxDuration;
            updatedAppointmentData.timePickerRangeIncrement = values.timePickerRangeIncrement;
            updatedAppointmentData.isShowStartedSlot = values.isShowStartedSlot;
        } else if (durationType === APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION) {
            updatedAppointmentData.fixedEndTime = values.fixedEndTime ? moment(values.fixedEndTime).format() : null;
        } else if (durationType === APPOINTMENT_DURATION_TYPE_DAYS) {
            updatedAppointmentData.minDuration = values.minDuration ? values.minDuration * 1440 : null;
            updatedAppointmentData.maxDuration = values.maxDuration ? values.maxDuration * 1440 : null;
            updatedAppointmentData.checkinTime = values.checkinTime;
            updatedAppointmentData.checkoutTime = values.checkoutTime;
        }

        updateAppointment(appointment.id, originalAppointment, updatedAppointmentData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {appointment && !appointment?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <AppointmentBookingSettingsForm
                                serviceOptions={serviceOptions}
                                service={appointment}
                                showResources={false}
                            />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(AppointmentPageBookingSettings);
