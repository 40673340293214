import React, { useEffect, useState } from "react";
import { actions as campaignActions } from "../../../redux/campaigns/campaignRedux";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";
import { isCampaignUpdateable } from "./utils";
import { Alert } from "@material-ui/lab";
import {
    CAMPAIGN_STATUS_DRAFT,
    CAMPAIGN_STATUS_SCHEDULED,
    CAMPAIGN_STATUS_STARTED,
    CAMPAIGN_STATUS_FINISHED,
    CAMPAIGN_STATUS_ERROR,
} from "./utils";

const schema = yup.object().shape({
    publishAt: yup
        .date()
        .transform((_, val) => (val ? new Date(val) : null))
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function CampaignPublishPage({ getCampaignPreview, publishCampaign }) {
    const { campaign, publishPreview, isUpdating } = useSelector((state) => state.campaigns);

    const [publishAtPickerOpen, setPublishAtPickerOpen] = useState(false);

    useEffect(() => {
        if (campaign && (campaign.status === CAMPAIGN_STATUS_DRAFT || campaign.status === CAMPAIGN_STATUS_SCHEDULED)) {
            getCampaignPreview(campaign.id);
        }
    }, [getCampaignPreview, campaign]);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { register, setValue, errors, handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        publishCampaign(campaign.id, values);
    };

    if (
        campaign?.status === CAMPAIGN_STATUS_STARTED ||
        campaign?.status === CAMPAIGN_STATUS_FINISHED ||
        campaign?.status === CAMPAIGN_STATUS_ERROR
    ) {
        return <Redirect to={`/campaigns/${campaign.id}/sms/recipients`} />;
    }

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CAMPAIGN.PUBLISH.TITLE" />
                        </h3>
                    </div>
                    {campaign && isCampaignUpdateable(campaign.status) && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CAMPAIGN.PUBLISH.PUBLISH_AT" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-3"
                                name="publishAt"
                                ref={register}
                                onClick={() => setPublishAtPickerOpen(true)}
                                isInvalid={errors.publishAt}
                                defaultValue={
                                    campaign?.publishAt ? moment(campaign.publishAt).format("YYYY-MM-DD HH:mm") : null
                                }
                            />
                            <Form.Control.Feedback type="invalid">{errors.publishAt?.message}</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage id="CAMPAIGN.PUBLISH.PUBLISH_AT_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                        {publishPreview && (
                            <Form.Group>
                                <Alert severity="info">
                                    <FormattedMessage
                                        id="CAMPAIGN.PUBLISH.ALERT"
                                        values={{
                                            p: (...chunks) => <p>{chunks}</p>,
                                            strong: (...chunks) => <strong>{chunks}</strong>,
                                            br: <br />,
                                            unitPrice: publishPreview.unitPrice,
                                            recipientCount: publishPreview.recipientCount,
                                            totalPrice: publishPreview.totalPrice,
                                            currency: publishPreview.currency,
                                        }}
                                    />
                                </Alert>
                            </Form.Group>
                        )}
                    </Form>
                </Card.Body>
            </div>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    showTodayButton
                    disablePast={true}
                    ampm={false}
                    style={{ display: "none" }}
                    open={publishAtPickerOpen}
                    onClose={() => setPublishAtPickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue(
                            "publishAt",
                            selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                            {
                                shouldValidate: true,
                            }
                        );
                    }}
                />
            </MuiPickersUtilsProvider>
        </Card>
    );
}
export default connect(null, campaignActions)(CampaignPublishPage);
