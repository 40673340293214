import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    formContent: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    serviceId: yup
        .number()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    deliveryMethod: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    signAuthMethod: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function ScriveDocumentServiceModal(props) {
    const { isLoading, managingTemplate, managingTemplateService } = useSelector((state) => state.scrive);
    const classes = useStyles();
    const intl = useIntl();

    let defaultValues;
    if (managingTemplateService) {
        defaultValues = {
            serviceId: managingTemplateService.service.id,
            deliveryMethod: managingTemplateService.deliveryMethod,
            signAuthMethod: managingTemplateService.signAuthMethod,
        };
    }

    const { register, handleSubmit } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    if (!managingTemplate) return <></>;

    const onFormSubmit = (values) => {
        props.onSaveClicked(managingTemplate, managingTemplateService, values);
    };

    const { services } = props;

    const deliveryMethods = [
        {
            id: "email",
            name: intl.formatMessage({ id: "INTEGRATION.SCRIVE.DELIVERY_METHOD.EMAIL" }),
        },
        {
            id: "mobile",
            name: intl.formatMessage({ id: "INTEGRATION.SCRIVE.DELIVERY_METHOD.MOBILE" }),
        },
        {
            id: "email_mobile",
            name: intl.formatMessage({ id: "INTEGRATION.SCRIVE.DELIVERY_METHOD.EMAIL_MOBILE" }),
        },
        {
            id: "api",
            name: intl.formatMessage({ id: "INTEGRATION.SCRIVE.DELIVERY_METHOD.API" }),
        },
    ];

    const signAuthMethods = [
        {
            id: "sms_pin",
            name: intl.formatMessage({ id: "INTEGRATION.SCRIVE.SIGH_AUTH_METHOD.SMS" }),
        },
        {
            id: "se_bankid",
            name: intl.formatMessage({ id: "INTEGRATION.SCRIVE.SIGH_AUTH_METHOD.BANKID" }),
        },
    ];

    return (
        <Form>
            <Modal size="lg" show={props.show} onHide={props.onCloseClicked}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {!managingTemplateService && (
                            <FormattedMessage
                                id={"INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.ADD"}
                                values={{ documentName: managingTemplate.title }}
                            />
                        )}
                        {managingTemplateService && (
                            <FormattedMessage
                                id={"INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.EDIT"}
                                values={{ serviceName: managingTemplateService.service.name }}
                            />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={"INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.DESCRIPTION"} />
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id={"INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.SERVICE"} />
                            </Form.Label>
                            <Form.Control as="select" ref={register} name="serviceId">
                                {services &&
                                    services.map((x) => {
                                        return (
                                            <option key={x.id} value={x.id}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id={"INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.DELIVERY_METHOD"} />
                            </Form.Label>
                            <Form.Control as="select" ref={register} name="deliveryMethod">
                                {deliveryMethods.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id={"INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.SIGH_AUTH_METHOD"} />
                            </Form.Label>
                            <Form.Control as="select" ref={register} name="signAuthMethod">
                                {signAuthMethods.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={props.onCloseClicked} />
                    <BootstrapSaveButton isLoading={isLoading} onClick={handleSubmit(onFormSubmit)} />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default ScriveDocumentServiceModal;
