import React, { useMemo, useCallback } from "react";
import { actions as parakeyActions } from "../../../../redux/integrations/parakey/parakeyRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell, AvatarCell, LinkCell } from "../../../components/tables/MaterialTableCells";
import { getBookingAccessStatusCell } from "../utils";

function ParakeyPageAccessKeys({ getParakeyBookingAccesses }) {
    const { integration, bookingAccessesPagination, isLoading } = useSelector((state) => state.parakey);
    const { displayError } = useSelector((state) => state.errors);
    const integrationId = integration?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (integrationId) {
                getParakeyBookingAccesses(integrationId, pageIndex + 1, pageSize);
            }
        },
        [integrationId, getParakeyBookingAccesses]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.DESCRIPTION" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={integrationId}
                        data={useMemo(() => bookingAccessesPagination.data, [bookingAccessesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={bookingAccessesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.ID" />
                                    ),
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.CUSTOMER" />
                                    ),
                                    accessor: "accessCustomer",
                                    maxWidth: 200,
                                    Cell: (props) => {
                                        const customer = props.value.customer;
                                        return (
                                            <Link to={`/customers/${customer.id}`}>
                                                {AvatarCell(
                                                    customer.avatarUrl,
                                                    `${customer.firstName} ${customer.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.BOOKING" />
                                    ),
                                    accessor: "booking",
                                    Cell: (props) => {
                                        return LinkCell("/bookings/" + props.value.id, props.value.id);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.START_TIME" />
                                    ),
                                    accessor: "accessStartTime",
                                    maxWidth: 140,
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.END_TIME" />
                                    ),
                                    maxWidth: 140,
                                    accessor: "accessEndTime",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.ACCESS_KEY_ID" />
                                    ),
                                    accessor: "externalId",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.ACCESS_ID" />
                                    ),
                                    accessor: "accessDevice",
                                    Cell: (props) => {
                                        return TextCell(props.value.externalId);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.STATUS" />
                                    ),
                                    accessor: "status",
                                    Cell: (props) => {
                                        return getBookingAccessStatusCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.CREATED_AT" />
                                    ),
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...parakeyActions, ...serviceActions, ...errorActions })(ParakeyPageAccessKeys);
