import React, { useMemo, useCallback } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import {
    LinkCell,
    DateTimeCell,
    DateCell,
    TextCell,
    TranslationCell,
} from "../../components/tables/MaterialTableCells";
import * as subscriptionUtils from "../subscriptions/utils";
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_INVOICE } from "../transactions/utils";

function CustomerPageSubscriptions({ getCustomerSubscriptions }) {
    const { customer, customerSubscriptionsPagination, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const customerId = customer?.id;

    const intl = useIntl();

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerId) {
                getCustomerSubscriptions(customerId, pageIndex + 1, pageSize);
            }
        },
        [customerId, getCustomerSubscriptions]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.SUBSCRIPTIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.SUBSCRIPTIONS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customer?.id}
                        data={useMemo(() => customerSubscriptionsPagination.data, [
                            customerSubscriptionsPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={customerSubscriptionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/subscriptions/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.AMOUNT" />,
                                    accessor: "amount",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.PAYMENT_TYPE" />,
                                    accessor: "paymentType",
                                    maxWidth: 100,
                                    Cell: (props) => {
                                        if (props.value === PAYMENT_TYPE_CARD) {
                                            return TranslationCell("PAYMENT.PAYMENT_TYPE.CARD");
                                        } else if (props.value === PAYMENT_TYPE_INVOICE) {
                                            return TranslationCell("PAYMENT.PAYMENT_TYPE.INVOICE");
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.RECURRING_UNIT" />,
                                    accessor: "recurringUnit",
                                    Cell: (props) => {
                                        return TextCell(subscriptionUtils.getRecurringUnitText(props.value, intl));
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.PAID_TRANSACTIONS" />,
                                    accessor: "paidTransactionsCount",
                                    maxWidth: 90,
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.NEXT_AT" />,
                                    accessor: "nextAt",
                                    maxWidth: 100,
                                    Cell: (props) => {
                                        return DateCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.LAST_AT" />,
                                    accessor: "lastAt",
                                    maxWidth: 100,
                                    Cell: (props) => {
                                        return DateCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SUBSCRIPTION.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        return subscriptionUtils.getSubscriptionStatusCell(props.value);
                                    },
                                },
                            ],
                            [intl]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}

export default connect(null, { ...customerActions, ...errorActions })(CustomerPageSubscriptions);
