import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { actions as serviceCategoriesActions } from "../../../redux/servicecategories/serviceCategoriesRedux";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function AddServiceCategoryModal({ profileId, show, onCloseClicked, onServiceCategoryAdded, addServiceCategory }) {
    const { isLoading } = useSelector((state) => state.serviceCategories);
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        addServiceCategory(profileId, { name: values.name }, (serviceCategory) => {
            onServiceCategoryAdded(serviceCategory);
        });
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id={"SERVICE.DETAILS.SERVICE_CATEGORY.ADD_MODAL.TITLE"} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.formContent}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE_CATEGORY.DETAILS.NAME" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control form-control-lg col-xl-6"
                            name="name"
                            ref={register}
                            isInvalid={errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton isLoading={isLoading} onClick={handleSubmit(onFormSubmit)} />
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, serviceCategoriesActions)(AddServiceCategoryModal);
