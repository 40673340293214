import React, { useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Button, Menu, MenuItem, Avatar, Typography, makeStyles, withStyles, Divider } from "@material-ui/core";
import { actions as authActions } from "../../../../redux/auth/authRedux";
import { useHistory } from "react-router-dom";
import SearchField from "../../../../app/components/SearchField";

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        textAlign: "center",
    },
    searchField: {
        width: "100%",
        padding: "8px",
    },
    profileItem: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
    },
    profileAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        margin: theme.spacing(0.3),
        marginRight: theme.spacing(1),
    },
}));

const StyledMenu = withStyles({
    paper: {
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    list: {
        padding: 0,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
));

function HeaderMobile({ getUserProfiles, searchUserProfiles, changeUserProfile }) {
    const { profile, profiles, profilesSearch } = useSelector((state) => state.auth);

    const [anchorElement, setAnchorElement] = useState(null);
    const [isSearching, setIsSearching] = useState(null);
    const classes = useStyles();
    const history = useHistory();
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            headerLogo: uiService.getStickyLogo(),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            headerMenuSelfDisplay: objectPath.get(uiService.config, "header.menu.self.display") === true,
            headerMobileCssClasses: uiService.getClasses("header_mobile", true),
            headerMobileAttributes: uiService.getAttributes("header_mobile"),
        };
    }, [uiService]);

    function onDropdownClicked(event) {
        getUserProfiles();
        if (profiles?.length > 1) {
            setAnchorElement(event.currentTarget);
        }
    }

    function onProfileClicked(profile) {
        changeUserProfile(profile);
        handleDropdownClose();
        history.push("/overview");
    }

    function handleDropdownClose() {
        setAnchorElement(null);
    }

    const onSearchChanged = useCallback(
        (text) => {
            searchUserProfiles(text);
            setIsSearching(text ? true : false);
        },
        [searchUserProfiles]
    );

    let profilesToShow = !isSearching ? profiles : profilesSearch;
    if (!profilesToShow) profilesToShow = [];

    return (
        <>
            {/*begin::Header Mobile*/}
            <div
                id="kt_header_mobile"
                className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
                {...layoutProps.headerMobileAttributes}
            >
                {/*begin::Logo*/}
                <Link to="/">
                    <img alt="logo" src={layoutProps.headerLogo} />
                </Link>
                {/*end::Logo*/}

                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center">
                    {layoutProps.asideDisplay && (
                        <>
                            {/*begin::Aside Mobile Toggle*/}
                            <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                                <span />
                            </button>
                            {/*end::Aside Mobile Toggle*/}
                        </>
                    )}

                    {layoutProps.headerMenuSelfDisplay && (
                        <>
                            {/*begin::Header Menu Mobile Toggle*/}

                            <Button onClick={onDropdownClicked}>
                                <Avatar alt="logo" src={profile.avatarUrl} className={classes.profileAvatar} />
                            </Button>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorElement}
                                keepMounted
                                open={anchorElement ? true : false}
                                onClose={handleDropdownClose}
                            >
                                <div>
                                    {profiles.length > 8 && (
                                        <div className={classes.searchContainer}>
                                            <SearchField
                                                onSearchChanged={onSearchChanged}
                                                textFieldStyles={classes.searchField}
                                                stopPropagation={true}
                                            />
                                        </div>
                                    )}
                                    {profilesToShow.map((profile, index) => (
                                        <div key={profile.id}>
                                            <MenuItem
                                                onClick={() => onProfileClicked(profile)}
                                                className={classes.profileItem}
                                            >
                                                <Avatar
                                                    alt="logo"
                                                    src={profile.avatarUrl}
                                                    className={classes.profileAvatar}
                                                />
                                                <Typography className="menu-text">{profile.name}</Typography>
                                            </MenuItem>
                                            {index < profilesToShow.length - 1 && <Divider />}
                                        </div>
                                    ))}
                                </div>
                            </StyledMenu>

                            {/*end::Header Menu Mobile Toggle*/}
                        </>
                    )}

                    {/*begin::Topbar Mobile Toggle*/}
                    <button className="btn btn-hover-text-primary p-0 " id="kt_header_mobile_topbar_toggle">
                        <span className="svg-icon svg-icon-xl">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                        </span>
                    </button>
                    {/*end::Topbar Mobile Toggle*/}
                </div>
                {/*end::Toolbar*/}
            </div>
            {/*end::Header Mobile*/}
        </>
    );
}

export default connect(null, authActions)(HeaderMobile);
