import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function CourseAttendeeStatusFilter({ onStatusChanged }) {
    const classes = useStyles();
    const [status, setStatus] = useState("attending");

    const handleChange = ({ target: { value } }) => {
        setStatus(value);
        onStatusChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">
                <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.FILTER.STATUS" />
            </InputLabel>
            <Select labelId="status-filter-label" id="status-filter" value={status} onChange={handleChange}>
                <MenuItem value={"all"}>
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={"attending"}>
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.FILTER.STATUS.ATTENDING" />
                </MenuItem>
                <MenuItem value={"cancelled"}>
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.FILTER.STATUS.CANCELLED" />
                </MenuItem>
                <MenuItem value={"deleted"}>
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.ATTENDEES.FILTER.STATUS.DELETED" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default CourseAttendeeStatusFilter;
