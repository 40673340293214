import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const SERVICE_CATEGORIES_URL = "v1/admin/profiles/:id/service-categories";
export const SERVICE_CATEGORY_DETAILS_URL = "v1/admin/service-categories/:id";
export const SERVICE_CATEGORY_SERVICES_URL = "v1/admin/service-categories/:id/services";

export function getServiceCategories(profileId, page, perPage, search, status) {
    const url =
        SERVICE_CATEGORIES_URL.replace(":id", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        `${search ? `&search=${search}` : ""}` +
        `${status ? `&status=${status}` : ""}`;
    return axios.get(getApiUrl() + url);
}

export function getServiceCategory(serviceCategoryId) {
    const url = SERVICE_CATEGORY_DETAILS_URL.replace(":id", serviceCategoryId);
    return axios.get(getApiUrl() + url);
}

export function addServiceCategory(profileId, serviceCategory) {
    const url = SERVICE_CATEGORIES_URL.replace(":id", profileId);
    return axios.post(getApiUrl() + url, serviceCategory);
}

export function patchServiceCategory(id, originalServiceCategory, changedServiceCategory) {
    const url = SERVICE_CATEGORY_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalServiceCategory, changedServiceCategory), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteServiceCategory(serviceCategoryId) {
    const url = SERVICE_CATEGORY_DETAILS_URL.replace(":id", serviceCategoryId);
    return axios.delete(getApiUrl() + url);
}

export function getServiceCategoryServices(serviceCategoryId, page, perPage) {
    const url =
        SERVICE_CATEGORY_SERVICES_URL.replace(":id", serviceCategoryId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}
