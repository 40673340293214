import React, { useEffect, useState } from "react";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { getColorSettingsOptions, RESOURCE_COLOR_MODE_OWN } from "./utils";
import { getRGBACssFromARGBHex } from "../../utils/ColorUtils";
import { SERVICE_COLOR_DEFAULT } from "../services/utils";
import SelectColorModal from "../../components/SelectColorModal";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    stockBalance: yup
        .number()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    description: yup.string(),
});

function ResourcePageDetailsArticle({ updateResource }) {
    const intl = useIntl();
    const { resource, isUpdating } = useSelector((state) => state.resources);
    const { register, handleSubmit, errors, control, setValue, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        if (resource) {
            if (resource.color) {
                setValue("colorMode", RESOURCE_COLOR_MODE_OWN);
                setValue("color", resource.color);
            }
        }
    }, [resource, setValue]);

    const onFormSubmit = (values) => {
        let data = values;
        data.color = data.colorMode === RESOURCE_COLOR_MODE_OWN ? values.color : null;

        delete data.colorMode;
        updateResource(resource.type, resource.id, data);
    };

    const colorMode = watch("colorMode");
    const color = watch("color");
    if (!color) {
        register("color");
        setValue("color", SERVICE_COLOR_DEFAULT);
    }

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                            <FormattedMessage id="COMMON.SAVE" />
                        </BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.NAME" />
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        name="name"
                                        ref={register}
                                        isInvalid={errors.name}
                                        defaultValue={resource?.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.STOCK_BALANCE" />
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="number"
                                        className="form-control-lg"
                                        name="stockBalance"
                                        ref={register}
                                        isInvalid={errors.stockBalance}
                                        defaultValue={resource?.stockBalance}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.stockBalance?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.DESCRIPTION" />
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <div>
                                    <Form.Control
                                        as="textarea"
                                        rows={8}
                                        className="form-control-lg"
                                        name="description"
                                        ref={register}
                                        isInvalid={errors.description}
                                        defaultValue={resource?.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description?.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="RESOURCE.DETAILS.COLOR" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <Controller
                                    name="colorMode"
                                    control={control}
                                    defaultValue={resource?.permission?.id || 0}
                                    render={({ onChange, value }) => {
                                        return (
                                            <Form.Control
                                                as="select"
                                                value={value}
                                                onChange={(val) => onChange(val.target.value)}
                                            >
                                                {getColorSettingsOptions(intl).map((x) => {
                                                    return (
                                                        <option key={x.id} value={x.id}>
                                                            {x.name}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        );
                                    }}
                                />
                            </div>
                            {colorMode === RESOURCE_COLOR_MODE_OWN && (
                                <div
                                    style={{
                                        backgroundColor: `${getRGBACssFromARGBHex(color)}`,
                                        width: 100,
                                        height: 44,
                                        borderRadius: "0.42rem",
                                    }}
                                    onClick={() => setShowColorPicker(true)}
                                ></div>
                            )}
                        </div>
                    </Form>
                </Card.Body>
            </div>
            {showColorPicker && (
                <SelectColorModal
                    show={showColorPicker}
                    onSaveClicked={(colorARBGHex) => {
                        setShowColorPicker(false);
                        setValue("color", colorARBGHex);
                    }}
                    onCloseClicked={() => setShowColorPicker(false)}
                    defaultColorHex={color}
                />
            )}
        </Card>
    );
}
export default connect(null, resourceActions)(ResourcePageDetailsArticle);
