import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as customerOfferActions } from "../../../../redux/customeroffers/customerOffersRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import MembershipPageNavigator from "./MembershipPageNavigator";
import MembershipPageDetails from "./MembershipPageDetails";
import MembershipPageTransactions from "./MembershipPageTransactions";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function MembershipPage({ match, getMembership, clearCustomerOffersState, clearDisplayError, clearErrorState }) {
    const customerOfferId = parseInt(match.params.id, 10);
    const { membership, isLoading, isUpdating } = useSelector((state) => state.customerOffers);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!membership || membership.id !== customerOfferId)) {
            getMembership(customerOfferId);
        }
    }, [getMembership, membership, customerOfferId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearCustomerOffersState();
            clearErrorState();
        };
    }, [clearCustomerOffersState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <MembershipPageNavigator customerOfferId={customerOfferId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/memberships/${customerOfferId}`}
                            exact={true}
                            to={`/memberships/${customerOfferId}/details`}
                        />
                        <Route path={`/memberships/${customerOfferId}/details`} component={MembershipPageDetails} />

                        <Route
                            path={`/memberships/${customerOfferId}/transactions`}
                            component={MembershipPageTransactions}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...customerOfferActions, ...errorActions })(MembershipPage);
