import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as customerOfferActions } from "../../../../redux/customeroffers/customerOffersRedux";
import { Card, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(2),
        alignItems: "center",
    },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
        <span
            ref={ref}
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </span>
    );
});

function MembershipPageNavigator({ customerOfferId, deleteMembership }) {
    const { membership } = useSelector((state) => state.customerOffers);
    const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();

    const onRemoveMembershipClicked = (e) => {
        e.preventDefault();
        setMenuDropdownOpen(true);
        setShowDeleteConfirmation(true);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            deleteMembership(customerOfferId);
        }
        setShowDeleteConfirmation(false);
    };

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        <Dropdown
                            className="dropdown dropdown-inline"
                            alignRight
                            show={isMenuDropdownOpen}
                            onToggle={() => setMenuDropdownOpen(!isMenuDropdownOpen)}
                        >
                            <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                variant="transparent"
                                id="dropdown-toggle-top-user-profile"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-hor"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <ul className="navi navi-hover py-5">
                                    {membership && !membership.deletedAt && (
                                        <li className="navi-item" onClick={onRemoveMembershipClicked}>
                                            <a href="/" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-trash"></i>
                                                </span>
                                                <span className="navi-text">
                                                    <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DELETE" />
                                                </span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        <span className="font-weight-bolder font-size-h6 text-dark-75">
                            <FormattedMessage
                                id="CUSTOMEROFFER.MEMBERSHIP.DETAILS.TITLE_NAVIGATOR"
                                values={{ id: customerOfferId }}
                            />
                        </span>
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DETAILS.CREATED_AT" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {moment(membership?.createdAt).format("YYYY-MM-DD HH:mm")}
                            </span>
                        </div>
                        {membership?.customer && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DETAILS.CUSTOMER" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/customers/${membership.customer.id}`}>
                                        {`${membership.customer.firstName} ${membership.customer.surname}`}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {membership?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DETAILS.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(membership.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {membership?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DETAILS.DELETED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${membership.deletedBy.firstName} ${membership.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/memberships/${customerOfferId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/memberships/${customerOfferId}/transactions`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.TRANSACTIONS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="CUSTOMEROFFER.MEMBERSHIP.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={onDeleteDialogActionClicked}
            />
        </div>
    );
}

export default connect(null, customerOfferActions)(MembershipPageNavigator);
