import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as entriesActions } from "../../../../redux/entries/entriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import CoursePageNavigator from "./CoursePageNavigator";
import CoursePageDetails from "./CoursePageDetails";
import CoursePageBookingSettings from "./CoursePageBookingSettings";
import CoursePageCheckoutSettings from "./CoursePageCheckoutSettings";
import ServicePageAddons from "../ServicePageAddons";
import CoursePageResources from "./CoursePageResources";
import CoursePageOccasions from "./CoursePageOccasions";
import CoursePageAttendees from "./CoursePageAttendees";
import CoursePageWaitingList from "./CoursePageWaitingList";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles, Snackbar, useTheme, useMediaQuery } from "@material-ui/core";
import ContentDrawer from "../../../components/drawer/ContentDrawer";
import * as drawerUtils from "../../../components/drawer/utils";
import * as entryUtils from "../../bookings/entryUtils";
import DrawerCourseOccasion from "../../bookings/drawer/DrawerCourseOccasion";
import MuiAlert from "@material-ui/lab/Alert";

const drawerWidth = drawerUtils.getDrawerDefaultWidth();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    content: {
        flexGrow: 1,
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function CoursePage({ match, getCourse, clearServiceState, getCourseOccasions, clearDisplayError, clearErrorState }) {
    const serviceId = parseInt(match.params.id, 10);
    const { course, isLoading, isUpdating } = useSelector((state) => state.services);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerEntry, setDrawerEntry] = useState(null);
    const [showMessageError, setShowMessageError] = useState(false);
    const [messageError, setMessageError] = useState(null);

    const contentContainerRef = useRef(null);

    // Determine if small screen for the sake of drawer behaviour (push main content or go above)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (!isLoading && !apiError && (!course || course.id !== serviceId)) {
            getCourse(serviceId);
        }
    }, [getCourse, course, serviceId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    const toggleDrawer = (open) => {
        setIsDrawerOpen(open);
    };

    const showDrawerEntry = (entry) => {
        setDrawerEntry(entry);
        setIsDrawerOpen(true);
    };

    const onDrawerEntryUpdated = () => {
        getCourseOccasions(serviceId, 1, 10, "upcoming");
    };

    const onDrawerEntryDeleted = () => {
        getCourseOccasions(serviceId, 1, 10, "upcoming");
    };

    const showErrorMessage = (message) => {
        setMessageError(message);
        setShowMessageError(true);
    };

    const onErrorMessageClosed = () => {
        setShowMessageError(false);
    };

    return (
        <div className={classes.root} ref={contentContainerRef}>
            <PageBackdrop isLoading={isLoading || isUpdating}>
                <Snackbar
                    open={showMessageError}
                    onClose={onErrorMessageClosed}
                    autoHideDuration={4000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MuiAlert elevation={6} variant="filled" severity="error" onClose={onErrorMessageClosed}>
                        {messageError}
                    </MuiAlert>
                </Snackbar>
                <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
                <div
                    className={classes.content}
                    style={
                        isSmallScreen
                            ? {}
                            : drawerUtils.getDrawerOffsetsStyle(
                                  drawerWidth,
                                  isDrawerOpen,
                                  contentContainerRef,
                                  document.getElementsByClassName("container")[0]
                              )
                    }
                >
                    <CoursePageNavigator serviceId={serviceId} />
                    <div className="flex-row-fluid ml-lg-8">
                        <Switch>
                            <Redirect
                                from={`/services/${serviceId}/group-service`}
                                exact={true}
                                to={`/services/${serviceId}/group-service/details`}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/details`}
                                component={CoursePageDetails}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/booking-settings`}
                                component={CoursePageBookingSettings}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/checkout-settings`}
                                component={CoursePageCheckoutSettings}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/addons`}
                                render={(props) => <ServicePageAddons {...props} service={course} />}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/resources`}
                                component={CoursePageResources}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/occasions`}
                                render={(props) => <CoursePageOccasions {...props} showDrawerEntry={showDrawerEntry} />}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/attendees`}
                                component={CoursePageAttendees}
                            />
                            <Route
                                path={`/services/${serviceId}/group-service/waiting-list`}
                                component={CoursePageWaitingList}
                            />
                        </Switch>
                    </div>
                </div>
            </PageBackdrop>

            <ContentDrawer
                isOpen={isDrawerOpen}
                onDrawerToggle={toggleDrawer}
                width={drawerWidth}
                style={{
                    visibility: "hidden" /* Strange bug fix to prevent new booking button not being clickable */,
                }}
                paperProps={{ style: { backgroundColor: "#FAFAFA", visibility: "visible" } }}
            >
                {drawerEntry && entryUtils.isCourseOccasionEntry(drawerEntry) && (
                    <DrawerCourseOccasion
                        key={drawerEntry.id}
                        onDrawerToggle={toggleDrawer}
                        showErrorMessage={showErrorMessage}
                        onDrawerEntryUpdated={onDrawerEntryUpdated}
                        onDrawerEntryDeleted={onDrawerEntryDeleted}
                    />
                )}
            </ContentDrawer>
        </div>
    );
}

export default connect(null, {
    ...serviceActions,
    ...entriesActions,
    ...errorActions,
})(CoursePage);
