import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, Avatar, Typography } from "@material-ui/core";
import { actions as bookingManagerActions } from "../../../redux/bookings/bookingManagerRedux";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Button, Col } from "react-bootstrap";
import DrawerAddBookingHeader from "./DrawerAddBookingHeader";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FormattedMessage, useIntl } from "react-intl";
import {
    SERVICE_TYPE_APPOINTMENT,
    APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION,
    SERVICE_TYPE_COURSE,
    SERVICE_TYPE_VOUCHER,
    SERVICE_TYPE_MEMBERSHIP,
} from "../services/utils";
import AddCustomerModal from "./AddCustomerModal";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarSuccess from "../../components/SnackbarSuccess";
import SnackbarError from "../../components/SnackbarError";
import DrawerAddBookingAppointment from "./DrawerAddBookingAppointment";
import DrawerAddBookingCourse from "./DrawerAddBookingCourse";
import DrawerAddBookingOffer from "./DrawerAddBookingOffer";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#FAFAFA",
        flexDirection: "column",
    },
    detailsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
        padding: theme.spacing(2),
    },
    listItemAvatarContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemLabelContainer: {
        display: "flex",
        flexDirection: "column",
    },
    listItemAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
    confirmButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
    },
}));

function getCustomerOptions(customerPagination) {
    let options = [];
    if (!customerPagination?.data?.length) return options;
    customerPagination.data.forEach((customer) => {
        options.push(getCustomerOption(customer));
    });

    return options;
}

function getCustomerOption(customer) {
    return {
        id: customer.id,
        name: `${customer.firstName} ${customer.surname}`,
        avatarUrl: customer.avatarUrl,
        email: customer.email,
    };
}

function getServiceOptions(servicePagination) {
    let options = [];
    if (!servicePagination?.data?.length) return options;
    servicePagination.data.forEach((service) => {
        var serviceOption = {
            id: service.id,
            name: service.name,
            type: service.type,
            paymentMode: service.paymentMode,
            duration: service.duration,
            durationType: service.durationType,
            fixedEndTime: service.fixedEndTime,
            checkinTime: service.checkinTime,
            checkoutTime: service.checkoutTime,
        };

        if (service.type === SERVICE_TYPE_COURSE && service.startTime) {
            serviceOption.name = `${serviceOption.name} (${moment(service.startTime).format("YYYY-MM-DD")})`;
            serviceOption.isBookPerOccasion = service.isBookPerOccasion;
        }

        options.push(serviceOption);
    });
    return options;
}

function DrawerAddBooking({
    onDrawerToggle,
    getNewBookingCustomers,
    getNewBookingServices,
    addCustomer,
    addNewBooking,
    onNewBookingServiceUnselected,
    clearBookingManagerState,
    onBookingAdded,
    clearDisplayError,
}) {
    const classes = useStyles();
    const intl = useIntl();

    const { profile } = useSelector((state) => state.auth);
    const {
        newBookingCustomersPagination,
        newBookingCustomersLoading,
        newBookingServicesPagination,
        newBookingServicesLoading,
        isDrawerLoading,
    } = useSelector((state) => state.bookingManager);
    const { displayError } = useSelector((state) => state.errors);

    const [showAddCustomer, setShowAddCustomer] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [notifyUser, setNotifyUser] = useState(true);
    const [isMarkedAsPaid, setIsMarkedAsPaid] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    // Appointments
    const [selectedResource, setSelectedResource] = useState(null);
    const [addDefaultResourceIfPossible, setAddDefaultResourceIfPossible] = useState(false);
    const [pickedStartTime, setPickedStartTime] = useState(null);
    const [pickedEndTime, setPickedEndTime] = useState(null);
    const [pickedRepeatUntilTime, setPickedRepeatUntilTime] = useState(null);
    const [isShowUntilCancellationEndTime, setIsShowUntilCancellationEndTime] = useState(null);

    // Courses
    const [selectedCourseOccasions, setSelectedCourseOccasions] = useState([]);

    useEffect(() => {
        if (!newBookingCustomersPagination) {
            getNewBookingCustomers(profile.id);
        }
    }, [profile.id, getNewBookingCustomers, newBookingCustomersPagination]);

    useEffect(() => {
        if (!newBookingServicesPagination) {
            getNewBookingServices(profile.id);
        }
    }, [profile.id, getNewBookingServices, newBookingServicesPagination]);

    const onCustomerSearch = (search) => {
        getNewBookingCustomers(profile.id, search);
    };

    const onCustomerChanged = (customers) => {
        if (customers && customers.length > 0) {
            setSelectedCustomer(customers[0]);
        } else {
            setSelectedCustomer(null);
        }
    };

    const onAddCustomerSavedClicked = (values) => {
        addCustomer(profile.id, values, (customer) => {
            setShowAddCustomer(false);
            setSelectedCustomer(getCustomerOption(customer));
        });
    };

    const onServiceSearch = (search) => {
        getNewBookingServices(profile.id, search);
    };

    const onServiceChanged = (services) => {
        if (services && services.length > 0) {
            const service = services[0];
            setSelectedService(service);
            if (service.type === SERVICE_TYPE_APPOINTMENT) {
                setIsShowUntilCancellationEndTime(
                    service.durationType === APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION && !service.fixedEndTime
                );
                setSelectedResource(null);
                setAddDefaultResourceIfPossible(true);
            }
        } else {
            setSelectedService(null);
            setSelectedResource(null);
            onNewBookingServiceUnselected();
        }
    };

    const isReadyToConfirm = () => {
        if (!selectedCustomer || !selectedService) {
            return false;
        }

        if (selectedService.type === SERVICE_TYPE_APPOINTMENT) {
            if (!selectedResource || !pickedStartTime) {
                return false;
            }

            if (!isShowUntilCancellationEndTime && !pickedEndTime) {
                return false;
            }
        } else if (selectedService.type === SERVICE_TYPE_COURSE) {
            if (selectedService.isBookPerOccasion && selectedCourseOccasions.length === 0) {
                return false;
            }
        }

        return true;
    };

    const onConfirmBookingClicked = () => {
        var data = {
            customerId: selectedCustomer.id,
            serviceId: selectedService.id,
            notifyUser,
            isMarkedAsPaid,
        };

        if (selectedAddons.length > 0) {
            data.addons = [];
            selectedAddons.forEach((addon) => {
                data.addons.push({
                    id: addon.id,
                    quantity: addon.quantity,
                });
            });
        }

        if (selectedService.type === SERVICE_TYPE_APPOINTMENT) {
            data.resourceId = selectedResource.id;
            data.startTime = pickedStartTime.format();

            if (!isShowUntilCancellationEndTime) {
                data.endTime = pickedEndTime.format();
            }

            if (pickedRepeatUntilTime) {
                const sameTimeAsEndTime = pickedRepeatUntilTime.set({
                    h: pickedEndTime.hour(),
                    m: pickedEndTime.minute(),
                });
                data.repeatUntil = sameTimeAsEndTime.format();
            }
        } else if (selectedService.type === SERVICE_TYPE_COURSE) {
            if (selectedService.isBookPerOccasion) {
                data.entryIds = [];
                selectedCourseOccasions.forEach((occasion) => {
                    data.entryIds.push(occasion.id);
                });
            }
        }

        addNewBooking(profile.id, data, () => {
            setSuccessMessage(
                intl.formatMessage({
                    id: "BOOKING.ADD.CONFIRM_SUCCESS",
                })
            );
            clearData();
            if (onBookingAdded) {
                onBookingAdded();
            }
        });
    };

    const clearData = () => {
        clearBookingManagerState();
        setSelectedCustomer(null);
        setSelectedService(null);
        setSelectedResource(null);
        setPickedRepeatUntilTime(null);
        setSelectedAddons([]);
        setSelectedCourseOccasions([]);
        setNotifyUser(true);
        setIsMarkedAsPaid(false);
    };

    return (
        <div className={classes.drawerContainer}>
            <DrawerAddBookingHeader onDrawerToggle={onDrawerToggle} />

            <div className={classes.detailsContainer}>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="BOOKING.ADD.CUSTOMER.TITLE" />
                    </Form.Label>
                    <Form.Row>
                        <Col xs={8} sm={9}>
                            <AsyncTypeahead
                                id="typeahead-customers"
                                labelKey={"name"}
                                minLength={0}
                                clearButton={true}
                                onChange={onCustomerChanged}
                                onSearch={onCustomerSearch}
                                onInputChange={(input) => {
                                    if (!input) {
                                        onCustomerSearch(input);
                                    }
                                }}
                                useCache={false}
                                isLoading={newBookingCustomersLoading}
                                options={getCustomerOptions(newBookingCustomersPagination)}
                                placeholder={intl.formatMessage({
                                    id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                })}
                                emptyLabel={intl.formatMessage({
                                    id: "BOOKING.ADD.CUSTOMER.SEARCH_EMPTY",
                                })}
                                renderMenuItemChildren={(option, props) => (
                                    <div className={classes.listItemAvatarContainer}>
                                        <Avatar
                                            alt={option.name}
                                            src={option.avatarUrl}
                                            className={classes.listItemAvatar}
                                        />
                                        <div className={classes.listItemLabelContainer}>
                                            <Typography variant="body1">{option.name}</Typography>
                                            <Typography variant="body2">{option.email}</Typography>
                                        </div>
                                    </div>
                                )}
                                selected={selectedCustomer ? [selectedCustomer] : []}
                                filterBy={() => true}
                            />
                        </Col>
                        <Col xs={4} sm={3}>
                            <Button variant="outline-primary" onClick={() => setShowAddCustomer(true)}>
                                <FormattedMessage id="BOOKING.ADD.CUSTOMER.BUTTON_NEW" />
                            </Button>
                        </Col>
                    </Form.Row>

                    <AddCustomerModal
                        show={showAddCustomer}
                        onCloseClicked={() => setShowAddCustomer(false)}
                        onSaveClicked={(values) => onAddCustomerSavedClicked(values)}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="BOOKING.ADD.SERVICE.TITLE" />
                    </Form.Label>
                    <AsyncTypeahead
                        id="typeahead-services"
                        labelKey={"name"}
                        minLength={0}
                        clearButton={true}
                        onChange={onServiceChanged}
                        onSearch={onServiceSearch}
                        onInputChange={(input) => {
                            if (!input) {
                                onServiceSearch(input);
                            }
                        }}
                        useCache={false}
                        isLoading={newBookingServicesLoading}
                        options={getServiceOptions(newBookingServicesPagination)}
                        placeholder={intl.formatMessage({ id: "COMMON.DROPDOWN.TYPE_TO_SEARCH" })}
                        emptyLabel={intl.formatMessage({ id: "BOOKING.ADD.SERVICE.SEARCH_EMPTY" })}
                        selected={selectedService ? [selectedService] : []}
                        filterBy={() => true}
                    />
                </Form.Group>

                {selectedService?.type === SERVICE_TYPE_APPOINTMENT && (
                    <DrawerAddBookingAppointment
                        selectedService={selectedService}
                        selectedResource={selectedResource}
                        setSelectedResource={setSelectedResource}
                        pickedStartTime={pickedStartTime}
                        setPickedStartTime={setPickedStartTime}
                        pickedEndTime={pickedEndTime}
                        setPickedEndTime={setPickedEndTime}
                        pickedRepeatUntilTime={pickedRepeatUntilTime}
                        setPickedRepeatUntilTime={setPickedRepeatUntilTime}
                        isShowUntilCancellationEndTime={isShowUntilCancellationEndTime}
                        setIsShowUntilCancellationEndTime={setIsShowUntilCancellationEndTime}
                        addDefaultResourceIfPossible={addDefaultResourceIfPossible}
                        setAddDefaultResourceIfPossible={setAddDefaultResourceIfPossible}
                        selectedAddons={selectedAddons}
                        setSelectedAddons={setSelectedAddons}
                        notifyUser={notifyUser}
                        setNotifyUser={setNotifyUser}
                        isMarkedAsPaid={isMarkedAsPaid}
                        setIsMarkedAsPaid={setIsMarkedAsPaid}
                    />
                )}

                {selectedService?.type === SERVICE_TYPE_COURSE && (
                    <DrawerAddBookingCourse
                        selectedService={selectedService}
                        notifyUser={notifyUser}
                        setNotifyUser={setNotifyUser}
                        isMarkedAsPaid={isMarkedAsPaid}
                        setIsMarkedAsPaid={setIsMarkedAsPaid}
                        selectedCourseOccasions={selectedCourseOccasions}
                        setSelectedCourseOccasions={setSelectedCourseOccasions}
                    />
                )}

                {(selectedService?.type === SERVICE_TYPE_VOUCHER ||
                    selectedService?.type === SERVICE_TYPE_MEMBERSHIP) && (
                    <DrawerAddBookingOffer
                        selectedService={selectedService}
                        notifyUser={notifyUser}
                        setNotifyUser={setNotifyUser}
                        isMarkedAsPaid={isMarkedAsPaid}
                        setIsMarkedAsPaid={setIsMarkedAsPaid}
                    />
                )}

                {isReadyToConfirm() && (
                    <Form.Group className={classes.confirmButtonContainer}>
                        <BootstrapSaveButton
                            variant="outline-primary"
                            size="lg"
                            onClick={() => onConfirmBookingClicked()}
                            isLoading={isDrawerLoading}
                            label={<FormattedMessage id="BOOKING.ADD.CONFIRM_BUTTON" />}
                        />
                    </Form.Group>
                )}
            </div>

            <SnackbarSuccess
                message={successMessage}
                onSnackBarClosed={() => {
                    setSuccessMessage(null);
                }}
            ></SnackbarSuccess>

            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
        </div>
    );
}

export default injectIntl(
    connect(null, { ...bookingManagerActions, ...customerActions, ...errorActions })(DrawerAddBooking)
);
