import React, { useMemo, useCallback } from "react";
import { actions as campaignActions } from "../../../../redux/campaigns/campaignRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, AvatarCell, DateTimeCell, ChipCell } from "../../../components/tables/MaterialTableCells";
import {
    CAMPAIGN_RECIPIENT_STATUS_PENDING,
    CAMPAIGN_RECIPIENT_STATUS_SUCCESS,
    CAMPAIGN_RECIPIENT_STATUS_ERROR,
} from "../utils";

function CampaignSMSPageRecipients({ getCampaignRecipients }) {
    const { campaign, recipientsPagination, isRecipientsLoading } = useSelector((state) => state.campaigns);
    const { displayError } = useSelector((state) => state.errors);
    const campaignId = campaign?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (campaignId) {
                getCampaignRecipients(campaignId, pageIndex + 1, pageSize);
            }
        },
        [campaignId, getCampaignRecipients]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CAMPAIGN.RECIPIENTS.HEADER" />
                        </h3>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={campaignId}
                        data={useMemo(() => recipientsPagination.data, [recipientsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={recipientsPagination.totalRows}
                        isLoading={isRecipientsLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS.LIST.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS.LIST.NAME" />,
                                    accessor: "name",
                                    Cell: ({ row }) => {
                                        return (
                                            <Link to={`/customers/${row.original.customer.id}`}>
                                                {AvatarCell(
                                                    row.original.customer.avatarUrl,
                                                    `${row.original.customer.firstName} ${row.original.customer.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS.LIST.SENT_AT" />,
                                    accessor: "sentAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS.LIST.SENT_TO" />,
                                    accessor: "sentTo",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS.LIST.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === CAMPAIGN_RECIPIENT_STATUS_PENDING) {
                                            return ChipCell(
                                                "info",
                                                <FormattedMessage id="CAMPAIGN.RECIPIENTS.STATUS.PENDING" />
                                            );
                                        } else if (props.value === CAMPAIGN_RECIPIENT_STATUS_SUCCESS) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="CAMPAIGN.RECIPIENTS.STATUS.SUCCESS" />
                                            );
                                        } else if (props.value === CAMPAIGN_RECIPIENT_STATUS_ERROR) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="CAMPAIGN.RECIPIENTS.STATUS.ERROR" />
                                            );
                                        }

                                        return TextCell();
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...campaignActions, ...errorActions })(CampaignSMSPageRecipients);
