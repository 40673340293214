import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import * as transactionUtils from "../../../../pages/transactions/utils";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function TransactionPaymentStatusFilter({ onPaymentStatusChanged }) {
    const classes = useStyles();
    const [paymentStatus, setPaymentStatus] = useState("all");

    const handleChange = ({ target: { value } }) => {
        setPaymentStatus(value);
        onPaymentStatusChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">
                <FormattedMessage id="PAYMENT.FILTER.PAYMENT_STATUS.TITLE" />
            </InputLabel>
            <Select labelId="status-filter-label" id="status-filter" value={paymentStatus} onChange={handleChange}>
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={transactionUtils.PAYMENT_STATUS_PAID}>
                    <FormattedMessage id="PAYMENT.FILTER.PAYMENT_STATUS.PAID" />
                </MenuItem>
                <MenuItem value={transactionUtils.PAYMENT_STATUS_PARTPAID}>
                    <FormattedMessage id="PAYMENT.FILTER.PAYMENT_STATUS.PARTPAID" />
                </MenuItem>
                <MenuItem value={transactionUtils.PAYMENT_STATUS_UNPAID}>
                    <FormattedMessage id="PAYMENT.FILTER.PAYMENT_STATUS.UNPAID" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default TransactionPaymentStatusFilter;
