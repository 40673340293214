import { put, takeLatest } from "redux-saga/effects";
import {
    getProfileCancellationTemplates,
    getProfileCancellationTemplate,
    addProfileCancellationTemplate,
    updateProfileCancellationTemplate,
    deleteProfileCancellationTemplate,
    getProfileCancellationTemplateRefundRules,
    addCancellationTemplateRefundRule,
    deleteProfileCancellationTemplateRefundRule,
} from "./cancellationTemplateCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import { findAndSpliceArrayByProperty } from "../reduxUtils";

export const actionTypes = {
    GET_PROFILE_CANCELLATION_TEMPLATES_REQUEST: "GET_PROFILE_CANCELLATION_TEMPLATES_REQUEST",
    GET_PROFILE_CANCELLATION_TEMPLATES_SUCCESS: "GET_PROFILE_CANCELLATION_TEMPLATES_SUCCESS",
    GET_PROFILE_CANCELLATION_TEMPLATE_REQUEST: "GET_PROFILE_CANCELLATION_TEMPLATE_REQUEST",
    GET_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: "GET_PROFILE_CANCELLATION_TEMPLATE_SUCCESS",
    ADD_PROFILE_CANCELLATION_TEMPLATE_REQUEST: "ADD_PROFILE_CANCELLATION_TEMPLATE_REQUEST",
    ADD_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: "ADD_PROFILE_CANCELLATION_TEMPLATE_SUCCESS",
    UPDATE_PROFILE_CANCELLATION_TEMPLATE_REQUEST: "UPDATE_PROFILE_CANCELLATION_TEMPLATE_REQUEST",
    UPDATE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: "UPDATE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS",
    DELETE_PROFILE_CANCELLATION_TEMPLATE_REQUEST: "DELETE_PROFILE_CANCELLATION_TEMPLATE_REQUEST",
    DELETE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: "DELETE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS",
    GET_CANCELLATION_TEMPLATE_REFUND_RULES_REQUEST: "GET_CANCELLATION_TEMPLATE_REFUND_RULES_REQUEST",
    GET_CANCELLATION_TEMPLATE_REFUND_RULES_SUCCESS: "GET_CANCELLATION_TEMPLATE_REFUND_RULES_SUCCESS",
    ADD_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST: "ADD_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST",
    ADD_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS: "ADD_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS",
    DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST: "DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST",
    DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS: "DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS",
    CLEAR_CANCELLATION_TEMPLATES_STATE: "CLEAR_CANCELLATION_TEMPLATES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    refundRulesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_CANCELLATION_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_CANCELLATION_TEMPLATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PROFILE_CANCELLATION_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cancellationTemplate: action.payload.response,
            };
        }

        case actionTypes.ADD_PROFILE_CANCELLATION_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cancellationTemplate: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PROFILE_CANCELLATION_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cancellationTemplate: action.payload.response,
            };
        }

        case actionTypes.DELETE_PROFILE_CANCELLATION_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cancellationTemplate: action.payload.response,
            };
        }

        case actionTypes.GET_CANCELLATION_TEMPLATE_REFUND_RULES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CANCELLATION_TEMPLATE_REFUND_RULES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refundRulesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.refundRulesPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                refundRulesPagination: {
                    ...state.refundRulesPagination,
                    data: alteredStateArray,
                    totalRows: state.refundRulesPagination.totalRows - 1,
                },
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_CANCELLATION_TEMPLATES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getProfileCancellationTemplates: (profileId, page, perPage) => ({
        type: actionTypes.GET_PROFILE_CANCELLATION_TEMPLATES_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getProfileCancellationTemplate: (id) => ({
        type: actionTypes.GET_PROFILE_CANCELLATION_TEMPLATE_REQUEST,
        payload: { id },
    }),

    addProfileCancellationTemplate: (profileId, data, callback) => ({
        type: actionTypes.ADD_PROFILE_CANCELLATION_TEMPLATE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateProfileCancellationTemplate: (id, originalCancellationTemplate, updatedCancellationTemplate) => ({
        type: actionTypes.UPDATE_PROFILE_CANCELLATION_TEMPLATE_REQUEST,
        payload: { id, originalCancellationTemplate, updatedCancellationTemplate },
    }),

    deleteProfileCancellationTemplate: (id) => ({
        type: actionTypes.DELETE_PROFILE_CANCELLATION_TEMPLATE_REQUEST,
        payload: { id },
    }),

    getCancellationTemplateRefundRules: (cancellationTemplateId, page, perPage) => ({
        type: actionTypes.GET_CANCELLATION_TEMPLATE_REFUND_RULES_REQUEST,
        payload: { cancellationTemplateId, page, perPage },
    }),

    addCancellationTemplateRefundRule: (profileId, data, callback) => ({
        type: actionTypes.ADD_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST,
        payload: { profileId, data, callback },
    }),

    deleteCancellationTemplateRefundRules: (id) => ({
        type: actionTypes.DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST,
        payload: { id },
    }),

    clearCancellationTemplateState: () => ({
        type: actionTypes.CLEAR_CANCELLATION_TEMPLATES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROFILE_CANCELLATION_TEMPLATES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileCancellationTemplates(
                payload.profileId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_PROFILE_CANCELLATION_TEMPLATES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROFILE_CANCELLATION_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileCancellationTemplate(payload.id);

            yield put({
                type: actionTypes.GET_PROFILE_CANCELLATION_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROFILE_CANCELLATION_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addProfileCancellationTemplate(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PROFILE_CANCELLATION_TEMPLATE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PROFILE_CANCELLATION_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateProfileCancellationTemplate(
                payload.id,
                payload.originalCancellationTemplate,
                {
                    ...payload.originalCancellationTemplate,
                    ...payload.updatedCancellationTemplate,
                }
            );
            yield put({
                type: actionTypes.UPDATE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PROFILE_CANCELLATION_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteProfileCancellationTemplate(payload.id);
            yield put({
                type: actionTypes.DELETE_PROFILE_CANCELLATION_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CANCELLATION_TEMPLATE_REFUND_RULES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileCancellationTemplateRefundRules(
                payload.cancellationTemplateId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_CANCELLATION_TEMPLATE_REFUND_RULES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCancellationTemplateRefundRule(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteProfileCancellationTemplateRefundRule(payload.id);
            yield put({
                type: actionTypes.DELETE_CANCELLATION_TEMPLATE_REFUND_RULE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
