import React, { useState, useMemo, useCallback } from "react";
import { actions as bankIDActions } from "../../../../redux/integrations/bankid/bankIDRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    DateTimeCell,
    TextCell,
    LinkCell,
    DeleteActionButton,
    TranslationCell,
} from "../../../components/tables/MaterialTableCells";
import { getServiceDetailsPageUrl } from "../../services/utils";
import SelectBankIDServicesModal from "./SelectBankIDServicesModal";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";

function BankIDPageServices({ getBankIDServices, addBankIDServicesAndCategories, deleteBankIDService }) {
    const { integration, servicesPagination: bankIDServicesPagination, isLoading, isUpdating } = useSelector(
        (state) => state.bankid
    );
    const { displayError } = useSelector((state) => state.errors);
    const integrationId = integration?.id;

    const [showModalAddService, setShowModalAddService] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (integrationId) {
                getBankIDServices(integrationId, pageIndex + 1, pageSize);
            }
        },
        [integrationId, getBankIDServices]
    );

    const onAddModalSaveClicked = (items, userVisibleData) => {
        addBankIDServicesAndCategories(integrationId, items, userVisibleData, () => {
            setShowModalAddService(false);
            getBankIDServices(integrationId, 1, 10);
        });
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="INTEGRATION.BANKID.SERVICES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="INTEGRATION.BANKID.SERVICES.DESCRIPTION" />
                        </span>
                    </div>
                    {integration && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddService(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={integrationId}
                        data={useMemo(() => bankIDServicesPagination.data, [bankIDServicesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={bankIDServicesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="INTEGRATION.BANKID.SERVICES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="INTEGRATION.BANKID.SERVICES.LIST.HEADER.TYPE" />,
                                    accessor: "type",
                                    maxWidth: 100,
                                    Cell: ({ row }) => {
                                        const type = row.original.type;
                                        if (type === "service") {
                                            return TranslationCell("INTEGRATION.BANKID.SERVICES.TYPE.SERVICE");
                                        } else if (type === "service_category") {
                                            return TranslationCell("INTEGRATION.BANKID.SERVICES.TYPE.SERVICE_CATEGORY");
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="INTEGRATION.BANKID.SERVICES.LIST.HEADER.NAME" />,
                                    accessor: "service",
                                    Cell: ({ row }) => {
                                        if (row.original.service) {
                                            const service = row.original.service;
                                            const url = getServiceDetailsPageUrl(service.type, service.id);
                                            if (!url) return TextCell(service.name);
                                            return LinkCell(url, service.name);
                                        } else if (row.original.serviceCategory) {
                                            const serviceCategory = row.original.serviceCategory;
                                            return LinkCell(
                                                "/service-categories/" + serviceCategory.id,
                                                serviceCategory.name
                                            );
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.BANKID.SERVICES.LIST.HEADER.CREATED_AT" />
                                    ),
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "delete",
                                    Cell: ({ row }) => {
                                        const content = (
                                            <FormattedMessage id="INTEGRATION.BANKID.SERVICES.DELETE.TEXT" />
                                        );
                                        const name = row.original.service
                                            ? row.original.service.name
                                            : row.original.serviceCategory.name;
                                        return DeleteActionButton(name, content, () =>
                                            deleteBankIDService(row.original.id)
                                        );
                                    },
                                },
                            ],
                            [deleteBankIDService]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {showModalAddService && (
                <SelectBankIDServicesModal
                    show={showModalAddService}
                    onCloseClicked={() => setShowModalAddService(false)}
                    onSaveClicked={onAddModalSaveClicked}
                    titleText={<FormattedMessage id={"INTEGRATION.BANKID.SERVICES.ADD.TITLE"} />}
                    descriptionText={<FormattedMessage id={"INTEGRATION.BANKID.SERVICES.ADD.DESCRIPTION"} />}
                    saveButtonText={<FormattedMessage id="COMMON.SAVE" />}
                    isLoading={isUpdating}
                    serviceStatusFilter="adminBookable"
                />
            )}
        </Card>
    );
}
export default connect(null, { ...bankIDActions, ...errorActions })(BankIDPageServices);
