import { put, takeLatest } from "redux-saga/effects";
import {
    getPayouts,
    getPayout,
    getPayoutTransactions,
    getPayoutTransactionRefunds,
    downloadPayoutDocument,
} from "./payoutsCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PAYOUTS_REQUEST: "GET_PAYOUTS_REQUEST",
    GET_PAYOUTS_SUCCESS: "GET_PAYOUTS_SUCCESS",
    GET_PAYOUT_REQUEST: "GET_PAYOUT_REQUEST",
    GET_PAYOUT_SUCCESS: "GET_PAYOUT_SUCCESS",
    GET_PAYOUT_TRANSACTIONS_REQUEST: "GET_PAYOUT_TRANSACTIONS_REQUEST",
    GET_PAYOUT_TRANSACTIONS_SUCCESS: "GET_PAYOUT_TRANSACTIONS_SUCCESS",
    GET_PAYOUT_TRANSACTION_REFUNDS_REQUEST: "GET_PAYOUT_TRANSACTION_REFUNDS_REQUEST",
    GET_PAYOUT_TRANSACTION_REFUNDS_SUCCESS: "GET_PAYOUT_TRANSACTION_REFUNDS_SUCCESS",
    DOWNLOAD_PAYOUT_DOCUMENT_REQUEST: "DOWNLOAD_PAYOUT_DOCUMENT_REQUEST",
    DOWNLOAD_PAYOUT_DOCUMENT_SUCCESS: "DOWNLOAD_PAYOUT_DOCUMENT_SUCCESS",
    CLEAR_PAYOUTS_STATE: "CLEAR_PAYOUTS_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    isDownloadingDocument: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    payoutTransactionsPagination: {
        data: [],
        totalRows: 0,
    },
    payoutTransactionRefundsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYOUTS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYOUTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PAYOUT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYOUT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                payout: action.payload.response,
            };
        }

        case actionTypes.GET_PAYOUT_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYOUT_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                payoutTransactionsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PAYOUT_TRANSACTION_REFUNDS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYOUT_TRANSACTION_REFUNDS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                payoutTransactionRefundsPagination: action.payload.response,
            };
        }

        case actionTypes.DOWNLOAD_PAYOUT_DOCUMENT_REQUEST: {
            return { ...state, isDownloadingDocument: true };
        }

        case actionTypes.DOWNLOAD_PAYOUT_DOCUMENT_SUCCESS: {
            return { ...state, isDownloadingDocument: false };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false, isDownloadingPdf: false };
        }

        case actionTypes.CLEAR_PAYOUTS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getPayouts: (profileId, page, perPage) => ({
        type: actionTypes.GET_PAYOUTS_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getPayout: (id) => ({
        type: actionTypes.GET_PAYOUT_REQUEST,
        payload: { id },
    }),

    getPayoutTransactions: (id, page, perPage) => ({
        type: actionTypes.GET_PAYOUT_TRANSACTIONS_REQUEST,
        payload: { id, page, perPage },
    }),

    getPayoutTransactionRefunds: (id, page, perPage) => ({
        type: actionTypes.GET_PAYOUT_TRANSACTION_REFUNDS_REQUEST,
        payload: { id, page, perPage },
    }),

    downloadPayoutDocument: (id, fileName) => ({
        type: actionTypes.DOWNLOAD_PAYOUT_DOCUMENT_REQUEST,
        payload: { id, fileName },
    }),

    clearPayoutState: () => ({
        type: actionTypes.CLEAR_PAYOUTS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PAYOUTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPayouts(payload.profileId, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_PAYOUTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PAYOUT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPayout(payload.id);

            yield put({
                type: actionTypes.GET_PAYOUT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PAYOUT_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPayoutTransactions(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_PAYOUT_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PAYOUT_TRANSACTION_REFUNDS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPayoutTransactionRefunds(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_PAYOUT_TRANSACTION_REFUNDS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DOWNLOAD_PAYOUT_DOCUMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield downloadPayoutDocument(payload.id);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${payload.fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();

            yield put({ type: actionTypes.DOWNLOAD_PAYOUT_DOCUMENT_SUCCESS });
        } catch (error) {
            if (error?.response?.data) {
                // Since response is expected in arraybuffer, convert to json manually before passing along
                var decodedApiResponseString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
                var apiErrorResponseObject = JSON.parse(decodedApiResponseString);
                error.response.data = apiErrorResponseObject;
            }

            yield put(errorActions.registerError(error));
        }
    });
}
