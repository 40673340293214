import React, { useMemo, useCallback } from "react";
import { actions as customerOfferActions } from "../../../../redux/customeroffers/customerOffersRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell, TranslationCell } from "../../../components/tables/MaterialTableCells";
import * as customerOfferUtils from "../utils";
import moment from "moment";

function VoucherPageTransactions({ getVoucherTransactions }) {
    const { voucher, voucherTransactionsPagination, isLoading } = useSelector((state) => state.customerOffers);
    const { displayError } = useSelector((state) => state.errors);
    const customerOfferId = voucher?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerOfferId) {
                getVoucherTransactions(customerOfferId, pageIndex + 1, pageSize);
            }
        },
        [customerOfferId, getVoucherTransactions]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customerOfferId}
                        data={useMemo(() => voucherTransactionsPagination.data, [voucherTransactionsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={voucherTransactionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.SERVICE" />
                                    ),
                                    accessor: "service",
                                    Cell: ({ row }) => {
                                        if (row.original.service && row.original.entry) {
                                            return TextCell(
                                                `${row.original.service.name} (${moment(
                                                    row.original.entry.startTime
                                                ).format("YYYY-MM-DD HH:mm")})`
                                            );
                                        } else if (row.original.service) {
                                            return TextCell(row.original.service.name);
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.TYPE" />
                                    ),
                                    accessor: "type",
                                    Cell: (props) => {
                                        if (props.value === customerOfferUtils.TRANSACTION_TYPE_PURCHASE) {
                                            return TranslationCell(
                                                "CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.TYPE.PURCHASE"
                                            );
                                        } else if (props.value === customerOfferUtils.TRANSACTION_TYPE_REFUND) {
                                            return TranslationCell(
                                                "CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.TYPE.REFUND"
                                            );
                                        } else if (
                                            props.value === customerOfferUtils.TRANSACTION_TYPE_MANUAL_ADJUSTMENT
                                        ) {
                                            return TranslationCell(
                                                "CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.TYPE.MANUAL_ADJUSTMENT"
                                            );
                                        } else if (props.value === customerOfferUtils.TRANSACTION_TYPE_SUBSCRIPTION) {
                                            return TranslationCell(
                                                "CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.TYPE.SUBSCRIPTION"
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.AMOUNT" />
                                    ),
                                    accessor: "balanceChange",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="CUSTOMEROFFER.VOUCHER.TRANSACTIONS.LIST.HEADER.CREATED_AT" />
                                    ),
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...customerOfferActions, ...errorActions })(VoucherPageTransactions);
