import axios from "axios";
import { getApiUrl } from "../../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const INTEGRATION_URL = "v1/admin/integrations/{id}/fortnox";
export const INTEGRATIONS_URL = "v1/admin/profiles/{profileId}/integrations";
export const FORTNOX_SERVICES_URL = "v1/admin/integrations/{id}/fortnox/services";
export const FORTNOX_ARTICLES_URL = "v1/admin/integrations/{id}/fortnox/articles";
export const FORTNOX_ACCOUNTS_URL = "v1/admin/integrations/{id}/fortnox/accounts";
export const FORTNOX_COST_CENTERS_URL = "v1/admin/integrations/{id}/fortnox/cost-centers";

export function getFortnoxIntegrationAuth(profileId, integrationId) {
    const url = `${INTEGRATIONS_URL.replace("{profileId}", profileId)}/fortnox/auth${
        integrationId ? `?integrationId=${integrationId}` : ""
    }`;
    return axios.get(getApiUrl() + url);
}

export function createFortnoxIntegration(profileId, code, integrationId) {
    const url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "/fortnox";
    return axios.post(getApiUrl() + url, { code, integrationId });
}

export function patchFortnoxIntegration(id, originalIntegration, changedIntegration) {
    const url = INTEGRATION_URL.replace("{id}", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalIntegration, changedIntegration), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function getFortnoxServices(integrationId, page, perPage, search) {
    const url = `${FORTNOX_SERVICES_URL.replace("{id}", integrationId)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }`;
    return axios.get(getApiUrl() + url);
}

export function addFortnoxService(integrationId, data) {
    const url = FORTNOX_SERVICES_URL.replace("{id}", integrationId);
    return axios.post(getApiUrl() + url, data);
}

export function patchFortnoxService(integrationId, fortnoxServiceId, originalFortnoxService, changedFortnoxService) {
    const url = FORTNOX_SERVICES_URL.replace("{id}", integrationId) + "/" + fortnoxServiceId;
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalFortnoxService, changedFortnoxService), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteFortnoxService(integrationId, fortnoxServiceId) {
    return axios.delete(getApiUrl() + FORTNOX_SERVICES_URL.replace("{id}", integrationId) + "/" + fortnoxServiceId);
}

export function getFortnoxArticles(integrationId, page, perPage, search) {
    const url = `${FORTNOX_ARTICLES_URL.replace("{id}", integrationId)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }`;
    return axios.get(getApiUrl() + url);
}

export function getFortnoxAccounts(integrationId, page, perPage, search) {
    const url = `${FORTNOX_ACCOUNTS_URL.replace("{id}", integrationId)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }`;
    return axios.get(getApiUrl() + url);
}

export function getFortnoxCostCenters(integrationId, page, perPage, search) {
    const url = `${FORTNOX_COST_CENTERS_URL.replace("{id}", integrationId)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }`;
    return axios.get(getApiUrl() + url);
}
