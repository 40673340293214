import { put, takeLatest } from "redux-saga/effects";
import {
    createBankIDIntegration,
    getBankIDServices,
    addBankIDServicesAndCategories,
    deleteBankIDService,
} from "./bankIDCrud";
import { getIntegration } from "../integrationCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../../errors/errorsRedux";
import { actionTypes as integrationActionTypes } from "../integrationRedux";
import { findAndSpliceArrayByProperty } from "../../reduxUtils";

export const actionTypes = {
    GET_INTEGRATION_BANKID_REQUEST: "GET_INTEGRATION_BANKID_REQUEST",
    GET_INTEGRATION_BANKID_SUCCESS: "GET_INTEGRATION_BANKID_SUCCESS",
    CREATE_INTEGRATION_BANKID_REQUEST: "CREATE_INTEGRATION_BANKID_REQUEST",
    CREATE_INTEGRATION_BANKID_SUCCESS: "CREATE_INTEGRATION_BANKID_SUCCESS",
    GET_BANKID_SERVICES_REQUEST: "GET_BANKID_SERVICES_REQUEST",
    GET_BANKID_SERVICES_SUCCESS: "GET_BANKID_SERVICES_SUCCESS",
    ADD_BANKID_SERVICE_REQUEST: "ADD_BANKID_SERVICE_REQUEST",
    ADD_BANKID_SERVICE_SUCCESS: "ADD_BANKID_SERVICE_SUCCESS",
    DELETE_BANKID_SERVICE_REQUEST: "DELETE_BANKID_SERVICE_REQUEST",
    DELETE_BANKID_SERVICE_SUCCESS: "DELETE_BANKID_SERVICE_SUCCESS",
    CLEAR_BANKID_STATE: "CLEAR_BANKID_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    integration: null,
    servicesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTEGRATION_BANKID_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_BANKID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.CREATE_INTEGRATION_BANKID_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_INTEGRATION_BANKID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
                redirectToDetails: true,
            };
        }

        case integrationActionTypes.DELETE_INTEGRATION_REQUEST: {
            return { ...state, isLoading: true };
        }

        case integrationActionTypes.DELETE_INTEGRATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.GET_BANKID_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_BANKID_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                servicesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_BANKID_SERVICE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_BANKID_SERVICE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.DELETE_BANKID_SERVICE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.DELETE_BANKID_SERVICE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.servicesPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isUpdating: false,
                servicesPagination: {
                    ...state.servicesPagination,
                    data: alteredStateArray,
                    totalRows: state.servicesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.CLEAR_BANKID_STATE: {
            return initialState;
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getBankIDIntegration: (id) => ({
        type: actionTypes.GET_INTEGRATION_BANKID_REQUEST,
        payload: { id },
    }),

    createBankIDIntegration: (profileId) => ({
        type: actionTypes.CREATE_INTEGRATION_BANKID_REQUEST,
        payload: { profileId },
    }),

    getBankIDServices: (id, page, perPage) => ({
        type: actionTypes.GET_BANKID_SERVICES_REQUEST,
        payload: { id, page, perPage },
    }),

    addBankIDServicesAndCategories: (id, items, userVisibleData, callback) => ({
        type: actionTypes.ADD_BANKID_SERVICE_REQUEST,
        payload: { id, items, userVisibleData, callback },
    }),

    deleteBankIDService: (id, serviceId, callback) => ({
        type: actionTypes.DELETE_BANKID_SERVICE_REQUEST,
        payload: { id, serviceId, callback },
    }),

    clearBankIDState: () => ({
        type: actionTypes.CLEAR_BANKID_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_INTEGRATION_BANKID_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getIntegration("bankid", payload.id);

            yield put({
                type: actionTypes.GET_INTEGRATION_BANKID_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_INTEGRATION_BANKID_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createBankIDIntegration(payload.profileId);

            yield put({
                type: actionTypes.CREATE_INTEGRATION_BANKID_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_BANKID_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getBankIDServices(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_BANKID_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_BANKID_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addBankIDServicesAndCategories(
                payload.id,
                payload.items,
                payload.userVisibleData
            );
            yield put({
                type: actionTypes.ADD_BANKID_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_BANKID_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteBankIDService(payload.id);
            yield put({
                type: actionTypes.DELETE_BANKID_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
