import React, { useEffect } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import ServiceCheckoutDetailsFormOld from "../ServiceCheckoutDetailsFormOld";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
} from "../utils";

const schemaWithoutPaymentTemplate = yup.object().shape({
    paymentMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    vat: yup.object(),
    currency: yup.object(),
    billingTemplate: yup.object(),
    customerMessageMode: yup.string(),
    addonsMode: yup.string(),
    proxyUsersMode: yup.string(),
    emailDetails: yup.string(),
});

const schemaWithPaymentTemplate = yup.object().shape({
    paymentMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    vat: yup.object(),
    currency: yup.object(),
    paymentTemplate: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    giftMode: yup.object(),
    proxyUsersMode: yup.object(),
    addonsMode: yup.object(),
    customerMessageMode: yup.object(),
    emailDetails: yup.string(),
});

function CoursePageCheckoutSettings({ updateCourse, getServiceOptions }) {
    const { profile } = useSelector((state) => state.auth);
    const { course, serviceOptions, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(
            serviceOptions?.paymentTemplates?.length > 0 ? schemaWithPaymentTemplate : schemaWithoutPaymentTemplate
        ),
    });

    useEffect(() => {
        if (!serviceOptions) {
            getServiceOptions(profile.id);
        }
    }, [profile.id, serviceOptions, getServiceOptions]);

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        if (hasPaymentTemplates) {
            onFormSubmitNew(values);
        } else {
            onFormSubmitOld(values);
        }
    };

    const onFormSubmitOld = (values) => {
        let originalCourse = { ...course };
        originalCourse.currencyId = originalCourse?.currency?.id;
        originalCourse.vatId = originalCourse?.vat?.id;
        originalCourse.billingTemplateId = originalCourse?.billingTemplate?.id || null;
        originalCourse.isSwishEnabled = originalCourse.paymentMethods.includes(PAYMENT_METHOD_SWISH);
        originalCourse.isCardEnabled = originalCourse.paymentMethods.includes(PAYMENT_METHOD_CARD);
        originalCourse.isInvoiceEnabled = originalCourse.paymentMethods.includes(PAYMENT_METHOD_INVOICE);

        let updatedCourseData = { ...course };
        updatedCourseData.paymentMode = values.paymentMode;
        updatedCourseData.price = values.price;
        updatedCourseData.currencyId = values?.currency?.id;
        updatedCourseData.vatId = values?.vat?.id;
        updatedCourseData.billingTemplateId = values?.billingTemplate?.id;
        updatedCourseData.customerMessageMode = values.customerMessageMode || null;
        updatedCourseData.customerMessage = values.customerMessage;
        updatedCourseData.addonsMode = values.addonsMode || null;
        updatedCourseData.proxyUsersMode = values.proxyUsersMode || null;
        updatedCourseData.emailDetails = values.emailDetails;

        if (values.paymentMethods) {
            updatedCourseData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            updatedCourseData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            updatedCourseData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        updateCourse(course.id, originalCourse, updatedCourseData);
    };

    const onFormSubmitNew = (values) => {
        let originalCourse = { ...course };
        originalCourse.currencyId = originalCourse?.currency?.id;
        originalCourse.vatId = originalCourse?.vat?.id;
        originalCourse.billingTemplateId = originalCourse?.billingTemplate?.id || null;
        originalCourse.paymentTemplateId = originalCourse?.paymentTemplate?.id || null;
        originalCourse.isSwishEnabled = originalCourse.paymentMethods.includes(PAYMENT_METHOD_SWISH);
        originalCourse.isCardEnabled = originalCourse.paymentMethods.includes(PAYMENT_METHOD_CARD);
        originalCourse.isInvoiceEnabled = originalCourse.paymentMethods.includes(PAYMENT_METHOD_INVOICE);

        let updatedCourseData = { ...course };
        updatedCourseData.price = values.price;
        updatedCourseData.currencyId = values?.currency?.id;
        updatedCourseData.vatId = values?.vat?.id;
        updatedCourseData.paymentTemplateId = values?.paymentTemplate?.id;
        updatedCourseData.giftMode = values?.giftMode?.id;
        updatedCourseData.proxyUsersMode = values?.proxyUsersMode?.id;
        updatedCourseData.addonsMode = values?.addonsMode?.id;
        updatedCourseData.customerMessageMode = values?.customerMessageMode?.id;
        updatedCourseData.customerMessage = values.customerMessage;
        updatedCourseData.emailDetails = values.emailDetails;

        if (serviceOptions.paymentTemplates.length === 0) {
            updatedCourseData.billingTemplateId = values?.billingTemplate?.id;

            if (values.paymentMethods) {
                updatedCourseData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
                updatedCourseData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
                updatedCourseData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
            }
        }

        updateCourse(course.id, originalCourse, updatedCourseData);
    };

    const hasPaymentTemplates = serviceOptions?.paymentTemplates?.length > 0;

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.PAYMENT_SETTINGS.TITLE" />
                        </h3>
                    </div>
                    {course && !course?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            {serviceOptions && !hasPaymentTemplates && (
                                <ServiceCheckoutDetailsFormOld
                                    serviceOptions={serviceOptions}
                                    service={course}
                                    pricePerOccasion={course?.isBookPerOccasion}
                                />
                            )}
                            {serviceOptions && hasPaymentTemplates && (
                                <ServiceCheckoutDetailsForm
                                    serviceOptions={serviceOptions}
                                    service={course}
                                    pricePerOccasion={course?.isBookPerOccasion}
                                />
                            )}
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(CoursePageCheckoutSettings);
