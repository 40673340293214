import React from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as payoutActions } from "../../../redux/payouts/payoutsRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(2),
        alignItems: "center",
    },
}));

function PayoutPageNavigator({ payoutId }) {
    const { payout } = useSelector((state) => state.payouts);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        <span className="font-weight-bolder font-size-h6 text-dark-75">
                            <FormattedMessage id="PAYOUT.DETAILS.TITLE_NAVIGATOR" values={{ id: payoutId }} />
                        </span>
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="PAYOUT.DETAILS.PERIOD_START" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {payout?.periodStart ? moment(payout.periodStart).format("YYYY-MM-DD HH:mm") : ""}
                            </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="PAYOUT.DETAILS.PERIOD_END" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {payout?.periodEnd ? moment(payout.periodEnd).format("YYYY-MM-DD HH:mm") : ""}
                            </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="PAYOUT.DETAILS.PAYOUT_AMOUNT_TOTAL" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {payout ? `${payout.payoutAmountTotal} ${payout.currency}` : ""}
                            </span>
                        </div>
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/payouts/${payoutId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYOUT.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/payouts/${payoutId}/transactions`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYOUT.TRANSACTIONS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/payouts/${payoutId}/refunds`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
        </div>
    );
}

export default connect(null, payoutActions)(PayoutPageNavigator);
