import React from "react";
import { TextCell, ChipCell } from "../../components/tables/MaterialTableCells";
import { FormattedMessage } from "react-intl";

export const STATUS_ACTIVE = "active";
export const STATUS_DISABLED = "disabled";
export const STATUS_EXPIRED = "expired";
export const STATUS_DELETED = "deleted";

export const BOOKING_ACCESS_STATUS_FINISHED = "finished";
export const BOOKING_ACCESS_STATUS_DELETED = "deleted";

export function getIntegrationStatusCell(integration) {
    if (integration.status === STATUS_ACTIVE) {
        return ChipCell("success", <FormattedMessage id="INTEGRATION.STATUS.ACTIVE" />);
    } else if (integration.status === STATUS_DISABLED) {
        return ChipCell("warning", <FormattedMessage id="INTEGRATION.STATUS.DISABLED" />);
    } else if (integration.status === STATUS_EXPIRED) {
        return ChipCell("warning", <FormattedMessage id="INTEGRATION.STATUS.EXPIRED" />);
    } else if (integration.status === STATUS_DELETED) {
        return ChipCell("error", <FormattedMessage id="INTEGRATION.STATUS.DELETED" />);
    }

    return TextCell();
}

export function getBookingAccessStatusCell(status) {
    if (status === BOOKING_ACCESS_STATUS_FINISHED) {
        return ChipCell("success", <FormattedMessage id="INTEGRATION.BOOKING_ACCESS.STATUS.FINISHED" />);
    } else if (status === BOOKING_ACCESS_STATUS_DELETED) {
        return ChipCell("error", <FormattedMessage id="INTEGRATION.BOOKING_ACCESS.STATUS.DELETED" />);
    }

    return TextCell();
}
