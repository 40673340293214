import React, { useMemo, useCallback, useState } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell, LinkCell } from "../../../components/tables/MaterialTableCells";
import { makeStyles } from "@material-ui/core";
import ListActionsButton from "../../../components/buttons/ListActionsButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import MoveMemberIcon from "@material-ui/icons/ThumbUpOutlined";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import WaitingListMemberMoveModal from "./WaitingListMemberMoveModal";

const useStyles = makeStyles((theme) => ({
    isBookPerOccasionContainer: {
        display: "flex",
        padding: "100px",
        justifyContent: "center",
        textAlign: "center",
    },
}));

function CoursePageWaitingList({ getWaitingListMembers, deleteWaitingListMember }) {
    const { course, waitingListMembersPagination, isLoading } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const serviceId = course?.id;

    const classes = useStyles();

    const [waitingListMemberToMove, setWaitingListMemberToMove] = useState(null);
    const [waitingListMemberToDelete, setWaitingListMemberToDelete] = useState(null);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getWaitingListMembers(serviceId, pageIndex + 1, pageSize);
            }
        },
        [serviceId, getWaitingListMembers]
    );

    const tableData = useMemo(() => waitingListMembersPagination.data, [waitingListMembersPagination.data]);
    const tableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.ID" />,
                accessor: "id",
                width: "7%",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.NAME" />,
                accessor: "name",
                Cell: ({ row }) => {
                    const waitingListMember = row.original;
                    if (!waitingListMember.customerId) {
                        return TextCell(waitingListMember.name);
                    }

                    return LinkCell(`/customers/${waitingListMember.customerId}`, waitingListMember.name);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.EMAIL" />,
                accessor: "email",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.CREATED_AT" />,
                accessor: "createdAt",
                Cell: (props) => {
                    return DateTimeCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                id: "actions",
                textAlign: "center",
                Cell: ({ row }) => {
                    return (
                        <ListActionsButton
                            menuItems={[
                                {
                                    icon: <MoveMemberIcon fontSize="small" />,
                                    label: <FormattedMessage id="WAITING_LIST.MEMBER.MOVE.BUTTON.TO_COURSE" />,
                                    onClick: () => setWaitingListMemberToMove(row.original),
                                },
                                {
                                    icon: <DeleteOutlinedIcon fontSize="small" />,
                                    label: <FormattedMessage id="COMMON.DELETE" />,
                                    onClick: () => setWaitingListMemberToDelete(row.original),
                                },
                            ]}
                        />
                    );
                },
            },
        ],
        []
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.TITLE" />
                        </h3>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    {!course?.isBookPerOccasion && (
                        <MaterialReactTable
                            key={serviceId}
                            data={tableData}
                            fetchData={fetchData}
                            pageSize={10}
                            rowCount={waitingListMembersPagination.totalRows}
                            isLoading={isLoading}
                            error={displayError}
                            columns={tableColumns}
                            getCustomCellProps={() => ({
                                style: { height: "64px" },
                            })}
                        />
                    )}
                    {course?.isBookPerOccasion && (
                        <div className={classes.isBookPerOccasionContainer}>
                            <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.BOOK_PER_OCCASION_TEXT" />
                        </div>
                    )}
                </Card.Body>
            </div>

            {waitingListMemberToMove && (
                <WaitingListMemberMoveModal
                    waitingListMember={waitingListMemberToMove}
                    moveToName={course.name}
                    show={waitingListMemberToMove ? true : false}
                    onCloseClicked={() => setWaitingListMemberToMove(null)}
                    onMoved={() => setWaitingListMemberToMove(null)}
                />
            )}

            <YesNoDialog
                title={<FormattedMessage id="WAITING_LIST.MEMBER.DELETE.TEXT" />}
                open={waitingListMemberToDelete ? true : false}
                onOpenChanged={() => setWaitingListMemberToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteWaitingListMember(waitingListMemberToDelete.id);
                    }
                    setWaitingListMemberToDelete(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(CoursePageWaitingList);
