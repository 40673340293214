const supportedLanguages = [
  "en",
  "sv"
]

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

export function getSelectedLanguage() {

  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      // Return selected language
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }

  // Determine language and fallback on english if it isn't supported
  var language = getBrowserLanguage();
  if (language) {

    if (supportedLanguages.indexOf(language) > 0) {
      return {
        selectedLang: language
      };
    }

  }
  
  return { selectedLang: "en" };
}

export function getBrowserLanguage() {
  var language = (navigator.language || navigator.userLanguage);
  if (language) {

    // Language might be returned as "en" but also "en-US"
    if (language.indexOf('-') > -1) {
      language = language.split('-')[0];
    }

  }

  return language;
}

export function saveLanguage(lang) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
}