import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as serviceCategoryActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import { Paper } from "@material-ui/core";
import { LinkCell, TextCell, TextCellEllipsis } from "../../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import ServiceCategoryListToolbar from "../../../components/tables/toolbar/ServiceCategoryListToolbar";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import ListActionsButton from "../../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

function CategoryPageList({ getServiceCategories, deleteServiceCategory, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.serviceCategories);
    const { displayError } = useSelector((state) => state.errors);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [categoryToDelete, setCategoryToDelete] = useState(null);

    const history = useHistory();

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getServiceCategories(profile.id, pageIndex + 1, pageSize, search, status);
        },
        [getServiceCategories, profile.id, search, status]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return (
                        <ServiceCategoryListToolbar
                            onSearchChanged={onSearchChanged}
                            onStatusChanged={onStatusChanged}
                        />
                    );
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="SERVICE_CATEGORY.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                return LinkCell("/service-categories/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE_CATEGORY.LIST.HEADER.NAME" />,
                            accessor: "name",
                            Cell: (props) => {
                                return TextCellEllipsis(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="SERVICE_CATEGORY.LIST.HEADER.SERVICE_COUNT" />,
                            accessor: "serviceCount",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                return (
                                    <ListActionsButton
                                        menuItems={[
                                            {
                                                icon: <LinkIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.OPEN" />,
                                                onClick: () => {
                                                    history.push(`/service-categories/${row.original.id}`);
                                                },
                                            },
                                            {
                                                icon: <DeleteOutlinedIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.DELETE" />,
                                                onClick: () => setCategoryToDelete(row.original),
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                    [history]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
            <YesNoDialog
                title={<FormattedMessage id="SERVICE_CATEGORY.DELETE.TITLE" />}
                description={<FormattedMessage id="SERVICE_CATEGORY.DELETE.TEXT" />}
                open={categoryToDelete ? true : false}
                onOpenChanged={() => setCategoryToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteServiceCategory(categoryToDelete.id);
                    }
                    setCategoryToDelete(null);
                }}
            />
        </Paper>
    );
}

export default connect(null, { ...serviceCategoryActions, ...errorActions })(CategoryPageList);
