import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as serviceCategoryActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import CategoryPageNavigator from "./CategoryPageNavigator";
import CategoryPageDetails from "./CategoryPageDetails";
import CategoryPageServices from "./CategoryPageServices";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function CategoryPage({ match, getServiceCategory, clearServiceCategoriesState, clearDisplayError, clearErrorState }) {
    const serviceCategoryId = parseInt(match.params.id, 10);
    const { serviceCategory, isLoading, isUpdating } = useSelector((state) => state.serviceCategories);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!serviceCategory || serviceCategory.id !== serviceCategoryId)) {
            getServiceCategory(serviceCategoryId);
        }
    }, [getServiceCategory, serviceCategory, serviceCategoryId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearServiceCategoriesState();
            clearErrorState();
        };
    }, [clearServiceCategoriesState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <CategoryPageNavigator serviceCategoryId={serviceCategoryId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/service-categories/${serviceCategoryId}`}
                            exact={true}
                            to={`/service-categories/${serviceCategoryId}/details`}
                        />
                        <Route
                            path={`/service-categories/${serviceCategoryId}/details`}
                            component={CategoryPageDetails}
                        />
                        <Route
                            path={`/service-categories/${serviceCategoryId}/services`}
                            component={CategoryPageServices}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...serviceCategoryActions, ...errorActions })(CategoryPage);
