import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions as bookingActions } from "../../../redux/bookings/bookingRedux";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import "./overview.css";
import { isAtLeastStaffPermission } from "../../../app/pages/resources/utils";

export function OverviewCardBookings({ getBookings, className }) {
    const { profile } = useSelector((state) => state.auth);
    const { bookingsPagination } = useSelector((state) => state.bookings);

    useEffect(() => {
        getBookings(profile.id, 1, 10);
    }, [getBookings, profile.id]);

    const getTimeLineTimeText = (dateTime) => {
        const momentDateTime = moment(dateTime);
        if (momentDateTime.isSame(moment(), "day")) {
            return (
                <FormattedMessage
                    id="OVERVIEW.BOOKINGS_CARD.TIMELINE.DATE_TODAY"
                    values={{ time: momentDateTime.format("HH:mm") }}
                />
            );
        } else if (momentDateTime.isSame(moment().subtract(1, "days"), "day")) {
            return (
                <FormattedMessage
                    id="OVERVIEW.BOOKINGS_CARD.TIMELINE.DATE_YESTERDAY"
                    values={{ time: momentDateTime.format("HH:mm") }}
                />
            );
        }
        return momentDateTime.format("YYYY-MM-DD HH:mm");
    };

    return (
        <>
            <div className={`card card-custom  ${className}`}>
                {/* Header */}
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-weight-bolder text-dark">
                            <FormattedMessage id="OVERVIEW.BOOKINGS_CARD.TITLE" />
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            <FormattedMessage id="OVERVIEW.BOOKINGS_CARD.SUBTITLE1" />
                            <Link to={`/bookings/list`}>
                                <FormattedMessage id="OVERVIEW.BOOKINGS_CARD.SUBTITLE2" />
                            </Link>
                        </span>
                    </h3>
                </div>
                {/* Body */}
                <div className="card-body pt-4">
                    <div className="timeline-overview-bookings mt-3">
                        {bookingsPagination.data.map((x, index) => {
                            return (
                                <div
                                    key={`timeline-overview-bookings-item-${index}`}
                                    className="timeline-overview-bookings-item align-items-start"
                                >
                                    <div className="timeline-overview-bookings-label font-weight-bolder text-dark-75 font-size-lg">
                                        {getTimeLineTimeText(x.createdAt)}
                                    </div>

                                    <div className="timeline-overview-bookings-badge">
                                        <i className={`fa fa-genderless ${getTimeLineBadgeColor(index)} icon-xl`}></i>
                                    </div>

                                    <div className="font-weight-mormal font-size-lg timeline-overview-bookings-content text-muted pl-3">
                                        {isAtLeastStaffPermission(profile.permissionId) ? (
                                            <Link
                                                to={`/customers/${x.customer.id}`}
                                            >{`${x.customer.firstName} ${x.customer.surname}`}</Link>
                                        ) : (
                                            <span>{`${x.customer.firstName} ${x.customer.surname}`}</span>
                                        )}
                                        <FormattedMessage id="OVERVIEW.BOOKINGS_CARD.TIMELINE.BOOKED" />
                                        <Link to={`/bookings/${x.id}`}>{x.service.name}</Link>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

function getTimeLineBadgeColor(index) {
    if (index % 3 === 0) {
        return "text-warning";
    } else if (index % 3 === 1) {
        return "text-success";
    } else if (index % 3 === 2) {
        return "text-danger";
    }
}

export default connect(null, bookingActions)(OverviewCardBookings);
