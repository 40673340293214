import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROMOTION_CODES_URL = "v1/admin/profiles/:id/promotion-codes";
export const PROMOTION_CODES_DETAILS_URL = "v1/admin/promotion-codes/:id";
export const PROMOTION_CODE_SERVICES_URL = "v1/admin/promotion-codes/:id/services";
export const PROMOTION_CODE_SERVICE_DELETE_URL = "v1/admin/promotion-code-services/:id";
export const PROMOTION_CODE_TRANSCATIONS_URL = "v1/admin/promotion-codes/:id/transactions";
export const PROMOTION_CODE_CUSTOMERS_URL = "v1/admin/promotion-codes/:id/customers";

export function getPromotionCodes(profileId, page, perPage, search) {
    const url = `${PROMOTION_CODES_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}${
        search ? `&search=${search}` : ""
    }`;
    return axios.get(getApiUrl() + url);
}

export function getPromotionCode(id) {
    return axios.get(getApiUrl() + PROMOTION_CODES_DETAILS_URL.replace(":id", id));
}

export function addPromotionCode(profileId, data) {
    return axios.post(getApiUrl() + PROMOTION_CODES_URL.replace(":id", profileId), data);
}

export function patchPromotionCode(id, originalPromotionCode, changedPromotionCode) {
    let url = PROMOTION_CODES_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalPromotionCode, changedPromotionCode), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deletePromotionCode(id) {
    return axios.delete(getApiUrl() + PROMOTION_CODES_DETAILS_URL.replace(":id", id));
}

export function getPromotionCodeServices(id, page, perPage) {
    let url = PROMOTION_CODE_SERVICES_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function addPromotionCodeServicesAndCategories(id, items) {
    let url = PROMOTION_CODE_SERVICES_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, items);
}

export function deletePromotionCodeService(promotionCodeServiceId) {
    let url = PROMOTION_CODE_SERVICE_DELETE_URL.replace(":id", promotionCodeServiceId);
    return axios.delete(getApiUrl() + url);
}

export function getPromotionCodeTransactions(id, page, perPage) {
    let url = PROMOTION_CODE_TRANSCATIONS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getPromotionCodeCustomers(id, page, perPage) {
    let url = PROMOTION_CODE_CUSTOMERS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}
