import { put, takeLatest } from "redux-saga/effects";
import {
    createParakeyIntegration,
    getParakeyDomains,
    getParakeyAccesses,
    createParakeyKeyService,
    updateParakeyKeyService,
    deleteParakeyKeyService,
    getParakeyBookingAccesses
} from "./parakeyCrud";
import { getIntegration, patchIntegration } from "../integrationCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../../errors/errorsRedux";

export const actionTypes = {
    GET_INTEGRATION_PARAKEY_REQUEST: "GET_INTEGRATION_PARAKEY_REQUEST",
    GET_INTEGRATION_PARAKEY_SUCCESS: "GET_INTEGRATION_PARAKEY_SUCCESS",
    CREATE_INTEGRATION_PARAKEY_REQUEST: "CREATE_INTEGRATION_PARAKEY_REQUEST",
    CREATE_INTEGRATION_PARAKEY_SUCCESS: "CREATE_INTEGRATION_PARAKEY_SUCCESS",
    UPDATE_INTEGRATION_PARAKEY_REQUEST: "UPDATE_INTEGRATION_PARAKEY_REQUEST",
    UPDATE_INTEGRATION_PARAKEY_SUCCESS: "UPDATE_INTEGRATION_PARAKEY_SUCCESS",
    GET_INTEGRATION_PARAKEY_DOMAINS_REQUEST: "GET_INTEGRATION_PARAKEY_DOMAINS_REQUEST",
    GET_INTEGRATION_PARAKEY_DOMAINS_SUCCESS: "GET_INTEGRATION_PARAKEY_DOMAINS_SUCCESS",
    GET_INTEGRATION_PARAKEY_ACCESSES_REQUEST: "GET_INTEGRATION_PARAKEY_ACCESSES_REQUEST",
    GET_INTEGRATION_PARAKEY_ACCESSES_SUCCESS: "GET_INTEGRATION_PARAKEY_ACCESSES_SUCCESS",
    CREATE_PARAKEY_KEY_SERVICE_REQUEST: "CREATE_PARAKEY_KEY_SERVICE_REQUEST",
    CREATE_PARAKEY_KEY_SERVICE_SUCCESS: "CREATE_PARAKEY_KEY_SERVICE_SUCCESS",
    UPDATE_PARAKEY_KEY_SERVICE_REQUEST: "UPDATE_PARAKEY_KEY_SERVICE_REQUEST",
    UPDATE_PARAKEY_KEY_SERVICE_SUCCESS: "UPDATE_PARAKEY_KEY_SERVICE_SUCCESS",
    DELETE_PARAKEY_KEY_SERVICE_REQUEST: "DELETE_PARAKEY_KEY_SERVICE_REQUEST",
    DELETE_PARAKEY_KEY_SERVICE_SUCCESS: "DELETE_PARAKEY_KEY_SERVICE_SUCCESS",
    GET_PARAKEY_BOOKING_ACCESSES_REQUEST: "GET_PARAKEY_BOOKING_ACCESSES_REQUEST",
    GET_PARAKEY_BOOKING_ACCESSES_SUCCESS: "GET_PARAKEY_BOOKING_ACCESSES_SUCCESS",
};

const initialState = {
    isLoading: false,
    integration: null,
    domains: [],
    keyServicesPagination: {
        data: [],
        totalRows: 0,
    },
    bookingAccessesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTEGRATION_PARAKEY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_PARAKEY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.CREATE_INTEGRATION_PARAKEY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_INTEGRATION_PARAKEY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
                redirectToDetails: true,
            };
        }

        case actionTypes.UPDATE_INTEGRATION_PARAKEY_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_INTEGRATION_PARAKEY_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.GET_INTEGRATION_PARAKEY_DOMAINS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_PARAKEY_DOMAINS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                domains: action.payload.response,
            };
        }

        case actionTypes.GET_INTEGRATION_PARAKEY_ACCESSES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_PARAKEY_ACCESSES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                keyServicesPagination: action.payload.response,
            };
        }

        case actionTypes.CREATE_PARAKEY_KEY_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_PARAKEY_KEY_SERVICE_SUCCESS: {
            const keyServiceListPagination = state.keyServicesPagination;
            const index = keyServiceListPagination.data.findIndex(
                (item) => item.externalId === action.payload.response.accessDeviceExternalId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...keyServiceListPagination.data];
                let accessDevice = alteredPaginationData[index];
                if (accessDevice.deviceServices) {
                    accessDevice.deviceServices.push(action.payload.response);
                } else {
                    accessDevice.deviceServices = [action.payload.response];
                }
            }
            return {
                ...state,
                isLoading: false,
                keyServicesPagination: {
                    ...state.keyServicesPagination,
                    data: alteredPaginationData || keyServiceListPagination.data,
                },
            };
        }

        case actionTypes.UPDATE_PARAKEY_KEY_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PARAKEY_KEY_SERVICE_SUCCESS: {
            const keyServiceListPagination = state.keyServicesPagination;
            const index = keyServiceListPagination.data.findIndex(
                (item) => item.externalId === action.payload.response.accessDeviceExternalId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...keyServiceListPagination.data];
                let accessDevice = alteredPaginationData[index];
                if (accessDevice.deviceServices) {
                    const accessDeviceServiceIndex = accessDevice.deviceServices.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (accessDeviceServiceIndex > -1) {
                        accessDevice.deviceServices[accessDeviceServiceIndex] = action.payload.response;
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingAccessDevice: null,
                managingAccessDeviceService: null,
                keyServicesPagination: {
                    ...state.keyServicesPagination,
                    data: alteredPaginationData || keyServiceListPagination.data,
                },
            };
        }

        case actionTypes.DELETE_PARAKEY_KEY_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PARAKEY_KEY_SERVICE_SUCCESS: {
            const keyServiceListPagination = state.keyServicesPagination;
            const index = keyServiceListPagination.data.findIndex(
                (item) => item.externalId === action.payload.response.accessDeviceExternalId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...keyServiceListPagination.data];
                let accessDevice = alteredPaginationData[index];
                if (accessDevice.deviceServices) {
                    const accessDeviceServiceIndex = accessDevice.deviceServices.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (accessDeviceServiceIndex > -1) {
                        accessDevice.deviceServices.splice(accessDeviceServiceIndex, 1);
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingAccessDevice: null,
                managingAccessDeviceService: null,
                keyServicesPagination: {
                    ...state.keyServicesPagination,
                    data: alteredPaginationData || keyServiceListPagination.data,
                },
            };
        }

        case actionTypes.GET_PARAKEY_BOOKING_ACCESSES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PARAKEY_BOOKING_ACCESSES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                bookingAccessesPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getParakeyIntegration: (id) => ({
        type: actionTypes.GET_INTEGRATION_PARAKEY_REQUEST,
        payload: { id },
    }),

    createParakeyIntegration: (profileId, accessToken) => ({
        type: actionTypes.CREATE_INTEGRATION_PARAKEY_REQUEST,
        payload: { profileId, accessToken },
    }),

    updateParakeyIntegration: (integrationId, originalIntegration, domain) => ({
        type: actionTypes.UPDATE_INTEGRATION_PARAKEY_REQUEST,
        payload: { integrationId, originalIntegration, domain },
    }),

    getParakeyDomains: (integrationId) => ({
        type: actionTypes.GET_INTEGRATION_PARAKEY_DOMAINS_REQUEST,
        payload: { integrationId },
    }),

    getParakeyAccesses: (id, page, perPage) => ({
        type: actionTypes.GET_INTEGRATION_PARAKEY_ACCESSES_REQUEST,
        payload: { id, page, perPage },
    }),

    createParakeyKeyService: (integrationId, externalId, data, callback) => ({
        type: actionTypes.CREATE_PARAKEY_KEY_SERVICE_REQUEST,
        payload: {
            integrationId,
            externalId,
            data,
            callback,
        },
    }),

    updateParakeyKeyService: (id, data, callback) => ({
        type: actionTypes.UPDATE_PARAKEY_KEY_SERVICE_REQUEST,
        payload: {
            id,
            data,
            callback,
        },
    }),

    deleteParakeyKeyService: (id) => ({
        type: actionTypes.DELETE_PARAKEY_KEY_SERVICE_REQUEST,
        payload: { id },
    }),

    getParakeyBookingAccesses: (id, page, perPage) => ({
        type: actionTypes.GET_PARAKEY_BOOKING_ACCESSES_REQUEST,
        payload: { id, page, perPage },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_INTEGRATION_PARAKEY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getIntegration("parakey", payload.id);

            yield put({
                type: actionTypes.GET_INTEGRATION_PARAKEY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_INTEGRATION_PARAKEY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createParakeyIntegration(payload.profileId, payload.accessToken);

            yield put({
                type: actionTypes.CREATE_INTEGRATION_PARAKEY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_INTEGRATION_PARAKEY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchIntegration(
                "parakey",
                payload.integrationId,
                payload.originalIntegration,
                {
                    ...payload.originalIntegration,
                    domain: payload.domain,
                }
            );

            yield put({
                type: actionTypes.UPDATE_INTEGRATION_PARAKEY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_INTEGRATION_PARAKEY_DOMAINS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getParakeyDomains(payload.integrationId);

            yield put({
                type: actionTypes.GET_INTEGRATION_PARAKEY_DOMAINS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_INTEGRATION_PARAKEY_ACCESSES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getParakeyAccesses(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_INTEGRATION_PARAKEY_ACCESSES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_PARAKEY_KEY_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createParakeyKeyService(
                payload.integrationId,
                payload.externalId,
                payload.data
            );

            yield put({
                type: actionTypes.CREATE_PARAKEY_KEY_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PARAKEY_KEY_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateParakeyKeyService(payload.id, payload.data);

            yield put({
                type: actionTypes.UPDATE_PARAKEY_KEY_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PARAKEY_KEY_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteParakeyKeyService(payload.id);
            yield put({
                type: actionTypes.DELETE_PARAKEY_KEY_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PARAKEY_BOOKING_ACCESSES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getParakeyBookingAccesses(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_PARAKEY_BOOKING_ACCESSES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
