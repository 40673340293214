import React, { useState, useMemo, useCallback } from "react";
import { actions as serviceActions } from "../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell, DeleteActionButton, LinkCell } from "../../components/tables/MaterialTableCells";
import SelectServiceModal from "./SelectServiceModal";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { makeStyles } from "@material-ui/core";
import { MODE_OPTIONAL, MODE_MANDATORY } from "../../components/dropdowns/ModeSettingDropdown";
import { getServiceDetailsPageUrl } from "./utils";

const useStyles = makeStyles((theme) => ({
    isAddonsNotEnabledContainer: {
        display: "flex",
        padding: "100px",
        justifyContent: "center",
        textAlign: "center",
    },
}));

function ServicePageAddons({ service, getServiceAddons, addServiceAddon, deleteServiceAddon }) {
    const { serviceAddonsPagination, isLoading, isUpdating } = useSelector((state) => state.services);
    const serviceId = service?.id;
    const classes = useStyles();

    const [showModalAddAddon, setShowModalAddAddon] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getServiceAddons(serviceId, pageIndex + 1, pageSize);
            }
        },
        [serviceId, getServiceAddons]
    );

    const onAddModalSaveClicked = (addonService) => {
        addServiceAddon(serviceId, addonService.id, () => {
            fetchData({ pageIndex: 0, pageSize: 10 });
        });
        setShowModalAddAddon(false);
    };

    const tableData = useMemo(() => serviceAddonsPagination.data, [serviceAddonsPagination.data]);

    const tableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="SERVICE.ADDONS.LIST.HEADER.ID" />,
                accessor: "id",
                width: "7%",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.ADDONS.LIST.HEADER.SERVICE" />,
                accessor: "service",
                Cell: (props) => {
                    const url = getServiceDetailsPageUrl(props.value.type, props.value.id);
                    if (!url) return TextCell(props.value.name);
                    return LinkCell(url, props.value.name);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.ADDONS.LIST.HEADER.CREATED_AT" />,
                accessor: "createdAt",
                Cell: (props) => {
                    return DateTimeCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                id: "delete",
                Cell: ({ row }) => {
                    if (row.original.deletedAt) return <></>;

                    return DeleteActionButton(
                        <FormattedMessage id="SERVICE.ADDONS.DELETE.TITLE" />,
                        <FormattedMessage id="SERVICE.ADDONS.DELETE.TEXT" />,
                        () => deleteServiceAddon(serviceId, row.original.id)
                    );
                },
            },
        ],
        [deleteServiceAddon, serviceId]
    );

    const hasAddonsEnabled = service?.addonsMode === MODE_OPTIONAL || service?.addonsMode === MODE_MANDATORY;

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.ADDONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.ADDONS.SUBTITLE" />
                        </span>
                    </div>
                    {service && hasAddonsEnabled && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddAddon(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    {hasAddonsEnabled && (
                        <MaterialReactTable
                            key={serviceId}
                            data={tableData}
                            fetchData={fetchData}
                            pageSize={10}
                            rowCount={serviceAddonsPagination.totalRows}
                            isLoading={isLoading}
                            columns={tableColumns}
                            getCustomCellProps={() => ({
                                style: { height: "64px" },
                            })}
                        />
                    )}
                    {!hasAddonsEnabled && (
                        <div className={classes.isAddonsNotEnabledContainer}>
                            <FormattedMessage
                                id="SERVICE.ADDONS.DISABLED.TEXT"
                                values={{
                                    p: (...chunks) => <p>{chunks}</p>,
                                    a: (msg) => (
                                        <Link to={`checkout-settings`} key={1}>
                                            <FormattedMessage id={"SERVICE.APPOINTMENT.PAYMENT_SETTINGS.TITLE"} />
                                        </Link>
                                    ),
                                }}
                            />
                        </div>
                    )}
                </Card.Body>
            </div>
            {showModalAddAddon && (
                <SelectServiceModal
                    show={showModalAddAddon}
                    onCloseClicked={() => setShowModalAddAddon(false)}
                    onSaveClicked={onAddModalSaveClicked}
                    titleText={<FormattedMessage id={"SERVICE.ADDONS.ADD.TITLE"} />}
                    descriptionText={<FormattedMessage id={"SERVICE.ADDONS.ADD.DESCRIPTION"} />}
                    saveButtonText={<FormattedMessage id="COMMON.SAVE" />}
                    isLoading={isUpdating}
                />
            )}
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(ServicePageAddons);
