import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const INTEGRATIONS_URL = "v1/admin/profiles/{profileId}/integrations";
export const INTEGRATION_DETAILS_URL = "v1/admin/integrations/{id}/{type}";
export const INTEGRATION_DELETE_URL = "v1/admin/integrations/{id}";

export const ZESEC_LOCATIONS_URL = "v1/admin/integrations/{id}/zesec/locations";
export const ZESEC_KEYS_URL = "v1/admin/integrations/{id}/zesec/keys";
export const ZESEC_KEY_SERVICE_URL = "v1/admin/integrations/{id}/zesec/keyservices";

export function getIntegrations(profileId, page, perPage) {
    let url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getIntegration(type, id) {
    let url = INTEGRATION_DETAILS_URL.replace("{id}", id).replace("{type}", type);
    return axios.get(getApiUrl() + url);
}

export function patchIntegration(type, id, originalIntegration, changedIntegration) {
    let url = INTEGRATION_DETAILS_URL.replace("{id}", id).replace("{type}", type);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalIntegration, changedIntegration), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteIntegration(id) {
    return axios.delete(getApiUrl() + INTEGRATION_DELETE_URL.replace("{id}", id));
}

export function createZesecIntegration(profileId, phoneNumber, password) {
    let url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "/zesec";
    return axios.post(getApiUrl() + url, { phoneNumber, password });
}

export function getZesecLocations(id) {
    let url = ZESEC_LOCATIONS_URL.replace("{id}", id);
    return axios.get(getApiUrl() + url);
}

export function getZesecKeys(id, page, perPage) {
    let url = `${ZESEC_KEYS_URL.replace("{id}", id)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function createZesecKeyService(integrationId, zesecKeyId, serviceId, prefixSeconds, postfixSeconds) {
    let url = ZESEC_KEY_SERVICE_URL.replace("{id}", integrationId);
    return axios.post(getApiUrl() + url, { zesecKeyId, serviceId, prefixSeconds, postfixSeconds });
}

export function patchZesecKeyService(integrationId, originalKeyService, changedKeyService) {
    let url = ZESEC_KEY_SERVICE_URL.replace("{id}", integrationId) + "/" + originalKeyService.id;
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalKeyService, changedKeyService), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteZesecKeyService(keyServiceId) {
    let url = ZESEC_KEY_SERVICE_URL.replace("{id}", keyServiceId) + "/" + keyServiceId;
    return axios.delete(getApiUrl() + url);
}
