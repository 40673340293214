import React, { useMemo, useCallback } from "react";
import { actions as serviceCategoryActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell, LinkCell } from "../../../components/tables/MaterialTableCells";
import { getServiceDetailsPageUrl } from "../utils";

function CategoryPageServices({ getServiceCategoryServices }) {
    const { serviceCategory, serviceCategoryServicesPagination, isLoading } = useSelector(
        (state) => state.serviceCategories
    );
    const { displayError } = useSelector((state) => state.errors);
    const serviceCategoryId = serviceCategory?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceCategoryId) {
                getServiceCategoryServices(serviceCategoryId, pageIndex + 1, pageSize);
            }
        },
        [serviceCategoryId, getServiceCategoryServices]
    );

    const tableData = useMemo(() => serviceCategoryServicesPagination.data, [serviceCategoryServicesPagination.data]);

    const tableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="SERVICE_CATEGORY.SERVICES.LIST.HEADER.ID" />,
                accessor: "id",
                width: "7%",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE_CATEGORY.SERVICES.LIST.HEADER.SERVICE" />,
                accessor: "service",
                Cell: ({ row }) => {
                    const service = row.original.service;
                    const url = getServiceDetailsPageUrl(service.type, service.id);
                    if (!url) return TextCell(service.name);
                    return LinkCell(url, service.name);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE_CATEGORY.SERVICES.LIST.HEADER.CREATED_AT" />,
                accessor: "createdAt",
                Cell: (props) => {
                    return DateTimeCell(props.value);
                },
            },
        ],
        []
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE_CATEGORY.SERVICES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE_CATEGORY.SERVICES.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceCategoryId}
                        data={tableData}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={serviceCategoryServicesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={tableColumns}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...serviceCategoryActions, ...errorActions })(CategoryPageServices);
