import React from "react";
import { actions as pricingTemplateActions } from "../../../redux/pricingtemplates/pricingTemplateRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import PricingTemplateDetailsForm from "./PricingTemplateDetailsForm";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function PricingTemplatePageDetails({ updatePricingTemplate }) {
    const { pricingTemplate, isUpdating } = useSelector((state) => state.pricingTemplates);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalPricingTemplate = { ...pricingTemplate };

        let updatedPricingTemplate = { ...pricingTemplate };
        updatedPricingTemplate.name = values.name;

        updatePricingTemplate(pricingTemplate.id, originalPricingTemplate, updatedPricingTemplate);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PRICING_TEMPLATE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PRICING_TEMPLATE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {pricingTemplate && !pricingTemplate?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <PricingTemplateDetailsForm pricingTemplate={pricingTemplate} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, pricingTemplateActions)(PricingTemplatePageDetails);
