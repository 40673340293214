import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import MaterialRadioDescriptionButton from "../../../components/inputs/MaterialRadioDescriptionButton";
import {
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE,
    OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION,
    OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME,
    OFFER_VALID_FOR_TYPE_ALL_SERVICES,
    OFFER_VALID_FOR_TYPE_SPECIFIC_SERVICES,
} from "../utils";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    validUntilTimeUnitContainer: {
        marginLeft: "16px",
    },
}));

function VoucherBookingSettingsForm({ service }) {
    const classes = useStyles();
    const intl = useIntl();

    const { register, control, setValue, watch, errors } = useFormContext();

    const [fixedEndTimePickerOpen, setFixedEndTimePickerOpen] = useState(false);

    useEffect(() => {
        if (service) {
            setValue(
                "validForType",
                service.isValidForAllServices
                    ? OFFER_VALID_FOR_TYPE_ALL_SERVICES
                    : OFFER_VALID_FOR_TYPE_SPECIFIC_SERVICES
            );
            setValue("validUntilType", service.validUntilType);

            if (
                service.validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE ||
                service.validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION
            ) {
                setValue("validUntilTimeUnit", service.validUntilTimeUnit);
            }
        }
    }, [service, setValue]);

    const validUntilType = watch("validUntilType");
    const validUntilTimeUnit = watch("validUntilTimeUnit");
    const fixedEndTime = watch("fixedEndTime");

    if (!validUntilTimeUnit) {
        register("validUntilTimeUnit");
        setValue("validUntilTimeUnit", "year");
    }

    return (
        <div>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_FOR" />
                </Form.Label>
                <Controller
                    control={control}
                    name="validForType"
                    defaultValue={OFFER_VALID_FOR_TYPE_ALL_SERVICES}
                    render={({ onChange, value }) => (
                        <>
                            <Col sm={4}>
                                <MaterialRadioDescriptionButton
                                    checked={value === OFFER_VALID_FOR_TYPE_ALL_SERVICES}
                                    value={OFFER_VALID_FOR_TYPE_ALL_SERVICES}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={"SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_FOR.ALL_SERVICES"}
                                    descriptionIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_FOR.ALL_SERVICES_DESCRIPTION"
                                    }
                                />
                            </Col>
                            <Col sm={4}>
                                <MaterialRadioDescriptionButton
                                    checked={value === OFFER_VALID_FOR_TYPE_SPECIFIC_SERVICES}
                                    value={OFFER_VALID_FOR_TYPE_SPECIFIC_SERVICES}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={"SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_FOR.SPECIFIC_SERVICES"}
                                    descriptionIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_FOR.SPECIFIC_SERVICES_DESCRIPTION"
                                    }
                                />
                            </Col>
                        </>
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.BALANCE" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-2"
                    name="balance"
                    ref={register}
                    isInvalid={errors.balance}
                    defaultValue={service?.balance}
                />
                <Form.Control.Feedback type="invalid">{errors.balance?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.BALANCE_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE" />
                </Form.Label>
                <Controller
                    control={control}
                    name="validUntilType"
                    defaultValue={OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE}
                    render={({ onChange, value }) => (
                        <>
                            <Col sm={3}>
                                <MaterialRadioDescriptionButton
                                    checked={value === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE}
                                    value={OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE.TIME_FROM_PURCHASE_FIXED"
                                    }
                                    descriptionIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE.TIME_FROM_PURCHASE_FIXED_DESCRIPTION"
                                    }
                                />
                            </Col>
                            <Col sm={3}>
                                <MaterialRadioDescriptionButton
                                    checked={value === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION}
                                    value={OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE.TIME_FROM_PURCHASE_UNTIL_CANCELLATION"
                                    }
                                    descriptionIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE.TIME_FROM_PURCHASE_UNTIL_CANCELLATION_DESCRIPTION"
                                    }
                                />
                            </Col>
                            <Col sm={3}>
                                <MaterialRadioDescriptionButton
                                    checked={value === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME}
                                    value={OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME}
                                    onChange={(e) => onChange(e)}
                                    titleIntlId={"SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE.FIXED_END_TIME"}
                                    descriptionIntlId={
                                        "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TYPE.FIXED_END_TIME_DESCRIPTION"
                                    }
                                />
                            </Col>
                        </>
                    )}
                />
            </Form.Group>
            {(validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE ||
                validUntilType === OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION) && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.VALUE" />
                    </Form.Label>
                    <Row noGutters>
                        <Col xl={2}>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg"
                                name="validUntilValue"
                                ref={register}
                                defaultValue={service?.validUntilValue}
                                isInvalid={errors.validUntilValue}
                            />
                        </Col>
                        <Col>
                            <Controller
                                control={control}
                                name="validUntilTimeUnit"
                                defaultValue={"year"}
                                render={({ name, onBlur, onChange, value }) => (
                                    <RadioGroup
                                        row
                                        onBlur={onBlur}
                                        onChange={(e) => onChange(e)}
                                        className={classes.validUntilTimeUnitContainer}
                                    >
                                        <FormControlLabel
                                            value={"year"}
                                            checked={validUntilTimeUnit === "year"}
                                            control={<Radio />}
                                            label={
                                                <FormattedMessage
                                                    id={"SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TIME_UNIT.YEAR"}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            value={"month"}
                                            checked={validUntilTimeUnit === "month"}
                                            control={<Radio />}
                                            label={
                                                <FormattedMessage
                                                    id={"SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.TIME_UNIT.MONTH"}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Col>
                    </Row>
                    {errors.validUntilValue?.message && (
                        <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                            {errors.validUntilValue?.message}
                        </div>
                    )}
                </Form.Group>
            )}
            {validUntilType === OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.FIXED_END_TIME" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name="fixedEndTime"
                        ref={register}
                        onClick={() => setFixedEndTimePickerOpen(true)}
                        defaultValue={
                            service?.fixedEndTime ? moment(service.fixedEndTime).format("YYYY-MM-DD HH:mm") : null
                        }
                        placeholder={intl.formatMessage({
                            id: "SERVICE.VOUCHER.BOOKING_SETTINGS.VALID_UNTIL.FIXED_END_TIME.PLACEHOLDER",
                        })}
                        isInvalid={errors.fixedEndTime}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fixedEndTime?.message}</Form.Control.Feedback>
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.ACTIVE_LIMIT" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-2"
                    name="activeLimit"
                    ref={register}
                    defaultValue={service?.activeLimit}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="SERVICE.VOUCHER.BOOKING_SETTINGS.ACTIVE_LIMIT_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    showTodayButton
                    ampm={false}
                    style={{ display: "none" }}
                    open={fixedEndTimePickerOpen}
                    onClose={() => setFixedEndTimePickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue(
                            "fixedEndTime",
                            selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                            {
                                shouldValidate: true,
                            }
                        );
                    }}
                    maxDate={fixedEndTime ? moment(fixedEndTime) : new Date("2100-01-01")}
                    clearable={true}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default connect(null, {})(VoucherBookingSettingsForm);
