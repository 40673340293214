import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as campaignActions } from "../../../../redux/campaigns/campaignRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import CampaignSMSPageNavigator from "./CampaignSMSPageNavigator";
import CampaignSMSPageDetails from "./CampaignSMSPageDetails";
import CampaignPageRecipientFilters from "../CampaignPageRecipientFilters";
import CampaignSMSPageRecipientsPreview from "./CampaignSMSPageRecipientsPreview";
import CampaignSMSPageRecipients from "./CampaignSMSPageRecipients";
import CampaignPublishPage from "../CampaignPublishPage";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function CampaignSMSPage({ match, getCampaign, clearCampaignState, clearDisplayError, clearErrorState }) {
    const campaignId = parseInt(match.params.id, 10);
    const { campaign, isLoading, isUpdating } = useSelector((state) => state.campaigns);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!campaign || campaign.id !== campaignId)) {
            getCampaign(campaignId);
        }
    }, [getCampaign, campaign, campaignId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearCampaignState();
            clearErrorState();
        };
    }, [clearCampaignState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <CampaignSMSPageNavigator campaignId={campaignId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/campaigns/${campaignId}/sms`}
                            exact={true}
                            to={`/campaigns/${campaignId}/sms/details`}
                        />
                        <Route path={`/campaigns/${campaignId}/sms/details`} component={CampaignSMSPageDetails} />

                        <Route
                            path={`/campaigns/${campaignId}/sms/recipient-filters`}
                            component={CampaignPageRecipientFilters}
                        />

                        <Route
                            path={`/campaigns/${campaignId}/sms/recipients-preview`}
                            component={CampaignSMSPageRecipientsPreview}
                        />

                        <Route path={`/campaigns/${campaignId}/sms/recipients`} component={CampaignSMSPageRecipients} />

                        <Route path={`/campaigns/${campaignId}/sms/publish`} component={CampaignPublishPage} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...campaignActions,
    ...errorActions,
})(CampaignSMSPage);
