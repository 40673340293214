import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Paper } from "@material-ui/core";
import {
    AvatarCell,
    DateTimeCell,
    TextCell,
    LinkCell,
    TranslationCell,
} from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import TransactionRefundListToolbar from "../../components/tables/toolbar/TransactionRefundListToolbar";
import { REFUND_TYPE_MANUAL, REFUND_TYPE_AUTOMATIC } from "./utils";
import SnackbarError from "../../components/SnackbarError";
import * as transactionUtils from "./utils";

function TransactionRefundsListPage({ getRefunds, clearDisplayError, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, refundsListPagination } = useSelector((state) => state.transactions);
    const { displayError } = useSelector((state) => state.errors);

    const [search, setSearch] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getRefunds(
                profile.id,
                pageIndex + 1,
                pageSize,
                search,
                startTime ? startTime.format() : "",
                endTime ? endTime.format() : ""
            );
        },
        [getRefunds, profile.id, search, startTime, endTime]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStartTimeChanged = useCallback((value) => {
        setStartTime(value);
    }, []);

    const onEndTimeChanged = useCallback((value) => {
        setEndTime(value);
    }, []);

    return (
        <Paper>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => refundsListPagination.data, [refundsListPagination.data])}
                fetchData={fetchData}
                rowCount={refundsListPagination.totalRows}
                isLoading={isLoading}
                renderToolbar={() => {
                    return (
                        <TransactionRefundListToolbar
                            onSearchChanged={onSearchChanged}
                            onStartTimeChanged={onStartTimeChanged}
                            onEndTimeChanged={onEndTimeChanged}
                        />
                    );
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.ID" />,
                            accessor: "id",
                            maxWidth: 75,
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.TRANSACTION_ID" />,
                            accessor: "transactionId",
                            maxWidth: 75,
                            Cell: (props) => {
                                return LinkCell("/transactions/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.CUSTOMER" />,
                            accessor: "customer",
                            maxWidth: 200,
                            Cell: (props) => {
                                return (
                                    <Link to={`/customers/${props.value.id}`}>
                                        {AvatarCell(
                                            props.value.avatarUrl,
                                            `${props.value.firstName} ${props.value.surname}`
                                        )}
                                    </Link>
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.AMOUNT" />,
                            accessor: "amount",
                            Cell: (props) => {
                                return TextCell(props.value + " " + props.row.original.currency);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.TRANSACTION_DAY" />,
                            accessor: "processedAt",
                            Cell: ({ row }) => {
                                return DateTimeCell(row.original.processedAt || row.original.createdAt);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.TYPE" />,
                            accessor: "type",
                            Cell: (props) => {
                                if (props.value === REFUND_TYPE_MANUAL) {
                                    return TranslationCell("REFUND.TYPE.MANUAL");
                                } else if (props.value === REFUND_TYPE_AUTOMATIC) {
                                    return TranslationCell("REFUND.TYPE.AUTOMATIC");
                                }
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="REFUND.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return transactionUtils.getTransactionRefundStatusCell(props.value);
                            },
                        },
                    ],
                    []
                )}
            />
        </Paper>
    );
}

export default connect(null, { ...transactionActions, ...errorActions })(TransactionRefundsListPage);
