import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as integrationActions } from "../../../redux/integrations/integrationRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import { Paper } from "@material-ui/core";
import { DateTimeCell, LinkCell } from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import IntegrationListToolbar from "../../components/tables/toolbar/IntegrationListToolbar";
import { getIntegrationStatusCell } from "./utils";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";

function IntegrationListPage({ getIntegrations, deleteIntegration, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.integrations);

    const [integrationToDelete, setIntegrationToDelete] = useState(null);

    const history = useHistory();

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getIntegrations(profile.id, pageIndex + 1, pageSize);
        },
        [getIntegrations, profile.id]
    );

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                renderToolbar={() => {
                    return <IntegrationListToolbar />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="INTEGRATION.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                const row = props.row;
                                return LinkCell(
                                    "/integrations/" + row.original.type + "/" + row.original.id,
                                    props.value
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="INTEGRATION.LIST.HEADER.INTEGRATION" />,
                            accessor: "name",
                            Cell: (props) => {
                                const row = props.row;
                                return LinkCell(
                                    "/integrations/" + row.original.type + "/" + row.original.id,
                                    props.value
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="INTEGRATION.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="INTEGRATION.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return getIntegrationStatusCell(props.row.original);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                return (
                                    <ListActionsButton
                                        menuItems={[
                                            {
                                                icon: <LinkIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.OPEN" />,
                                                onClick: () => {
                                                    const url =
                                                        "/integrations/" + row.original.type + "/" + row.original.id;
                                                    history.push(url);
                                                },
                                            },
                                            {
                                                icon: <DeleteOutlinedIcon fontSize="small" />,
                                                label: <FormattedMessage id="COMMON.DELETE" />,
                                                onClick: () => setIntegrationToDelete(row.original),
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ],
                    [history]
                )}
            />
            <YesNoDialog
                title={<FormattedMessage id="INTEGRATION.DELETE.TEXT" />}
                open={integrationToDelete ? true : false}
                onOpenChanged={() => setIntegrationToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteIntegration(integrationToDelete.id);
                    }
                    setIntegrationToDelete(null);
                }}
            />
        </Paper>
    );
}

export default connect(null, { ...integrationActions, ...errorActions })(IntegrationListPage);
