import React from "react";
import GoogleButton from "./GoogleButton";
import AppleSignInButton from "./AppleButton";
import FacebookButton from "./FacebookButton";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

export const ExternalLoginButtons = ({ onLoginSuccessful }) => {
    return (
        <div>
            <ExternalButtonsWrapper>
                <FacebookButton onLoginSuccessful={onLoginSuccessful} />
                <GoogleButton onLoginSuccessful={onLoginSuccessful} />
                <AppleSignInButton />
            </ExternalButtonsWrapper>
            <OuterWrapperDivider>
                <InnerWrapperDivider>
                    <Divider>
                        <DividerTitle>
                            <FormattedMessage id="AUTH.DIVIDER.OR" />
                        </DividerTitle>
                    </Divider>
                </InnerWrapperDivider>
            </OuterWrapperDivider>
        </div>
    );
};

const ExternalButtonsWrapper = styled.div`
    button {
        box-sizing: border-box !important;
        font-family: "Poppins" !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        max-height: 40px !important;
        width: 100%;
        border: 0 !important;
        border-radius: 4px !important;
        padding: 10px !important;
        display: flex !important;
        justify-content: center !important;
        margin-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        div {
            margin-right: 20px !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background: 0 !important;
        }
        svg {
            height: 20px !important;
            width: 20px !important;
        }
        img {
            height: 20px !important;
            width: 20px !important;
        }
    }
    button:hover {
        opacity: 0.8 !important;
    }
`;

const OuterWrapperDivider = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;
`;
const InnerWrapperDivider = styled.div`
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
`;
const DividerTitle = styled.strong`
    background: #fff;
    letter-spacing: 1px;
    padding: 0 20px;
    text-transform: uppercase;
`;
const Divider = styled.div`
    border-top: 1px solid #d9dadc;
    display: block;
    line-height: 1px;
    margin: 15px 0;
    position: relative;
    text-align: center;
`;
