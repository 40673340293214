import React, { useMemo, useCallback } from "react";
import { actions as payoutActions } from "../../../redux/payouts/payoutsRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell, LinkCell } from "../../components/tables/MaterialTableCells";
import * as transactionUtils from "../transactions/utils";

function PayoutPageTransactions({ getPayoutTransactions }) {
    const { payout, payoutTransactionsPagination, isLoading } = useSelector((state) => state.payouts);
    const { displayError } = useSelector((state) => state.errors);
    const payoutId = payout?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (payoutId) {
                getPayoutTransactions(payoutId, pageIndex + 1, pageSize);
            }
        },
        [payoutId, getPayoutTransactions]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYOUT.TRANSACTIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYOUT.TRANSACTIONS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={payoutId}
                        data={useMemo(() => payoutTransactionsPagination.data, [payoutTransactionsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={payoutTransactionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        var transaction = row.original.transaction;
                                        return LinkCell("/transactions/" + transaction.id, transaction.id);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_TYPE" />,
                                    accessor: "paymentType",
                                    Cell: ({ row }) => {
                                        return transactionUtils.getTransactionListPaymentTypeCell(
                                            row.original.transaction.paymentType
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.TRANSACTION_DAY" />,
                                    accessor: "paidAt",
                                    Cell: ({ row }) => {
                                        var transaction = row.original.transaction;
                                        return DateTimeCell(transaction.paidAt || transaction.createdAt);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.AMOUNT" />,
                                    accessor: "amount",
                                    Cell: ({ row }) => {
                                        var transaction = row.original.transaction;
                                        return TextCell(transaction.amount + " " + transaction.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.REFUNDED_AMOUNT" />,
                                    accessor: "refundedAmount",
                                    Cell: ({ row }) => {
                                        return TextCell(
                                            row.original.refundedAmount + " " + row.original.transaction.currency
                                        );
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...payoutActions, ...errorActions })(PayoutPageTransactions);
