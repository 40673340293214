import React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FormattedMessage } from "react-intl";

export const DefaultAsyncTypeaheadMultiple = ({
    id,
    labelKey,
    options,
    selected,
    placeholder,
    emptyLabel,
    onChange,
    onSearch,
    renderMenuItemChildren,
    isLoading = false,
    minLength = 0,
    clearButton = true,
    disabled = false,
    isInvalid = false,
    invalidMessage,
}) => {
    return (
        <div>
            <AsyncTypeahead
                id={id}
                multiple
                labelKey={labelKey || "name"}
                minLength={minLength}
                clearButton={clearButton}
                onChange={onChange}
                onSearch={onSearch}
                onInputChange={(input) => {
                    if (!input) {
                        onSearch(input);
                    }
                }}
                useCache={false}
                isLoading={isLoading}
                options={options}
                placeholder={placeholder}
                emptyLabel={emptyLabel}
                renderMenuItemChildren={renderMenuItemChildren}
                selected={selected ? selected : []}
                filterBy={() => true}
                inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                disabled={disabled}
                isInvalid={isInvalid}
            />
            {isInvalid && (
                <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                    {invalidMessage || <FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />}
                </div>
            )}
        </div>
    );
};

export default DefaultAsyncTypeaheadMultiple;
