import { put, takeLatest } from "redux-saga/effects";
import {
    getVoucher,
    patchVoucher,
    deleteVoucher,
    getVoucherTransactions,
    getMembership,
    patchMembership,
    deleteMembership,
    getMembershipTransactions,
} from "./customerOffersCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_CUSTOMEROFFER_VOUCHER_REQUEST: "GET_CUSTOMEROFFER_VOUCHER_REQUEST",
    GET_CUSTOMEROFFER_VOUCHER_SUCCESS: "GET_CUSTOMEROFFER_VOUCHER_SUCCESS",
    UPDATE_CUSTOMEROFFER_VOUCHER_REQUEST: "UPDATE_CUSTOMEROFFER_VOUCHER_REQUEST",
    UPDATE_CUSTOMEROFFER_VOUCHER_SUCCESS: "UPDATE_CUSTOMEROFFER_VOUCHER_SUCCESS",
    DELETE_CUSTOMEROFFER_VOUCHER_REQUEST: "DELETE_CUSTOMEROFFER_VOUCHER_REQUEST",
    DELETE_CUSTOMEROFFER_VOUCHER_SUCCESS: "DELETE_CUSTOMEROFFER_VOUCHER_SUCCESS",
    GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_REQUEST: "GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_REQUEST",
    GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_SUCCESS: "GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_SUCCESS",
    GET_CUSTOMEROFFER_MEMBERSHIP_REQUEST: "GET_CUSTOMEROFFER_MEMBERSHIP_REQUEST",
    GET_CUSTOMEROFFER_MEMBERSHIP_SUCCESS: "GET_CUSTOMEROFFER_MEMBERSHIP_SUCCESS",
    UPDATE_CUSTOMEROFFER_MEMBERSHIP_REQUEST: "UPDATE_CUSTOMEROFFER_MEMBERSHIP_REQUEST",
    UPDATE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS: "UPDATE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS",
    DELETE_CUSTOMEROFFER_MEMBERSHIP_REQUEST: "DELETE_CUSTOMEROFFER_MEMBERSHIP_REQUEST",
    DELETE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS: "DELETE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS",
    GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_REQUEST: "GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_REQUEST",
    GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_SUCCESS: "GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_SUCCESS",
    CLEAR_CUSTOMER_OFFERS_STATE: "CLEAR_CUSTOMER_OFFERS_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    voucherTransactionsPagination: {
        data: [],
        totalRows: 0,
    },
    membershipTransactionsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMEROFFER_VOUCHER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMEROFFER_VOUCHER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucher: action.payload.response,
            };
        }

        case actionTypes.UPDATE_CUSTOMEROFFER_VOUCHER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_CUSTOMEROFFER_VOUCHER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucher: action.payload.response,
            };
        }

        case actionTypes.DELETE_CUSTOMEROFFER_VOUCHER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_CUSTOMEROFFER_VOUCHER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucher: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                voucherTransactionsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membership: action.payload.response,
            };
        }

        case actionTypes.UPDATE_CUSTOMEROFFER_MEMBERSHIP_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membership: action.payload.response,
            };
        }

        case actionTypes.DELETE_CUSTOMEROFFER_MEMBERSHIP_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membership: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                membershipTransactionsPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_CUSTOMER_OFFERS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getVoucher: (id) => ({
        type: actionTypes.GET_CUSTOMEROFFER_VOUCHER_REQUEST,
        payload: { id },
    }),

    updateVoucher: (id, originalVoucher, changedVoucher) => ({
        type: actionTypes.UPDATE_CUSTOMEROFFER_VOUCHER_REQUEST,
        payload: { id, originalVoucher, changedVoucher },
    }),

    deleteVoucher: (id) => ({
        type: actionTypes.DELETE_CUSTOMEROFFER_VOUCHER_REQUEST,
        payload: { id },
    }),

    getVoucherTransactions: (id) => ({
        type: actionTypes.GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_REQUEST,
        payload: { id },
    }),

    getMembership: (id) => ({
        type: actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_REQUEST,
        payload: { id },
    }),

    updateMembership: (id, originalMembership, changedMembership) => ({
        type: actionTypes.UPDATE_CUSTOMEROFFER_MEMBERSHIP_REQUEST,
        payload: { id, originalMembership, changedMembership },
    }),

    deleteMembership: (id) => ({
        type: actionTypes.DELETE_CUSTOMEROFFER_MEMBERSHIP_REQUEST,
        payload: { id },
    }),

    getMembershipTransactions: (id) => ({
        type: actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_REQUEST,
        payload: { id },
    }),

    clearCustomerOffersState: () => ({
        type: actionTypes.CLEAR_CUSTOMER_OFFERS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_CUSTOMEROFFER_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getVoucher(payload.id);

            yield put({
                type: actionTypes.GET_CUSTOMEROFFER_VOUCHER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_CUSTOMEROFFER_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchVoucher(payload.id, payload.originalVoucher, {
                ...payload.originalVoucher,
                ...payload.changedVoucher,
            });
            yield put({
                type: actionTypes.UPDATE_CUSTOMEROFFER_VOUCHER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_CUSTOMEROFFER_VOUCHER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteVoucher(payload.id);

            yield put({
                type: actionTypes.DELETE_CUSTOMEROFFER_VOUCHER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getVoucherTransactions(payload.id);

            yield put({
                type: actionTypes.GET_CUSTOMEROFFER_VOUCHER_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getMembership(payload.id);

            yield put({
                type: actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_CUSTOMEROFFER_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchMembership(payload.id, payload.originalMembership, {
                ...payload.originalMembership,
                ...payload.changedMembership,
            });
            yield put({
                type: actionTypes.UPDATE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_CUSTOMEROFFER_MEMBERSHIP_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteMembership(payload.id);

            yield put({
                type: actionTypes.DELETE_CUSTOMEROFFER_MEMBERSHIP_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getMembershipTransactions(payload.id);

            yield put({
                type: actionTypes.GET_CUSTOMEROFFER_MEMBERSHIP_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
