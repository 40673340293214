import React from "react";
import { useIntl } from "react-intl";
import DefaultAsyncTypeahead from "../../components/DefaultAsyncTypeahead";

export const MODE_DONT_ASK = "dontAsk";
export const MODE_OPTIONAL = "optional";
export const MODE_MANDATORY = "mandatory";

export function ModeSettingDropdownTypeahead({ id, onChange, selected, includeDefaultGlobal }) {
    const intl = useIntl();

    return (
        <DefaultAsyncTypeahead
            id={id}
            onChange={onChange}
            onSearch={() => {}}
            options={getModeSettingsOptions(includeDefaultGlobal, intl)}
            selected={selected}
            filterBy={() => true}
            clearButton={false}
        />
    );
}

export function getModeSettingsOptions(includeDefaultGlobal, intl) {
    const options = [
        {
            id: MODE_DONT_ASK,
            name: intl.formatMessage({
                id: "COMMON.MODE.DONT_ASK",
            }),
        },
        {
            id: MODE_OPTIONAL,
            name: intl.formatMessage({
                id: "COMMON.MODE.OPTIONAL",
            }),
        },
        {
            id: MODE_MANDATORY,
            name: intl.formatMessage({
                id: "COMMON.MODE.MANDATORY",
            }),
        },
    ];

    if (includeDefaultGlobal) {
        options.unshift({
            id: "",
            name: intl.formatMessage({
                id: "COMMON.MODE.GLOBAL_SETTING",
            }),
        });
    }

    return options;
}

export function findModeSettingsOption(id, includeDefaultGlobal, intl) {
    const options = getModeSettingsOptions(includeDefaultGlobal, intl);
    return options.find((x) => x.id === id);
}
