import React, { useEffect, useState } from "react";
import { Form, Col, InputGroup, FormControl } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { getPromotionCodeTypeOptions, getCheckoutItemRulesTypeOptions } from "./utils";
import { Switch } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Alert } from "@material-ui/lab";

function PromotionCodeForm({ register, setValue, control, errors, showDescription, promotionCode }) {
    const intl = useIntl();

    const [isValidFromDatePickerOpen, setIsValidFromDatePickerOpen] = useState(false);
    const [isValidToDatePickerOpen, setIsValidToDatePickerOpen] = useState(false);
    const [bookingFromDatePickerOpen, setBookingFromDatePickerOpen] = useState(false);
    const [bookingToDatePickerOpen, setBookingToDatePickerOpen] = useState(false);

    useEffect(() => {
        if (promotionCode) {
            setValue("type", promotionCode.type);
            setValue("isAllServices", promotionCode.isAllServices);
            setValue("isActiveBookingRequired", promotionCode.isActiveBookingRequired);
            setValue("checkoutItemsRule", promotionCode.checkoutItemsRule);
        }
    }, [promotionCode, setValue]);

    return (
        <div>
            {showDescription && (
                <Form.Group>
                    <Alert severity="info">
                        <FormattedMessage
                            id="PROMOTIONCODE.CREATE.DESCRIPTION"
                            values={{
                                p: (...chunks) => <p>{chunks}</p>,
                                strong: (...chunks) => <strong>{chunks}</strong>,
                                br: <br />,
                            }}
                        />
                    </Alert>
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.CODE" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="code"
                    ref={register}
                    isInvalid={errors.code}
                    defaultValue={promotionCode?.code}
                />
                <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} xl={3}>
                    <Form.Label>
                        <FormattedMessage id="PROMOTIONCODE.DETAILS.TYPE" />
                    </Form.Label>
                    <Controller
                        name="type"
                        className="form-control form-control-lg"
                        control={control}
                        defaultValue={promotionCode?.type || "discountPercentage"}
                        render={({ onChange, value }) => {
                            return (
                                <Form.Control
                                    as="select"
                                    className="form-control form-control-lg"
                                    value={value}
                                    onChange={(val) => onChange(val.target.value)}
                                >
                                    {getPromotionCodeTypeOptions(intl).map((x) => {
                                        return (
                                            <option key={x.key} value={x.key}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            );
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.property?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xl={2}>
                    <Form.Label>
                        <FormattedMessage id="PROMOTIONCODE.DETAILS.VALUE" />
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control form-control-lg"
                        name="value"
                        ref={register}
                        isInvalid={errors.value}
                        defaultValue={promotionCode?.value}
                    />
                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.VALID_BETWEEN" />
                </Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={"validFrom"}
                        ref={register}
                        onClick={() => setIsValidFromDatePickerOpen(true)}
                        isInvalid={errors.validFrom}
                        defaultValue={
                            promotionCode?.validFrom ? moment(promotionCode.validFrom).format("YYYY-MM-DD HH:mm") : null
                        }
                    />
                    <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                        <InputGroup.Text id="basic-addon1">
                            <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={"validTo"}
                        ref={register}
                        onClick={() => setIsValidToDatePickerOpen(true)}
                        isInvalid={errors.validFrom}
                        style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                        defaultValue={
                            promotionCode?.validTo ? moment(promotionCode.validTo).format("YYYY-MM-DD HH:mm") : null
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />
                    </Form.Control.Feedback>
                </InputGroup>
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.VALID_BETWEEN_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.BOOKING_BETWEEN" />
                </Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={"bookingFrom"}
                        ref={register}
                        onClick={() => setBookingFromDatePickerOpen(true)}
                        isInvalid={errors.bookingFrom}
                        defaultValue={
                            promotionCode?.bookingFrom
                                ? moment(promotionCode.bookingFrom).format("YYYY-MM-DD HH:mm")
                                : null
                        }
                    />
                    <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                        <InputGroup.Text id="basic-addon1">
                            <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={"bookingTo"}
                        ref={register}
                        onClick={() => setBookingToDatePickerOpen(true)}
                        isInvalid={errors.bookingFrom}
                        style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                        defaultValue={
                            promotionCode?.bookingTo ? moment(promotionCode.bookingTo).format("YYYY-MM-DD HH:mm") : null
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />
                    </Form.Control.Feedback>
                </InputGroup>
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.BOOKING_BETWEEN_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.MAX_USAGES_PER_CUSTOMER" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-4"
                    name="maxUsagesPerCustomer"
                    ref={register}
                    isInvalid={errors.maxUsagesPerCustomer}
                    defaultValue={promotionCode?.maxUsagesPerCustomer}
                />
                <Form.Control.Feedback type="invalid">{errors.maxUsagesPerCustomer?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.MAX_USAGES_PER_CUSTOMER_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.MAX_USAGES" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-4"
                    name="maxUsages"
                    ref={register}
                    isInvalid={errors.maxUsages}
                    defaultValue={promotionCode?.maxUsages}
                />
                <Form.Control.Feedback type="invalid">{errors.maxUsages?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.MAX_USAGES_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.SUBSCRIPTION_TRANSACTIONS" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-4"
                    name="subscriptionTransactionsCount"
                    ref={register}
                    isInvalid={errors.subscriptionTransactionsCount}
                    defaultValue={promotionCode?.subscriptionTransactionsCount}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.subscriptionTransactionsCount?.message}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.SUBSCRIPTION_TRANSACTIONS_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.IS_ALL_SERVICES" />
                </Form.Label>
                <Controller
                    name="isAllServices"
                    control={control}
                    defaultValue={false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.IS_ALL_SERVICES_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.IS_ACTIVE_BOOKING_REQUIRED" />
                </Form.Label>
                <Controller
                    name="isActiveBookingRequired"
                    control={control}
                    defaultValue={false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.IS_ACTIVE_BOOKING_REQUIRED_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.CHECKOUT_ITEM_RULE" />
                </Form.Label>
                <Controller
                    name="checkoutItemsRule"
                    className="form-control form-control-lg col-xl-4"
                    control={control}
                    defaultValue={promotionCode?.checkoutItemRule || ""}
                    render={({ onChange, value }) => {
                        return (
                            <Form.Control
                                as="select"
                                className="form-control form-control-lg col-xl-4"
                                value={value}
                                onChange={(val) => onChange(val.target.value)}
                            >
                                {getCheckoutItemRulesTypeOptions(intl).map((x) => {
                                    return (
                                        <option key={x.key} value={x.key}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        );
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="PROMOTIONCODE.DETAILS.CHECKOUT_ITEM_RULE_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast={false}
                        clearable={true}
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={isValidFromDatePickerOpen}
                        onClose={() => setIsValidFromDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                "validFrom",
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null
                            );
                        }}
                    />
                    <DateTimePicker
                        disablePast={false}
                        clearable={true}
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={isValidToDatePickerOpen}
                        onClose={() => setIsValidToDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                "validTo",
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null
                            );
                        }}
                    />
                    <DateTimePicker
                        disablePast={false}
                        clearable={true}
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={bookingFromDatePickerOpen}
                        onClose={() => setBookingFromDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                "bookingFrom",
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null
                            );
                        }}
                    />
                    <DateTimePicker
                        disablePast={false}
                        clearable={true}
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={bookingToDatePickerOpen}
                        onClose={() => setBookingToDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                "bookingTo",
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null
                            );
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}

export default PromotionCodeForm;
