import React from "react";
import { connect, useSelector } from "react-redux";
import { actions as dormakabaActions } from "../../../../redux/integrations/dormakaba/dormakabaRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    apiKey: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    apiSecret: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    siteId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function DormakabaCreatePage({ createDormakabaIntegration }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, redirectToDetails } = useSelector((state) => state.dormakaba);
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

    const onFormSubmit = (values) => {
        createDormakabaIntegration(profile.id, values.apiKey, values.apiSecret, values.siteId);
    };

    if (redirectToDetails) {
        return <Redirect to={"/integrations/dormakaba/" + integration.id} />;
    }

    return (
        <PageBackdrop isLoading={isLoading}>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.DORMAKABA.CREATE.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.DORMAKABA.CREATE.DESCRIPTION" />
                    <a href="https://www.dormakaba.com/">www.dormakaba.com</a>
                </Typography>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.DORMAKABA.CREATE.API_KEY" />
                        </Form.Label>
                        <Form.Control name="apiKey" ref={register} isInvalid={errors.apiKey} />
                        <Form.Control.Feedback type="invalid">
                            {errors.apiKey?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.DORMAKABA.CREATE.API_SECRET" />
                        </Form.Label>
                        <Form.Control
                            name="apiSecret"
                            ref={register}
                            isInvalid={errors.apiSecret}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.apiSecret?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>
                            <FormattedMessage id="INTEGRATION.DORMAKABA.CREATE.SITE_ID" />
                        </Form.Label>
                        <Form.Control name="siteId" ref={register} isInvalid={errors.siteId} />
                        <Form.Control.Feedback type="invalid">
                            {errors.siteId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        <FormattedMessage id="COMMON.ADD" />
                    </Button>
                </Form>
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, dormakabaActions)(DormakabaCreatePage);
