import React, { useEffect, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper } from "@material-ui/core";
import { TextCell, DateTimeCell, LinkCell, DeleteActionButton } from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import ResourceGroupListToolbar from "../../components/tables/toolbar/ResourceGroupListToolbar";

function ResourceGroupPageList({ getResourceGroups, deleteResourceGroup, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, groupsListPagination } = useSelector((state) => state.resources);
    const { displayError } = useSelector((state) => state.errors);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getResourceGroups(profile.id, pageIndex + 1, pageSize);
        },
        [getResourceGroups, profile.id]
    );

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => groupsListPagination.data, [groupsListPagination.data])}
                fetchData={fetchData}
                rowCount={groupsListPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return <ResourceGroupListToolbar />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="RESOURCE.GROUP.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                return LinkCell("/resource-groups/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="RESOURCE.GROUP.LIST.HEADER.NAME" />,
                            accessor: "name",
                            Cell: (props) => {
                                return LinkCell("/resource-groups/" + props.row.original.id, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="RESOURCE.GROUP.LIST.HEADER.RESOURCE_COUNT" />,
                            accessor: "resourceCount",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="RESOURCE.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "delete",
                            Cell: ({ row: { original: resourceGroup } }) => {
                                const intl = useIntl();
                                if (!resourceGroup.removedAt) {
                                    const title = `${intl.formatMessage({
                                        id: "RESOURCE.GROUP.DELETE.TITLE",
                                    })} 
                                ${resourceGroup.id} `;
                                    return DeleteActionButton(
                                        title,
                                        <FormattedMessage id="RESOURCE.GROUP.DELETE.TEXT" />,
                                        () => deleteResourceGroup(resourceGroup.id)
                                    );
                                }
                                return <></>;
                            },
                        },
                    ],
                    [deleteResourceGroup]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
        </Paper>
    );
}

export default connect(null, { ...resourceActions, ...errorActions })(ResourceGroupPageList);
