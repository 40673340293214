import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Switch, FormControlLabel } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, InputGroup, FormControl } from "react-bootstrap";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/sv";
import MomentUtils from "@date-io/moment";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    descriptionTextContainer: {
        marginBottom: theme.spacing(4),
    },
    formContent: {},
}));

function areStartAndEndTimesValid(startTime, endTime) {
    if (!startTime || !endTime) return false;
    return startTime.isBefore(endTime);
}

function getNearestDefaultStartTime() {
    return moment().add(1, "hour").startOf("hour");
}

function getNearestDefaultEndTime(startTime) {
    if (!startTime) return moment();
    return moment(startTime).add(1, "hour").startOf("hour");
}

function getResultInfoText(startTime, endTime, isRepeating, repeatUntil) {
    if (!areStartAndEndTimesValid(startTime, endTime)) return <></>;

    if (!isRepeating || !repeatUntil) {
        return (
            <Alert severity="info">
                <FormattedMessage
                    id="SERVICE.COURSE.OCCASIONS.ADD_MODAL.RESULT_INFO_SINGLE"
                    values={{
                        p: (...chunks) => <p>{chunks}</p>,
                        strong: (...chunks) => <strong>{chunks}</strong>,
                        br: <br />,
                        dayName: startTime.format("dddd"),
                        startTime: startTime.format("YYYY-MM-DD HH:mm"),
                        endTime: endTime.format("HH:mm"),
                    }}
                />
            </Alert>
        );
    }

    const weeksBetween = repeatUntil.diff(endTime, "week") + 1;
    return (
        <Alert severity="info">
            <FormattedMessage
                id="SERVICE.COURSE.OCCASIONS.ADD_MODAL.RESULT_INFO_REPEAT"
                values={{
                    p: (...chunks) => <p>{chunks}</p>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    br: <br />,
                    count: weeksBetween,
                    dayName: startTime.format("dddd"),
                    startTime: startTime.format("HH:mm"),
                    endTime: endTime.format("HH:mm"),
                    repeatFrom: startTime.format("YYYY-MM-DD"),
                    repeatUntil: repeatUntil.format("YYYY-MM-DD"),
                }}
            />
        </Alert>
    );
}

function CourseOccasionAddModal({ onOccasionAdded, onCloseClicked, show, isLoading }) {
    const classes = useStyles();
    const intl = useIntl();

    const [showErrors, setShowErrors] = useState(false);
    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
    const [pickedStartTime, setPickedStartTime] = useState(null);
    const [pickedEndTime, setPickedEndTime] = useState(null);
    const [isRepeating, setIsRepeating] = useState(null);
    const [isRepeatUntilPickerOpen, setIsRepeatUntilPickerOpen] = useState(false);
    const [pickedRepeatUntilTime, setPickedRepeatUntilTime] = useState(null);

    const onSaveClicked = () => {
        if (!areStartAndEndTimesValid(pickedStartTime, pickedEndTime)) {
            setShowErrors(true);
            return;
        }

        let occasionData = {
            startTime: pickedStartTime,
            endTime: pickedEndTime,
        };

        if (isRepeating && pickedRepeatUntilTime) {
            occasionData.repeatUntil = pickedRepeatUntilTime;
        }

        onOccasionAdded(occasionData);
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="SERVICE.COURSE.OCCASIONS.ADD_MODAL.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.formContent}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.COURSE.OCCASIONS.ADD_MODAL.CHOOSE_TIME" />
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={intl.formatMessage({
                                    id: "SERVICE.COURSE.OCCASIONS.ADD_MODAL.START_TIME_PLACEHOLDER",
                                })}
                                aria-describedby="basic-addon1"
                                onClick={() => setIsStartTimePickerOpen(true)}
                                readOnly={true}
                                value={pickedStartTime?.format("YYYY-MM-DD HH:mm")}
                                isInvalid={showErrors && !areStartAndEndTimesValid(pickedStartTime, pickedEndTime)}
                            />
                            <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder={intl.formatMessage({
                                    id: "SERVICE.COURSE.OCCASIONS.ADD_MODAL.END_TIME_PLACEHOLDER",
                                })}
                                aria-describedby="basic-addon1"
                                onClick={() => setIsEndTimePickerOpen(true)}
                                readOnly={true}
                                value={pickedEndTime?.format("YYYY-MM-DD HH:mm")}
                                isInvalid={showErrors && !areStartAndEndTimesValid(pickedStartTime, pickedEndTime)}
                                style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        {areStartAndEndTimesValid(pickedStartTime, pickedEndTime) && (
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label={<FormattedMessage id="SERVICE.COURSE.OCCASIONS.ADD_MODAL.REPEAT" />}
                                labelPlacement="end"
                                checked={isRepeating}
                                onChange={(event, value) => setIsRepeating(value)}
                                style={{ userSelect: "none" }}
                            />
                        )}
                        {isRepeating && (
                            <FormControl
                                placeholder={intl.formatMessage({
                                    id: "SERVICE.COURSE.OCCASIONS.ADD_MODAL.REPEAT_TIME_PLACEHOLDER",
                                })}
                                aria-describedby="basic-addon1"
                                onClick={() => setIsRepeatUntilPickerOpen(true)}
                                readOnly={true}
                                value={pickedRepeatUntilTime?.format("YYYY-MM-DD")}
                                style={{ width: "250px", display: "inline-block" }}
                            />
                        )}
                    </Form.Group>
                    <Form.Group>
                        {getResultInfoText(pickedStartTime, pickedEndTime, isRepeating, pickedRepeatUntilTime)}
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={() => onSaveClicked()}
                    label={<FormattedMessage id="COMMON.SAVE" />}
                />
            </Modal.Footer>

            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={isStartTimePickerOpen}
                        onClose={() => setIsStartTimePickerOpen(false)}
                        onChange={(startTime) => {
                            setPickedStartTime(startTime);
                            setShowErrors(pickedEndTime && startTime.isAfter(pickedEndTime));
                        }}
                        value={pickedStartTime || getNearestDefaultStartTime()}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast
                        showTodayButton
                        ampm={false}
                        open={isEndTimePickerOpen}
                        onClose={() => setIsEndTimePickerOpen(false)}
                        onChange={(endTime) => {
                            setPickedEndTime(endTime);
                            setShowErrors(pickedStartTime && endTime.isBefore(pickedStartTime));
                        }}
                        value={pickedEndTime || getNearestDefaultEndTime(pickedStartTime)}
                        minDate={pickedStartTime ? pickedStartTime : new Date("2022-01-01")}
                    />
                </MuiPickersUtilsProvider>
                {pickedEndTime && (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                            disablePast
                            showTodayButton
                            ampm={false}
                            open={isRepeatUntilPickerOpen}
                            onClose={() => setIsRepeatUntilPickerOpen(false)}
                            onChange={(repeatUntil) => {
                                repeatUntil.set("hour", pickedEndTime.hour());
                                repeatUntil.set("minute", pickedEndTime.minute());
                                repeatUntil.set("second", 0);
                                setPickedRepeatUntilTime(repeatUntil);
                            }}
                            value={pickedRepeatUntilTime}
                            shouldDisableDate={(date) => {
                                return date.isBefore(pickedEndTime) || date.day() !== pickedEndTime.day();
                            }}
                        />
                    </MuiPickersUtilsProvider>
                )}
            </div>
        </Modal>
    );
}

export default connect(null, serviceActions)(CourseOccasionAddModal);
