import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles, Avatar, Switch } from "@material-ui/core";
import { useFormContext, Controller } from "react-hook-form";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { Form, Row, Col } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import MaterialRadioDescriptionButton from "../../../components/inputs/MaterialRadioDescriptionButton";
import {
    APPOINTMENT_DURATION_TYPE_FIXED,
    APPOINTMENT_DURATION_TYPE_FLEXIBLE,
    APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION,
    APPOINTMENT_DURATION_TYPE_DAYS,
} from "../utils";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, TimePicker } from "@material-ui/pickers";
import { getResourcesAndGroupsOptions } from "../../resources/utils";
import GroupIcon from "@material-ui/icons/Group";
import { Typeahead } from "react-bootstrap-typeahead";

const useStyles = makeStyles((theme) => ({
    listItemResourceContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemResourceAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
    multipleOptionsRb: {
        marginTop: "4px",
        marginBottom: "4px",
    },
}));

function getReminderTemplateOption(reminderTemplate) {
    return { id: reminderTemplate.id, name: reminderTemplate.name };
}

function getCancellationTemplateOption(cancellationTemplate) {
    return { id: cancellationTemplate.id, name: cancellationTemplate.name };
}

function AppointmentBookingSettingsForm({ serviceOptions, getResourcesAndGroups, service, showResources = true }) {
    const classes = useStyles();
    const intl = useIntl();

    const { register, control, setValue, watch, errors } = useFormContext();

    const { profile } = useSelector((state) => state.auth);
    const { resourcesAndGroupsListPagination, isLoading: isResourcesLoading } = useSelector((state) => state.resources);

    const profileId = profile.id;

    const [fixedEndTimePickerOpen, setFixedEndTimePickerOpen] = useState(false);
    const [timePickerFieldName, setTimePickerFieldName] = useState(null);

    useEffect(() => {
        if (showResources) {
            getResourcesAndGroups(profileId, 1, 20);
        }
    }, [profileId, getResourcesAndGroups, showResources]);

    useEffect(() => {
        if (service) {
            setValue("durationType", service.durationType);
            setValue("isOvernightBookingsAllowed", service.isOvernightBookingsAllowed);
            setValue("isShowStartedSlot", service.isShowStartedSlot);

            if (service.reminderTemplate) {
                setValue("reminderTemplate", getReminderTemplateOption(service.reminderTemplate));
            }

            if (service.cancellationTemplate) {
                setValue("cancellationTemplate", getCancellationTemplateOption(service.cancellationTemplate));
            }
        }
    }, [service, setValue]);

    const onResourceSearch = (search) => {
        getResourcesAndGroups(profileId, 1, 20, search);
    };

    const onResourceChanged = (resources) => {
        setValue("resources", resources, { shouldValidate: true });
    };

    const resources = watch("resources");
    const durationType = watch("durationType");
    const fixedEndTime = watch("fixedEndTime");

    // Add default null option first on cancellation templates since its optional
    let allReminderTemplates = serviceOptions?.reminderTemplates ? [...serviceOptions.reminderTemplates] : [];
    allReminderTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "SERVICE.REMINDER_TEMPLATE.DEFAULT_OPTION",
        }),
    });

    const reminderTemplate = watch("reminderTemplate");
    if (!reminderTemplate) {
        register("reminderTemplate");
        setValue("reminderTemplate", getReminderTemplateOption(allReminderTemplates[0]), {
            shouldValidate: true,
        });
    }

    // Add default null option first on cancellation templates since its optional
    let allCancellationTemplates = serviceOptions?.cancellationTemplates
        ? [...serviceOptions.cancellationTemplates]
        : [];
    allCancellationTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "SERVICE.CANCELLATION_TEMPLATE.DEFAULT_OPTION",
        }),
    });

    const cancellationTemplate = watch("cancellationTemplate");
    if (!cancellationTemplate) {
        register("cancellationTemplate");
        setValue("cancellationTemplate", getCancellationTemplateOption(allCancellationTemplates[0]), {
            shouldValidate: true,
        });
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE" />
                </Form.Label>
                <Controller
                    control={control}
                    name="durationType"
                    defaultValue={APPOINTMENT_DURATION_TYPE_FIXED}
                    render={({ onChange, value }) => (
                        <>
                            <Row noGutters>
                                <Col sm={3} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === APPOINTMENT_DURATION_TYPE_FIXED}
                                        value={APPOINTMENT_DURATION_TYPE_FIXED}
                                        onChange={(e) => onChange(e)}
                                        titleIntlId={"SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.FIXED"}
                                        descriptionIntlId={
                                            "SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.FIXED_DESCRIPTION"
                                        }
                                    />
                                </Col>
                                <Col sm={3} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === APPOINTMENT_DURATION_TYPE_FLEXIBLE}
                                        value={APPOINTMENT_DURATION_TYPE_FLEXIBLE}
                                        onChange={(e) => onChange(e)}
                                        titleIntlId={"SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.FLEXIBLE"}
                                        descriptionIntlId={
                                            "SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.FLEXIBLE_DESCRIPTION"
                                        }
                                    />
                                </Col>
                                <Col sm={3} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION}
                                        value={APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION}
                                        onChange={(e) => onChange(e)}
                                        titleIntlId={
                                            "SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.UNTIL_CANCELLATION"
                                        }
                                        descriptionIntlId={
                                            "SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.UNTIL_CANCELLATION_DESCRIPTION"
                                        }
                                    />
                                </Col>
                                <Col sm={3} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === APPOINTMENT_DURATION_TYPE_DAYS}
                                        value={APPOINTMENT_DURATION_TYPE_DAYS}
                                        onChange={(e) => onChange(e)}
                                        titleIntlId={"SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.DAYS"}
                                        descriptionIntlId={
                                            "SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_TYPE.DAYS_DESCRIPTION"
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                />
            </Form.Group>

            {showResources && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.RESOURCES" />
                    </Form.Label>
                    <Row noGutters>
                        <Col xl={6}>
                            <Controller
                                control={control}
                                name="resources"
                                defaultValue={[]}
                                render={() => (
                                    <div>
                                        <AsyncTypeahead
                                            id="typeahead-course-resources"
                                            labelKey={"name"}
                                            minLength={0}
                                            onChange={onResourceChanged}
                                            onSearch={onResourceSearch}
                                            onInputChange={(input) => {
                                                if (!input) {
                                                    onResourceSearch(input);
                                                }
                                            }}
                                            useCache={false}
                                            isLoading={isResourcesLoading}
                                            options={getResourcesAndGroupsOptions(resourcesAndGroupsListPagination)}
                                            placeholder={intl.formatMessage({
                                                id: "SERVICE.APPOINTMENT.BOOKING_SETTINGS.RESOURCES.PLACEHOLDER",
                                            })}
                                            emptyLabel={intl.formatMessage({
                                                id: "SERVICE.APPOINTMENT.BOOKING_SETTINGS.RESOURCES.SEARCH_EMPTY",
                                            })}
                                            renderMenuItemChildren={(option, props) => (
                                                <div className={classes.listItemResourceContainer}>
                                                    {!option.isGroup && (
                                                        <>
                                                            <Avatar
                                                                alt={option.name}
                                                                src={option.avatarUrl}
                                                                className={classes.listItemResourceAvatar}
                                                            />
                                                            <span>{option.name}</span>
                                                        </>
                                                    )}
                                                    {option.isGroup && (
                                                        <>
                                                            <Avatar
                                                                alt={option.name}
                                                                src={option.avatarUrl}
                                                                className={classes.listItemResourceAvatar}
                                                            >
                                                                <GroupIcon />
                                                            </Avatar>
                                                            <span>
                                                                <FormattedMessage
                                                                    id="RESOURCE_AND_GROUP.LIST.GROUP.IDENTIFIER"
                                                                    values={{ name: option.name }}
                                                                />
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            selected={resources?.length ? resources : []}
                                            multiple
                                            isInvalid={!!errors.resources}
                                        />
                                        {errors.resources?.message && (
                                            <div
                                                className="text-danger"
                                                style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}
                                            >
                                                {errors.resources.message}
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            )}
            {durationType === APPOINTMENT_DURATION_TYPE_FIXED && (
                <>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION" />
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control form-control-lg col-xl-2"
                            name="duration"
                            ref={register}
                            defaultValue={service?.duration}
                            isInvalid={errors.duration}
                        />
                        <Form.Control.Feedback type="invalid">{errors.duration?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_EXTENDED" />
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control form-control-lg col-xl-2"
                            name="extendedDuration"
                            ref={register}
                            defaultValue={service?.extendedDuration}
                            isInvalid={errors.extendedDuration}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_EXTENDED_DESCRIPTION" />
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">{errors.extendedDuration?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.OVERNIGHT_BOOKINGS" />
                        </Form.Label>
                        <Controller
                            name="isOvernightBookingsAllowed"
                            control={control}
                            defaultValue={service ? service.isOvernightBookingsAllowed : false}
                            render={({ value, onChange }) => {
                                return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                            }}
                        />
                    </Form.Group>
                </>
            )}
            {durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE && (
                <>
                    <Form.Row>
                        <Form.Group as={Col} xl={2}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_MIN" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg"
                                name="minDuration"
                                ref={register}
                                defaultValue={service?.minDuration}
                                isInvalid={errors.minDuration}
                            />
                            <Form.Control.Feedback type="invalid">{errors.minDuration?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xl={2}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_MAX" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg"
                                name="maxDuration"
                                ref={register}
                                defaultValue={service?.maxDuration}
                                isInvalid={errors.maxDuration}
                            />
                            <Form.Control.Feedback type="invalid">{errors.maxDuration?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.RANGE_INCREMENT" />
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control form-control-lg col-xl-2"
                            name="timePickerRangeIncrement"
                            ref={register}
                            defaultValue={service?.timePickerRangeIncrement}
                            isInvalid={errors.timePickerRangeIncrement}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.RANGE_INCREMENT_DESCRIPTION" />
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            {errors.timePickerRangeIncrement?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>
            )}
            {(durationType === APPOINTMENT_DURATION_TYPE_FIXED ||
                durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE) && (
                <>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.SHOW_STARTED_SLOT" />
                        </Form.Label>
                        <Controller
                            name="isShowStartedSlot"
                            control={control}
                            defaultValue={service ? service.isShowStartedSlot : false}
                            render={({ value, onChange }) => {
                                return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                            }}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.SHOW_STARTED_SLOT_DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                </>
            )}
            {durationType === APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.FIXED_END_TIME" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name="fixedEndTime"
                        ref={register}
                        onClick={() => setFixedEndTimePickerOpen(true)}
                        defaultValue={
                            service?.fixedEndTime ? moment(service.fixedEndTime).format("YYYY-MM-DD HH:mm") : null
                        }
                        placeholder={intl.formatMessage({
                            id: "SERVICE.APPOINTMENT.BOOKING_SETTINGS.FIXED_END_TIME.PLACEHOLDER",
                        })}
                    />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.FIXED_END_TIME.DESCRIPTION" />
                    </Form.Text>
                </Form.Group>
            )}
            {durationType === APPOINTMENT_DURATION_TYPE_DAYS && (
                <>
                    <Form.Row>
                        <Form.Group as={Col} xl={2}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_MIN_DAYS" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg"
                                name="minDuration"
                                ref={register}
                                defaultValue={service?.minDuration ? service.minDuration / 1440 : null}
                                isInvalid={errors.minDuration}
                            />
                            <Form.Control.Feedback type="invalid">{errors.minDuration?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xl={2}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.DURATION_MAX_DAYS" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg"
                                name="maxDuration"
                                ref={register}
                                defaultValue={service?.maxDuration ? service.maxDuration / 1440 : null}
                                isInvalid={errors.maxDuration}
                            />
                            <Form.Control.Feedback type="invalid">{errors.maxDuration?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xl={2} style={{ marginBottom: "0px" }}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.CHECKIN_TIME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg"
                                name="checkinTime"
                                onClick={() => setTimePickerFieldName("checkinTime")}
                                ref={register}
                                defaultValue={service?.checkinTime}
                                isInvalid={errors.checkinTime}
                                readOnly={true}
                            />
                            <Form.Control.Feedback type="invalid">{errors.checkinTime?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xl={2} style={{ marginBottom: "0px" }}>
                            <Form.Label>
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.CHECKOUT_TIME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg"
                                name="checkoutTime"
                                onClick={() => setTimePickerFieldName("checkoutTime")}
                                ref={register}
                                defaultValue={service?.checkoutTime}
                                isInvalid={errors.checkoutTime}
                                readOnly={true}
                            />
                            <Form.Control.Feedback type="invalid">{errors.checkoutTime?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xl={12}>
                            <Form.Text className="text-muted">
                                <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.CHECKOUT_TIMES_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>
                </>
            )}
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.REMINDER_TEMPLATE" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="reminderTemplate"
                        defaultValue={null}
                        render={() => (
                            <div>
                                <Typeahead
                                    id="typeahead-payment-reminder-template"
                                    labelKey={"name"}
                                    onChange={(reminderTemplates) =>
                                        setValue("reminderTemplate", reminderTemplates[0], {
                                            shouldValidate: true,
                                        })
                                    }
                                    options={allReminderTemplates.map((rt) => getReminderTemplateOption(rt))}
                                    selected={reminderTemplate ? [reminderTemplate] : []}
                                    filterBy={() => true}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xl={4}>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.CANCELLATION_TEMPLATE" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="cancellationTemplate"
                        defaultValue={null}
                        render={() => (
                            <div>
                                <Typeahead
                                    id="typeahead-payment-cancellation-template"
                                    labelKey={"name"}
                                    onChange={(cancellationTemplates) =>
                                        setValue("cancellationTemplate", cancellationTemplates[0], {
                                            shouldValidate: true,
                                        })
                                    }
                                    options={allCancellationTemplates.map((ct) => getCancellationTemplateOption(ct))}
                                    selected={cancellationTemplate ? [cancellationTemplate] : []}
                                    filterBy={() => true}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                />
                            </div>
                        )}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.NO_AVAILABILITY_TEXT" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={4}
                    className="form-control form-control-lg col-xl-8"
                    name="noAvailabilityText"
                    ref={register}
                    isInvalid={errors.noAvailabilityText}
                    defaultValue={service?.noAvailabilityText}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="SERVICE.APPOINTMENT.BOOKING_SETTINGS.NO_AVAILABILITY_TEXT.DESCRIPTION" />
                </Form.Text>
            </Form.Group>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                    showTodayButton
                    ampm={false}
                    style={{ display: "none" }}
                    open={fixedEndTimePickerOpen}
                    onClose={() => setFixedEndTimePickerOpen(false)}
                    onChange={(selectedMomentDate) => {
                        setValue(
                            "fixedEndTime",
                            selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                            {
                                shouldValidate: true,
                            }
                        );
                    }}
                    maxDate={fixedEndTime ? moment(fixedEndTime) : new Date("2100-01-01")}
                    clearable={true}
                />
                <TimePicker
                    ampm={false}
                    open={timePickerFieldName ? true : false}
                    style={{ display: "none" }}
                    onClose={() => setTimePickerFieldName(null)}
                    onChange={(time) => {
                        if (!time) {
                            // Cleared
                            setValue(timePickerFieldName, null, {
                                shouldValidate: true,
                            });
                            return;
                        }
                        const selectedTime = moment(time);
                        const hour = selectedTime.hour();
                        const minute = selectedTime.minute();
                        const selectedTimeString = `${hour < 10 ? `0${hour}` : hour}:${
                            minute < 10 ? `0${minute}` : minute
                        }`;
                        setValue(timePickerFieldName, selectedTimeString, {
                            shouldValidate: true,
                        });
                    }}
                    clearable={true}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default connect(null, resourceActions)(AppointmentBookingSettingsForm);
