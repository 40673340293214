import React, { useEffect } from "react";
import { actions as fortnoxActions } from "../../../../redux/integrations/fortnox/fortnoxRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapButton from "../../../components/buttons/BootstrapButton";
import { STATUS_EXPIRED } from "../utils";

const useStyles = makeStyles((theme) => ({
    expiredButton: {
        marginRight: theme.spacing(1),
    },
}));

const schema = yup.object().shape({});

function FortnoxPageDetails({ updateFortnoxIntegration, getFortnoxIntegrationAuth }) {
    const { profile } = useSelector((state) => state.auth);
    const { integration, isLoading, isUpdating, authRedirectUrl } = useSelector((state) => state.fortnox);
    const classes = useStyles();

    const { control, setValue, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (integration) {
            setValue("invoicesEnabled", integration.invoicesEnabled);
            setValue("receiptsEnabled", integration.receiptsEnabled);
        }
    }, [integration, setValue]);

    const onFormSubmit = (values) => {
        updateFortnoxIntegration(integration.id, integration, values.invoicesEnabled, values.receiptsEnabled);
    };

    if (authRedirectUrl) {
        window.location.href = authRedirectUrl;
        return <></>;
    }

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {integration && !integration?.deletedAt && (
                        <div className="card-toolbar">
                            {integration.status === STATUS_EXPIRED && (
                                <BootstrapButton
                                    variant={"warning"}
                                    isLoading={isLoading}
                                    onClick={() => getFortnoxIntegrationAuth(profile.id, integration.id)}
                                    className={classes.expiredButton}
                                    label={<FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.RE_AUTH" />}
                                />
                            )}
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)} />
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm={3}>
                                <FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.PAYMENT_TYPES" />
                            </Form.Label>
                            <Col sm={9}>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="invoicesEnabled"
                                            control={control}
                                            defaultValue={false}
                                            render={({ value, onChange }) => {
                                                return (
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                );
                                            }}
                                        />
                                    }
                                    label={<FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.PAYMENT_TYPES.INVOICES" />}
                                />
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="receiptsEnabled"
                                            control={control}
                                            defaultValue={false}
                                            render={({ value, onChange }) => {
                                                return (
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                );
                                            }}
                                        />
                                    }
                                    label={<FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.PAYMENT_TYPES.RECEIPTS" />}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, fortnoxActions)(FortnoxPageDetails);
