import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as cancellationTemplateActions } from "../../../redux/cancellationtemplates/cancellationTemplateRedux";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),
    },
}));

const REFUND_RULE_VALUE_TYPE_PERCENT = "refundRulePercent";
const REFUND_RULE_VALUE_TYPE_AMOUNT = "refundRuleAmount";

const schema = yup.object().shape({
    minuteLimit: yup
        .number()
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
    refundValue: yup
        .number()
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .when("refundValueType", (valueType, schema) => {
            if (valueType === REFUND_RULE_VALUE_TYPE_PERCENT) {
                return schema
                    .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />)
                    .max(100, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 100 }} />);
            } else if (valueType === REFUND_RULE_VALUE_TYPE_AMOUNT) {
                return schema.min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
            }
        }),
});

function getRefundValueTypeOptions(intl) {
    return [
        {
            name: intl.formatMessage({
                id: "CANCELLATION_TEMPLATE.REFUND_RULE.ADD.REFUND_VALUE_TYPE.PERCENTAGE",
            }),
            key: REFUND_RULE_VALUE_TYPE_PERCENT,
        },
        {
            name: intl.formatMessage({
                id: "CANCELLATION_TEMPLATE.REFUND_RULE.ADD.REFUND_VALUE_TYPE.AMOUNT",
            }),
            key: REFUND_RULE_VALUE_TYPE_AMOUNT,
        },
    ];
}

function CancellationTemplateRefundRuleAddModal({ show, onSaveClicked, onCloseClicked }) {
    const { isLoading } = useSelector((state) => state.cancellationTemplates);
    const classes = useStyles();
    const intl = useIntl();

    const { register, handleSubmit, control, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (formValues) => {
        let ruleData = {
            minuteLimit: formValues.minuteLimit,
        };

        if (formValues.refundValueType === REFUND_RULE_VALUE_TYPE_PERCENT) {
            ruleData.refundPercentage = formValues.refundValue;
        } else if (formValues.refundValueType === REFUND_RULE_VALUE_TYPE_AMOUNT) {
            ruleData.refundAmount = formValues.refundValue;
        }

        onSaveClicked(ruleData);
    };

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULE.ADD.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULE.ADD.MINUTE_LIMIT" />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="form-control form-control-lg col-xl-2"
                                name="minuteLimit"
                                ref={register}
                                isInvalid={errors.minuteLimit}
                            />
                            <Form.Control.Feedback type="invalid">{errors.minuteLimit?.message}</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULE.ADD.MINUTE_LIMIT_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULE.ADD.REFUND_VALUE_TYPE" />
                                </Form.Label>
                                <Controller
                                    name="refundValueType"
                                    className="form-control form-control-lg"
                                    control={control}
                                    defaultValue={REFUND_RULE_VALUE_TYPE_PERCENT}
                                    render={({ onChange, value }) => {
                                        return (
                                            <Form.Control
                                                as="select"
                                                className="form-control form-control-lg"
                                                value={value}
                                                onChange={(val) => onChange(val.target.value)}
                                            >
                                                {getRefundValueTypeOptions(intl).map((x) => {
                                                    return (
                                                        <option key={x.key} value={x.key}>
                                                            {x.name}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        );
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.property?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                                <Form.Label>
                                    <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULE.ADD.REFUND_VALUE" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg"
                                    name="refundValue"
                                    ref={register}
                                    isInvalid={errors.refundValue}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.refundValue?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isLoading}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="COMMON.ADD" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default connect(null, { ...cancellationTemplateActions })(CancellationTemplateRefundRuleAddModal);
