export function findAndSpliceArrayByProperty(array, property, propertyValue) {
    if (!array || !property) return array;
    let index = array.findIndex((item) => item[property] === propertyValue);
    if (index > -1) {
        let arrayCopy = [...array];
        arrayCopy.splice(index, 1);
        return arrayCopy;
    }
    return array;
}
