import React from "react";
import { TextCell, ChipCell } from "../../components/tables/MaterialTableCells";
import { FormattedMessage } from "react-intl";

export const STATUS_ACTIVE = "active";
export const STATUS_UNDER_NOTICE = "underNotice";
export const STATUS_PAST_DUE = "pastDue";
export const STATUS_PAST_DUE_CANCELLED = "pastDueCancelled";
export const STATUS_CANCELLED = "cancelled";

export const RECURRING_UNIT_DAILY = "daily";
export const RECURRING_UNIT_MONTHLY = "monthly";
export const RECURRING_UNIT_QUARTERLY = "quarterly";
export const RECURRING_UNIT_YEARLY = "yearly";

export const CANCELLATION_TYPE_NOW = "now";
export const CANCELLATION_TYPE_NOW_NOTICE_PERIOD = "nowNoticePeriod";
export const CANCELLATION_TYPE_LAST_AT = "lastAt";

export function getSubscriptionStatusCell(status) {
    if (status === STATUS_ACTIVE) {
        return ChipCell("success", <FormattedMessage id="SUBSCRIPTION.STATUS.ACTIVE" />);
    } else if (status === STATUS_UNDER_NOTICE) {
        return ChipCell("info", <FormattedMessage id="SUBSCRIPTION.STATUS.UNDER_NOTICE" />);
    } else if (status === STATUS_PAST_DUE) {
        return ChipCell("warning", <FormattedMessage id="SUBSCRIPTION.STATUS.PAST_DUE" />);
    } else if (status === STATUS_CANCELLED) {
        return ChipCell("error", <FormattedMessage id="SUBSCRIPTION.STATUS.CANCELLED" />);
    }
    return TextCell();
}

export function getRecurringUnitText(recurringUnit, intl) {
    if (recurringUnit === RECURRING_UNIT_DAILY) {
        return intl.formatMessage({
            id: "SUBSCRIPTION.RECURRING_UNIT.DAILY",
        });
    } else if (recurringUnit === RECURRING_UNIT_MONTHLY) {
        return intl.formatMessage({
            id: "SUBSCRIPTION.RECURRING_UNIT.MONTHLY",
        });
    } else if (recurringUnit === RECURRING_UNIT_QUARTERLY) {
        return intl.formatMessage({
            id: "SUBSCRIPTION.RECURRING_UNIT.QUARTERLY",
        });
    } else if (recurringUnit === RECURRING_UNIT_YEARLY) {
        return intl.formatMessage({
            id: "SUBSCRIPTION.RECURRING_UNIT.YEARLY",
        });
    }

    return "";
}
