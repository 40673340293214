import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import PaymentTemplatePageNavigator from "./PaymentTemplatePageNavigator";
import PaymentTemplatePageDetails from "./PaymentTemplatePageDetails";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function PaymentTemplatePage({
    match,
    getPaymentTemplate,
    clearPaymentTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const paymentTemplateId = parseInt(match.params.id, 10);
    const { paymentTemplate, isLoading, isUpdating } = useSelector((state) => state.paymentTemplates);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!paymentTemplate || paymentTemplate.id !== paymentTemplateId)) {
            getPaymentTemplate(paymentTemplateId);
        }
    }, [getPaymentTemplate, paymentTemplate, paymentTemplateId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearPaymentTemplateState();
            clearErrorState();
        };
    }, [clearPaymentTemplateState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <PaymentTemplatePageNavigator paymentTemplateId={paymentTemplateId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/payment-templates/${paymentTemplateId}`}
                            exact={true}
                            to={`/payment-templates/${paymentTemplateId}/details`}
                        />
                        <Route
                            path={`/payment-templates/${paymentTemplateId}/details`}
                            component={PaymentTemplatePageDetails}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...paymentTemplateActions,
    ...errorActions,
})(PaymentTemplatePage);
