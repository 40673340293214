import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Switch, FormControlLabel } from "@material-ui/core";
import { actions as bookingManagerActions } from "../../../redux/bookings/bookingManagerRedux";
import { actions as serviceActions } from "../../../redux/services/serviceRedux";
import { Form, Col } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FormattedMessage, useIntl } from "react-intl";
import { PAYMENT_MODE_OPTIONAL, PAYMENT_MODE_MANDATORY } from "../services/utils";
import moment from "moment";

function getCourseOccasionOptions(courseOccasionsPagination) {
    let options = [];
    if (!courseOccasionsPagination?.data?.length) return options;
    courseOccasionsPagination.data.forEach((courseOccasion) => {
        options.push({
            id: courseOccasion.id,
            name: `${moment(courseOccasion.startTime).format("YYYY-MM-DD HH:mm")} - ${moment(
                courseOccasion.endTime
            ).format("YYYY-MM-DD HH:mm")}`,
        });
    });

    return options;
}

function DrawerAddBookingCourse({
    selectedService,
    notifyUser,
    setNotifyUser,
    isMarkedAsPaid,
    setIsMarkedAsPaid,
    selectedCourseOccasions,
    setSelectedCourseOccasions,
    getCourseOccasions,
}) {
    const { courseOccasionsPagination, isLoading } = useSelector((state) => state.services);

    const intl = useIntl();

    useEffect(() => {
        if (selectedService.isBookPerOccasion) {
            getCourseOccasions(selectedService.id, 1, 100, "upcoming");
        }
    }, [selectedService, getCourseOccasions]);

    return (
        <div>
            {selectedService.isBookPerOccasion && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="BOOKING.ADD.COURSE.OCCASIONS.TITLE" />
                    </Form.Label>
                    <AsyncTypeahead
                        id="typeahead-course-occasions"
                        labelKey={"name"}
                        minLength={0}
                        clearButton={true}
                        onChange={(occasions) => {
                            setSelectedCourseOccasions(occasions);
                        }}
                        onSearch={() => {}}
                        useCache={false}
                        isLoading={isLoading}
                        options={getCourseOccasionOptions(courseOccasionsPagination)}
                        placeholder={intl.formatMessage({
                            id: "BOOKING.ADD.COURSE.OCCASIONS.PLACEHOLDER",
                        })}
                        emptyLabel={intl.formatMessage({
                            id: "BOOKING.ADD.COURSE.OCCASIONS.SEARCH_EMPTY",
                        })}
                        selected={selectedCourseOccasions}
                        multiple
                    />
                </Form.Group>
            )}

            <Form.Group>
                <Form.Label>
                    <FormattedMessage
                        id="BOOKING.ADD.OTHER.TITLE"
                        values={{ stepCount: selectedService.isBookPerOccasion ? 4 : 3 }}
                    />
                </Form.Label>
                <Form.Row>
                    <Col xs={12}>
                        <FormControlLabel
                            control={<Switch color="primary" />}
                            label={<FormattedMessage id="BOOKING.ADD.OTHER.NOTIFY_USER" />}
                            labelPlacement="end"
                            checked={notifyUser}
                            onChange={(event, value) => setNotifyUser(value)}
                            style={{ userSelect: "none" }}
                        />
                    </Col>
                    {(selectedService.paymentMode === PAYMENT_MODE_OPTIONAL ||
                        selectedService.paymentMode === PAYMENT_MODE_MANDATORY) && (
                        <Col xs={12}>
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label={<FormattedMessage id="BOOKING.ADD.OTHER.MARK_AS_PAID" />}
                                labelPlacement="end"
                                checked={isMarkedAsPaid}
                                onChange={(event, value) => setIsMarkedAsPaid(value)}
                                style={{ userSelect: "none" }}
                            />
                        </Col>
                    )}
                </Form.Row>
            </Form.Group>
        </div>
    );
}

export default injectIntl(connect(null, { ...bookingManagerActions, ...serviceActions })(DrawerAddBookingCourse));
