import React, { useCallback, useMemo } from "react";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell } from "../../../components/tables/MaterialTableCells";
import { getBookingAccessStatusCell } from "../../integrations/utils";

function BookingPageAccesses({ getBookingAccesses }) {
    const { booking, accessesPagination, isLoading } = useSelector((state) => state.bookings);
    const bookingId = booking?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (bookingId) {
                getBookingAccesses(bookingId, pageIndex + 1, pageSize);
            }
        },
        [bookingId, getBookingAccesses]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="BOOKING.ACCESSES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="BOOKING.ACCESSES.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={bookingId}
                        data={useMemo(() => accessesPagination.data, [accessesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={accessesPagination.totalRows}
                        isLoading={isLoading}
                        columns={useMemo(
                            () => [
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.ID" />
                                    ),
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.START_TIME" />
                                    ),
                                    accessor: "accessStartTime",
                                    maxWidth: 140,
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.END_TIME" />
                                    ),
                                    maxWidth: 140,
                                    accessor: "accessEndTime",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.ACCESS_KEY_ID" />
                                    ),
                                    accessor: "externalId",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.ACCESS_ID" />
                                    ),
                                    accessor: "accessDevice",
                                    Cell: (props) => {
                                        return TextCell(props.value.externalId);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.STATUS" />
                                    ),
                                    accessor: "status",
                                    Cell: (props) => {
                                        return getBookingAccessStatusCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.PARAKEY.BOOKING_ACCESSES.LIST.HEADER.CREATED_AT" />
                                    ),
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, bookingActions)(BookingPageAccesses);
