import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Paper, IconButton } from "@material-ui/core";
import {
    AvatarCell,
    DateTimeCell,
    TextCell,
    LinkCell,
    LinkCellEllipsis,
} from "../../components/tables/MaterialTableCells";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import TransactionListToolbar from "../../components/tables/toolbar/TransactionListToolbar";
import * as transactionUtils from "./utils";
import SnackbarError from "../../components/SnackbarError";

function TransactionListPage({ getTransactions, downloadTransactionPdf, clearDisplayError, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination, isDownloadingPdf } = useSelector((state) => state.transactions);
    const { displayError } = useSelector((state) => state.errors);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(transactionUtils.STATUS_SUCCESS);
    const [paymentType, setPaymentType] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [promotionCodeFilter, setPromotionCodeFilter] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getTransactions(
                profile.id,
                pageIndex + 1,
                pageSize,
                search,
                status,
                paymentType,
                paymentStatus,
                promotionCodeFilter,
                startTime ? startTime.format() : "",
                endTime ? endTime.format() : ""
            );
        },
        [
            getTransactions,
            profile.id,
            search,
            status,
            paymentType,
            paymentStatus,
            promotionCodeFilter,
            startTime,
            endTime,
        ]
    );

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((value) => {
        setStatus(value);
    }, []);

    const onPaymentTypeChanged = useCallback((value) => {
        setPaymentType(value);
    }, []);

    const onPaymentStatusChanged = useCallback((value) => {
        setPaymentStatus(value);
    }, []);

    const onPromotionCodeFilterChanged = useCallback((value) => {
        setPromotionCodeFilter(value);
    }, []);

    const onStartTimeChanged = useCallback((value) => {
        setStartTime(value);
    }, []);

    const onEndTimeChanged = useCallback((value) => {
        setEndTime(value);
    }, []);

    return (
        <Paper>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                renderToolbar={() => {
                    return (
                        <TransactionListToolbar
                            onSearchChanged={onSearchChanged}
                            onStatusChanged={onStatusChanged}
                            onPaymentTypeChanged={onPaymentTypeChanged}
                            onPaymentStatusChanged={onPaymentStatusChanged}
                            onPromotionCodeFilterChanged={onPromotionCodeFilterChanged}
                            onStartTimeChanged={onStartTimeChanged}
                            onEndTimeChanged={onEndTimeChanged}
                            defaultStatus={transactionUtils.STATUS_SUCCESS}
                        />
                    );
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.ID" />,
                            accessor: "id",
                            maxWidth: 75,
                            Cell: (props) => {
                                return LinkCell("/transactions/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return transactionUtils.getTransactionListStatusCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_TYPE" />,
                            accessor: "paymentType",
                            maxWidth: 100,
                            Cell: (props) => {
                                return transactionUtils.getTransactionListPaymentTypeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.SERIAL_NUMBER" />,
                            accessor: "serialNumber",
                            maxWidth: 100,
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.CUSTOMER" />,
                            accessor: "customer",
                            maxWidth: 200,
                            Cell: (props) => {
                                return (
                                    <Link to={`/customers/${props.value.id}`}>
                                        {AvatarCell(
                                            props.value.avatarUrl,
                                            `${props.value.firstName} ${props.value.surname}`
                                        )}
                                    </Link>
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.AMOUNT" />,
                            accessor: "amount",
                            Cell: (props) => {
                                return TextCell(props.value + " " + props.row.original.currency);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.REFUNDED_AMOUNT" />,
                            accessor: "refundedAmount",
                            Cell: (props) => {
                                return TextCell(props.value + " " + props.row.original.currency);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PROMO_CODE" />,
                            accessor: "promotionCode",
                            maxWidth: 100,
                            Cell: (props) => {
                                if (!props.value) return <></>;
                                return LinkCellEllipsis(`/promotion-codes/${props.value.id}/details`, props.value.code);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.TRANSACTION_DAY" />,
                            accessor: "paidAt",
                            Cell: ({ row }) => {
                                return DateTimeCell(row.original.paidAt || row.original.createdAt);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_STATUS" />,
                            accessor: "paymentStatus",
                            Cell: ({ row }) => {
                                return transactionUtils.getTransactionStatusCell(
                                    row.original.paymentStatus,
                                    row.original.status
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            maxWidth: 100,
                            Cell: ({ row }) => {
                                return (
                                    <IconButton
                                        disabled={isDownloadingPdf}
                                        onClick={() =>
                                            downloadTransactionPdf(row.original.id, row.original.serialNumber)
                                        }
                                    >
                                        <CloudDownloadOutlinedIcon />
                                    </IconButton>
                                );
                            },
                        },
                    ],
                    [downloadTransactionPdf, isDownloadingPdf]
                )}
            />
        </Paper>
    );
}

export default connect(null, { ...transactionActions, ...errorActions })(TransactionListPage);
