import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const BOOKINGS_URL = "v1/admin/profiles/:id/bookings";
export const BOOKING_URL = "v1/admin/bookings/:id";
export const BOOKING_ACCESSES_URL = "v1/admin/bookings/:id/accesses";
export const RESOURCE_LIST_BOOKING_URL = "v1/admin/profiles/:id/resources/bookings";

export function getBookings(profileId, page, perPage, search, status, paymentType) {
    const url = `${BOOKINGS_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}
        ${search ? `&search=${search}` : ""}
        ${status ? `&status=${status}` : ""}
        ${paymentType ? `&paymentType=${paymentType}` : ""}`;
    return axios.get(getApiUrl() + url);
}

export function getBooking(id) {
    const url = `${BOOKING_URL.replace(":id", id)}`;
    return axios.get(getApiUrl() + url);
}

export function getBookingAccesses(bookingId, page, perPage) {
    const url = `${BOOKING_ACCESSES_URL.replace(":id", bookingId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getResourceListBookings(profileId, page, perPage, search, status, type) {
    const url = `${RESOURCE_LIST_BOOKING_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}${
        search ? "&search=" + search : ""
    }${status ? "&status=" + status : ""}${type ? "&type=" + type : ""}`;
    return axios.get(getApiUrl() + url);
}

export function addBooking(profileId, bookingData) {
    const url = BOOKINGS_URL.replace(":id", profileId);
    return axios.post(getApiUrl() + url, bookingData);
}

export function patchBooking(id, originalBooking, changedBooking) {
    const url = BOOKING_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalBooking, changedBooking), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}
