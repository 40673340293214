import { put, takeLatest } from "redux-saga/effects";
import {
    getCampaigns,
    getCampaign,
    addCampaign,
    patchCampaign,
    deleteCampaign,
    getCampaignRecipientFilters,
    addCampaignRecipientFilter,
    deleteCampaignRecipientFilter,
    getCampaignSmsPreviewRecipients,
    getCampaignRecipients,
    getCampaignPreview,
    publishCampaign,
    getCampaignAvailableFilters,
    getSmsPreviewRecipients,
} from "./campaignCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import { findAndSpliceArrayByProperty } from "../reduxUtils";

export const actionTypes = {
    GET_CAMPAIGNS_REQUEST: "GET_CAMPAIGNS_REQUEST",
    GET_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_SUCCESS",
    GET_CAMPAIGN_REQUEST: "GET_CAMPAIGN_REQUEST",
    GET_CAMPAIGN_SUCCESS: "GET_CAMPAIGN_SUCCESS",
    ADD_CAMPAIGN_REQUEST: "ADD_CAMPAIGN_REQUEST",
    ADD_CAMPAIGN_SUCCESS: "ADD_CAMPAIGN_SUCCESS",
    UPDATE_CAMPAIGN_REQUEST: "UPDATE_CAMPAIGN_REQUEST",
    UPDATE_CAMPAIGN_SUCCESS: "UPDATE_CAMPAIGN_SUCCESS",
    DELETE_CAMPAIGN_REQUEST: "DELETE_CAMPAIGN_REQUEST",
    DELETE_CAMPAIGN_SUCCESS: "DELETE_CAMPAIGN_SUCCESS",
    GET_CAMPAIGN_RECIPIENT_FILTERS_REQUEST: "GET_CAMPAIGN_RECIPIENT_FILTERS_REQUEST",
    GET_CAMPAIGN_RECIPIENT_FILTERS_SUCCESS: "GET_CAMPAIGN_RECIPIENT_FILTERS_SUCCESS",
    ADD_CAMPAIGN_RECIPIENT_FILTER_REQUEST: "ADD_CAMPAIGN_RECIPIENT_FILTER_REQUEST",
    ADD_CAMPAIGN_RECIPIENT_FILTER_SUCCESS: "ADD_CAMPAIGN_RECIPIENT_FILTER_SUCCESS",
    DELETE_CAMPAIGN_RECIPIENT_FILTER_REQUEST: "DELETE_CAMPAIGN_RECIPIENT_FILTER_REQUEST",
    DELETE_CAMPAIGN_RECIPIENT_FILTER_SUCCESS: "DELETE_CAMPAIGN_RECIPIENT_FILTER_SUCCESS",
    GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_REQUEST: "GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_REQUEST",
    GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_SUCCESS: "GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_SUCCESS",
    GET_CAMPAIGN_RECIPIENTS_REQUEST: "GET_CAMPAIGN_RECIPIENTS_REQUEST",
    GET_CAMPAIGN_RECIPIENTS_SUCCESS: "GET_CAMPAIGN_RECIPIENTS_SUCCESS",
    GET_CAMPAIGN_PREVIEW_REQUEST: "GET_CAMPAIGN_PREVIEW_REQUEST",
    GET_CAMPAIGN_PREVIEW_SUCCESS: "GET_CAMPAIGN_PREVIEW_SUCCESS",
    PUBLISH_CAMPAIGN_REQUEST: "PUBLISH_CAMPAIGN_REQUEST",
    PUBLISH_CAMPAIGN_SUCCESS: "PUBLISH_CAMPAIGN_SUCCESS",
    GET_CAMPAIGN_AVAILABLE_FILTERS_REQUEST: "GET_CAMPAIGN_AVAILABLE_FILTERS_REQUEST",
    GET_CAMPAIGN_AVAILABLE_FILTERS_SUCCESS: "GET_CAMPAIGN_AVAILABLE_FILTERS_SUCCESS",
    GET_SMS_PREVIEW_RECIPIENTS_REQUEST: "GET_SMS_PREVIEW_RECIPIENTS_REQUEST",
    GET_SMS_PREVIEW_RECIPIENTS_SUCCESS: "GET_SMS_PREVIEW_RECIPIENTS_SUCCESS",
    CLEAR_CAMPAIGN_STATE: "CLEAR_CAMPAIGN_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },

    recipientFiltersPagination: {
        data: [],
        totalRows: 0,
    },

    isSmsPreviewRecipientsLoading: false,
    smsPreviewRecipientsPagination: {
        data: [],
        totalRows: 0,
    },

    isRecipientsLoading: false,
    recipientsPagination: {
        data: [],
        totalRows: 0,
    },

    campaignAvailableFilters: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CAMPAIGNS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CAMPAIGNS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CAMPAIGN_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                campaign: action.payload.response,
            };
        }

        case actionTypes.ADD_CAMPAIGN_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                campaign: action.payload.response,
            };
        }

        case actionTypes.UPDATE_CAMPAIGN_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                campaign: action.payload.response,
            };
        }

        case actionTypes.DELETE_CAMPAIGN_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_CAMPAIGN_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.listPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                campaign: action.payload.response,
                listPagination: {
                    ...state.listPagination,
                    data: alteredStateArray,
                    totalRows: state.listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_CAMPAIGN_RECIPIENT_FILTERS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CAMPAIGN_RECIPIENT_FILTERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                recipientFiltersPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_CAMPAIGN_RECIPIENT_FILTER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_CAMPAIGN_RECIPIENT_FILTER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionTypes.DELETE_CAMPAIGN_RECIPIENT_FILTER_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_CAMPAIGN_RECIPIENT_FILTER_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.recipientFiltersPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                recipientFiltersPagination: {
                    ...state.recipientFiltersPagination,
                    data: alteredStateArray,
                    totalRows: state.recipientFiltersPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_REQUEST: {
            return { ...state, isSmsPreviewRecipientsLoading: true };
        }

        case actionTypes.GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_SUCCESS: {
            return {
                ...state,
                isSmsPreviewRecipientsLoading: false,
                smsPreviewRecipientsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CAMPAIGN_RECIPIENTS_REQUEST: {
            return { ...state, isRecipientsLoading: true };
        }

        case actionTypes.GET_CAMPAIGN_RECIPIENTS_SUCCESS: {
            return {
                ...state,
                isRecipientsLoading: false,
                recipientsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CAMPAIGN_PREVIEW_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CAMPAIGN_PREVIEW_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                publishPreview: action.payload.response,
            };
        }

        case actionTypes.PUBLISH_CAMPAIGN_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.PUBLISH_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                campaign: action.payload.response,
            };
        }

        case actionTypes.GET_CAMPAIGN_AVAILABLE_FILTERS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CAMPAIGN_AVAILABLE_FILTERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                campaignAvailableFilters: action.payload.response,
            };
        }

        case actionTypes.GET_SMS_PREVIEW_RECIPIENTS_REQUEST: {
            return { ...state, isSmsPreviewRecipientsLoading: true };
        }

        case actionTypes.GET_SMS_PREVIEW_RECIPIENTS_SUCCESS: {
            return {
                ...state,
                isSmsPreviewRecipientsLoading: false,
                smsPreviewRecipientsPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_CAMPAIGN_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getCampaigns: (profileId, page, perPage) => ({
        type: actionTypes.GET_CAMPAIGNS_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getCampaign: (campaignId) => ({
        type: actionTypes.GET_CAMPAIGN_REQUEST,
        payload: { campaignId },
    }),

    addCampaign: (profileId, campaignType, data, callback) => ({
        type: actionTypes.ADD_CAMPAIGN_REQUEST,
        payload: { profileId, campaignType, data, callback },
    }),

    updateCampaign: (campaignId, originalCampaign, updatedCampaign) => ({
        type: actionTypes.UPDATE_CAMPAIGN_REQUEST,
        payload: { campaignId, originalCampaign, updatedCampaign },
    }),

    deleteCampaign: (id) => ({
        type: actionTypes.DELETE_CAMPAIGN_REQUEST,
        payload: { id },
    }),

    getCampaignRecipientFilters: (campaignId, page, perPage) => ({
        type: actionTypes.GET_CAMPAIGN_RECIPIENT_FILTERS_REQUEST,
        payload: { campaignId, page, perPage },
    }),

    addCampaignRecipientFilter: (campaignId, data, callback) => ({
        type: actionTypes.ADD_CAMPAIGN_RECIPIENT_FILTER_REQUEST,
        payload: { campaignId, data, callback },
    }),

    deleteCampaignRecipientFilter: (campaignId, recipientFilterId) => ({
        type: actionTypes.DELETE_CAMPAIGN_RECIPIENT_FILTER_REQUEST,
        payload: { campaignId, recipientFilterId },
    }),

    getCampaignSmsPreviewRecipients: (campaignId, page, perPage) => ({
        type: actionTypes.GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_REQUEST,
        payload: { campaignId, page, perPage },
    }),

    getCampaignRecipients: (campaignId, page, perPage) => ({
        type: actionTypes.GET_CAMPAIGN_RECIPIENTS_REQUEST,
        payload: { campaignId, page, perPage },
    }),

    getCampaignPreview: (campaignId) => ({
        type: actionTypes.GET_CAMPAIGN_PREVIEW_REQUEST,
        payload: { campaignId },
    }),

    publishCampaign: (profileId, data) => ({
        type: actionTypes.PUBLISH_CAMPAIGN_REQUEST,
        payload: { profileId, data },
    }),

    getCampaignAvailableFilters: () => ({
        type: actionTypes.GET_CAMPAIGN_AVAILABLE_FILTERS_REQUEST,
        payload: {},
    }),

    getSmsPreviewRecipients: (profileId, page, perPage, recipientFilters) => ({
        type: actionTypes.GET_SMS_PREVIEW_RECIPIENTS_REQUEST,
        payload: { profileId, page, perPage, recipientFilters },
    }),

    clearCampaignState: () => ({
        type: actionTypes.CLEAR_CAMPAIGN_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_CAMPAIGNS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCampaigns(payload.profileId, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_CAMPAIGNS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CAMPAIGN_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCampaign(payload.campaignId);
            yield put({
                type: actionTypes.GET_CAMPAIGN_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_CAMPAIGN_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCampaign(payload.profileId, payload.campaignType, payload.data);
            yield put({
                type: actionTypes.ADD_CAMPAIGN_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_CAMPAIGN_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchCampaign(payload.campaignId, payload.originalCampaign, {
                ...payload.originalCampaign,
                ...payload.updatedCampaign,
            });
            yield put({
                type: actionTypes.UPDATE_CAMPAIGN_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_CAMPAIGN_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteCampaign(payload.id);
            yield put({
                type: actionTypes.DELETE_CAMPAIGN_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CAMPAIGN_RECIPIENT_FILTERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCampaignRecipientFilters(
                payload.campaignId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_CAMPAIGN_RECIPIENT_FILTERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_CAMPAIGN_RECIPIENT_FILTER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCampaignRecipientFilter(payload.campaignId, payload.data);
            yield put({
                type: actionTypes.ADD_CAMPAIGN_RECIPIENT_FILTER_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_CAMPAIGN_RECIPIENT_FILTER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteCampaignRecipientFilter(
                payload.campaignId,
                payload.recipientFilterId
            );
            yield put({
                type: actionTypes.DELETE_CAMPAIGN_RECIPIENT_FILTER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCampaignSmsPreviewRecipients(
                payload.campaignId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_CAMPAIGN_SMS_PREVIEW_RECIPIENTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CAMPAIGN_RECIPIENTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCampaignRecipients(payload.campaignId, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_CAMPAIGN_RECIPIENTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CAMPAIGN_PREVIEW_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCampaignPreview(payload.campaignId);
            yield put({
                type: actionTypes.GET_CAMPAIGN_PREVIEW_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.PUBLISH_CAMPAIGN_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield publishCampaign(payload.profileId, payload.data);
            yield put({
                type: actionTypes.PUBLISH_CAMPAIGN_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CAMPAIGN_AVAILABLE_FILTERS_REQUEST, function* () {
        try {
            const { data: response } = yield getCampaignAvailableFilters();
            yield put({
                type: actionTypes.GET_CAMPAIGN_AVAILABLE_FILTERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SMS_PREVIEW_RECIPIENTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getSmsPreviewRecipients(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.recipientFilters
            );
            yield put({
                type: actionTypes.GET_SMS_PREVIEW_RECIPIENTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
