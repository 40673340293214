import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles, Typography, Divider, IconButton } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
    },
    addButtonIcon: {
        fontSize: "18px",
    },
}));

export default function CampaignSectionHeader({ intlId, onAddClicked }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                <Typography variant="h6">
                    <FormattedMessage id={intlId} />
                </Typography>
                {onAddClicked && (
                    <IconButton onClick={() => onAddClicked()}>
                        <AddOutlinedIcon color="action" className={classes.addButtonIcon} />
                    </IconButton>
                )}
            </div>
            <Divider light />
        </div>
    );
}
