import React from "react";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import PaymentTemplateDetailsForm from "./PaymentTemplateDetailsForm";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    PAYMENT_TYPE_MODE_ONE_TIME,
} from "./utils";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    billingTemplate: yup.object(),
    subscriptionRecurringDayMode: yup.object(),
    subscriptionRecurringDayOfMonth: yup
        .number()
        .when("subscriptionRecurringDayMode", (subscriptionRecurringDayMode) => {
            if (subscriptionRecurringDayMode?.id !== SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
                    .max(31, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 31 }} />);
            }
        }),
    subscriptionNoticePeriodDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
});

function PaymentTemplatePageDetails({ updatePaymentTemplate }) {
    const { paymentTemplate, isUpdating } = useSelector((state) => state.paymentTemplates);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalTemplate = { ...paymentTemplate };
        originalTemplate.billingTemplateId = originalTemplate?.billingTemplate?.id || null;
        originalTemplate.isSwishEnabled = originalTemplate.paymentMethods.includes(PAYMENT_METHOD_SWISH);
        originalTemplate.isCardEnabled = originalTemplate.paymentMethods.includes(PAYMENT_METHOD_CARD);
        originalTemplate.isInvoiceEnabled = originalTemplate.paymentMethods.includes(PAYMENT_METHOD_INVOICE);

        let updatedTemplate = { ...paymentTemplate };
        updatedTemplate.name = values.name;
        updatedTemplate.isDefault = values.isDefault;
        updatedTemplate.paymentMode = values.paymentMode;
        updatedTemplate.billingTemplateId = values?.billingTemplate?.id;

        if (values.paymentMethods) {
            updatedTemplate.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            updatedTemplate.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            updatedTemplate.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        if (values.paymentTypeMode) {
            if (values.paymentTypeMode === PAYMENT_TYPE_MODE_ONE_TIME) {
                updatedTemplate.isSubscription = false;
            } else {
                updatedTemplate.isSubscription = true;
                updatedTemplate.subscriptionUnit = values.subscriptionUnit?.id;
                updatedTemplate.subscriptionDayMode = values.subscriptionDayMode?.id;
                updatedTemplate.subscriptionDayOfMonth = values.subscriptionDayOfMonth;
                updatedTemplate.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
                updatedTemplate.isSubscriptionExtendOnFailure = values.isSubscriptionExtendOnFailure;
            }
        }

        updatePaymentTemplate(paymentTemplate.id, originalTemplate, updatedTemplate);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {paymentTemplate && !paymentTemplate?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <PaymentTemplateDetailsForm paymentTemplate={paymentTemplate} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, paymentTemplateActions)(PaymentTemplatePageDetails);
