import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import BootstrapSaveButton from "./buttons/BootstrapSaveButton";
import BootstrapCancelButton from "./buttons/BootstrapCancelButton";
import { SketchPicker } from "react-color";
import { getRGBAObjectFromARBGHex, getARGBHexFromRGBACss } from "../utils/ColorUtils";

const useStyles = makeStyles((theme) => ({
    body: {
        margin: "auto",
    },
}));

function SelectColorModal({ onSaveClicked, onCloseClicked, show, defaultColorHex }) {
    const classes = useStyles();

    const [colorRgba, setColorRgba] = useState(getRGBAObjectFromARBGHex(defaultColorHex));

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
            <Modal.Body className={classes.body}>
                <SketchPicker color={colorRgba} onChange={(color) => setColorRgba(color.rgb)} width="300px" />
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    onClick={() => {
                        const rgbaCss = `rgba(${colorRgba.r}, ${colorRgba.g}, ${colorRgba.b}, ${colorRgba.a})`;
                        const colorARBGHex = getARGBHexFromRGBACss(rgbaCss);
                        onSaveClicked(colorARBGHex);
                    }}
                    label={<FormattedMessage id="COMMON.CHOOSE" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default SelectColorModal;
