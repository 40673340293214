import { put, takeLatest } from "redux-saga/effects";
import {
    getProfileBillingTemplates,
    getProfileBillingTemplate,
    addProfileBillingTemplate,
    updateProfileBillingTemplate,
    deleteProfileBillingTemplate,
} from "./billingTemplateCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PROFILE_BILLING_TEMPLATES_REQUEST: "GET_PROFILE_BILLING_TEMPLATES_REQUEST",
    GET_PROFILE_BILLING_TEMPLATES_SUCCESS: "GET_PROFILE_BILLING_TEMPLATES_SUCCESS",
    GET_PROFILE_BILLING_TEMPLATE_REQUEST: "GET_PROFILE_BILLING_TEMPLATE_REQUEST",
    GET_PROFILE_BILLING_TEMPLATE_SUCCESS: "GET_PROFILE_BILLING_TEMPLATE_SUCCESS",
    ADD_PROFILE_BILLING_TEMPLATE_REQUEST: "ADD_PROFILE_BILLING_TEMPLATE_REQUEST",
    ADD_PROFILE_BILLING_TEMPLATE_SUCCESS: "ADD_PROFILE_BILLING_TEMPLATE_SUCCESS",
    UPDATE_PROFILE_BILLING_TEMPLATE_REQUEST: "UPDATE_PROFILE_BILLING_TEMPLATE_REQUEST",
    UPDATE_PROFILE_BILLING_TEMPLATE_SUCCESS: "UPDATE_PROFILE_BILLING_TEMPLATE_SUCCESS",
    DELETE_PROFILE_BILLING_TEMPLATE_REQUEST: "DELETE_PROFILE_BILLING_TEMPLATE_REQUEST",
    DELETE_PROFILE_BILLING_TEMPLATE_SUCCESS: "DELETE_PROFILE_BILLING_TEMPLATE_SUCCESS",
    CLEAR_BILLING_TEMPLATES_STATE: "CLEAR_BILLING_TEMPLATES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_BILLING_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_BILLING_TEMPLATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PROFILE_BILLING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_BILLING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                billingTemplate: action.payload.response,
            };
        }

        case actionTypes.ADD_PROFILE_BILLING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PROFILE_BILLING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                billingTemplate: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PROFILE_BILLING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PROFILE_BILLING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                billingTemplate: action.payload.response,
            };
        }

        case actionTypes.DELETE_PROFILE_BILLING_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PROFILE_BILLING_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                billingTemplate: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_BILLING_TEMPLATES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getProfileBillingTemplates: (profileId, page, perPage) => ({
        type: actionTypes.GET_PROFILE_BILLING_TEMPLATES_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getProfileBillingTemplate: (id) => ({
        type: actionTypes.GET_PROFILE_BILLING_TEMPLATE_REQUEST,
        payload: { id },
    }),

    addProfileBillingTemplate: (profileId, data, callback) => ({
        type: actionTypes.ADD_PROFILE_BILLING_TEMPLATE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateProfileBillingTemplate: (id, originalBillingTemplate, updatedBillingTemplate) => ({
        type: actionTypes.UPDATE_PROFILE_BILLING_TEMPLATE_REQUEST,
        payload: { id, originalBillingTemplate, updatedBillingTemplate },
    }),

    deleteProfileBillingTemplate: (id) => ({
        type: actionTypes.DELETE_PROFILE_BILLING_TEMPLATE_REQUEST,
        payload: { id },
    }),

    clearBillingTemplateState: () => ({
        type: actionTypes.CLEAR_BILLING_TEMPLATES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROFILE_BILLING_TEMPLATES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileBillingTemplates(
                payload.profileId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_PROFILE_BILLING_TEMPLATES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROFILE_BILLING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfileBillingTemplate(payload.id);

            yield put({
                type: actionTypes.GET_PROFILE_BILLING_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROFILE_BILLING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addProfileBillingTemplate(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PROFILE_BILLING_TEMPLATE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PROFILE_BILLING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateProfileBillingTemplate(payload.id, payload.originalBillingTemplate, {
                ...payload.originalBillingTemplate,
                ...payload.updatedBillingTemplate,
            });
            yield put({
                type: actionTypes.UPDATE_PROFILE_BILLING_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PROFILE_BILLING_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteProfileBillingTemplate(payload.id);
            yield put({
                type: actionTypes.DELETE_PROFILE_BILLING_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
