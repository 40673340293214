import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as resourceUtils from "../../app/pages/resources/utils";
import * as jsonpatch from "fast-json-patch";

export const RESOURCES_URL = "v1/admin/profiles/:id/resources";
export const RESOURCES_AND_GROUPS_URL = "v1/admin/profiles/:id/resources-and-groups";
export const RESOURCE_STAFF_DETAILS_URL = "v1/admin/staff/";
export const RESOURCE_ARTICLE_DETAILS_URL = "v1/admin/articles/";
export const RESOURCE_SPACE_DETAILS_URL = "v1/admin/spaces/";
export const RESOURCE_DETAILS_URL = "v1/admin/resources/";
export const RESOURCE_STAFF_ADD_PERMISSIONS_URL = "v1/admin/profiles/:id/staff/permissions";
export const RESOURCE_STAFF_AVAILABLE_PERMISSION_URL = "v1/admin/profiles/:id/staff/permissions?resourceId=";
export const RESOURCES_CREATE_URL = "v1/admin/profiles/";
export const RESOURCE_DELETE_URL = "/v1/admin/resources/:id";
export const RESOURCE_HOURS_URL = "v1/admin/resources/:id/hours";
export const RESOURCE_HOURS_COPY_URL = "v1/admin/resources/:id/hours/copy";
export const RESOURCE_BOOKINGS_URL = "v1/admin/resources/:id/bookings";
export const RESOURCE_IS_AVAILABLE_URL = "v1/admin/resources/:id/is-available";

export const RESOURCE_GROUPS_URL = "v1/admin/profiles/:id/resource-groups";
export const RESOURCE_GROUP_DETAILS_URL = "v1/admin/resource-groups/:id";
export const RESOURCE_GROUP_PATCH_URL = "v1/admin/resource-groups/:id";
export const RESOURCE_GROUPS_DELETE_URL = "v1/admin/resource-groups/:id";
export const RESOURCE_GROUP_RESOURCES_URL = "v1/admin/resource-groups/:id/resources";
export const RESOURCE_GROUP_RESOURCES_DELETE_URL = "v1/admin/resource-group-resources/:id";
export const RESOURCE_GROUP_HOURS_URL = "v1/admin/resource-groups/:id/hours";
export const RESOURCE_GROUP_HOURS_COPY_URL = "v1/admin/resource-groups/:id/hours/copy";

export function getResource(type, id) {
    if (!type) {
        return axios.get(getApiUrl() + RESOURCE_DETAILS_URL + id);
    }

    const resourceType = { type };

    if (resourceUtils.isArticle(resourceType)) {
        return axios.get(getApiUrl() + RESOURCE_ARTICLE_DETAILS_URL + id);
    } else if (resourceUtils.isStaff(resourceType)) {
        return axios.get(getApiUrl() + RESOURCE_STAFF_DETAILS_URL + id);
    } else if (resourceUtils.isSpace(resourceType)) {
        return axios.get(getApiUrl() + RESOURCE_SPACE_DETAILS_URL + id);
    }
}

export function getResources(profileId, page, perPage, search, status, type) {
    const url =
        RESOURCES_URL.replace(":id", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (type ? "&type=" + type : "");

    return axios.get(getApiUrl() + url);
}

export function getResourcesAndGroups(profileId, page, perPage, search, status, type) {
    const url =
        RESOURCES_AND_GROUPS_URL.replace(":id", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (type ? "&type=" + type : "");

    return axios.get(getApiUrl() + url);
}

export function createResource(type, profileId, properties) {
    const resourceType = { type };
    if (resourceUtils.isArticle(resourceType)) {
        return axios.post(getApiUrl() + RESOURCES_CREATE_URL + profileId + "/" + type + "s", properties);
    } else if (resourceUtils.isStaff(resourceType)) {
        return axios.post(getApiUrl() + RESOURCES_CREATE_URL + profileId + "/" + type, properties);
    } else if (resourceUtils.isSpace(resourceType)) {
        return axios.post(getApiUrl() + RESOURCES_CREATE_URL + profileId + "/" + type + "s", properties);
    }
}

export function getStaffAddPermissions(profileId) {
    const url = RESOURCE_STAFF_ADD_PERMISSIONS_URL.replace(":id", profileId);
    return axios.get(getApiUrl() + url);
}

export function getStaffAvailablePermissions(resourceId, profileId) {
    const url = RESOURCE_STAFF_AVAILABLE_PERMISSION_URL.replace(":id", profileId) + resourceId;
    return axios.get(getApiUrl() + url);
}

export function updateResource(type, id, properties) {
    const resourceType = { type };
    if (resourceUtils.isArticle(resourceType)) {
        return axios.put(getApiUrl() + RESOURCE_ARTICLE_DETAILS_URL + id, properties);
    } else if (resourceUtils.isStaff(resourceType)) {
        return axios.put(getApiUrl() + RESOURCE_STAFF_DETAILS_URL + id, properties);
    } else if (resourceUtils.isSpace(resourceType)) {
        return axios.put(getApiUrl() + RESOURCE_SPACE_DETAILS_URL + id, properties);
    }
}

export function deleteResource(id) {
    const url = RESOURCE_DELETE_URL.replace(":id", id);
    return axios.delete(getApiUrl() + url);
}

export function getResourceGroups(profileId, page, perPage) {
    const url = RESOURCE_GROUPS_URL.replace(":id", profileId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function createResourceGroup(profileId, name, description) {
    const url = RESOURCE_GROUPS_URL.replace(":id", profileId);
    return axios.post(getApiUrl() + url, { name, description });
}

export function getResourceGroup(id) {
    const url = RESOURCE_GROUP_DETAILS_URL.replace(":id", id);
    return axios.get(getApiUrl() + url);
}

export function patchResourceGroup(id, originalResourceGroup, changedResourceGroup) {
    const url = RESOURCE_GROUP_PATCH_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalResourceGroup, changedResourceGroup), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteResourceGroup(id) {
    const url = RESOURCE_GROUPS_DELETE_URL.replace(":id", id);
    return axios.delete(getApiUrl() + url);
}

export function getResourceGroupResources(id, page, perPage) {
    const url = RESOURCE_GROUP_RESOURCES_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function createResourceGroupResource(resourceGroupId, resourceIds) {
    const url = RESOURCE_GROUP_RESOURCES_URL.replace(":id", resourceGroupId);
    return axios.post(getApiUrl() + url, { resourceIds });
}

export function deleteResourceGroupResource(resourceGroupResourceId) {
    const url = RESOURCE_GROUP_RESOURCES_DELETE_URL.replace(":id", resourceGroupResourceId);
    return axios.delete(getApiUrl() + url);
}

export function getResourceHours(id, year, week) {
    const url = RESOURCE_HOURS_URL.replace(":id", id) + "?year=" + year + "&week=" + week;
    return axios.get(getApiUrl() + url);
}

export function saveResourceHours(id, days) {
    const url = RESOURCE_HOURS_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, days);
}

export function copyResourceHours(id, fromYear, fromWeek, untilYear, untilWeek) {
    const url = RESOURCE_HOURS_COPY_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, {
        fromYear,
        fromWeek,
        untilYear,
        untilWeek,
    });
}

export function getResourceGroupHours(id, year, week) {
    const url = RESOURCE_GROUP_HOURS_URL.replace(":id", id) + "?year=" + year + "&week=" + week;
    return axios.get(getApiUrl() + url);
}

export function saveResourceGroupHours(id, days) {
    const url = RESOURCE_GROUP_HOURS_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, days);
}

export function copyResourceGroupHours(id, fromYear, fromWeek, untilYear, untilWeek) {
    const url = RESOURCE_GROUP_HOURS_COPY_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, {
        fromYear,
        fromWeek,
        untilYear,
        untilWeek,
    });
}

export function getResourceBookings(id, page, perPage) {
    const url = `${RESOURCE_BOOKINGS_URL.replace(":id", id)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function isResourceAvailable(id, startTime, endTime) {
    const url = `${RESOURCE_IS_AVAILABLE_URL.replace(":id", id)}?startTime=${encodeURIComponent(
        startTime
    )}&endTime=${encodeURIComponent(endTime)}`;
    return axios.get(getApiUrl() + url);
}
