import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    backdrop: {
        backgroundColor: "transparent !important",
        position: "absolute",
        zIndex: 1000,
    },
}));

/**
 * Material UI backdrop, however used with relative parent container and absolute position.
 * This way, it centers page content and not the entire application.
 */
export default function PageBackdrop({ isLoading, children }) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress size={60}></CircularProgress>
            </Backdrop>
            {children}
        </div>
    );
}
