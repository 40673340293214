import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function CommonStatusFilter({ onStatusChanged }) {
    const classes = useStyles();
    const [status, setStatus] = useState("active");

    const handleChange = ({ target: { value } }) => {
        setStatus(value);
        onStatusChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">
                <FormattedMessage id="COMMON.FILTER.STATUS.HEADER" />
            </InputLabel>
            <Select
                labelId="status-filter-label"
                id="status-filter"
                value={status}
                onChange={handleChange}
            >
                <MenuItem value={"active"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ACTIVE" />
                </MenuItem>
                <MenuItem value={"removed"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.REMOVED" />
                </MenuItem>
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default CommonStatusFilter;
