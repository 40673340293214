import React, { useMemo, useCallback } from "react";
import { actions as payoutActions } from "../../../redux/payouts/payoutsRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell, LinkCell } from "../../components/tables/MaterialTableCells";

function PayoutPageRefunds({ getPayoutTransactionRefunds }) {
    const { payout, payoutTransactionRefundsPagination, isLoading } = useSelector((state) => state.payouts);
    const { displayError } = useSelector((state) => state.errors);
    const payoutId = payout?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (payoutId) {
                getPayoutTransactionRefunds(payoutId, pageIndex + 1, pageSize);
            }
        },
        [payoutId, getPayoutTransactionRefunds]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={payoutId}
                        data={useMemo(() => payoutTransactionRefundsPagination.data, [
                            payoutTransactionRefundsPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={payoutTransactionRefundsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        var refund = row.original.refund;
                                        return TextCell(refund.id);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.LIST.HEADER.TRANSACTION" />
                                    ),
                                    accessor: "transactionId",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/transactions/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: ({ row }) => {
                                        var refund = row.original.refund;
                                        return DateTimeCell(refund.createdAt);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYOUT.TRANSACTION_REFUNDS.LIST.HEADER.AMOUNT" />,
                                    accessor: "amount",
                                    Cell: ({ row }) => {
                                        var refund = row.original.refund;
                                        return TextCell(row.original.amount + " " + refund.currency);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...payoutActions, ...errorActions })(PayoutPageRefunds);
