import React from "react";
import { FormattedMessage } from "react-intl";
import ReportFilterDateInterval from "./filters/ReportFilterDateInterval";
import ReportFilterSwitch from "./filters/ReportFilterSwitch";
import moment from "moment";

const ReportFilterFormCustomerAppointmentBookingsAggregated = ({ report, onSaveHasBeenClicked, isDisabled }) => {
    const filterValues = report?.filterValues;
    const filterValuesObj = filterValues ? JSON.parse(filterValues) : {};
    let startTime = filterValuesObj.bookingStartTime
        ? moment(filterValuesObj.bookingStartTime).format("YYYY-MM-DD HH:mm")
        : null;
    let endTime = filterValuesObj.bookingEndTime
        ? moment(filterValuesObj.bookingEndTime).format("YYYY-MM-DD HH:mm")
        : null;
    let includeRemovedBookings = filterValuesObj.includeRemovedBookings;

    return (
        <>
            <ReportFilterDateInterval
                startTimeName="bookingStartTime"
                endTimeName="bookingEndTime"
                defaultStartTime={startTime}
                defaultEndTime={endTime}
                showWithTime={true}
                title={<FormattedMessage id="REPORT.CUSTOMER_APPOINTMENT_BOOKINGS.FILTER.BOOKING_PERIOD.TITLE" />}
                isStartTimeMandatory={true}
                isEndTimeMandatory={false}
                onSaveHasBeenClicked={onSaveHasBeenClicked}
                isDisabled={isDisabled}
            />
            <ReportFilterSwitch
                name="includeRemovedBookings"
                defaultValue={includeRemovedBookings}
                title={
                    <FormattedMessage id="REPORT.CUSTOMER_APPOINTMENT_BOOKINGS.FILTER.INCLUDE_REMOVED_SERVICES.TITLE" />
                }
                isDisabled={isDisabled}
            />
        </>
    );
};

export default ReportFilterFormCustomerAppointmentBookingsAggregated;
