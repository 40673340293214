import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";

function BootstrapCancelButton(props) {
  return (
    <Button variant="secondary" onClick={props.onClick}>
      <FormattedMessage id="COMMON.CANCEL" />
    </Button>
  );
}

export default BootstrapCancelButton;
