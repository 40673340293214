import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROFILE_PAYMENT_TEMPLATES_URL = "v1/admin/profiles/:id/payment-templates";

export const PAYMENT_TEMPLATE_DETAILS_URL = "v1/admin/payment-templates/:id";

export const PAYMENT_TEMPLATE_OPTIONS_URL = "v1/admin/profiles/:id/payment-templates/options";

export function getProfilePaymentTemplates(profileId, page, perPage) {
    let url = `${PROFILE_PAYMENT_TEMPLATES_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getPaymentTemplate(id) {
    return axios.get(getApiUrl() + PAYMENT_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function addProfilePaymentTemplate(profileId, data) {
    return axios.post(getApiUrl() + PROFILE_PAYMENT_TEMPLATES_URL.replace(":id", profileId), data);
}

export function updatePaymentTemplate(id, originalPricingTemplate, changedPricingTemplate) {
    let url = PAYMENT_TEMPLATE_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalPricingTemplate, changedPricingTemplate), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deletePaymentTemplate(id) {
    return axios.delete(getApiUrl() + PAYMENT_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function getPaymentTemplateOptions(profileId) {
    const url = PAYMENT_TEMPLATE_OPTIONS_URL.replace(":id", profileId);
    return axios.get(getApiUrl() + url);
}
