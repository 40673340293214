import React from "react";
import { makeStyles } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    drawerHeaderContainer: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
        boxShadow: "0 0 4px 1px rgba(0,0,0,0.2)",
        zIndex: 1000,
        backgroundColor: "#ffffff",
    },
    drawerHeaderLabel: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export default function DrawerAddBookingHeader({ onDrawerToggle }) {
    const classes = useStyles();

    return (
        <div className={classes.drawerHeaderContainer}>
            <IconButton onClick={() => onDrawerToggle(false)}>
                <ChevronRightIcon />
            </IconButton>
            <Typography variant="h5" className={classes.drawerHeaderLabel}>
                <FormattedMessage id="BOOKING.ADD.DRAWER.TITLE" />
            </Typography>
        </div>
    );
}
