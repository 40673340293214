import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROFILE_PRICING_TEMPLATES_URL = "v1/admin/profiles/:id/pricing-templates";

export const PRICING_TEMPLATE_DETAILS_URL = "v1/admin/pricing-templates/:id";

export const PRICING_TEMPLATE_TIMES_RULES_URL = "v1/admin/pricing-templates/:id/time-rules";

export function getProfilePricingTemplates(profileId, page, perPage) {
    let url = `${PROFILE_PRICING_TEMPLATES_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getPricingTemplate(id) {
    return axios.get(getApiUrl() + PRICING_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function addProfilePricingTemplate(profileId, data) {
    return axios.post(getApiUrl() + PROFILE_PRICING_TEMPLATES_URL.replace(":id", profileId), data);
}

export function updatePricingTemplate(id, originalPricingTemplate, changedPricingTemplate) {
    let url = PRICING_TEMPLATE_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalPricingTemplate, changedPricingTemplate), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deletePricingTemplate(id) {
    return axios.delete(getApiUrl() + PRICING_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function getPricingTemplateRefundRules(pricingTemplateId, page, perPage) {
    let url = `${PRICING_TEMPLATE_TIMES_RULES_URL.replace(":id", pricingTemplateId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function updatePricingTemplateTimeRules(pricingTemplateId, data) {
    return axios.post(getApiUrl() + PRICING_TEMPLATE_TIMES_RULES_URL.replace(":id", pricingTemplateId), data);
}
