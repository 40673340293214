import { put, takeLatest, select } from "redux-saga/effects";
import { getProfile, patchProfile } from "./profilesCrud";
import { actions as authActions } from "../auth/authRedux";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
    UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
    CLEAR_PROFILES_STATE: "CLEAR_PROFILES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                profile: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PROFILE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                profile: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_PROFILES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getProfile: (id) => ({
        type: actionTypes.GET_PROFILE_REQUEST,
        payload: { id },
    }),

    updateProfile: (id, originalProfile, updatedValues) => ({
        type: actionTypes.UPDATE_PROFILE_REQUEST,
        payload: { id, originalProfile, updatedValues },
    }),

    clearProfileState: () => ({
        type: actionTypes.CLEAR_PROFILES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROFILE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfile(payload.id);
            yield put({
                type: actionTypes.GET_PROFILE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PROFILE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchProfile(payload.id, payload.originalProfile, {
                ...payload.originalProfile,
                ...payload.updatedValues,
            });

            yield put({
                type: actionTypes.UPDATE_PROFILE_SUCCESS,
                payload: { response },
            });

            if (response.name !== payload.originalProfile.name) {
                const state = yield select();
                let profile = state.auth.profile;
                profile.name = response.name;
                yield put(authActions.changeUserProfile(profile));
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
