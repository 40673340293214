import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";

export const TRANSACTIONS_URL = "v1/admin/profiles/{profileId}/transactions";
export const TRANSACTIONS_DETAILS_URL = "v1/admin/transactions/";
export const TRANSACTIONS_BOOKINGS_URL = "v1/admin/transactions/{id}/bookings";
export const TRANSACTIONS_REFUNDS_URL = "v1/admin/transactions/{id}/refunds";
export const TRANSACTIONS_ADD_REFUND_URL = "v1/admin/transactions/{id}/refund";
export const TRANSACTIONS_DOCUMENT_VERSIONS_URL = "v1/admin/transactions/{id}/document-versions";
export const TRANSACTIONS_DOWNLOAD_PDF_URL = "v1/admin/transactions/{id}/pdf";
export const TRANSACTIONS_DOCUMENT_VERSIONS_DOWNLOAD_PDF_URL = "v1/admin/transaction-document-versions/{id}/pdf";

export const REFUNDS_URL = "v1/admin/profiles/{profileId}/refunds";

export function getTransactions(
    profileId,
    page,
    perPage,
    search,
    status,
    paymentType,
    paymentStatus,
    promotionCodeFilter,
    startTime,
    endTime
) {
    let url =
        TRANSACTIONS_URL.replace("{profileId}", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (paymentType ? "&paymentType=" + paymentType : "") +
        (paymentStatus ? "&paymentStatus=" + paymentStatus : "") +
        (promotionCodeFilter ? "&promotionCodeFilter=" + promotionCodeFilter : "") +
        (startTime ? "&startTime=" + encodeURIComponent(startTime) : "") +
        (endTime ? "&endTime=" + encodeURIComponent(endTime) : "");
    return axios.get(getApiUrl() + url);
}

export function getTransaction(id) {
    return axios.get(getApiUrl() + TRANSACTIONS_DETAILS_URL + id);
}

export function getTransactionBookings(id, page, perPage) {
    const url = `${TRANSACTIONS_BOOKINGS_URL.replace("{id}", id)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getTransactionRefunds(id, page, perPage) {
    const url = `${TRANSACTIONS_REFUNDS_URL.replace("{id}", id)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function addTransactionRefund(id, amount, reason) {
    const url = `${TRANSACTIONS_ADD_REFUND_URL.replace("{id}", id)}`;
    return axios.post(getApiUrl() + url, { amount, reason });
}

export function getTransactionDocumentVersions(id, page, perPage) {
    const url = `${TRANSACTIONS_DOCUMENT_VERSIONS_URL.replace("{id}", id)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function deleteTransaction(id) {
    return axios.delete(getApiUrl() + `${TRANSACTIONS_DETAILS_URL}${id}`);
}

export function downloadTransactionPdf(id) {
    return axios.get(getApiUrl() + TRANSACTIONS_DOWNLOAD_PDF_URL.replace("{id}", id), {
        responseType: "arraybuffer",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
        },
    });
}

export function downloadTransactionDocumentVersionPdf(id) {
    return axios.get(getApiUrl() + TRANSACTIONS_DOCUMENT_VERSIONS_DOWNLOAD_PDF_URL.replace("{id}", id), {
        responseType: "arraybuffer",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
        },
    });
}

export function getRefunds(profileId, page, perPage, search, startTime, endTime) {
    let url =
        REFUNDS_URL.replace("{profileId}", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (startTime ? "&startTime=" + encodeURIComponent(startTime) : "") +
        (endTime ? "&endTime=" + encodeURIComponent(endTime) : "");
    return axios.get(getApiUrl() + url);
}
