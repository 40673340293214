/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

export function QuickUser() {
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);

    const onLogoutClicked = () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
            toggle.click();
        }
        history.push("/logout");
    };

    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">
                    <FormattedMessage id="USER.SIDEBAR.TITLE" />
                </h3>
                <a
                    href="#"
                    className="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                >
                    <i className="ki ki-close icon-xs text-muted" />
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div className="symbol symbol-100 mr-5">
                        <div
                            className="symbol-label bg-transparent"
                            style={{
                                backgroundImage: `url(${user.avatarUrl})`,
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <a
                            href="#"
                            className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                        >
                            {`${user.firstName} ${user.surname}`}
                        </a>
                        <div className="navi mt-2">
                            <a href="#" className="navi-item">
                                <span className="navi-link p-0 pb-2">
                                    <span className="navi-icon mr-1">
                                        <span className="svg-icon-lg svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Communication/Mail-notification.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </span>
                                    <span className="navi-text text-muted text-hover-primary">
                                        {user.email}
                                    </span>
                                </span>
                            </a>
                        </div>
                        <button
                            className="btn btn-light-primary btn-bold"
                            onClick={onLogoutClicked}
                        >
                            <FormattedMessage id="USER.SIDEBAR.LOGOUT" />
                        </button>
                    </div>
                </div>

                <div className="separator separator-dashed mt-8 mb-5" />
            </div>
        </div>
    );
}
