import React from "react";
import { makeStyles } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import ToolbarSearchField from "./ToolbarSearchField";
import StatusFilter from "./filter/CommonStatusFilter";
import AddCustomerButton from "./buttons/AddCustomerButton";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: theme.spacing(1),
    },
    searchAndFilterContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        "& > div": { margin: theme.spacing(1) },
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
}));

function CustomerListToolbar({ onSearchChanged, onStatusChanged }) {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.searchAndFilterContainer}>
                <ToolbarSearchField onSearchChanged={onSearchChanged} />
                <StatusFilter onStatusChanged={onStatusChanged} />
            </div>
            <div className={classes.buttonContainer}>
                <AddCustomerButton />
            </div>
        </Toolbar>
    );
}

export default CustomerListToolbar;
