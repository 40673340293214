import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../../../redux/auth/authRedux";
import { resetPassword } from "../../../../redux/auth/authCrud";

const schema = Yup.object().shape({
    password: Yup.string()
        .min(8, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 8 }} />)
        .max(60, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 60 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    confirmpassword: Yup.string()
        .min(8, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 8 }} />)
        .max(60, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 60 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
        .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                <FormattedMessage id="AUTH.VALIDATION.PASSWORD_MISMATCH" />
            ),
        }),
});

function ResetPassword(props) {
    const { intl } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [isReset, setReset] = useState(false);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmpassword: "",
        },
        validationSchema: schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            resetPassword(values.password, history.location.search)
                .then(() => {
                    disableLoading();
                    setReset(true);
                })
                .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage({
                            id: "AUTH.ERROR.INVALID_HASH",
                        })
                    );
                });
        },
    });

    return (
        <>
            {!isReset && (
                <div className="login-form login-signin" id="kt_login_signin_form">
                    {/* begin::Head */}
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            <FormattedMessage id="AUTH.RESETPASSWORD.TITLE" />
                        </h3>
                    </div>
                    {/* end::Head */}

                    {/*begin::Form*/}
                    <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                        {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        )}

                        {/* begin: Password */}
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "password"
                                )}`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.password}</div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Password */}

                        {/* begin: Confirm Password */}
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder={intl.formatMessage({
                                    id: "AUTH.INPUT.CONFIRM_PASSWORD",
                                })}
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "confirmpassword"
                                )}`}
                                name="confirmpassword"
                                {...formik.getFieldProps("confirmpassword")}
                            />
                            {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.confirmpassword}</div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Confirm Password */}
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <Link
                                to={`/auth/login`}
                                className="text-dark-50 text-hover-primary my-3 mr-2"
                                id="kt_login_forgot"
                            >
                                <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                            </Link>
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={formik.isSubmitting}
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                            >
                                <span>
                                    <FormattedMessage id="AUTH.BUTTON.RESET" />
                                </span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>
            )}
            {isReset && (
                <div className="text-center">
                    <h4 className="my-5">
                        {intl.formatMessage({ id: "AUTH.RESETPASSWORD.CONFIRMAITION" })} {formik.values.email}
                    </h4>
                    <Link to={`/auth/login`} className="text-light mr-2" id="kt_login_forgot">
                        <button className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
                            {intl.formatMessage({ id: "AUTH.LOGIN.BACK_TO_LOGIN" })}
                        </button>
                    </Link>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
