import React, { useState, useEffect } from "react";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Alert } from "@material-ui/lab";
import { PAYMENT_TYPE_CARD } from "../transactions/utils";

const schema = yup.object().shape({
    nextAmount: yup.number().when("paymentType", (paymentType) => {
        if (paymentType === PAYMENT_TYPE_CARD) {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(5, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 5 }} />);
        }

        return yup
            .number()
            .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
            .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
    }),
    nextAt: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function SubscriptionPageNext({ updateSubscription }) {
    const { subscription, isUpdating } = useSelector((state) => state.subscriptions);
    const [isNextAtDatePickerOpen, setIsNextAtDatePickerOpen] = useState(false);

    const { register, handleSubmit, setValue, errors } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (subscription) {
            register("paymentType");
            setValue("paymentType", subscription.paymentType);
        }
    }, [subscription, register, setValue]);

    const onFormSubmit = (values) => {
        const updatedSubscription = {
            ...subscription,
            nextAmount: values.nextAmount,
            nextAt: moment(values.nextAt).utc().format(),
        };
        updateSubscription(subscription.id, subscription, updatedSubscription);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group">
                            <Alert severity="info">
                                <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.PAYMENT.ALERT_INFO" />
                            </Alert>
                        </div>
                        {/* Cannot update amount of Stripe subscriptions, remove condition once all of those have run out. */}
                        {!subscription?.externalRef && (
                            <div className="form-group row">
                                <label className="col-xl-3 col-form-label">
                                    <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.AMOUNT" />
                                </label>
                                <div className="col-lg-12 col-xl-6">
                                    <div>
                                        <Form.Control
                                            type="number"
                                            className="form-control-lg"
                                            name="nextAmount"
                                            ref={register}
                                            isInvalid={errors.nextAmount}
                                            defaultValue={
                                                subscription?.nextAmount != null
                                                    ? subscription.nextAmount
                                                    : subscription?.amount
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nextAmount?.message}
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.NEXT_AT" />
                            </label>
                            <div className="col-lg-6 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        name="nextAt"
                                        ref={register}
                                        className="form-control-lg"
                                        readOnly
                                        onClick={() => setIsNextAtDatePickerOpen(true)}
                                        defaultValue={
                                            subscription?.nextAt
                                                ? moment(subscription.nextAt).format("YYYY-MM-DD HH:mm")
                                                : ""
                                        }
                                    />
                                    <Form.Text className="text-muted">
                                        <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.NEXT_AT_DESCRIPTION" />
                                    </Form.Text>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </div>
            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast={true}
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={isNextAtDatePickerOpen}
                        onClose={() => setIsNextAtDatePickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue("nextAt", selectedMomentDate.format("YYYY-MM-DD HH:mm"));
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </Card>
    );
}
export default connect(null, subscriptionActions)(SubscriptionPageNext);
