import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { makeStyles, CircularProgress, AppBar, Tabs, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import moment from "moment";
import "moment/locale/sv";
import DrawerHeader from "./DrawerHeader";
import DrawerEntryTime from "./DrawerEntryTime";

import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import DrawerCourseOccasionDetailsTab from "./DrawerCourseOccasionDetailsTab";
import DrawerCourseOccasionSettingsTab from "./DrawerCourseOccasionSettingsTab";
import UpdateEntryModal from "./UpdateEntryModal";
import DeleteEntryModal from "./DeleteEntryModal";
import { isAtLeastStaffPermission } from "../../resources/utils";
import { getEntryTitle, getCalendarSettings } from "../entryUtils";

const useStyles = makeStyles((theme) => ({
    entryDrawerContainer: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#FAFAFA",
        flexDirection: "column",
    },
    detailsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
    },
    detailsLoadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    tabContainer: {
        padding: theme.spacing(2),
    },
    sectionContainer: {
        marginBottom: "16px",
    },
    sectionLabel: {
        marginBottom: "4px",
    },
    accordionHeaderContainer: {
        display: "flex",
        alignItems: "center",
    },
    accordionIconHeaderLabel: {
        marginLeft: "8px",
    },
}));

function DrawerCourseOccasion({
    onDrawerToggle,
    showErrorMessage,
    updateEntryOccasion,
    onDrawerEntryUpdated,
    deleteEntryOccasion,
    onDrawerEntryDeleted,
    deleteEntryOccasionAttendee,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { entry } = useSelector((state) => state.entries);
    const { isDrawerLoading } = useSelector((state) => state.bookingManager);
    const classes = useStyles(entry);

    // Header and common
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // Info tab
    const [attendeeToBeDeleted, setAttendeeToBeDeleted] = useState(null);

    // Settings tab
    const [maxAttendees, setMaxAttendees] = useState(null);
    const [isWaitingListEnabled, setIsWaitingListEnabled] = useState(null);

    const getCurrentStartTime = () => {
        return selectedStartTime ? selectedStartTime : moment(entry.startTime);
    };

    const getCurrentEndTime = () => {
        return selectedEndTime ? selectedEndTime : moment(entry.endTime);
    };

    const onSaveClicked = () => {
        if (selectedStartTime || selectedEndTime) {
            if (!getCurrentStartTime().isBefore(getCurrentEndTime())) {
                showErrorMessage(<FormattedMessage id="CALENDAR.ENTRY.ERROR.INVALID_DATES" />);
                return;
            }
        }

        if (selectedStartTime || selectedEndTime) {
            setShowUpdateConfirmation(true);
            return;
        }

        updateEntry(true);
    };

    const updateEntry = (notifyUser) => {
        const originalData = {
            startTime: entry.startTime,
            endTime: entry.endTime,
            maxAttendees: entry.maxAttendees,
            isWaitingListEnabled: entry.isWaitingListEnabled,
        };

        var updatedData = {
            startTime: selectedStartTime ? selectedStartTime : entry.startTime,
            endTime: selectedEndTime ? selectedEndTime : entry.endTime,
            maxAttendees: maxAttendees == null ? entry.maxAttendees : maxAttendees >= 0 ? maxAttendees : 0,
            isWaitingListEnabled: isWaitingListEnabled == null ? entry.isWaitingListEnabled : isWaitingListEnabled,
            notifyUser,
        };

        updateEntryOccasion(entry.id, originalData, updatedData, () => {
            resetStates();
            if (onDrawerEntryUpdated) {
                onDrawerEntryUpdated();
            }
        });
    };

    const onDeleteAttendeeDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            deleteEntryOccasionAttendee(attendeeToBeDeleted.id);
        }
        setAttendeeToBeDeleted(null);
    };

    const resetStates = () => {
        setSelectedStartTime(null);
        setSelectedEndTime(null);
    };

    return (
        <div className={classes.entryDrawerContainer}>
            <DrawerHeader
                onDrawerToggle={onDrawerToggle}
                onSaveClicked={onSaveClicked}
                onDeleteClicked={() => setShowDeleteConfirmation(true)}
                showEditButtons={isAtLeastStaffPermission(profile.permissionId)}
                title={
                    entry.title ||
                    getEntryTitle(entry?.type, getCalendarSettings(profile.id), null, entry?.service, null)
                }
            />
            <DrawerEntryTime
                startTime={getCurrentStartTime()}
                endTime={getCurrentEndTime()}
                setSelectedStartTime={setSelectedStartTime}
                setSelectedEndTime={setSelectedEndTime}
                allowEdit={isAtLeastStaffPermission(profile.permissionId)}
            />
            <div className={classes.detailsContainer}>
                {isDrawerLoading && (
                    <div className={classes.detailsLoadingContainer}>
                        <CircularProgress size={60} className={classes.loadingProgress} />
                    </div>
                )}

                {!isDrawerLoading && (
                    <>
                        {entry.service.isBookPerOccasion && isAtLeastStaffPermission(profile.permissionId) && (
                            <>
                                <AppBar position="static" color="transparent">
                                    <Tabs
                                        value={selectedTabIndex}
                                        onChange={(event, newValue) => setSelectedTabIndex(newValue)}
                                        variant="fullWidth"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="icon tabs example"
                                    >
                                        <Tab icon={<InfoOutlinedIcon />} aria-label="phone"></Tab>
                                        <Tab icon={<SettingsOutlinedIcon />} aria-label="settings"></Tab>
                                    </Tabs>
                                </AppBar>
                            </>
                        )}

                        {selectedTabIndex === 0 && (
                            <DrawerCourseOccasionDetailsTab
                                setAttendeeToBeDeleted={setAttendeeToBeDeleted}
                                parentClasses={classes}
                            />
                        )}

                        {selectedTabIndex === 1 && (
                            <DrawerCourseOccasionSettingsTab
                                maxAttendees={maxAttendees}
                                setMaxAttendees={setMaxAttendees}
                                isWaitingListEnabled={isWaitingListEnabled}
                                setIsWaitingListEnabled={setIsWaitingListEnabled}
                                parentClasses={classes}
                            />
                        )}
                    </>
                )}
            </div>
            <UpdateEntryModal
                show={showUpdateConfirmation}
                titleLabel={<FormattedMessage id="CALENDAR.COURSE_OCCASION.UPDATE.TITLE" />}
                notifyLabel={<FormattedMessage id="CALENDAR.COURSE_OCCASION.UPDATE.NOTIFY" />}
                onCloseClicked={() => setShowUpdateConfirmation(false)}
                onUpdateClicked={(confirmationValues) => {
                    setShowUpdateConfirmation(false);
                    updateEntry(confirmationValues.notifyUser);
                }}
            />
            <DeleteEntryModal
                show={showDeleteConfirmation}
                titleLabel={<FormattedMessage id="CALENDAR.COURSE_OCCASION.DELETE.TITLE" />}
                notifyLabel={<FormattedMessage id="CALENDAR.COURSE_OCCASION.DELETE.NOTIFY" />}
                showCancellationMessage={false}
                onCloseClicked={() => setShowDeleteConfirmation(false)}
                onDeleteClicked={(values) => {
                    setShowDeleteConfirmation(false);
                    deleteEntryOccasion(entry.id, values.notifyUser, () => {
                        if (onDrawerEntryDeleted) {
                            onDrawerEntryDeleted();
                        }
                    });
                }}
            />
            <YesNoDialog
                title={
                    <FormattedMessage
                        id="CALENDAR.COURSE_OCCASION.ATTENDEES.DELETE.TITLE"
                        values={{
                            attendeeName: attendeeToBeDeleted?.booking
                                ? attendeeToBeDeleted.booking.customer.firstName
                                : attendeeToBeDeleted?.resource
                                ? attendeeToBeDeleted.resource.firstName
                                : null,
                        }}
                    />
                }
                open={attendeeToBeDeleted ? true : false}
                onOpenChanged={() => setAttendeeToBeDeleted(null)}
                onActionClicked={onDeleteAttendeeDialogActionClicked}
            />
        </div>
    );
}

export default injectIntl(connect(null, bookingManagerActions)(DrawerCourseOccasion));
