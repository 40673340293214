import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import * as subscriptionUtils from "./utils";
import SubscriptionCancelModal from "./SubscriptionCancelModal";
import NavigatorOptionsMenu from "../../components/buttons/NavigatorOptionsMenu";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "325px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(1),
        alignItems: "center",
    },
}));

function SubscriptionPageNavigator({ subscriptionId }) {
    const { subscription } = useSelector((state) => state.subscriptions);
    const [showCancelModal, setShowCancelModal] = useState();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        {subscription && subscription.status !== subscriptionUtils.STATUS_CANCELLED && (
                            <NavigatorOptionsMenu
                                menuItems={[
                                    {
                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                        label: <FormattedMessage id="SUBSCRIPTION.CANCEL" />,
                                        onClick: () => setShowCancelModal(true),
                                    },
                                ]}
                            />
                        )}
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        {subscription && (
                            <>
                                <span className="font-weight-bolder font-size-h6 text-dark-75 flex-grow-1">
                                    <FormattedMessage
                                        id="SUBSCRIPTION.DETAILS.TITLE_NAVIGATOR"
                                        values={{ id: subscriptionId }}
                                    />
                                </span>
                                {subscriptionUtils.getSubscriptionStatusCell(subscription.status)}
                            </>
                        )}
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        {subscription?.createdAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.CREATED_AT" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {moment(subscription?.createdAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {subscription?.createdBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.CREATED_BY" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${subscription.createdBy.firstName} ${subscription.createdBy.surname}`}
                                </span>
                            </div>
                        )}
                        {subscription?.customer && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.CUSTOMER" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/customers/${subscription.customer.id}`}>
                                        {`${subscription.customer.firstName} ${subscription.customer.surname}`}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {subscription?.paymentMethod && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.PAYMENT_TYPE" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/payment-methods/${subscription.paymentMethod.id}`}>
                                        {subscription.paymentMethod.name}
                                    </Link>
                                </span>
                            </div>
                        )}
                        {subscription?.bookingId && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.BOOKING" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    <Link to={`/bookings/${subscription.bookingId}`}>{subscription.bookingId}</Link>
                                </span>
                            </div>
                        )}
                        {subscription?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.CANCELLED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(subscription.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {subscription?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="SUBSCRIPTION.CANCELLED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${subscription.deletedBy.firstName} ${subscription.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/subscriptions/${subscriptionId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SUBSCRIPTION.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/subscriptions/${subscriptionId}/transactions`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {(subscription?.status === subscriptionUtils.STATUS_ACTIVE ||
                        subscription?.status === subscriptionUtils.STATUS_UNDER_NOTICE) && (
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to={`/subscriptions/${subscriptionId}/next`}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}></SVG>{" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        <FormattedMessage id="SUBSCRIPTION.TRANSACTIONS.NEXT.TITLE" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    )}
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <SubscriptionCancelModal
                show={showCancelModal}
                onCloseClicked={() => setShowCancelModal(false)}
                onSubscriptionCancelled={() => setShowCancelModal(false)}
            />
        </div>
    );
}

export default connect(null, subscriptionActions)(SubscriptionPageNavigator);
