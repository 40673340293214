import React from "react";
import { TextCell, ChipCell } from "../../components/tables/MaterialTableCells";
import { FormattedMessage } from "react-intl";

export const SERVICE_TYPE_APPOINTMENT = "appointment";
export const SERVICE_TYPE_COURSE = "course";
export const SERVICE_TYPE_VOUCHER = "voucher";
export const SERVICE_TYPE_MEMBERSHIP = "membership";
export const SERVICE_TYPE_PRODUCT = "product";

export const FILTER_STATUS_ACTIVE = "active";
export const FILTER_STATUS_HIDDEN = "hidden";
export const FILTER_STATUS_REMOVED = "removed";

export const APPOINTMENT_DURATION_TYPE_FIXED = "fixed";
export const APPOINTMENT_DURATION_TYPE_FLEXIBLE = "flexible";
export const APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION = "until_cancellation";
export const APPOINTMENT_DURATION_TYPE_DAYS = "days";

export const PAYMENT_MODE_ON_ARRIVAL = "onArrival";
export const PAYMENT_MODE_OPTIONAL = "optional";
export const PAYMENT_MODE_MANDATORY = "mandatory";

export const PAYMENT_TYPE_MODE_ONE_TIME = "oneTime";
export const PAYMENT_TYPE_MODE_SUBSCRIPTION = "subscription";

export const PAYMENT_METHOD_CARD = "card";
export const PAYMENT_METHOD_SWISH = "swish";
export const PAYMENT_METHOD_INVOICE = "invoice";

export const PRICE_PER_UNIT_MINUTE = "minute";
export const PRICE_PER_UNIT_HOUR = "hour";
export const PRICE_PER_UNIT_DAY = "day";

export const SUBSCRIPTION_RECURRING_UNIT_MONTHLY = "monthly";
export const SUBSCRIPTION_RECURRING_UNIT_QUARTERLY = "quarterly";
export const SUBSCRIPTION_RECURRING_UNIT_YEARLY = "yearly";

export const SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING = "ongoing";
export const SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH = "dayOfMonth";

export const COURSE_OCCASION_STATUS_ACTIVE = "active";
export const COURSE_OCCASION_STATUS_CANCELLED = "cancelled";

export const COURSE_ATTENDEE_STATUS_UNCONFIRMED = "unconfirmed";
export const COURSE_ATTENDEE_STATUS_CONFIRMED = "confirmed";
export const COURSE_ATTENDEE_STATUS_UNPAID = "unpaid";
export const COURSE_ATTENDEE_STATUS_CANCELLED = "cancelled";
export const COURSE_ATTENDEE_STATUS_DELETED = "deleted";

export const OFFER_VALID_FOR_TYPE_ALL_SERVICES = "allServices";
export const OFFER_VALID_FOR_TYPE_SPECIFIC_SERVICES = "specificServices";

export const OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE = "timeFromPurchase";
export const OFFER_VALID_UNTIL_TYPE_TIME_FROM_PURCHASE_UNTIL_CANCELLATION = "timeFromPurchaseUntilCancellation";
export const OFFER_VALID_UNTIL_TYPE_FIXED_END_TIME = "fixedEndTime";

export const STATUS_ACTIVE = "active";
export const STATUS_HIDDEN = "hidden";
export const STATUS_DELETED = "deleted";

// Default color for service, in ARGB hex format
export const SERVICE_COLOR_DEFAULT = "#FF5C755E";

export function getServiceDetailsPageUrl(serviceType, serviceId) {
    if (serviceType === SERVICE_TYPE_APPOINTMENT) {
        return `/services/${serviceId}/appointment`;
    } else if (serviceType === SERVICE_TYPE_COURSE) {
        return `/services/${serviceId}/group-service`;
    } else if (serviceType === SERVICE_TYPE_VOUCHER) {
        return `/services/${serviceId}/voucher`;
    } else if (serviceType === SERVICE_TYPE_MEMBERSHIP) {
        return `/services/${serviceId}/membership`;
    } else if (serviceType === SERVICE_TYPE_PRODUCT) {
        return `/services/${serviceId}/product`;
    }

    return "";
}

export function getServiceCopyUrl(serviceType, serviceId) {
    if (serviceType === SERVICE_TYPE_APPOINTMENT) {
        return `/services/create/appointment?copyServiceId=${serviceId}`;
    } else if (serviceType === SERVICE_TYPE_COURSE) {
        return `/services/create/group-service?copyServiceId=${serviceId}`;
    } else if (serviceType === SERVICE_TYPE_VOUCHER) {
        return `/services/create/voucher?copyServiceId=${serviceId}`;
    } else if (serviceType === SERVICE_TYPE_MEMBERSHIP) {
        return `/services/create/membership?copyServiceId=${serviceId}`;
    } else if (serviceType === SERVICE_TYPE_PRODUCT) {
        return `/services/create/product?copyServiceId=${serviceId}`;
    }

    return "";
}

export function getServiceStatusCell(status) {
    if (status === STATUS_DELETED) {
        return ChipCell("error", <FormattedMessage id="SERVICE.STATUS.DELETED" />);
    } else if (status === STATUS_HIDDEN) {
        return ChipCell("info", <FormattedMessage id="SERVICE.STATUS.HIDDEN" />);
    } else if (status === STATUS_ACTIVE) {
        return ChipCell("success", <FormattedMessage id="SERVICE.STATUS.ACTIVE" />);
    }
    return TextCell();
}

export function getPricePerUnitOption(id, intl) {
    if (id === PRICE_PER_UNIT_MINUTE) {
        return {
            id: PRICE_PER_UNIT_MINUTE,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICE_PER_UNIT.MINUTE",
            }),
        };
    } else if (id === PRICE_PER_UNIT_HOUR) {
        return {
            id: PRICE_PER_UNIT_HOUR,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICE_PER_UNIT.HOUR",
            }),
        };
    } else if (id === PRICE_PER_UNIT_DAY) {
        return {
            id: PRICE_PER_UNIT_DAY,
            name: intl.formatMessage({
                id: "SERVICE.PAYMENT.PRICE_PER_UNIT.DAY",
            }),
        };
    }
}
