import React from "react";
import FilterPartDateInterval from "./filters/FilterPartDateInterval";
import FilterPartSelectService from "./filters/FilterPartSelectService";
import { FormattedMessage } from "react-intl";
import { TextCell, ChipCell } from "../../components/tables/MaterialTableCells";

export const CAMPAIGN_TYPE_SMS = "sms";

export const CAMPAIGN_STATUS_DRAFT = "draft";
export const CAMPAIGN_STATUS_SCHEDULED = "scheduled";
export const CAMPAIGN_STATUS_STARTED = "started";
export const CAMPAIGN_STATUS_FINISHED = "finished";
export const CAMPAIGN_STATUS_ERROR = "error";
export const CAMPAIGN_STATUS_DELETED = "deleted";

export const CAMPAIGN_RECIPIENT_STATUS_PENDING = "pending";
export const CAMPAIGN_RECIPIENT_STATUS_SUCCESS = "success";
export const CAMPAIGN_RECIPIENT_STATUS_ERROR = "error";

export const CAMPAIGN_FILTER_TYPE_CUSTOMERS = "customers";
export const CAMPAIGN_FILTER_TYPE_CUSTOMERS_WITH_BOOKING = "customers_with_booking";
export const CAMPAIGN_FILTER_TYPE_SERVICES_BOOKED = "booked_services";

export const CAMPAIGN_FILTER_PART_DATE_INTERVAL = "date_interval";
export const CAMPAIGN_FILTER_PART_SELECT_SERVICE = "select_service";

export function getCampaignFilterNameFromType(type, intl) {
    if (type === CAMPAIGN_FILTER_TYPE_CUSTOMERS) {
        return intl.formatMessage({ id: "CAMPAIGN.RECIPIENTS_FILTER.TYPE.CUSTOMERS" });
    } else if (type === CAMPAIGN_FILTER_TYPE_CUSTOMERS_WITH_BOOKING) {
        return intl.formatMessage({ id: "CAMPAIGN.RECIPIENTS_FILTER.TYPE.CUSTOMERS_WITH_BOOKING" });
    } else if (type === CAMPAIGN_FILTER_TYPE_SERVICES_BOOKED) {
        return intl.formatMessage({ id: "CAMPAIGN.RECIPIENTS_FILTER.TYPE.SERVICES_BOOKED" });
    }

    return "";
}

export function getFilterPart(filter, dataQuery, onSaveHasBeenClicked) {
    if (dataQuery.type === CAMPAIGN_FILTER_PART_DATE_INTERVAL) {
        let title;
        if (filter.type === CAMPAIGN_FILTER_TYPE_CUSTOMERS) {
            title = <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.TYPE.CUSTOMERS.CREATED_AT_INTERVAL" />;
        } else if (filter.type === CAMPAIGN_FILTER_TYPE_CUSTOMERS_WITH_BOOKING) {
            title = (
                <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.TYPE.CUSTOMERS_WITH_BOOKING.CREATED_AT_INTERVAL" />
            );
        } else if (filter.type === CAMPAIGN_FILTER_TYPE_SERVICES_BOOKED) {
            title = <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.TYPE.SERVICES_BOOKED.CREATED_AT_INTERVAL" />;
        }
        return (
            <FilterPartDateInterval dataQuery={dataQuery} title={title} onSaveHasBeenClicked={onSaveHasBeenClicked} />
        );
    } else if (dataQuery.type === CAMPAIGN_FILTER_PART_SELECT_SERVICE) {
        let title = <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.TYPE.SERVICES_BOOKED.SERVICES" />;
        return (
            <FilterPartSelectService dataQuery={dataQuery} title={title} onSaveHasBeenClicked={onSaveHasBeenClicked} />
        );
    }

    return <></>;
}

export function getCampaignDetailsPageUrl(campaignType, campaignId) {
    if (campaignType === CAMPAIGN_TYPE_SMS) {
        return `/campaigns/${campaignId}/sms`;
    }

    return "";
}

export function getCampaignStatusCell(status) {
    if (status === CAMPAIGN_STATUS_DRAFT) {
        return ChipCell("info", <FormattedMessage id="CAMPAIGN.STATUS.DRAFT" />);
    } else if (status === CAMPAIGN_STATUS_SCHEDULED) {
        return ChipCell("info", <FormattedMessage id="CAMPAIGN.STATUS.SCHEDULED" />);
    } else if (status === CAMPAIGN_STATUS_STARTED) {
        return ChipCell("warning", <FormattedMessage id="CAMPAIGN.STATUS.STARTED" />);
    } else if (status === CAMPAIGN_STATUS_FINISHED) {
        return ChipCell("success", <FormattedMessage id="CAMPAIGN.STATUS.FINISHED" />);
    } else if (status === CAMPAIGN_STATUS_ERROR) {
        return ChipCell("error", <FormattedMessage id="CAMPAIGN.STATUS.ERROR" />);
    } else if (status === CAMPAIGN_STATUS_DELETED) {
        return ChipCell("error", <FormattedMessage id="CAMPAIGN.STATUS.DELETED" />);
    }
    return TextCell();
}

export function isCampaignUpdateable(status) {
    if (status === CAMPAIGN_STATUS_DRAFT || status === CAMPAIGN_STATUS_SCHEDULED) return true;
    return false;
}
