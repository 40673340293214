import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const CAMPAIGNS_URL = "v1/admin/profiles/{profileId}/campaigns";
export const CAMPAIGN_URL = "v1/admin/campaigns/{id}";
export const CAMPAIGN_RECIPIENT_FILTERS_URL = "v1/admin/campaigns/{id}/recipient-filters";
export const CAMPAIGN_SMS_RECIPIENTS_PREVIEW_URL = "v1/admin/campaigns/{id}/sms/recipients-preview";
export const CAMPAIGN_RECIPIENTS_URL = "v1/admin/campaigns/{id}/recipients";
export const CAMPAIGN_PREVIEW_URL = "v1/admin/campaigns/{id}/sms/preview";
export const CAMPAIGN_PUBLISH_URL = "v1/admin/campaigns/{id}/publish";

export const CAMPAIGN_AVAILABLE_FILTERS_URL = "v1/admin/campaigns/filters";
export const SMS_RECIPIENTS_PREVIEW_URL = "v1/admin/profiles/{profileId}/campaigns/sms/recipients-preview";

export function getCampaigns(profileId, page, perPage) {
    const url = CAMPAIGNS_URL.replace("{profileId}", profileId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getCampaign(campaignId) {
    const url = CAMPAIGN_URL.replace("{id}", campaignId);
    return axios.get(getApiUrl() + url);
}

export function addCampaign(profileId, campaignType, data) {
    const url = `${CAMPAIGNS_URL.replace("{profileId}", profileId)}/${campaignType}`;
    return axios.post(getApiUrl() + url, data);
}

export function patchCampaign(campaignId, originalCampaign, changedCampaign) {
    const url = CAMPAIGN_URL.replace("{id}", campaignId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalCampaign, changedCampaign), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteCampaign(campaignId) {
    const url = CAMPAIGN_URL.replace("{id}", campaignId);
    return axios.delete(getApiUrl() + url);
}

export function getCampaignRecipientFilters(campaignId, page, perPage) {
    const url = `${CAMPAIGN_RECIPIENT_FILTERS_URL.replace("{id}", campaignId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function addCampaignRecipientFilter(campaignId, data) {
    const url = `${CAMPAIGN_RECIPIENT_FILTERS_URL.replace("{id}", campaignId)}`;
    return axios.post(getApiUrl() + url, data);
}

export function deleteCampaignRecipientFilter(campaignId, recipientFilterId) {
    const url = `${CAMPAIGN_RECIPIENT_FILTERS_URL.replace("{id}", campaignId)}/${recipientFilterId}`;
    return axios.delete(getApiUrl() + url);
}

export function getCampaignSmsPreviewRecipients(campaignId, page, perPage) {
    const url = `${CAMPAIGN_SMS_RECIPIENTS_PREVIEW_URL.replace("{id}", campaignId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getCampaignRecipients(campaignId, page, perPage) {
    const url = `${CAMPAIGN_RECIPIENTS_URL.replace("{id}", campaignId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getCampaignPreview(campaignId) {
    const url = CAMPAIGN_PREVIEW_URL.replace("{id}", campaignId);
    return axios.get(getApiUrl() + url);
}

export function publishCampaign(campaignId, data) {
    const url = `${CAMPAIGN_PUBLISH_URL.replace("{id}", campaignId)}`;
    return axios.post(getApiUrl() + url, data);
}

export function getCampaignAvailableFilters() {
    return axios.get(getApiUrl() + CAMPAIGN_AVAILABLE_FILTERS_URL);
}

export function getSmsPreviewRecipients(profileId, page, perPage, recipientFilters) {
    const url = `${SMS_RECIPIENTS_PREVIEW_URL.replace("{profileId}", profileId)}?page=${page}&perPage=${perPage}`;
    return axios.post(getApiUrl() + url, { recipientFilters: recipientFilters });
}
