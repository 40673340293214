import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export const ACTION_YES = "yes";
export const ACTION_NO = "no";

export const YesNoDialog = ({ title, description, open, onOpenChanged, onActionClicked }) => {
    const handleAction = (action) => {
        onActionClicked(action);
        onOpenChanged();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={onOpenChanged}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

                {description && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {description}
                        </DialogContentText>
                    </DialogContent>
                )}

                <DialogActions>
                    <Button onClick={() => handleAction(ACTION_YES)} color="primary">
                        <FormattedMessage id="DIALOG.YESNO.YES" />
                    </Button>

                    <Button onClick={() => handleAction(ACTION_NO)} color="primary" autoFocus>
                        <FormattedMessage id="DIALOG.YESNO.NO" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
