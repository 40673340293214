import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useFormContext } from "react-hook-form";

function CampaignSMSCommonDetailsForm({ campaign }) {
    const { register, watch, errors } = useFormContext();

    const message = watch("message");
    const messageLength = message ? message.length : campaign ? campaign.message.length : 0;

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CAMPAIGN.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-6"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={campaign?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="CAMPAIGN.DETAILS.NAME_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CAMPAIGN.SMS.DETAILS.MESSAGE_TITLE" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-6"
                    name="title"
                    ref={register}
                    isInvalid={errors.title}
                    defaultValue={campaign?.title}
                />
                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="CAMPAIGN.SMS.DETAILS.MESSAGE_TITLE_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CAMPAIGN.SMS.DETAILS.MESSAGE" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    className="form-control form-control-lg col-xl-9"
                    name="message"
                    ref={register}
                    isInvalid={errors.message}
                    defaultValue={campaign?.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message?.message}</Form.Control.Feedback>
                <Form.Text>
                    <FormattedMessage
                        id="CAMPAIGN.SMS.DETAILS.MESSAGE_LENGTH_COUNTER"
                        values={{ length: messageLength }}
                    />
                </Form.Text>
                <Form.Text className="text-muted">
                    <FormattedMessage id="CAMPAIGN.SMS.DETAILS.MESSAGE_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default connect(null, {})(CampaignSMSCommonDetailsForm);
