import React from "react";
import { actions as profileActions } from "../../../redux/profiles/profilesRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
    address: yup.string(),
    postalCode: yup.string(),
    city: yup.string(),
    website: yup.string(),
    email: yup.string(),
    phone: yup.string(),
    financeEmail: yup.string(),
});

function SettingsPageDetails({ updateProfile }) {
    const { profile, isUpdating } = useSelector((state) => state.profiles);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        updateProfile(profile.id, profile, values);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROFILE.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROFILE.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                            <FormattedMessage id="COMMON.SAVE" />
                        </BootstrapSaveButton>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.DETAILS.NAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-6"
                                name="name"
                                ref={register}
                                isInvalid={errors.name}
                                defaultValue={profile?.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.DETAILS.DESCRIPTION" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={8}
                                className="form-control form-control-lg col-xl-9"
                                name="description"
                                ref={register}
                                isInvalid={errors.description}
                                defaultValue={profile?.description}
                            />
                            <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} xl={3}>
                                <Form.Label>
                                    <FormattedMessage id="PROFILE.DETAILS.ADDRESS" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg"
                                    name="address"
                                    ref={register}
                                    isInvalid={errors.address}
                                    defaultValue={profile?.address}
                                />
                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                                <Form.Label>
                                    <FormattedMessage id="PROFILE.DETAILS.POSTAL_CODE" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control-lg"
                                    name="postalCode"
                                    ref={register}
                                    isInvalid={errors.postalCode}
                                    defaultValue={profile?.postalCode}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.postalCode?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xl={3}>
                                <Form.Label>
                                    <FormattedMessage id="PROFILE.DETAILS.CITY" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control-lg"
                                    name="city"
                                    ref={register}
                                    isInvalid={errors.city}
                                    defaultValue={profile?.city}
                                />
                                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.DETAILS.WEBSITE" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-6"
                                name="website"
                                ref={register}
                                isInvalid={errors.website}
                                defaultValue={profile?.website}
                            />
                            <Form.Control.Feedback type="invalid">{errors.website?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.DETAILS.EMAIL" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-4"
                                name="email"
                                ref={register}
                                isInvalid={errors.email}
                                defaultValue={profile?.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.DETAILS.PHONE" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-3"
                                name="phone"
                                ref={register}
                                isInvalid={errors.phone}
                                defaultValue={profile?.phone}
                            />
                            <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="PROFILE.DETAILS.FINANCE_EMAIL" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-4"
                                name="financeEmail"
                                ref={register}
                                isInvalid={errors.financeEmail}
                                defaultValue={profile?.financeEmail}
                            />
                            <Form.Control.Feedback type="invalid">{errors.financeEmail?.message}</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage id="PROFILE.DETAILS.FINANCE_EMAIL_DESCRIPTION" />
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, profileActions)(SettingsPageDetails);
