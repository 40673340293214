import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { v4 as uuidv4 } from "uuid";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import logo from "./logo-icons/apple.svg";

const getAppleRedirectUri = process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URI
const getAppleStatePrefix = process.env.REACT_APP_APPLE_LOGIN_STATE_PREFIX

const AppleButton = () => (
  <AppleSignin
    authOptions={{
      clientId: "com.alf.agendo.apple.signin",
      scope: "name email",
      redirectURI: getAppleRedirectUri,
      state: getAppleStatePrefix + uuidv4(),
    }}
    render={(props) => (
      <AppleBtn {...props}>
        <img
          src={logo}
          style={{
            color: "white",
            width: "20px",
            height: "20px",
            marginRight: "20px",
          }}
          alt=""
        />
        <FormattedMessage id="AUTH.BUTTON.APPLE" />
      </AppleBtn>
    )}
  />
);

const AppleBtn = styled.button`
  color: white;
  background: black;
`;
export default AppleButton;
