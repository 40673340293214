import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import MembershipPageNavigator from "./MembershipPageNavigator";
import MembershipPageDetails from "./MembershipPageDetails";
import MembershipPageBookingSettings from "./MembershipPageBookingSettings";
import MembershipPageCheckoutSettings from "./MembershipPageCheckoutSettings";
import ServicePageAddons from "../ServicePageAddons";
import MembershipPageServices from "./MembershipPageServices";
import MembershipPageBookings from "./MembershipPageBookings";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function MembershipPage({ match, getMembership, clearServiceState, clearDisplayError, clearErrorState }) {
    const serviceId = parseInt(match.params.id, 10);
    const { membership, isLoading, isUpdating } = useSelector((state) => state.services);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!membership || membership.id !== serviceId)) {
            getMembership(serviceId);
        }
    }, [getMembership, membership, serviceId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <MembershipPageNavigator serviceId={serviceId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/services/${serviceId}/membership`}
                            exact={true}
                            to={`/services/${serviceId}/membership/details`}
                        />
                        <Route path={`/services/${serviceId}/membership/details`} component={MembershipPageDetails} />
                        <Route
                            path={`/services/${serviceId}/membership/booking-settings`}
                            component={MembershipPageBookingSettings}
                        />
                        <Route
                            path={`/services/${serviceId}/membership/checkout-settings`}
                            component={MembershipPageCheckoutSettings}
                        />
                        <Route
                            path={`/services/${serviceId}/membership/addons`}
                            render={(props) => <ServicePageAddons {...props} service={membership} />}
                        />
                        <Route path={`/services/${serviceId}/membership/services`} component={MembershipPageServices} />
                        <Route path={`/services/${serviceId}/membership/bookings`} component={MembershipPageBookings} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...serviceActions,
    ...errorActions,
})(MembershipPage);
