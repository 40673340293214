import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    makeStyles,
    Divider,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    menuList: {
        paddingTop: "0",
        paddingBottom: "0",
    },
    menuItem: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        alignItems: "start",
        whiteSpace: "pre-line",
    },
}));

function AddServiceButton() {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const classes = useStyles();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
                className="bg-primary text-light"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ width: "150px", height: "min-content", alignSelf: "center" }}
            >
                <FormattedMessage id="SERVICE.LIST.CREATE.CREATE_BUTTON" />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                style={{ width: "300px", zIndex: 1 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    className={classes.menuList}
                                >
                                    <Link to="/services/create/appointment">
                                        <MenuItem onClick={handleClose} className={classes.menuItem}>
                                            <div>
                                                <FormattedMessage id="SERVICE.TYPE.APPOINTMENT" />
                                            </div>
                                            <div>
                                                <Typography variant="body2" color="textSecondary">
                                                    <FormattedMessage id="SERVICE.TYPE.APPOINTMENT.DESCRIPTION" />
                                                </Typography>
                                            </div>
                                        </MenuItem>
                                    </Link>
                                    <Divider />
                                    <Link to="/services/create/group-service">
                                        <MenuItem onClick={handleClose} className={classes.menuItem}>
                                            <div>
                                                <FormattedMessage id="SERVICE.TYPE.COURSE" />
                                            </div>
                                            <div>
                                                <Typography variant="body2" color="textSecondary">
                                                    <FormattedMessage id="SERVICE.TYPE.COURSE.DESCRIPTION" />
                                                </Typography>
                                            </div>
                                        </MenuItem>
                                    </Link>
                                    <Divider />
                                    <Link to="/services/create/membership">
                                        <MenuItem onClick={handleClose} className={classes.menuItem}>
                                            <div>
                                                <FormattedMessage id="SERVICE.TYPE.MEMBERSHIP" />
                                            </div>
                                            <div>
                                                <Typography variant="body2" color="textSecondary">
                                                    <FormattedMessage id="SERVICE.TYPE.MEMBERSHIP.DESCRIPTION" />
                                                </Typography>
                                            </div>
                                        </MenuItem>
                                    </Link>
                                    <Divider />
                                    <Link to="/services/create/voucher">
                                        <MenuItem onClick={handleClose} className={classes.menuItem}>
                                            <div>
                                                <FormattedMessage id="SERVICE.TYPE.VOUCHER" />
                                            </div>
                                            <div>
                                                <Typography variant="body2" color="textSecondary">
                                                    <FormattedMessage id="SERVICE.TYPE.VOUCHER.DESCRIPTION" />
                                                </Typography>
                                            </div>
                                        </MenuItem>
                                    </Link>
                                    <Divider />
                                    <Link to="/services/create/product">
                                        <MenuItem onClick={handleClose} className={classes.menuItem}>
                                            <div>
                                                <FormattedMessage id="SERVICE.TYPE.PRODUCT" />
                                            </div>
                                            <div>
                                                <Typography variant="body2" color="textSecondary">
                                                    <FormattedMessage id="SERVICE.TYPE.PRODUCT.DESCRIPTION" />
                                                </Typography>
                                            </div>
                                        </MenuItem>
                                    </Link>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default AddServiceButton;
