import { put, takeLatest } from "redux-saga/effects";
import {
    getPaymentMethod,
    patchPaymentMethod,
    deletePaymentMethod,
    addCustomerPaymentMethod,
} from "./paymentMethodCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PAYMENT_METHOD_REQUEST: "GET_PAYMENT_METHOD_REQUEST",
    GET_PAYMENT_METHOD_SUCCESS: "GET_PAYMENT_METHOD_SUCCESS",
    UPDATE_PAYMENT_METHOD_REQUEST: "UPDATE_PAYMENT_METHOD_REQUEST",
    UPDATE_PAYMENT_METHOD_SUCCESS: "UPDATE_PAYMENT_METHOD_SUCCESS",
    DELETE_PAYMENT_METHOD_REQUEST: "DELETE_PAYMENT_METHOD_REQUEST",
    DELETE_PAYMENT_METHOD_SUCCESS: "DELETE_PAYMENT_METHOD_SUCCESS",
    ADD_CUSTOMER_PAYMENT_METHOD_REQUEST: "ADD_CUSTOMER_PAYMENT_METHOD_REQUEST",
    ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS: "ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS",
    CLEAR_PAYMENT_METHOD_STATE: "CLEAR_PAYMENT_METHOD_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYMENT_METHOD_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentMethod: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PAYMENT_METHOD_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentMethod: action.payload.response,
            };
        }

        case actionTypes.DELETE_PAYMENT_METHOD_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentMethod: action.payload.response,
            };
        }

        case actionTypes.ADD_CUSTOMER_PAYMENT_METHOD_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                paymentMethod: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false, isDownloadingPdf: false };
        }

        case actionTypes.CLEAR_PAYMENT_METHOD_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getPaymentMethod: (id) => ({
        type: actionTypes.GET_PAYMENT_METHOD_REQUEST,
        payload: { id },
    }),

    updatePaymentMethod: (id, originalPaymentMethod, updatedPaymentMethod) => ({
        type: actionTypes.UPDATE_PAYMENT_METHOD_REQUEST,
        payload: { id, originalPaymentMethod, updatedPaymentMethod },
    }),

    deletePaymentMethod: (id) => ({
        type: actionTypes.DELETE_PAYMENT_METHOD_REQUEST,
        payload: { id },
    }),

    addCustomerPaymentMethod: (customerId, data, callback) => ({
        type: actionTypes.ADD_CUSTOMER_PAYMENT_METHOD_REQUEST,
        payload: { customerId, data, callback },
    }),

    clearPaymentMethodState: () => ({
        type: actionTypes.CLEAR_PAYMENT_METHOD_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PAYMENT_METHOD_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPaymentMethod(payload.id);

            yield put({
                type: actionTypes.GET_PAYMENT_METHOD_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PAYMENT_METHOD_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchPaymentMethod(payload.id, payload.originalPaymentMethod, {
                ...payload.originalPaymentMethod,
                ...payload.updatedPaymentMethod,
            });
            yield put({
                type: actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PAYMENT_METHOD_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deletePaymentMethod(payload.id);
            yield put({
                type: actionTypes.DELETE_PAYMENT_METHOD_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_CUSTOMER_PAYMENT_METHOD_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addCustomerPaymentMethod(payload.customerId, payload.data);
            yield put({
                type: actionTypes.ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
