/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import LoginRedirect from "./apple/LoginRedirect";
import Registration from "./Registration";
import StaffInvitation from "./StaffInvitation";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";

export function AuthPage() {
    const location = useLocation();
    const history = useHistory();

    const onLoginSuccessful = () => {
        const requestedUrl = location.state?.from;
        if (requestedUrl !== "/" && requestedUrl !== "/logout") {
            history.push(location.state.from);
        }
    };

    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                    id="kt_login"
                >
                    {/*begin::Aside*/}
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
                        }}
                    >
                        {/*begin: Aside Container*/}
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">
                            <SVG src={toAbsoluteUrl("/media/logos/agendo_logo_white.svg")} />

                            {/* end:: Aside header */}

                            {/* start:: Aside content */}
                            <div className="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 className="font-size-h1 mb-5 text-white">
                                    <FormattedMessage id="AUTH.GENERAL.WELCOME_TITLE" />
                                </h3>
                                <p className="font-weight-lighter text-white opacity-80">
                                    <FormattedMessage id="AUTH.GENERAL.WELCOME_DESCRIPTION" />
                                </p>
                            </div>
                            {/* end:: Aside content */}
                        </div>
                        {/*end: Aside Container*/}
                    </div>
                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
                        {/*begin::Content header*/}
                        {location.pathname.indexOf("registration") < 0 && location.pathname.indexOf("invitation") < 0 && (
                            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                                <span className="font-weight-bold text-dark-50">
                                    <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
                                </span>
                                <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                                    <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
                                </Link>
                            </div>
                        )}
                        {/*end::Content header*/}

                        {/* begin::Content body */}
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <Switch>
                                <ContentRoute path="/auth/login">
                                    <Login onLoginSuccessful={onLoginSuccessful} />
                                </ContentRoute>
                                <ContentRoute path="/auth/registration">
                                    <Registration onLoginSuccessful={onLoginSuccessful} />
                                </ContentRoute>
                                <ContentRoute path="/auth/staff-invitation">
                                    <StaffInvitation onLoginSuccessful={onLoginSuccessful} />
                                </ContentRoute>
                                <ContentRoute path="/auth/apple/login-redirect" exact component={LoginRedirect} />
                                <ContentRoute path="/auth/forgot-password/:email?" component={ForgotPassword} />
                                <ContentRoute path="/auth/reset-password/" component={ResetPassword} />
                                <Redirect from="/auth" exact={true} to="/auth/login" />
                                <Redirect
                                    to={{
                                        pathname: "/auth/login",
                                        state: { from: `${location.pathname}${location.search}` },
                                    }}
                                />
                            </Switch>
                        </div>
                        {/*end::Content body*/}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    );
}
