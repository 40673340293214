import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import ResourcePageNavigator from "./ResourcePageNavigator";
import ResourcePageDetailsStaff from "./ResourcePageDetailsStaff";
import ResourcePageDetailsSpace from "./ResourcePageDetailsSpace";
import ResourcePageDetailsArticle from "./ResourcePageDetailsArticle";
import ResourcePageBookings from "./ResourcePageBookings";
import ResourcePageHours from "./ResourcePageHours";
import ResourcePageMedia from "./ResourcePageMedia";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function ResourcePage({ match, getResource, clearResourceState, clearDisplayError, clearErrorState }) {
    const resourceId = parseInt(match.params.id, 10);
    const resourceType = match.params.type;
    const { resource, isLoading, isUpdating } = useSelector((state) => state.resources);
    const { isLoading: isMediaLoading, isUpdating: isMediaUpdating } = useSelector((state) => state.media);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!resource || resource.id !== resourceId)) {
            getResource(resourceType, resourceId);
        }
    }, [getResource, resource, resourceType, resourceId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearResourceState();
            clearErrorState();
        };
    }, [clearResourceState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isMediaLoading || isUpdating || isMediaUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <ResourcePageNavigator resourceId={resourceId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/resources/${resourceId}/staff`}
                            exact={true}
                            to={`/resources/${resourceId}/staff/details`}
                        />
                        <Redirect
                            from={`/resources/${resourceId}/space`}
                            exact={true}
                            to={`/resources/${resourceId}/space/details`}
                        />
                        <Redirect
                            from={`/resources/${resourceId}/article`}
                            exact={true}
                            to={`/resources/${resourceId}/article/details`}
                        />
                        <Redirect
                            from={`/resources/${resourceId}/hours`}
                            exact={true}
                            to={`/resources/${resourceId}/hours`}
                        />
                        <Route path={`/resources/${resourceId}/staff/details`} component={ResourcePageDetailsStaff} />
                        <Route path={`/resources/${resourceId}/space/details`} component={ResourcePageDetailsSpace} />
                        <Route
                            path={`/resources/${resourceId}/article/details`}
                            component={ResourcePageDetailsArticle}
                        />
                        <Route
                            path={`/resources/${resourceId}/${resourceType}/bookings`}
                            component={ResourcePageBookings}
                        />
                        <Route path={`/resources/${resourceId}/${resourceType}/hours`} component={ResourcePageHours} />
                        <Route path={`/resources/${resourceId}/${resourceType}/media`} component={ResourcePageMedia} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...resourceActions, ...errorActions })(ResourcePage);
