import React from "react";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import * as transactionUtils from "./utils";
import moment from "moment";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";

function TransactionPageDetails({ downloadTransactionPdf }) {
    const { transaction, isDownloadingPdf } = useSelector((state) => state.transactions);
    const intl = useIntl();

    const getPaymentTypeText = () => {
        if (!transaction) return "";

        if (transaction.paymentType === transactionUtils.PAYMENT_TYPE_CARD) {
            return intl.formatMessage({
                id: "PAYMENT.PAYMENT_TYPE.CARD",
            });
        } else if (transaction.paymentType === transactionUtils.PAYMENT_TYPE_INVOICE) {
            return intl.formatMessage({
                id: "PAYMENT.PAYMENT_TYPE.INVOICE",
            });
        } else if (transaction.paymentType === transactionUtils.PAYMENT_TYPE_SWISH) {
            return intl.formatMessage({
                id: "PAYMENT.PAYMENT_TYPE.SWISH",
            });
        }

        return "";
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            isLoading={isDownloadingPdf}
                            onClick={() => downloadTransactionPdf(transaction.id, transaction.serialNumber)}
                            label={<FormattedMessage id="COMMON.DOWNLOAD" />}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYMENT.DETAILS.STATUS" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={
                                            transaction
                                                ? intl.formatMessage({
                                                      id: transactionUtils.getTransactionStatusTransactionId(
                                                          transaction.status
                                                      ),
                                                  })
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYMENT.DETAILS.AMOUNT" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={transaction ? `${transaction?.amount} ${transaction?.currency}` : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYMENT.DETAILS.PAYMENT_TYPE" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={getPaymentTypeText()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYMENT.DETAILS.SERIAL_NUMBER" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={transaction?.serialNumber || ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYMENT.DETAILS.CREATED_AT" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={
                                            transaction ? moment(transaction.createdAt).format("YYYY-MM-DD HH:mm") : ""
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYMENT.DETAILS.PAID_AT" />
                            </label>
                            <div className="col-lg-12 col-xl-6">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={
                                            transaction?.paidAt
                                                ? moment(transaction.paidAt).format("YYYY-MM-DD HH:mm")
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {transaction?.periodStart && (
                            <div className="form-group row">
                                <label className="col-xl-3 col-form-label">
                                    <FormattedMessage id="PAYMENT.DETAILS.PERIOD_START" />
                                </label>
                                <div className="col-lg-12 col-xl-6">
                                    <div>
                                        <Form.Control
                                            type="text"
                                            className="form-control-lg"
                                            readOnly
                                            value={moment(transaction.periodStart).format("YYYY-MM-DD")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {transaction?.periodEnd && (
                            <div className="form-group row">
                                <label className="col-xl-3 col-form-label">
                                    <FormattedMessage id="PAYMENT.DETAILS.PERIOD_END" />
                                </label>
                                <div className="col-lg-12 col-xl-6">
                                    <div>
                                        <Form.Control
                                            type="text"
                                            className="form-control-lg"
                                            readOnly
                                            value={moment(transaction.periodEnd).format("YYYY-MM-DD")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {transaction?.externalRef && (
                            <div className="form-group row">
                                <label className="col-xl-3 col-form-label">
                                    <FormattedMessage id="PAYMENT.DETAILS.EXTERNAL_REF" />
                                </label>
                                <div className="col-lg-12 col-xl-6">
                                    <div>
                                        <Form.Control
                                            type="text"
                                            className="form-control-lg"
                                            readOnly
                                            value={transaction?.externalRef || ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, transactionActions)(TransactionPageDetails);
