import React, { useState, useMemo, useCallback } from "react";
import { actions as cancellationTemplateActions } from "../../../redux/cancellationtemplates/cancellationTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell } from "../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import CancellationTemplateRefundRuleAddModal from "./CancellationTemplateRefundRuleAddModal";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        margin: theme.spacing(2),
    },
    isAutomaticRefundsDisabledContainer: {
        display: "flex",
        padding: "100px",
        justifyContent: "center",
        textAlign: "center",
    },
}));

const RULE_SUMMARY_DATE = "2023-12-15 12:00";

function getRuleSummaryExamples(refundRules) {
    const rules = refundRules.length >= 3 ? refundRules.slice(0, 3) : refundRules;
    return (
        <div>
            {rules.map((rule) => {
                const refundDateExample = moment(RULE_SUMMARY_DATE).subtract(rule.minuteLimit, "minutes");
                return (
                    <div key={rule.id}>
                        {rule.refundPercentage >= 0 && (
                            <FormattedMessage
                                id="CANCELLATION_TEMPLATE.REFUND_RULES.RULES_SUMMARY_EXAMPLE_PERCENTAGE"
                                values={{
                                    cancellationDate: refundDateExample.format("YYYY-MM-DD HH:mm"),
                                    percentage: `${rule.refundPercentage * 100}%`,
                                    ruleId: rule.id,
                                }}
                            />
                        )}
                        {rule.refundAmount >= 0 && (
                            <FormattedMessage
                                id="CANCELLATION_TEMPLATE.REFUND_RULES.RULES_SUMMARY_EXAMPLE_AMOUNT"
                                values={{
                                    cancellationDate: refundDateExample.format("YYYY-MM-DD HH:mm"),
                                    amount: rule.refundAmount,
                                    ruleId: rule.id,
                                }}
                            />
                        )}
                        <br />
                    </div>
                );
            })}
        </div>
    );
}

function CancellationTemplateRefundRules({
    getCancellationTemplateRefundRules,
    addCancellationTemplateRefundRule,
    deleteCancellationTemplateRefundRules,
}) {
    const { cancellationTemplate, refundRulesPagination, isLoading } = useSelector(
        (state) => state.cancellationTemplates
    );
    const { displayError } = useSelector((state) => state.errors);
    const cancellationTemplateId = cancellationTemplate?.id;
    const isAutomaticRefundEnabled = cancellationTemplate?.isAutomaticRefund;
    const refundRulesCount = refundRulesPagination.totalRows;
    const classes = useStyles();

    const [showAddModal, setShowAddModal] = useState(false);
    const [ruleToDelete, setRuleToDelete] = useState(null);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (cancellationTemplateId) {
                getCancellationTemplateRefundRules(cancellationTemplateId, pageIndex + 1, pageSize);
            }
        },
        [cancellationTemplateId, getCancellationTemplateRefundRules]
    );

    const tableData = useMemo(() => refundRulesPagination.data, [refundRulesPagination.data]);

    const tableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.LIST.HEADER.ID" />,
                accessor: "id",
                width: "7%",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.LIST.HEADER.MINUTE_LIMIT" />,
                accessor: "minuteLimit",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.LIST.HEADER.REFUND_PERCENTAGE" />,
                accessor: "refundPercentage",
                Cell: (props) => {
                    if (props.value) {
                        return TextCell(`${props.value * 100}%`);
                    }
                    return TextCell("-");
                },
            },
            {
                Header: <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.LIST.HEADER.REFUND_AMOUNT" />,
                accessor: "refundAmount",
                Cell: (props) => {
                    if (props.value) {
                        return TextCell(props.value);
                    }
                    return TextCell("-");
                },
            },
            {
                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                id: "actions",
                textAlign: "center",
                Cell: ({ row }) => {
                    return (
                        <ListActionsButton
                            menuItems={[
                                {
                                    icon: <LinkIcon fontSize="small" />,
                                    label: <FormattedMessage id="COMMON.DELETE" />,
                                    onClick: () => {
                                        setRuleToDelete(row.original);
                                    },
                                },
                            ]}
                        />
                    );
                },
            },
        ],
        []
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        {isAutomaticRefundEnabled && (
                            <BootstrapSaveButton
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowAddModal(true)}
                            ></BootstrapSaveButton>
                        )}
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    {cancellationTemplate && isAutomaticRefundEnabled && (
                        <>
                            <Alert severity="info" className={classes.alertContainer}>
                                {refundRulesCount > 0 ? (
                                    <FormattedMessage
                                        id="CANCELLATION_TEMPLATE.REFUND_RULES.RULES_SUMMARY"
                                        values={{
                                            span: (...chunks) => <span>{chunks}</span>,
                                            strong: (...chunks) => <strong>{chunks}</strong>,
                                            br: <br />,
                                            ruleSummaryDate: RULE_SUMMARY_DATE,
                                            ruleSummaryRows: getRuleSummaryExamples(refundRulesPagination.data),
                                        }}
                                    />
                                ) : (
                                    <FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULES.RULES_SUMMARY_NO_RULES" />
                                )}
                            </Alert>
                            <MaterialReactTable
                                key={cancellationTemplateId}
                                data={tableData}
                                fetchData={fetchData}
                                pageSize={10}
                                rowCount={refundRulesPagination.totalRows}
                                isLoading={isLoading}
                                error={displayError}
                                columns={tableColumns}
                                getCustomCellProps={() => ({
                                    style: { height: "64px" },
                                })}
                            />
                        </>
                    )}
                    {cancellationTemplate && !isAutomaticRefundEnabled && (
                        <div className={classes.isAutomaticRefundsDisabledContainer}>
                            <FormattedMessage
                                id="CANCELLATION_TEMPLATE.REFUND_RULES.DISABLED_TEXT"
                                values={{
                                    p: (...chunks) => <p>{chunks}</p>,
                                    a: (msg) => (
                                        <Link to="details" key={1}>
                                            <FormattedMessage id={"CANCELLATION_TEMPLATE.DETAILS.TITLE"} />
                                        </Link>
                                    ),
                                }}
                            />
                        </div>
                    )}
                </Card.Body>
            </div>
            {showAddModal && (
                <CancellationTemplateRefundRuleAddModal
                    show={showAddModal ? true : false}
                    onCloseClicked={() => setShowAddModal(false)}
                    onSaveClicked={(data) => {
                        addCancellationTemplateRefundRule(cancellationTemplateId, data, () => {
                            fetchData({ pageIndex: 0, pageSize: 10 });
                            setShowAddModal(false);
                        });
                    }}
                />
            )}
            <YesNoDialog
                title={<FormattedMessage id="CANCELLATION_TEMPLATE.REFUND_RULE.DELETE.TEXT" />}
                open={ruleToDelete ? true : false}
                onOpenChanged={() => setRuleToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteCancellationTemplateRefundRules(ruleToDelete.id);
                    }
                    setRuleToDelete(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...cancellationTemplateActions, ...errorActions })(CancellationTemplateRefundRules);
