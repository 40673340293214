import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { actions as entriesActions } from "../../../../redux/entries/entriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import BookingPageNavigator from "./BookingPageNavigator";
import BookingPageDetails from "./BookingPageDetails";
import BookingPageCheckout from "./BookingPageCheckout";
import BookingPageStaffDetails from "./BookingPageStaffDetails";
import BookingPageTransactionLink from "./BookingPageTransactionLink";
import BookingPageAccesses from "./BookingPageAccesses";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles, Snackbar, useTheme, useMediaQuery } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ContentDrawer from "../../../components/drawer/ContentDrawer";
import * as drawerUtils from "../../../components/drawer/utils";
import * as entryUtils from "../entryUtils";
import DrawerAppointment from "../drawer/DrawerAppointment";
import DrawerCourseOccasion from "../drawer/DrawerCourseOccasion";

const drawerWidth = drawerUtils.getDrawerDefaultWidth();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    content: {
        flexGrow: 1,
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function BookingPage({ match, getBooking, clearBookingsState, clearEntriesState, clearDisplayError, clearErrorState }) {
    const bookingId = parseInt(match.params.id, 10);
    const { booking, isLoading, isUpdating } = useSelector((state) => state.bookings);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerEntry, setDrawerEntry] = useState(null);
    const [showMessageError, setShowMessageError] = useState(false);
    const [messageError, setMessageError] = useState(null);

    const contentContainerRef = useRef(null);

    // Determine if small screen for the sake of drawer behaviour (push main content or go above)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (!isLoading && !apiError && (!booking || booking.id !== bookingId)) {
            getBooking(bookingId);
        }
    }, [getBooking, booking, bookingId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearBookingsState();
            clearEntriesState();
            clearErrorState();
        };
    }, [clearBookingsState, clearEntriesState, clearErrorState]);

    const toggleDrawer = (open) => {
        setIsDrawerOpen(open);
    };

    const showDrawerEntry = (entry) => {
        setDrawerEntry(entry);
        setIsDrawerOpen(true);
    };

    const onDrawerEntryDeleted = () => {
        getBooking(bookingId);
    };

    const showErrorMessage = (message) => {
        setMessageError(message);
        setShowMessageError(true);
    };

    const onErrorMessageClosed = () => {
        setShowMessageError(false);
    };

    return (
        <div className={classes.root} ref={contentContainerRef}>
            <PageBackdrop isLoading={isLoading || isUpdating}>
                <Snackbar
                    open={showMessageError}
                    onClose={onErrorMessageClosed}
                    autoHideDuration={4000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MuiAlert elevation={6} variant="filled" severity="error" onClose={onErrorMessageClosed}>
                        {messageError}
                    </MuiAlert>
                </Snackbar>
                <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
                <div
                    className={classes.content}
                    style={
                        isSmallScreen
                            ? {}
                            : drawerUtils.getDrawerOffsetsStyle(
                                  drawerWidth,
                                  isDrawerOpen,
                                  contentContainerRef,
                                  document.getElementsByClassName("container")[0]
                              )
                    }
                >
                    <BookingPageNavigator bookingId={bookingId} />
                    <div className="flex-row-fluid ml-lg-8">
                        <Switch>
                            <Redirect
                                from={`/bookings/${bookingId}`}
                                exact={true}
                                to={`/bookings/${bookingId}/details`}
                            />
                            <Route
                                path={`/bookings/${bookingId}/details`}
                                render={(props) => <BookingPageDetails {...props} showDrawerEntry={showDrawerEntry} />}
                            />
                            <Route path={`/bookings/${bookingId}/checkout`} component={BookingPageCheckout} />
                            <Route path={`/bookings/${bookingId}/staff-details`} component={BookingPageStaffDetails} />
                            <Route
                                path={`/bookings/${bookingId}/payment-link`}
                                component={BookingPageTransactionLink}
                            />
                            <Route path={`/bookings/${bookingId}/accesses`} component={BookingPageAccesses} />
                        </Switch>
                    </div>
                </div>
            </PageBackdrop>

            <ContentDrawer
                isOpen={isDrawerOpen}
                onDrawerToggle={toggleDrawer}
                width={drawerWidth}
                style={{
                    visibility: "hidden" /* Strange bug fix to prevent new booking button not being clickable */,
                }}
                paperProps={{ style: { backgroundColor: "#FAFAFA", visibility: "visible" } }}
            >
                {drawerEntry && entryUtils.isAppointmentEntry(drawerEntry) && (
                    <DrawerAppointment
                        key={drawerEntry.id}
                        onDrawerToggle={toggleDrawer}
                        showErrorMessage={showErrorMessage}
                        onDrawerEntryDeleted={onDrawerEntryDeleted}
                    />
                )}

                {drawerEntry && entryUtils.isCourseOccasionEntry(drawerEntry) && (
                    <DrawerCourseOccasion
                        key={drawerEntry.id}
                        onDrawerToggle={toggleDrawer}
                        showErrorMessage={showErrorMessage}
                        onDrawerEntryDeleted={onDrawerEntryDeleted}
                    />
                )}
            </ContentDrawer>
        </div>
    );
}

export default connect(null, { ...bookingActions, ...entriesActions, ...errorActions })(BookingPage);
