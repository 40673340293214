import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const VOUCHER_DETAILS_URL = "v1/admin/customer-offers/vouchers/:id";
export const VOUCHER_TRANSACTIONS_URL = "v1/admin/customer-offers/vouchers/:id/transactions";

export const MEMBERSHIP_DETAILS_URL = "v1/admin/customer-offers/memberships/:id";
export const MEMBERSHIP_TRANSACTIONS_URL = "v1/admin/customer-offers/memberships/:id/transactions";

export function getVoucher(id) {
    return axios.get(getApiUrl() + VOUCHER_DETAILS_URL.replace(":id", id));
}

export function patchVoucher(id, originalVoucher, changedVoucher) {
    let url = VOUCHER_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalVoucher, changedVoucher), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteVoucher(id) {
    return axios.delete(getApiUrl() + VOUCHER_DETAILS_URL.replace(":id", id));
}

export function getVoucherTransactions(id) {
    return axios.get(getApiUrl() + VOUCHER_TRANSACTIONS_URL.replace(":id", id));
}

export function getMembership(id) {
    return axios.get(getApiUrl() + MEMBERSHIP_DETAILS_URL.replace(":id", id));
}

export function patchMembership(id, originalMembership, changedMembership) {
    let url = MEMBERSHIP_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalMembership, changedMembership), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteMembership(id) {
    return axios.delete(getApiUrl() + MEMBERSHIP_DETAILS_URL.replace(":id", id));
}

export function getMembershipTransactions(id) {
    return axios.get(getApiUrl() + MEMBERSHIP_TRANSACTIONS_URL.replace(":id", id));
}
