import { getSelectedLanguage } from "../_metronic/i18n/LanguageResolver";

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        (config) => {
            const {
                auth: { token },
            } = store.getState();

            if (token) {
                config.headers.Authorization = `"Bearer ${token}`;
            }

            config.headers["Client-Platform"] = "WEB_ADMIN";
            config.headers["Client-Version"] = "1.0.0";
            config.headers["Accept-Language"] = getSelectedLanguage().selectedLang;

            return config;
        },
        (err) => Promise.reject(err)
    );
}
