import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as serviceCategoriesActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../../components/SnackbarError";
import ServiceSectionHeader from "../ServiceSectionHeader";
import ServiceCommonDetailsForm from "../ServiceCommonDetailsForm";
import AppointmentBookingSettingsForm from "./AppointmentBookingSettingsForm";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import {
    SERVICE_COLOR_DEFAULT,
    APPOINTMENT_DURATION_TYPE_FIXED,
    APPOINTMENT_DURATION_TYPE_FLEXIBLE,
    APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION,
    APPOINTMENT_DURATION_TYPE_DAYS,
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    SERVICE_TYPE_APPOINTMENT,
    PRICE_PER_UNIT_MINUTE,
    PRICE_PER_UNIT_HOUR,
    PRICE_PER_UNIT_DAY,
} from "../utils";
import moment from "moment";

const schema = yup.object().shape(
    {
        name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
        description: yup.string(),
        category: yup.object().nullable(),
        color: yup.string(),
        resources: yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
        duration: yup.number().when("durationType", (durationType) => {
            if (durationType !== APPOINTMENT_DURATION_TYPE_FIXED) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(5, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 5 }} />);
            }
        }),
        extendedDuration: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
            .min(5, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 5 }} />),
        minDuration: yup.number().when(["durationType", "checkinTime", "checkoutTime"], {
            is: (durationType, checkinTime, checkoutTime) => {
                return durationType === APPOINTMENT_DURATION_TYPE_DAYS && (checkinTime || checkoutTime);
            },
            then: (schema) => {
                // Doesn't really makes sense to have checkin times with min 1 day so force min 2
                return yup
                    .number()
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />)
                    .min(2, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 2 }} />);
            },
            otherwise: (schema) => {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />);
            },
        }),
        maxDuration: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
            .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />),
        timePickerRangeIncrement: yup
            .number()
            .nullable(true)
            .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
        checkinTime: yup.string().when("checkoutTime", (checkoutTime) => {
            if (!checkoutTime) {
                return yup.string();
            } else {
                return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
            }
        }),
        checkoutTime: yup.string().when("checkinTime", (checkinTime) => {
            if (!checkinTime) {
                return yup.string();
            } else {
                return yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
            }
        }),
        paymentMode: yup.string(),
        paymentTypeMode: yup.string(),
        price: yup.number().when("paymentMode", (paymentMode) => {
            if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
            }
        }),
        pricePerUnit: yup.object(),
        vat: yup.object(),
        currency: yup.object(),
        pricingTemplate: yup.object(),
        paymentTemplate: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
        giftMode: yup.object(),
        proxyUsersMode: yup.object(),
        addonsMode: yup.object(),
        customerMessageMode: yup.object(),
        emailDetails: yup.string(),
    },
    ["checkinTime", "checkoutTime"]
);

function ServicePageAppointmentAdd({
    getServiceOptions,
    addAppointment,
    clearServiceState,
    clearDisplayError,
    clearErrorState,
    location,
    getServiceCopy,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { appointment, serviceOptions, isLoading, serviceToCopy } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const intl = useIntl();
    const queryParams = new URLSearchParams(location.search);
    const copyServiceId = queryParams.get("copyServiceId");

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, watch, setValue } = formMethods;

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    useEffect(() => {
        getServiceOptions(profileId);
    }, [profileId, getServiceOptions]);

    useEffect(() => {
        if (copyServiceId) {
            getServiceCopy(copyServiceId, SERVICE_TYPE_APPOINTMENT);
        }
    }, [copyServiceId, getServiceCopy]);

    useEffect(() => {
        if (serviceToCopy) {
            setValue("name", intl.formatMessage({ id: "SERVICE.COPY.NEW_NAME" }, { serviceName: serviceToCopy.name }));
        }
    }, [serviceToCopy, setValue, intl]);

    const onFormSubmit = (values) => {
        var appointmentData = {
            name: values.name,
            description: values.description,
            categoryId: values?.category?.id,
            color: values.color || SERVICE_COLOR_DEFAULT,
            durationType: values.durationType,
            paymentTypeMode: values.paymentTypeMode,
            price: values.price || 0,
            pricePerUnit: values.pricePerUnit?.id,
            vatId: values.vat.id,
            currencyId: values.currency.id,
            pricingTemplateId: values?.pricingTemplate?.id,
            paymentTemplateId: values?.paymentTemplate?.id,
            cancellationTemplateId: values?.cancellationTemplate?.id,
            reminderTemplateId: values?.reminderTemplate?.id,
            giftMode: values?.giftMode?.id,
            proxyUsersMode: values?.proxyUsersMode?.id,
            addonsMode: values?.addonsMode?.id,
            customerMessageMode: values?.customerMessageMode?.id,
            customerMessage: values.customerMessage,
            emailDetails: values.emailDetails,
            noAvailabilityText: values.noAvailabilityText,
        };

        if (values.resources.length > 0) {
            appointmentData.resourceIds = values.resources.filter((x) => !x.isGroup).map((x) => x.id);
            appointmentData.resourceGroupIds = values.resources.filter((x) => x.isGroup).map((x) => x.id);
        }

        const durationType = appointmentData.durationType;
        if (durationType === APPOINTMENT_DURATION_TYPE_FIXED) {
            appointmentData.duration = values.duration;
            appointmentData.extendedDuration = values.extendedDuration;
            appointmentData.isOvernightBookingsAllowed = values.isOvernightBookingsAllowed;
            appointmentData.isShowStartedSlot = values.isShowStartedSlot;
        } else if (durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE) {
            appointmentData.minDuration = values.minDuration;
            appointmentData.maxDuration = values.maxDuration;
            appointmentData.timePickerRangeIncrement = values.timePickerRangeIncrement;
            appointmentData.isShowStartedSlot = values.isShowStartedSlot;
        } else if (durationType === APPOINTMENT_DURATION_TYPE_UNTIL_CANCELLATION) {
            appointmentData.fixedEndTime = values.fixedEndTime ? moment(values.fixedEndTime).format() : null;
        } else if (durationType === APPOINTMENT_DURATION_TYPE_DAYS) {
            appointmentData.minDuration = values.minDuration ? values.minDuration * 1440 : null;
            appointmentData.maxDuration = values.maxDuration ? values.maxDuration * 1440 : null;
            appointmentData.checkinTime = values.checkinTime;
            appointmentData.checkoutTime = values.checkoutTime;
        }

        // TODO: Future deprecated PaymentSettings values
        appointmentData.paymentMode = values.paymentMode;
        appointmentData.paymentMethods = values.paymentMethods;
        appointmentData.billingTemplateId = values?.billingTemplate?.id;

        if (appointmentData.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION) {
            appointmentData.subscriptionRecurringUnit = values.subscriptionRecurringUnit.id;
            appointmentData.subscriptionRecurringDayMode = values.subscriptionRecurringDayMode.id;
            appointmentData.subscriptionRecurringDayOfMonth = values.subscriptionRecurringDayOfMonth;
            appointmentData.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
        }

        addAppointment(profileId, appointmentData, () => {
            setRedirect(true);
        });
    };

    if (redirect && appointment) {
        return <Redirect to={`/services/${appointment.id}/appointment`} />;
    }

    const durationType = watch("durationType");

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="SERVICE.APPOINTMENT.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="SERVICE.TYPE.APPOINTMENT.DESCRIPTION" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body className="pt-0">
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceSectionHeader intlId={"SERVICE.DETAILS.HEADER"} />
                            <ServiceCommonDetailsForm isAddingService={true} service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.APPOINTMENT.BOOKING_SETTINGS.TITLE"} />
                            <AppointmentBookingSettingsForm serviceOptions={serviceOptions} service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.PAYMENT.HEADER"} />

                            {serviceOptions && (
                                <ServiceCheckoutDetailsForm
                                    serviceOptions={serviceOptions}
                                    pricePerUnits={
                                        durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE ||
                                        durationType === APPOINTMENT_DURATION_TYPE_DAYS
                                            ? [PRICE_PER_UNIT_MINUTE, PRICE_PER_UNIT_HOUR, PRICE_PER_UNIT_DAY]
                                            : []
                                    }
                                    defaultPricePerUnit={
                                        durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE
                                            ? PRICE_PER_UNIT_HOUR
                                            : durationType === APPOINTMENT_DURATION_TYPE_DAYS
                                            ? PRICE_PER_UNIT_DAY
                                            : null
                                    }
                                    service={serviceToCopy}
                                    allowPricingTemplates={
                                        durationType === APPOINTMENT_DURATION_TYPE_FIXED ||
                                        durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE
                                    }
                                />
                            )}

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...serviceActions,
    ...serviceCategoriesActions,
    ...errorActions,
})(ServicePageAppointmentAdd);
