import { put, takeLatest } from "redux-saga/effects";
import {
    getCustomers,
    getCustomer,
    deleteCustomer,
    addCustomer,
    updateCustomer,
    getCustomerBookings,
    getCustomerSubscriptionAvailableBookings,
    getCustomerBookingResources,
    getCustomerTransactions,
    getCustomerSubscriptions,
    getCustomerVouchers,
    getCustomerMemberships,
    getCustomerUserProxies,
    getCustomerPaymentMethods,
} from "./customerCrud";
import { findAndSpliceArrayByProperty } from "../reduxUtils";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
    GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
    GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
    GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
    CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
    CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
    UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
    UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
    DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
    DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
    GET_CUSTOMER_BOOKINGS_REQUEST: "GET_CUSTOMER_BOOKINGS_REQUEST",
    GET_CUSTOMER_BOOKINGS_SUCCESS: "GET_CUSTOMER_BOOKINGS_SUCCESS",
    GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_REQUEST: "GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_REQUEST",
    GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_SUCCESS: "GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_SUCCESS",
    GET_CUSTOMER_BOOKING_RESOURCES_REQUEST: "GET_CUSTOMER_BOOKING_RESOURCES_REQUEST",
    GET_CUSTOMER_BOOKING_RESOURCES_SUCCESS: "GET_CUSTOMER_BOOKING_RESOURCES_SUCCESS",
    GET_CUSTOMER_TRANSACTIONS_REQUEST: "GET_CUSTOMER_TRANSACTIONS_REQUEST",
    GET_CUSTOMER_TRANSACTIONS_SUCCESS: "GET_CUSTOMER_TRANSACTIONS_SUCCESS",
    GET_CUSTOMER_SUBSCRIPTIONS_REQUEST: "GET_CUSTOMER_SUBSCRIPTIONS_REQUEST",
    GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS: "GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS",
    GET_CUSTOMER_VOUCHERS_REQUEST: "GET_CUSTOMER_VOUCHERS_REQUEST",
    GET_CUSTOMER_VOUCHERS_SUCCESS: "GET_CUSTOMER_VOUCHERS_SUCCESS",
    GET_CUSTOMER_MEMBERSHIPS_REQUEST: "GET_CUSTOMER_MEMBERSHIPS_REQUEST",
    GET_CUSTOMER_MEMBERSHIPS_SUCCESS: "GET_CUSTOMER_MEMBERSHIPS_SUCCESS",
    GET_CUSTOMER_USER_PROXIES_REQUEST: "GET_CUSTOMER_USER_PROXIES_REQUEST",
    GET_CUSTOMER_USER_PROXIES_SUCCESS: "GET_CUSTOMER_USER_PROXIES_SUCCESS",
    GET_CUSTOMER_PAYMENT_METHODS_REQUEST: "GET_CUSTOMER_PAYMENT_METHODS_REQUEST",
    GET_CUSTOMER_PAYMENT_METHODS_SUCCESS: "GET_CUSTOMER_PAYMENT_METHODS_SUCCESS",
    CLEAR_CUSTOMERS_STATE: "CLEAR_CUSTOMERS_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    customerBookingsPagination: {
        data: [],
        totalRows: 0,
    },
    customerSubscriptionAvailableBookingsPagination: {
        data: [],
        totalRows: 0,
    },
    customerBookingResourcesPagination: {
        data: [],
        totalRows: 0,
    },
    customerPaymentsPagination: {
        data: [],
        totalRows: 0,
    },
    customerSubscriptionsPagination: {
        data: [],
        totalRows: 0,
    },
    customerVouchersPagination: {
        data: [],
        totalRows: 0,
    },
    customerMembershipsPagination: {
        data: [],
        totalRows: 0,
    },
    customerUserProxiesPagination: {
        data: [],
        totalRows: 0,
    },
    customerPaymentMethodsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMERS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customer: action.payload.response,
            };
        }

        case actionTypes.CREATE_CUSTOMER_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_CUSTOMER_SUCCESS: {
            return { ...state, isLoading: false, customer: action.payload.response };
        }

        case actionTypes.UPDATE_CUSTOMER_REQUEST: {
            return {
                ...state,
                isUpdating: true,
            };
        }

        case actionTypes.UPDATE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                customer: action.payload.response,
            };
        }

        case actionTypes.DELETE_CUSTOMER_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_CUSTOMER_SUCCESS: {
            const customer = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(state.listPagination.data, "id", customer.id);
            return {
                ...state,
                isLoading: false,
                customer: state.customer && state.customer.id === customer.id ? customer : state.customer,
                listPagination: {
                    ...state.listPagination,
                    data: alteredStateArray,
                    totalRows: state.listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_CUSTOMER_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerBookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerSubscriptionAvailableBookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_BOOKING_RESOURCES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_BOOKING_RESOURCES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerBookingResourcesPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerPaymentsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerSubscriptionsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_VOUCHERS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_VOUCHERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerVouchersPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_MEMBERSHIPS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_MEMBERSHIPS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerMembershipsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_USER_PROXIES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_USER_PROXIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerUserProxiesPagination: action.payload.response,
            };
        }

        case actionTypes.GET_CUSTOMER_PAYMENT_METHODS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_CUSTOMER_PAYMENT_METHODS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerPaymentMethodsPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_CUSTOMERS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getCustomers: (profileId, page, perPage, search, status) => ({
        type: actionTypes.GET_CUSTOMERS_REQUEST,
        payload: { profileId, page, perPage, search, status },
    }),

    getCustomer: (id) => ({
        type: actionTypes.GET_CUSTOMER_REQUEST,
        payload: { id },
    }),

    addCustomer: (profileId, properties, callback) => ({
        type: actionTypes.CREATE_CUSTOMER_REQUEST,
        payload: { profileId, properties, callback },
    }),

    updateCustomer: (id, properties) => ({
        type: actionTypes.UPDATE_CUSTOMER_REQUEST,
        payload: { id, properties },
    }),

    deleteCustomer: (id) => ({
        type: actionTypes.DELETE_CUSTOMER_REQUEST,
        payload: { id },
    }),

    getCustomerBookings: (id, page, perPage, search, status) => ({
        type: actionTypes.GET_CUSTOMER_BOOKINGS_REQUEST,
        payload: { id, page, perPage, search, status },
    }),

    getCustomerSubscriptionAvailableBookings: (id, page, perPage, search) => ({
        type: actionTypes.GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_REQUEST,
        payload: { id, page, perPage, search },
    }),

    getCustomerBookingResources: (id, page, perPage) => ({
        type: actionTypes.GET_CUSTOMER_BOOKING_RESOURCES_REQUEST,
        payload: { id, page, perPage },
    }),

    getCustomerTransactions: (id, page, perPage, search, status, paymentType, paymentStatus) => ({
        type: actionTypes.GET_CUSTOMER_TRANSACTIONS_REQUEST,
        payload: { id, page, perPage, search, status, paymentType, paymentStatus },
    }),

    getCustomerSubscriptions: (id, page, perPage, search, status) => ({
        type: actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_REQUEST,
        payload: { id, page, perPage, search, status },
    }),

    getCustomerVouchers: (id, page, perPage) => ({
        type: actionTypes.GET_CUSTOMER_VOUCHERS_REQUEST,
        payload: { id, page, perPage },
    }),

    getCustomerMemberships: (id, page, perPage) => ({
        type: actionTypes.GET_CUSTOMER_MEMBERSHIPS_REQUEST,
        payload: { id, page, perPage },
    }),

    getCustomerUserProxies: (id, page, perPage) => ({
        type: actionTypes.GET_CUSTOMER_USER_PROXIES_REQUEST,
        payload: { id, page, perPage },
    }),

    getCustomerPaymentMethods: (id, page, perPage, search, type) => ({
        type: actionTypes.GET_CUSTOMER_PAYMENT_METHODS_REQUEST,
        payload: { id, page, perPage, search, type },
    }),

    clearCustomerState: () => ({
        type: actionTypes.CLEAR_CUSTOMERS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_CUSTOMERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomers(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status
            );
            yield put({
                type: actionTypes.GET_CUSTOMERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomer(payload.id);
            yield put({
                type: actionTypes.GET_CUSTOMER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_CUSTOMER_REQUEST, function* ({ payload }) {
        try {
            if (payload.properties.phoneNumber && payload.properties.phoneNumber.indexOf("+") < 0) {
                payload.properties.phoneNumber = `+${payload.properties.phoneNumber}`;
            }

            const { data: response } = yield addCustomer(payload.profileId, payload.properties);

            yield put({
                type: actionTypes.CREATE_CUSTOMER_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_CUSTOMER_REQUEST, function* ({ payload }) {
        try {
            if (payload.properties.phoneNumber && payload.properties.phoneNumber.indexOf("+") < 0) {
                payload.properties.phoneNumber = `+${payload.properties.phoneNumber}`;
            }

            const { data: response } = yield updateCustomer(payload.id, payload.properties);

            yield put({
                type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_CUSTOMER_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteCustomer(payload.id);
            yield put({
                type: actionTypes.DELETE_CUSTOMER_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerBookings(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status
            );
            yield put({
                type: actionTypes.GET_CUSTOMER_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerSubscriptionAvailableBookings(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search
            );
            yield put({
                type: actionTypes.GET_CUSTOMER_SUBSCRIPTION_AVAILABLE_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_BOOKING_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerBookingResources(payload.id, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_CUSTOMER_BOOKING_RESOURCES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerTransactions(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.paymentType,
                payload.paymentStatus
            );

            yield put({
                type: actionTypes.GET_CUSTOMER_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerSubscriptions(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status
            );

            yield put({
                type: actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_VOUCHERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerVouchers(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_CUSTOMER_VOUCHERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_MEMBERSHIPS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerMemberships(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_CUSTOMER_MEMBERSHIPS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_USER_PROXIES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerUserProxies(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_CUSTOMER_USER_PROXIES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_CUSTOMER_PAYMENT_METHODS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomerPaymentMethods(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search,
                payload.type
            );

            yield put({
                type: actionTypes.GET_CUSTOMER_PAYMENT_METHODS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
