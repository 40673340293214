import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as customerOfferActions } from "../../../../redux/customeroffers/customerOffersRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import VoucherPageNavigator from "./VoucherPageNavigator";
import VoucherPageDetails from "./VoucherPageDetails";
import VoucherPageTransactions from "./VoucherPageTransactions";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function VoucherPage({ match, getVoucher, clearCustomerOffersState, clearDisplayError, clearErrorState }) {
    const customerOfferId = parseInt(match.params.id, 10);
    const { voucher, isLoading, isUpdating } = useSelector((state) => state.customerOffers);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!voucher || voucher.id !== customerOfferId)) {
            getVoucher(customerOfferId);
        }
    }, [getVoucher, voucher, customerOfferId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearCustomerOffersState();
            clearErrorState();
        };
    }, [clearCustomerOffersState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <VoucherPageNavigator customerOfferId={customerOfferId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/vouchers/${customerOfferId}`}
                            exact={true}
                            to={`/vouchers/${customerOfferId}/details`}
                        />
                        <Route path={`/vouchers/${customerOfferId}/details`} component={VoucherPageDetails} />
                        <Route path={`/vouchers/${customerOfferId}/transactions`} component={VoucherPageTransactions} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...customerOfferActions, ...errorActions })(VoucherPage);
