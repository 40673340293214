import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReportFilterDateInterval from "./filters/ReportFilterDateInterval";
import ReportFilterDropdownMultiple from "./filters/ReportFilterDropdownMultiple";
import ReportFilterSwitch from "./filters/ReportFilterSwitch";
import {
    PAYMENT_TYPE_CARD,
    PAYMENT_TYPE_SWISH,
    PAYMENT_TYPE_INVOICE,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PARTPAID,
    PAYMENT_STATUS_PAID,
} from "../transactions/utils";
import moment from "moment";

const ReportFilterFormTransactions = ({ report, onSaveHasBeenClicked, isDisabled }) => {
    const intl = useIntl();

    const filterValues = report?.filterValues;
    const filterValuesObj = filterValues ? JSON.parse(filterValues) : {};
    let startTime = filterValuesObj.transactionCreatedStart
        ? moment(filterValuesObj.transactionCreatedStart).format("YYYY-MM-DD HH:mm")
        : null;
    let endTime = filterValuesObj.transactionCreatedEnd
        ? moment(filterValuesObj.transactionCreatedEnd).format("YYYY-MM-DD HH:mm")
        : null;
    let includeRemovedTransactions = filterValuesObj.includeRemovedTransactions;

    // Payment type options
    const paymentTypeOptions = [
        {
            id: PAYMENT_TYPE_CARD,
            name: intl.formatMessage({ id: "PAYMENT.PAYMENT_TYPE.CARD" }),
        },
        {
            id: PAYMENT_TYPE_SWISH,
            name: intl.formatMessage({ id: "PAYMENT.PAYMENT_TYPE.SWISH" }),
        },
        {
            id: PAYMENT_TYPE_INVOICE,
            name: intl.formatMessage({ id: "PAYMENT.PAYMENT_TYPE.INVOICE" }),
        },
    ];

    let defaultPaymentTypeOptions = [];
    if (filterValuesObj.paymentTypes) {
        if (filterValuesObj.paymentTypes.includes(PAYMENT_TYPE_CARD)) {
            defaultPaymentTypeOptions.push({
                id: PAYMENT_TYPE_CARD,
                name: intl.formatMessage({ id: "PAYMENT.PAYMENT_TYPE.CARD" }),
            });
        }
        if (filterValuesObj.paymentTypes.includes(PAYMENT_TYPE_SWISH)) {
            defaultPaymentTypeOptions.push({
                id: PAYMENT_TYPE_SWISH,
                name: intl.formatMessage({ id: "PAYMENT.PAYMENT_TYPE.SWISH" }),
            });
        }
        if (filterValuesObj.paymentTypes.includes(PAYMENT_TYPE_INVOICE)) {
            defaultPaymentTypeOptions.push({
                id: PAYMENT_TYPE_INVOICE,
                name: intl.formatMessage({ id: "PAYMENT.PAYMENT_TYPE.INVOICE" }),
            });
        }
    }

    // Payment status options
    const paymentStatusOptions = [
        {
            id: PAYMENT_STATUS_PAID,
            name: intl.formatMessage({ id: "PAYMENT.FILTER.PAYMENT_STATUS.PAID" }),
        },
        {
            id: PAYMENT_STATUS_PARTPAID,
            name: intl.formatMessage({ id: "PAYMENT.FILTER.PAYMENT_STATUS.PARTPAID" }),
        },
        {
            id: PAYMENT_STATUS_UNPAID,
            name: intl.formatMessage({ id: "PAYMENT.FILTER.PAYMENT_STATUS.UNPAID" }),
        },
    ];

    let defaultPaymentStatusOptions = [];
    if (filterValuesObj.paymentStatuses) {
        if (filterValuesObj.paymentStatuses.includes(PAYMENT_STATUS_PAID)) {
            defaultPaymentStatusOptions.push({
                id: PAYMENT_STATUS_PAID,
                name: intl.formatMessage({ id: "PAYMENT.FILTER.PAYMENT_STATUS.PAID" }),
            });
        }
        if (filterValuesObj.paymentStatuses.includes(PAYMENT_STATUS_PARTPAID)) {
            defaultPaymentStatusOptions.push({
                id: PAYMENT_STATUS_PARTPAID,
                name: intl.formatMessage({ id: "PAYMENT.FILTER.PAYMENT_STATUS.PARTPAID" }),
            });
        }
        if (filterValuesObj.paymentStatuses.includes(PAYMENT_STATUS_UNPAID)) {
            defaultPaymentStatusOptions.push({
                id: PAYMENT_STATUS_UNPAID,
                name: intl.formatMessage({ id: "PAYMENT.FILTER.PAYMENT_STATUS.UNPAID" }),
            });
        }
    }

    return (
        <>
            <ReportFilterDateInterval
                startTimeName="transactionCreatedStart"
                endTimeName="transactionCreatedEnd"
                defaultStartTime={startTime}
                defaultEndTime={endTime}
                showWithTime={true}
                title={<FormattedMessage id="REPORT.TRANSACTIONS.FILTER.CREATED_AT.TITLE" />}
                isStartTimeMandatory={true}
                isEndTimeMandatory={true}
                onSaveHasBeenClicked={onSaveHasBeenClicked}
                isDisabled={isDisabled}
            />
            <ReportFilterDropdownMultiple
                name="paymentTypes"
                options={paymentTypeOptions}
                defaultValues={defaultPaymentTypeOptions}
                title={<FormattedMessage id="REPORT.TRANSACTIONS.FILTER.PAYMENT_TYPES.TITLE" />}
                isDisabled={isDisabled}
            />
            <ReportFilterDropdownMultiple
                name="paymentStatuses"
                options={paymentStatusOptions}
                defaultValues={defaultPaymentStatusOptions}
                title={<FormattedMessage id="REPORT.TRANSACTIONS.FILTER.PAYMENT_STATUSES.TITLE" />}
                isDisabled={isDisabled}
            />
            <ReportFilterSwitch
                name="includeRemovedTransactions"
                defaultValue={includeRemovedTransactions}
                title={<FormattedMessage id="REPORT.TRANSACTIONS.FILTER.INCLUDE_REMOVED_TRANSACTIONS.TITLE" />}
                isDisabled={isDisabled}
            />
        </>
    );
};

export default ReportFilterFormTransactions;
