import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions as serviceCategoryActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapAddButtons from "../../../components/buttons/BootstrapAddButtons";
import { useHistory } from "react-router-dom";
import SnackbarError from "../../../components/SnackbarError";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function CategoryPageAdd({ addServiceCategory, clearServiceCategoriesState, clearDisplayError, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { serviceCategory, isLoading } = useSelector((state) => state.serviceCategories);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    useEffect(() => {
        clearServiceCategoriesState();
        clearErrorState();
    }, [clearServiceCategoriesState, clearErrorState]);

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmitRedirect = (values) => {
        addServiceCategoryFromForm(values, true);
    };

    const onFormSubmitAddAnother = (values) => {
        addServiceCategoryFromForm(values, false);
    };

    const addServiceCategoryFromForm = (formData, redirect) => {
        const serviceCategory = {
            name: formData.name,
        };
        addServiceCategory(profile.id, serviceCategory, () => {
            if (redirect) {
                setRedirect(true);
            } else {
                reset();
            }
        });
    };

    if (redirect && serviceCategory) {
        history.push("/service-categories/" + serviceCategory.id);
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">
                        <FormattedMessage id="SERVICE_CATEGORY.GROUP.CREATE.TITLE" />
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="SERVICE_CATEGORY.DETAILS.NAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-6"
                                name="name"
                                ref={register}
                                isInvalid={errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <BootstrapAddButtons
                            addButtonIsLoading={isLoading}
                            addAnotherButtonIsLoading={isLoading}
                            addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                            addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                        />
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, { ...serviceCategoryActions, ...errorActions })(CategoryPageAdd);
