export const DRAWER_PUSH_CONTENT_THRESHOLD = 800;

export function getDrawerDefaultWidth() {
    if (window.screen.width >= DRAWER_PUSH_CONTENT_THRESHOLD) {
        return 500;
    }

    return window.screen.width * 0.9;
}

export function getDrawerOffsetsStyle(drawerWidth, drawerOpen, contentContainer, pageContainer) {
    if (!contentContainer.current || !drawerOpen) {
        return {
            marginRight: -drawerWidth,
            marginLeft: 0,
        };
    }

    // Is there room to adjust to left side?
    let contentContainerRect = contentContainer.current.getBoundingClientRect();
    let pageContainerRect = pageContainer.parentNode.getBoundingClientRect();

    let leftSideRoom = contentContainerRect.left - pageContainerRect.left;
    let rightSideRoom = pageContainerRect.right - contentContainerRect.right;
    let minimumOffsetPadding = 40;
    if (leftSideRoom > drawerWidth / 2) {
        // Plenty of room, keep calendar in original size and split drawer width on both sides of calendar
        return {
            marginLeft: -drawerWidth / 2,
            marginRight: -drawerWidth / 2,
        };
    } else if (leftSideRoom + rightSideRoom > minimumOffsetPadding * 2) {
        // Keep calendar as big as possible with some offset to sides
        return {
            marginRight: -rightSideRoom + minimumOffsetPadding,
            marginLeft: -leftSideRoom + minimumOffsetPadding,
        };
    } else {
        if (window.screen.width >= DRAWER_PUSH_CONTENT_THRESHOLD) {
            // Large screens, push calendar full drawer width
            return {
                marginRight: 0,
            };
        }

        // Small screens, drawer will go above calendar
        return {
            marginRight: -drawerWidth,
            marginLeft: 0,
        };
    }
}
