import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions as statsActions } from "../../../redux/stats/statsRedux";
import ApexChart from "react-apexcharts";
import { Dropdown } from "react-bootstrap";
import OverviewCardStatsDropdown from "./OverviewCardStatsDropdown";
import { PERSPECTIVE_TODAY, PERSPECTIVE_WEEKLY, PERSPECTIVE_MONTHLY } from "./utils";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import moment from "moment";

export function OverviewCardStats({ getOverviewStats, className }) {
    const intl = useIntl();

    const { profile } = useSelector((state) => state.auth);
    const { overviewStats } = useSelector((state) => state.stats);
    const [perspective, setPerspective] = useState(PERSPECTIVE_TODAY);
    const [isPerspectiveDropdownOpen, setIsPerspectiveDropdownOpen] = useState(false);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: `chart-bookings-${perspective}`,
            type: "area",
            height: 200,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 5,
                left: 0,
                blur: 3,
                color: "#D13647",
                opacity: 0.5,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: "solid",
            opacity: 0,
        },
        stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            colors: ["#D13647"],
        },
        xaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: "#B5B5C3",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                },
            },
            crosshairs: {
                show: false,
                position: "front",
                stroke: {
                    color: "#E5EAEE",
                    width: 1,
                    dashArray: 3,
                },
            },
        },
        yaxis: {
            labels: {
                show: false,
                style: {
                    colors: "#B5B5C3",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                },
                formatter: (val) => {
                    return Math.floor(val);
                },
            },
            max: (max) => {
                if (max <= 0) {
                    return 1; // Throws error if <= 0
                }
                return Math.ceil(max * 1.2); // Give some space to chart top from highest point
            },
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: "Poppins",
            },
            marker: {
                show: false,
            },
            x: {
                formatter: (index, series) => {
                    return tooltipXFormatter(index, series, perspective, intl);
                },
            },
        },
        colors: ["transparent"],
        markers: {
            colors: "#F64E60",
            strokeColor: ["#D13647"],
            strokeWidth: 3,
        },
    });

    useEffect(() => {
        getOverviewStats(profile.id, perspective);
    }, [getOverviewStats, profile.id, perspective]);

    const onPerspectiveDropdownItemClicked = (perspective) => {
        setPerspective(perspective);
        setChartOptions({
            ...chartOptions,
            chart: {
                ...chartOptions.chart,
                id: `chart-bookings-${perspective}`, // https://github.com/apexcharts/react-apexcharts/issues/146
            },
            tooltip: {
                ...chartOptions.tooltip,
                x: {
                    ...chartOptions.tooltip.x,
                    formatter: (index, series) => {
                        return tooltipXFormatter(index, series, perspective, intl);
                    },
                },
            },
        });
        setIsPerspectiveDropdownOpen(false);
    };

    return (
        <div className={`card card-custom bg-gray-100 ${className}`}>
            {/* Header */}
            <div className="card-header border-0 bg-danger py-5">
                <h3 className="card-title font-weight-bolder text-white">
                    <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.TITLE" />
                </h3>
                <div className="card-toolbar">
                    <Dropdown
                        className="dropdown-inline"
                        drop="down"
                        alignRight
                        show={isPerspectiveDropdownOpen}
                        onToggle={() => setIsPerspectiveDropdownOpen(!isPerspectiveDropdownOpen)}
                    >
                        <Dropdown.Toggle
                            className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"
                            variant="transparent"
                            id="dropdown-toggle-top"
                        >
                            {getPerspectiveDropdownText(perspective)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <OverviewCardStatsDropdown onItemClicked={onPerspectiveDropdownItemClicked} />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {/* Body */}
            <div className="card-body p-0 position-relative overflow-hidden">
                {/* Chart */}
                <div className="card-rounded-bottom bg-danger">
                    <ApexChart
                        options={chartOptions}
                        series={[
                            {
                                name: intl.formatMessage({
                                    id: "OVERVIEW.OVERVIEW_CARD.CHART.TOOLTIP_LABEL",
                                }),
                                data: overviewStats?.bookingsChart?.data
                                    ? Array.from(overviewStats.bookingsChart.data)
                                    : [],
                            },
                        ]}
                        type="area"
                        height={200}
                    />
                </div>

                {/* Stat */}
                <div className="card-spacer">
                    <div className="font-weight-normal font-size-lg text-muted d-flex justify-content-center mb-4">
                        {getPerspectiveTitleText(perspective)}
                    </div>
                    <div className="row m-0">
                        <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                            <span className="text-warning font-weight-bold font-size-h3 d-block my-2">
                                {overviewStats?.bookings ? overviewStats.bookings.toLocaleString("sv") : 0}
                            </span>
                            <span className="text-warning overview-stats-card-name-label">
                                <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.BOOKINGS" />
                            </span>
                        </div>
                        <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                            <span className="text-primary font-weight-bold font-size-h3 d-block my-2">
                                {overviewStats?.newCustomers ? overviewStats.newCustomers.toLocaleString("sv") : 0}
                            </span>
                            <span className="text-primary overview-stats-card-name-label mt-2">
                                <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.NEW_CUSTOMERS" />
                            </span>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col bg-light-success px-6 py-8 rounded-xl mr-7">
                            <span className="text-success font-weight-bold font-size-h3 d-block my-2">
                                {`${
                                    overviewStats?.bookingsRevenue
                                        ? overviewStats.bookingsRevenue.toLocaleString("sv")
                                        : 0
                                } ${overviewStats?.revenueCurrency || ""}`}
                            </span>
                            <span className="text-success overview-stats-card-name-label mt-2">
                                <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.BOOKINGS_REVENUE" />
                            </span>
                        </div>
                        <div className="col bg-light-danger px-6 py-8 rounded-xl">
                            <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                                {`${
                                    overviewStats?.transactionsVolume
                                        ? overviewStats.transactionsVolume.toLocaleString("sv")
                                        : 0
                                } ${overviewStats?.revenueCurrency || ""}`}
                            </span>
                            <span className="text-danger overview-stats-card-name-label mt-2">
                                <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.TRANSACTIONS_VOLUME" />
                            </span>
                        </div>
                    </div>
                </div>

                {/* Resize */}
                <div className="resize-triggers">
                    <div className="expand-trigger">
                        <div style={{ width: "411px", height: "461px" }} />
                    </div>
                    <div className="contract-trigger" />
                </div>
            </div>
        </div>
    );
}

function getPerspectiveTitleText(perspective) {
    if (perspective === PERSPECTIVE_TODAY) {
        return <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.NUMBERS.TITLE.TODAY" />;
    } else if (perspective === PERSPECTIVE_WEEKLY) {
        return <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.NUMBERS.TITLE.WEEKLY" />;
    } else if (perspective === PERSPECTIVE_MONTHLY) {
        return <FormattedMessage id="OVERVIEW.OVERVIEW_CARD.NUMBERS.TITLE.MONTHLY" />;
    }
}

function getPerspectiveDropdownText(perspective) {
    if (perspective === PERSPECTIVE_TODAY) {
        return <FormattedMessage id="OVERVIEW.PERSPECTIVE.TODAY" />;
    } else if (perspective === PERSPECTIVE_WEEKLY) {
        return <FormattedMessage id="OVERVIEW.PERSPECTIVE.WEEKLY" />;
    } else if (perspective === PERSPECTIVE_MONTHLY) {
        return <FormattedMessage id="OVERVIEW.PERSPECTIVE.MONTHLY" />;
    }
}

function tooltipXFormatter(index, series, perspective, intl) {
    const chartData = series.w.config.series[0].data;
    if (!chartData?.length) return;
    const xValue = chartData[series.dataPointIndex].x;
    const xValueMoment = moment(xValue);
    if (perspective === PERSPECTIVE_TODAY) {
        if (xValueMoment.isSame(moment(), "day")) {
            return intl.formatMessage({
                id: "OVERVIEW.OVERVIEW_CARD.CHART.Y.TODAY",
            });
        } else if (xValueMoment.isSame(moment().subtract(1, "days"), "day")) {
            return intl.formatMessage({
                id: "OVERVIEW.OVERVIEW_CARD.CHART.Y.YESTERDAY",
            });
        } else {
            return xValue;
        }
    } else if (perspective === PERSPECTIVE_WEEKLY) {
        const weekNumber = xValueMoment.isoWeek();
        return intl.formatMessage(
            {
                id: "OVERVIEW.OVERVIEW_CARD.CHART.Y.WEEK",
            },
            { weekNumber: weekNumber }
        );
    } else if (perspective === PERSPECTIVE_MONTHLY) {
        const monthName = xValueMoment.format("MMMM");
        return `${monthName.charAt(0).toUpperCase()}${monthName.slice(1)}`;
    }
    return xValue;
}

export default connect(null, statsActions)(OverviewCardStats);
