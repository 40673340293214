import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import "moment/locale/sv";
import MomentUtils from "@date-io/moment";
import { convertApiColorToHexColor } from "../../../utils/ColorUtils";

const useStyles = makeStyles((theme) => ({
    colorContainer: (entry) => ({
        width: "100%",
        minHeight: "180px",
        backgroundColor: convertApiColorToHexColor(entry.color),
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    }),
    timeContainer: {
        backgroundColor: "white",
        padding: theme.spacing(2),
        textAlign: "center",
        borderRadius: "4px",

        [theme.breakpoints.down("xs")]: {
            padding: "12px",
        },
    },
    timeContainerBetweenIconContainer: {
        backgroundColor: "white",
        padding: theme.spacing(1),
        borderRadius: "50%",
        border: "1px solid #EEF0F8",
    },
    timeContainerBetweenIcon: {
        fontSize: "30px",
        color: "#3F4254",

        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
}));

export default function DrawerEntryTime({ startTime, endTime, setSelectedStartTime, setSelectedEndTime, allowEdit }) {
    const { entry } = useSelector((state) => state.entries);
    const classes = useStyles(entry);
    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);

    return (
        <div className={classes.colorContainer}>
            <div
                className={classes.timeContainer}
                onClick={() => {
                    if (allowEdit) {
                        setIsStartTimePickerOpen(true);
                    }
                }}
            >
                <Typography className={classes.text} variant="h5">
                    {startTime.format("YYYY-MM-DD")}
                </Typography>
                <Typography className={classes.text} variant="h6">
                    {startTime.format("HH:mm")}
                </Typography>
            </div>

            <div className={classes.timeContainerBetweenIconContainer}>
                <ArrowForwardIcon className={classes.timeContainerBetweenIcon} />
            </div>

            <div
                className={classes.timeContainer}
                onClick={() => {
                    if (allowEdit) {
                        setIsEndTimePickerOpen(true);
                    }
                }}
            >
                <Typography className={classes.text} variant="h5">
                    {endTime.format("YYYY-MM-DD")}
                </Typography>
                <Typography className={classes.text} variant="h6">
                    {endTime.format("HH:mm")}
                </Typography>
            </div>

            {/* Since input fields of pickers are harder to customize, keep track of open state*/}
            <div style={{ display: "none" }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={isStartTimePickerOpen}
                        onClose={() => setIsStartTimePickerOpen(false)}
                        onChange={(date) => setSelectedStartTime(date)}
                        value={startTime}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        showTodayButton
                        ampm={false}
                        open={isEndTimePickerOpen}
                        onClose={() => setIsEndTimePickerOpen(false)}
                        onChange={(date) => setSelectedEndTime(date)}
                        value={endTime}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}
