import React from "react";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../../../../redux/auth/authRedux";
import { GOOGLE_LOGIN_URL } from "../../../../redux/auth/authCrud";

function GoogleButton(props) {
    const { intl } = props;
    const responseGoogle = (codeObject) => {
        props.onLoginRequest(GOOGLE_LOGIN_URL, codeObject, () => {
            props.onLoginSuccessful();
        });
    };

    return (
        <GoogleLogin
            clientId="555874678093-jauubok1jvaqktb0a098hut3ojl5kimg.apps.googleusercontent.com"
            buttonText={intl.formatMessage({ id: "AUTH.BUTTON.GOOGLE" })}
            onSuccess={responseGoogle}
            cookiePolicy={"single_host_origin"}
            scope="profile email"
            responseType="code"
            redirectUri="postmessage"
        />
    );
}
export default injectIntl(connect(null, auth.actions)(GoogleButton));
