import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Avatar } from "@material-ui/core";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import DefaultAsyncTypeaheadMultiple from "../../components/DefaultAsyncTypeaheadMultiple";

const useStyles = makeStyles((theme) => ({
    formContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    listItemResourceContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemResourceAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

const schema = yup.object().shape({});

function SelectMultipleResourcesModal({
    getResources,
    onSaveClicked,
    onCloseClicked,
    show,
    titleText,
    descriptionText,
    saveButtonText,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { listPagination, isUpdating } = useSelector((state) => state.resources);
    const classes = useStyles();
    const intl = useIntl();

    useEffect(() => {
        getResources(profile.id, 1, 50);
    }, [getResources, profile.id]);

    const { register, handleSubmit, setValue, watch } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        let addedItems = [];

        for (let i = 0; i < values.selectedItems.length; i++) {
            addedItems.push(values.selectedItems[i].id);
        }

        if (addedItems.length === 0) return;

        onSaveClicked(addedItems);
    };

    const selectedItems = watch("selectedItems");
    if (!selectedItems) {
        register("selectedItems");
    }

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>{descriptionText}</Form.Label>
                            <DefaultAsyncTypeaheadMultiple
                                id="typeahead-resources"
                                options={getResourceOptions(listPagination)}
                                selected={selectedItems}
                                clearButton={false}
                                onChange={(items) => {
                                    if (selectedItems && items.length > selectedItems.length) {
                                        // Don't add duplicates
                                        const addedItem = items[items.length - 1];
                                        if (addedItem) {
                                            const existingIndex = selectedItems.findIndex((x) => x.id === addedItem.id);
                                            if (existingIndex >= 0) return;
                                        }
                                    }
                                    setValue("selectedItems", items);
                                }}
                                onSearch={(search) => getResources(profile.id, 1, 50, search)}
                                placeholder={intl.formatMessage({
                                    id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                })}
                                renderMenuItemChildren={(option, props) => (
                                    <div className={classes.listItemResourceContainer}>
                                        <>
                                            <Avatar
                                                alt={option.name}
                                                src={option.avatarUrl}
                                                className={classes.listItemResourceAvatar}
                                            />
                                            <span>{option.name}</span>
                                        </>
                                    </div>
                                )}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isUpdating}
                        onClick={handleSubmit(onFormSubmit)}
                        label={saveButtonText}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, resourceActions)(SelectMultipleResourcesModal);

function getResourceOptions(resourcesPagination) {
    let options = [];
    if (!resourcesPagination?.data?.length) return options;
    resourcesPagination.data.forEach((resource) => {
        options.push(getResourceOption(resource));
    });

    return options;
}

function getResourceOption(resource) {
    return {
        id: resource.id,
        name: resource.name,
        avatarUrl: resource.avatarUrl,
    };
}
