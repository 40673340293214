import React from "react";
import { actions as serviceCategoryActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function CategoryPageDetails({ updateServiceCategory }) {
    const { serviceCategory, isUpdating } = useSelector((state) => state.serviceCategories);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        var requestData = { ...serviceCategory, name: values.name };
        updateServiceCategory(serviceCategory.id, serviceCategory, requestData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE_CATEGORY.DETAILS.TITLE" />
                        </h3>
                    </div>
                    {serviceCategory && !serviceCategory?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="SERVICE_CATEGORY.DETAILS.NAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-4"
                                name="name"
                                ref={register}
                                isInvalid={errors.name}
                                defaultValue={serviceCategory?.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceCategoryActions)(CategoryPageDetails);
