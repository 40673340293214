import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as parakeyActions } from "../../../../redux/integrations/parakey/parakeyRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import ParakeyPageNavigator from "./ParakeyPageNavigator";
import ParakeyPageDetails from "./ParakeyPageDetails";
import ParakeyPageAccesses from "./ParakeyPageAccesses";
import ParakeyPageAccessKeys from "./ParakeyPageAccessKeys";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function ParakeyPage({ match, getParakeyIntegration, clearFortnoxState, clearDisplayError, clearErrorState }) {
    const integrationId = parseInt(match.params.id, 10);
    const { isLoading, isUpdating, integration } = useSelector((state) => state.fortnox);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!integration || integration.id !== integrationId)) {
            getParakeyIntegration(integrationId);
        }
    }, [getParakeyIntegration, integration, integrationId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            //clearFortnoxState();
            clearErrorState();
        };
    }, [clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <ParakeyPageNavigator integrationId={integrationId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/integrations/parakey/${integrationId}`}
                            exact={true}
                            to={`/integrations/parakey/${integrationId}/details`}
                        />
                        <Route path={`/integrations/parakey/${integrationId}/details`} component={ParakeyPageDetails} />
                        <Route
                            path={`/integrations/parakey/${integrationId}/accesses`}
                            component={ParakeyPageAccesses}
                        />
                        <Route
                            path={`/integrations/parakey/${integrationId}/access-keys`}
                            component={ParakeyPageAccessKeys}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...parakeyActions,
    ...errorActions,
})(ParakeyPage);
