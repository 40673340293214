import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import NavigatorOptionsMenu from "../../components/buttons/NavigatorOptionsMenu";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { getServiceCopyUrl, STATUS_HIDDEN } from "./utils";

function ServiceNavigatorOptionsMenu({ service, onServiceChangeVisibilityClicked, onServiceDeleteClicked }) {
    const history = useHistory();

    if (!service || service.deletedAt) return <></>;

    const isServiceDeleted = service.deletedAt ? true : false;
    const isServiceVisible = service.status !== STATUS_HIDDEN;

    const menuItems = [];

    if (!isServiceDeleted) {
        menuItems.push({
            icon: isServiceVisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />,
            label: isServiceVisible ? (
                <FormattedMessage id="SERVICE.VISBILITY.HIDE" />
            ) : (
                <FormattedMessage id="SERVICE.VISBILITY.SHOW" />
            ),
            onClick: () => onServiceChangeVisibilityClicked(),
        });

        menuItems.push({
            icon: <CopyIcon fontSize="small" />,
            label: <FormattedMessage id="COMMON.COPY" />,
            onClick: () => {
                const url = getServiceCopyUrl(service.type, service.id);
                if (url) {
                    history.push(url);
                }
            },
        });

        menuItems.push({
            icon: <DeleteOutlinedIcon fontSize="small" />,
            label: <FormattedMessage id="COMMON.DELETE" />,
            onClick: () => onServiceDeleteClicked(),
        });
    }

    return <NavigatorOptionsMenu menuItems={menuItems} />;
}

export default ServiceNavigatorOptionsMenu;
