import React, { useMemo, useCallback, useState } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    DateTimeCell,
    TextCell,
    LinkCell,
    TranslationCell,
    DeleteActionButton,
} from "../../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SelectMembershipServiceModal from "./SelectMembershipServiceModal";
import { makeStyles } from "@material-ui/core";
import { getServiceDetailsPageUrl } from "../utils";

const useStyles = makeStyles((theme) => ({
    isAllServicesContainer: {
        display: "flex",
        padding: "100px",
        justifyContent: "center",
        textAlign: "center",
    },
}));

function MembershipPageServices({ getMembershipServices, addMembershipService, deleteMembershipService }) {
    const { membership, membershipServicesPagination, isLoading, isUpdating } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const serviceId = membership?.id;
    const isValidForAllServices = membership?.isValidForAllServices;

    const classes = useStyles();

    const [showModalAddService, setShowModalAddService] = useState(false);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId && !isValidForAllServices) {
                getMembershipServices(serviceId, pageIndex + 1, pageSize);
            }
        },
        [serviceId, isValidForAllServices, getMembershipServices]
    );

    const onAddModalSaveClicked = (data) => {
        addMembershipService(serviceId, data, () => {
            setShowModalAddService(false);
            getMembershipServices(serviceId, 1, 10);
        });
    };

    const tableData = useMemo(() => membershipServicesPagination.data, [membershipServicesPagination.data]);

    const tableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.LIST.HEADER.ID" />,
                accessor: "id",
                width: "7%",
                Cell: (props) => {
                    return TextCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.LIST.HEADER.SERVICE" />,
                accessor: "service",
                Cell: ({ row }) => {
                    if (row.original.service) {
                        const service = row.original.service;
                        const url = getServiceDetailsPageUrl(service.type, service.id);
                        if (!url) return TextCell(service.name);
                        return LinkCell(url, service.name);
                    }

                    return TextCell();
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.LIST.HEADER.DISCOUNT_PERCENTAGE" />,
                accessor: "discountPercentage",
                Cell: (props) => {
                    if (props.value) {
                        return TextCell(`${props.value}%`);
                    }

                    return TextCell();
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.LIST.HEADER.OFFER_MANDATORY" />,
                accessor: "offerMandatory",
                Cell: (props) => {
                    if (props.value) {
                        return TranslationCell("COMMON.YES");
                    }
                    return TranslationCell("COMMON.NO");
                },
            },
            {
                Header: <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.LIST.HEADER.CREATED_AT" />,
                accessor: "createdAt",
                Cell: (props) => {
                    return DateTimeCell(props.value);
                },
            },
            {
                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                id: "delete",
                Cell: ({ row }) => {
                    const content = <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.DELETE.TEXT" />;
                    return DeleteActionButton(row.original.service.name, content, () =>
                        deleteMembershipService(serviceId, row.original.id)
                    );
                },
            },
        ],
        [deleteMembershipService, serviceId]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.MEMBERSHIP.SERVICES.SUBTITLE" />
                        </span>
                    </div>
                    {serviceId && !isValidForAllServices && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setShowModalAddService(true)}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    {!isValidForAllServices && (
                        <MaterialReactTable
                            key={serviceId}
                            data={tableData}
                            fetchData={fetchData}
                            pageSize={10}
                            rowCount={membershipServicesPagination.totalRows}
                            isLoading={isLoading}
                            error={displayError}
                            columns={tableColumns}
                            getCustomCellProps={() => ({
                                style: { height: "64px" },
                            })}
                        />
                    )}
                    {isValidForAllServices && (
                        <div className={classes.isAllServicesContainer}>
                            <FormattedMessage
                                id="SERVICE.MEMBERSHIP.ALL_SERVICES.TEXT"
                                values={{
                                    p: (...chunks) => <p>{chunks}</p>,
                                    a: (msg) => (
                                        <Link to={`booking-settings`} key={1}>
                                            <FormattedMessage id={"SERVICE.MEMBERSHIP.BOOKING_SETTINGS.TITLE"} />
                                        </Link>
                                    ),
                                }}
                            />
                        </div>
                    )}
                </Card.Body>
            </div>
            {showModalAddService && (
                <SelectMembershipServiceModal
                    show={showModalAddService}
                    onCloseClicked={() => setShowModalAddService(false)}
                    onSaveClicked={onAddModalSaveClicked}
                    titleText={<FormattedMessage id={"SERVICE.MEMBERSHIP.SERVICES.ADD.TITLE"} />}
                    descriptionText={<FormattedMessage id={"SERVICE.MEMBERSHIP.SERVICES.ADD.DESCRIPTION"} />}
                    saveButtonText={<FormattedMessage id="COMMON.SAVE" />}
                    isLoading={isUpdating}
                />
            )}
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(MembershipPageServices);
