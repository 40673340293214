import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { makeStyles, IconButton } from "@material-ui/core";
import { getDayName } from "../../utils/DateUtils";
import { Typeahead } from "react-bootstrap-typeahead";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Alert } from "@material-ui/lab";
import { getPricePerUnitOption, PRICE_PER_UNIT_MINUTE, PRICE_PER_UNIT_HOUR } from "../services/utils";

const useStyles = makeStyles((theme) => ({
    addDayContainer: {
        alignItems: "center",
    },
    addDayButton: {
        marginLeft: "10px",
    },
    timeRulesContainer: {},
    timeRuleContainer: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E4E6EF",
        borderRadius: "0.42rem",
        padding: "12px",
        marginTop: "20px",
    },
    timeRuleDayContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
    },
    timeRuleDayLabel: {
        fontSize: "18px",
        width: "80px",
        display: "block",
    },
    addPeriodButton: {
        padding: "0px",
        marginLeft: "10px",
    },
    timeRulePeriodLineContainer: {
        display: "flex",
        alignItems: "center",
    },
    timeRulePeriodsContainer: { width: "110px", display: "block" },
    timeRulePeriodLabel: {
        fontSize: "16px",

        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    timeRulePeriodPriceOptionContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: "20px",

        [theme.breakpoints.down("sm")]: {
            marginLeft: "10px",
        },
    },
    timeRulePeriodPricePerUnitField: {
        marginLeft: "20px",

        [theme.breakpoints.down("sm")]: {
            marginLeft: "10px",
        },
    },
    removePeriodButton: {
        color: "red",
        padding: "0px",
        marginLeft: "20px",

        [theme.breakpoints.down("sm")]: {
            marginLeft: "10px",
        },
    },
    errorMessageContainer: {
        marginTop: "20px",
    },
}));

function PricingTemplateTimeRulesForm({ savedTimeRules, errorMessage }) {
    const { register, watch, setValue } = useFormContext();

    const intl = useIntl();
    const classes = useStyles();

    const [currentChosenDay, setCurrentChosenDay] = useState({
        name: getDayName(1, intl),
        id: 1,
    });
    const [dayOptions, setDayOptions] = useState(getDayOptions(intl));
    const [periodForTimePicker, setPeriodForTimePicker] = useState(null);
    const [periodStartOrEnd, setPeriodStartOrEnd] = useState(null);

    const timeRules = watch("timeRules");
    if (!timeRules) {
        register("timeRules");

        if (!savedTimeRules || savedTimeRules.length === 0) {
            setValue("timeRules", []);
        } else {
            let defaultTimeRules = JSON.parse(JSON.stringify(savedTimeRules));
            setValue("timeRules", defaultTimeRules);
        }
    }

    useEffect(() => {
        if (timeRules) {
            const timeRulesDays = timeRules.map((x) => x.dayOfWeek);
            let notAddedDays = [];
            for (let i = 1; i <= 7; i++) {
                if (timeRulesDays.includes(i)) continue;
                notAddedDays.push({
                    name: getDayName(i, intl),
                    id: i,
                });
            }

            setDayOptions(notAddedDays);
            if (notAddedDays.length > 0 && timeRulesDays.includes(currentChosenDay.id)) {
                const selectNewDayOfWeek = notAddedDays[0].id;
                setCurrentChosenDay({
                    name: getDayName(selectNewDayOfWeek, intl),
                    id: selectNewDayOfWeek,
                });
            }
        }
    }, [timeRules, intl, currentChosenDay.id]);

    const onDayAdded = () => {
        const updatedTimeRules = [...timeRules];
        updatedTimeRules.push({
            dayOfWeek: currentChosenDay.id,
            periods: [getDefaultTimeRule()],
        });
        setValue("timeRules", updatedTimeRules);
        if (dayOptions.length > 1) {
            const otherNotAddedDays = dayOptions.filter((x) => x.id !== currentChosenDay.id);
            setCurrentChosenDay(otherNotAddedDays[0]);
        }
    };

    return (
        <div>
            {dayOptions.length > 0 && (
                <Form.Row className={classes.addDayContainer}>
                    <Form.Group as={Col} xl={3}>
                        <Form.Label>
                            <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.ADJUST.DAY_ADD" />
                        </Form.Label>
                        <Typeahead
                            id="typeahead-dayOfWeek"
                            labelKey={"name"}
                            onChange={(days) => {
                                setCurrentChosenDay(days[0]);
                            }}
                            options={dayOptions}
                            selected={currentChosenDay ? [currentChosenDay] : []}
                            filterBy={() => true}
                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                        />
                    </Form.Group>
                    <div className={classes.addDayButton}>
                        <BootstrapSaveButton
                            onClick={(e) => {
                                e.preventDefault();
                                onDayAdded();
                            }}
                            label={<FormattedMessage id="COMMON.ADD" />}
                        />
                    </div>
                </Form.Row>
            )}
            <div className={classes.timeRulesContainer}>
                {timeRules &&
                    timeRules.map((timeRule) => {
                        return (
                            <div key={timeRule.dayOfWeek} className={classes.timeRuleContainer}>
                                <div className={classes.timeRuleDayContainer}>
                                    <span className={classes.timeRuleDayLabel}>
                                        {getDayName(timeRule.dayOfWeek, intl)}
                                    </span>

                                    <IconButton
                                        onClick={() => {
                                            const lastTimePeriod = timeRule.periods[timeRule.periods.length - 1];
                                            const lastTimeHour = parseInt(lastTimePeriod.endTime.substring(0, 2), 10);
                                            const lastTimeMinute = parseInt(lastTimePeriod.endTime.substring(3), 10);
                                            let newPeriodStartHour = lastTimeHour;
                                            let newPeriodStartMinute = lastTimeMinute;
                                            let newPeriodEndHour = newPeriodStartHour + 1;
                                            let newPeriodEndMinute = 0;
                                            if (lastTimeHour === 23) {
                                                if (lastTimeMinute === 59) return;
                                                newPeriodStartHour = 23;
                                                newPeriodEndHour = 23;

                                                if (lastTimeMinute === 0) {
                                                    newPeriodStartMinute = 0;
                                                    newPeriodEndMinute = 59;
                                                } else {
                                                    newPeriodStartMinute = lastTimeMinute + 1;
                                                    newPeriodEndMinute = newPeriodStartMinute + 1;
                                                }
                                            }

                                            timeRule.periods.push({
                                                startTime: `${
                                                    newPeriodStartHour >= 10
                                                        ? newPeriodStartHour
                                                        : `0${newPeriodStartHour}`
                                                }:${
                                                    newPeriodStartMinute >= 10
                                                        ? newPeriodStartMinute
                                                        : `0${newPeriodStartMinute}`
                                                }`,
                                                endTime: `${
                                                    newPeriodEndHour >= 10 ? newPeriodEndHour : `0${newPeriodEndHour}`
                                                }:${
                                                    newPeriodEndMinute >= 10
                                                        ? newPeriodEndMinute
                                                        : `0${newPeriodEndMinute}`
                                                }`,
                                                pricePerUnit: PRICE_PER_UNIT_HOUR,
                                            });
                                            const updatedTimeRules = [...timeRules];
                                            setValue("timeRules", updatedTimeRules, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        className={classes.addPeriodButton}
                                    >
                                        <AddCircleOutlineIcon fontSize="large" />
                                    </IconButton>
                                </div>

                                {timeRule.periods.map((period) => {
                                    let selectedPricePerUnitOption;
                                    if (period.pricePerUnit === PRICE_PER_UNIT_MINUTE) {
                                        selectedPricePerUnitOption = getPricePerUnitOption(PRICE_PER_UNIT_MINUTE, intl);
                                    } else if (period.pricePerUnit === PRICE_PER_UNIT_HOUR) {
                                        selectedPricePerUnitOption = getPricePerUnitOption(PRICE_PER_UNIT_HOUR, intl);
                                    }

                                    return (
                                        <div
                                            key={`${timeRule.dayOfWeek}_period_${period.startTime}`}
                                            className={classes.timeRulePeriodLineContainer}
                                        >
                                            <div className={classes.timeRulePeriodsContainer}>
                                                <span
                                                    className={classes.timeRulePeriodLabel}
                                                    onClick={() => {
                                                        setPeriodForTimePicker(period);
                                                        setPeriodStartOrEnd("start");
                                                    }}
                                                >{`${period.startTime}`}</span>
                                                <span className={classes.timeRulePeriodLabel}> - </span>
                                                <span
                                                    className={classes.timeRulePeriodLabel}
                                                    onClick={() => {
                                                        setPeriodForTimePicker(period);
                                                        setPeriodStartOrEnd("end");
                                                    }}
                                                >{`${period.endTime}`}</span>
                                            </div>
                                            <div className={classes.timeRulePeriodPriceOptionContainer}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.PRICE.TITLE" />
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control form-control-lg"
                                                        onChange={(e) => {
                                                            const updatedValue =
                                                                e.target.value || "" ? parseFloat(e.target.value) : "";
                                                            period.price = updatedValue;
                                                            const updatedTimeRules = [...timeRules];
                                                            setValue("timeRules", updatedTimeRules, {
                                                                shouldValidate: true,
                                                            });
                                                        }}
                                                        defaultValue={period.price}
                                                    />
                                                </Form.Group>
                                                <Form.Group className={classes.timeRulePeriodPricePerUnitField}>
                                                    <Form.Label>
                                                        <FormattedMessage id="PRICING_TEMPLATE.TIME_RULES.PRICE_PER_UNIT.TITLE" />
                                                    </Form.Label>
                                                    <Typeahead
                                                        id="typeahead-period-pricePerUnit"
                                                        labelKey={"name"}
                                                        onChange={(pricePerUnits) => {
                                                            period.pricePerUnit = pricePerUnits[0].id;
                                                            const updatedTimeRules = [...timeRules];
                                                            setValue("timeRules", updatedTimeRules, {
                                                                shouldValidate: true,
                                                            });
                                                        }}
                                                        options={[
                                                            getPricePerUnitOption(PRICE_PER_UNIT_MINUTE, intl),
                                                            getPricePerUnitOption(PRICE_PER_UNIT_HOUR, intl),
                                                        ]}
                                                        selected={
                                                            selectedPricePerUnitOption
                                                                ? [selectedPricePerUnitOption]
                                                                : []
                                                        }
                                                        filterBy={() => true}
                                                        inputProps={{
                                                            style: { height: "calc(1.5em + 1.65rem + 2px)" },
                                                        }}
                                                    />
                                                </Form.Group>
                                                <IconButton
                                                    onClick={() => {
                                                        let updatedTimeRules;
                                                        if (timeRule.periods.length === 1) {
                                                            // Last period, remove rule
                                                            updatedTimeRules = [...timeRules];
                                                            const removeIndex = updatedTimeRules.findIndex(
                                                                (x) => x.dayOfWeek === timeRule.dayOfWeek
                                                            );
                                                            updatedTimeRules.splice(removeIndex, 1);
                                                        } else {
                                                            const removeIndex = timeRule.periods.findIndex(
                                                                (x) =>
                                                                    x.startTime === period.startTime &&
                                                                    x.endTime === period.endTime
                                                            );
                                                            timeRule.periods.splice(removeIndex, 1);
                                                            updatedTimeRules = [...timeRules];
                                                        }

                                                        if (updatedTimeRules) {
                                                            setValue("timeRules", updatedTimeRules, {
                                                                shouldValidate: true,
                                                            });
                                                        }
                                                    }}
                                                    className={classes.removePeriodButton}
                                                >
                                                    <HighlightOffRoundedIcon fontSize="large" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
            </div>
            {errorMessage && (
                <Alert severity="error" className={classes.errorMessageContainer}>
                    {errorMessage}
                </Alert>
            )}
            {periodForTimePicker && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <TimePicker
                        ampm={false}
                        open={periodForTimePicker ? true : false}
                        onClose={() => setPeriodForTimePicker(null)}
                        style={{ display: "none" }}
                        onChange={(time) => {
                            if (periodStartOrEnd === "start") {
                                periodForTimePicker.startTime = time.format("HH:mm");
                            } else {
                                periodForTimePicker.endTime = time.format("HH:mm");
                            }
                            const updatedTimeRules = [...timeRules];
                            setValue("timeRules", updatedTimeRules, {
                                shouldValidate: true,
                            });
                        }}
                        value={
                            periodStartOrEnd === "start"
                                ? moment()
                                      .set("hour", periodForTimePicker.startTime.substring(0, 2))
                                      .set("minute", periodForTimePicker.startTime.substring(3))
                                : moment()
                                      .set("hour", periodForTimePicker.endTime.substring(0, 2))
                                      .set("minute", periodForTimePicker.endTime.substring(3))
                        }
                    />
                </MuiPickersUtilsProvider>
            )}
        </div>
    );
}

export default PricingTemplateTimeRulesForm;

function getDayOptions(intl) {
    return [
        {
            name: getDayName(1, intl),
            id: 1,
        },
        {
            name: getDayName(2, intl),
            id: 2,
        },
        {
            name: getDayName(3, intl),
            id: 3,
        },
        {
            name: getDayName(4, intl),
            id: 4,
        },
        {
            name: getDayName(5, intl),
            id: 5,
        },
        {
            name: getDayName(6, intl),
            id: 6,
        },
        {
            name: getDayName(7, intl),
            id: 7,
        },
    ];
}

function getDefaultTimeRule() {
    return {
        startTime: "08:00",
        endTime: "17:00",
        pricePerUnit: PRICE_PER_UNIT_HOUR,
    };
}
