import axios from "axios";
import { getApiUrl } from "../../../app/utils/ApiUtils";

export const INTEGRATIONS_URL = "v1/admin/profiles/{profileId}/integrations";
export const PARAKEY_DOMAINS_URL = "v1/admin/integrations/{id}/parakey/domains";
export const PARAKEY_ACCESSES_URL = "v1/admin/integrations/{id}/parakey/accesses";
export const PARAKEY_KEY_SERVICE_URL = "v1/admin/integrations/{id}/parakey/key-services";
export const PARAKEY_KEY_SERVICE_DETAILS_URL = "v1/admin/parakey/key-services/{id}";
export const PARAKEY_BOOKING_ACCESSES_URL = "v1/admin/integrations/{id}/parakey/booking-accesses";

export function createParakeyIntegration(profileId, accessToken) {
    const url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "/parakey";
    return axios.post(getApiUrl() + url, { accessToken });
}

export function getParakeyDomains(id) {
    const url = PARAKEY_DOMAINS_URL.replace("{id}", id);
    return axios.get(getApiUrl() + url);
}

export function getParakeyAccesses(id, page, perPage) {
    const url = PARAKEY_ACCESSES_URL.replace("{id}", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function createParakeyKeyService(integrationId, externalId, data) {
    const url = PARAKEY_KEY_SERVICE_URL.replace("{id}", integrationId);
    return axios.post(getApiUrl() + url, { externalId, ...data });
}

export function updateParakeyKeyService(id, data) {
    const url = PARAKEY_KEY_SERVICE_DETAILS_URL.replace("{id}", id);
    return axios.put(getApiUrl() + url, data);
}

export function deleteParakeyKeyService(id) {
    const url = PARAKEY_KEY_SERVICE_DETAILS_URL.replace("{id}", id);
    return axios.delete(getApiUrl() + url);
}

export function getParakeyBookingAccesses(id, page, perPage) {
    const url = PARAKEY_BOOKING_ACCESSES_URL.replace("{id}", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}
