import { put, takeLatest } from "redux-saga/effects";
import { getBookings, getBooking, patchBooking, getBookingAccesses, getResourceListBookings } from "./bookingCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_BOOKINGS_REQUEST: "GET_BOOKINGS_REQUEST",
    GET_BOOKINGS_SUCCESS: "GET_BOOKINGS_SUCCESS",
    GET_BOOKING_REQUEST: "GET_BOOKING_REQUEST",
    GET_BOOKING_SUCCESS: "GET_BOOKING_SUCCESS",
    UPDATE_BOOKING_REQUEST: "UPDATE_BOOKING_REQUEST",
    UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
    GET_BOOKING_ACCESSES_REQUEST: "GET_BOOKING_ACCESSES_REQUEST",
    GET_BOOKING_ACCESSES_SUCCESS: "GET_BOOKING_ACCESSES_SUCCESS",
    GET_RESOURCE_LIST_BOOKING_REQUEST: "GET_RESOURCE_LIST_BOOKING_REQUEST",
    GET_RESOURCE_LIST_BOOKING_SUCCESS: "GET_RESOURCE_LIST_BOOKING_SUCCESS",
    CLEAR_BOOKINGS_STATE: "CLEAR_BOOKINGS_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    bookingsPagination: {
        data: [],
        totalRows: 0,
    },
    accessesPagination: {
        data: [],
        totalRows: 0,
    },
    resourceBookingsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                bookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_BOOKING_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_BOOKING_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                booking: action.payload.response,
            };
        }

        case actionTypes.UPDATE_BOOKING_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_BOOKING_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                booking: action.payload.response,
            };
        }

        case actionTypes.GET_BOOKING_ACCESSES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_BOOKING_ACCESSES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                accessesPagination: action.payload.response,
            };
        }

        case actionTypes.GET_RESOURCE_LIST_BOOKING_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_RESOURCE_LIST_BOOKING_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceBookingsPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_BOOKINGS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getBookings: (profileId, page, perPage, search, status, paymentType) => ({
        type: actionTypes.GET_BOOKINGS_REQUEST,
        payload: { profileId, page, perPage, search, status, paymentType },
    }),

    getBooking: (id) => ({
        type: actionTypes.GET_BOOKING_REQUEST,
        payload: { id },
    }),
    getBookingSuccess: (response) => ({
        type: actionTypes.GET_BOOKING_SUCCESS,
        payload: { response },
    }),

    updateBooking: (id, originalBooking, updatedBooking) => ({
        type: actionTypes.UPDATE_BOOKING_REQUEST,
        payload: { id, originalBooking, updatedBooking },
    }),

    getBookingAccesses: (bookingId, page, perPage) => ({
        type: actionTypes.GET_BOOKING_ACCESSES_REQUEST,
        payload: { bookingId, page, perPage },
    }),

    getResourceListBookings: (profileId, page, perPage, search, status, type) => ({
        type: actionTypes.GET_RESOURCE_LIST_BOOKING_REQUEST,
        payload: { profileId, page, perPage, search, status, type },
    }),

    clearBookingsState: () => ({
        type: actionTypes.CLEAR_BOOKINGS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getBookings(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.paymentType
            );
            yield put({
                type: actionTypes.GET_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_BOOKING_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getBooking(payload.id);

            yield put({
                type: actionTypes.GET_BOOKING_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_BOOKING_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchBooking(payload.id, payload.originalBooking, {
                ...payload.originalBooking,
                ...payload.updatedBooking,
            });
            yield put({
                type: actionTypes.UPDATE_BOOKING_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_BOOKING_ACCESSES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getBookingAccesses(payload.bookingId, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_BOOKING_ACCESSES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_LIST_BOOKING_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceListBookings(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.type
            );
            yield put({
                type: actionTypes.GET_RESOURCE_LIST_BOOKING_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
