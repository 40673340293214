import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";

function ProductBookingSettingsForm({ service }) {
    const { register, errors } = useFormContext();

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PRODUCT.BOOKING_SETTINGS.MIN_QUANTITY" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-2"
                    name="minQuantity"
                    ref={register}
                    defaultValue={service?.minQuantity}
                />
                <Form.Control.Feedback type="invalid">{errors.minQuantity?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="SERVICE.PRODUCT.BOOKING_SETTINGS.MIN_QUANTITY_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="SERVICE.PRODUCT.BOOKING_SETTINGS.MAX_QUANTITY" />
                </Form.Label>
                <Form.Control
                    type="number"
                    className="form-control form-control-lg col-xl-2"
                    name="maxQuantity"
                    ref={register}
                    defaultValue={service?.maxQuantity}
                />
                <Form.Control.Feedback type="invalid">{errors.maxQuantity?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="SERVICE.PRODUCT.BOOKING_SETTINGS.MAX_QUANTITY_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
        </div>
    );
}

export default connect(null, {})(ProductBookingSettingsForm);
