import React from "react";
import { connect, useSelector } from "react-redux";
import { actions as integrationActions } from "../../../../redux/integrations/integrationRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
}));

const schema = yup.object().shape({
  phoneNumber: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
  password: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function ZesecCreatePage(props) {
  const { profile } = useSelector((state) => state.auth);
  const { isLoading, zesec } = useSelector((state) => state.integrations);
  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = (values) => {
    props.createZesecIntegration(profile.id, values.phoneNumber, values.password);
  };

  if (zesec.redirectToDetails) {
    return <Redirect to={"/integrations/zesec/" + zesec.integration.id} />;
  }

  return (
    <PageBackdrop isLoading={isLoading}>
      <Paper className={classes.content}>
        <Typography variant="h4" className={classes.header}>
          <FormattedMessage id="INTEGRATION.ZESEC.CREATE.TITLE" />
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          <FormattedMessage id="INTEGRATION.ZESEC.CREATE.DESCRIPTION" />
          <a href="https://www.zesec.com/">www.zesec.com</a>
        </Typography>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id="INTEGRATION.ZESEC.CREATE.PHONE_NUMBER" />
            </Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              ref={register}
              isInvalid={errors.phoneNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber?.message}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              <FormattedMessage id="INTEGRATION.ZESEC.CREATE.PHONE_NUMBER_DESCRIPTION" />
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>
              <FormattedMessage id="INTEGRATION.ZESEC.CREATE.PASSWORD" />
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              ref={register}
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            <FormattedMessage id="COMMON.ADD" />
          </Button>
        </Form>
      </Paper>
    </PageBackdrop>
  );
}

export default connect(null, integrationActions)(ZesecCreatePage);
