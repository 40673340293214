import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import * as resourceUtils from "./utils";
import { Paper } from "@material-ui/core";
import {
    AvatarCell,
    TextCell,
    TranslationCell,
    DateTimeCell,
    LinkCell,
    DeleteActionButton,
} from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import ResourceListToolbar from "../../components/tables/toolbar/ResourceListToolbar";

function ResourcePageList({ getResources, deleteResource, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.resources);
    const { displayError } = useSelector((state) => state.errors);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");

    const intl = useIntl();

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getResources(profile.id, pageIndex + 1, pageSize, search, status, type);
        },
        [getResources, profile.id, search, status, type]
    );

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    const onStatusChanged = useCallback((status) => {
        setStatus(status);
    }, []);

    const onTypeChanged = useCallback((type) => {
        setType(type);
    }, []);

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return (
                        <ResourceListToolbar
                            onSearchChanged={onSearchChanged}
                            onStatusChanged={onStatusChanged}
                            onTypeChanged={onTypeChanged}
                        />
                    );
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="RESOURCE.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: ({ row }) => {
                                return LinkCell(`/resources/${row.values.id}/${row.values.type}`, row.values.id);
                            },
                        },
                        {
                            Header: <FormattedMessage id="RESOURCE.LIST.HEADER.NAME" />,
                            accessor: "name",
                            Cell: ({ row }) => {
                                const resource = row.original;
                                return (
                                    <Link to={`/resources/${resource.id}/${resource.type}`}>
                                        {AvatarCell(resource.avatarUrl, resource.name)}
                                    </Link>
                                );
                            },
                        },
                        {
                            Header: <FormattedMessage id="RESOURCE.LIST.HEADER.TYPE" />,
                            accessor: "type",
                            Cell: ({ row }) => {
                                const resource = row.original;
                                if (resourceUtils.isStaff(resource)) {
                                    return TextCell(resourceUtils.getPermissionName(resource?.permission?.id, intl));
                                } else if (resourceUtils.isSpace(resource)) {
                                    return TranslationCell("RESOURCE.TYPE.SPACE");
                                } else if (resourceUtils.isArticle(resource)) {
                                    return TranslationCell("RESOURCE.TYPE.ARTICLE");
                                }

                                return TextCell();
                            },
                        },
                        {
                            Header: <FormattedMessage id="RESOURCE.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "delete",
                            Cell: ({ row: { original: resource } }) => {
                                const { id, name, firstName, surname } = resource;

                                const title = `${intl.formatMessage({
                                    id: "RESOURCE.DELETE.TITLE",
                                })} ${name ? name : `${firstName} ${surname}`}`;

                                const content = <FormattedMessage id="RESOURCE.DELETE.TEXT" />;
                                return DeleteActionButton(title, content, () => deleteResource(id));
                            },
                        },
                    ],
                    [intl, deleteResource]
                )}
            />
        </Paper>
    );
}

export default connect(null, { ...resourceActions, ...errorActions })(ResourcePageList);
