import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";

export const PAYOUTS_URL = "v1/admin/profiles/:id/payouts";
export const PAYOUT_DETAILS_URL = "v1/admin/payouts/:id";
export const PAYOUT_TRANSACTIONS_URL = "v1/admin/payouts/:id/transactions";
export const PAYOUT_TRANSACTION_REFUNDS_URL = "v1/admin/payouts/:id/transaction-refunds";
export const PAYOUT_DOWNLOAD_DOCUMENT = "v1/admin/payouts/:id/download";

export function getPayouts(profileId, page, perPage) {
    let url = PAYOUTS_URL.replace(":id", profileId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getPayout(id) {
    return axios.get(getApiUrl() + PAYOUT_DETAILS_URL.replace(":id", id));
}

export function getPayoutTransactions(id, page, perPage) {
    let url = PAYOUT_TRANSACTIONS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getPayoutTransactionRefunds(id, page, perPage) {
    let url = PAYOUT_TRANSACTION_REFUNDS_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function downloadPayoutDocument(id) {
    return axios.get(getApiUrl() + PAYOUT_DOWNLOAD_DOCUMENT.replace(":id", id), {
        responseType: "arraybuffer",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
    });
}
