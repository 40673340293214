import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PAYMENT_METHOD_DETAILS_URL = "v1/admin/payment-methods/:id";

export const CUSTOMER_PAYMENT_METHOD_URL = "v1/admin/customers/:id/payment-methods";

export function getPaymentMethod(id) {
    return axios.get(getApiUrl() + PAYMENT_METHOD_DETAILS_URL.replace(":id", id));
}

export function addCustomerPaymentMethod(customerId, data) {
    return axios.post(getApiUrl() + CUSTOMER_PAYMENT_METHOD_URL.replace(":id", customerId), data);
}

export function patchPaymentMethod(id, originalPaymentMethod, changedPaymentMethod) {
    const url = PAYMENT_METHOD_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalPaymentMethod, changedPaymentMethod), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deletePaymentMethod(id) {
    return axios.delete(getApiUrl() + PAYMENT_METHOD_DETAILS_URL.replace(":id", id));
}
