export function getApiUrl() {
    return process.env.REACT_APP_API_URL;
}

export function getApiErrorMessage(error) {
    if (!error || !error.response || !error.response.data) return null;
    return error.response.data.message;
}

export function getApiErrorMessageDetailed(error, intl) {
    let messageDetails = getApiErrorMessage(error) || '';
    if (!error || !error.response || !error.response.data) return null;
    if (error.response.data.code) messageDetails += ' [' + error.response.data.code + ']';
    return intl.formatMessage({id: "COMMON.ERROR.API_GENERAL"}, { details: messageDetails });
}