import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles, Avatar } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Col } from "react-bootstrap";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { getResourcesAndGroupsOptions, getResourceOption, getResourceGroupOption } from "../../resources/utils";
import GroupIcon from "@material-ui/icons/Group";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DefaultAsyncTypeahead from "../../../components/DefaultAsyncTypeahead";
import {
    PAYMENT_SETTINGS_AS_SERVICE,
    PAYMENT_SETTINGS_CUSTOM,
    RESOURCE_GROUP_SELECT_INDIVIDUAL_MODE,
    RESOURCE_GROUP_SELECT_RANDOM_MODE,
    RESOURCE_GROUP_SELECT_COUNT_MODE,
    RESOURCE_GROUP_SELECT_ALL_MODE,
    APPOINTMENT_RESURCE_TYPE_GROUP,
} from "./utils";
import MaterialRadioDescriptionButton from "../../../components/inputs/MaterialRadioDescriptionButton";
import { PriceForm } from "../../../components/price/PriceForm";
import {
    APPOINTMENT_DURATION_TYPE_FLEXIBLE,
    APPOINTMENT_DURATION_TYPE_DAYS,
    PRICE_PER_UNIT_MINUTE,
    PRICE_PER_UNIT_HOUR,
    PRICE_PER_UNIT_DAY,
    getPricePerUnitOption,
} from "../utils";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    formContent: {},
    listItemResourceContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemResourceAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
    multipleOptionsRb: {
        marginTop: "4px",
        marginBottom: "4px",
    },
}));

const schema = yup.object().shape({
    resourceOrGroup: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    resourceGroupSelectMode: yup.object(),
    price: yup.number().when("paymentSettings", (paymentSettings) => {
        if (paymentSettings !== PAYMENT_SETTINGS_CUSTOM) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    pricePerUnit: yup.object(),
    vat: yup.object(),
    currency: yup.object(),
    paymentTemplate: yup.object().when("paymentSettings", (paymentSettings) => {
        if (paymentSettings !== PAYMENT_SETTINGS_CUSTOM) {
            return yup.object();
        } else {
            return yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />);
        }
    }),
    pricingTemplate: yup.object(),
});

function AppointmentResourceModal({
    addAppointmentResource,
    updateAppointmentResource,
    onResourceAdded,
    onResourceUpdated,
    getResourcesAndGroups,
    onCloseClicked,
    appointment,
    serviceResourceOrGroup,
    serviceOptions,
    show,
}) {
    const classes = useStyles();
    const intl = useIntl();

    const { profile } = useSelector((state) => state.auth);
    const { isLoading: isServicesLoading } = useSelector((state) => state.services);
    const { resourcesAndGroupsListPagination, isLoading: isResourcesLoading } = useSelector((state) => state.resources);

    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { register, handleSubmit, setValue, control, watch, errors } = formMethods;

    useEffect(() => {
        getResourcesAndGroups(profileId, 1, 20);
    }, [profileId, getResourcesAndGroups]);

    useEffect(() => {
        if (serviceResourceOrGroup) {
            if (serviceResourceOrGroup.resource) {
                setValue("resourceOrGroup", getResourceOption(serviceResourceOrGroup.resource, intl));
            } else if (serviceResourceOrGroup.resourceGroup) {
                setValue("resourceOrGroup", getResourceGroupOption(serviceResourceOrGroup.resourceGroup, intl));
            }

            if (serviceResourceOrGroup.resourceGroupSelectMode) {
                var options = getGroupSelectModeOptions(intl);
                setValue(
                    "resourceGroupSelectMode",
                    options.filter((x) => x.id === serviceResourceOrGroup.resourceGroupSelectMode)[0]
                );
            }

            if (serviceResourceOrGroup.vat) {
                setValue("vat", getVatOption(serviceResourceOrGroup.vat));
            }

            if (serviceResourceOrGroup.currency) {
                setValue("paymentSettings", PAYMENT_SETTINGS_CUSTOM);
            }

            if (serviceResourceOrGroup.pricePerUnit) {
                setValue("pricePerUnit", getPricePerUnitOption(serviceResourceOrGroup.pricePerUnit, intl));
            }

            if (serviceResourceOrGroup.currency) {
                setValue("currency", getCurrencyOption(serviceResourceOrGroup.currency));
            }

            if (serviceResourceOrGroup.paymentTemplate) {
                setValue("paymentTemplate", getPaymentTemplateOption(serviceResourceOrGroup.paymentTemplate));
            }
        }
    }, [serviceResourceOrGroup, setValue, intl]);

    const onFormSubmit = (values) => {
        let addResourceData = {};

        if (values.paymentSettings === PAYMENT_SETTINGS_AS_SERVICE) {
            addResourceData.paymentSettingsMode = PAYMENT_SETTINGS_AS_SERVICE;
        } else {
            addResourceData.paymentSettingsMode = PAYMENT_SETTINGS_CUSTOM;
            addResourceData.price = values.price;
            addResourceData.pricePerUnit = values?.pricePerUnit?.id;
            addResourceData.currencyId = values?.currency?.id;
            addResourceData.vatId = values?.vat?.id;
            addResourceData.paymentTemplateId = values?.paymentTemplate?.id;
        }

        if (!serviceResourceOrGroup) {
            if (!values.resourceOrGroup.isGroup) {
                addResourceData.resourceId = values.resourceOrGroup.id;
            } else {
                addResourceData.resourceGroupId = values.resourceOrGroup.id;
                addResourceData.resourceGroupSelectMode = values.resourceGroupSelectMode.id;
            }

            addAppointmentResource(appointment.id, addResourceData, (addedResource) => {
                onResourceAdded(addedResource);
            });
        } else {
            let originalServiceResourceOrGroup = { ...serviceResourceOrGroup };
            if (serviceResourceOrGroup.type === APPOINTMENT_RESURCE_TYPE_GROUP) {
                addResourceData.resourceGroupSelectMode = values.resourceGroupSelectMode.id;
            }

            updateAppointmentResource(
                appointment.id,
                originalServiceResourceOrGroup.id,
                originalServiceResourceOrGroup,
                addResourceData,
                (updateResource) => {
                    onResourceUpdated(updateResource);
                }
            );
        }
    };

    const resourceOrGroup = watch("resourceOrGroup");
    if (!resourceOrGroup) {
        register("resourceOrGroup");
    }

    const resourceGroupSelectMode = watch("resourceGroupSelectMode");
    if (!resourceGroupSelectMode) {
        register("resourceGroupSelectMode");

        if (!serviceResourceOrGroup?.resourceGroupSelectMode) {
            setValue("resourceGroupSelectMode", getGroupSelectModeOptions(intl)[0], { shouldValidate: true });
        }
    }

    const paymentSettings = watch("paymentSettings");

    const durationType = appointment?.durationType;
    const pricePerUnits =
        durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE || durationType === APPOINTMENT_DURATION_TYPE_DAYS
            ? [PRICE_PER_UNIT_MINUTE, PRICE_PER_UNIT_HOUR, PRICE_PER_UNIT_DAY]
            : [];

    const pricePerUnit = watch("pricePerUnit");
    if (pricePerUnits.length > 0 && !pricePerUnit) {
        register("pricePerUnit");

        if (!serviceResourceOrGroup?.pricePerUnit) {
            setValue("pricePerUnit", getPricePerUnitOption(PRICE_PER_UNIT_HOUR, intl), { shouldValidate: true });
        }
    }

    const vat = watch("vat");
    if (!vat?.id && serviceOptions?.vats) {
        register("vat");

        if (!serviceResourceOrGroup?.vat) {
            const defaultVat = serviceOptions.vats.find((x) => x.isDefault);
            setValue("vat", getVatOption(defaultVat), { shouldValidate: true });
        }
    }

    const currency = watch("currency");
    if (!currency?.id && serviceOptions?.currencies) {
        register("currency");

        if (!serviceResourceOrGroup?.currency) {
            setValue("currency", getCurrencyOption(serviceOptions.currencies[0]), { shouldValidate: true });
        }
    }

    let allPaymentTemplates = serviceOptions?.paymentTemplates ? [...serviceOptions.paymentTemplates] : [];

    // Add default null option to use same as service
    allPaymentTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.PAYMENT_TEMPLATE.SAME_AS_SERVICE",
        }),
    });

    const paymentTemplate = watch("paymentTemplate");
    if (!paymentTemplate) {
        register("paymentTemplate");
        setValue("paymentTemplate", getPaymentTemplateOption(allPaymentTemplates[0]), { shouldValidate: true });
    }

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {!serviceResourceOrGroup ? (
                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.TITLE_ADD" />
                    ) : (
                        <FormattedMessage
                            id="SERVICE.APPOINTMENT.RESOURCES.MODAL.TITLE_EDIT"
                            values={{
                                resourceName: serviceResourceOrGroup.resource
                                    ? serviceResourceOrGroup.resource.name
                                    : serviceResourceOrGroup.resourceGroup.name,
                            }}
                        />
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.formContent}>
                    <FormProvider {...formMethods}>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} xl={6}>
                                    <Form.Label>
                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.CHOOSE_RESOURCE" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="resourceOrGroup"
                                        defaultValue={null}
                                        render={() => (
                                            <div>
                                                <DefaultAsyncTypeahead
                                                    id="typeahead-resource-or-group"
                                                    onChange={(values) =>
                                                        setValue("resourceOrGroup", values[0], {
                                                            shouldValidate: true,
                                                        })
                                                    }
                                                    onSearch={(search) =>
                                                        getResourcesAndGroups(profileId, 1, 20, search)
                                                    }
                                                    options={getResourcesAndGroupsOptions(
                                                        resourcesAndGroupsListPagination
                                                    )}
                                                    selected={resourceOrGroup}
                                                    clearButton={false}
                                                    isLoading={isResourcesLoading}
                                                    isInvalid={errors.resourceOrGroup?.message ? true : false}
                                                    placeholder={intl.formatMessage({
                                                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                                    })}
                                                    emptyLabel={intl.formatMessage({
                                                        id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                                    })}
                                                    disabled={serviceResourceOrGroup ? true : false}
                                                    renderMenuItemChildren={(option, props) => (
                                                        <div className={classes.listItemResourceContainer}>
                                                            {!option.isGroup && (
                                                                <>
                                                                    <Avatar
                                                                        alt={option.name}
                                                                        src={option.avatarUrl}
                                                                        className={classes.listItemResourceAvatar}
                                                                    />
                                                                    <span>{option.name}</span>
                                                                </>
                                                            )}
                                                            {option.isGroup && (
                                                                <>
                                                                    <Avatar
                                                                        alt={option.name}
                                                                        src={option.avatarUrl}
                                                                        className={classes.listItemResourceAvatar}
                                                                    >
                                                                        <GroupIcon />
                                                                    </Avatar>
                                                                    <span>
                                                                        <FormattedMessage
                                                                            id="RESOURCE_AND_GROUP.LIST.GROUP.IDENTIFIER"
                                                                            values={{ name: option.name }}
                                                                        />
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        )}
                                    />
                                </Form.Group>
                            </Form.Row>
                            {resourceOrGroup?.isGroup && (
                                <>
                                    <Form.Row>
                                        <Form.Group as={Col} xl={6}>
                                            <Form.Label>
                                                <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE" />
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="resourceGroupSelectMode"
                                                defaultValue={null}
                                                render={() => (
                                                    <div>
                                                        <DefaultAsyncTypeahead
                                                            id="typeahead-resource-group-select-mode"
                                                            onChange={(values) =>
                                                                setValue("resourceGroupSelectMode", values[0], {
                                                                    shouldValidate: true,
                                                                })
                                                            }
                                                            onSearch={() => {}}
                                                            options={getGroupSelectModeOptions(intl)}
                                                            selected={resourceGroupSelectMode}
                                                            clearButton={false}
                                                            placeholder={intl.formatMessage({
                                                                id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                                            })}
                                                            emptyLabel={intl.formatMessage({
                                                                id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                                            })}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    {resourceGroupSelectMode?.id && (
                                        <Form.Row>
                                            <Form.Group as={Col} xl={9}>
                                                <Alert severity="info">
                                                    {resourceGroupSelectMode.id ===
                                                        RESOURCE_GROUP_SELECT_INDIVIDUAL_MODE && (
                                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.INDIVIDUAL.DESCRIPTION" />
                                                    )}
                                                    {resourceGroupSelectMode.id ===
                                                        RESOURCE_GROUP_SELECT_RANDOM_MODE && (
                                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.RANDOM.DESCRIPTION" />
                                                    )}
                                                    {resourceGroupSelectMode.id ===
                                                        RESOURCE_GROUP_SELECT_COUNT_MODE && (
                                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.COUNT.DESCRIPTION" />
                                                    )}
                                                    {resourceGroupSelectMode.id === RESOURCE_GROUP_SELECT_ALL_MODE && (
                                                        <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.ALL.DESCRIPTION" />
                                                    )}
                                                </Alert>
                                            </Form.Group>
                                        </Form.Row>
                                    )}
                                </>
                            )}
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="paymentSettings"
                                    defaultValue={PAYMENT_SETTINGS_AS_SERVICE}
                                    render={({ onChange, value }) => (
                                        <>
                                            <Form.Row>
                                                <Col sm={6} className={classes.multipleOptionsRb}>
                                                    <MaterialRadioDescriptionButton
                                                        checked={value === PAYMENT_SETTINGS_AS_SERVICE}
                                                        value={PAYMENT_SETTINGS_AS_SERVICE}
                                                        onChange={(e) => onChange(e)}
                                                        titleIntlId={
                                                            "SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.AS_SERVICE.TITLE"
                                                        }
                                                        descriptionIntlId={
                                                            "SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.AS_SERVICE.DESCRIPTION"
                                                        }
                                                    />
                                                </Col>
                                                <Col sm={6} className={classes.multipleOptionsRb}>
                                                    <MaterialRadioDescriptionButton
                                                        checked={value === PAYMENT_SETTINGS_CUSTOM}
                                                        value={PAYMENT_SETTINGS_CUSTOM}
                                                        onChange={(e) => onChange(e)}
                                                        titleIntlId={
                                                            "SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.CUSTOM.TITLE"
                                                        }
                                                        descriptionIntlId={
                                                            "SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.CUSTOM.DESCRIPTION"
                                                        }
                                                    />
                                                </Col>
                                            </Form.Row>
                                        </>
                                    )}
                                />
                            </Form.Group>
                            {paymentSettings === PAYMENT_SETTINGS_CUSTOM && (
                                <>
                                    <PriceForm
                                        priceTitle={<FormattedMessage id="SERVICE.PAYMENT.PRICE" />}
                                        priceDescription={<FormattedMessage id="SERVICE.PAYMENT.PRICE_DESCRIPTION" />}
                                        price={serviceResourceOrGroup?.price}
                                        pricePerUnits={pricePerUnits}
                                        pricePerUnit={pricePerUnit}
                                        vats={serviceOptions?.vats}
                                        vat={vat}
                                        currencies={serviceOptions?.currencies}
                                        currency={currency}
                                    />
                                    <Form.Row>
                                        <Form.Group as={Col} xl={6}>
                                            <Form.Label>
                                                <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.PAYMENT_TEMPLATE" />
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="paymentTemplate"
                                                defaultValue={null}
                                                render={() => (
                                                    <DefaultAsyncTypeahead
                                                        id="typeahead-payment-template"
                                                        onChange={(paymentTemplates) =>
                                                            setValue("paymentTemplate", paymentTemplates[0], {
                                                                shouldValidate: true,
                                                            })
                                                        }
                                                        onSearch={() => {}}
                                                        options={allPaymentTemplates.map((bt) =>
                                                            getPaymentTemplateOption(bt)
                                                        )}
                                                        selected={paymentTemplate}
                                                        filterBy={() => true}
                                                        clearButton={false}
                                                        placeholder={intl.formatMessage({
                                                            id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                                        })}
                                                        emptyLabel={intl.formatMessage({
                                                            id: "COMMON.DROPDOWN.EMPTY_RESULT",
                                                        })}
                                                        isInvalid={errors.paymentTemplate?.message ? true : false}
                                                    />
                                                )}
                                            />
                                            <Form.Text className="text-muted">
                                                <FormattedMessage id="SERVICE.APPOINTMENT.RESOURCES.MODAL.PAYMENT_SETTINGS.PAYMENT_TEMPLATE.DESCRIPTION" />
                                            </Form.Text>
                                        </Form.Group>
                                    </Form.Row>
                                </>
                            )}
                        </Form>
                    </FormProvider>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isServicesLoading}
                    onClick={handleSubmit(onFormSubmit)}
                    label={
                        !serviceResourceOrGroup ? (
                            <FormattedMessage id="COMMON.ADD" />
                        ) : (
                            <FormattedMessage id="COMMON.SAVE" />
                        )
                    }
                />
            </Modal.Footer>
        </Modal>
    );
}

function getVatOption(vat) {
    return { id: vat.id, name: `${vat.amount}%` };
}

function getCurrencyOption(currency) {
    return { id: currency.id, name: currency.shortName };
}

function getPaymentTemplateOption(paymentTemplate) {
    return { id: paymentTemplate.id, name: paymentTemplate.name };
}

function getGroupSelectModeOptions(intl) {
    const options = [
        {
            id: RESOURCE_GROUP_SELECT_INDIVIDUAL_MODE,
            name: intl.formatMessage({
                id: "SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.INDIVIDUAL",
            }),
        },
        {
            id: RESOURCE_GROUP_SELECT_RANDOM_MODE,
            name: intl.formatMessage({
                id: "SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.RANDOM",
            }),
        },
        {
            id: RESOURCE_GROUP_SELECT_COUNT_MODE,
            name: intl.formatMessage({
                id: "SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.COUNT",
            }),
        },
        {
            id: RESOURCE_GROUP_SELECT_ALL_MODE,
            name: intl.formatMessage({
                id: "SERVICE.APPOINTMENT.RESOURCES.MODAL.GROUP_SELECT_MODE.ALL",
            }),
        },
    ];

    return options;
}

export default connect(null, { ...serviceActions, ...resourceActions })(AppointmentResourceModal);
