import React, { useMemo, useCallback, useState } from "react";
import { actions as campaignActions } from "../../../redux/campaigns/campaignRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell } from "../../components/tables/MaterialTableCells";
import * as campaignUtils from "./utils";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import CampaignRecipientFilterModal from "./CampaignRecipientFilterModal";

function CampaignPageRecipientFilters({
    getCampaignRecipientFilters,
    addCampaignRecipientFilter,
    deleteCampaignRecipientFilter,
}) {
    const { campaign, recipientFiltersPagination, isLoading, isUpdating } = useSelector((state) => state.campaigns);
    const { displayError } = useSelector((state) => state.errors);
    const campaignId = campaign?.id;
    const campaignStatus = campaign?.status;
    const intl = useIntl();

    const [customerFilterModalMode, setCustomerFilterModalMode] = useState(null);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (campaignId) {
                getCampaignRecipientFilters(campaignId, pageIndex + 1, pageSize);
            }
        },
        [campaignId, getCampaignRecipientFilters]
    );

    const onAddModalSaveClicked = (recipientFilterData) => {
        if (customerFilterModalMode === "add") {
            addCampaignRecipientFilter(campaignId, recipientFilterData, () => {
                fetchData({ pageIndex: 0, pageSize: 10 });
            });
        }

        setCustomerFilterModalMode(null);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.HEADER" />
                        </h3>
                    </div>
                    {campaign && campaignUtils.isCampaignUpdateable(campaign.status) && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setCustomerFilterModalMode("add")}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={campaignId}
                        data={useMemo(() => recipientFiltersPagination.data, [recipientFiltersPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={recipientFiltersPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.LIST.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.LIST.TYPE" />,
                                    accessor: "type",
                                    Cell: (props) => {
                                        return TextCell(campaignUtils.getCampaignFilterNameFromType(props.value, intl));
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.LIST.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    textAlign: "center",
                                    Cell: ({ row }) => {
                                        if (!campaignUtils.isCampaignUpdateable(campaignStatus)) return <></>;
                                        return (
                                            <ListActionsButton
                                                menuItems={[
                                                    {
                                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                                        onClick: () => {
                                                            deleteCampaignRecipientFilter(campaignId, row.original.id);
                                                        },
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ],
                            [intl, deleteCampaignRecipientFilter, campaignId, campaignStatus]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {customerFilterModalMode && (
                <CampaignRecipientFilterModal
                    show={customerFilterModalMode ? true : false}
                    onCloseClicked={() => setCustomerFilterModalMode(null)}
                    onSaveClicked={onAddModalSaveClicked}
                />
            )}
        </Card>
    );
}
export default connect(null, { ...campaignActions, ...errorActions })(CampaignPageRecipientFilters);
