import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

function AddReportButton() {
    return (
        <Link to="/reports/create">
            <Button className="bg-primary text-light">
                <Typography variant="button">
                    <FormattedMessage id="REPORT.LIST.CREATE_BUTTON" />
                </Typography>
            </Button>
        </Link>
    );
}

export default AddReportButton;
