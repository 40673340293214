import { put, takeLatest } from "redux-saga/effects";
import { getReports, getReport, addReport, patchReport, deleteReport, downloadReportDocument } from "./reportCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import { findAndSpliceArrayByProperty } from "../reduxUtils";

export const actionTypes = {
    GET_REPORTS_REQUEST: "GET_REPORTS_REQUEST",
    GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
    GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
    GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
    ADD_REPORT_REQUEST: "ADD_REPORT_REQUEST",
    ADD_REPORT_SUCCESS: "ADD_REPORT_SUCCESS",
    UPDATE_REPORT_REQUEST: "UPDATE_REPORT_REQUEST",
    UPDATE_REPORT_SUCCESS: "UPDATE_REPORT_SUCCESS",
    DELETE_REPORT_REQUEST: "DELETE_REPORT_REQUEST",
    DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",
    DOWNLOAD_REPORT_DOCUMENT_REQUEST: "DOWNLOAD_REPORT_DOCUMENT_REQUEST",
    DOWNLOAD_REPORT_DOCUMENT_SUCCESS: "DOWNLOAD_REPORT_DOCUMENT_SUCCESS",
    GET_REPORT_COPY_REQUEST: "GET_REPORT_COPY_REQUEST",
    GET_REPORT_COPY_SUCCESS: "GET_REPORT_COPY_SUCCESS",
    CLEAR_REPORT_STATE: "CLEAR_REPORT_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    isDownloadingDocument: false,
    reportToCopy: null,
    listPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REPORTS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_REPORTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_REPORT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_REPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                report: action.payload.response,
            };
        }

        case actionTypes.ADD_REPORT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_REPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                report: action.payload.response,
            };
        }

        case actionTypes.UPDATE_REPORT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_REPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                report: action.payload.response,
            };
        }

        case actionTypes.DELETE_REPORT_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_REPORT_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.listPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                report: action.payload.response,
                listPagination: {
                    ...state.listPagination,
                    data: alteredStateArray,
                    totalRows: state.listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.DOWNLOAD_REPORT_DOCUMENT_REQUEST: {
            return { ...state, isDownloadingDocument: true };
        }

        case actionTypes.DOWNLOAD_REPORT_DOCUMENT_SUCCESS: {
            return { ...state, isDownloadingDocument: false };
        }

        case actionTypes.GET_REPORT_COPY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_REPORT_COPY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reportToCopy: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_REPORT_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getReports: (profileId, page, perPage) => ({
        type: actionTypes.GET_REPORTS_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getReport: (reportId) => ({
        type: actionTypes.GET_REPORT_REQUEST,
        payload: { reportId },
    }),

    addReport: (profileId, data, callback) => ({
        type: actionTypes.ADD_REPORT_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateReport: (reportId, originalReport, updatedReport) => ({
        type: actionTypes.UPDATE_REPORT_REQUEST,
        payload: { reportId, originalReport, updatedReport },
    }),

    deleteReport: (reportId) => ({
        type: actionTypes.DELETE_REPORT_REQUEST,
        payload: { reportId },
    }),

    downloadReportDocument: (id, fileName) => ({
        type: actionTypes.DOWNLOAD_REPORT_DOCUMENT_REQUEST,
        payload: { id, fileName },
    }),

    getReportCopy: (reportId) => ({
        type: actionTypes.GET_REPORT_COPY_REQUEST,
        payload: { reportId },
    }),

    clearReportState: () => ({
        type: actionTypes.CLEAR_REPORT_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_REPORTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getReports(payload.profileId, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_REPORTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_REPORT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getReport(payload.reportId);
            yield put({
                type: actionTypes.GET_REPORT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_REPORT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addReport(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_REPORT_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_REPORT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchReport(payload.reportId, payload.originalReport, {
                ...payload.originalReport,
                ...payload.updatedReport,
            });
            yield put({
                type: actionTypes.UPDATE_REPORT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_REPORT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteReport(payload.reportId);
            yield put({
                type: actionTypes.DELETE_REPORT_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DOWNLOAD_REPORT_DOCUMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield downloadReportDocument(payload.id);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${payload.fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();

            yield put({ type: actionTypes.DOWNLOAD_REPORT_DOCUMENT_SUCCESS });
        } catch (error) {
            if (error?.response?.data) {
                // Since response is expected in arraybuffer, convert to json manually before passing along
                var decodedApiResponseString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
                var apiErrorResponseObject = JSON.parse(decodedApiResponseString);
                error.response.data = apiErrorResponseObject;
            }

            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_REPORT_COPY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getReport(payload.reportId);

            yield put({
                type: actionTypes.GET_REPORT_COPY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
