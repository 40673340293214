import { put, takeLatest } from "redux-saga/effects";
import { actions as entryActions, actionTypes as entryActionTypes } from "../entries/entriesRedux";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import * as entryUtils from "../../app/pages/bookings/entryUtils";
import { getCustomers } from "../customers/customerCrud";
import { getServices, getServiceResources, getServiceAddons } from "../services/serviceCrud";
import { addBooking } from "./bookingCrud";
import { isResourceAvailable } from "../resources/resourceCrud";

export const actionTypes = {
    CALENDAR_GET_ENTRIES_REQUEST: "CALENDAR_GET_ENTRIES_REQUEST",
    CALENDAR_GET_RESOURCE_ENTRIES_REQUEST: "CALENDAR_GET_RESOURCE_ENTRIES_REQUEST",
    CALENDAR_GET_ENTRY_REQUEST: "CALENDAR_GET_ENTRY_REQUEST",
    CALENDAR_GET_ENTRY_ATTENDEES_REQUEST: "CALENDAR_GET_ENTRY_ATTENDEES_REQUEST",
    CALENDAR_GET_ENTRY_WAITING_LIST_REQUEST: "CALENDAR_GET_ENTRY_WAITING_LIST_REQUEST",
    CALENDAR_UPDATE_ENTRY_APPOINTMENT_REQUEST: "CALENDAR_UPDATE_ENTRY_APPOINTMENT_REQUEST",
    CALENDAR_UPDATE_ENTRY_OCCASION_REQUEST: "CALENDAR_UPDATE_ENTRY_OCCASION_REQUEST",
    CALENDAR_DELETE_ENTRY_APPOINTMENT_REQUEST: "CALENDAR_DELETE_ENTRY_APPOINTMENT_REQUEST",
    CALENDAR_DELETE_ENTRY_OCCASION_REQUEST: "CALENDAR_DELETE_ENTRY_OCCASION_REQUEST",
    CALENDAR_ADD_ENTRY_OCCASION_RESOURCE_REQUEST: "CALENDAR_ADD_ENTRY_OCCASION_RESOURCE_REQUEST",
    CALENDAR_UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST:
        "CALENDAR_UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST",
    CALENDAR_DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST: "CALENDAR_DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST",
    BOOKINGMANAGER_GET_CUSTOMERS_REQUEST: "BOOKINGMANAGER_GET_CUSTOMERS_REQUEST",
    BOOKINGMANAGER_GET_CUSTOMERS_SUCCESS: "BOOKINGMANAGER_GET_CUSTOMERS_SUCCESS",
    BOOKINGMANAGER_GET_SERVICES_REQUEST: "BOOKINGMANAGER_GET_SERVICES_REQUEST",
    BOOKINGMANAGER_GET_SERVICES_SUCCESS: "BOOKINGMANAGER_GET_SERVICES_SUCCESS",
    BOOKINGMANAGER_GET_SERVICE_RESOURCES_REQUEST: "BOOKINGMANAGER_GET_SERVICE_RESOURCES_REQUEST",
    BOOKINGMANAGER_GET_SERVICE_RESOURCES_SUCCESS: "BOOKINGMANAGER_GET_SERVICE_RESOURCES_SUCCESS",
    BOOKINGMANAGER_GET_SERVICE_ADDONS_REQUEST: "BOOKINGMANAGER_GET_SERVICE_ADDONS_REQUEST",
    BOOKINGMANAGER_GET_SERVICE_ADDONS_SUCCESS: "BOOKINGMANAGER_GET_SERVICE_ADDONS_SUCCESS",
    BOOKINGMANAGER_ADD_BOOKING_REQUEST: "BOOKINGMANAGER_ADD_BOOKING_REQUEST",
    BOOKINGMANAGER_ADD_BOOKING_SUCCESS: "BOOKINGMANAGER_ADD_BOOKING_SUCCESS",
    BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_REQUEST: "BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_REQUEST",
    BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_SUCCESS: "BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_SUCCESS",
    BOOKINGMANAGER_SERVICE_UNSELECTED: "BOOKINGMANAGER_SERVICE_UNSELECTED",
    CLEAR_BOOKINGMANAGER_STATE: "CLEAR_BOOKINGMANAGER_STATE",
};

const initialState = {
    isCalendarLoading: false,
    isDrawerLoading: false,
    isAttendeesLoading: false,
    newBookingCustomersPagination: null,
    newBookingCustomersLoading: false,
    newBookingServicesPagination: null,
    newBookingServicesLoading: false,
    newBookingServiceResourcesPagination: null,
    newBookingServiceResourcesLoading: false,
    newBookingServiceAddonsPagination: null,
    newBookingServiceAddonsLoading: false,
    resourceConflictingBooking: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CALENDAR_GET_ENTRIES_REQUEST: {
            return { ...state, isCalendarLoading: true };
        }

        case actionTypes.CALENDAR_GET_RESOURCE_ENTRIES_REQUEST: {
            return { ...state, isCalendarLoading: true };
        }

        case entryActionTypes.GET_ENTRIES_SUCCESS: {
            return { ...state, isCalendarLoading: false };
        }

        case actionTypes.CALENDAR_GET_ENTRY_REQUEST: {
            return { ...state, isDrawerLoading: true };
        }

        case entryActionTypes.GET_ENTRY_APPOINTMENT_SUCCESS: {
            return { ...state, isDrawerLoading: false };
        }

        case entryActionTypes.GET_ENTRY_COURSE_OCCASION_SUCCESS: {
            return { ...state, isDrawerLoading: false };
        }

        case entryActionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_REQUEST: {
            return { ...state, isAttendeesLoading: true };
        }

        case entryActionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_SUCCESS: {
            return { ...state, isAttendeesLoading: false };
        }

        case actionTypes.CALENDAR_UPDATE_ENTRY_APPOINTMENT_REQUEST: {
            return { ...state, isDrawerLoading: true };
        }

        case entryActionTypes.UPDATE_ENTRY_APPOINTMENT_SUCCESS: {
            return { ...state, isDrawerLoading: false };
        }

        case actionTypes.CALENDAR_UPDATE_ENTRY_OCCASION_REQUEST: {
            return { ...state, isDrawerLoading: true };
        }

        case entryActionTypes.UPDATE_ENTRY_OCCASION_SUCCESS: {
            return { ...state, isDrawerLoading: false };
        }

        case actionTypes.CALENDAR_DELETE_ENTRY_APPOINTMENT_REQUEST: {
            return { ...state, isDrawerLoading: true };
        }

        case entryActionTypes.DELETE_ENTRY_APPOINTMENT_SUCCESS: {
            return { ...state, isDrawerLoading: false };
        }

        case actionTypes.CALENDAR_DELETE_ENTRY_OCCASION_REQUEST: {
            return { ...state, isDrawerLoading: true };
        }

        case entryActionTypes.DELETE_ENTRY_OCCASION_SUCCESS: {
            return { ...state, isDrawerLoading: false };
        }

        case actionTypes.BOOKINGMANAGER_GET_CUSTOMERS_REQUEST: {
            return { ...state, newBookingCustomersLoading: true };
        }

        case actionTypes.BOOKINGMANAGER_GET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                newBookingCustomersLoading: false,
                newBookingCustomersPagination: action.payload.response,
            };
        }

        case actionTypes.BOOKINGMANAGER_GET_SERVICES_REQUEST: {
            return { ...state, newBookingServicesLoading: true };
        }

        case actionTypes.BOOKINGMANAGER_GET_SERVICES_SUCCESS: {
            return {
                ...state,
                newBookingServicesLoading: false,
                newBookingServicesPagination: action.payload.response,
            };
        }

        case actionTypes.BOOKINGMANAGER_GET_SERVICE_RESOURCES_REQUEST: {
            return { ...state, newBookingServiceResourcesLoading: true };
        }

        case actionTypes.BOOKINGMANAGER_GET_SERVICE_RESOURCES_SUCCESS: {
            return {
                ...state,
                newBookingServiceResourcesLoading: false,
                newBookingServiceResourcesPagination: action.payload.response,
            };
        }

        case actionTypes.BOOKINGMANAGER_GET_SERVICE_ADDONS_REQUEST: {
            return { ...state, newBookingServiceAddonsLoading: true };
        }

        case actionTypes.BOOKINGMANAGER_GET_SERVICE_ADDONS_SUCCESS: {
            return {
                ...state,
                newBookingServiceAddonsLoading: false,
                newBookingServiceAddonsPagination: action.payload.response,
            };
        }

        case actionTypes.BOOKINGMANAGER_ADD_BOOKING_REQUEST: {
            return { ...state, isDrawerLoading: true };
        }

        case actionTypes.BOOKINGMANAGER_ADD_BOOKING_SUCCESS: {
            return {
                ...state,
                isDrawerLoading: false,
            };
        }

        case actionTypes.BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_REQUEST: {
            return { ...state, resourceConflictingBooking: null };
        }

        case actionTypes.BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_SUCCESS: {
            return {
                ...state,
                resourceConflictingBooking: action.payload.response?.booking,
            };
        }

        case actionTypes.BOOKINGMANAGER_SERVICE_UNSELECTED: {
            return { ...state, newBookingServiceResourcesPagination: null };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isDrawerLoading: false };
        }

        case actionTypes.CLEAR_BOOKINGMANAGER_STATE: {
            const clearNewBookingCache = action.payload.clearNewBookingCache;
            return {
                ...initialState,
                newBookingCustomersPagination: !clearNewBookingCache ? state.newBookingCustomersPagination : null,
                newBookingServicesPagination: !clearNewBookingCache ? state.newBookingServicesPagination : null,
            };
        }

        default:
            return state;
    }
};

export const actions = {
    getEntries: (profileId, startTime, endTime, filterData) => ({
        type: actionTypes.CALENDAR_GET_ENTRIES_REQUEST,
        payload: { profileId, startTime, endTime, filterData },
    }),

    getResourceEntries: (profileId, startTime, endTime, page, perPage, filterData, mergeResult) => ({
        type: actionTypes.CALENDAR_GET_RESOURCE_ENTRIES_REQUEST,
        payload: { profileId, startTime, endTime, page, perPage, filterData, mergeResult },
    }),

    getEntry: (entry) => ({
        type: actionTypes.CALENDAR_GET_ENTRY_REQUEST,
        payload: { entry },
    }),
    getEntryAttendees: (entryId, page, perPage, type, status) => ({
        type: actionTypes.CALENDAR_GET_ENTRY_ATTENDEES_REQUEST,
        payload: { entryId, page, perPage, type, status },
    }),
    getEntryWaitingListMembers: (entryId, page, perPage) => ({
        type: actionTypes.CALENDAR_GET_ENTRY_WAITING_LIST_REQUEST,
        payload: { entryId, page, perPage },
    }),
    updateEntryAppointment: (
        originalEntry,
        startTime,
        endTime,
        staffNotes,
        changedResourceId,
        notifyUser,
        callback
    ) => ({
        type: actionTypes.CALENDAR_UPDATE_ENTRY_APPOINTMENT_REQUEST,
        payload: { originalEntry, startTime, endTime, staffNotes, changedResourceId, notifyUser, callback },
    }),
    deleteEntryAppointment: (entryId, notifyUser, cancellationMessage, callback) => ({
        type: actionTypes.CALENDAR_DELETE_ENTRY_APPOINTMENT_REQUEST,
        payload: { entryId, notifyUser, cancellationMessage, callback },
    }),

    updateEntryOccasion: (id, originalEntry, updatedEntry, callback) => ({
        type: actionTypes.CALENDAR_UPDATE_ENTRY_OCCASION_REQUEST,
        payload: { id, originalEntry, updatedEntry, callback },
    }),
    deleteEntryOccasion: (entryId, notifyUser, callback) => ({
        type: actionTypes.CALENDAR_DELETE_ENTRY_OCCASION_REQUEST,
        payload: { entryId, notifyUser, callback },
    }),

    addEntryOccasionResource: (entryId, resourceId) => ({
        type: actionTypes.CALENDAR_ADD_ENTRY_OCCASION_RESOURCE_REQUEST,
        payload: { entryId, resourceId },
    }),

    updateEntryOccasionAttendeePresence: (entryAttendeeId, isPresent) => ({
        type: actionTypes.CALENDAR_UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST,
        payload: { entryAttendeeId, isPresent },
    }),

    deleteEntryOccasionAttendee: (entryAttendeeId) => ({
        type: actionTypes.CALENDAR_DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST,
        payload: { entryAttendeeId },
    }),

    getNewBookingCustomers: (profileId, search) => ({
        type: actionTypes.BOOKINGMANAGER_GET_CUSTOMERS_REQUEST,
        payload: { profileId, search },
    }),
    getNewBookingServices: (profileId, search) => ({
        type: actionTypes.BOOKINGMANAGER_GET_SERVICES_REQUEST,
        payload: { profileId, search },
    }),
    getNewBookingServiceResources: (serviceId, search) => ({
        type: actionTypes.BOOKINGMANAGER_GET_SERVICE_RESOURCES_REQUEST,
        payload: { serviceId, search },
    }),
    getNewBookingServiceAddons: (serviceId, search, type) => ({
        type: actionTypes.BOOKINGMANAGER_GET_SERVICE_ADDONS_REQUEST,
        payload: { serviceId, search, type },
    }),
    addNewBooking: (profileId, data, callback) => ({
        type: actionTypes.BOOKINGMANAGER_ADD_BOOKING_REQUEST,
        payload: { profileId, data, callback },
    }),
    onNewBookingServiceUnselected: () => ({
        type: actionTypes.BOOKINGMANAGER_SERVICE_UNSELECTED,
    }),

    isResourceAvailable: (resourceId, startTime, endTime) => ({
        type: actionTypes.BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_REQUEST,
        payload: { resourceId, startTime, endTime },
    }),

    clearBookingManagerState: (clearNewBookingCache) => ({
        type: actionTypes.CLEAR_BOOKINGMANAGER_STATE,
        payload: { clearNewBookingCache },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.CALENDAR_GET_ENTRIES_REQUEST, function* ({ payload }) {
        yield put(entryActions.getEntries(payload.profileId, payload.startTime, payload.endTime, payload.filterData));
    });

    yield takeLatest(actionTypes.CALENDAR_GET_RESOURCE_ENTRIES_REQUEST, function* ({ payload }) {
        yield put(
            entryActions.getResourceEntries(
                payload.profileId,
                payload.startTime,
                payload.endTime,
                payload.page,
                payload.perPage,
                payload.filterData,
                payload.mergeResult
            )
        );
    });

    yield takeLatest(actionTypes.CALENDAR_GET_ENTRY_REQUEST, function* ({ payload }) {
        try {
            yield put(entryActions.selectEntry(payload.entry));
            if (entryUtils.isAppointmentEntry(payload.entry)) {
                yield put(entryActions.getEntryAppointment(payload.entry.id));
            } else if (entryUtils.isCourseOccasionEntry(payload.entry)) {
                yield put(entryActions.getEntryCourseOccasion(payload.entry.id));
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_GET_ENTRY_ATTENDEES_REQUEST, function* ({ payload }) {
        yield put(
            entryActions.getEntryCourseOccasionAttendees(
                payload.entryId,
                payload.page,
                payload.perPage,
                payload.type,
                payload.status
            )
        );
    });

    yield takeLatest(actionTypes.CALENDAR_GET_ENTRY_WAITING_LIST_REQUEST, function* ({ payload }) {
        yield put(
            entryActions.getEntryCourseOccasionWaitingListMembers(payload.entryId, payload.page, payload.perPage)
        );
    });

    yield takeLatest(actionTypes.CALENDAR_UPDATE_ENTRY_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            yield put(
                entryActions.updateEntryAppointment(
                    payload.originalEntry,
                    payload.startTime,
                    payload.endTime,
                    payload.staffNotes,
                    payload.changedResourceId,
                    payload.notifyUser,
                    payload.callback
                )
            );
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_UPDATE_ENTRY_OCCASION_REQUEST, function* ({ payload }) {
        try {
            yield put(
                entryActions.updateEntryOccasion(
                    payload.id,
                    payload.originalEntry,
                    payload.updatedEntry,
                    payload.callback
                )
            );
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_DELETE_ENTRY_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            yield put(
                entryActions.deleteEntryAppointment(
                    payload.entryId,
                    payload.notifyUser,
                    payload.cancellationMessage,
                    payload.callback
                )
            );
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_DELETE_ENTRY_OCCASION_REQUEST, function* ({ payload }) {
        try {
            yield put(entryActions.deleteEntryOccasion(payload.entryId, payload.notifyUser, payload.callback));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_ADD_ENTRY_OCCASION_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            yield put(entryActions.addEntryOccasionResource(payload.entryId, payload.resourceId));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST, function* ({ payload }) {
        try {
            yield put(entryActions.updateEntryOccasionAttendeePresence(payload.entryAttendeeId, payload.isPresent));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CALENDAR_DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST, function* ({ payload }) {
        try {
            yield put(entryActions.deleteEntryOccasionAttendee(payload.entryAttendeeId, payload.callback));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.BOOKINGMANAGER_GET_CUSTOMERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getCustomers(payload.profileId, 1, 20, payload.search);
            yield put({
                type: actionTypes.BOOKINGMANAGER_GET_CUSTOMERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.BOOKINGMANAGER_GET_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServices(payload.profileId, 1, 9999, payload.search, "adminBookable");
            yield put({
                type: actionTypes.BOOKINGMANAGER_GET_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.BOOKINGMANAGER_GET_SERVICE_RESOURCES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceResources(payload.serviceId, 1, 20, payload.search);
            response.search = payload.search;
            yield put({
                type: actionTypes.BOOKINGMANAGER_GET_SERVICE_RESOURCES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.BOOKINGMANAGER_GET_SERVICE_ADDONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceAddons(payload.serviceId, 1, 20, payload.search, payload.type);
            response.search = payload.search;
            yield put({
                type: actionTypes.BOOKINGMANAGER_GET_SERVICE_ADDONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.BOOKINGMANAGER_ADD_BOOKING_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addBooking(payload.profileId, payload.data);
            yield put({
                type: actionTypes.BOOKINGMANAGER_ADD_BOOKING_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield isResourceAvailable(
                payload.resourceId,
                payload.startTime,
                payload.endTime
            );
            yield put({
                type: actionTypes.BOOKINGMANAGER_IS_RESOURCE_AVAILABLE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
