import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export const DeleteDialog = ({
  title,
  content,
  open,
  handleOpenToggle,
  handleDelete,
}) => {
  const handleYes = () => {
    handleDelete();
    handleOpenToggle();
  };
  const handleNo = () => {
    handleOpenToggle();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpenToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleYes} color="primary">
            <FormattedMessage id="CUSTOMER.DELETE.YES" />
          </Button>

          <Button onClick={handleNo} color="primary" autoFocus>
            <FormattedMessage id="CUSTOMER.DELETE.NO" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
