import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../components/SnackbarError";
import PaymentTemplateDetailsForm from "./PaymentTemplateDetailsForm";
import {
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    paymentTemplateInputSchema,
} from "./utils";

function PaymentTemplatePageAdd({
    addProfilePaymentTemplate,
    clearPaymentTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { paymentTemplate, isLoading } = useSelector((state) => state.paymentTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(paymentTemplateInputSchema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        return () => {
            clearPaymentTemplateState();
            clearErrorState();
        };
    }, [clearPaymentTemplateState, clearErrorState]);

    const onFormSubmit = (values) => {
        var templateData = {
            name: values.name,
            isDefault: values.isDefault,
            paymentMode: values.paymentMode,
            billingTemplateId: values?.billingTemplate?.id,
        };

        if (values.paymentMethods) {
            templateData.isSwishEnabled = values.paymentMethods.includes(PAYMENT_METHOD_SWISH);
            templateData.isCardEnabled = values.paymentMethods.includes(PAYMENT_METHOD_CARD);
            templateData.isInvoiceEnabled = values.paymentMethods.includes(PAYMENT_METHOD_INVOICE);
        }

        if (values.paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION) {
            templateData.isSubscription = true;
            templateData.subscriptionUnit = values.subscriptionUnit.id;
            templateData.subscriptionDayMode = values.subscriptionDayMode.id;
            templateData.subscriptionDayOfMonth = values.subscriptionDayOfMonth;
            templateData.subscriptionNoticePeriodDays = values.subscriptionNoticePeriodDays;
            templateData.isSubscriptionExtendOnFailure = values.isSubscriptionExtendOnFailure;
        }

        addProfilePaymentTemplate(profileId, templateData, () => {
            setRedirect(true);
        });
    };

    if (redirect && paymentTemplate) {
        return <Redirect to={`/payment-templates/${paymentTemplate.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="PAYMENT_TEMPLATE.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="PROFILE.PAYMENT_TEMPLATES.SUBTITLE" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <PaymentTemplateDetailsForm />
                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...paymentTemplateActions,
    ...errorActions,
})(PaymentTemplatePageAdd);
