import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as campaignActions } from "../../../redux/campaigns/campaignRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import { Paper } from "@material-ui/core";
import { LinkCell, DateTimeCell, TextCell, TranslationCell } from "../../components/tables/MaterialTableCells";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import * as campaignUtils from "./utils";
import CampaignListToolbar from "../../components/tables/toolbar/CampaignListToolbar";
import ListActionsButton from "../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";

function CampaignListPage({ getCampaigns, deleteCampaign, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination } = useSelector((state) => state.campaigns);
    const { displayError } = useSelector((state) => state.errors);

    const [campaignToDelete, setCampaignToDelete] = useState(null);

    const history = useHistory();

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getCampaigns(profile.id, pageIndex + 1, pageSize);
        },
        [getCampaigns, profile.id]
    );

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return <CampaignListToolbar />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: ({ row }) => {
                                const url = campaignUtils.getCampaignDetailsPageUrl(row.original.type, row.original.id);
                                if (!url) return TextCell(row.original.id);
                                return LinkCell(url, row.original.id);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.TYPE" />,
                            accessor: "type",
                            Cell: (props) => {
                                if (props.value === campaignUtils.CAMPAIGN_TYPE_SMS) {
                                    return TranslationCell("CAMPAIGN.TYPE.SMS");
                                }
                                return TextCell();
                            },
                        },
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.NAME" />,
                            accessor: "name",
                            Cell: (props) => {
                                return TextCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.CREATED_AT" />,
                            accessor: "createdAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.PUBLISH_AT" />,
                            accessor: "publishAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.FINISHED_AT" />,
                            accessor: "finishedAt",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="CAMPAIGN.LIST.HEADER.STATUS" />,
                            accessor: "status",
                            Cell: (props) => {
                                return campaignUtils.getCampaignStatusCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                            id: "actions",
                            textAlign: "center",
                            Cell: ({ row }) => {
                                let menuItems = [
                                    {
                                        icon: <LinkIcon fontSize="small" />,
                                        label: <FormattedMessage id="COMMON.OPEN" />,
                                        onClick: () => {
                                            const url = campaignUtils.getCampaignDetailsPageUrl(
                                                row.original.type,
                                                row.original.id
                                            );
                                            history.push(url);
                                        },
                                    },
                                ];

                                if (campaignUtils.isCampaignUpdateable(row.original.status)) {
                                    menuItems.push({
                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                        onClick: () => setCampaignToDelete(row.original),
                                    });
                                }

                                return <ListActionsButton menuItems={menuItems} />;
                            },
                        },
                    ],
                    [history]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
            <YesNoDialog
                title={<FormattedMessage id="CAMPAIGN.DELETE.TEXT" />}
                open={campaignToDelete ? true : false}
                onOpenChanged={() => setCampaignToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteCampaign(campaignToDelete.id);
                    }
                    setCampaignToDelete(null);
                }}
            />
        </Paper>
    );
}

export default connect(null, { ...campaignActions, ...errorActions })(CampaignListPage);
