import React, { useMemo, useCallback } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import {
    LinkCell,
    LinkCellEllipsis,
    DateTimeCell,
    TextCell,
    TextCellEllipsis,
    ChipCell,
} from "../../components/tables/MaterialTableCells";
import * as bookingUtils from "../bookings/bookings/utils";
import { getServiceDetailsPageUrl } from "../services/utils";
import { isAtLeastStaffPermission } from "../resources/utils";

function CustomerPageBookings({ getCustomerBookings }) {
    const { profile } = useSelector((state) => state.auth);
    const { customer, customerBookingsPagination, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const customerId = customer?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerId) {
                getCustomerBookings(customerId, pageIndex + 1, pageSize);
            }
        },
        [customerId, getCustomerBookings]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.BOOKINGS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.BOOKINGS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customer?.id}
                        data={useMemo(() => customerBookingsPagination.data, [customerBookingsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={customerBookingsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/bookings/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.SERVICE" />,
                                    accessor: "service",
                                    maxWidth: 275,
                                    Cell: (props) => {
                                        if (isAtLeastStaffPermission(profile.permissionId)) {
                                            const url = getServiceDetailsPageUrl(props.value.type, props.value.id);
                                            if (!url) return TextCellEllipsis(props.value.name);
                                            return LinkCellEllipsis(url, props.value.name);
                                        }

                                        return TextCellEllipsis(props.value.name);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.PRICE" />,
                                    accessor: "price",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.START_TIME" />,
                                    accessor: "entries_starttime",
                                    Cell: ({ row }) => {
                                        const entries = row.original.entries;
                                        if (entries && entries.length === 1) {
                                            return DateTimeCell(entries[0].startTime);
                                        }
                                        return TextCell("");
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.END_TIME" />,
                                    accessor: "entries_endtime",
                                    Cell: ({ row }) => {
                                        const entries = row.original.entries;
                                        if (entries && entries.length === 1) {
                                            return DateTimeCell(entries[0].endTime);
                                        }
                                        return TextCell("");
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === bookingUtils.BOOKING_STATUS_DELETED) {
                                            return ChipCell("error", <FormattedMessage id="BOOKING.STATUS.DELETED" />);
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_CANCELLED) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="BOOKING.STATUS.CANCELLED" />
                                            );
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_UNCONFIRMED) {
                                            return ChipCell(
                                                "warning",
                                                <FormattedMessage id="BOOKING.STATUS.UNCONFIRMED" />
                                            );
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_UNPAID) {
                                            return ChipCell("warning", <FormattedMessage id="BOOKING.STATUS.UNPAID" />);
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_CONFIRMED) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="BOOKING.STATUS.CONFIRMED" />
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                            ],
                            [profile.permissionId]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...customerActions, ...errorActions })(CustomerPageBookings);
