import React from "react";
import { Form, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const ReportFilterDropdownMultiple = ({ name, title, options, defaultValues, isDisabled }) => {
    const { control, errors, setValue, watch } = useFormContext();

    const selectedOptions = watch(name);
    if (defaultValues && !selectedOptions) {
        setValue(name, defaultValues);
    }

    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Form.Row>
                <Col xl={4} xs={12}>
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={null}
                        render={() => (
                            <div>
                                <AsyncTypeahead
                                    id="typeahead-resource-types"
                                    labelKey={"name"}
                                    minLength={0}
                                    clearButton={true}
                                    onChange={(resources) => {
                                        setValue(name, resources);
                                    }}
                                    onSearch={() => {}}
                                    useCache={false}
                                    isLoading={false}
                                    options={options}
                                    selected={selectedOptions ? selectedOptions : []}
                                    inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    isInvalid={errors.resourceTypes?.message ? true : false}
                                    disabled={isDisabled}
                                    multiple
                                />
                            </div>
                        )}
                    />
                </Col>
            </Form.Row>
        </Form.Group>
    );
};

export default ReportFilterDropdownMultiple;
