import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as cancellationTemplateActions } from "../../../redux/cancellationtemplates/cancellationTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../components/SnackbarError";
import CancellationTemplateDetailsForm from "./CancellationTemplateDetailsForm";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    minuteLimit: yup
        .number()
        .transform((_, val) => (val === "" ? null : parseInt(val, 10)))
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />),
});
function CancellationTemplatePageAdd({
    addProfileCancellationTemplate,
    clearCancellationTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { cancellationTemplate, isLoading } = useSelector((state) => state.cancellationTemplates);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        return () => {
            clearCancellationTemplateState();
            clearErrorState();
        };
    }, [clearCancellationTemplateState, clearErrorState]);

    const onFormSubmit = (values) => {
        var cancellationTemplateData = {
            name: values.name,
            isDefault: values.isDefault,
            minuteLimit: values.minuteLimit,
            isAutomaticRefund: values.isAutomaticRefund,
        };

        addProfileCancellationTemplate(profileId, cancellationTemplateData, () => {
            setRedirect(true);
        });
    };

    if (redirect && cancellationTemplate) {
        return <Redirect to={`/cancellation-templates/${cancellationTemplate.id}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="CANCELLATION_TEMPLATE.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="PROFILE.CANCELLATION_TEMPLATES.SUBTITLE" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <CancellationTemplateDetailsForm />

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...cancellationTemplateActions,
    ...errorActions,
})(CancellationTemplatePageAdd);
