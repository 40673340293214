import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { makeStyles, IconButton } from "@material-ui/core";
import CourseOccasionAddModal from "./CourseOccasionAddModal";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    occasionContainer: {
        marginBottom: "20px",
    },
    listItemOccasionContainer: {
        display: "flex",
        border: "1px solid #E4E6EF",
        borderRadius: "0.42rem",
        backgroundColor: "#ffffff",
        marginBottom: "1.5rem",
        padding: "12px",
        alignItems: "center",
        maxWidth: "450px",
    },
    listItemOccasionTextContainer: {
        flexGrow: 1,
        flexDirection: "column",
        display: "flex",
    },
    listItemOccasionHeader: {
        color: "#3F4254",
        fontSize: "1.25rem",
    },
    listItemOccasionSubtitle: {
        color: "#B5B5C3",
        fontSize: "1rem",
    },
    listItemOccasionRemoveButton: {
        color: "red",
        padding: "0px",
        marginLeft: "16px",
    },
}));

function getOccasionTitle(occasion) {
    const startTime = occasion.startTime;
    const endTime = occasion.endTime;
    const repeatUntil = occasion.repeatUntil;
    if (!repeatUntil) {
        let dayName = startTime.format("dddd");
        dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
        return (
            <FormattedMessage
                id="SERVICE.COURSE.OCCASIONS.ADD.TITLE_SINGLE"
                values={{ dayName, startTime: startTime.format("YYYY-MM-DD HH:mm"), endTime: endTime.format("HH:mm") }}
            />
        );
    }

    return (
        <FormattedMessage
            id="SERVICE.COURSE.OCCASIONS.ADD.TITLE_REPEAT"
            values={{
                dayName: startTime.format("dddd"),
                startTime: startTime.format("HH:mm"),
                endTime: endTime.format("HH:mm"),
            }}
        />
    );
}

function getOccasionSubtitle(occasion) {
    const startTime = occasion.startTime;
    const repeatUntil = occasion.repeatUntil;
    const weeksBetween = repeatUntil.diff(occasion.endTime, "week") + 1;
    return (
        <FormattedMessage
            id="SERVICE.COURSE.OCCASIONS.ADD.SUBTITLE_REPEAT"
            values={{
                repeatFrom: startTime.format("YYYY-MM-DD"),
                repeatUntil: repeatUntil.format("YYYY-MM-DD"),
                count: weeksBetween,
            }}
        />
    );
}

function CourseOccasionAddForm() {
    const classes = useStyles();

    const { register, setValue, getValues, watch, errors } = useFormContext();

    const [showAddOccasionModal, setShowAddOccasionModal] = useState(false);

    const onOccasionRemoved = (index) => {
        let occasions = getValues("occasions") || [];
        let updatedOccasions = occasions.slice();
        updatedOccasions.splice(index, 1);
        setValue("occasions", updatedOccasions);
    };

    register("occasions");
    let occasions = watch("occasions");
    if (!occasions) occasions = [];

    return (
        <div>
            <div className={classes.occasionContainer}>
                {occasions.map((occasion, i) => {
                    return (
                        <div key={`occasion_${i}`} className={classes.listItemOccasionContainer}>
                            <div className={classes.listItemOccasionTextContainer}>
                                <span className={classes.listItemOccasionHeader}>{getOccasionTitle(occasion)}</span>
                                {occasion.repeatUntil && (
                                    <span className={classes.listItemOccasionSubtitle}>
                                        {getOccasionSubtitle(occasion)}
                                    </span>
                                )}
                            </div>

                            <IconButton
                                onClick={() => onOccasionRemoved(i)}
                                className={classes.listItemOccasionRemoveButton}
                            >
                                <HighlightOffRoundedIcon fontSize="large" />
                            </IconButton>
                        </div>
                    );
                })}
            </div>

            <Button
                variant={!errors.occasions?.message ? "outline-primary" : "outline-danger"}
                onClick={() => setShowAddOccasionModal(true)}
            >
                <FormattedMessage id="SERVICE.COURSE.OCCASIONS.ADD_BUTTON" />
            </Button>

            {errors.occasions?.message && (
                <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "10px" }}>
                    {errors.occasions.message}
                </div>
            )}

            {showAddOccasionModal && (
                <CourseOccasionAddModal
                    show={showAddOccasionModal}
                    onCloseClicked={() => setShowAddOccasionModal(false)}
                    onOccasionAdded={(occasionData) => {
                        setShowAddOccasionModal(false);
                        let occasions = getValues("occasions") || [];
                        let updatedOccasions = occasions.slice();
                        updatedOccasions.push(occasionData);
                        setValue("occasions", updatedOccasions, { shouldValidate: true });
                    }}
                />
            )}
        </div>
    );
}

export default CourseOccasionAddForm;
