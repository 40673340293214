import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROFILE_CANCELLATION_TEMPLATES_URL = "v1/admin/profiles/:id/cancellation-templates";

export const CANCELLATION_TEMPLATE_DETAILS_URL = "v1/admin/cancellation-templates/:id";

export const CANCELLATION_TEMPLATE_REFUND_RULES_URL = "v1/admin/cancellation-templates/:id/refund-rules";
export const CANCELLATION_TEMPLATE_REFUND_RULE_DETAILS_URL = "v1/admin/cancellation-template-refund-rules/:id";

export function getProfileCancellationTemplates(profileId, page, perPage) {
    let url = `${PROFILE_CANCELLATION_TEMPLATES_URL.replace(":id", profileId)}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function getProfileCancellationTemplate(id) {
    return axios.get(getApiUrl() + CANCELLATION_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function addProfileCancellationTemplate(profileId, data) {
    return axios.post(getApiUrl() + PROFILE_CANCELLATION_TEMPLATES_URL.replace(":id", profileId), data);
}

export function updateProfileCancellationTemplate(id, originalCancellationTemplate, changedCancellationTemplate) {
    let url = CANCELLATION_TEMPLATE_DETAILS_URL.replace(":id", id);
    return axios.patch(
        getApiUrl() + url,
        jsonpatch.compare(originalCancellationTemplate, changedCancellationTemplate),
        {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        }
    );
}

export function deleteProfileCancellationTemplate(id) {
    return axios.delete(getApiUrl() + CANCELLATION_TEMPLATE_DETAILS_URL.replace(":id", id));
}

export function getProfileCancellationTemplateRefundRules(cancellationTemplateId, page, perPage) {
    let url = `${CANCELLATION_TEMPLATE_REFUND_RULES_URL.replace(
        ":id",
        cancellationTemplateId
    )}?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}

export function addCancellationTemplateRefundRule(cancellationTemplateId, data) {
    return axios.post(
        getApiUrl() + CANCELLATION_TEMPLATE_REFUND_RULES_URL.replace(":id", cancellationTemplateId),
        data
    );
}

export function deleteProfileCancellationTemplateRefundRule(id) {
    return axios.delete(getApiUrl() + CANCELLATION_TEMPLATE_REFUND_RULE_DETAILS_URL.replace(":id", id));
}
