import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions as paymentTemplateActions } from "../../../redux/paymenttemplates/paymentTemplateRedux";
import { Form, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import MaterialRadioDescriptionButton from "../../components/inputs/MaterialRadioDescriptionButton";
import {
    PAYMENT_MODE_ON_ARRIVAL,
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_MODE_MANDATORY,
    PAYMENT_TYPE_MODE_ONE_TIME,
    PAYMENT_TYPE_MODE_SUBSCRIPTION,
    PAYMENT_METHOD_SWISH,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_INVOICE,
    SUBSCRIPTION_RECURRING_UNIT_MONTHLY,
    SUBSCRIPTION_RECURRING_UNIT_QUARTERLY,
    SUBSCRIPTION_RECURRING_UNIT_YEARLY,
    SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
} from "./utils";

const useStyles = makeStyles((theme) => ({
    multipleOptionsRb: {
        marginTop: "4px",
        marginBottom: "4px",
    },
}));

function PaymentTemplateDetailsForm({ paymentTemplate, getPaymentTemplateOptions }) {
    const intl = useIntl();
    const classes = useStyles();

    const { profile } = useSelector((state) => state.auth);
    const { paymentTemplateOptions } = useSelector((state) => state.paymentTemplates);

    const { register, control, setValue, watch, errors } = useFormContext();

    useEffect(() => {
        if (!paymentTemplateOptions) {
            getPaymentTemplateOptions(profile.id);
        }
    }, [profile.id, paymentTemplateOptions, getPaymentTemplateOptions]);

    useEffect(() => {
        if (paymentTemplate) {
            setValue("isDefault", paymentTemplate.isDefault);
            setValue("isSubscriptionExtendOnFailure", paymentTemplate.isSubscriptionExtendOnFailure);

            setValue(
                "paymentMode",
                paymentTemplate.paymentMode === PAYMENT_MODE_ON_ARRIVAL
                    ? PAYMENT_MODE_ON_ARRIVAL
                    : paymentTemplate.paymentMode === PAYMENT_MODE_MANDATORY
                    ? PAYMENT_MODE_MANDATORY
                    : PAYMENT_MODE_OPTIONAL
            );

            setValue(
                "paymentTypeMode",
                !paymentTemplate.isSubscription ? PAYMENT_TYPE_MODE_ONE_TIME : PAYMENT_TYPE_MODE_SUBSCRIPTION
            );

            if (paymentTemplate.billingTemplate) {
                setValue("billingTemplate", getBillingTemplateOption(paymentTemplate.billingTemplate));
            }

            if (paymentTemplate.subscriptionUnit) {
                setValue("subscriptionUnit", getSubscriptionUnitOption(paymentTemplate.subscriptionUnit, intl));
            }

            if (paymentTemplate.subscriptionDayMode) {
                setValue(
                    "subscriptionDayMode",
                    getSubscriptionDayModeOption(paymentTemplate.subscriptionDayMode, intl)
                );
            }

            if (paymentTemplate.subscriptionDayOfMonth) {
                setValue("subscriptionDayOfMonth", paymentTemplate.subscriptionDayOfMonth);
            }
        }
    }, [paymentTemplate, setValue, intl]);

    const paymentMode = watch("paymentMode");

    const paymentTypeMode = watch("paymentTypeMode");
    if (!paymentTypeMode) {
        register("paymentTypeMode");
    }

    const subscriptionUnit = watch("subscriptionUnit");
    if (!subscriptionUnit) {
        register("subscriptionUnit");
    }

    const subscriptionDayMode = watch("subscriptionDayMode");
    if (!subscriptionDayMode) {
        register("subscriptionDayMode");
    }

    const subscriptionDayOfMonth = watch("subscriptionDayOfMonth");
    if (!subscriptionDayOfMonth) {
        register("subscriptionDayOfMonth");
    }

    // Add default null option first on billing templates since its optional
    let allBillingTemplates = paymentTemplateOptions?.billingTemplates
        ? [...paymentTemplateOptions.billingTemplates]
        : [];
    allBillingTemplates.unshift({
        id: null,
        name: intl.formatMessage({
            id: "PAYMENT_TEMPLATE.BILLING_TEMPLATE.DEFAULT_OPTION",
        }),
    });

    const billingTemplate = watch("billingTemplate");
    if (!billingTemplate) {
        register("billingTemplate");
        setValue("billingTemplate", getBillingTemplateOption(allBillingTemplates[0]), {
            shouldValidate: true,
        });
    }

    const allowSwish = paymentTemplateOptions?.paymentMethods?.findIndex((x) => x === PAYMENT_METHOD_SWISH) > -1;
    const allowCard = paymentTemplateOptions?.paymentMethods?.findIndex((x) => x === PAYMENT_METHOD_CARD) > -1;
    const allowInvoice = paymentTemplateOptions?.paymentMethods?.findIndex((x) => x === PAYMENT_METHOD_INVOICE) > -1;

    const setPaymentModeDependeableValuesToDefault = () => {
        setValue("paymentTypeMode", PAYMENT_TYPE_MODE_ONE_TIME);
        if (paymentTemplate?.billingTemplate) {
            setValue("billingTemplate", getBillingTemplateOption(paymentTemplate.billingTemplate));
        }
    };

    const shouldRecurringDayOfMonthBeLocked = (recurringUnitId, recurringDayModeId) => {
        return (
            (recurringUnitId === SUBSCRIPTION_RECURRING_UNIT_QUARTERLY ||
                recurringUnitId === SUBSCRIPTION_RECURRING_UNIT_YEARLY) &&
            recurringDayModeId === SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH
        );
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.NAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    className="form-control form-control-lg col-xl-4"
                    name="name"
                    ref={register}
                    isInvalid={errors.name}
                    defaultValue={paymentTemplate?.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.NAME_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.IS_DEFAULT" />
                </Form.Label>
                <Controller
                    name="isDefault"
                    control={control}
                    defaultValue={paymentTemplate ? paymentTemplate.isDefault : false}
                    render={({ value, onChange }) => {
                        return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                    }}
                />
                <Form.Text className="text-muted">
                    <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.IS_DEFAULT_DESCRIPTION" />
                </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="PAYMENT_TEMPLATE.MODE.TITLE" />
                </Form.Label>
                <Controller
                    control={control}
                    name="paymentMode"
                    defaultValue={PAYMENT_MODE_ON_ARRIVAL}
                    render={({ onChange, value }) => (
                        <>
                            <Row noGutters>
                                <Col sm={4} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === PAYMENT_MODE_ON_ARRIVAL}
                                        value={PAYMENT_MODE_ON_ARRIVAL}
                                        onChange={(e) => onChange(e)}
                                        titleIntlId={"PAYMENT_TEMPLATE.MODE.ON_ARRIVAL.TITLE"}
                                        descriptionIntlId={"PAYMENT_TEMPLATE.MODE.ON_ARRIVAL.DESCRIPTION"}
                                    />
                                </Col>
                                <Col sm={4} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === PAYMENT_MODE_MANDATORY}
                                        value={PAYMENT_MODE_MANDATORY}
                                        onChange={(e) => {
                                            setPaymentModeDependeableValuesToDefault();
                                            onChange(e);
                                        }}
                                        titleIntlId={"PAYMENT_TEMPLATE.MODE.MANDATORY.TITLE"}
                                        descriptionIntlId={"PAYMENT_TEMPLATE.MODE.MANDATORY.DESCRIPTION"}
                                    />
                                </Col>
                                <Col sm={4} className={classes.multipleOptionsRb}>
                                    <MaterialRadioDescriptionButton
                                        checked={value === PAYMENT_MODE_OPTIONAL}
                                        value={PAYMENT_MODE_OPTIONAL}
                                        onChange={(e) => {
                                            setPaymentModeDependeableValuesToDefault();
                                            onChange(e);
                                        }}
                                        titleIntlId={"PAYMENT_TEMPLATE.MODE.OPTIONAL.TITLE"}
                                        descriptionIntlId={"PAYMENT_TEMPLATE.MODE.OPTIONAL.DESCRIPTION"}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                />
            </Form.Group>
            {paymentMode && paymentMode !== PAYMENT_MODE_ON_ARRIVAL && (
                <>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.TITLE" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="paymentTypeMode"
                            defaultValue={
                                !paymentTemplate?.isSubscription
                                    ? PAYMENT_TYPE_MODE_ONE_TIME
                                    : PAYMENT_TYPE_MODE_SUBSCRIPTION
                            }
                            render={({ onChange, value }) => (
                                <>
                                    <Col xs={4}>
                                        <MaterialRadioDescriptionButton
                                            checked={value === PAYMENT_TYPE_MODE_ONE_TIME}
                                            value={PAYMENT_TYPE_MODE_ONE_TIME}
                                            onChange={(e) => onChange(e)}
                                            titleIntlId={"PAYMENT_TEMPLATE.PAYMENT.TYPE.SINGLE_PAYMENT"}
                                            descriptionIntlId={
                                                "PAYMENT_TEMPLATE.PAYMENT.TYPE.SINGLE_PAYMENT_DESCRIPTION"
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <MaterialRadioDescriptionButton
                                            checked={value === PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                            value={PAYMENT_TYPE_MODE_SUBSCRIPTION}
                                            onChange={(e) => {
                                                if (!subscriptionUnit) {
                                                    setValue(
                                                        "subscriptionUnit",
                                                        getSubscriptionUnitOption(
                                                            SUBSCRIPTION_RECURRING_UNIT_MONTHLY,
                                                            intl
                                                        )
                                                    );
                                                }

                                                if (!subscriptionDayMode) {
                                                    setValue(
                                                        "subscriptionDayMode",
                                                        getSubscriptionDayModeOption(
                                                            SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
                                                            intl
                                                        )
                                                    );
                                                }
                                                onChange(e);
                                            }}
                                            titleIntlId={"PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT"}
                                            descriptionIntlId={
                                                "PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT_DESCRIPTION"
                                            }
                                        />
                                    </Col>
                                </>
                            )}
                        />
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            <FormattedMessage id="PAYMENT_TEMPLATE.METHODS" />
                        </Form.Label>
                        <Col sm={10}>
                            <FormControlLabel
                                value={PAYMENT_METHOD_CARD}
                                control={
                                    <Checkbox
                                        disabled={!allowCard}
                                        defaultChecked={
                                            (allowCard && !paymentTemplate) ||
                                            (paymentTemplate && paymentTemplate?.paymentMethods?.includes("card"))
                                        }
                                    />
                                }
                                label={<FormattedMessage id="PAYMENT_TEMPLATE.METHOD.CARD" />}
                                name={"paymentMethods"}
                                inputRef={register}
                                style={{ marginLeft: "0px" }}
                            />
                            <FormControlLabel
                                value={PAYMENT_METHOD_INVOICE}
                                control={
                                    <Checkbox
                                        disabled={!allowInvoice}
                                        defaultChecked={
                                            (allowInvoice && !paymentTemplate) ||
                                            (paymentTemplate && paymentTemplate?.paymentMethods?.includes("invoice"))
                                        }
                                    />
                                }
                                label={<FormattedMessage id="PAYMENT_TEMPLATE.METHOD.INVOICE" />}
                                name={"paymentMethods"}
                                inputRef={register}
                            />
                            {paymentTypeMode === PAYMENT_TYPE_MODE_ONE_TIME && (
                                <FormControlLabel
                                    value={PAYMENT_METHOD_SWISH}
                                    control={
                                        <Checkbox
                                            disabled={!allowSwish}
                                            defaultChecked={
                                                (allowSwish && !paymentTemplate) ||
                                                (paymentTemplate && paymentTemplate?.paymentMethods?.includes("swish"))
                                            }
                                        />
                                    }
                                    label={<FormattedMessage id="PAYMENT_TEMPLATE.METHOD.SWISH" />}
                                    name={"paymentMethods"}
                                    inputRef={register}
                                />
                            )}
                        </Col>
                        {errors.paymentMethods?.message && (
                            <>
                                <Col sm={2}></Col>
                                <Col style={{ marginLeft: "12px" }}>
                                    <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                                        {errors.paymentMethods.message}
                                    </div>
                                </Col>
                            </>
                        )}
                    </Form.Group>
                </>
            )}
            {paymentTypeMode === PAYMENT_TYPE_MODE_SUBSCRIPTION && (
                <>
                    <Form.Row>
                        <Form.Group as={Col} xl={4}>
                            <Form.Label>
                                <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_UNIT.TITLE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="subscriptionUnit"
                                defaultValue={null}
                                render={() => (
                                    <div>
                                        <Typeahead
                                            id="typeahead-subscription-recurring-unit"
                                            labelKey={"name"}
                                            onChange={(data) =>
                                                setValue("subscriptionUnit", data[0], {
                                                    shouldValidate: true,
                                                })
                                            }
                                            options={[
                                                getSubscriptionUnitOption(SUBSCRIPTION_RECURRING_UNIT_MONTHLY, intl),
                                                getSubscriptionUnitOption(SUBSCRIPTION_RECURRING_UNIT_QUARTERLY, intl),
                                                getSubscriptionUnitOption(SUBSCRIPTION_RECURRING_UNIT_YEARLY, intl),
                                            ]}
                                            selected={subscriptionUnit ? [subscriptionUnit] : []}
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                    </div>
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xl={4}>
                            <Form.Label>
                                <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.TITLE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="subscriptionDayMode"
                                defaultValue={SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING}
                                render={() => (
                                    <div>
                                        <Typeahead
                                            id="typeahead-subscription-recurring-day-mode"
                                            labelKey={"name"}
                                            onChange={(data) => {
                                                const recurringDayMode = data[0];
                                                setValue("subscriptionDayMode", recurringDayMode, {
                                                    shouldValidate: true,
                                                });

                                                if (
                                                    shouldRecurringDayOfMonthBeLocked(
                                                        subscriptionUnit.id,
                                                        recurringDayMode.id
                                                    )
                                                ) {
                                                    setValue("subscriptionDayOfMonth", 1);
                                                }
                                            }}
                                            options={[
                                                getSubscriptionDayModeOption(
                                                    SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
                                                    intl
                                                ),
                                                getSubscriptionDayModeOption(
                                                    SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
                                                    intl
                                                ),
                                            ]}
                                            selected={subscriptionDayMode ? [subscriptionDayMode] : []}
                                            filterBy={() => true}
                                            inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                        />
                                    </div>
                                )}
                            />
                        </Form.Group>
                        {subscriptionDayMode?.id === SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH && (
                            <Form.Group as={Col} xl={4}>
                                <Form.Label>
                                    <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH.TITLE" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg"
                                    name="subscriptionDayOfMonth"
                                    isInvalid={errors.subscriptionDayOfMonth}
                                    defaultValue={subscriptionDayOfMonth}
                                    disabled={shouldRecurringDayOfMonthBeLocked(
                                        subscriptionUnit.id,
                                        subscriptionDayMode.id
                                    )}
                                    onChange={(e) => {
                                        // ref={register} breaks this control in some cases so set value manually here
                                        const dayOfMonth = e.target.value || "" ? parseInt(e.target.value, 10) : "";
                                        setValue("subscriptionDayOfMonth", dayOfMonth);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.subscriptionDayOfMonth?.message}
                                </Form.Control.Feedback>
                                <Form.Text className="text-muted">
                                    {!shouldRecurringDayOfMonthBeLocked(subscriptionUnit.id, subscriptionDayMode.id) ? (
                                        <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH.DESCRIPTION.UNLOCKED" />
                                    ) : (
                                        <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH.DESCRIPTION.LOCKED" />
                                    )}
                                </Form.Text>
                            </Form.Group>
                        )}
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.NOTICE_PERIOD.TITLE" />
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control form-control-lg col-xl-2"
                            name="subscriptionNoticePeriodDays"
                            ref={register}
                            isInvalid={errors.subscriptionNoticePeriodDays}
                            defaultValue={paymentTemplate?.subscriptionNoticePeriodDays}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.subscriptionNoticePeriodDays?.message}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.NOTICE_PERIOD.DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.IS_SUBSCRIPTION_EXTEND_ON_FAILURE" />
                        </Form.Label>
                        <Controller
                            name="isSubscriptionExtendOnFailure"
                            control={control}
                            defaultValue={paymentTemplate ? paymentTemplate.isSubscriptionExtendOnFailure : false}
                            render={({ value, onChange }) => {
                                return <Switch checked={value} onChange={(event, value) => onChange(value)} />;
                            }}
                        />
                        <Form.Text className="text-muted">
                            <FormattedMessage id="PAYMENT_TEMPLATE.DETAILS.IS_SUBSCRIPTION_EXTEND_ON_FAILURE_DESCRIPTION" />
                        </Form.Text>
                    </Form.Group>
                </>
            )}
            {paymentMode && paymentMode !== PAYMENT_MODE_ON_ARRIVAL && (
                <Form.Row>
                    <Form.Group as={Col} xl={4}>
                        <Form.Label>
                            <FormattedMessage id="PAYMENT_TEMPLATE.BILLING_TEMPLATE" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="billingTemplate"
                            defaultValue={null}
                            render={() => (
                                <div>
                                    <Typeahead
                                        id="typeahead-payment-billing-template"
                                        labelKey={"name"}
                                        onChange={(billingTemplates) =>
                                            setValue("billingTemplate", billingTemplates[0], { shouldValidate: true })
                                        }
                                        options={allBillingTemplates.map((bt) => getBillingTemplateOption(bt))}
                                        selected={billingTemplate ? [billingTemplate] : []}
                                        filterBy={() => true}
                                        inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                    />
                                </div>
                            )}
                        />
                    </Form.Group>
                </Form.Row>
            )}
        </div>
    );
}

function getBillingTemplateOption(billingTemplate) {
    return { id: billingTemplate.id, name: billingTemplate.name };
}

function getSubscriptionUnitOption(id, intl) {
    if (id === SUBSCRIPTION_RECURRING_UNIT_MONTHLY) {
        return {
            id: SUBSCRIPTION_RECURRING_UNIT_MONTHLY,
            name: intl.formatMessage({
                id: "SUBSCRIPTION.RECURRING_UNIT.MONTHLY",
            }),
        };
    } else if (id === SUBSCRIPTION_RECURRING_UNIT_QUARTERLY) {
        return {
            id: SUBSCRIPTION_RECURRING_UNIT_QUARTERLY,
            name: intl.formatMessage({
                id: "SUBSCRIPTION.RECURRING_UNIT.QUARTERLY",
            }),
        };
    } else if (id === SUBSCRIPTION_RECURRING_UNIT_YEARLY) {
        return {
            id: SUBSCRIPTION_RECURRING_UNIT_YEARLY,
            name: intl.formatMessage({
                id: "SUBSCRIPTION.RECURRING_UNIT.YEARLY",
            }),
        };
    }
}

function getSubscriptionDayModeOption(id, intl) {
    if (id === SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING) {
        return {
            id: SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING,
            name: intl.formatMessage({
                id: "PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.ONGOING",
            }),
        };
    } else if (id === SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
        return {
            id: SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH,
            name: intl.formatMessage({
                id: "PAYMENT_TEMPLATE.PAYMENT.TYPE.SUBSCRIPTION_PAYMENT.RECURRING_DAY_MODE.DAY_OF_MONTH",
            }),
        };
    }
}

export default connect(null, paymentTemplateActions)(PaymentTemplateDetailsForm);
