import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

export function ErrorNoCompanyPage() {
    const history = useHistory();

    const onBackToLoginClicked = () => {
        history.push("/logout");
    };

    const onCompanyApplicationClicked = () => {
        window.location.replace("https://docs.google.com/forms/d/1k2XcoXEasZYsSfRUjAbE8F4bIg6HMzhQ2suqCvPdCp0/edit");
    };

    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`,
                }}
            >
                <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                    <p className="display-4 font-weight-boldest text-white mb-12">
                        <FormattedMessage id="ERRORPAGE.NOCOMPANY.TITLE" />
                    </p>
                    <p className="font-size-h4 line-height-md">
                        <FormattedMessage id="ERRORPAGE.NOCOMPANY.DESCRIPTION1" />
                    </p>
                    <p className="font-size-h4 line-height-md">
                        <FormattedMessage id="ERRORPAGE.NOCOMPANY.DESCRIPTION2" />
                    </p>

                    <div className="py-10">
                        <button
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4"
                            onClick={onBackToLoginClicked}
                        >
                            <FormattedMessage id="ERRORPAGE.NOCOMPANY.BUTTON.BACK_TO_LOGIN" />
                        </button>
                        <button
                            className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                            onClick={onCompanyApplicationClicked}
                        >
                            <FormattedMessage id="ERRORPAGE.NOCOMPANY.BUTTON.COMPANY_APPLICATION" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
