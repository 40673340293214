import React from "react";
import { makeStyles, Radio, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: "flex",
    },
    labelsContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));

export default function MaterialRadioDescriptionButton({
    checked,
    value,
    name,
    onChange,
    titleIntlId,
    descriptionIntlId,
    rootClassName,
    disabled = false,
    readOnly = false,
}) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.rootContainer, rootClassName)}>
            <Radio
                checked={checked}
                value={value}
                name={name}
                onChange={(e) => onChange(e)}
                disabled={disabled}
                readOnly={readOnly}
            />
            <div className={classes.labelsContainer}>
                <Typography variant="body1" color={!disabled && !readOnly ? "initial" : "textSecondary"}>
                    <FormattedMessage id={titleIntlId} />
                </Typography>
                <Typography variant="body2" color={!disabled && !readOnly ? "initial" : "textSecondary"}>
                    <FormattedMessage id={descriptionIntlId} />
                </Typography>
            </div>
        </div>
    );
}
