import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Dropdown } from "react-bootstrap";
import { makeStyles, Grid, Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    emptyMediaContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "20px",
    },
    bigImageCard: {
        width: "400px",
        height: "260px",
        marginBottom: "10px",
    },
    bigImage: {
        width: "100%",
        height: "200px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        borderRadius: "0.42rem 0.42rem 0rem 0rem",
    },
    bigImageInfoContainer: {
        display: "flex",
        height: "60px",
        alignItems: "center",
        paddingLeft: "8px",
    },
    mediaItem: {
        width: "180px",
        height: "180px",
    },
    mediaItemBody: {
        padding: "0px",
    },
    mediaItemImage: {
        width: "100%",
        height: "120px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        borderRadius: "0.42rem 0.42rem 0rem 0rem",
    },
    mediaItemInfoContainer: {
        display: "flex",
        height: "60px",
        alignItems: "center",
        paddingLeft: "4px",
    },
    mediaItemInfoLabel: {
        flexGrow: "1",
    },
    mediaItemOptionItem: {
        padding: "2px",
    },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
        <span
            ref={ref}
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </span>
    );
});

function MediaContainer({
    mediaItems,
    onUpdateAvatarClicked,
    onUpdateCoverClicked,
    onRemoveMediaClicked,
    supportAvatarImage = true,
}) {
    const classes = useStyles();

    const closeDropDown = () => {
        // Not proper way of doing it but easier than states
        document.getElementById("media-container-grid").click();
    };

    var avatarMediaItem = mediaItems.find((x) => x.isAvatarImage);
    var coverMediaItem = mediaItems.find((x) => x.isCoverImage);

    return (
        <div>
            <Grid item xs={12} id="media-container-grid">
                <Grid container justifyContent="flex-start" spacing={2}>
                    {mediaItems?.length < 1 && !avatarMediaItem && !coverMediaItem && (
                        <div className={classes.emptyMediaContainer}>
                            <span>
                                <FormattedMessage id="RESOURCE.MEDIA.EMPTY" />
                            </span>
                        </div>
                    )}
                    {avatarMediaItem && (
                        <>
                            <Grid item xs={!coverMediaItem ? 12 : 6}>
                                <Card className={classes.bigImageCard}>
                                    <Card.Body className={classes.mediaItemBody}>
                                        <div
                                            className={classes.bigImage}
                                            style={{
                                                backgroundImage: `url(${avatarMediaItem.url})`,
                                            }}
                                        ></div>
                                        <div className={classes.bigImageInfoContainer}>
                                            <div className={classes.mediaItemInfoLabel}>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="RESOURCE.MEDIA.CURRENT_AVATAR" />
                                                </Typography>
                                            </div>
                                            <Dropdown className="dropdown dropdown-inline" alignRight>
                                                <Dropdown.Toggle
                                                    className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                                    variant="transparent"
                                                    id="dropdown-toggle-top-user-profile"
                                                    as={DropdownCustomToggler}
                                                >
                                                    <i className="ki ki-bold-more-ver"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right p-0">
                                                    <ul className="navi navi-hover">
                                                        <li
                                                            className="navi-item"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onUpdateAvatarClicked(avatarMediaItem.id, false);
                                                                closeDropDown();
                                                            }}
                                                        >
                                                            <a href="/" className="navi-link">
                                                                <span className="navi-text">
                                                                    <FormattedMessage id="RESOURCE.MEDIA.ITEM.OPTION.REMOVE_AVATAR" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Grid>
                        </>
                    )}
                    {coverMediaItem && (
                        <>
                            <Grid item xs={!avatarMediaItem ? 12 : 6}>
                                <Card className={classes.bigImageCard}>
                                    <Card.Body className={classes.mediaItemBody}>
                                        <div
                                            className={classes.bigImage}
                                            style={{
                                                backgroundImage: `url(${coverMediaItem.url})`,
                                            }}
                                        ></div>
                                        <div className={classes.bigImageInfoContainer}>
                                            <div className={classes.mediaItemInfoLabel}>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="RESOURCE.MEDIA.CURRENT_COVER" />
                                                </Typography>
                                            </div>
                                            <Dropdown className="dropdown dropdown-inline" alignRight>
                                                <Dropdown.Toggle
                                                    className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                                    variant="transparent"
                                                    id="dropdown-toggle-top-user-profile"
                                                    as={DropdownCustomToggler}
                                                >
                                                    <i className="ki ki-bold-more-ver"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right p-0">
                                                    <ul className="navi navi-hover">
                                                        <li
                                                            className="navi-item"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onUpdateCoverClicked(coverMediaItem.id, false);
                                                                closeDropDown();
                                                            }}
                                                        >
                                                            <a href="/" className="navi-link">
                                                                <span className="navi-text">
                                                                    <FormattedMessage id="RESOURCE.MEDIA.ITEM.OPTION.REMOVE_COVER" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Grid>
                        </>
                    )}
                    {(avatarMediaItem || coverMediaItem) && (
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    )}
                    {mediaItems.map((mediaItem) => (
                        <Grid key={mediaItem.id} item>
                            <Card className={classes.mediaItem}>
                                <Card.Body className={classes.mediaItemBody}>
                                    <div
                                        className={classes.mediaItemImage}
                                        style={{
                                            backgroundImage: `url(${mediaItem.url})`,
                                        }}
                                    ></div>
                                    <div className={classes.mediaItemInfoContainer}>
                                        <div className={classes.mediaItemInfoLabel}>
                                            {mediaItem.isAvatarImage && !mediaItem.isCoverImage && (
                                                <Typography variant="body2">
                                                    <FormattedMessage id="RESOURCE.MEDIA.ITEM.IS_AVATAR" />
                                                </Typography>
                                            )}
                                            {mediaItem.isCoverImage && !mediaItem.isAvatarImage && (
                                                <Typography variant="body2">
                                                    <FormattedMessage id="RESOURCE.MEDIA.ITEM.IS_COVER" />
                                                </Typography>
                                            )}
                                            {mediaItem.isAvatarImage && mediaItem.isCoverImage && (
                                                <Typography variant="body2">
                                                    <FormattedMessage id="RESOURCE.MEDIA.ITEM.IS_AVATAR_AND_COVER" />
                                                </Typography>
                                            )}
                                        </div>
                                        <Dropdown className="dropdown dropdown-inline" alignRight>
                                            <Dropdown.Toggle
                                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                                variant="transparent"
                                                id="dropdown-toggle-top-user-profile"
                                                as={DropdownCustomToggler}
                                            >
                                                <i className="ki ki-bold-more-ver"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right p-0">
                                                <ul className="navi navi-hover">
                                                    <>
                                                        {!mediaItem.isAvatarImage && supportAvatarImage && (
                                                            <>
                                                                <li
                                                                    className="navi-item"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        onUpdateAvatarClicked(mediaItem.id, true);
                                                                        closeDropDown();
                                                                    }}
                                                                >
                                                                    <a href="/" className="navi-link">
                                                                        <span className="navi-text">
                                                                            <FormattedMessage id="RESOURCE.MEDIA.ITEM.OPTION.SET_AVATAR" />
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                                <Divider />
                                                            </>
                                                        )}
                                                        {!mediaItem.isCoverImage && (
                                                            <>
                                                                <li
                                                                    className="navi-item"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        onUpdateCoverClicked(mediaItem.id, true);
                                                                        closeDropDown();
                                                                    }}
                                                                >
                                                                    <a href="/" className="navi-link">
                                                                        <span className="navi-text">
                                                                            <FormattedMessage id="RESOURCE.MEDIA.ITEM.OPTION.SET_COVER" />
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                                <Divider />
                                                            </>
                                                        )}
                                                        <li
                                                            className="navi-item"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onRemoveMediaClicked(mediaItem.id);
                                                                closeDropDown();
                                                            }}
                                                        >
                                                            <a href="/" className="navi-link">
                                                                <span className="navi-text">
                                                                    <FormattedMessage id="RESOURCE.MEDIA.ITEM.OPTION.REMOVE" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </>
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
}

export default MediaContainer;
