import React, { useState } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const FilterPartDateInterval = ({ dataQuery, title, onSaveHasBeenClicked }) => {
    const { register, setValue, watch } = useFormContext();

    const [createdAtStartPickerOpen, setCreatedAtStartPickerOpen] = useState(false);
    const [createdAtEndPickerOpen, setCreatedAtEndPickerOpen] = useState(false);

    if (!dataQuery.values || dataQuery.values.length === 0) return <></>;

    const startProperties = dataQuery.values[0];
    const endProperties = dataQuery.values[1];

    const startValue = watch(startProperties.property);
    const endValue = watch(endProperties.property);

    return (
        <>
            <Form.Group>
                <Form.Label>{title}</Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={startProperties.property}
                        ref={register}
                        onClick={() => setCreatedAtStartPickerOpen(true)}
                        isInvalid={startProperties.mandatory && !startValue && onSaveHasBeenClicked}
                    />
                    <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                        <InputGroup.Text id="basic-addon1">
                            <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={endProperties.property}
                        ref={register}
                        onClick={() => setCreatedAtEndPickerOpen(true)}
                        style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                        isInvalid={endProperties.mandatory && !endValue && onSaveHasBeenClicked}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                {startProperties.type === "datetime" && (
                    <DateTimePicker
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={createdAtStartPickerOpen}
                        onClose={() => setCreatedAtStartPickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                startProperties.property,
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                                {
                                    shouldValidate: true,
                                }
                            );
                        }}
                        clearable={true}
                    />
                )}
                {endProperties.type === "datetime" && (
                    <DateTimePicker
                        showTodayButton
                        ampm={false}
                        style={{ display: "none" }}
                        open={createdAtEndPickerOpen}
                        onClose={() => setCreatedAtEndPickerOpen(false)}
                        onChange={(selectedMomentDate) => {
                            setValue(
                                endProperties.property,
                                selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                                {
                                    shouldValidate: true,
                                }
                            );
                        }}
                        clearable={true}
                    />
                )}
            </MuiPickersUtilsProvider>
        </>
    );
};

export default FilterPartDateInterval;
