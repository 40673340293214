import React, { useMemo, useCallback } from "react";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { DateTimeCell, DateCell, TextCell, TranslationCell } from "../../components/tables/MaterialTableCells";
import * as transactionUtils from "./utils";
import { IconButton } from "@material-ui/core";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

function TransactionPageDocumentVersions({ getTransactionDocumentVersions, downloadTransactionDocumentVersionPdf }) {
    const { transaction, transactionDocumentVersionsPagination, isLoading, isDownloadingPdf } = useSelector(
        (state) => state.transactions
    );
    const { displayError } = useSelector((state) => state.errors);
    const transactionId = transaction?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (transactionId) {
                getTransactionDocumentVersions(transactionId, pageIndex + 1, pageSize);
            }
        },
        [transactionId, getTransactionDocumentVersions]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={transactionId}
                        data={useMemo(() => transactionDocumentVersionsPagination.data, [
                            transactionDocumentVersionsPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={transactionDocumentVersionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.PROCESS_STATUS" />
                                    ),
                                    accessor: "processStatus",
                                    Cell: (props) => {
                                        if (props.value === transactionUtils.PROCESS_STATUS_INVOICE) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.INVOICE");
                                        } else if (props.value === transactionUtils.PROCESS_STATUS_RECEIPT) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.RECEIPT");
                                        } else if (props.value === transactionUtils.PROCESS_STATUS_REMINDER) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.REMINDER");
                                        } else if (
                                            props.value === transactionUtils.PROCESS_STATUS_MANUAL_HANDLING_REMINDER
                                        ) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.MANUAL_HANDLING_REMINDER");
                                        } else if (
                                            props.value === transactionUtils.PROCESS_STATUS_LONG_TIME_SURVEILLANCE
                                        ) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.LONG_TERM_SURVEILLANCE");
                                        } else if (props.value === transactionUtils.PROCESS_STATUS_DEBT_COLLECTION) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.DEBT_COLLECTION");
                                        } else if (props.value === transactionUtils.PROCESS_STATUS_DELETED) {
                                            return TranslationCell("PAYMENT.PROCESS_STATUS.DELETED");
                                        }
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.PAYMENT_STATUS" />
                                    ),
                                    accessor: "paymentStatus",
                                    Cell: (props) => {
                                        return transactionUtils.getTransactionStatusCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.EXPIRATION_DATE" />
                                    ),
                                    accessor: "invoiceExpirationDate",
                                    Cell: (props) => {
                                        return DateCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.CREATED_BY" />,
                                    id: "createdBy",
                                    Cell: ({ row: { original: documentVersion } }) => {
                                        if (documentVersion.application) {
                                            return TextCell(documentVersion.application.name);
                                        } else if (documentVersion.createdBy) {
                                            return TextCell(
                                                `${documentVersion.createdBy.firstName} ${documentVersion.createdBy.surname}`
                                            );
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="PAYMENT.DOCUMENT_VERSIONS.LIST.HEADER.EXTERNAL_REF" />
                                    ),
                                    accessor: "externalRef",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    Cell: ({ row }) => {
                                        return (
                                            <IconButton
                                                disabled={isDownloadingPdf}
                                                onClick={() =>
                                                    downloadTransactionDocumentVersionPdf(
                                                        row.original.id,
                                                        `${transactionId}_version_${row.original.id}`
                                                    )
                                                }
                                            >
                                                <CloudDownloadOutlinedIcon />
                                            </IconButton>
                                        );
                                    },
                                },
                            ],
                            [downloadTransactionDocumentVersionPdf, isDownloadingPdf, transactionId]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...transactionActions, ...errorActions })(TransactionPageDocumentVersions);
