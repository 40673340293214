import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  formContent: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const schema = yup.object().shape({
  serviceId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
  prefixMinutes: yup
    .number()
    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
    .nullable()
    .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
  postfixMinutes: yup
    .number()
    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
    .nullable()
    .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
});

function ZesecKeyServiceModal(props) {
  const { isLoading, zesec } = useSelector((state) => state.integrations);
  const classes = useStyles();

  const { managingKey, managingKeyService } = zesec;

  let defaultValues;
  if (managingKeyService) {
    defaultValues = {
      serviceId: managingKeyService.service.id,
      prefixMinutes: managingKeyService.prefixSeconds
        ? managingKeyService.prefixSeconds / 60
        : null,
      postfixMinutes: managingKeyService.postfixSeconds
        ? managingKeyService.postfixSeconds / 60
        : null,
    };
  }

  const { register, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  if (!managingKey) return <></>;

  const onFormSubmit = (values) => {
    props.onSaveClicked(
      managingKey,
      managingKeyService,
      parseInt(values.serviceId, 10),
      values.prefixMinutes ? parseInt(values.prefixMinutes, 10) : null,
      values.postfixMinutes ? parseInt(values.postfixMinutes, 10) : null
    );
  };

  const { services } = props;

  return (
    <Form>
      <Modal size="lg" show={props.show} onHide={props.onCloseClicked}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {!managingKeyService && (
              <FormattedMessage
                id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.ADD"}
                values={{ keyName: managingKey.name }}
              />
            )}
            {managingKeyService && (
              <FormattedMessage
                id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.EDIT"}
                values={{ serviceName: managingKeyService.service.name }}
              />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.DESCRIPTION"} />
          <div className={classes.formContent}>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.SERVICE"} />
              </Form.Label>
              <Form.Control as="select" ref={register} name="serviceId">
                {services &&
                  services.map((x) => {
                    return (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.PREFIX_MINUTES"} />
              </Form.Label>
              <Form.Control
                type="text"
                ref={register}
                name="prefixMinutes"
                isInvalid={errors.prefixMinutes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prefixMinutes?.message}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                <FormattedMessage
                  id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.PREFIX_MINUTES_DESCRIPTION"}
                />
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <FormattedMessage id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.POSTFIX_MINUTES"} />
              <Form.Control
                type="number"
                ref={register}
                name="postfixMinutes"
                isInvalid={errors.postfixMinutes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.postfixMinutes?.message}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                <FormattedMessage
                  id={"INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.POSTFIX_MINUTES_DESCRIPTION"}
                />
              </Form.Text>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapCancelButton onClick={props.onCloseClicked} />
          <BootstrapSaveButton isLoading={isLoading} onClick={handleSubmit(onFormSubmit)} />
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default ZesecKeyServiceModal;
