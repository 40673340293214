import React, { useMemo } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import HeaderMenu from "./HeaderMenu";

export function HeaderMenuWrapper() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(uiService.config, "header.menu.self.root-arrow"),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay: objectPath.get(uiService.config, "aside.self.display") === false,
            headerLogo: uiService.getLogo(),
        };
    }, [uiService]);

    return (
        <>
            {/*begin::Header Menu Wrapper*/}
            <div
                className="header-menu-wrapper header-menu-wrapper-left"
                id="kt_header_menu_wrapper"
                style={{ alignSelf: "center" }}
            >
                {layoutProps.disabledAsideSelfDisplay && (
                    <>
                        {/*begin::Header Logo*/}
                        <div className="header-logo">
                            <Link to="/">
                                <img alt="logo" src={layoutProps.headerLogo} />
                            </Link>
                        </div>
                        {/*end::Header Logo*/}
                    </>
                )}
                {/*begin::Header Menu*/}

                {/* TODO: Fix similar but with company selection: import {HeaderMenu} from "./HeaderMenu"; <HeaderMenu layoutProps={layoutProps} /> */}
                <HeaderMenu layoutProps={layoutProps} />

                {/*end::Header Menu*/}
            </div>
            {/*Header Menu Wrapper*/}
        </>
    );
}
