import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

export default function SnackbarSuccess({ message, onSnackBarClosed }) {
    return (
        <Snackbar
            open={message ? true : false}
            onClose={onSnackBarClosed}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <MuiAlert elevation={6} variant="filled" severity="success">
                {message}
            </MuiAlert>
        </Snackbar>
    );
}
