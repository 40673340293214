import React from "react";
import { FormattedMessage } from "react-intl";
import ReportFilterDateInterval from "./filters/ReportFilterDateInterval";
import ReportFilterSwitch from "./filters/ReportFilterSwitch";
import moment from "moment";

const ReportFilterFormGroupsAttendeesCount = ({ report, onSaveHasBeenClicked, isDisabled }) => {
    const filterValues = report?.filterValues;
    const filterValuesObj = filterValues ? JSON.parse(filterValues) : {};
    let startTime = filterValuesObj.courseStartTime
        ? moment(filterValuesObj.courseStartTime).format("YYYY-MM-DD HH:mm")
        : null;
    let endTime = filterValuesObj.courseEndTime
        ? moment(filterValuesObj.courseEndTime).format("YYYY-MM-DD HH:mm")
        : null;
    let includeRemovedServices = filterValuesObj.includeRemovedServices;

    return (
        <>
            <ReportFilterDateInterval
                startTimeName="courseStartTime"
                endTimeName="courseEndTime"
                defaultStartTime={startTime}
                defaultEndTime={endTime}
                showWithTime={true}
                title={<FormattedMessage id="REPORT.GROUPS_ATTENDEES_COUNT.FILTER.PERIOD.TITLE" />}
                isStartTimeMandatory={true}
                isEndTimeMandatory={true}
                onSaveHasBeenClicked={onSaveHasBeenClicked}
                isDisabled={isDisabled}
            />
            <ReportFilterSwitch
                name="includeRemovedServices"
                defaultValue={includeRemovedServices}
                title={<FormattedMessage id="REPORT.GROUPS_ATTENDEES_COUNT.FILTER.INCLUDE_REMOVED_SERVICES.TITLE" />}
                isDisabled={isDisabled}
            />
        </>
    );
};

export default ReportFilterFormGroupsAttendeesCount;
