import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as serviceCategoriesActions } from "../../../../redux/servicecategories/serviceCategoriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../../components/SnackbarError";
import ServiceSectionHeader from "../ServiceSectionHeader";
import ServiceCommonDetailsForm from "../ServiceCommonDetailsForm";
import ProductBookingSettingsForm from "./ProductBookingSettingsForm";
import ServiceCheckoutDetailsForm from "../ServiceCheckoutDetailsForm";
import { SERVICE_COLOR_DEFAULT, PAYMENT_MODE_ON_ARRIVAL, PAYMENT_MODE_OPTIONAL, SERVICE_TYPE_PRODUCT } from "../utils";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
    color: yup.string(),
    minQuantity: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    maxQuantity: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    price: yup.number().when("paymentMode", (paymentMode) => {
        if (paymentMode === PAYMENT_MODE_ON_ARRIVAL || paymentMode === PAYMENT_MODE_OPTIONAL) {
            return yup
                .number()
                .nullable(true)
                .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
        } else {
            return yup
                .number()
                .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                .min(0, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 0 }} />);
        }
    }),
    vat: yup.object(),
    currency: yup.object(),
    paymentTemplate: yup.object().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    giftMode: yup.object(),
    proxyUsersMode: yup.object(),
    customerMessageMode: yup.object(),
    emailDetails: yup.string(),
});

function ServicePageProductAdd({
    getServiceOptions,
    addProduct,
    clearServiceState,
    clearDisplayError,
    clearErrorState,
    location,
    getServiceCopy,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { product, serviceOptions, isLoading, serviceToCopy } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const [redirect, setRedirect] = useState(false);
    const profileId = profile.id;

    const intl = useIntl();
    const queryParams = new URLSearchParams(location.search);
    const copyServiceId = queryParams.get("copyServiceId");

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, setValue } = formMethods;

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    useEffect(() => {
        getServiceOptions(profileId);
    }, [profileId, getServiceOptions]);

    useEffect(() => {
        if (copyServiceId) {
            getServiceCopy(copyServiceId, SERVICE_TYPE_PRODUCT);
        }
    }, [copyServiceId, getServiceCopy]);

    useEffect(() => {
        if (serviceToCopy) {
            setValue("name", intl.formatMessage({ id: "SERVICE.COPY.NEW_NAME" }, { serviceName: serviceToCopy.name }));
        }
    }, [serviceToCopy, setValue, intl]);

    const onFormSubmit = (values) => {
        var productData = {
            name: values.name,
            description: values.description,
            categoryId: values?.category?.id,
            color: values.color || SERVICE_COLOR_DEFAULT,
            minQuantity: values.minQuantity,
            maxQuantity: values.maxQuantity,
            price: values.price || 0,
            vatId: values.vat.id,
            currencyId: values.currency.id,
            paymentTemplateId: values?.paymentTemplate?.id,
            giftMode: values?.giftMode?.id,
            proxyUsersMode: values?.proxyUsersMode?.id,
            customerMessageMode: values?.customerMessageMode?.id,
            customerMessage: values.customerMessage,
            emailDetails: values.emailDetails,
        };

        if (serviceOptions.paymentTemplates.length === 0) {
            productData.paymentMode = values.paymentMode;
            productData.paymentMethods = values.paymentMethods;
            productData.billingTemplateId = values?.billingTemplate?.id;
        }

        addProduct(profileId, productData, () => {
            setRedirect(true);
        });
    };

    if (redirect && product) {
        return <Redirect to={`/services/${product.id}/product`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="SERVICE.PRODUCT.CREATE.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="SERVICE.TYPE.PRODUCT.DESCRIPTION" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body className="pt-0">
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceSectionHeader intlId={"SERVICE.DETAILS.HEADER"} />
                            <ServiceCommonDetailsForm isAddingService={true} service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.PRODUCT.BOOKING_SETTINGS.TITLE"} />
                            <ProductBookingSettingsForm service={serviceToCopy} />

                            <ServiceSectionHeader intlId={"SERVICE.PAYMENT.HEADER"} />

                            {serviceOptions && (
                                <ServiceCheckoutDetailsForm
                                    serviceOptions={serviceOptions}
                                    allowAddonsMode={false}
                                    service={serviceToCopy}
                                />
                            )}

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="COMMON.ADD" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, {
    ...serviceActions,
    ...serviceCategoriesActions,
    ...errorActions,
})(ServicePageProductAdd);
