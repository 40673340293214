import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions as fortnoxActions } from "../../../../redux/integrations/fortnox/fortnoxRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../../components/SnackbarError";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    alertContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

function FortnoxCreatePage({ getFortnoxIntegrationAuth, clearFortnoxState, clearErrorState, clearDisplayError }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, authRedirectUrl } = useSelector((state) => state.fortnox);
    const { displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        return () => {
            clearFortnoxState();
            clearErrorState();
        };
    }, [clearFortnoxState, clearErrorState]);

    if (authRedirectUrl) {
        window.location.href = authRedirectUrl;
        return <></>;
    }

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.FORTNOX.CREATE.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.FORTNOX.CREATE.DESCRIPTION1" />
                    <a
                        href="https://www.fortnox.se/integrationer/integration/alf-software-ab/agendo-bokningssystem"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FormattedMessage id="INTEGRATION.FORTNOX.CREATE.DESCRIPTION2" />
                    </a>
                </Typography>
                <Alert severity="info" className={classes.alertContainer}>
                    <FormattedMessage id="INTEGRATION.FORTNOX.CREATE.ALERT_INFO" />
                </Alert>
                <BootstrapSaveButton
                    onClick={() => getFortnoxIntegrationAuth(profile.id)}
                    label={<FormattedMessage id="INTEGRATION.FORTNOX.CREATE.BUTTON_AUTH" />}
                    isLoading={isLoading}
                ></BootstrapSaveButton>
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, { ...fortnoxActions, ...errorActions })(FortnoxCreatePage);
