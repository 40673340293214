import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { actions as integrationActions } from "../../../../redux/integrations/integrationRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import {
    Paper,
    makeStyles,
    Typography,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import EditKeyServiceIcon from "@material-ui/icons/EditOutlined";
import DeleteKeyServiceIcon from "@material-ui/icons/DeleteOutlined";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import ZesecKeyServiceModal from "./ZesecKeyServiceModal";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    locationForm: {
        width: "75%",
    },
    keyChip: {
        marginLeft: theme.spacing(2),
    },
}));

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 40px 0px 40px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemStyled = withStyles({
    gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
})(ListItem);

const KeyAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const KeyAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        justifyContent: "space-between",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

const schema = yup.object().shape({
    location: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function ZesecDetailsPage(props) {
    const integrationId = props.match.params.id;
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, zesec } = useSelector((state) => state.integrations);
    const { listPagination: serviceListPagination } = useSelector((state) => state.services);
    const [keyServiceDeletion, setKeyServiceDeletion] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        props.getIntegrationZesec(integrationId);
        props.getServices(profile.id, 1, 9999);
    }, [props, integrationId, profile.id]);

    const fetchKeys = useCallback(
        ({ pageIndex, pageSize }) => {
            props.getZesecKeys(integrationId, pageIndex + 1, pageSize);
        },
        [props, integrationId]
    );

    const onFormSubmit = (values) => {
        props.updateZesecIntegration(zesec.integration, parseInt(values.location, 10));
    };

    const onAddKeyServiceClicked = useCallback(
        (event, key) => {
            event.stopPropagation();
            props.manageZesecKeyService(key);
        },
        [props]
    );

    const onKeyServiceEditClicked = useCallback(
        (key, keyService) => {
            props.manageZesecKeyService(key, keyService);
        },
        [props]
    );

    const onKeyServiceDeleteClicked = useCallback((keyService) => {
        setKeyServiceDeletion(keyService);
    }, []);

    const onAddKeyServiceModalCloseClicked = () => {
        props.cancelManageZesecKeyService();
    };

    const onKeyServiceModalSaveClicked = (
        key,
        keyService,
        serviceId,
        prefixMinutes,
        postfixMinutes
    ) => {
        if (!keyService) {
            props.createZesecKeyService(
                parseInt(integrationId, 10),
                key.zesecKeyId,
                serviceId,
                prefixMinutes,
                postfixMinutes
            );
        } else {
            props.updateZesecKeyService(
                parseInt(integrationId, 10),
                keyService,
                serviceId,
                prefixMinutes,
                postfixMinutes
            );
        }
    };

    const onDeleteDialogOpenChanged = () => {
        setKeyServiceDeletion(null);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            props.deleteZesecKeyService(keyServiceDeletion.id);
        }
        setKeyServiceDeletion(null);
    };

    const keysColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="INTEGRATION.ZESEC.DETAILS.KEY.LIST.HEADER" />,
                accessor: "id",
                width: "7%",
                Cell: ({ row }) => {
                    const key = row.original;
                    const serviceCount = key.keyServices.length;
                    return (
                        <KeyAccordionStyled elevation={0} variant={"elevation"}>
                            <KeyAccordionSummaryStyled
                                expandIcon={serviceCount > 0 ? <ExpandMoreIcon /> : null}
                            >
                                <div>{key.name}</div>

                                <div>
                                    {serviceCount > 0 && (
                                        <ChipSuccess
                                            label={
                                                <FormattedMessage
                                                    id={
                                                        serviceCount === 1
                                                            ? "INTEGRATION.ZESEC.DETAILS.KEY.SERVICE_COUNT_MULTIPLE"
                                                            : "INTEGRATION.ZESEC.DETAILS.KEY.SERVICE_COUNT_SINGLE"
                                                    }
                                                    values={{ serviceCount }}
                                                />
                                            }
                                            style={{ marginRight: "8px" }}
                                            size="small"
                                        />
                                    )}
                                    <IconButton
                                        size="small"
                                        onClick={(event) => onAddKeyServiceClicked(event, key)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </KeyAccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <ListStyled>
                                    {key.keyServices.map((x, i, items) => {
                                        return (
                                            <div
                                                key={key.id + "_" + x.service.id}
                                                style={{
                                                    marginBottom:
                                                        i === items.length - 1 ? "8px" : "0px",
                                                }}
                                            >
                                                <ListItemStyled>
                                                    <ListItemText
                                                        secondary={x.service.name}
                                                        secondaryTypographyProps={{
                                                            variant: "body1",
                                                        }}
                                                    ></ListItemText>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onKeyServiceDeleteClicked(x)}
                                                        style={{ marginRight: "8px" }}
                                                    >
                                                        <DeleteKeyServiceIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            onKeyServiceEditClicked(key, x)
                                                        }
                                                    >
                                                        <EditKeyServiceIcon />
                                                    </IconButton>
                                                </ListItemStyled>
                                                {i < items.length - 1 && <Divider />}
                                            </div>
                                        );
                                    })}
                                </ListStyled>
                            </AccordionDetailsStyled>
                        </KeyAccordionStyled>
                    );
                },
            },
        ],
        [onAddKeyServiceClicked, onKeyServiceEditClicked, onKeyServiceDeleteClicked]
    );

    const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(schema) });
    const { integration, locations } = zesec;

    const getSelectLocationUI = () => {
        return (
            <div>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.ZESEC.SELECT_LOCATION.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.ZESEC.SELECT_LOCATION.DESCRIPTION" />
                </Typography>
                <Form onSubmit={handleSubmit(onFormSubmit)} className={classes.locationForm}>
                    <Form.Group>
                        <Form.Control as="select" ref={register} name="location">
                            {locations &&
                                locations.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.location?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        <FormattedMessage id="INTEGRATION.ZESEC.SELECT_LOCATION.CONNECT" />
                    </Button>
                </Form>
            </div>
        );
    };

    return (
        <PageBackdrop isLoading={isLoading && integration && !integration.accountId ? true : false}>
            <Paper className={classes.content}>
                {integration && !integration.accountId && getSelectLocationUI()}
                {integration && integration.accountId && (
                    <div className={classes.locationForm}>
                        <Typography variant="h4" className={classes.header}>
                            <FormattedMessage
                                id="INTEGRATION.ZESEC.DETAILS.TITLE"
                                values={{ location: integration.accountName }}
                            />
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            <FormattedMessage id="INTEGRATION.ZESEC.DETAILS.DESCRIPTION" />
                        </Typography>
                        <MaterialReactTable
                            key={integrationId}
                            data={zesec.keysPagination.data}
                            fetchData={fetchKeys}
                            pageSize={10}
                            rowCount={zesec.keysPagination.totalRows}
                            isLoading={isLoading}
                            columns={keysColumns}
                            showHeader={true}
                            getCustomCellProps={() => ({
                                style: { paddingLeft: "0px", paddingRight: "8px" },
                            })}
                            className={classes.keysTable}
                        />
                        {zesec.managingKey && (
                            <ZesecKeyServiceModal
                                show={zesec.managingKey ? true : false}
                                onCloseClicked={onAddKeyServiceModalCloseClicked}
                                services={serviceListPagination.data}
                                onSaveClicked={onKeyServiceModalSaveClicked}
                            />
                        )}
                    </div>
                )}
                <YesNoDialog
                    title={
                        <FormattedMessage id="INTEGRATION.ZESEC.DETAILS.KEY.SERVICE.DELETE.TITLE" />
                    }
                    open={keyServiceDeletion ? true : false}
                    onOpenChanged={onDeleteDialogOpenChanged}
                    onActionClicked={onDeleteDialogActionClicked}
                />
            </Paper>
        </PageBackdrop>
    );
}

const mapDispatchToProps = {
    ...integrationActions,
    ...serviceActions,
};

export default connect(null, mapDispatchToProps)(ZesecDetailsPage);
