import { put, takeLatest, select } from "redux-saga/effects";
import {
    getMediaContainerItems,
    addResourceMediaContainerItem,
    addResourceGroupMediaContainerItem,
    patchMediaContainerItem,
    deleteMediaContainerItem,
} from "./mediaCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_MEDIA_CONTAINER_ITEMS_REQUEST: "GET_MEDIA_CONTAINER_ITEMS_REQUEST",
    GET_MEDIA_CONTAINER_ITEMS_SUCCESS: "GET_MEDIA_CONTAINER_ITEMS_SUCCESS",
    UPDATE_MEDIA_CONTAINER_ITEM_REQUEST: "UPDATE_MEDIA_CONTAINER_ITEM_REQUEST",
    UPDATE_MEDIA_CONTAINER_ITEM_SUCCESS: "UPDATE_MEDIA_CONTAINER_ITEM_SUCCESS",
    DELETE_MEDIA_CONTAINER_ITEM_REQUEST: "DELETE_MEDIA_CONTAINER_ITEM_REQUEST",
    DELETE_MEDIA_CONTAINER_ITEM_SUCCESS: "DELETE_MEDIA_CONTAINER_ITEM_SUCCESS",
    ADD_RESOURCE_MEDIA_CONTAINER_ITEM_REQUEST: "ADD_RESOURCE_MEDIA_CONTAINER_ITEM_REQUEST",
    ADD_RESOURCE_MEDIA_CONTAINER_ITEM_SUCCESS: "ADD_RESOURCE_MEDIA_CONTAINER_ITEM_SUCCESS",
    ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_REQUEST: "ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_REQUEST",
    ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_SUCCESS: "ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_SUCCESS",
    CLEAR_MEDIA_STATE: "CLEAR_MEDIA_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEDIA_CONTAINER_ITEMS_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionTypes.GET_MEDIA_CONTAINER_ITEMS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.UPDATE_MEDIA_CONTAINER_ITEM_REQUEST: {
            return {
                ...state,
                isUpdating: true,
            };
        }

        case actionTypes.UPDATE_MEDIA_CONTAINER_ITEM_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.DELETE_MEDIA_CONTAINER_ITEM_REQUEST: {
            return {
                ...state,
                isUpdating: true,
            };
        }

        case actionTypes.DELETE_MEDIA_CONTAINER_ITEM_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.ADD_RESOURCE_MEDIA_CONTAINER_ITEM_REQUEST: {
            return {
                ...state,
                isUpdating: true,
            };
        }

        case actionTypes.ADD_RESOURCE_MEDIA_CONTAINER_ITEM_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_REQUEST: {
            return {
                ...state,
                isUpdating: true,
            };
        }

        case actionTypes.ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_MEDIA_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getMediaContainerItems: (mediaContainerId) => ({
        type: actionTypes.GET_MEDIA_CONTAINER_ITEMS_REQUEST,
        payload: { mediaContainerId },
    }),

    updateMediaContainerItem: (
        mediaContainerItemId,
        mediaContainerId,
        isAvatarImage,
        isCoverImage,
        resourceId,
        resourceGroupId
    ) => ({
        type: actionTypes.UPDATE_MEDIA_CONTAINER_ITEM_REQUEST,
        payload: { mediaContainerItemId, mediaContainerId, isAvatarImage, isCoverImage, resourceId, resourceGroupId },
    }),

    deleteMediaContainerItem: (mediaContainerItemId, mediaContainerId) => ({
        type: actionTypes.DELETE_MEDIA_CONTAINER_ITEM_REQUEST,
        payload: { mediaContainerItemId, mediaContainerId },
    }),

    addResourceMediaContainerItem: (resourceId, fileArrayBuffer, fileName, callback) => ({
        type: actionTypes.ADD_RESOURCE_MEDIA_CONTAINER_ITEM_REQUEST,
        payload: { resourceId, fileArrayBuffer, fileName, callback },
    }),

    addResourceGroupMediaContainerItem: (resourceGroupId, fileArrayBuffer, fileName, callback) => ({
        type: actionTypes.ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_REQUEST,
        payload: { resourceGroupId, fileArrayBuffer, fileName, callback },
    }),

    clearMediaState: () => ({
        type: actionTypes.CLEAR_MEDIA_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_MEDIA_CONTAINER_ITEMS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getMediaContainerItems(payload.mediaContainerId);
            yield put({
                type: actionTypes.GET_MEDIA_CONTAINER_ITEMS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_MEDIA_CONTAINER_ITEM_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchMediaContainerItem(
                payload.mediaContainerItemId,
                payload.isAvatarImage,
                payload.isCoverImage
            );

            const state = yield select();
            if (payload.resourceId) {
                if (state.resources.resource && state.resources.resource.id === payload.resourceId) {
                    let avatarUrl = state.resources.resource.avatarUrl;
                    if (payload.isAvatarImage !== null) {
                        avatarUrl = payload.isAvatarImage ? response.url : null;
                    }
                    let coverImageUrl = state.resources.resource.coverImageUrl;
                    if (payload.isCoverImage !== null) {
                        coverImageUrl = payload.isCoverImage ? response.url : null;
                    }

                    state.resources = {
                        ...state.resources,
                        resource: {
                            ...state.resources.resource,
                            avatarUrl: avatarUrl,
                            coverImageUrl: coverImageUrl,
                        },
                    };
                }
            }

            yield put(actions.getMediaContainerItems(payload.mediaContainerId));

            yield put({
                type: actionTypes.UPDATE_MEDIA_CONTAINER_ITEM_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_MEDIA_CONTAINER_ITEM_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteMediaContainerItem(payload.mediaContainerItemId);

            yield put(actions.getMediaContainerItems(payload.mediaContainerId));

            yield put({
                type: actionTypes.DELETE_MEDIA_CONTAINER_ITEM_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_RESOURCE_MEDIA_CONTAINER_ITEM_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addResourceMediaContainerItem(
                payload.resourceId,
                payload.fileArrayBuffer,
                payload.fileName
            );

            const mediaContainerId = response.mediaContainer.id;
            yield put(actions.getMediaContainerItems(mediaContainerId));

            // Update resource state with created container id
            const state = yield select();
            if (state.resources.resource && state.resources.resource.id === payload.resourceId) {
                state.resources.resource.mediaContainerId = mediaContainerId;
            }

            yield put({
                type: actionTypes.ADD_RESOURCE_MEDIA_CONTAINER_ITEM_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addResourceGroupMediaContainerItem(
                payload.resourceGroupId,
                payload.fileArrayBuffer,
                payload.fileName
            );

            const mediaContainerId = response.mediaContainer.id;
            yield put(actions.getMediaContainerItems(mediaContainerId));

            // Update resource group state with created container id
            const state = yield select();
            if (state.resources.resourceGroup && state.resources.resourceGroup.id === payload.resourceGroupId) {
                state.resources.resourceGroup.mediaContainerId = mediaContainerId;
            }

            yield put({
                type: actionTypes.ADD_RESOURCE_GROUP_MEDIA_CONTAINER_ITEM_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
