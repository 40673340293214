import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const SUBSCRIPTIONS_URL = "v1/admin/profiles/:profileId/subscriptions";
export const SUBSCRIPTION_DETAILS_URL = "v1/admin/subscriptions/:id";
export const SUBSCRIPTION_CANCEL_URL = "v1/admin/subscriptions/:id/cancel";
export const SUBSCRIPTION_TRANSACTIONS_URL = "v1/admin/subscriptions/:id/transactions";

export function getSubscriptions(profileId, page, perPage, search, status, paymentType) {
    const url =
        SUBSCRIPTIONS_URL.replace(":profileId", profileId) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (paymentType ? "&paymentMethod=" + paymentType : "");
    return axios.get(getApiUrl() + url);
}

export function getSubscription(id) {
    return axios.get(getApiUrl() + SUBSCRIPTION_DETAILS_URL.replace(":id", id));
}

export function addSubscription(profileId, data) {
    return axios.post(getApiUrl() + SUBSCRIPTIONS_URL.replace(":profileId", profileId), data);
}

export function patchSubscription(id, originalSubscription, changedSubscription) {
    const url = SUBSCRIPTION_DETAILS_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalSubscription, changedSubscription), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function cancelSubscription(id, cancellationType, cancelLastAt) {
    return axios.post(getApiUrl() + SUBSCRIPTION_CANCEL_URL.replace(":id", id), { cancellationType, cancelLastAt });
}

export function getSubscriptionTransactions(id, page, perPage, search, status, paymentType, paymentStatus) {
    const url =
        SUBSCRIPTION_TRANSACTIONS_URL.replace(":id", id) +
        "?page=" +
        page +
        "&perPage=" +
        perPage +
        (search ? "&search=" + search : "") +
        (status ? "&status=" + status : "") +
        (paymentType ? "&paymentType=" + paymentType : "") +
        (paymentStatus ? "&paymentStatus=" + paymentStatus : "");
    return axios.get(getApiUrl() + url);
}
