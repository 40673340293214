import React, { useEffect, useMemo, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Switch, Typography, Paper } from "@material-ui/core";
import { Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { TextCell, DateTimeCell, LinkCell } from "../../../components/tables/MaterialTableCells";
import ListActionsButton from "../../../components/buttons/ListActionsButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import MoveMemberIcon from "@material-ui/icons/ThumbUpOutlined";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import WaitingListMemberMoveModal from "../../services/courses/WaitingListMemberMoveModal";
import moment from "moment";

export function DrawerCourseOccasionSettingsTab({
    getEntryWaitingListMembers,
    deleteWaitingListMember,
    maxAttendees,
    setMaxAttendees,
    isWaitingListEnabled,
    setIsWaitingListEnabled,
    parentClasses,
}) {
    const { entry, waitingListMembersPagination } = useSelector((state) => state.entries);

    const [waitingListMemberToMove, setWaitingListMemberToMove] = useState(null);
    const [waitingListMemberToDelete, setWaitingListMemberToDelete] = useState(null);

    useEffect(() => {
        if (entry) {
            if (maxAttendees == null) {
                setMaxAttendees(entry.maxAttendees);
            }

            if (isWaitingListEnabled == null) {
                setIsWaitingListEnabled(entry.isWaitingListEnabled);
            }
        }
    }, [entry, maxAttendees, setMaxAttendees, isWaitingListEnabled, setIsWaitingListEnabled]);

    const fetchWaitingListMembers = useCallback(
        ({ pageIndex, pageSize }) => {
            getEntryWaitingListMembers(entry.id, pageIndex + 1, pageSize);
        },
        [getEntryWaitingListMembers, entry.id]
    );

    const sectionPaperProps = { elevation: 0, variant: "outlined" };

    return (
        <div className={parentClasses.tabContainer}>
            <div>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>
                        <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.MAX_ATTENDEES" />
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control col-4"
                        value={maxAttendees == null ? 0 : maxAttendees}
                        onChange={(e) => setMaxAttendees(e.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="SERVICE.COURSE.BOOKING_SETTINGS.WAITING_LIST" />
                    </Form.Label>
                    <Switch
                        checked={isWaitingListEnabled ? true : false}
                        onChange={(e, value) => setIsWaitingListEnabled(value)}
                    />
                </Form.Group>
            </div>
            <div className={parentClasses.sectionContainer}>
                <Typography className={parentClasses.sectionLabel} variant="subtitle1">
                    <FormattedMessage id="CALENDAR.COURSE_OCCASION.WAITING_LIST" />
                </Typography>
                <Paper {...sectionPaperProps}>
                    <MaterialReactTable
                        key={entry.id}
                        data={useMemo(() => waitingListMembersPagination.data, [waitingListMembersPagination.data])}
                        fetchData={fetchWaitingListMembers}
                        pageSize={20}
                        rowCount={waitingListMembersPagination.totalRows}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.NAME" />,
                                    accessor: "name",
                                    Cell: ({ row }) => {
                                        const waitingListMember = row.original;
                                        if (!waitingListMember.customerId) {
                                            return TextCell(waitingListMember.name);
                                        }

                                        return LinkCell(
                                            `/customers/${waitingListMember.customerId}`,
                                            waitingListMember.name
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.EMAIL" />,
                                    accessor: "email",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.COURSE.WAITING_LIST.LIST.HEADER.CREATED_AT" />
                                    ),
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "actions",
                                    textAlign: "center",
                                    Cell: ({ row }) => {
                                        return (
                                            <ListActionsButton
                                                menuItems={[
                                                    {
                                                        icon: <MoveMemberIcon fontSize="small" />,
                                                        label: (
                                                            <FormattedMessage id="WAITING_LIST.MEMBER.MOVE.BUTTON.TO_COURSE_OCCASION" />
                                                        ),
                                                        onClick: () => setWaitingListMemberToMove(row.original),
                                                    },
                                                    {
                                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                                        onClick: () => setWaitingListMemberToDelete(row.original),
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                        emptyMessage={<FormattedMessage id="CALENDAR.COURSE_OCCASION.WAITING_LIST.NO_RESULT" />}
                    />
                </Paper>
            </div>

            {waitingListMemberToMove && (
                <WaitingListMemberMoveModal
                    waitingListMember={waitingListMemberToMove}
                    moveToName={`${entry.service.name} - ${moment(entry.startTime).format("YYYY-MM-DD HH:mm")}`}
                    show={waitingListMemberToMove ? true : false}
                    onCloseClicked={() => setWaitingListMemberToMove(null)}
                    onMoved={() => setWaitingListMemberToMove(null)}
                />
            )}

            <YesNoDialog
                title={<FormattedMessage id="WAITING_LIST.MEMBER.DELETE.TEXT" />}
                open={waitingListMemberToDelete ? true : false}
                onOpenChanged={() => setWaitingListMemberToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteWaitingListMember(waitingListMemberToDelete.id);
                    }
                    setWaitingListMemberToDelete(null);
                }}
            />
        </div>
    );
}

export default injectIntl(
    connect(null, { ...bookingManagerActions, ...serviceActions })(DrawerCourseOccasionSettingsTab)
);
