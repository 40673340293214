import React, { useEffect, useState } from "react";
import { makeStyles, Avatar, Switch } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { SERVICE_TYPE_APPOINTMENT } from "../services/utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    formContent: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    listItemAvatarContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
}));

const schema = yup.object().shape({
    serviceId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    prefixMinutes: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    postfixMinutes: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    messageToCustomer: yup.string().max(160, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 160 }} />),
});

function getServiceResourceOptions(serviceResourcePagination) {
    let options = [];
    if (!serviceResourcePagination?.data?.length) return options;
    serviceResourcePagination.data.forEach((serviceResource) => {
        options.push(getServiceResourceOption(serviceResource));
    });
    return options;
}

function getServiceResourceOption(serviceResource) {
    return {
        id: serviceResource.resource.id,
        name: serviceResource.resource.name,
        avatarUrl: serviceResource.resource.avatarUrl,
    };
}

function AccessDeviceServiceModal({
    show,
    managingAccessDevice,
    managingAccessDeviceService,
    services,
    getServiceResources,
    serviceResourcesPagination,
    showMessageToCustomer,
    isLoading,
    onSaveClicked,
    onCloseClicked,
}) {
    const classes = useStyles();
    const intl = useIntl();
    const [selectedService, setSelectedService] = useState(null);
    const [selectedResources, setSelectedResources] = useState([]);
    const [isAllResources, setIsAllResources] = useState(true);

    let defaultValues;
    if (managingAccessDeviceService) {
        defaultValues = {
            serviceId: managingAccessDeviceService.service.id,
            prefixMinutes: managingAccessDeviceService.prefixSeconds
                ? managingAccessDeviceService.prefixSeconds / 60
                : null,
            postfixMinutes: managingAccessDeviceService.postfixSeconds
                ? managingAccessDeviceService.postfixSeconds / 60
                : null,
            messageToCustomer: managingAccessDeviceService.messageToCustomer,
        };
    }

    const { register, handleSubmit, errors, control, setValue } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const currentService =
        selectedService || managingAccessDeviceService?.service || (services.length > 0 ? services[0] : null);

    useEffect(() => {
        if (currentService?.type === SERVICE_TYPE_APPOINTMENT) {
            getServiceResources(currentService.id);
        }
    }, [currentService, getServiceResources]);

    useEffect(() => {
        if (managingAccessDeviceService) {
            setValue("isAllResources", managingAccessDeviceService.isAllResources);
            setIsAllResources(managingAccessDeviceService.isAllResources);

            if (managingAccessDeviceService.deviceServiceResources?.length > 0) {
                let resources = [];
                managingAccessDeviceService.deviceServiceResources.forEach((deviceServiceResource) => {
                    resources.push({
                        id: deviceServiceResource.resource.id,
                        name: deviceServiceResource.resource.name,
                        avatarUrl: deviceServiceResource.resource.avatarUrl,
                    });
                });
                setSelectedResources(resources);
            }
        }
    }, [managingAccessDeviceService, setValue]);

    if (!managingAccessDevice) return <></>;

    const onServiceChanged = (e) => {
        const serviceId = parseInt(e.target.value, 10);
        const service = services.find((x) => x.id === serviceId);
        setSelectedService(service);
    };

    const onResourceSearch = (search) => {
        getServiceResources(currentService.id, search);
    };

    const onResourceChanged = (resources) => {
        setSelectedResources(resources);
    };

    const onFormSubmit = (values) => {
        let data = {
            serviceId: parseInt(values.serviceId, 10),
            isAllResources: values.isAllResources,
            resourceIds: [],
            messageToCustomer: values.messageToCustomer,
        };

        if (!values.isAllResources && selectedResources?.length > 0) {
            selectedResources.forEach((resource) => {
                data.resourceIds.push(resource.id);
            });
        }

        if (values.prefixMinutes) {
            data.prefixSeconds = parseInt(values.prefixMinutes, 10) * 60;
        }

        if (values.postfixMinutes) {
            data.postfixSeconds = parseInt(values.postfixMinutes, 10) * 60;
        }

        onSaveClicked(managingAccessDevice, managingAccessDeviceService, data);
    };

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {!managingAccessDeviceService && (
                            <FormattedMessage
                                id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.ADD"}
                                values={{ name: managingAccessDevice.name }}
                            />
                        )}
                        {managingAccessDeviceService && (
                            <FormattedMessage
                                id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.EDIT"}
                                values={{ serviceName: managingAccessDeviceService.service.name }}
                            />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.DESCRIPTION"} />
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.SERVICE"} />
                            </Form.Label>
                            <Form.Control as="select" ref={register} name="serviceId" onChange={onServiceChanged}>
                                {services &&
                                    services.map((x) => {
                                        return (
                                            <option key={x.id} value={x.id}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                            </Form.Control>
                        </Form.Group>
                        {currentService?.type === SERVICE_TYPE_APPOINTMENT && (
                            <>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.ALL_RESOURCES" />
                                    </Form.Label>
                                    <Controller
                                        name="isAllResources"
                                        control={control}
                                        defaultValue={true}
                                        render={({ value, onChange }) => {
                                            return (
                                                <Switch
                                                    checked={value}
                                                    onChange={(event, value) => {
                                                        setIsAllResources(value);
                                                        onChange(value);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Form.Group>
                                {!isAllResources && (
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage
                                                id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.SELECT_RESOURCE"}
                                            />
                                        </Form.Label>
                                        <AsyncTypeahead
                                            id="typeahead-resources"
                                            labelKey={"name"}
                                            multiple
                                            minLength={0}
                                            clearButton={true}
                                            isLoading={false}
                                            onChange={onResourceChanged}
                                            onSearch={onResourceSearch}
                                            onInputChange={(input) => {
                                                if (!input) {
                                                    onResourceSearch(input);
                                                }
                                            }}
                                            useCache={false}
                                            options={getServiceResourceOptions(serviceResourcesPagination)}
                                            placeholder={intl.formatMessage({
                                                id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                            })}
                                            emptyLabel={intl.formatMessage({
                                                id: "BOOKING.ADD.RESOURCE.SEARCH_EMPTY",
                                            })}
                                            renderMenuItemChildren={(option, props) => (
                                                <div className={classes.listItemAvatarContainer}>
                                                    <Avatar
                                                        alt={option.name}
                                                        src={option.avatarUrl}
                                                        className={classes.listItemAvatar}
                                                    />
                                                    <span>{option.name}</span>
                                                </div>
                                            )}
                                            selected={selectedResources}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        )}
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage
                                    id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.PREFIX_MINUTES"}
                                />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                ref={register}
                                name="prefixMinutes"
                                isInvalid={errors.prefixMinutes}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.prefixMinutes?.message}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage
                                    id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.PREFIX_MINUTES_DESCRIPTION"}
                                />
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <FormattedMessage id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.POSTFIX_MINUTES"} />
                            <Form.Control
                                type="number"
                                ref={register}
                                name="postfixMinutes"
                                isInvalid={errors.postfixMinutes}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.postfixMinutes?.message}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage
                                    id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.POSTFIX_MINUTES_DESCRIPTION"}
                                />
                            </Form.Text>
                        </Form.Group>
                        {showMessageToCustomer && (
                            <Form.Group>
                                <FormattedMessage
                                    id={"INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.MESSAGE_TO_CUSTOMER"}
                                />
                                <Form.Control
                                    type="text"
                                    ref={register}
                                    name="messageToCustomer"
                                    isInvalid={errors.messageToCustomer}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.messageToCustomer?.message}
                                </Form.Control.Feedback>

                                <Form.Text className="text-muted">
                                    <FormattedMessage
                                        id="INTEGRATION.ACCESS.DETAILS.ACCESS_DEVICE.SERVICE.MESSAGE_TO_CUSTOMER_DESCRIPTION"
                                        values={{
                                            p: (...chunks) => <p>{chunks}</p>,
                                            strong: (...chunks) => <strong>{chunks}</strong>,
                                            br: <br />,
                                        }}
                                    />
                                </Form.Text>
                            </Form.Group>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton isLoading={isLoading} onClick={handleSubmit(onFormSubmit)} />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default AccessDeviceServiceModal;
