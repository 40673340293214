import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./auth/authRedux";
import * as resources from "./resources/resourceRedux";
import * as customers from "./customers/customerRedux";
import * as entries from "./entries/entriesRedux";
import * as transactions from "./transactions/transactionRedux";
import * as subscriptions from "./subscriptions/subscriptionRedux";
import * as paymentMethods from "./paymentmethods/paymentMethodRedux";
import * as payouts from "./payouts/payoutsRedux";
import * as promotionCodes from "./promotioncodes/promotionCodesRedux";
import * as campaigns from "./campaigns/campaignRedux";
import * as reports from "./reports/reportRedux";
import * as integrations from "./integrations/integrationRedux";
import * as parakey from "./integrations/parakey/parakeyRedux";
import * as accessy from "./integrations/accessy/accessyRedux";
import * as dormakaba from "./integrations/dormakaba/dormakabaRedux";
import * as scrive from "./integrations/scrive/scriveRedux";
import * as fortnox from "./integrations/fortnox/fortnoxRedux";
import * as bankid from "./integrations/bankid/bankIDRedux";
import * as services from "./services/serviceRedux";
import * as serviceCategories from "./servicecategories/serviceCategoriesRedux";
import * as bookings from "./bookings/bookingRedux";
import * as bookingManager from "./bookings/bookingManagerRedux";
import * as stats from "./stats/statsRedux";
import * as profiles from "./profiles/profilesRedux";
import * as paymentTemplates from "./paymenttemplates/paymentTemplateRedux";
import * as billingTemplates from "./billingtemplates/billingTemplateRedux";
import * as pricingTemplates from "./pricingtemplates/pricingTemplateRedux";
import * as cancellationTemplates from "./cancellationtemplates/cancellationTemplateRedux";
import * as reminderTemplates from "./remindertemplates/reminderTemplateRedux";
import * as media from "./media/mediaRedux";
import * as customerOffers from "./customeroffers/customerOffersRedux";
import * as errors from "./errors/errorsRedux";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    resources: resources.reducer,
    customers: customers.reducer,
    entries: entries.reducer,
    bookingManager: bookingManager.reducer,
    transactions: transactions.reducer,
    subscriptions: subscriptions.reducer,
    paymentMethods: paymentMethods.reducer,
    payouts: payouts.reducer,
    promotionCodes: promotionCodes.reducer,
    campaigns: campaigns.reducer,
    reports: reports.reducer,
    integrations: integrations.reducer,
    parakey: parakey.reducer,
    accessy: accessy.reducer,
    dormakaba: dormakaba.reducer,
    scrive: scrive.reducer,
    fortnox: fortnox.reducer,
    bankid: bankid.reducer,
    services: services.reducer,
    serviceCategories: serviceCategories.reducer,
    bookings: bookings.reducer,
    stats: stats.reducer,
    profiles: profiles.reducer,
    paymentTemplates: paymentTemplates.reducer,
    billingTemplates: billingTemplates.reducer,
    pricingTemplates: pricingTemplates.reducer,
    cancellationTemplates: cancellationTemplates.reducer,
    reminderTemplates: reminderTemplates.reducer,
    media: media.reducer,
    customerOffers: customerOffers.reducer,
    errors: errors.reducer,
});

export function* rootSaga() {
    yield all([
        auth.saga(),
        resources.saga(),
        customers.saga(),
        entries.saga(),
        bookingManager.saga(),
        transactions.saga(),
        subscriptions.saga(),
        paymentMethods.saga(),
        payouts.saga(),
        promotionCodes.saga(),
        campaigns.saga(),
        reports.saga(),
        integrations.saga(),
        parakey.saga(),
        accessy.saga(),
        dormakaba.saga(),
        scrive.saga(),
        fortnox.saga(),
        bankid.saga(),
        services.saga(),
        serviceCategories.saga(),
        bookings.saga(),
        stats.saga(),
        profiles.saga(),
        paymentTemplates.saga(),
        billingTemplates.saga(),
        pricingTemplates.saga(),
        cancellationTemplates.saga(),
        reminderTemplates.saga(),
        media.saga(),
        customerOffers.saga(),
        errors.saga(),
    ]);
}
