import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { actions as dormakabaActions } from "../../../../redux/integrations/dormakaba/dormakabaRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import {
    Paper,
    makeStyles,
    Typography,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import EditKeyServiceIcon from "@material-ui/icons/EditOutlined";
import DeleteKeyServiceIcon from "@material-ui/icons/DeleteOutlined";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import DormakabaComponentServiceModal from "./DormakabaComponentServiceModal";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import SnackbarError from "../../../components/SnackbarError";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    locationForm: {
        width: "75%",
    },
    keyChip: {
        marginLeft: theme.spacing(2),
    },
}));

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 40px 0px 40px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemStyled = withStyles({
    gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
})(ListItem);

const KeyAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const KeyAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        justifyContent: "space-between",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

function DormakabaDetailsPage(props) {
    const integrationId = props.match.params.id;
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, componentsPagination, managingComponent } = useSelector((state) => state.dormakaba);
    const { listPagination: serviceListPagination } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);

    const [componentServiceDeletion, setComponentServiceDeletion] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        props.getDormakabaIntegration(integrationId);
        props.getServices(profile.id, 1, 9999);
    }, [props, integrationId, profile.id]);

    const fetchKeys = useCallback(
        ({ pageIndex, pageSize }) => {
            props.getDormakabaComponents(integrationId, pageIndex + 1, pageSize);
        },
        [props, integrationId]
    );

    const onAddComponentServiceClicked = useCallback(
        (event, component) => {
            event.stopPropagation();
            props.manageDormakabaComponentService(component);
        },
        [props]
    );

    const onComponentServiceEditClicked = useCallback(
        (component, componentService) => {
            props.manageDormakabaComponentService(component, componentService);
        },
        [props]
    );

    const onComponentServiceDeleteClicked = useCallback((keyService) => {
        setComponentServiceDeletion(keyService);
    }, []);

    const onAddComponentServiceModalCloseClicked = () => {
        props.cancelManageDormakabaComponentService();
    };

    const onComponentServiceModalSaveClicked = (
        managingComponent,
        managingComponentService,
        serviceId,
        prefixMinutes,
        postfixMinutes,
        messageToCustomer
    ) => {
        if (!managingComponentService) {
            props.createDormakabaComponentService(
                parseInt(integrationId, 10),
                managingComponent.dormakabaComponentId,
                serviceId,
                prefixMinutes,
                postfixMinutes,
                messageToCustomer
            );
        } else {
            props.updateDormakabaComponentService(
                parseInt(integrationId, 10),
                managingComponentService,
                serviceId,
                prefixMinutes,
                postfixMinutes,
                messageToCustomer
            );
        }
    };

    const onDeleteDialogOpenChanged = () => {
        setComponentServiceDeletion(null);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            props.deleteDormakabaComponentService(parseInt(integrationId, 10), componentServiceDeletion.id);
        }
        setComponentServiceDeletion(null);
    };

    const keysColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="INTEGRATION.DORMAKABA.DETAILS.COMPONENT.LIST.HEADER" />,
                accessor: "id",
                width: "7%",
                Cell: ({ row }) => {
                    const component = row.original;
                    const serviceCount = component.services.length;
                    return (
                        <KeyAccordionStyled elevation={0} variant={"elevation"}>
                            <KeyAccordionSummaryStyled expandIcon={serviceCount > 0 ? <ExpandMoreIcon /> : null}>
                                <div>{component.name}</div>

                                <div>
                                    {serviceCount > 0 && (
                                        <ChipSuccess
                                            label={
                                                <FormattedMessage
                                                    id={
                                                        serviceCount === 1
                                                            ? "INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE_COUNT_SINGLE"
                                                            : "INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE_COUNT_MULTIPLE"
                                                    }
                                                    values={{ serviceCount }}
                                                />
                                            }
                                            style={{ marginRight: "8px" }}
                                            size="small"
                                        />
                                    )}
                                    <IconButton
                                        size="small"
                                        onClick={(event) => onAddComponentServiceClicked(event, component)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </KeyAccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <ListStyled>
                                    {component.services.map((x, i, items) => {
                                        return (
                                            <div
                                                key={component.id + "_" + x.service.id}
                                                style={{
                                                    marginBottom: i === items.length - 1 ? "8px" : "0px",
                                                }}
                                            >
                                                <ListItemStyled>
                                                    <ListItemText
                                                        secondary={x.service.name}
                                                        secondaryTypographyProps={{
                                                            variant: "body1",
                                                        }}
                                                    ></ListItemText>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onComponentServiceDeleteClicked(x)}
                                                        style={{ marginRight: "8px" }}
                                                    >
                                                        <DeleteKeyServiceIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onComponentServiceEditClicked(component, x)}
                                                    >
                                                        <EditKeyServiceIcon />
                                                    </IconButton>
                                                </ListItemStyled>
                                                {i < items.length - 1 && <Divider />}
                                            </div>
                                        );
                                    })}
                                </ListStyled>
                            </AccordionDetailsStyled>
                        </KeyAccordionStyled>
                    );
                },
            },
        ],
        [onAddComponentServiceClicked, onComponentServiceEditClicked, onComponentServiceDeleteClicked]
    );

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => props.clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                {integration && (
                    <div className={classes.locationForm}>
                        <Typography variant="h4" className={classes.header}>
                            <FormattedMessage
                                id="INTEGRATION.DORMAKABA.DETAILS.TITLE"
                                values={{ location: integration.accountName }}
                            />
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            <FormattedMessage id="INTEGRATION.DORMAKABA.DETAILS.DESCRIPTION" />
                        </Typography>
                        <MaterialReactTable
                            key={integrationId}
                            data={componentsPagination.data}
                            fetchData={fetchKeys}
                            pageSize={10}
                            rowCount={componentsPagination.totalRows}
                            isLoading={isLoading}
                            columns={keysColumns}
                            showHeader={true}
                            getCustomCellProps={() => ({
                                style: { paddingLeft: "0px", paddingRight: "8px" },
                            })}
                            className={classes.keysTable}
                        />
                        {managingComponent && (
                            <DormakabaComponentServiceModal
                                show={managingComponent ? true : false}
                                onCloseClicked={onAddComponentServiceModalCloseClicked}
                                services={serviceListPagination.data}
                                onSaveClicked={onComponentServiceModalSaveClicked}
                            />
                        )}
                    </div>
                )}
                <YesNoDialog
                    title={<FormattedMessage id="INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.DELETE.TITLE" />}
                    open={componentServiceDeletion ? true : false}
                    onOpenChanged={onDeleteDialogOpenChanged}
                    onActionClicked={onDeleteDialogActionClicked}
                />
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...dormakabaActions,
    ...serviceActions,
    ...errorActions,
})(DormakabaDetailsPage);
