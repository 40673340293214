import React from "react";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { convertApiColorToHexColor } from "../../../utils/ColorUtils";
import { SERVICE_TYPE_MEMBERSHIP, SERVICE_TYPE_VOUCHER } from "../../services/utils";
import moment from "moment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    gridItem: {
        width: "100%",
    },
    bigCard: {
        marginBottom: "10px",
        cursor: "pointer",
    },
    bigCardTopContainer: {
        width: "100%",
        height: "125px",
        borderRadius: "0.42rem 0.42rem 0rem 0rem",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    timeContainer: {
        backgroundColor: "white",
        padding: theme.spacing(1.25),
        textAlign: "center",
        borderRadius: "4px",
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(2),
        },
    },
    timeContainerBetweenIconContainer: {
        backgroundColor: "white",
        padding: theme.spacing(1),
        borderRadius: "50%",
        border: "1px solid #EEF0F8",
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(1.5),
        },
    },
    timeContainerBetweenIcon: {
        fontSize: "18px",
        color: "#3F4254",
        [theme.breakpoints.up("lg")]: {
            fontSize: "24px",
        },
    },
    timeText: {
        fontSize: "14px",
    },
    bigCardInfoContainer: {
        display: "flex",
        padding: theme.spacing(1),
        justifyContent: "center",
        height: "60px",
        flexDirection: "column",
    },
    bigCardInfoContainerTitle: {
        overflow: "hidden",
        wordWrap: "break-word",
        textOverflow: "ellipsis",
    },
    bigCardInfoContainerSubtitle: {
        fontStyle: "italic",
    },
    cardBody: {
        padding: "0px",
    },
}));

function BookingPageDetails({ getEntry, showDrawerEntry }) {
    const { booking } = useSelector((state) => state.bookings);
    const classes = useStyles();
    const history = useHistory();

    const getBookingGridItems = () => {
        let gridItems = [];
        if (!booking) return gridItems;

        if (booking.entries?.length > 0) {
            booking.entries.forEach((entry) => {
                const startTime = moment(entry.startTime);
                const endTime = moment(entry.endTime);
                var resource = entry?.resources?.length === 1 ? entry.resources[0] : null;
                let gridItem = (
                    <Grid item xl={6} lg={12} key={entry.id} className={classes.gridItem}>
                        <Card className={classes.bigCard} onClick={() => onBookingEntryShowClicked(entry)}>
                            <Card.Body className={classes.cardBody}>
                                <div
                                    className={classes.bigCardTopContainer}
                                    style={{ backgroundColor: convertApiColorToHexColor(entry.color) }}
                                >
                                    <div className={classes.timeContainer}>
                                        <Typography className={classes.timeText} variant="subtitle1">
                                            {startTime.format("YYYY-MM-DD")}
                                        </Typography>
                                        <Typography className={classes.timeText} variant="subtitle1">
                                            {startTime.format("HH:mm")}
                                        </Typography>
                                    </div>

                                    <div className={classes.timeContainerBetweenIconContainer}>
                                        <ArrowForwardIcon className={classes.timeContainerBetweenIcon} />
                                    </div>

                                    <div className={classes.timeContainer}>
                                        <Typography className={classes.timeText} variant="subtitle1">
                                            {endTime.format("YYYY-MM-DD")}
                                        </Typography>
                                        <Typography className={classes.timeText} variant="subtitle1">
                                            {endTime.format("HH:mm")}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.bigCardInfoContainer}>
                                    <Typography variant="subtitle1" className={classes.bigCardInfoContainerTitle}>
                                        {entry.service.name}
                                    </Typography>
                                    {resource && (
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.bigCardInfoContainerSubtitle}
                                        >
                                            {resource.name}
                                        </Typography>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Grid>
                );
                gridItems.push(gridItem);
            });
        }

        if (booking.customerOffer) {
            let gridItem = (
                <Grid item xl={4} lg={12} key={booking.customerOffer.id}>
                    <Card
                        className={classes.bigCard}
                        onClick={() => onBookingCustomerOfferShowClicked(booking.customerOffer)}
                    >
                        <Card.Body className={classes.cardBody}>
                            <div
                                className={classes.bigCardTopContainer}
                                style={{
                                    backgroundColor: convertApiColorToHexColor(booking.customerOffer.service.color),
                                }}
                            ></div>
                            <div className={classes.bigCardInfoContainer}>
                                <Typography variant="subtitle1" className={classes.bigCardInfoContainerTitle}>
                                    {booking.customerOffer.service.name}
                                </Typography>
                            </div>
                        </Card.Body>
                    </Card>
                </Grid>
            );
            gridItems.push(gridItem);
        }

        if (booking.addons?.length > 0) {
            booking.addons.forEach((addon) => {
                let gridItem = (
                    <Grid item xl={4} lg={12} key={addon.id}>
                        <Card className={classes.bigCard}>
                            <Card.Body className={classes.cardBody}>
                                <div
                                    className={classes.bigCardTopContainer}
                                    style={{ backgroundColor: convertApiColorToHexColor(addon.service.color) }}
                                ></div>
                                <div className={classes.bigCardInfoContainer}>
                                    <Typography variant="subtitle1" className={classes.bigCardInfoContainerTitle}>
                                        {`${addon.service.name} (x${addon.quantity || 1})`}
                                    </Typography>
                                    {addon.price > 0 && (
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.bigCardInfoContainerSubtitle}
                                        >
                                            {`${addon.price} ${addon.currency}`}
                                        </Typography>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Grid>
                );
                gridItems.push(gridItem);
            });
        }

        return gridItems;
    };

    const onBookingEntryShowClicked = (entry) => {
        getEntry(entry);
        showDrawerEntry(entry);
    };

    const onBookingCustomerOfferShowClicked = (customerOffer) => {
        if (customerOffer.service.type === SERVICE_TYPE_MEMBERSHIP) {
            history.push(`/memberships/${customerOffer.id}/details`);
        } else if (customerOffer.service.type === SERVICE_TYPE_VOUCHER) {
            history.push(`/vouchers/${customerOffer.id}/details`);
        }
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="BOOKING.DETAILS.TITLE" />
                        </h3>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Grid container justifyContent="flex-start" spacing={2}>
                        {getBookingGridItems()}
                    </Grid>
                </Card.Body>
            </div>
        </Card>
    );
}

const mapDispatchToProps = {
    ...bookingActions,
    ...bookingManagerActions,
};

export default connect(null, mapDispatchToProps)(BookingPageDetails);
