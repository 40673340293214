import React, { useMemo, useCallback } from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell, AvatarCell, LinkCell, ChipCell } from "../../../components/tables/MaterialTableCells";
import { Link } from "react-router-dom";
import * as bookingUtils from "../../bookings/bookings/utils";

function VoucherPageBookings({ getVoucherBookings }) {
    const { voucher, voucherBookingsPagination, isLoading } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);
    const serviceId = voucher?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (serviceId) {
                getVoucherBookings(serviceId, pageIndex + 1, pageSize);
            }
        },
        [serviceId, getVoucherBookings]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.VOUCHER.BOOKINGS.TITLE" />
                        </h3>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={serviceId}
                        data={useMemo(() => voucherBookingsPagination.data, [voucherBookingsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={voucherBookingsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/bookings/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.CUSTOMER" />,
                                    accessor: "customer",
                                    maxWidth: 200,
                                    Cell: ({ row }) => {
                                        return (
                                            <Link to={`/customers/${row.original.customer.id}`}>
                                                {AvatarCell(
                                                    row.original.customer.avatarUrl,
                                                    `${row.original.customer.firstName} ${row.original.customer.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.PRICE" />,
                                    accessor: "price",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.VOUCHER.BOOKINGS.LIST.HEADER.CUSTOMER_OFFER_ID" />
                                    ),
                                    accessor: "customerOfferId",
                                    width: "7%",
                                    Cell: ({ row }) => {
                                        const customerOffer = row.original.customerOffer;
                                        return LinkCell("/vouchers/" + customerOffer.id, customerOffer.id);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.VOUCHER.BOOKINGS.LIST.HEADER.CUSTOMER_OFFER_BALANCE" />
                                    ),
                                    accessor: "customerOfferBalance",
                                    Cell: ({ row }) => {
                                        const customerOffer = row.original.customerOffer;
                                        return TextCell(customerOffer.balance);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="SERVICE.VOUCHER.BOOKINGS.LIST.HEADER.CUSTOMER_OFFER_BALANCE_ORIGINAL" />
                                    ),
                                    accessor: "customerOfferBalanceOriginal",
                                    Cell: ({ row }) => {
                                        const customerOffer = row.original.customerOffer;
                                        return TextCell(customerOffer.originalBalance);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="BOOKING.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        if (props.value === bookingUtils.BOOKING_STATUS_DELETED) {
                                            return ChipCell("error", <FormattedMessage id="BOOKING.STATUS.DELETED" />);
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_CANCELLED) {
                                            return ChipCell(
                                                "error",
                                                <FormattedMessage id="BOOKING.STATUS.CANCELLED" />
                                            );
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_UNCONFIRMED) {
                                            return ChipCell(
                                                "warning",
                                                <FormattedMessage id="BOOKING.STATUS.UNCONFIRMED" />
                                            );
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_UNPAID) {
                                            return ChipCell("warning", <FormattedMessage id="BOOKING.STATUS.UNPAID" />);
                                        } else if (props.value === bookingUtils.BOOKING_STATUS_CONFIRMED) {
                                            return ChipCell(
                                                "success",
                                                <FormattedMessage id="BOOKING.STATUS.CONFIRMED" />
                                            );
                                        }
                                        return TextCell();
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...serviceActions, ...errorActions })(VoucherPageBookings);
