import React from "react";
import { makeStyles } from "@material-ui/core";
import SearchField from "../../SearchField";

const useStyles = makeStyles(() => ({
    searchField: {
        minWidth: "225px",
    },
}));

export default function ToolbarSearchField({ onSearchChanged, searchDelay }) {
    const classes = useStyles();
    return (
        <SearchField
            onSearchChanged={onSearchChanged}
            searchDelay={searchDelay}
            textFieldStyles={classes.searchField}
        />
    );
}
