import React from "react";
import { useSelector } from "react-redux";
import OverviewCardStats from "./OverviewCardStats";
import OverviewCardBookings from "./OverviewCardBookings";
import { isAtLeastAdminPermission } from "../../../app/pages/resources/utils";

export default function OverviewPage() {
    const { profile } = useSelector((state) => state.auth);

    if (isAtLeastAdminPermission(profile.permissionId)) {
        return (
            <>
                <div className="row">
                    <div className="col-lg-6 col-xxl-4">
                        <OverviewCardStats className="card-stretch gutter-b" />
                    </div>
                    <div className="col-lg-6 col-xxl-8">
                        <OverviewCardBookings className="card-stretch gutter-b" />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="col-lg-10">
            <OverviewCardBookings className="card-stretch gutter-b" />
        </div>
    );
}
