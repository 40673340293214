import React, { useState, useRef, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Paper, makeStyles, Snackbar } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import svLocale from "@fullcalendar/core/locales/sv";
import * as entryUtils from "../entryUtils";
import MuiAlert from "@material-ui/lab/Alert";
import "./fullcalendar.css";
import ContentDrawer from "../../../components/drawer/ContentDrawer";
import * as drawerUtils from "../../../components/drawer/utils";
import DrawerAppointment from "../drawer/DrawerAppointment";
import DrawerCourseOccasion from "../drawer/DrawerCourseOccasion";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DrawerAddBooking from "../DrawerAddBooking";
import PickDateIcon from "@material-ui/icons/DateRange";
import SettingsIcon from "@material-ui/icons/Settings";
import FilterListIcon from "@material-ui/icons/FilterList";
import CalendarSettingsPopup from "./CalendarSettingsPopup";
import CalendarFilterPopup from "./CalendarFilterPopup";
import { useIntl } from "react-intl";
import { isAtLeastBookingPermission } from "../../resources/utils";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const drawerWidth = drawerUtils.getDrawerDefaultWidth();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(4),
        },
    },
    buttonContainer: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    leftButtonContainer: {
        flexGrow: 1,
    },
    filterButton: {
        marginLeft: theme.spacing(2),
    },
    addBookingButton: {
        marginLeft: theme.spacing(2),
    },
}));

const DRAWER_CONTENT_MODE_ENTRY = "entry";
const DRAWER_CONTENT_MODE_NEW_BOOKING = "newBooking";

const DEFAULT_RESOURCES_PER_PAGE = 20;

function isInTimeLinePerspective(perspective) {
    return perspective === "resourceTimeline";
}

function CalendarPage({ getEntries, getResourceEntries, getEntry, clearBookingManagerState }) {
    const { entries, resourceEntriesPagination, entry } = useSelector((state) => state.entries);
    const { profile } = useSelector((state) => state.auth);

    const classes = useStyles();
    const intl = useIntl();

    const [isSelectDateOpen, setIsSelectDateOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [settingsPopupAnchor, setSettingsPopupAnchor] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterPopupAnchor, setFilterPopupAnchor] = useState(false);
    const [currentSettings, setCurrentSettings] = useState({});
    const [currentFilter, setCurrentFilter] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerContentMode, setDrawerContentMode] = useState(null);
    const [showMessageError, setShowMessageError] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [currentResourcePage, setCurrentResourcePage] = useState(1);

    const contentContainerRef = useRef(null);
    const calendarRef = useRef(null);

    useEffect(() => {
        const calendarSettings = entryUtils.getCalendarSettings(profile.id);
        if (calendarSettings) {
            setCurrentSettings(calendarSettings);
        }

        return () => {
            clearBookingManagerState();
        };
    }, [profile.id, clearBookingManagerState]);

    const getEventEntries = ({ startStr, endStr, filterData, view, resourcePage, resourcesPerPage }) => {
        const startTime = moment(startStr);
        const endTime = moment(endStr);

        if (!isInTimeLinePerspective(view.type)) {
            getEntries(profile.id, startTime.format(), endTime.format(), filterData);
        } else {
            const resourcePageToFetch = resourcePage || currentResourcePage;
            const resourcePerPageToFetch =
                resourcesPerPage ||
                (resourceEntriesPagination.page
                    ? resourceEntriesPagination.page * resourceEntriesPagination.perPage
                    : DEFAULT_RESOURCES_PER_PAGE);
            getResourceEntries(
                profile.id,
                startTime.format(),
                endTime.format(),
                resourcePageToFetch,
                resourcePerPageToFetch,
                filterData,
                resourcePageToFetch > 1
            );
        }
    };

    const onCalendarEventClicked = (clickedEvent) => {
        let entry = entryUtils.transformEventToEntry(clickedEvent.event);
        getEntry(entry);
        setDrawerContentMode(DRAWER_CONTENT_MODE_ENTRY);
        setIsDrawerOpen(true);
    };

    const toggleDrawer = (open) => {
        setIsDrawerOpen(open);
    };

    const showErrorMessage = (message) => {
        setMessageError(message);
        setShowMessageError(true);
    };

    const onErrorMessageClosed = () => {
        setShowMessageError(false);
    };

    const onAddBookingClicked = () => {
        setDrawerContentMode(DRAWER_CONTENT_MODE_NEW_BOOKING);
        setIsDrawerOpen(true);
    };

    const refreshCalendar = (filterData, resourcePage, resourcesPerPage) => {
        const calendarViewApi = calendarRef.current.getApi().view;
        getEventEntries({
            startStr: calendarViewApi.activeStart,
            endStr: calendarViewApi.activeEnd,
            filterData: filterData,
            view: calendarViewApi,
            resourcePage,
            resourcesPerPage,
        });
    };

    const onFetchMoreResourcesClicked = () => {
        const nextResourcePage = currentResourcePage + 1;
        setCurrentResourcePage(nextResourcePage);
        refreshCalendar(currentFilter, nextResourcePage, DEFAULT_RESOURCES_PER_PAGE);
    };

    return (
        <div className={classes.root} ref={contentContainerRef}>
            <Snackbar
                open={showMessageError}
                onClose={onErrorMessageClosed}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert elevation={6} variant="filled" severity="error" onClose={onErrorMessageClosed}>
                    {messageError}
                </MuiAlert>
            </Snackbar>

            <Paper
                className={classes.content}
                style={drawerUtils.getDrawerOffsetsStyle(
                    drawerWidth,
                    isDrawerOpen,
                    contentContainerRef,
                    document.getElementsByClassName("container")[0]
                )}
            >
                <div className={classes.buttonContainer}>
                    <div className={classes.leftButtonContainer}>
                        <Button onClick={(event) => setIsSelectDateOpen(true)} className="fc-button">
                            <PickDateIcon htmlColor="#333" />
                        </Button>
                    </div>

                    <Button
                        variant="outline-primary"
                        onClick={(event) => {
                            setIsSettingsOpen(true);
                            setSettingsPopupAnchor(event.currentTarget);
                        }}
                    >
                        <SettingsIcon />
                    </Button>

                    <Button
                        variant={currentFilter && Object.keys(currentFilter).length > 0 ? "warning" : "outline-primary"}
                        className={classes.filterButton}
                        onClick={(event) => {
                            setIsFilterOpen(true);
                            setFilterPopupAnchor(event.currentTarget);
                        }}
                    >
                        <FilterListIcon className={classes.filterIcon} />
                    </Button>

                    <CalendarSettingsPopup
                        isOpen={isSettingsOpen}
                        anchor={settingsPopupAnchor}
                        onClose={() => {
                            setIsSettingsOpen(false);
                            setSettingsPopupAnchor(null);
                        }}
                        profileId={profile.id}
                        settingsData={currentSettings}
                        onSettingsUpdated={(settingsData) => {
                            setCurrentSettings(settingsData);
                        }}
                    />

                    <CalendarFilterPopup
                        isOpen={isFilterOpen}
                        anchor={filterPopupAnchor}
                        onClose={() => {
                            setIsFilterOpen(false);
                            setFilterPopupAnchor(null);
                        }}
                        filterData={currentFilter}
                        onFilterUpdated={(filterData) => {
                            setCurrentFilter(filterData);
                            setCurrentResourcePage(1);
                            refreshCalendar(filterData, 1);
                        }}
                    />

                    {isAtLeastBookingPermission(profile.permissionId) && (
                        <Button
                            variant="outline-primary"
                            className={classes.addBookingButton}
                            onClick={() => onAddBookingClicked()}
                        >
                            <FormattedMessage id="BOOKING.ADD" />
                        </Button>
                    )}
                </div>

                <FullCalendar
                    key={profile.id}
                    contentHeight={"auto"}
                    resources={useMemo(() => {
                        const resources = resourceEntriesPagination.data.map((resource, index) => {
                            return { id: `${resource.id}`, title: resource.name, sortIndex: index };
                        });

                        if (resourceEntriesPagination.page < resourceEntriesPagination.totalPages) {
                            resources.push({
                                id: "-1",
                                title: intl.formatMessage({
                                    id: "CALENDAR.PERSPECTIVE.FETCH_MORE",
                                }),
                                sortIndex: resources.length,
                            });
                        }

                        return resources;
                    }, [resourceEntriesPagination, intl])}
                    events={useMemo(() => {
                        if (!calendarRef?.current) {
                            return [];
                        }

                        if (!isInTimeLinePerspective(calendarRef.current.getApi().view.type)) {
                            return entries.map((entry) => entryUtils.transformEntryToEvent(entry, currentSettings));
                        }

                        const resourceEntries = [];
                        resourceEntriesPagination.data.forEach((resource) => {
                            resource.entries.forEach((entry) =>
                                resourceEntries.push(
                                    entryUtils.transformEntryToEvent(
                                        { ...entry, resource: { id: resource.id, name: resource.name } },
                                        currentSettings
                                    )
                                )
                            );
                        });

                        return resourceEntries;
                    }, [entries, currentSettings, resourceEntriesPagination.data])}
                    eventClick={(e) => onCalendarEventClicked(e)}
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, resourceTimelinePlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek,resourceTimeline",
                    }}
                    locale={svLocale}
                    datesSet={(arg) => {
                        let filterData = currentFilter;
                        if (!filterData) {
                            const savedCalendarFilter = entryUtils.getCalendarFilter(profile.id);
                            if (savedCalendarFilter) {
                                filterData = {
                                    ...savedCalendarFilter,
                                    resourceIds: savedCalendarFilter.resources.map((x) => x.id),
                                    customerIds: savedCalendarFilter.customers.map((x) => x.id),
                                };
                                setCurrentFilter(filterData);
                            } else {
                                // Set empty so we dont need to check again from localStorage
                                setCurrentFilter({});
                            }
                        }

                        getEventEntries({ ...arg, filterData, resourcePage: 1 });
                    }}
                    ref={calendarRef}
                    handleWindowResize={true}
                    dayMaxEvents={2}
                    windowResize={() => {
                        // Height is sometimes not adjusted correctly when resizing window, re rendering it seems to help
                        calendarRef.current.getApi().updateSize();
                    }}
                    weekNumbers={true}
                    schedulerLicenseKey="0766419567-fcs-1686082622"
                    views={{
                        resourceTimeline: {
                            type: "resourceTimeline",
                            buttonText: intl.formatMessage({
                                id: "CALENDAR.PERSPECTIVE.RESOURCES",
                            }),
                            weekNumbers: false,
                        },
                    }}
                    resourceAreaHeaderContent={intl.formatMessage({
                        id: "CALENDAR.PERSPECTIVE.RESOURCES",
                    })}
                    resourceOrder="sortIndex"
                    resourceLabelContent={(arg) => {
                        const resource = arg.resource._resource;
                        if (resource.id !== "-1") {
                            return <span>{resource.title}</span>;
                        }

                        return (
                            <span
                                onClick={() => onFetchMoreResourcesClicked()}
                                style={{ color: "#3699FF", cursor: "pointer" }}
                            >
                                {resource.title}
                            </span>
                        );
                    }}
                />
            </Paper>

            <ContentDrawer
                isOpen={isDrawerOpen}
                onDrawerToggle={toggleDrawer}
                width={drawerWidth}
                style={{
                    visibility: "hidden" /* Strange bug fix to prevent new booking button not being clickable */,
                }}
                paperProps={{ style: { backgroundColor: "#FAFAFA", visibility: "visible" } }}
            >
                {drawerContentMode === DRAWER_CONTENT_MODE_ENTRY && entryUtils.isAppointmentEntry(entry) && (
                    <DrawerAppointment
                        key={entry.id}
                        onDrawerToggle={toggleDrawer}
                        showErrorMessage={showErrorMessage}
                    />
                )}

                {drawerContentMode === DRAWER_CONTENT_MODE_ENTRY && entryUtils.isCourseOccasionEntry(entry) && (
                    <DrawerCourseOccasion
                        key={entry.id}
                        onDrawerToggle={toggleDrawer}
                        showErrorMessage={showErrorMessage}
                    />
                )}

                {drawerContentMode === DRAWER_CONTENT_MODE_NEW_BOOKING && (
                    <DrawerAddBooking
                        onDrawerToggle={toggleDrawer}
                        onBookingAdded={() => refreshCalendar(currentFilter)}
                    />
                )}
            </ContentDrawer>

            {isSelectDateOpen && (
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DatePicker
                        clearable={false}
                        showTodayButton
                        ampm={false}
                        open={isSelectDateOpen}
                        onClose={() => setIsSelectDateOpen(false)}
                        onChange={(selectedMomentDate) => {
                            const selectedDate = selectedMomentDate.format("YYYY-MM-DD");
                            const currentCalendarViewType = calendarRef.current.getApi().view.type;
                            calendarRef.current.getApi().changeView(currentCalendarViewType, selectedDate);
                        }}
                        value={
                            calendarRef.current
                                ? moment(calendarRef.current.getApi().currentData.currentDate).format("YYYY-MM-DD")
                                : null
                        }
                    />
                </MuiPickersUtilsProvider>
            )}
        </div>
    );
}

export default injectIntl(connect(null, bookingManagerActions)(CalendarPage));
