import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const REPORTS_URL = "v1/admin/profiles/{profileId}/reports";
export const REPORT_URL = "v1/admin/reports/{id}";
export const REPORT_DOWNLOAD_DOCUMENT_URL = "v1/admin/reports/{id}/download";

export function getReports(profileId, page, perPage) {
    const url = REPORTS_URL.replace("{profileId}", profileId) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function getReport(reportId) {
    const url = REPORT_URL.replace("{id}", reportId);
    return axios.get(getApiUrl() + url);
}

export function addReport(profileId, data) {
    const url = `${REPORTS_URL.replace("{profileId}", profileId)}`;
    return axios.post(getApiUrl() + url, data);
}

export function patchReport(reportId, originalReport, changedReport) {
    const url = REPORT_URL.replace("{id}", reportId);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalReport, changedReport), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteReport(reportId) {
    const url = REPORT_URL.replace("{id}", reportId);
    return axios.delete(getApiUrl() + url);
}

export function downloadReportDocument(id) {
    return axios.get(getApiUrl() + REPORT_DOWNLOAD_DOCUMENT_URL.replace("{id}", id), {
        responseType: "arraybuffer",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
    });
}
