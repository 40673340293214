import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Link } from "react-router-dom";
import { makeStyles, Typography, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { DeleteDialog } from "../DeleteDialog";
import ChipSuccess from "../../components/chips/ChipSuccess";
import ChipWarning from "../../components/chips/ChipWarning";
import ChipInfo from "../../components/chips/ChipInfo";
import ChipError from "../../components/chips/ChipError";

const useStyles = makeStyles((theme) => ({
    deleteIconWrapper: {
        margin: theme.spacing(1),
        height: "30px",
        width: "30px",
        color: "#202020",
        "&:hover": {
            backgroundColor: "#f4516c",
            color: "white",
        },
    },
    icon: {
        height: "15px",
        width: "15px",
    },
}));

const TextCell = (text) => <Typography variant="body1">{text}</Typography>;

const TextCellEllipsis = (text) => (
    <Typography
        variant="body1"
        style={{ overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        title={text}
    >
        {text}
    </Typography>
);

const TranslationCell = (id) => (
    <Typography variant="body1">
        <FormattedMessage id={id} />
    </Typography>
);

const LinkCell = (to, text) => (
    <Typography>
        <Link to={to} color="inherit">
            {text}
        </Link>
    </Typography>
);

const LinkCellEllipsis = (to, text) => (
    <Typography style={{ overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        <Link to={to} color="inherit">
            {text}
        </Link>
    </Typography>
);

const DateCell = (dateTime) => (
    <Typography variant="body1">{dateTime ? moment(dateTime).format("YYYY-MM-DD") : ""}</Typography>
);

const DateTimeCell = (dateTime) => (
    <Typography variant="body1">{dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm") : ""}</Typography>
);

const AvatarCell = (image, name, secondary) => (
    <ListItem disableGutters={true}>
        <ListItemAvatar>
            <Avatar alt={name} src={image} />
        </ListItemAvatar>
        <ListItemText
            primary={name}
            secondary={secondary}
            primaryTypographyProps={{ style: { overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}
        />
    </ListItem>
);

const ChipCell = (type, label) => {
    if (type === "success") {
        return <ChipSuccess label={label} />;
    } else if (type === "info") {
        return <ChipInfo label={label} />;
    } else if (type === "warning") {
        return <ChipWarning label={label} />;
    } else if (type === "error") {
        return <ChipError label={label} />;
    }
};

function DeleteActionButton(title, content, handleDelete) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialogToggle = () => {
        setOpenDialog(!openDialog);
    };

    return (
        <>
            <IconButton className={classes.deleteIconWrapper} onClick={handleOpenDialogToggle}>
                <DeleteOutlinedIcon className={classes.icon} />
            </IconButton>
            <DeleteDialog
                title={title}
                content={content}
                open={openDialog}
                handleOpenToggle={handleOpenDialogToggle}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {
    TextCell,
    TextCellEllipsis,
    TranslationCell,
    DateCell,
    DateTimeCell,
    AvatarCell,
    LinkCell,
    LinkCellEllipsis,
    ChipCell,
    DeleteActionButton,
};
