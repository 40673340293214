import React from "react";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

export const PAYMENT_MODE_ON_ARRIVAL = "onArrival";
export const PAYMENT_MODE_OPTIONAL = "optional";
export const PAYMENT_MODE_MANDATORY = "mandatory";

export const PAYMENT_TYPE_MODE_ONE_TIME = "oneTime";
export const PAYMENT_TYPE_MODE_SUBSCRIPTION = "subscription";

export const PAYMENT_METHOD_CARD = "card";
export const PAYMENT_METHOD_SWISH = "swish";
export const PAYMENT_METHOD_INVOICE = "invoice";

export const SUBSCRIPTION_RECURRING_UNIT_MONTHLY = "monthly";
export const SUBSCRIPTION_RECURRING_UNIT_QUARTERLY = "quarterly";
export const SUBSCRIPTION_RECURRING_UNIT_YEARLY = "yearly";

export const SUBSCRIPTION_RECURRING_DAY_MODE_ONGOING = "ongoing";
export const SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH = "dayOfMonth";

export const paymentTemplateInputSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    paymentMode: yup.string(),
    paymentTypeMode: yup.string(),
    paymentMethods: yup.array().when("paymentMode", (paymentMode) => {
        if (paymentMode !== PAYMENT_MODE_ON_ARRIVAL) {
            return yup.array().required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD_CHECKBOXES" />);
        }

        return yup.array();
    }),
    billingTemplate: yup.object(),
    subscriptionRecurringDayMode: yup.object(),
    subscriptionRecurringDayOfMonth: yup
        .number()
        .when("subscriptionRecurringDayMode", (subscriptionRecurringDayMode) => {
            if (subscriptionRecurringDayMode?.id !== SUBSCRIPTION_RECURRING_DAY_MODE_DAY_OF_MONTH) {
                return yup
                    .number()
                    .nullable(true)
                    .transform((_, val) => (val === "" ? null : parseInt(val, 10)));
            } else {
                return yup
                    .number()
                    .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
                    .min(1, <FormattedMessage id="FORM.ERROR.MIN" values={{ number: 1 }} />)
                    .max(31, <FormattedMessage id="FORM.ERROR.MAX" values={{ number: 31 }} />);
            }
        }),
    subscriptionNoticePeriodDays: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === "" ? null : parseInt(val, 10))),
});
