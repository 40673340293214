import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import ResourceGroupPageNavigator from "./ResourceGroupPageNavigator";
import ResourceGroupPageDetails from "./ResourceGroupPageDetails";
import ResourceGroupPageResources from "./ResourceGroupPageResources";
import ResourceGroupPageHours from "./ResourceGroupPageHours";
import ResourceGroupPageMedia from "./ResourceGroupPageMedia";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function ResourceGroupPage({ match, getResourceGroup, clearResourceState, clearDisplayError, clearErrorState }) {
    const resourceGroupId = parseInt(match.params.id, 10);
    const { resourceGroup, isLoading, isUpdating } = useSelector((state) => state.resources);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!resourceGroup || resourceGroup.id !== resourceGroupId)) {
            getResourceGroup(resourceGroupId);
        }
    }, [getResourceGroup, resourceGroup, resourceGroupId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearResourceState();
            clearErrorState();
        };
    }, [clearResourceState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <ResourceGroupPageNavigator resourceGroupId={resourceGroupId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/resource-groups/${resourceGroupId}`}
                            exact={true}
                            to={`/resource-groups/${resourceGroupId}/details`}
                        />
                        <Route
                            path={`/resource-groups/${resourceGroupId}/details`}
                            component={ResourceGroupPageDetails}
                        />
                        <Route
                            path={`/resource-groups/${resourceGroupId}/resource-list`}
                            component={ResourceGroupPageResources}
                        />
                        <Route path={`/resource-groups/${resourceGroupId}/hours`} component={ResourceGroupPageHours} />
                        <Route path={`/resource-groups/${resourceGroupId}/media`} component={ResourceGroupPageMedia} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...resourceActions, ...errorActions })(ResourceGroupPage);
