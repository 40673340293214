import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
    REGISTER_ERROR: "REGISTER_ERROR",
    REGISTER_API_ERROR: "REGISTER_API_ERROR",
    REGISTER_LOCAL_ERROR: "REGISTER_LOCAL_ERROR",
    REGISTER_DISPLAY_ERROR: "REGISTER_DISPLAY_ERROR",
    CLEAR_DISPLAY_ERROR: "CLEAR_DISPLAY_ERROR",
    CLEAR_ERRORS_STATE: "CLEAR_ERRORS_STATE",
};

const initialState = {
    apiError: null,
    displayError: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_API_ERROR: {
            return {
                ...state,
                apiError: action.payload.error,
            };
        }

        case actionTypes.REGISTER_DISPLAY_ERROR: {
            return {
                ...state,
                displayError: action.payload.error,
            };
        }

        case actionTypes.CLEAR_DISPLAY_ERROR: {
            return {
                ...state,
                displayError: null,
            };
        }

        case actionTypes.CLEAR_ERRORS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    registerError: (error) => ({
        type: actionTypes.REGISTER_ERROR,
        payload: { error },
    }),

    clearDisplayError: () => ({
        type: actionTypes.CLEAR_DISPLAY_ERROR,
    }),

    clearErrorState: () => ({
        type: actionTypes.CLEAR_ERRORS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.REGISTER_ERROR, function* ({ payload }) {
        if (payload.error) {
            if (payload.error.response) {
                yield put({
                    type: actionTypes.REGISTER_API_ERROR,
                    payload: { error: payload.error },
                });

                const apiErrorResponse = payload.error.response;
                yield put({
                    type: actionTypes.REGISTER_DISPLAY_ERROR,
                    payload: { error: apiErrorResponse.data },
                });
            }
        }
    });
}
