import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as entriesActions } from "../../../../redux/entries/entriesRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import AppointmentPageNavigator from "./AppointmentPageNavigator";
import AppointmentPageDetails from "./AppointmentPageDetails";
import AppointmentPageBookingSettings from "./AppointmentPageBookingSettings";
import AppointmentPageCheckoutSettings from "./AppointmentPageCheckoutSettings";
import AppointmentPageResources from "./AppointmentPageResources";
import ServicePageAddons from "../ServicePageAddons";
import AppointmentPageBookings from "./AppointmentPageBookings";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function AppointmentPage({ match, getAppointment, clearServiceState, clearDisplayError, clearErrorState }) {
    const serviceId = parseInt(match.params.id, 10);
    const { appointment, isLoading, isUpdating } = useSelector((state) => state.services);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!appointment || appointment.id !== serviceId)) {
            getAppointment(serviceId);
        }
    }, [getAppointment, appointment, serviceId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <AppointmentPageNavigator serviceId={serviceId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/services/${serviceId}/appointment`}
                            exact={true}
                            to={`/services/${serviceId}/appointment/details`}
                        />
                        <Route path={`/services/${serviceId}/appointment/details`} component={AppointmentPageDetails} />
                        <Route
                            path={`/services/${serviceId}/appointment/booking-settings`}
                            component={AppointmentPageBookingSettings}
                        />
                        <Route
                            path={`/services/${serviceId}/appointment/checkout-settings`}
                            component={AppointmentPageCheckoutSettings}
                        />
                        <Route
                            path={`/services/${serviceId}/appointment/resources`}
                            component={AppointmentPageResources}
                        />
                        <Route
                            path={`/services/${serviceId}/appointment/addons`}
                            render={(props) => <ServicePageAddons {...props} service={appointment} />}
                        />
                        <Route
                            path={`/services/${serviceId}/appointment/bookings`}
                            component={AppointmentPageBookings}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...serviceActions,
    ...entriesActions,
    ...errorActions,
})(AppointmentPage);
