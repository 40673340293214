import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapAddButtons from "../../components/buttons/BootstrapAddButtons";
import SnackbarError from "../../components/SnackbarError";
import {
    RESOURCE_STAFF_TYPE,
    RESOURCE_SPACE_TYPE,
    RESOURCE_ARTICLE_TYPE,
    getPermissionName,
    PERMISSION_STAFF_ID,
} from "./utils";

const staffSchema = yup.object().shape({
    firstName: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    surname: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    email: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    permissionId: yup.number().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
});

const spaceSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    address: yup.string(),
    capacity: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    description: yup.string(),
});

const articleSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    stockBalance: yup
        .number()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    description: yup.string(),
});

function ResourcePageAdd({
    match,
    addResource,
    getStaffAddPermissions,
    clearResourceState,
    clearDisplayError,
    clearErrorState,
}) {
    const type = match.params.type;
    const { profile } = useSelector((state) => state.auth);
    const { resource, staffPermissions, isLoading } = useSelector((state) => state.resources);
    const { displayError } = useSelector((state) => state.errors);

    const [redirect, setRedirect] = useState(false);
    const [permissionId, setPermissionId] = useState(PERMISSION_STAFF_ID);

    const intl = useIntl();

    useEffect(() => {
        if (type === RESOURCE_STAFF_TYPE) {
            getStaffAddPermissions(profile.id);
        }

        return () => {
            clearResourceState();
            clearErrorState();
        };
    }, [getStaffAddPermissions, profile.id, type, clearResourceState, clearErrorState]);

    let schema = staffSchema;
    if (type === RESOURCE_STAFF_TYPE) {
        schema = staffSchema;
    } else if (type === RESOURCE_SPACE_TYPE) {
        schema = spaceSchema;
    } else if (type === RESOURCE_ARTICLE_TYPE) {
        schema = articleSchema;
    }

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmitRedirect = (values) => {
        addResourceFromForm(values, true);
    };

    const onFormSubmitAddAnother = (values) => {
        addResourceFromForm(values, false);
    };

    const addResourceFromForm = (formData, redirect) => {
        if (type === RESOURCE_SPACE_TYPE) {
            if (formData.address) {
                formData.location = {
                    address: formData.address,
                };
            }
            delete formData.address;
        }
        addResource(type, profile.id, formData, () => {
            if (redirect) {
                setRedirect(true);
            } else {
                reset();
            }
        });
    };

    if (redirect && resource) {
        return <Redirect to={`/resources/${resource.id}/${type}`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            {type === RESOURCE_STAFF_TYPE && (
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">
                            <FormattedMessage id="RESOURCE.STAFF.CREATE.TITLE" />
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.EMAIL" />
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    className="form-control form-control-lg col-xl-6"
                                    name="email"
                                    ref={register}
                                    isInvalid={errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                <Form.Text className="text-muted">
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.EMAIL_ADD_DESCRIPTION" />
                                </Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.FIRST_NAME" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg col-xl-6"
                                    name="firstName"
                                    ref={register}
                                    isInvalid={errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstName?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.SURNAME" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg col-xl-6"
                                    name="surname"
                                    ref={register}
                                    isInvalid={errors.surname}
                                />
                                <Form.Control.Feedback type="invalid">{errors.surname?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.PERMISSION" />
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="form-control form-control-lg col-xl-6"
                                    ref={register}
                                    name="permissionId"
                                    value={permissionId}
                                    onChange={(e) => {
                                        const permissionId = parseInt(e.target.value, 10);
                                        setPermissionId(permissionId);
                                    }}
                                >
                                    {getPermissionOptions(staffPermissions, intl)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.DESCRIPTION" />
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className="form-control form-control-lg col-xl-6"
                                    name="description"
                                    ref={register}
                                    isInvalid={errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <BootstrapAddButtons
                                addButtonIsLoading={isLoading}
                                addAnotherButtonIsLoading={isLoading}
                                addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                                addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                            />
                        </Form>
                    </Card.Body>
                </Card>
            )}
            {type === RESOURCE_SPACE_TYPE && (
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">
                            <FormattedMessage id="RESOURCE.SPACE.CREATE.TITLE" />
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.SPACE.DETAILS.NAME" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg col-xl-6"
                                    name="name"
                                    ref={register}
                                    isInvalid={errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.SPACE.DETAILS.ADDRESS" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg col-xl-6"
                                    name="address"
                                    ref={register}
                                    isInvalid={errors.address}
                                />
                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.SPACE.DETAILS.CAPACITY" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg col-xl-6"
                                    name="capacity"
                                    ref={register}
                                    isInvalid={errors.capacity}
                                />
                                <Form.Control.Feedback type="invalid">{errors.capacity?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.STAFF.DETAILS.DESCRIPTION" />
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className="form-control form-control-lg col-xl-6"
                                    name="description"
                                    ref={register}
                                    isInvalid={errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <BootstrapAddButtons
                                addButtonIsLoading={isLoading}
                                addAnotherButtonIsLoading={isLoading}
                                addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                                addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                            />
                        </Form>
                    </Card.Body>
                </Card>
            )}
            {type === RESOURCE_ARTICLE_TYPE && (
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">
                            <FormattedMessage id="RESOURCE.ARTICLE.CREATE.TITLE" />
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.NAME" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-control form-control-lg col-xl-6"
                                    name="name"
                                    ref={register}
                                    isInvalid={errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.STOCK_BALANCE" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="form-control form-control-lg col-xl-6"
                                    name="stockBalance"
                                    ref={register}
                                    isInvalid={errors.stockBalance}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.stockBalance?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="RESOURCE.ARTICLE.DETAILS.DESCRIPTION" />
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className="form-control form-control-lg col-xl-6"
                                    name="description"
                                    ref={register}
                                    isInvalid={errors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.description?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <BootstrapAddButtons
                                addButtonIsLoading={isLoading}
                                addAnotherButtonIsLoading={isLoading}
                                addButtonOnClick={handleSubmit(onFormSubmitRedirect)}
                                addAnotherButtonOnClick={handleSubmit(onFormSubmitAddAnother)}
                            />
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
}

export default connect(null, { ...resourceActions, ...errorActions })(ResourcePageAdd);

function getPermissionOptions(permissions, intl) {
    if (!permissions) return null;
    return permissions.map((x) => {
        return (
            <option key={x.name} value={x.id}>
                {getPermissionName(x.id, intl)}
            </option>
        );
    });
}
