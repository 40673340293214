import { put, takeLatest, select } from "redux-saga/effects";
import {
    getIntegrations,
    getIntegration,
    patchIntegration,
    deleteIntegration,
    createZesecIntegration,
    getZesecLocations,
    getZesecKeys,
    createZesecKeyService,
    patchZesecKeyService,
    deleteZesecKeyService,
} from "./integrationCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_INTEGRATIONS_REQUEST: "GET_INTEGRATIONS_REQUEST",
    GET_INTEGRATIONS_SUCCESS: "GET_INTEGRATIONS_SUCCESS",
    DELETE_INTEGRATION_REQUEST: "DELETE_INTEGRATION_REQUEST",
    DELETE_INTEGRATION_SUCCESS: "DELETE_INTEGRATION_SUCCESS",
    GET_INTEGRATION_ZESEC_REQUEST: "GET_INTEGRATION_ZESEC_REQUEST",
    GET_INTEGRATION_ZESEC_SUCCESS: "GET_INTEGRATION_ZESEC_SUCCESS",
    CREATE_INTEGRATION_ZESEC_REQUEST: "CREATE_INTEGRATION_ZESEC_REQUEST",
    CREATE_INTEGRATION_ZESEC_SUCCESS: "CREATE_INTEGRATION_ZESEC_SUCCESS",
    UPDATE_INTEGRATION_ZESEC_REQUEST: "UPDATE_INTEGRATION_ZESEC_REQUEST",
    UPDATE_INTEGRATION_ZESEC_SUCCESS: "UPDATE_INTEGRATION_ZESEC_SUCCESS",
    GET_ZESEC_KEYS_REQUEST: "GET_ZESEC_KEYS_REQUEST",
    GET_ZESEC_KEYS_SUCCESS: "GET_ZESEC_KEYS_SUCCESS",
    MANAGE_ZESEC_KEY_SERVICE: "MANAGE_ZESEC_KEY_SERVICE",
    MANAGE_ZESEC_KEY_SERVICE_CANCEL: "MANAGE_ZESEC_KEY_SERVICE_CANCEL",
    CREATE_ZESEC_KEY_SERVICE_REQUEST: "CREATE_ZESEC_KEY_SERVICE_REQUEST",
    CREATE_ZESEC_KEY_SERVICE_SUCCESS: "CREATE_ZESEC_KEY_SERVICE_SUCCESS",
    UPDATE_ZESEC_KEY_SERVICE_REQUEST: "UPDATE_ZESEC_KEY_SERVICE_REQUEST",
    UPDATE_ZESEC_KEY_SERVICE_SUCCESS: "UPDATE_ZESEC_KEY_SERVICE_SUCCESS",
    DELETE_ZESEC_KEY_SERVICE_REQUEST: "DELETE_ZESEC_KEY_SERVICE_REQUEST",
    DELETE_ZESEC_KEY_SERVICE_SUCCESS: "DELETE_ZESEC_KEY_SERVICE_SUCCESS",
};

const initialState = {
    isLoading: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    zesec: {
        integration: null,
        keysPagination: {
            data: [],
            totalRows: 0,
        },
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTEGRATIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.DELETE_INTEGRATION_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_INTEGRATION_SUCCESS: {
            let listPagination = state.listPagination;
            let index = listPagination.data.findIndex((item) => item.id === action.payload.id);
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...listPagination.data];
                alteredPaginationData.splice(index, 1);
            }
            return {
                ...state,
                isLoading: false,
                listPagination: {
                    ...state.listPagination,
                    data: alteredPaginationData || listPagination.data,
                    totalRows: listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_INTEGRATION_ZESEC_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_ZESEC_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    integration: action.payload.zesecIntegration,
                    locations: action.payload.locations,
                },
            };
        }

        case actionTypes.CREATE_INTEGRATION_ZESEC_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_INTEGRATION_ZESEC_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    integration: action.payload.response,
                    redirectToDetails: true,
                },
            };
        }

        case actionTypes.UPDATE_INTEGRATION_ZESEC_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_INTEGRATION_ZESEC_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    integration: action.payload.response,
                },
            };
        }

        case actionTypes.GET_ZESEC_KEYS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_ZESEC_KEYS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    keysPagination: action.payload.response,
                },
            };
        }

        case actionTypes.MANAGE_ZESEC_KEY_SERVICE: {
            return {
                ...state,
                zesec: {
                    ...state.zesec,
                    managingKey: action.payload.key,
                    managingKeyService: action.payload.keyService,
                },
            };
        }

        case actionTypes.MANAGE_ZESEC_KEY_SERVICE_CANCEL: {
            return {
                ...state,
                zesec: { ...state.zesec, managingKey: null, managingKeyService: null },
            };
        }

        case actionTypes.CREATE_ZESEC_KEY_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_ZESEC_KEY_SERVICE_SUCCESS: {
            let keyListPagination = state.zesec.keysPagination;
            let index = keyListPagination.data.findIndex(
                (item) => item.zesecKeyId === action.payload.response.zesecKeyId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...keyListPagination.data];
                let key = alteredPaginationData[index];
                if (key.keyServices) {
                    key.keyServices.push(action.payload.response);
                } else {
                    key.keyServices = [action.payload.response];
                }
            }
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    managingKey: null,
                    managingKeyService: null,
                    keysPagination: {
                        ...state.zesec.keysPagination,
                        data: alteredPaginationData || keyListPagination.data,
                    },
                },
            };
        }

        case actionTypes.DELETE_ZESEC_KEY_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_ZESEC_KEY_SERVICE_SUCCESS: {
            let keyListPagination = state.zesec.keysPagination;
            let index = keyListPagination.data.findIndex(
                (item) => item.zesecKeyId === action.payload.response.zesecKeyId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...keyListPagination.data];
                let key = alteredPaginationData[index];
                if (key.keyServices) {
                    let keyServiceIndex = key.keyServices.findIndex((item) => item.id === action.payload.response.id);
                    if (keyServiceIndex > -1) {
                        key.keyServices.splice(keyServiceIndex, 1);
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    managingKey: null,
                    managingKeyService: null,
                    keysPagination: {
                        ...state.zesec.keysPagination,
                        data: alteredPaginationData || keyListPagination.data,
                    },
                },
            };
        }

        case actionTypes.UPDATE_ZESEC_KEY_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_ZESEC_KEY_SERVICE_SUCCESS: {
            let keyListPagination = state.zesec.keysPagination;
            let index = keyListPagination.data.findIndex(
                (item) => item.zesecKeyId === action.payload.response.zesecKeyId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...keyListPagination.data];
                let key = alteredPaginationData[index];
                if (key.keyServices) {
                    let keyServiceIndex = key.keyServices.findIndex((item) => item.id === action.payload.response.id);
                    if (keyServiceIndex > -1) {
                        key.keyServices[keyServiceIndex] = action.payload.response;
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                zesec: {
                    ...state.zesec,
                    managingKey: null,
                    managingKeyService: null,
                    keysPagination: {
                        ...state.zesec.keysPagination,
                        data: alteredPaginationData || keyListPagination.data,
                    },
                },
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getIntegrations: (profileId, page, perPage) => ({
        type: actionTypes.GET_INTEGRATIONS_REQUEST,
        payload: { profileId, page, perPage },
    }),
    getIntegrationsSuccess: (response) => ({
        type: actionTypes.GET_INTEGRATIONS_SUCCESS,
        payload: { response },
    }),
    deleteIntegration: (id) => ({
        type: actionTypes.DELETE_INTEGRATION_REQUEST,
        payload: { id },
    }),
    deleteIntegrationSuccess: (id) => ({
        type: actionTypes.DELETE_INTEGRATION_SUCCESS,
        payload: { id },
    }),

    getIntegrationZesec: (id) => ({
        type: actionTypes.GET_INTEGRATION_ZESEC_REQUEST,
        payload: { id },
    }),
    getIntegrationZesecSuccess: (zesecIntegration, locations) => ({
        type: actionTypes.GET_INTEGRATION_ZESEC_SUCCESS,
        payload: { zesecIntegration, locations },
    }),
    createZesecIntegration: (profileId, phoneNumber, password) => ({
        type: actionTypes.CREATE_INTEGRATION_ZESEC_REQUEST,
        payload: { profileId, phoneNumber, password },
    }),
    createZesecIntegrationSuccess: (response) => ({
        type: actionTypes.CREATE_INTEGRATION_ZESEC_SUCCESS,
        payload: { response },
    }),
    updateZesecIntegration: (originalIntegration, accountId) => ({
        type: actionTypes.UPDATE_INTEGRATION_ZESEC_REQUEST,
        payload: { originalIntegration, accountId },
    }),
    updateZesecIntegrationSuccess: (response) => ({
        type: actionTypes.UPDATE_INTEGRATION_ZESEC_SUCCESS,
        payload: { response },
    }),
    getZesecKeys: (id, page, perPage) => ({
        type: actionTypes.GET_ZESEC_KEYS_REQUEST,
        payload: { id, page, perPage },
    }),
    getZesecKeysSuccess: (response) => ({
        type: actionTypes.GET_ZESEC_KEYS_SUCCESS,
        payload: { response },
    }),
    manageZesecKeyService: (key, keyService) => ({
        type: actionTypes.MANAGE_ZESEC_KEY_SERVICE,
        payload: { key, keyService },
    }),
    cancelManageZesecKeyService: () => ({
        type: actionTypes.MANAGE_ZESEC_KEY_SERVICE_CANCEL,
        payload: {},
    }),
    createZesecKeyService: (integrationId, zesecKeyId, serviceId, prefixMinutes, postfixMinutes) => ({
        type: actionTypes.CREATE_ZESEC_KEY_SERVICE_REQUEST,
        payload: { integrationId, zesecKeyId, serviceId, prefixMinutes, postfixMinutes },
    }),
    createZesecKeyServiceSuccess: (response) => ({
        type: actionTypes.CREATE_ZESEC_KEY_SERVICE_SUCCESS,
        payload: { response },
    }),
    updateZesecKeyService: (integrationId, originalKeyService, serviceId, prefixMinutes, postfixMinutes) => ({
        type: actionTypes.UPDATE_ZESEC_KEY_SERVICE_REQUEST,
        payload: { integrationId, originalKeyService, serviceId, prefixMinutes, postfixMinutes },
    }),
    updateZesecKeyServiceSuccess: (response) => ({
        type: actionTypes.UPDATE_ZESEC_KEY_SERVICE_SUCCESS,
        payload: { response },
    }),
    deleteZesecKeyService: (keyServiceId) => ({
        type: actionTypes.DELETE_ZESEC_KEY_SERVICE_REQUEST,
        payload: { keyServiceId },
    }),
    deleteZesecKeyServiceSuccess: (response) => ({
        type: actionTypes.DELETE_ZESEC_KEY_SERVICE_SUCCESS,
        payload: { response },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_INTEGRATIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getIntegrations(payload.profileId, payload.page, payload.perPage);

            yield put(actions.getIntegrationsSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_INTEGRATION_ZESEC_REQUEST, function* ({ payload }) {
        try {
            const accountId = payload.accountId || payload.originalIntegration.accountId;
            const state = yield select();
            const locations = [...state.integrations.zesec.locations];
            let accountName;
            if (locations) {
                const indexOfLocation = locations.findIndex((x) => x.id === accountId);
                if (indexOfLocation > -1) {
                    accountName = locations[indexOfLocation].name;
                }
            }

            const { data: response } = yield patchIntegration(
                "zesec",
                payload.originalIntegration.id,
                payload.originalIntegration,
                {
                    ...payload.originalIntegration,
                    accountId: accountId,
                    accountName: accountName || payload.originalIntegration.accountName,
                }
            );
            yield put(actions.updateZesecIntegrationSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_INTEGRATION_REQUEST, function* ({ payload }) {
        try {
            yield deleteIntegration(payload.id);
            yield put(actions.deleteIntegrationSuccess(payload.id));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_INTEGRATION_ZESEC_REQUEST, function* ({ payload }) {
        try {
            const { data: zesecIntegration } = yield getIntegration("zesec", payload.id);

            let locations;
            if (!zesecIntegration.accountId) {
                // Integration has no selected account, also fetch locations
                const { data } = yield getZesecLocations(zesecIntegration.id);
                locations = data;
            }

            yield put(actions.getIntegrationZesecSuccess(zesecIntegration, locations));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_INTEGRATION_ZESEC_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createZesecIntegration(
                payload.profileId,
                payload.phoneNumber,
                payload.password
            );
            yield put(actions.createZesecIntegrationSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_ZESEC_KEYS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getZesecKeys(payload.id, payload.page, payload.perPage);
            yield put(actions.getZesecKeysSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_ZESEC_KEY_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createZesecKeyService(
                payload.integrationId,
                payload.zesecKeyId,
                payload.serviceId,
                payload.prefixMinutes ? payload.prefixMinutes * 60 : null,
                payload.postfixMinutes ? payload.postfixMinutes * 60 : null
            );
            yield put(actions.createZesecKeyServiceSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_ZESEC_KEY_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchZesecKeyService(payload.integrationId, payload.originalKeyService, {
                ...payload.originalKeyService,
                serviceId: payload.serviceId || payload.originalKeyService.serviceId,
                prefixSeconds: payload.prefixMinutes ? payload.prefixMinutes * 60 : null,
                postfixSeconds: payload.postfixMinutes ? payload.postfixMinutes * 60 : null,
            });
            yield put(actions.updateZesecKeyServiceSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_ZESEC_KEY_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteZesecKeyService(payload.keyServiceId);
            yield put(actions.deleteZesecKeyServiceSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
