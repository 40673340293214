import React, { useMemo, useCallback } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { Link } from "react-router-dom";
import { AvatarCell, DateTimeCell, TextCell } from "../../components/tables/MaterialTableCells";
import { USER_PROXY_PARENT, USER_PROXY_CHILD } from "./utils";

function CustomerPageUserProxies({ getCustomerUserProxies, clearCustomerState }) {
    const { customer, customerUserProxiesPagination, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const intl = useIntl();
    const customerId = customer?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerId) {
                getCustomerUserProxies(customerId, pageIndex + 1, pageSize);
            }
        },
        [customerId, getCustomerUserProxies]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.USER_PROXIES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.USER_PROXIES.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customer?.id}
                        data={useMemo(() => customerUserProxiesPagination.data, [customerUserProxiesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={customerUserProxiesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CUSTOMER.USER_PROXIES.LIST.HEADER.NAME" />,
                                    accessor: "name",
                                    Cell: ({ row }) => {
                                        const customer = row.original.customer;
                                        return (
                                            <Link to={`/customers/${customer.id}`} onClick={() => clearCustomerState()}>
                                                {AvatarCell(
                                                    customer.avatarUrl,
                                                    `${customer.firstName} ${customer.surname}`
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.USER_PROXIES.LIST.HEADER.RELATION" />,
                                    accessor: "type",
                                    Cell: (props) => {
                                        if (props.value === USER_PROXY_PARENT) {
                                            return TextCell(
                                                intl.formatMessage(
                                                    { id: "CUSTOMER.USER_PROXIES.RELATION.PARENT" },
                                                    { name: customer?.firstName }
                                                )
                                            );
                                        } else if (props.value === USER_PROXY_CHILD) {
                                            return TextCell(
                                                intl.formatMessage(
                                                    { id: "CUSTOMER.USER_PROXIES.RELATION.CHILD" },
                                                    { name: customer?.firstName }
                                                )
                                            );
                                        }

                                        return TextCell();
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.USER_PROXIES.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: ({ row }) => {
                                        const customer = row.original.customer;
                                        return DateTimeCell(customer.createdAt);
                                    },
                                },
                            ],
                            [customer?.firstName, intl, clearCustomerState]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...customerActions, ...errorActions })(CustomerPageUserProxies);
