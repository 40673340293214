import axios from "axios";
import { getApiUrl } from "../../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const SCRIVE_DETAILS_URL = "v1/admin/integrations/{id}/scrive";
export const SCRIVE_TEMPLATES_URL = "v1/admin/integrations/{id}/scrive/templates";
export const SCRIVE_TEMPLATE_SERVICE_URL = "v1/admin/integrations/{id}/scrive/templateservices";

export function getIntegration(id) {
    let url = SCRIVE_DETAILS_URL.replace("{id}", id);
    return axios.get(getApiUrl() + url);
}

export function getTemplates(id) {
    let url = SCRIVE_TEMPLATES_URL.replace("{id}", id);
    return axios.get(getApiUrl() + url);
}

export function createScriveTemplateService(integrationId, scriveTemplateId, data) {
    let url = SCRIVE_TEMPLATE_SERVICE_URL.replace("{id}", integrationId);
    return axios.post(getApiUrl() + url, { scriveTemplateId, ...data });
}

export function patchScriveTemplateService(
    integrationId,
    originalTemplateService,
    changedTemplateService
) {
    let url =
        SCRIVE_TEMPLATE_SERVICE_URL.replace("{id}", integrationId) +
        "/" +
        originalTemplateService.id;
    return axios.patch(
        getApiUrl() + url,
        jsonpatch.compare(originalTemplateService, changedTemplateService),
        {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        }
    );
}

export function deleteScriveTemplateService(integrationId, scriveTemplateId) {
    let url = SCRIVE_TEMPLATE_SERVICE_URL.replace("{id}", integrationId) + "/" + scriveTemplateId;
    return axios.delete(getApiUrl() + url);
}
