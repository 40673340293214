import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as subscriptionActions } from "../../../redux/subscriptions/subscriptionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import SubscriptionPageNavigator from "./SubscriptionPageNavigator";
import SubscriptionPageDetails from "./SubscriptionPageDetails";
import SubscriptionPageTransactions from "./SubscriptionPageTransactions";
import SubscriptionPageNext from "./SubscriptionPageNext";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function SubscriptionPage({ match, getSubscription, clearSubscriptionState, clearDisplayError, clearErrorState }) {
    const subscriptionId = parseInt(match.params.id, 10);
    const { subscription, isLoading, isUpdating } = useSelector((state) => state.subscriptions);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!subscription || subscription.id !== subscriptionId)) {
            getSubscription(subscriptionId);
        }
    }, [getSubscription, subscription, subscriptionId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearSubscriptionState();
            clearErrorState();
        };
    }, [clearSubscriptionState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <SubscriptionPageNavigator subscriptionId={subscriptionId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/subscriptions/${subscriptionId}`}
                            exact={true}
                            to={`/subscriptions/${subscriptionId}/details`}
                        />
                        <Route path={`/subscriptions/${subscriptionId}/details`} component={SubscriptionPageDetails} />
                        <Route
                            path={`/subscriptions/${subscriptionId}/transactions`}
                            component={SubscriptionPageTransactions}
                        />
                        <Route path={`/subscriptions/${subscriptionId}/next`} component={SubscriptionPageNext} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...subscriptionActions, ...errorActions })(SubscriptionPage);
