import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { actions as scriveActions } from "../../../../redux/integrations/scrive/scriveRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import {
    Paper,
    makeStyles,
    Typography,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import EditKeyServiceIcon from "@material-ui/icons/EditOutlined";
import DeleteKeyServiceIcon from "@material-ui/icons/DeleteOutlined";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import ScriveDocumentServiceModal from "./ScriveDocumentServiceModal";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    locationForm: {
        width: "75%",
    },
    keyChip: {
        marginLeft: theme.spacing(2),
    },
}));

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 40px 0px 40px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemStyled = withStyles({
    gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
})(ListItem);

const KeyAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const KeyAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        justifyContent: "space-between",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

function ScriveDetailsPage(props) {
    const integrationId = props.match.params.id;
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, templatesPagination, managingTemplate } = useSelector((state) => state.scrive);
    const { displayError } = useSelector((state) => state.errors);
    const { listPagination: serviceListPagination } = useSelector((state) => state.services);
    const [templateServiceDeletion, setTemplateServiceDeletion] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        props.getScriveIntegration(integrationId);
        props.getServices(profile.id, 1, 9999, null, "adminBookable");
    }, [props, integrationId, profile.id]);

    const fetchTemplates = useCallback(
        ({ pageIndex, pageSize }) => {
            props.getScriveTemplates(integrationId, pageIndex + 1, pageSize);
        },
        [props, integrationId]
    );

    const onAddTemplateServiceClicked = useCallback(
        (event, template) => {
            event.stopPropagation();
            props.manageScriveTemplateService(template);
        },
        [props]
    );

    const onTemplateServiceEditClicked = useCallback(
        (template, templateService) => {
            props.manageScriveTemplateService(template, templateService);
        },
        [props]
    );

    const onTemplateServiceDeleteClicked = useCallback((templateService) => {
        setTemplateServiceDeletion(templateService);
    }, []);

    const onAddTemplateServiceModalCloseClicked = () => {
        props.cancelManageScriveTemplateService();
    };

    const onTemplateServiceModalSaveClicked = (template, templateService, data) => {
        if (!templateService) {
            props.createScriveTemplateService(parseInt(integrationId, 10), template.scriveTemplateId, data);
        } else {
            props.updateScriveTemplateService(parseInt(integrationId, 10), templateService, data);
        }
    };

    const onDeleteDialogOpenChanged = () => {
        setTemplateServiceDeletion(null);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            props.deleteScriveTemplateService(parseInt(integrationId, 10), templateServiceDeletion.id);
        }
        setTemplateServiceDeletion(null);
    };

    const keysColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="INTEGRATION.SCRIVE.DETAILS.DOCUMENT.LIST.HEADER" />,
                accessor: "id",
                width: "7%",
                Cell: ({ row }) => {
                    const template = row.original;
                    const serviceCount = template.templateServices.length;

                    return (
                        <KeyAccordionStyled elevation={0} variant={"elevation"}>
                            <KeyAccordionSummaryStyled expandIcon={serviceCount > 0 ? <ExpandMoreIcon /> : null}>
                                <div>
                                    <Typography variant="body1">{template.title}</Typography>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SUBTITLE"
                                            values={{
                                                scriveCreatedAt: moment(template.scriveCreatedAt).format(
                                                    "YYYY-MM-DD HH:mm"
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </div>
                                <div>
                                    {serviceCount > 0 && (
                                        <ChipSuccess
                                            label={
                                                <FormattedMessage
                                                    id={
                                                        serviceCount > 1
                                                            ? "INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE_COUNT_MULTIPLE"
                                                            : "INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE_COUNT_SINGLE"
                                                    }
                                                    values={{ serviceCount }}
                                                />
                                            }
                                            style={{ marginRight: "8px" }}
                                            size="small"
                                        />
                                    )}
                                    <IconButton
                                        size="small"
                                        onClick={(event) => onAddTemplateServiceClicked(event, template)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </KeyAccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <ListStyled>
                                    {template.templateServices.map((x, i, items) => {
                                        return (
                                            <div
                                                key={template.id + "_" + x.service.id}
                                                style={{
                                                    marginBottom: i === items.length - 1 ? "8px" : "0px",
                                                }}
                                            >
                                                <ListItemStyled>
                                                    <ListItemText
                                                        secondary={x.service.name}
                                                        secondaryTypographyProps={{
                                                            variant: "body1",
                                                        }}
                                                    ></ListItemText>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onTemplateServiceDeleteClicked(x)}
                                                        style={{ marginRight: "8px" }}
                                                    >
                                                        <DeleteKeyServiceIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onTemplateServiceEditClicked(template, x)}
                                                    >
                                                        <EditKeyServiceIcon />
                                                    </IconButton>
                                                </ListItemStyled>
                                                {i < items.length - 1 && <Divider />}
                                            </div>
                                        );
                                    })}
                                </ListStyled>
                            </AccordionDetailsStyled>
                        </KeyAccordionStyled>
                    );
                },
            },
        ],
        [onAddTemplateServiceClicked, onTemplateServiceEditClicked, onTemplateServiceDeleteClicked]
    );

    return (
        <PageBackdrop isLoading={isLoading && integration && !integration.accountName ? true : false}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => props.clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                {integration && integration.accountName && (
                    <div className={classes.locationForm}>
                        <Typography variant="h4" className={classes.header}>
                            <FormattedMessage
                                id="INTEGRATION.SCRIVE.DETAILS.TITLE"
                                values={{ accountName: integration.accountName }}
                            />
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            <FormattedMessage id="INTEGRATION.SCRIVE.DETAILS.DESCRIPTION" />
                        </Typography>
                        <MaterialReactTable
                            key={integrationId}
                            data={templatesPagination.data}
                            fetchData={fetchTemplates}
                            pageSize={10}
                            rowCount={templatesPagination.totalRows}
                            isLoading={isLoading}
                            columns={keysColumns}
                            showHeader={true}
                            getCustomCellProps={() => ({
                                style: { paddingLeft: "0px", paddingRight: "8px" },
                            })}
                            className={classes.keysTable}
                        />
                        {managingTemplate && (
                            <ScriveDocumentServiceModal
                                show={managingTemplate ? true : false}
                                onCloseClicked={onAddTemplateServiceModalCloseClicked}
                                services={serviceListPagination.data}
                                onSaveClicked={onTemplateServiceModalSaveClicked}
                            />
                        )}
                    </div>
                )}
                <YesNoDialog
                    title={<FormattedMessage id="INTEGRATION.SCRIVE.DETAILS.DOCUMENT.SERVICE.DELETE.TITLE" />}
                    open={templateServiceDeletion ? true : false}
                    onOpenChanged={onDeleteDialogOpenChanged}
                    onActionClicked={onDeleteDialogActionClicked}
                />
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...scriveActions,
    ...serviceActions,
    ...errorActions,
})(ScriveDetailsPage);
