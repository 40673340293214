import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as cancellationTemplateActions } from "../../../redux/cancellationtemplates/cancellationTemplateRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import CancellationTemplatePageNavigator from "./CancellationTemplatePageNavigator";
import CancellationTemplatePageDetails from "./CancellationTemplatePageDetails";
import CancellationTemplateRefundRules from "./CancellationTemplateRefundRules";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function CancellationTemplatePage({
    match,
    getProfileCancellationTemplate,
    clearCancellationTemplateState,
    clearDisplayError,
    clearErrorState,
}) {
    const cancellationTemplateId = parseInt(match.params.id, 10);
    const { cancellationTemplate, isLoading, isUpdating } = useSelector((state) => state.cancellationTemplates);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!cancellationTemplate || cancellationTemplate.id !== cancellationTemplateId)) {
            getProfileCancellationTemplate(cancellationTemplateId);
        }
    }, [getProfileCancellationTemplate, cancellationTemplate, cancellationTemplateId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearCancellationTemplateState();
            clearErrorState();
        };
    }, [clearCancellationTemplateState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <CancellationTemplatePageNavigator cancellationTemplateId={cancellationTemplateId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/cancellation-templates/${cancellationTemplateId}`}
                            exact={true}
                            to={`/cancellation-templates/${cancellationTemplateId}/details`}
                        />
                        <Route
                            path={`/cancellation-templates/${cancellationTemplateId}/details`}
                            component={CancellationTemplatePageDetails}
                        />
                        <Route
                            path={`/cancellation-templates/${cancellationTemplateId}/refund-rules`}
                            component={CancellationTemplateRefundRules}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...cancellationTemplateActions,
    ...errorActions,
})(CancellationTemplatePage);
