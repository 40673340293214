import React from "react";
import { makeStyles } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import ToolbarSearchField from "./ToolbarSearchField";
import TransactionStatusFilter from "./filter/TransactionStatusFilter";
import TransactionPaymentTypeFilter from "./filter/TransactionPaymentTypeFilter";
import TransactionPaymentStatusFilter from "./filter/TransactionPaymentStatusFilter";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: theme.spacing(1),
    },
    searchAndFilterContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        "& > div": { margin: theme.spacing(1) },
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
}));

function SubscriptionPaymentListToolbar({
    onSearchChanged,
    onStatusChanged,
    onPaymentTypeChanged,
    onPaymentStatusChanged,
}) {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.searchAndFilterContainer}>
                <ToolbarSearchField onSearchChanged={onSearchChanged} />
                <TransactionStatusFilter onStatusChanged={onStatusChanged} />
                <TransactionPaymentTypeFilter onPaymentTypeChanged={onPaymentTypeChanged} />
                <TransactionPaymentStatusFilter onPaymentStatusChanged={onPaymentStatusChanged} />
            </div>
            <div className={classes.buttonContainer}></div>
        </Toolbar>
    );
}

export default SubscriptionPaymentListToolbar;
