import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Switch } from "@material-ui/core";

const ReportFilterSwitch = ({ name, defaultValue, title, description, isDisabled }) => {
    const { control } = useFormContext();

    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue || false}
                value={true}
                render={({ value, onChange }) => {
                    return (
                        <Switch checked={value} onChange={(event, value) => onChange(value)} disabled={isDisabled} />
                    );
                }}
            />
            {description && <Form.Text className="text-muted">{description}</Form.Text>}
        </Form.Group>
    );
};

export default ReportFilterSwitch;
