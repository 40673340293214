export function getDayName(dayIndex, intl) {
    if (dayIndex === 1) {
        return intl.formatMessage({
            id: "COMMON.DAY.MONDAY",
        });
    } else if (dayIndex === 2) {
        return intl.formatMessage({
            id: "COMMON.DAY.TUESDAY",
        });
    } else if (dayIndex === 3) {
        return intl.formatMessage({
            id: "COMMON.DAY.WEDNESDAY",
        });
    } else if (dayIndex === 4) {
        return intl.formatMessage({
            id: "COMMON.DAY.THURSDAY",
        });
    } else if (dayIndex === 5) {
        return intl.formatMessage({
            id: "COMMON.DAY.FRIDAY",
        });
    } else if (dayIndex === 6) {
        return intl.formatMessage({
            id: "COMMON.DAY.SATURDAY",
        });
    } else if (dayIndex === 7) {
        return intl.formatMessage({
            id: "COMMON.DAY.SUNDAY",
        });
    }

    return "";
}

// Checks if hour intervals overlap
// https://stackoverflow.com/questions/44800471/check-if-times-overlap-using-moment#:~:text=You%20can%20sort%20timeSegments%20by,happens%2C%20there%20is%20an%20overlap.
export function areHoursOverlapping(timeSegments) {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) => timeSegment1[0].localeCompare(timeSegment2[0]));

    for (let i = 0; i < timeSegments.length - 1; i++) {
        const currentEndTime = timeSegments[i][1];
        const nextStartTime = timeSegments[i + 1][0];

        if (currentEndTime > nextStartTime) {
            return true;
        }
    }

    return false;
}
