import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles, Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));

export default function ServiceSectionHeader({ intlId }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h6">
                <FormattedMessage id={intlId} />
            </Typography>
            <Divider light />
        </div>
    );
}
