import { put, takeLatest } from "redux-saga/effects";
import {
    getPromotionCodes,
    getPromotionCode,
    addPromotionCode,
    patchPromotionCode,
    deletePromotionCode,
    getPromotionCodeServices,
    addPromotionCodeServicesAndCategories,
    deletePromotionCodeService,
    getPromotionCodeTransactions,
    getPromotionCodeCustomers,
} from "./promotionCodesCrud";
import { findAndSpliceArrayByProperty } from "../reduxUtils";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PROMOTION_CODES_REQUEST: "GET_PROMOTION_CODES_REQUEST",
    GET_PROMOTION_CODES_SUCCESS: "GET_PROMOTION_CODES_SUCCESS",
    GET_PROMOTION_CODE_REQUEST: "GET_PROMOTION_CODE_REQUEST",
    GET_PROMOTION_CODE_SUCCESS: "GET_PROMOTION_CODE_SUCCESS",
    ADD_PROMOTION_CODE_REQUEST: "ADD_PROMOTION_CODE_REQUEST",
    ADD_PROMOTION_CODE_SUCCESS: "ADD_PROMOTION_CODE_SUCCESS",
    UPDATE_PROMOTION_CODE_REQUEST: "UPDATE_PROMOTION_CODE_REQUEST",
    UPDATE_PROMOTION_CODE_SUCCESS: "UPDATE_PROMOTION_CODE_SUCCESS",
    DELETE_PROMOTION_CODE_REQUEST: "DELETE_PROMOTION_CODE_REQUEST",
    DELETE_PROMOTION_CODE_SUCCESS: "DELETE_PROMOTION_CODE_SUCCESS",
    GET_PROMOTION_CODE_SERVICES_REQUEST: "GET_PROMOTION_CODE_SERVICES_REQUEST",
    GET_PROMOTION_CODE_SERVICES_SUCCESS: "GET_PROMOTION_CODE_SERVICES_SUCCESS",
    ADD_PROMOTION_CODE_SERVICE_REQUEST: "ADD_PROMOTION_CODE_SERVICE_REQUEST",
    ADD_PROMOTION_CODE_SERVICE_SUCCESS: "ADD_PROMOTION_CODE_SERVICE_SUCCESS",
    DELETE_PROMOTION_CODE_SERVICE_REQUEST: "DELETE_PROMOTION_CODE_SERVICE_REQUEST",
    DELETE_PROMOTION_CODE_SERVICE_SUCCESS: "DELETE_PROMOTION_CODE_SERVICE_SUCCESS",
    GET_PROMOTION_CODE_TRANSACTIONS_REQUEST: "GET_PROMOTION_CODE_TRANSACTIONS_REQUEST",
    GET_PROMOTION_CODE_TRANSACTIONS_SUCCESS: "GET_PROMOTION_CODE_TRANSACTIONS_SUCCESS",
    GET_PROMOTION_CODE_CUSTOMERS_REQUEST: "GET_PROMOTION_CODE_CUSTOMERS_REQUEST",
    GET_PROMOTION_CODE_CUSTOMERS_SUCCESS: "GET_PROMOTION_CODE_CUSTOMERS_SUCCESS",
    CLEAR_PROMOTION_CODES_STATE: "CLEAR_PROMOTION_CODES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    promotionCodeServicesPagination: {
        data: [],
        totalRows: 0,
    },
    promotionCodeTransactionsPagination: {
        data: [],
        totalRows: 0,
    },
    promotionCodeCustomersPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROMOTION_CODES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROMOTION_CODES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PROMOTION_CODE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROMOTION_CODE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                promotionCode: action.payload.response,
            };
        }

        case actionTypes.ADD_PROMOTION_CODE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PROMOTION_CODE_SUCCESS: {
            return { ...state, isLoading: false, promotionCode: action.payload.response };
        }

        case actionTypes.UPDATE_PROMOTION_CODE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_PROMOTION_CODE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                promotionCode: action.payload.response,
            };
        }

        case actionTypes.DELETE_PROMOTION_CODE_REQUEST: {
            return { ...state, isLoading: false };
        }

        case actionTypes.DELETE_PROMOTION_CODE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.listPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                promotionCode: action.payload.response,
                listPagination: {
                    ...state.listPagination,
                    data: alteredStateArray,
                    totalRows: state.listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_PROMOTION_CODE_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROMOTION_CODE_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                promotionCodeServicesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_PROMOTION_CODE_SERVICE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_PROMOTION_CODE_SERVICE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.DELETE_PROMOTION_CODE_SERVICE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.DELETE_PROMOTION_CODE_SERVICE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.promotionCodeServicesPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isUpdating: false,
                promotionCodeServicesPagination: {
                    ...state.promotionCodeServicesPagination,
                    data: alteredStateArray,
                    totalRows: state.promotionCodeServicesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_PROMOTION_CODE_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROMOTION_CODE_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                promotionCodeTransactionsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PROMOTION_CODE_CUSTOMERS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROMOTION_CODE_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                promotionCodeCustomersPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false, isDownloadingPdf: false };
        }

        case actionTypes.CLEAR_PROMOTION_CODES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getPromotionCodes: (profileId, page, perPage, search) => ({
        type: actionTypes.GET_PROMOTION_CODES_REQUEST,
        payload: { profileId, page, perPage, search },
    }),

    getPromotionCode: (id) => ({
        type: actionTypes.GET_PROMOTION_CODE_REQUEST,
        payload: { id },
    }),

    addPromotionCode: (profileId, data, callback) => ({
        type: actionTypes.ADD_PROMOTION_CODE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updatePromotionCode: (id, originalPromotionCode, updatedPromotionCode) => ({
        type: actionTypes.UPDATE_PROMOTION_CODE_REQUEST,
        payload: { id, originalPromotionCode, updatedPromotionCode },
    }),

    deletePromotionCode: (id) => ({
        type: actionTypes.DELETE_PROMOTION_CODE_REQUEST,
        payload: { id },
    }),

    getPromotionCodeServices: (id, page, perPage) => ({
        type: actionTypes.GET_PROMOTION_CODE_SERVICES_REQUEST,
        payload: { id, page, perPage },
    }),

    addPromotionCodeServicesAndCategories: (id, items, callback) => ({
        type: actionTypes.ADD_PROMOTION_CODE_SERVICE_REQUEST,
        payload: { id, items, callback },
    }),

    deletePromotionCodeService: (id, serviceId, callback) => ({
        type: actionTypes.DELETE_PROMOTION_CODE_SERVICE_REQUEST,
        payload: { id, serviceId, callback },
    }),

    getPromotionCodeTransactions: (id, page, perPage) => ({
        type: actionTypes.GET_PROMOTION_CODE_TRANSACTIONS_REQUEST,
        payload: { id, page, perPage },
    }),

    getPromotionCodeCustomers: (id, page, perPage) => ({
        type: actionTypes.GET_PROMOTION_CODE_CUSTOMERS_REQUEST,
        payload: { id, page, perPage },
    }),

    clearPromotionCodeState: () => ({
        type: actionTypes.CLEAR_PROMOTION_CODES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROMOTION_CODES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPromotionCodes(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search
            );
            yield put({
                type: actionTypes.GET_PROMOTION_CODES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROMOTION_CODE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPromotionCode(payload.id);

            yield put({
                type: actionTypes.GET_PROMOTION_CODE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROMOTION_CODE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addPromotionCode(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PROMOTION_CODE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PROMOTION_CODE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchPromotionCode(payload.id, payload.originalPromotionCode, {
                ...payload.originalPromotionCode,
                ...payload.updatedPromotionCode,
            });
            yield put({
                type: actionTypes.UPDATE_PROMOTION_CODE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PROMOTION_CODE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deletePromotionCode(payload.id);
            yield put({
                type: actionTypes.DELETE_PROMOTION_CODE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROMOTION_CODE_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPromotionCodeServices(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_PROMOTION_CODE_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROMOTION_CODE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addPromotionCodeServicesAndCategories(payload.id, payload.items);
            yield put({
                type: actionTypes.ADD_PROMOTION_CODE_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PROMOTION_CODE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deletePromotionCodeService(payload.id);
            yield put({
                type: actionTypes.DELETE_PROMOTION_CODE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROMOTION_CODE_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPromotionCodeTransactions(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_PROMOTION_CODE_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PROMOTION_CODE_CUSTOMERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPromotionCodeCustomers(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_PROMOTION_CODE_CUSTOMERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
