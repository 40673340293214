import React, { useEffect, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as serviceActions } from "../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import {
    Paper,
    makeStyles,
    Typography,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from "@material-ui/core";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import ServiceSortListToolbar from "../../components/tables/toolbar/ServiceSortListToolbar";
import SnackbarError from "../../components/SnackbarError";
import { SERVICE_TYPE_COURSE } from "./utils";
import { useIntl } from "react-intl";
import moment from "moment";

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 40px 0px 40px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemStyled = withStyles({
    gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
})(ListItem);

const KeyAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const KeyAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

const ArrowButton = withStyles({
    root: {
        padding: "4px",
    },
})(IconButton);

const useStyles = makeStyles((theme) => ({
    content: { paddingTop: theme.spacing(4) },
    header: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginLeft: theme.spacing(4),
    },
    listItemName: {
        marginLeft: "8px",
    },
}));

function ServiceSortPageList({
    getServicesByCategory,
    changeServiceSorting,
    updateServiceListSorting,
    clearServiceState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listByCategoryPagination, isSortingUpdating } = useSelector((state) => state.services);
    const { displayError } = useSelector((state) => state.errors);

    const classes = useStyles();
    const intl = useIntl();

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getServicesByCategory(profile.id, pageIndex + 1, pageSize, null, "active");
        },
        [getServicesByCategory, profile.id]
    );

    const onSaveClicked = useCallback(() => {
        updateServiceListSorting(profile.id);
    }, [updateServiceListSorting, profile.id]);

    const getServiceNameText = useCallback(
        (service) => {
            if (service.serviceType === SERVICE_TYPE_COURSE && service.startTime) {
                return intl.formatMessage(
                    { id: "SERVICE.SORT.LIST.SERVICE.COURSE_NAME" },
                    { name: service.name, startTime: moment(service.startTime).format("YYYY-MM-DD HH:mm") }
                );
            }

            return service.name;
        },
        [intl]
    );

    return (
        <Paper className={classes.content}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Typography variant="h4" className={classes.header}>
                <FormattedMessage id="SERVICE.SORT.TITLE" />
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
                <FormattedMessage id="SERVICE.SORT.SUBTITLE" />
            </Typography>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listByCategoryPagination.data, [listByCategoryPagination.data])}
                fetchData={fetchData}
                rowCount={listByCategoryPagination.totalRows}
                pageSize={9999}
                isLoading={isLoading}
                showFooter={false}
                renderToolbar={() => {
                    return <ServiceSortListToolbar onSaveClicked={onSaveClicked} isSaveLoading={isSortingUpdating} />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="SERVICE.SORT.LIST.HEADER.NAME" />,
                            accessor: "id",
                            width: "7%",
                            Cell: ({ row }) => {
                                const category = row.original.categoryId ? row.original : null;
                                const service = row.original.serviceId ? row.original : null;
                                const services = category ? category.services : [];
                                return (
                                    <KeyAccordionStyled
                                        elevation={0}
                                        variant={"elevation"}
                                        defaultExpanded={true}
                                        expanded={true}
                                    >
                                        <KeyAccordionSummaryStyled>
                                            <ArrowButton
                                                onClick={(event) => {
                                                    changeServiceSorting(category || service, "up", 1);
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <ArrowUpward fontSize="medium" />
                                            </ArrowButton>
                                            <ArrowButton
                                                onClick={(event) => {
                                                    changeServiceSorting(category || service, "down", 1);
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <ArrowDownward fontSize="medium" />
                                            </ArrowButton>
                                            <Typography variant="subtitle1" className={classes.listItemName}>
                                                {category ? category.name : getServiceNameText(service)}
                                            </Typography>
                                        </KeyAccordionSummaryStyled>
                                        <AccordionDetailsStyled>
                                            <ListStyled>
                                                {services.map((x, i, items) => {
                                                    const changeSortingItem = {
                                                        categoryId: category.categoryId,
                                                        serviceId: x.id,
                                                    };
                                                    return (
                                                        <div
                                                            key={category.id + "_" + x.id}
                                                            style={{
                                                                marginBottom: i === items.length - 1 ? "8px" : "0px",
                                                            }}
                                                        >
                                                            <ListItemStyled>
                                                                <ArrowButton
                                                                    onClick={(event) => {
                                                                        changeServiceSorting(
                                                                            changeSortingItem,
                                                                            "up",
                                                                            2
                                                                        );
                                                                        event.stopPropagation();
                                                                    }}
                                                                >
                                                                    <ArrowUpward fontSize="medium" />
                                                                </ArrowButton>
                                                                <ArrowButton
                                                                    onClick={(event) => {
                                                                        changeServiceSorting(
                                                                            changeSortingItem,
                                                                            "down",
                                                                            2
                                                                        );
                                                                        event.stopPropagation();
                                                                    }}
                                                                >
                                                                    <ArrowDownward fontSize="medium" />
                                                                </ArrowButton>
                                                                <ListItemText
                                                                    primary={getServiceNameText(x)}
                                                                    primaryTypographyProps={{
                                                                        variant: "body1",
                                                                        style: { marginLeft: "8px" },
                                                                    }}
                                                                ></ListItemText>
                                                            </ListItemStyled>
                                                            {i < items.length - 1 && <Divider />}
                                                        </div>
                                                    );
                                                })}
                                            </ListStyled>
                                        </AccordionDetailsStyled>
                                    </KeyAccordionStyled>
                                );
                            },
                        },
                    ],
                    [classes.listItemName, changeServiceSorting, getServiceNameText]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
        </Paper>
    );
}

export default connect(null, { ...serviceActions, ...errorActions })(ServiceSortPageList);
