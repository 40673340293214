import { put, takeLatest } from "redux-saga/effects";
import {
    getServiceCategories,
    getServiceCategory,
    addServiceCategory,
    patchServiceCategory,
    deleteServiceCategory,
    getServiceCategoryServices,
} from "./serviceCategoriesCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import { findAndSpliceArrayByProperty } from "../reduxUtils";

export const actionTypes = {
    GET_SERVICE_CATEGORIES_REQUEST: "GET_SERVICE_CATEGORIES_REQUEST",
    GET_SERVICE_CATEGORIES_SUCCESS: "GET_SERVICE_CATEGORIES_SUCCESS",
    GET_SERVICE_CATEGORY_REQUEST: "GET_SERVICE_CATEGORY_REQUEST",
    GET_SERVICE_CATEGORY_SUCCESS: "GET_SERVICE_CATEGORY_SUCCESS",
    ADD_SERVICE_CATEGORY_REQUEST: "ADD_SERVICE_CATEGORY_REQUEST",
    ADD_SERVICE_CATEGORY_SUCCESS: "ADD_SERVICE_CATEGORY_SUCCESS",
    UPDATE_SERVICE_CATEGORY_REQUEST: "UPDATE_SERVICE_CATEGORY_REQUEST",
    UPDATE_SERVICE_CATEGORY_SUCCESS: "UPDATE_SERVICE_CATEGORY_SUCCESS",
    DELETE_SERVICE_CATEGORY_REQUEST: "DELETE_SERVICE_CATEGORY_REQUEST",
    DELETE_SERVICE_CATEGORY_SUCCESS: "DELETE_SERVICE_CATEGORY_SUCCESS",
    GET_SERVICE_CATEGORY_SERVICES_REQUEST: "GET_SERVICE_CATEGORY_SERVICES_REQUEST",
    GET_SERVICE_CATEGORY_SERVICES_SUCCESS: "GET_SERVICE_CATEGORY_SERVICES_SUCCESS",
    CLEAR_SERVICE_CATEGORIES_STATE: "CLEAR_SERVICE_CATEGORIES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    serviceCategoryServicesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SERVICE_CATEGORIES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICE_CATEGORIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_SERVICE_CATEGORY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICE_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                serviceCategory: action.payload.response,
            };
        }

        case actionTypes.ADD_SERVICE_CATEGORY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_SERVICE_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                serviceCategory: action.payload.response,
            };
        }

        case actionTypes.UPDATE_SERVICE_CATEGORY_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_SERVICE_CATEGORY_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                serviceCategory: action.payload.response,
            };
        }

        case actionTypes.DELETE_SERVICE_CATEGORY_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.DELETE_SERVICE_CATEGORY_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.listPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isUpdating: false,
                serviceCategory: action.payload.response,
                listPagination: {
                    ...state.listPagination,
                    data: alteredStateArray,
                    totalRows: state.listPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_SERVICE_CATEGORY_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SERVICE_CATEGORY_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                serviceCategoryServicesPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_SERVICE_CATEGORIES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getServiceCategories: (profileId, page, perPage, search, status) => ({
        type: actionTypes.GET_SERVICE_CATEGORIES_REQUEST,
        payload: { profileId, page, perPage, search, status },
    }),

    getServiceCategory: (serviceCategoryId) => ({
        type: actionTypes.GET_SERVICE_CATEGORY_REQUEST,
        payload: { serviceCategoryId },
    }),

    addServiceCategory: (profileId, serviceCategory, callback) => ({
        type: actionTypes.ADD_SERVICE_CATEGORY_REQUEST,
        payload: { profileId, serviceCategory, callback },
    }),

    updateServiceCategory: (id, originalServiceCategory, changedServiceCategory) => ({
        type: actionTypes.UPDATE_SERVICE_CATEGORY_REQUEST,
        payload: { id, originalServiceCategory, changedServiceCategory },
    }),

    deleteServiceCategory: (serviceCategoryId) => ({
        type: actionTypes.DELETE_SERVICE_CATEGORY_REQUEST,
        payload: { serviceCategoryId },
    }),

    getServiceCategoryServices: (profileId, page, perPage) => ({
        type: actionTypes.GET_SERVICE_CATEGORY_SERVICES_REQUEST,
        payload: { profileId, page, perPage },
    }),

    clearServiceCategoriesState: () => ({
        type: actionTypes.CLEAR_SERVICE_CATEGORIES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_SERVICE_CATEGORIES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceCategories(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status
            );
            yield put({
                type: actionTypes.GET_SERVICE_CATEGORIES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICE_CATEGORY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceCategory(payload.serviceCategoryId);
            yield put({
                type: actionTypes.GET_SERVICE_CATEGORY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_SERVICE_CATEGORY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addServiceCategory(payload.profileId, payload.serviceCategory);
            yield put({
                type: actionTypes.ADD_SERVICE_CATEGORY_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_SERVICE_CATEGORY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchServiceCategory(payload.id, payload.originalServiceCategory, {
                ...payload.originalServiceCategory,
                ...payload.changedServiceCategory,
            });
            yield put({
                type: actionTypes.UPDATE_SERVICE_CATEGORY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_SERVICE_CATEGORY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteServiceCategory(payload.serviceCategoryId);
            yield put({
                type: actionTypes.DELETE_SERVICE_CATEGORY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SERVICE_CATEGORY_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getServiceCategoryServices(
                payload.profileId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_SERVICE_CATEGORY_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
