/**
 * Api handles color hex in ARGB format while CSS supports RGBA.
 * This method converts given ARGB color hex to RGBA color hex.
 * @param {*} apiHex
 * @returns hex in RGBA format
 */
export function convertApiColorToHexColor(apiHex) {
    // If color without alpha, for example #01B5AD
    if (apiHex.length === 7) return apiHex;
    return `#${apiHex.substring(3)}${apiHex.substring(1, 3)}`;
}

/**
 * Api handles color hex in ARGB format while CSS supports RGBA.
 * This method converts given RGBA color hex to ARGB color hex.
 * @param {*} hexColor
 * @returns hex in ARGB format
 */
export function convertHexColorToApiColor(hexColor) {
    // If color without alpha, for example #01B5AD
    if (hexColor.length === 7) return hexColor;
    return `#${hexColor.substring(7, 9)}${hexColor.substring(1, 7)}`;
}

export function getRGBACssFromARGBHex(hex) {
    const rgba = getRGBAFromARGBHex(hex);
    return "rgba(" + rgba[0] + "," + rgba[1] + "," + rgba[2] + "," + rgba[3] + ")";
}

export function getRGBAObjectFromARBGHex(hex) {
    const rgba = getRGBAFromARGBHex(hex);
    return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
}

// Inspiration from https://stackoverflow.com/questions/49974145/how-to-convert-rgba-to-hex-color-code-using-javascript
export function getARGBHexFromRGBACss(orig) {
    var a,
        rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = ((rgb && rgb[4]) || "").trim(),
        hex = rgb
            ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
              (rgb[2] | (1 << 8)).toString(16).slice(1) +
              (rgb[3] | (1 << 8)).toString(16).slice(1)
            : orig;

    if (alpha !== "") {
        a = alpha;
    } else {
        // (change) a = 01; gives strict mode error
        a = 0o1;
    }

    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);

    // (change) return in ARGB format
    return "#" + a + hex;
}

// Inspiration from https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
function getRGBAFromARGBHex(hexCode) {
    var hex = hexCode.replace("#", "");

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r, g, b, a;
    if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
        a = 1;
    } else if (hex.length === 8) {
        r = parseInt(hex.substring(2, 4), 16);
        g = parseInt(hex.substring(4, 6), 16);
        b = parseInt(hex.substring(6, 8), 16);

        // Alpha in 3 decimals and then round to two
        a = parseFloat(parseInt((parseInt(hex.substring(0, 2), 16) / 255) * 1000) / 1000);
        a = Math.round((a + Number.EPSILON) * 100) / 100;
    }

    return [r, g, b, a];
}
