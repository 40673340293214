import React, { useEffect } from "react";
import { actions as reportActions } from "../../../redux/reports/reportRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapButton from "../../components/buttons/BootstrapButton";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import ReportCommonDetailsForm from "./ReportCommonDetailsForm";
import ReportFilterValuesForm from "./ReportFilterValuesForm";
import { makeStyles } from "@material-ui/core";
import * as reportUtils from "./utils";
import { Alert } from "@material-ui/lab";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

const useStyles = makeStyles((theme) => ({
    downloadButton: {
        marginRight: theme.spacing(2),
    },
}));

function ReportPageDetails({ getReport, updateReport, downloadReportDocument }) {
    const { report, isUpdating, isDownloadingDocument } = useSelector((state) => state.reports);

    const classes = useStyles();

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        // If report is in progress, fetch status updates
        let intervalId = 0;
        const reportStatus = report?.status;
        if (reportStatus === reportUtils.REPORT_STATUS_PENDING || reportStatus === reportUtils.REPORT_STATUS_STARTED) {
            let fetchCount = 0;
            intervalId = setInterval(() => {
                getReport(report.id);

                // Stop fetching after a while
                if (++fetchCount > 20) {
                    clearInterval(intervalId);
                }
            }, 6000);
        }

        // Stop fetching once finished
        if (
            intervalId &&
            (reportStatus === reportUtils.REPORT_STATUS_FINISHED ||
                reportStatus === reportUtils.REPORT_STATUS_ERROR ||
                reportStatus === reportUtils.REPORT_STATUS_DELETED)
        ) {
            clearInterval(intervalId);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [report?.status, report?.id, getReport]);

    const onFormSubmit = (values) => {
        let originalReport = { name: report.name };

        let updatedReportData = { ...originalReport };
        updatedReportData.name = values.name;

        updateReport(report.id, originalReport, updatedReportData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="REPORT.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="REPORT.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {report && (
                        <div className="card-toolbar">
                            {report.status === reportUtils.REPORT_STATUS_FINISHED && (
                                <BootstrapButton
                                    isLoading={isDownloadingDocument}
                                    onClick={() => downloadReportDocument(report.id, `Report ${report.id}`)}
                                    label={<FormattedMessage id="COMMON.DOWNLOAD" />}
                                    className={classes.downloadButton}
                                ></BootstrapButton>
                            )}
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)} />
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            {report?.status &&
                                report?.status !== reportUtils.REPORT_STATUS_FINISHED &&
                                report?.status !== reportUtils.REPORT_STATUS_ERROR &&
                                report?.status !== reportUtils.REPORT_STATUS_DELETED && (
                                    <Form.Group>
                                        <Alert severity="info">
                                            <FormattedMessage id="REPORT.DETAILS.IN_PROGRESS_INFO" />
                                        </Alert>
                                    </Form.Group>
                                )}

                            <ReportCommonDetailsForm report={report} />

                            <ReportFilterValuesForm report={report} showReportInfo={false} isExistingReport={true} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, reportActions)(ReportPageDetails);
