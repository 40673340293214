import React, { useEffect, useMemo } from "react";
import { APPLE_LOGIN_URL } from "../../../../../redux/auth/authCrud";
import * as auth from "../../../../../redux/auth/authRedux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

function LoginRedirect({ location, onLoginRequest }) {
    const credentials = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return {
            code: queryParams.get("code"),
            firstName: queryParams.get("firstName"),
            surname: queryParams.get("surname"),
            email: queryParams.get("email"),
        };
    }, [location.search]);

    useEffect(() => {
        onLoginRequest(APPLE_LOGIN_URL, credentials);
    }, [onLoginRequest, credentials]);

    return <div></div>;
}

export default injectIntl(connect(null, auth.actions)(LoginRedirect));
