import React from "react";
import { makeStyles } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import CourseOccasionStatusFilter from "./filter/CourseOccasionStatusFilter";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: theme.spacing(1),
    },
    searchAndFilterContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        "& > div": { margin: theme.spacing(1) },
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
}));

function CourseOccasionListToolbar({ onStatusChanged }) {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.searchAndFilterContainer}>
                <CourseOccasionStatusFilter onStatusChanged={onStatusChanged} />
            </div>
        </Toolbar>
    );
}

export default CourseOccasionListToolbar;
