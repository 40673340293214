import { put, takeLatest } from "redux-saga/effects";
import {
    createDormakabaIntegration,
    getDormakabaComponents,
    createDormakabaComponentService,
    patchDormakabaComponentService,
    deleteDormakabaComponentService,
} from "./dormakabaCrud";
import { getIntegration } from "../integrationCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../../errors/errorsRedux";

export const actionTypes = {
    GET_INTEGRATION_DORMAKABA_REQUEST: "GET_INTEGRATION_DORMAKABA_REQUEST",
    GET_INTEGRATION_DORMAKABA_SUCCESS: "GET_INTEGRATION_DORMAKABA_SUCCESS",
    CREATE_INTEGRATION_DORMAKABA_REQUEST: "CREATE_INTEGRATION_DORMAKABA_REQUEST",
    CREATE_INTEGRATION_DORMAKABA_SUCCESS: "CREATE_INTEGRATION_DORMAKABA_SUCCESS",
    GET_DORMAKABA_COMPONENTS_REQUEST: "GET_DORMAKABA_COMPONENTS_REQUEST",
    GET_DORMAKABA_COMPONENTS_SUCCESS: "GET_DORMAKABA_COMPONENTS_SUCCESS",
    MANAGE_DORMAKABA_COMPONENT_SERVICE: "MANAGE_DORMAKABA_COMPONENT_SERVICE",
    MANAGE_DORMAKABA_COMPONENT_CANCEL: "MANAGE_DORMAKABA_COMPONENT_CANCEL",
    CREATE_DORMAKABA_COMPONENT_SERVICE_REQUEST: "CREATE_DORMAKABA_COMPONENT_SERVICE_REQUEST",
    CREATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS: "CREATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS",
    UPDATE_DORMAKABA_COMPONENT_SERVICE_REQUEST: "UPDATE_DORMAKABA_COMPONENT_SERVICE_REQUEST",
    UPDATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS: "UPDATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS",
    DELETE_DORMAKABA_COMPONENT_SERVICE_REQUEST: "DELETE_DORMAKABA_COMPONENT_SERVICE_REQUEST",
    DELETE_DORMAKABA_COMPONENT_SERVICE_SUCCESS: "DELETE_DORMAKABA_COMPONENT_SERVICE_SUCCESS",
};

const initialState = {
    isLoading: false,
    integration: null,
    componentsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTEGRATION_DORMAKABA_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_DORMAKABA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.CREATE_INTEGRATION_DORMAKABA_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_INTEGRATION_DORMAKABA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
                redirectToDetails: true,
            };
        }

        case actionTypes.GET_DORMAKABA_COMPONENTS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_DORMAKABA_COMPONENTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                componentsPagination: action.payload.response,
            };
        }

        case actionTypes.MANAGE_DORMAKABA_COMPONENT_SERVICE: {
            return {
                ...state,
                managingComponent: action.payload.component,
                managingComponentService: action.payload.componentService,
            };
        }

        case actionTypes.MANAGE_DORMAKABA_COMPONENT_CANCEL: {
            return {
                ...state,
                managingComponent: null,
                managingComponentService: null,
            };
        }

        case actionTypes.CREATE_DORMAKABA_COMPONENT_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS: {
            const componentsListPagination = state.componentsPagination;
            const index = componentsListPagination.data.findIndex(
                (item) => item.dormakabaComponentId === action.payload.response.dormakabaComponentId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...componentsListPagination.data];
                let component = alteredPaginationData[index];
                if (component.services) {
                    component.services.push(action.payload.response);
                } else {
                    component.service = [action.payload.response];
                }
            }
            return {
                ...state,
                isLoading: false,
                managingComponent: null,
                managingComponentService: null,
                componentsPagination: {
                    ...state.componentsPagination,
                    data: alteredPaginationData || componentsListPagination.data,
                },
            };
        }

        case actionTypes.UPDATE_DORMAKABA_COMPONENT_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS: {
            const componentsListPagination = state.componentsPagination;
            const index = componentsListPagination.data.findIndex(
                (item) => item.dormakabaComponentId === action.payload.response.dormakabaComponentId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...componentsListPagination.data];
                let component = alteredPaginationData[index];
                if (component.services) {
                    const componentServiceIndex = component.services.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (componentServiceIndex > -1) {
                        component.services[componentServiceIndex] = action.payload.response;
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingComponent: null,
                managingComponentService: null,
                componentsPagination: {
                    ...state.componentsPagination,
                    data: alteredPaginationData || componentsListPagination.data,
                },
            };
        }

        case actionTypes.DELETE_DORMAKABA_COMPONENT_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_DORMAKABA_COMPONENT_SERVICE_SUCCESS: {
            const componentsListPagination = state.componentsPagination;
            const index = componentsListPagination.data.findIndex(
                (item) => item.dormakabaComponentId === action.payload.response.dormakabaComponentId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...componentsListPagination.data];
                let component = alteredPaginationData[index];
                if (component.services) {
                    const componentServiceIndex = component.services.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (componentServiceIndex > -1) {
                        component.services.splice(componentServiceIndex, 1);
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingComponent: null,
                managingComponentService: null,
                componentsPagination: {
                    ...state.componentsPagination,
                    data: alteredPaginationData || componentsListPagination.data,
                },
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getDormakabaIntegration: (id) => ({
        type: actionTypes.GET_INTEGRATION_DORMAKABA_REQUEST,
        payload: { id },
    }),

    createDormakabaIntegration: (profileId, apiKey, apiSecret, siteId) => ({
        type: actionTypes.CREATE_INTEGRATION_DORMAKABA_REQUEST,
        payload: { profileId, apiKey, apiSecret, siteId },
    }),

    getDormakabaComponents: (id, page, perPage) => ({
        type: actionTypes.GET_DORMAKABA_COMPONENTS_REQUEST,
        payload: { id, page, perPage },
    }),

    manageDormakabaComponentService: (component, componentService) => ({
        type: actionTypes.MANAGE_DORMAKABA_COMPONENT_SERVICE,
        payload: { component, componentService },
    }),

    cancelManageDormakabaComponentService: () => ({
        type: actionTypes.MANAGE_DORMAKABA_COMPONENT_CANCEL,
        payload: {},
    }),

    createDormakabaComponentService: (
        integrationId,
        dormakabaComponentId,
        serviceId,
        prefixMinutes,
        postfixMinutes,
        messageToCustomer
    ) => ({
        type: actionTypes.CREATE_DORMAKABA_COMPONENT_SERVICE_REQUEST,
        payload: { integrationId, dormakabaComponentId, serviceId, prefixMinutes, postfixMinutes, messageToCustomer },
    }),

    updateDormakabaComponentService: (
        integrationId,
        originalComponentService,
        serviceId,
        prefixMinutes,
        postfixMinutes,
        messageToCustomer
    ) => ({
        type: actionTypes.UPDATE_DORMAKABA_COMPONENT_SERVICE_REQUEST,
        payload: {
            integrationId,
            originalComponentService,
            serviceId,
            prefixMinutes,
            postfixMinutes,
            messageToCustomer,
        },
    }),

    deleteDormakabaComponentService: (integrationId, componentServiceId) => ({
        type: actionTypes.DELETE_DORMAKABA_COMPONENT_SERVICE_REQUEST,
        payload: { integrationId, componentServiceId },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_INTEGRATION_DORMAKABA_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getIntegration("dormakaba", payload.id);

            yield put({
                type: actionTypes.GET_INTEGRATION_DORMAKABA_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_INTEGRATION_DORMAKABA_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createDormakabaIntegration(
                payload.profileId,
                payload.apiKey,
                payload.apiSecret,
                payload.siteId
            );

            yield put({
                type: actionTypes.CREATE_INTEGRATION_DORMAKABA_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_DORMAKABA_COMPONENTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getDormakabaComponents(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_DORMAKABA_COMPONENTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_DORMAKABA_COMPONENT_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createDormakabaComponentService(
                payload.integrationId,
                payload.dormakabaComponentId,
                payload.serviceId,
                payload.prefixMinutes ? payload.prefixMinutes * 60 : null,
                payload.postfixMinutes ? payload.postfixMinutes * 60 : null,
                payload.messageToCustomer
            );

            yield put({
                type: actionTypes.CREATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_DORMAKABA_COMPONENT_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchDormakabaComponentService(
                payload.integrationId,
                payload.originalComponentService,
                {
                    ...payload.originalComponentService,
                    serviceId: payload.serviceId || payload.originalComponentService.serviceId,
                    prefixSeconds: payload.prefixMinutes ? payload.prefixMinutes * 60 : null,
                    postfixSeconds: payload.postfixMinutes ? payload.postfixMinutes * 60 : null,
                    messageToCustomer: payload.messageToCustomer,
                }
            );
            yield put({
                type: actionTypes.UPDATE_DORMAKABA_COMPONENT_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_DORMAKABA_COMPONENT_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteDormakabaComponentService(
                payload.integrationId,
                payload.componentServiceId
            );
            yield put({
                type: actionTypes.DELETE_DORMAKABA_COMPONENT_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
