import { put, takeLatest } from "redux-saga/effects";
import {
    getProfilePaymentTemplates,
    getPaymentTemplate,
    addProfilePaymentTemplate,
    updatePaymentTemplate,
    deletePaymentTemplate,
    getPaymentTemplateOptions,
} from "./paymentTemplateCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_PROFILE_PAYMENT_TEMPLATES_REQUEST: "GET_PROFILE_PAYMENT_TEMPLATES_REQUEST",
    GET_PROFILE_PAYMENT_TEMPLATES_SUCCESS: "GET_PROFILE_PAYMENT_TEMPLATES_SUCCESS",
    GET_PAYMENT_TEMPLATE_REQUEST: "GET_PAYMENT_TEMPLATE_REQUEST",
    GET_PAYMENT_TEMPLATE_SUCCESS: "GET_PAYMENT_TEMPLATE_SUCCESS",
    ADD_PROFILE_PAYMENT_TEMPLATE_REQUEST: "ADD_PROFILE_PAYMENT_TEMPLATE_REQUEST",
    ADD_PROFILE_PAYMENT_TEMPLATE_SUCCESS: "ADD_PROFILE_PAYMENT_TEMPLATE_SUCCESS",
    UPDATE_PAYMENT_TEMPLATE_REQUEST: "UPDATE_PAYMENT_TEMPLATE_REQUEST",
    UPDATE_PAYMENT_TEMPLATE_SUCCESS: "UPDATE_PAYMENT_TEMPLATE_SUCCESS",
    DELETE_PAYMENT_TEMPLATE_REQUEST: "DELETE_PAYMENT_TEMPLATE_REQUEST",
    DELETE_PAYMENT_TEMPLATE_SUCCESS: "DELETE_PAYMENT_TEMPLATE_SUCCESS",
    GET_PAYMENT_TEMPLATE_OPTIONS_REQUEST: "GET_PAYMENT_TEMPLATE_OPTIONS_REQUEST",
    GET_PAYMENT_TEMPLATE_OPTIONS_SUCCESS: "GET_PAYMENT_TEMPLATE_OPTIONS_SUCCESS",
    CLEAR_PAYMENT_TEMPLATES_STATE: "CLEAR_PAYMENT_TEMPLATES_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    paymentTemplateOptions: null,
    listPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_PAYMENT_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PROFILE_PAYMENT_TEMPLATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_PAYMENT_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYMENT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentTemplate: action.payload.response,
            };
        }

        case actionTypes.ADD_PROFILE_PAYMENT_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.ADD_PROFILE_PAYMENT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentTemplate: action.payload.response,
            };
        }

        case actionTypes.UPDATE_PAYMENT_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_PAYMENT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentTemplate: action.payload.response,
            };
        }

        case actionTypes.DELETE_PAYMENT_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_PAYMENT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentTemplate: action.payload.response,
            };
        }

        case actionTypes.GET_PAYMENT_TEMPLATE_OPTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_PAYMENT_TEMPLATE_OPTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paymentTemplateOptions: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_PAYMENT_TEMPLATES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getProfilePaymentTemplates: (profileId, page, perPage) => ({
        type: actionTypes.GET_PROFILE_PAYMENT_TEMPLATES_REQUEST,
        payload: { profileId, page, perPage },
    }),

    getPaymentTemplate: (id) => ({
        type: actionTypes.GET_PAYMENT_TEMPLATE_REQUEST,
        payload: { id },
    }),

    addProfilePaymentTemplate: (profileId, data, callback) => ({
        type: actionTypes.ADD_PROFILE_PAYMENT_TEMPLATE_REQUEST,
        payload: { profileId, data, callback },
    }),

    updatePaymentTemplate: (id, originalPaymentTemplate, updatedPaymentTemplate) => ({
        type: actionTypes.UPDATE_PAYMENT_TEMPLATE_REQUEST,
        payload: { id, originalPaymentTemplate, updatedPaymentTemplate },
    }),

    deletePaymentTemplate: (id) => ({
        type: actionTypes.DELETE_PAYMENT_TEMPLATE_REQUEST,
        payload: { id },
    }),

    getPaymentTemplateOptions: (profileId) => ({
        type: actionTypes.GET_PAYMENT_TEMPLATE_OPTIONS_REQUEST,
        payload: { profileId },
    }),

    clearPaymentTemplateState: () => ({
        type: actionTypes.CLEAR_PAYMENT_TEMPLATES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_PROFILE_PAYMENT_TEMPLATES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getProfilePaymentTemplates(
                payload.profileId,
                payload.page,
                payload.perPage
            );
            yield put({
                type: actionTypes.GET_PROFILE_PAYMENT_TEMPLATES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PAYMENT_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPaymentTemplate(payload.id);
            yield put({
                type: actionTypes.GET_PAYMENT_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_PROFILE_PAYMENT_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addProfilePaymentTemplate(payload.profileId, payload.data);
            yield put({
                type: actionTypes.ADD_PROFILE_PAYMENT_TEMPLATE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback(response);
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_PAYMENT_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updatePaymentTemplate(payload.id, payload.originalPaymentTemplate, {
                ...payload.originalPaymentTemplate,
                ...payload.updatedPaymentTemplate,
            });
            yield put({
                type: actionTypes.UPDATE_PAYMENT_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_PAYMENT_TEMPLATE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deletePaymentTemplate(payload.id);
            yield put({
                type: actionTypes.DELETE_PAYMENT_TEMPLATE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_PAYMENT_TEMPLATE_OPTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getPaymentTemplateOptions(payload.profileId);
            yield put({
                type: actionTypes.GET_PAYMENT_TEMPLATE_OPTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
