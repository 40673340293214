import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import * as transactionUtils from "../../../../pages/transactions/utils";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function TransactionStatusFilter({ onStatusChanged, defaultStatus }) {
    const classes = useStyles();
    const [status, setStatus] = useState(defaultStatus || "all");

    const handleChange = ({ target: { value } }) => {
        setStatus(value);
        onStatusChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">
                <FormattedMessage id="PAYMENT.FILTER.STATUS.TITLE" />
            </InputLabel>
            <Select labelId="status-filter-label" id="status-filter" value={status} onChange={handleChange}>
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={transactionUtils.STATUS_STARTED}>
                    <FormattedMessage id="PAYMENT.FILTER.STATUS.STARTED" />
                </MenuItem>
                <MenuItem value={transactionUtils.STATUS_CANCELLED}>
                    <FormattedMessage id="PAYMENT.FILTER.STATUS.CANCELLED" />
                </MenuItem>
                <MenuItem value={transactionUtils.STATUS_FAILED}>
                    <FormattedMessage id="PAYMENT.FILTER.STATUS.FAILED" />
                </MenuItem>
                <MenuItem value={transactionUtils.STATUS_DELETED}>
                    <FormattedMessage id="PAYMENT.FILTER.STATUS.DELETED" />
                </MenuItem>
                <MenuItem value={transactionUtils.STATUS_SUCCESS}>
                    <FormattedMessage id="PAYMENT.FILTER.STATUS.SUCCESS" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default TransactionStatusFilter;
