import React, { useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import { actions as bookingManagerActions } from "../../../../redux/bookings/bookingManagerRedux";
import { actions as resourceActions } from "../../../../redux/resources/resourceRedux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "./fastoffice.css";
import { ENTRY_TYPE_APPOINTMENT, ENTRY_TYPE_COURSE_OCCASION } from "../entryUtils";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        height: "100vh",
        backgroundColor: "#445C3E",
        flexDirection: "column",
        overflowX: "hidden",
        fontFamily: "FuturaEF Medium",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: "32px",
        backgroundColor: "#445C3E",
        flexGrow: 1,
        minHeight: 0,
    },
    topContainer: {
        display: "flex",
        marginBottom: "32px",
    },
    topLeftContainer: {
        flex: 1,
    },
    topMiddleContainer: {},
    topRightContainer: {
        display: "flex",
        justifyContent: "end",
        flex: 1,
    },
    resourceImage: {
        height: "120px",
        width: "120px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
    },
    resourceTitle: {
        textAlign: "center",
        marginTop: "32px",
        fontSize: "32px",
        color: "white",
    },
    customResourceAvatarContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    customResourceAvatarTitle: {
        fontSize: "12px",
        color: "white",
        marginTop: "4px",
    },
    bookingsListTitleContainer: {
        fontSize: "30px",
        color: "white",
        marginBottom: "16px",
    },
    bookingsListContainer: {
        overflow: "hidden",
        overflowY: "scroll",

        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    bookingsListItemContainer: { display: "flex", padding: "8px" },
    bookingsListItemTime: { fontSize: "30px", color: "white", minWidth: "225px" },
    bookingsListItemName: { fontSize: "30px", color: "white" },
    patternContainer: { display: "flex", height: "200px" },
    patternImage: { marginRight: "12px" },
    errorLabel: {
        fontSize: "30px",
        color: "red",
    },
}));

function FastOfficeKioskPage({ location, getEntries, getResource }) {
    const { entries } = useSelector((state) => state.entries);
    const { resource } = useSelector((state) => state.resources);
    const { displayError } = useSelector((state) => state.errors);

    const classes = useStyles();
    const patternImageUrl = toAbsoluteUrl("/media/logos/fastoffice_pattern_gold.png");

    const queryParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);
    const profileId = queryParams.get("profileId");
    const hasError = !profileId || (displayError ? true : false);

    const resourceIds = useMemo(() => {
        let queryResourceIds = [];
        if (queryParams.has("resourceId")) {
            queryResourceIds.push(parseInt(queryParams.get("resourceId"), 10));
        } else if (queryParams.has("rIds")) {
            queryResourceIds = queryParams.getAll("rIds").map((x) => parseInt(x, 10));
        }
        return queryResourceIds;
    }, [queryParams]);

    const fetchResourceDetailsId = resourceIds.length === 1 ? resourceIds[0] : null;
    useEffect(() => {
        if (fetchResourceDetailsId) {
            getResource(null, fetchResourceDetailsId);
        }
    }, [fetchResourceDetailsId, getResource]);

    const fetchEntries = useCallback(() => {
        const startTime = moment();
        const endTime = moment().endOf("day");
        let filterData = {};
        if (resourceIds.length > 0) {
            filterData.resourceIds = resourceIds;
        }
        getEntries(profileId, startTime.format(), endTime.format(), filterData);
    }, [getEntries, profileId, resourceIds]);

    useEffect(() => {
        if (!hasError) {
            // Initial fetch
            fetchEntries();
        }

        // Fetch entry interval every 3rd minute
        const fetchEntriesIntervalId = setInterval(() => {
            if (hasError) return;
            fetchEntries();
        }, 180000);

        // Reload page interval every 6th hour
        const reloadPageTimeoutId = setTimeout(() => {
            window.location.reload(true);
        }, 21600000);

        return () => {
            clearInterval(fetchEntriesIntervalId);
            clearTimeout(reloadPageTimeoutId);
        };
    }, [fetchEntries, hasError]);

    const customResourceAvatar =
        resourceIds.length === 1 && resource && resource.avatarUrl && !resource.avatarUrl.includes("/misc")
            ? resource.avatarUrl
            : null;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.topContainer}>
                    <div className={classes.topLeftContainer}></div>

                    <div className={classes.topMiddleContainer}>
                        <SVG src={toAbsoluteUrl("/media/logos/fastoffice_logo_gold.svg")} />

                        {resourceIds.length === 1 && resource && (
                            <div className={classes.resourceTitle}>{resource.name}</div>
                        )}
                    </div>

                    <div className={classes.topRightContainer}>
                        {customResourceAvatar && (
                            <div className={classes.customResourceAvatarContainer}>
                                <div
                                    className={classes.resourceImage}
                                    style={{
                                        backgroundImage: `url(${resource.avatarUrl})`,
                                    }}
                                ></div>
                                <div className={classes.customResourceAvatarTitle}>Scanna för att boka</div>
                            </div>
                        )}
                    </div>
                </div>

                {!hasError && (
                    <>
                        <div className={classes.bookingsListTitleContainer}>{`Bokningar ${moment().format(
                            "dddd"
                        )} ${moment().format("D")} ${moment().format("MMMM")}`}</div>
                        <div className={classes.bookingsListContainer}>
                            {entries.map((entry) => {
                                const entryStartTime = moment(entry.startTime);
                                const entryEndTime = moment(entry.endTime);

                                let title = "";
                                if (entry.type === ENTRY_TYPE_APPOINTMENT) {
                                    if (entry.customer) {
                                        title = `${entry.customer.firstName} ${entry.customer.surname}`;
                                    }
                                } else if (entry.type === ENTRY_TYPE_COURSE_OCCASION) {
                                    if (entry.service) {
                                        title = `${entry.service.name}`;
                                    }
                                }

                                return (
                                    <div key={entry.id} className={classes.bookingsListItemContainer}>
                                        <div className={classes.bookingsListItemTime}>{`${entryStartTime.format(
                                            "HH:mm"
                                        )} - ${entryEndTime.format("HH:mm")}`}</div>
                                        <div className={classes.bookingsListItemName}>{title}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                {hasError && (
                    <div className={classes.errorLabel}>
                        Kan inte visa bokningar pga fel inställning, vänligen uppdatera
                    </div>
                )}
            </div>
            <div className={classes.patternContainer}>
                <img src={patternImageUrl} className={classes.patternImage} alt=""></img>
                <img src={patternImageUrl} className={classes.patternImage} alt=""></img>
                <img src={patternImageUrl} className={classes.patternImage} alt=""></img>
                <img src={patternImageUrl} className={classes.patternImage} alt=""></img>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, { ...bookingManagerActions, ...resourceActions })(FastOfficeKioskPage));
