import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        position: "absolute",
        left: "50%",
        maxWidth: "400px",
        textAlign: "center",
    },
    text: {
        position: "relative",
        left: "-50%",
    },
}));

export default function MaterialEmptyMessage({ message }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography className={classes.text} variant="body1">
                {message || <FormattedMessage id="COMMON.LIST.EMPTY_MESSAGE" />}
            </Typography>
        </div>
    );
}
