/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import AsideMenuListAdminfrom from "./AsideMenuListAdmin";
import AsideMenuListStaff from "./AsideMenuListStaff";
import AsideMenuListBooking from "./AsideMenuListBooking";
import {
    isAtLeastAdminPermission,
    PERMISSION_STAFF_ID,
    PERMISSION_BOOKINGS_ID,
    PERMISSION_BOOKINGS_READONLY_ID,
} from "../../../../../app/pages/resources/utils";

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();

    const { profile } = useSelector((state) => state.auth);
    const permissionId = profile.permissionId;

    if (isAtLeastAdminPermission(permissionId)) {
        return <AsideMenuListAdminfrom layoutProps={layoutProps} location={location} />;
    } else if (permissionId === PERMISSION_STAFF_ID) {
        return <AsideMenuListStaff layoutProps={layoutProps} location={location} />;
    } else if (permissionId === PERMISSION_BOOKINGS_ID || permissionId === PERMISSION_BOOKINGS_READONLY_ID) {
        return <AsideMenuListBooking layoutProps={layoutProps} location={location} />;
    }

    return <></>;
}
