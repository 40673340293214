import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import MaterialPagination from "./MaterialPagination";
import MaterialSpinner from "./MaterialSpinner";
import MaterialEmptyMessage from "./MaterialEmptyMessage";
import MaterialErrorMessage from "./MaterialErrorMessage";
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
} from "@material-ui/core";

import { useTable, usePagination } from "react-table";

const useStyles = makeStyles(() => ({
    wrapper: {
        position: (props) => (props.position ? props.position : "relative"),
        overflowX: (props) => (props.overflowX ? props.overflowX : "auto"),
        whiteSpace: (props) => (props.whiteSpace ? props.whiteSpace : "nowrap"),
    },
    row: {
        "&:hover": {
            background: "rgba(0, 0, 0, 0.04)",
        },
    },
}));

const defaultPropGetter = () => ({});

const DEFAULT_PAGE_SIZE = 20;

export default function MaterialReactTable({
    columns,
    data,
    fetchData,
    pageSize: controlledPageSize,
    rowCount,
    isLoading,
    error,
    renderToolbar,
    showHeader = true,
    showFooter = true,
    emptyMessage,
    tableWrapperProps,
    getCustomTableProps = defaultPropGetter,
    getCustomCellProps = defaultPropGetter,
}) {
    const classes = useStyles(tableWrapperProps);
    const intl = useIntl();

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: controlledPageSize ?? DEFAULT_PAGE_SIZE },
            manualPagination: true,
            pageCount: Math.max(0, Math.ceil(rowCount / controlledPageSize || DEFAULT_PAGE_SIZE)),
        },
        usePagination
    );

    useEffect(() => {
        fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
    };

    const createEmptyRow = (childComponent) => {
        return (
            <TableRow>
                <TableCell style={{ border: "none" }}>{childComponent}</TableCell>
            </TableRow>
        );
    };

    let rowsPerPageOptions = [20, 50, 100];
    if (pageSize < rowsPerPageOptions[0]) {
        // If pagesize is smaller than current smallest option, add it first
        rowsPerPageOptions.unshift(pageSize);
    }

    return (
        <div className={classes.wrapper}>
            {renderToolbar ? renderToolbar() : null}
            <Table
                {...getTableProps([
                    {
                        style: { minHeight: data.length < 1 ? "200px" : null },
                    },
                    getCustomTableProps(),
                ])}
            >
                {showHeader && (
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                width: column.width,
                                                maxWidth: column.maxWidth,
                                                textAlign: column.textAlign,
                                            },
                                        })}
                                    >
                                        {column.render("Header")}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                )}
                <TableBody>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps()} className={classes.row}>
                                {row.cells.map((cell) => {
                                    const globalCustomCellProps = getCustomCellProps(cell);
                                    const mergedCellProps = {
                                        ...globalCustomCellProps,
                                        style: {
                                            ...globalCustomCellProps.style,
                                            ...{
                                                minWidth: cell.column.minWidth,
                                                width: cell.column.width,
                                                maxWidth: cell.column.maxWidth,
                                                textAlign: cell.column.textAlign,
                                            },
                                        },
                                    };
                                    return (
                                        <TableCell {...cell.getCellProps(mergedCellProps)}>
                                            {cell.render("Cell")}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                    {data.length < 1 && isLoading && createEmptyRow(<MaterialSpinner />)}
                    {data.length < 1 &&
                        !isLoading &&
                        !error &&
                        createEmptyRow(<MaterialEmptyMessage message={emptyMessage} />)}
                    {data.length < 1 && !isLoading && error && createEmptyRow(<MaterialErrorMessage error={error} />)}
                </TableBody>
                {showFooter && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={rowCount}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": intl.formatMessage({
                                            id: "COMMON.LIST.ROWS.PER_PAGE",
                                        }),
                                    },
                                    native: false,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={(props) => <MaterialPagination {...props} />}
                                labelRowsPerPage={intl.formatMessage({
                                    id: "COMMON.LIST.ROWS.PER_PAGE",
                                })}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return (
                                        from +
                                        "-" +
                                        to +
                                        " " +
                                        intl.formatMessage({ id: "COMMON.LIST.ROWS.DISPLAYED" }) +
                                        " " +
                                        count
                                    );
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
            {isLoading && <MaterialSpinner />}
            {error && data.length > 0 && <MaterialErrorMessage error={error} />}
        </div>
    );
}
