import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

function AddCustomerButton() {
  return (
    <Link to="/customers/create">
      <Button className="bg-primary text-light">
        <Typography variant="button">
          <FormattedMessage id="CUSTOMER.LIST.CREATE_BUTTON" />
        </Typography>
      </Button>
    </Link>
  );
}

export default AddCustomerButton;
