import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as campaignActions } from "../../../redux/campaigns/campaignRedux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import * as campaignUtils from "./utils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    formContent: {
        padding: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    filter: yup
        .object()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .nullable(),
});

function getCampaignFiltersOptions(campaignFilters, intl) {
    let options = [];
    if (!campaignFilters?.length) return options;
    campaignFilters.forEach((filter) => {
        options.push(getCampaignFilterOption(filter, intl));
    });
    return options;
}

function getCampaignFilterOption(filter, intl) {
    return {
        id: filter.type,
        name: campaignUtils.getCampaignFilterNameFromType(filter.type, intl),
        type: filter.type,
        dataQueries: filter.dataQueries,
    };
}

function CampaignRecipientFilterModal({ show, onSaveClicked, onCloseClicked, getCampaignAvailableFilters }) {
    const { isLoading, isUpdating, campaignAvailableFilters } = useSelector((state) => state.campaigns);
    const classes = useStyles();
    const intl = useIntl();

    const [onSaveHasBeenClicked, setOnSaveHasBeenClicked] = useState(false);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit, watch, errors, control, setValue } = formMethods;

    useEffect(() => {
        getCampaignAvailableFilters();
    }, [getCampaignAvailableFilters]);

    const onFilterChanged = (filters) => {
        if (filters && filters.length > 0) {
            setValue("filter", filters[0]);
        } else {
            setValue("filter", null);
        }
    };

    const onFormSubmit = (formValues) => {
        setOnSaveHasBeenClicked(true);

        var data = {
            type: formValues.filter.id,
        };

        if (filter.dataQueries && filter.dataQueries.length > 0) {
            let mandatoryValueMissing = false;
            filter.dataQueries.forEach((dataQuery) => {
                if (!dataQuery.values) return;

                dataQuery.values.forEach((filterValue) => {
                    if (formValues[filterValue.property]) {
                        if (filterValue.type === "datetime") {
                            data[filterValue.property] = moment(formValues[filterValue.property]).format();
                        } else {
                            data[filterValue.property] = formValues[filterValue.property];
                        }
                    } else if (filterValue.mandatory) {
                        mandatoryValueMissing = true;
                    }
                });
            });

            if (mandatoryValueMissing) return;
        }

        onSaveClicked(data);
    };

    const filter = watch("filter");

    return (
        <Modal size="lg" show={show} onHide={onCloseClicked}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.MODAL.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormProvider {...formMethods}>
                    <Form>
                        <div className={classes.formContent}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.MODAL.CHOOSE_FILTER" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="filter"
                                    defaultValue={null}
                                    render={() => (
                                        <div>
                                            <AsyncTypeahead
                                                id="typeahead-filters"
                                                labelKey={"name"}
                                                minLength={0}
                                                clearButton={true}
                                                onChange={onFilterChanged}
                                                onSearch={() => {}}
                                                onInputChange={(input) => {}}
                                                useCache={false}
                                                isLoading={isLoading}
                                                options={getCampaignFiltersOptions(campaignAvailableFilters, intl)}
                                                selected={filter ? [filter] : []}
                                                filterBy={() => true}
                                                isInvalid={!!errors.filter}
                                                inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                                            />
                                            {errors.filter?.message && (
                                                <div
                                                    className="text-danger"
                                                    style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}
                                                >
                                                    {errors.filter.message}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </Form.Group>
                            {getFilterComponent(filter, onSaveHasBeenClicked)}
                        </div>
                    </Form>
                </FormProvider>
            </Modal.Body>
            <Modal.Footer>
                <BootstrapCancelButton onClick={onCloseClicked} />
                <BootstrapSaveButton
                    isLoading={isUpdating}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="COMMON.ADD" />}
                />
            </Modal.Footer>
        </Modal>
    );
}

function getFilterComponent(filter, onSaveHasBeenClicked) {
    if (!filter) return <></>;

    return filter.dataQueries.map((dataQuery) => {
        return (
            <div key={`filter_${filter.type}_${dataQuery.type}`}>
                {campaignUtils.getFilterPart(filter, dataQuery, onSaveHasBeenClicked)}
            </div>
        );
    });
}

export default connect(null, { ...campaignActions })(CampaignRecipientFilterModal);
