import React from "react";
import { makeStyles } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import ToolbarSearchField from "./ToolbarSearchField";
import BookingListFilter from "./filter/BookingListFilter";
import BookingPaymentTypeFilter from "./filter/BookingPaymentTypeFilter";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: theme.spacing(1),
    },
    searchAndFilterContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        "& > div": { margin: theme.spacing(1) },
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
}));

function BookingListToolbar({ onSearchChanged, onStatusChanged, onPaymentTypeChanged, onAddBookingClicked }) {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.searchAndFilterContainer}>
                <ToolbarSearchField onSearchChanged={onSearchChanged} />
                <BookingListFilter onStatusChanged={onStatusChanged} />
                <BookingPaymentTypeFilter onPaymentTypeChanged={onPaymentTypeChanged} />
            </div>
            {onAddBookingClicked && (
                <div className={classes.buttonContainer}>
                    <Button variant="outline-primary" onClick={() => onAddBookingClicked()}>
                        <FormattedMessage id="BOOKING.ADD" />
                    </Button>
                </div>
            )}
        </Toolbar>
    );
}

export default BookingListToolbar;
