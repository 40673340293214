import React from "react";
import { actions as payoutActions } from "../../../redux/payouts/payoutsRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";

function PayoutPageDetails({ downloadPayoutDocument }) {
    const { payout, isDownloadingDocument } = useSelector((state) => state.payouts);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYOUT.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYOUT.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <BootstrapSaveButton
                            isLoading={isDownloadingDocument}
                            onClick={() => downloadPayoutDocument(payout.id, `Payout ${payout.id}`)}
                            label={<FormattedMessage id="COMMON.DOWNLOAD" />}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYOUT.DETAILS.PAYOUT_AMOUNT_GROSS" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={payout ? `${payout.grossAmount} ${payout.currency}` : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYOUT.DETAILS.PAYOUT_FESS" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={payout ? `${payout.feeAmount} ${payout.currency}` : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYOUT.DETAILS.PAYOUT_AMOUNT_CARD" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={payout ? `${payout.payoutAmountCard} ${payout.currency}` : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYOUT.DETAILS.PAYOUT_AMOUNT_SWISH" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={payout ? `${payout.payoutAmountSwish} ${payout.currency}` : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-form-label">
                                <FormattedMessage id="PAYOUT.DETAILS.PAYOUT_AMOUNT_TOTAL" />
                            </label>
                            <div className="col-lg-12 col-xl-4">
                                <div>
                                    <Form.Control
                                        type="text"
                                        className="form-control-lg"
                                        readOnly
                                        value={payout ? `${payout.payoutAmountTotal} ${payout.currency}` : ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, payoutActions)(PayoutPageDetails);
