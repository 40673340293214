import React from "react";
import { actions as campaignActions } from "../../../../redux/campaigns/campaignRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import CampaignSMSCommonDetailsForm from "./CampaignSMSCommonDetailsForm";
import { isCampaignUpdateable } from "../utils";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    title: yup
        .string()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(11, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 11 }} />)
        .matches(/^[A-Za-z0-9]+$/, { message: <FormattedMessage id="FORM.ERROR.REGEX_MISMATCH" /> }),
    message: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function CampaignSMSPageDetails({ updateCampaign }) {
    const { campaign, isUpdating } = useSelector((state) => state.campaigns);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalCampaign = { ...campaign };

        let updatedCampaignData = { ...campaign };
        updatedCampaignData.name = values.name;
        updatedCampaignData.title = values.title;
        updatedCampaignData.message = values.message;

        updateCampaign(campaign.id, originalCampaign, updatedCampaignData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CAMPAIGN.SMS.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CAMPAIGN.SMS.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {campaign && isCampaignUpdateable(campaign.status) && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <CampaignSMSCommonDetailsForm campaign={campaign} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, campaignActions)(CampaignSMSPageDetails);
