import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const PROFILE_URL = "v1/admin/profiles/:id";

export const PROFILE_BILLING_TEMPLATES_URL = "v1/admin/profiles/:id/billing-templates";

export function getProfile(id) {
    let url = `${PROFILE_URL.replace(":id", id)}`;
    return axios.get(getApiUrl() + url);
}

export function patchProfile(id, originalProfile, changedProfile) {
    let url = PROFILE_URL.replace(":id", id);
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalProfile, changedProfile), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}
