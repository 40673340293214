import React from "react";
import { makeStyles, Drawer } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    drawer: {
        width: (props) => props.width,
        flexShrink: 0,
    },
    drawerPaper: {
        width: (props) => props.width,
    },
}));

export default function ContentDrawer({ isOpen, onDrawerToggle, children, paperProps, ...props }) {
    const classes = useStyles(props);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor={"right"}
            open={isOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
            onClose={(e) => onDrawerToggle(false)}
            style={props.style}
            {...(paperProps ? { PaperProps: paperProps } : {})}
        >
            {children}
        </Drawer>
    );
}
