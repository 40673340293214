import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { Card, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    avatarInfoCell: {
        display: "flex",
        flexDirection: "column",
    },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
        <span
            ref={ref}
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </span>
    );
});

function ResourceGroupPageNavigator({ resourceGroupId, deleteResourceGroup }) {
    const { resourceGroup } = useSelector((state) => state.resources);
    const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();

    const onDeleteResourceGroupClicked = (e) => {
        e.preventDefault();
        setMenuDropdownOpen(false);
        setShowDeleteConfirmation(true);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            deleteResourceGroup(resourceGroupId);
        }
        setShowDeleteConfirmation(false);
    };

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        <Dropdown
                            className="dropdown dropdown-inline"
                            alignRight
                            show={isMenuDropdownOpen}
                            onToggle={() => setMenuDropdownOpen(!isMenuDropdownOpen)}
                        >
                            <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                variant="transparent"
                                id="dropdown-toggle-top-user-profile"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-hor"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <ul className="navi navi-hover py-5">
                                    {!resourceGroup?.deletedAt && (
                                        <li className="navi-item" onClick={onDeleteResourceGroupClicked}>
                                            <a href="/" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-trash"></i>
                                                </span>
                                                <span className="navi-text">
                                                    <FormattedMessage id="RESOURCE.GROUP.DELETE" />
                                                </span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.avatarInfoCell}>
                        <div>
                            <span className="font-weight-bolder font-size-h6 text-dark-75">{resourceGroup?.name}</span>
                        </div>
                    </div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="RESOURCE.GROUP.CREATED_AT" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {moment(resourceGroup?.createdAt).format("YYYY-MM-DD HH:mm")}
                            </span>
                        </div>
                        {resourceGroup?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="RESOURCE.GROUP.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(resourceGroup?.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/resource-groups/${resourceGroupId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="RESOURCE.GROUP.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/resource-groups/${resourceGroupId}/resource-list`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="RESOURCE.GROUP.RESOURCES.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/resource-groups/${resourceGroupId}/hours`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Door-open.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="RESOURCE.GROUP.HOURS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/resource-groups/${resourceGroupId}/media`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Pictures1.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="RESOURCE.MEDIA.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="RESOURCE.GROUP.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={onDeleteDialogActionClicked}
            />
        </div>
    );
}

export default connect(null, resourceActions)(ResourceGroupPageNavigator);
