import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, Avatar, Switch, FormControlLabel, IconButton, Typography } from "@material-ui/core";
import { actions as bookingManagerActions } from "../../../redux/bookings/bookingManagerRedux";
import { Form, InputGroup, FormControl, Col } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FormattedMessage, useIntl } from "react-intl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/sv";
import MomentUtils from "@date-io/moment";
import {
    PAYMENT_MODE_OPTIONAL,
    PAYMENT_MODE_MANDATORY,
    APPOINTMENT_DURATION_TYPE_FIXED,
    APPOINTMENT_DURATION_TYPE_FLEXIBLE,
} from "../services/utils";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    listItemAvatarContainer: {
        display: "flex",
        alignItems: "center",
    },
    listItemAvatar: {
        width: "32px",
        height: "32px",
        marginRight: theme.spacing(1),
    },
    listItemAddonContainer: {
        display: "flex",
        border: "1px solid #E4E6EF",
        borderRadius: "0.42rem",
        backgroundColor: "#ffffff",
        marginBottom: "1.5rem",
        padding: "12px",
        alignItems: "center",
    },
    listItemAddonName: {
        flexGrow: 1,
        color: "#3F4254",
        fontSize: "1.25rem",
    },
    listItemAddonQuantityButton: {
        padding: "0px",
        color: "#3F4254",
    },
    listItemAddonQuantityLabel: {
        color: "#3F4254",
        width: "30px",
        fontSize: "1.25rem",
        textAlign: "center",
    },
    listItemAddonRemoveButton: {
        color: "red",
        padding: "0px",
        marginLeft: "16px",
    },
}));

function getServiceResourceOptions(serviceResourcePagination) {
    let options = [];
    if (!serviceResourcePagination?.data?.length) return options;
    serviceResourcePagination.data.forEach((serviceResource) => {
        options.push(getServiceResourceOption(serviceResource));
    });
    return options;
}

function getServiceResourceOption(serviceResource) {
    return {
        id: serviceResource.resource.id,
        name: serviceResource.resource.name,
        avatarUrl: serviceResource.resource.avatarUrl,
    };
}

function getServiceAddonOptions(serviceAddonPagination) {
    let options = [];
    if (!serviceAddonPagination?.data?.length) return options;
    serviceAddonPagination.data.forEach((serviceAddon) => {
        options.push(getServiceAddonOption(serviceAddon));
    });
    return options;
}

function getServiceAddonOption(serviceAddon) {
    return {
        id: serviceAddon.id,
        name: serviceAddon.service.name,
        quantity: 1,
        price: serviceAddon.service.price,
        currency: serviceAddon.service.currency,
    };
}

function getNearestDefaultStartTime(service) {
    const defaultStartTime = moment();
    if (service.checkinTime) {
        const times = service.checkinTime.split(":");
        defaultStartTime.set("hour", times[0]);
        defaultStartTime.set("minute", times[1]);
        return defaultStartTime;
    }

    return defaultStartTime.add(1, "hour").startOf("hour");
}

function getNearestDefaultEndTime(service) {
    const defaultEndTime = getNearestDefaultStartTime(service);

    if (service.duration) {
        return defaultEndTime.add(service.duration, "minutes");
    } else if (service.checkoutTime) {
        const times = service.checkoutTime.split(":");
        defaultEndTime.add(1, "day");
        defaultEndTime.set("hour", times[0]);
        defaultEndTime.set("minute", times[1]);
        return defaultEndTime;
    }

    return defaultEndTime.add(1, "hour").startOf("hour");
}

function arePickedTimesValid(startTime, endTime) {
    return startTime.isBefore(endTime);
}

function DrawerAddBookingAppointment({
    getNewBookingServiceResources,
    getNewBookingServiceAddons,
    isResourceAvailable,
    selectedService,
    selectedResource,
    setSelectedResource,
    pickedStartTime,
    setPickedStartTime,
    pickedEndTime,
    setPickedEndTime,
    pickedRepeatUntilTime,
    setPickedRepeatUntilTime,
    isShowUntilCancellationEndTime,
    setIsShowUntilCancellationEndTime,
    addDefaultResourceIfPossible,
    setAddDefaultResourceIfPossible,
    selectedAddons,
    setSelectedAddons,
    notifyUser,
    setNotifyUser,
    isMarkedAsPaid,
    setIsMarkedAsPaid,
}) {
    const classes = useStyles();
    const intl = useIntl();

    const {
        newBookingServiceResourcesPagination,
        newBookingServiceResourcesLoading,
        newBookingServiceAddonsPagination,
        newBookingServiceAddonsLoading,
        resourceConflictingBooking,
    } = useSelector((state) => state.bookingManager);

    const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
    const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
    const [isRepeatUntilPickerOpen, setIsRepeatUntilPickerOpen] = useState(false);

    const onResourceChanged = useCallback(
        (resources) => {
            if (resources && resources.length > 0) {
                const resource = resources[0];
                setSelectedResource(resource);
                let startTime = pickedStartTime;
                let endTime = pickedEndTime;
                if (!pickedStartTime || selectedService.fixedEndTime) {
                    startTime = getNearestDefaultStartTime(selectedService);

                    if (!selectedService.fixedEndTime) {
                        endTime = getNearestDefaultEndTime(selectedService);
                    } else {
                        endTime = moment(selectedService.fixedEndTime);
                    }

                    setPickedStartTime(startTime);
                    setPickedEndTime(endTime);
                }

                isResourceAvailable(resource.id, startTime.format(), endTime.format());
            } else {
                setSelectedResource(null);
            }
        },
        [
            setSelectedResource,
            pickedStartTime,
            setPickedStartTime,
            pickedEndTime,
            setPickedEndTime,
            isResourceAvailable,
            selectedService,
        ]
    );

    useEffect(() => {
        getNewBookingServiceResources(selectedService.id);
        getNewBookingServiceAddons(selectedService.id, null, "product");
    }, [selectedService, getNewBookingServiceResources, getNewBookingServiceAddons]);

    // If service only has one resource
    const defaultResource =
        addDefaultResourceIfPossible && !selectedResource && newBookingServiceResourcesPagination?.data?.length === 1
            ? newBookingServiceResourcesPagination.data[0]
            : null;

    useEffect(() => {
        if (defaultResource) {
            setAddDefaultResourceIfPossible(false);
            onResourceChanged([getServiceResourceOption(defaultResource)]);
        }
    }, [defaultResource, setAddDefaultResourceIfPossible, onResourceChanged]);

    const onResourceSearch = (search) => {
        getNewBookingServiceResources(selectedService.id, search);
    };

    const showRepeatUntilOption = () => {
        return (
            selectedService.durationType === APPOINTMENT_DURATION_TYPE_FIXED ||
            selectedService.durationType === APPOINTMENT_DURATION_TYPE_FLEXIBLE
        );
    };

    const shouldDisableRepeatUntilDatePickerDate = (date) => {
        if (!pickedEndTime) return false;
        return date.day() !== pickedEndTime.day() || date.isBefore(pickedEndTime) || date.isSame(pickedEndTime, "day");
    };

    const hasAddons = () => {
        return newBookingServiceAddonsPagination?.data?.length > 0 || newBookingServiceAddonsPagination?.search;
    };

    const onAddonSearch = (search) => {
        getNewBookingServiceAddons(selectedService.id, search, "product");
    };

    const onAddonChanged = (addons) => {
        if (addons && addons.length > 0) {
            const addon = addons[0];
            const existingIndex = selectedAddons.findIndex((item) => item.id === addon.id);
            if (existingIndex < 0) {
                const selectedAddonsArray = [...selectedAddons];
                selectedAddonsArray.push(addon);
                setSelectedAddons(selectedAddonsArray);
            }
        }
    };

    const onAddonQuantityChanged = (addon, change) => {
        const existingIndex = selectedAddons.findIndex((item) => item.id === addon.id);
        if (existingIndex > -1) {
            let newQuantity = addon.quantity + change;
            if (newQuantity < 1) newQuantity = 1;
            const selectedAddonsArray = [...selectedAddons];
            selectedAddonsArray[existingIndex].quantity = newQuantity;
            setSelectedAddons(selectedAddonsArray);
        }
    };

    const onAddonRemoved = (addon) => {
        const existingIndex = selectedAddons.findIndex((item) => item.id === addon.id);
        if (existingIndex > -1) {
            const selectedAddonsArray = [...selectedAddons];
            selectedAddonsArray.splice(existingIndex, 1);
            setSelectedAddons(selectedAddonsArray);
        }
    };

    const getStepCountPayment = () => {
        if (showRepeatUntilOption() && hasAddons()) return 7;
        if (showRepeatUntilOption() || hasAddons()) return 6;
        return 5;
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BOOKING.ADD.RESOURCE.TITLE" />
                </Form.Label>
                <AsyncTypeahead
                    id="typeahead-resources"
                    labelKey={"name"}
                    minLength={0}
                    clearButton={true}
                    onChange={onResourceChanged}
                    onSearch={onResourceSearch}
                    onInputChange={(input) => {
                        if (!input) {
                            onResourceSearch(input);
                        }
                    }}
                    useCache={false}
                    isLoading={newBookingServiceResourcesLoading}
                    options={getServiceResourceOptions(newBookingServiceResourcesPagination)}
                    placeholder={intl.formatMessage({
                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                    })}
                    emptyLabel={intl.formatMessage({
                        id: "BOOKING.ADD.RESOURCE.SEARCH_EMPTY",
                    })}
                    renderMenuItemChildren={(option, props) => (
                        <div className={classes.listItemAvatarContainer}>
                            <Avatar alt={option.name} src={option.avatarUrl} className={classes.listItemAvatar} />
                            <span>{option.name}</span>
                        </div>
                    )}
                    selected={selectedResource ? [selectedResource] : []}
                    filterBy={() => true}
                />
            </Form.Group>

            {selectedResource && (
                <>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="BOOKING.ADD.TIME.TITLE" />
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder={intl.formatMessage({
                                    id: "BOOKING.ADD.TIME.START.PLACEHOLDER",
                                })}
                                aria-describedby="basic-addon1"
                                onClick={() => setIsStartTimePickerOpen(true)}
                                readOnly={true}
                                value={pickedStartTime.format("YYYY-MM-DD HH:mm")}
                                isInvalid={!arePickedTimesValid(pickedStartTime, pickedEndTime)}
                            />
                            <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder={intl.formatMessage({
                                    id: "BOOKING.ADD.TIME.END.PLACEHOLDER",
                                })}
                                aria-describedby="basic-addon1"
                                onClick={() => setIsEndTimePickerOpen(true)}
                                readOnly={true}
                                value={
                                    !isShowUntilCancellationEndTime
                                        ? pickedEndTime.format("YYYY-MM-DD HH:mm")
                                        : intl.formatMessage({
                                              id: "BOOKING.ADD.TIME.END.UNTIL_CANCELLATION",
                                          })
                                }
                                isInvalid={!arePickedTimesValid(pickedStartTime, pickedEndTime)}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="BOOKING.ADD.TIME.INVALID" />
                            </Form.Control.Feedback>
                        </InputGroup>

                        <div style={{ display: "none" }}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <DateTimePicker
                                    disablePast={false}
                                    showTodayButton
                                    ampm={false}
                                    style={{ display: "none" }}
                                    open={isStartTimePickerOpen}
                                    onClose={() => setIsStartTimePickerOpen(false)}
                                    onChange={(startTime) => {
                                        setPickedStartTime(startTime);
                                        let endTime = pickedEndTime;
                                        if (startTime.isAfter(endTime)) {
                                            // End time is before start time, move forward for convenience
                                            endTime = startTime.clone();
                                            if (selectedService.checkoutTime) {
                                                const times = selectedService.checkoutTime.split(":");
                                                endTime.add(1, "day");
                                                endTime.set("hour", times[0]);
                                                endTime.set("minute", times[1]);
                                            } else {
                                                const duration = selectedService.duration || 60;
                                                endTime.add(duration, "minutes");
                                            }

                                            setPickedEndTime(endTime);
                                        }

                                        isResourceAvailable(selectedResource.id, startTime.format(), endTime.format());
                                    }}
                                    value={pickedStartTime || getNearestDefaultStartTime(selectedService)}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <DateTimePicker
                                    disablePast
                                    showTodayButton
                                    ampm={false}
                                    open={isEndTimePickerOpen}
                                    onClose={() => setIsEndTimePickerOpen(false)}
                                    onChange={(endTime) => {
                                        setPickedEndTime(endTime);
                                        let startTime = pickedStartTime;
                                        if (endTime.isBefore(startTime)) {
                                            // End time is before start time, move back for convenience
                                            startTime = endTime.clone();
                                            if (selectedService.checkinTime) {
                                                const times = selectedService.checkinTime.split(":");
                                                startTime.subtract(1, "day");
                                                startTime.set("hour", times[0]);
                                                startTime.set("minute", times[1]);
                                            } else {
                                                const duration = selectedService.duration || 60;
                                                startTime.subtract(duration, "minutes");
                                            }

                                            setPickedStartTime(startTime);
                                        }

                                        setPickedRepeatUntilTime(null);
                                        setIsShowUntilCancellationEndTime(false);
                                        isResourceAvailable(selectedResource.id, startTime.format(), endTime.format());
                                    }}
                                    value={pickedEndTime || getNearestDefaultEndTime(selectedService)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </Form.Group>

                    {showRepeatUntilOption() && (
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="BOOKING.ADD.REPEAT_UNTIL.TITLE" />
                            </Form.Label>
                            <FormControl
                                placeholder={intl.formatMessage({
                                    id: "BOOKING.ADD.REPEAT_UNTIL.PLACEHOLDER",
                                })}
                                aria-describedby="basic-addon1"
                                onClick={() => setIsRepeatUntilPickerOpen(true)}
                                readOnly={true}
                                value={pickedRepeatUntilTime ? pickedRepeatUntilTime.format("YYYY-MM-DD") : ""}
                                style={{ width: "150px" }}
                            />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="BOOKING.ADD.REPEAT_UNTIL.DESCRIPTION" />
                            </Form.Text>

                            <div style={{ display: "none" }}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        disablePast={true}
                                        clearable={true}
                                        showTodayButton
                                        ampm={false}
                                        open={isRepeatUntilPickerOpen}
                                        shouldDisableDate={shouldDisableRepeatUntilDatePickerDate}
                                        onClose={() => setIsRepeatUntilPickerOpen(false)}
                                        onChange={(repeatUntil) => {
                                            setPickedRepeatUntilTime(repeatUntil);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Form.Group>
                    )}

                    {hasAddons() && (
                        <div>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage
                                        id="BOOKING.ADD.ADDON.TITLE"
                                        values={{ stepCount: showRepeatUntilOption() ? 6 : 5 }}
                                    />
                                </Form.Label>
                                <AsyncTypeahead
                                    id="typeahead-addons"
                                    labelKey={"name"}
                                    minLength={0}
                                    clearButton={true}
                                    onChange={onAddonChanged}
                                    onSearch={onAddonSearch}
                                    onInputChange={(input) => {
                                        if (!input) {
                                            onAddonSearch(input);
                                        }
                                    }}
                                    useCache={false}
                                    isLoading={newBookingServiceAddonsLoading}
                                    options={getServiceAddonOptions(newBookingServiceAddonsPagination)}
                                    placeholder={intl.formatMessage({
                                        id: "COMMON.DROPDOWN.TYPE_TO_SEARCH",
                                    })}
                                    emptyLabel={intl.formatMessage({
                                        id: "BOOKING.ADD.ADDON.SEARCH_EMPTY",
                                    })}
                                    selected={[]}
                                />
                            </Form.Group>
                            {selectedAddons.map((addon) => {
                                return (
                                    <div key={`addon_${addon.id}`} className={classes.listItemAddonContainer}>
                                        <span className={classes.listItemAddonName}>{addon.name}</span>

                                        <IconButton
                                            onClick={() => onAddonQuantityChanged(addon, -1)}
                                            className={classes.listItemAddonQuantityButton}
                                        >
                                            <RemoveCircleRoundedIcon fontSize="large" />
                                        </IconButton>

                                        <span className={classes.listItemAddonQuantityLabel}>{addon.quantity}</span>

                                        <IconButton
                                            onClick={() => onAddonQuantityChanged(addon, 1)}
                                            className={classes.listItemAddonQuantityButton}
                                        >
                                            <AddCircleRoundedIcon fontSize="large" />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => onAddonRemoved(addon)}
                                            className={classes.listItemAddonRemoveButton}
                                        >
                                            <HighlightOffRoundedIcon fontSize="large" />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage
                                id="BOOKING.ADD.OTHER.TITLE"
                                values={{ stepCount: getStepCountPayment() }}
                            />
                        </Form.Label>
                        <Form.Row>
                            <Col xs={12}>
                                <FormControlLabel
                                    control={<Switch color="primary" />}
                                    label={<FormattedMessage id="BOOKING.ADD.OTHER.NOTIFY_USER" />}
                                    labelPlacement="end"
                                    checked={notifyUser}
                                    onChange={(event, value) => setNotifyUser(value)}
                                    style={{ userSelect: "none" }}
                                />
                            </Col>
                            {(selectedService.paymentMode === PAYMENT_MODE_OPTIONAL ||
                                selectedService.paymentMode === PAYMENT_MODE_MANDATORY) && (
                                <Col xs={12}>
                                    <FormControlLabel
                                        control={<Switch color="primary" />}
                                        label={<FormattedMessage id="BOOKING.ADD.OTHER.MARK_AS_PAID" />}
                                        labelPlacement="end"
                                        checked={isMarkedAsPaid}
                                        onChange={(event, value) => setIsMarkedAsPaid(value)}
                                        style={{ userSelect: "none" }}
                                    />
                                </Col>
                            )}
                        </Form.Row>
                    </Form.Group>

                    {resourceConflictingBooking && (
                        <Form.Group>
                            <Alert severity="warning">
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="BOOKING.ADD.RESOURCE.UNAVAILABLE"
                                        values={{
                                            p: (...chunks) => <p>{chunks}</p>,
                                            strong: (...chunks) => <strong>{chunks}</strong>,
                                            br: <br />,
                                            resourceName: selectedResource.name,
                                            serviceName: resourceConflictingBooking.service.name,
                                            customerName: `${resourceConflictingBooking.customer.firstName} ${resourceConflictingBooking.customer.surname}`,
                                            time:
                                                resourceConflictingBooking.entries.length === 0
                                                    ? ""
                                                    : `${moment(resourceConflictingBooking.entries[0].startTime).format(
                                                          "YYYY-MM-DD HH:mm"
                                                      )} -> ${moment(
                                                          resourceConflictingBooking.entries[0].endTime
                                                      ).format("YYYY-MM-DD HH:mm")}`,
                                            link: (
                                                <a
                                                    href={`/bookings/${resourceConflictingBooking.id}/details`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FormattedMessage id="BOOKING.ADD.RESOURCE.UNAVAILABLE.GO_TO_BOOKING"></FormattedMessage>
                                                </a>
                                            ),
                                        }}
                                    />
                                </Typography>
                            </Alert>
                        </Form.Group>
                    )}
                </>
            )}
        </div>
    );
}

export default injectIntl(connect(null, bookingManagerActions)(DrawerAddBookingAppointment));
