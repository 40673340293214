import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as campaignActions } from "../../../../redux/campaigns/campaignRedux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import {
    getCampaignStatusCell,
    isCampaignUpdateable,
    CAMPAIGN_STATUS_DRAFT,
    CAMPAIGN_STATUS_SCHEDULED,
    CAMPAIGN_STATUS_STARTED,
    CAMPAIGN_STATUS_ERROR,
    CAMPAIGN_STATUS_FINISHED,
} from "../utils";
import NavigatorOptionsMenu from "../../../components/buttons/NavigatorOptionsMenu";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "325px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    titleContainer: {
        display: "flex",
        paddingTop: theme.spacing(1),
        alignItems: "center",
    },
}));

function CampaignSMSPageNavigator({ campaignId, deleteCampaign }) {
    const { campaign } = useSelector((state) => state.campaigns);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();

    let menuItems = [];
    if (isCampaignUpdateable(campaign?.status)) {
        menuItems.push({
            icon: <DeleteOutlinedIcon fontSize="small" />,
            label: <FormattedMessage id="COMMON.DELETE" />,
            onClick: () => setShowDeleteConfirmation(true),
        });
    }

    const isDraftOrScheduled =
        campaign && (campaign.status === CAMPAIGN_STATUS_DRAFT || campaign.status === CAMPAIGN_STATUS_SCHEDULED);
    const showPublishedPages =
        campaign &&
        (campaign.status === CAMPAIGN_STATUS_STARTED ||
            campaign.status === CAMPAIGN_STATUS_FINISHED ||
            campaign.status === CAMPAIGN_STATUS_ERROR);

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    {menuItems.length > 0 && (
                        <div className="d-flex justify-content-end">
                            <NavigatorOptionsMenu menuItems={menuItems} />
                        </div>
                    )}
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className={classes.titleContainer}>
                        {campaign && (
                            <>
                                <span
                                    className="font-weight-bolder font-size-h6 text-dark-75 flex-grow-1"
                                    style={{ marginRight: "24px" }}
                                >
                                    {campaign.name}
                                </span>
                                {getCampaignStatusCell(campaign.status)}
                            </>
                        )}
                    </div>
                    <div></div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        {campaign?.totalCost > 0 && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="CAMPAIGN.DETAILS.TOTAL_COST" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${campaign.totalCost} ${campaign.currency}`}
                                </span>
                            </div>
                        )}
                        {campaign?.createdAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="CAMPAIGN.DETAILS.CREATED_AT" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {moment(campaign.createdAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {campaign?.createdBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">
                                    <FormattedMessage id="CAMPAIGN.DETAILS.CREATED_BY" />
                                </span>
                                <span className="text-muted text-hover-primary">
                                    {`${campaign.createdBy.firstName} ${campaign.createdBy.surname}`}
                                </span>
                            </div>
                        )}
                        {campaign?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="CAMPAIGN.DETAILS.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(campaign.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {campaign?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="CAMPAIGN.DETAILS.DELETED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${campaign.deletedBy.firstName} ${campaign.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/campaigns/${campaignId}/sms/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="CAMPAIGN.SMS.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/campaigns/${campaignId}/sms/recipient-filters`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Text/filter.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.HEADER" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {isDraftOrScheduled && (
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to={`/campaigns/${campaignId}/sms/recipients-preview`}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.HEADER" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    )}
                    {isDraftOrScheduled && (
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to={`/campaigns/${campaignId}/sms/publish`}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}></SVG>{" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        <FormattedMessage id="CAMPAIGN.PUBLISH.TITLE" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    )}
                    {showPublishedPages && (
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink
                                    to={`/campaigns/${campaignId}/sms/recipients`}
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}></SVG>{" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        <FormattedMessage id="CAMPAIGN.RECIPIENTS.HEADER" />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    )}
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="CAMPAIGN.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteCampaign(campaignId);
                    }
                    setShowDeleteConfirmation(false);
                }}
            />
        </div>
    );
}

export default connect(null, campaignActions)(CampaignSMSPageNavigator);
