import React, { useState } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const ReportFilterDateInterval = ({
    startTimeName,
    endTimeName,
    defaultStartTime,
    defaultEndTime,
    title,
    showWithTime,
    isStartTimeMandatory,
    isEndTimeMandatory,
    onSaveHasBeenClicked,
    isDisabled,
}) => {
    const { register, setValue, watch } = useFormContext();

    const [createdAtStartPickerOpen, setCreatedAtStartPickerOpen] = useState(false);
    const [createdAtEndPickerOpen, setCreatedAtEndPickerOpen] = useState(false);

    const startValue = watch(startTimeName);
    const endValue = watch(endTimeName);

    return (
        <>
            <Form.Group>
                <Form.Label>{title}</Form.Label>
                <InputGroup className="mb-3">
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={startTimeName}
                        ref={register}
                        onClick={() => setCreatedAtStartPickerOpen(true)}
                        isInvalid={isStartTimeMandatory && !startValue && onSaveHasBeenClicked}
                        defaultValue={defaultStartTime}
                        disabled={isDisabled}
                    />
                    <InputGroup.Prepend style={{ marginLeft: "-1px" }}>
                        <InputGroup.Text id="basic-addon1">
                            <FormattedMessage id="BOOKING.ADD.TIME.BETWEEN_TEXT" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        className="form-control form-control-lg col-xl-3"
                        name={endTimeName}
                        ref={register}
                        onClick={() => setCreatedAtEndPickerOpen(true)}
                        style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                        isInvalid={isEndTimeMandatory && !endValue && onSaveHasBeenClicked}
                        defaultValue={defaultEndTime}
                        disabled={isDisabled}
                    />
                    {isStartTimeMandatory && isEndTimeMandatory ? (
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="FORM.ERROR.DATE_START_BEFORE_END" />
                        </Form.Control.Feedback>
                    ) : (
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />
                        </Form.Control.Feedback>
                    )}
                </InputGroup>
            </Form.Group>

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                {showWithTime && (
                    <>
                        <DateTimePicker
                            showTodayButton
                            ampm={false}
                            style={{ display: "none" }}
                            open={createdAtStartPickerOpen}
                            onClose={() => setCreatedAtStartPickerOpen(false)}
                            onChange={(selectedMomentDate) => {
                                setValue(
                                    startTimeName,
                                    selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                                    {
                                        shouldValidate: true,
                                    }
                                );
                            }}
                            clearable={true}
                        />
                        <DateTimePicker
                            showTodayButton
                            ampm={false}
                            style={{ display: "none" }}
                            open={createdAtEndPickerOpen}
                            onClose={() => setCreatedAtEndPickerOpen(false)}
                            onChange={(selectedMomentDate) => {
                                setValue(
                                    endTimeName,
                                    selectedMomentDate ? selectedMomentDate.format("YYYY-MM-DD HH:mm") : null,
                                    {
                                        shouldValidate: true,
                                    }
                                );
                            }}
                            clearable={true}
                        />
                    </>
                )}
            </MuiPickersUtilsProvider>
        </>
    );
};

export default ReportFilterDateInterval;
