import { put, takeLatest, takeEvery, select } from "redux-saga/effects";
import {
    getEntries,
    getResourceEntries,
    getEntryAppointment,
    getEntryCourseOccasion,
    getEntryCourseOccasionAttendees,
    patchEntryAppointment,
    patchEntryOccasion,
    deleteEntryAppointment,
    deleteEntryOccasion,
    addEntryOccasionResource,
    updateEntryOccasionAttendeePresence,
    deleteEntryOccasionAttendee,
    getEntryCourseWaitingListMembers,
} from "./entriesCrud";
import { actions as bookingActions } from "../bookings/bookingRedux";
import { actionTypes as serviceActionsTypes } from "../services/serviceRedux";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";
import { findAndSpliceArrayByProperty } from "../reduxUtils";

export const actionTypes = {
    GET_ENTRIES_REQUEST: "GET_ENTRIES_REQUEST",
    GET_ENTRIES_SUCCESS: "GET_ENTRIES_SUCCESS",
    GET_RESOURCE_ENTRIES_REQUEST: "GET_RESOURCE_ENTRIES_REQUEST",
    GET_RESOURCE_ENTRIES_SUCCESS: "GET_RESOURCE_ENTRIES_SUCCESS",
    GET_ENTRY_APPOINTMENT_REQUEST: "GET_ENTRY_APPOINTMENT_REQUEST",
    GET_ENTRY_APPOINTMENT_SUCCESS: "GET_ENTRY_APPOINTMENT_SUCCESS",
    GET_ENTRY_COURSE_OCCASION_REQUEST: "GET_ENTRY_COURSE_OCCASION_REQUEST",
    GET_ENTRY_COURSE_OCCASION_SUCCESS: "GET_ENTRY_COURSE_OCCASION_SUCCESS",
    GET_ENTRY_COURSE_OCCASION_ATTENDEES_REQUEST: "GET_ENTRY_COURSE_OCCASION_ATTENDEES_REQUEST",
    GET_ENTRY_COURSE_OCCASION_ATTENDEES_SUCCESS: "GET_ENTRY_COURSE_OCCASION_ATTENDEES_SUCCESS",
    GET_ENTRY_COURSE_OCCASION_WAITING_LIST_REQUEST: "GET_ENTRY_COURSE_OCCASION_WAITING_LIST_REQUEST",
    GET_ENTRY_COURSE_OCCASION_WAITING_LIST_SUCCESS: "GET_ENTRY_COURSE_OCCASION_WAITING_LIST_SUCCESS",
    UPDATE_ENTRY_APPOINTMENT_REQUEST: "UPDATE_ENTRY_APPOINTMENT_REQUEST",
    UPDATE_ENTRY_APPOINTMENT_SUCCESS: "UPDATE_ENTRY_APPOINTMENT_SUCCESS",
    DELETE_ENTRY_APPOINTMENT_REQUEST: "DELETE_ENTRY_APPOINTMENT_REQUEST",
    DELETE_ENTRY_APPOINTMENT_SUCCESS: "DELETE_ENTRY_APPOINTMENT_SUCCESS",
    UPDATE_ENTRY_OCCASION_REQUEST: "UPDATE_ENTRY_OCCASION_REQUEST",
    UPDATE_ENTRY_OCCASION_SUCCESS: "UPDATE_ENTRY_OCCASION_SUCCESS",
    DELETE_ENTRY_OCCASION_REQUEST: "DELETE_ENTRY_OCCASION_REQUEST",
    DELETE_ENTRY_OCCASION_SUCCESS: "DELETE_ENTRY_OCCASION_SUCCESS",
    ADD_ENTRY_OCCASION_RESOURCE_REQUEST: "ADD_ENTRY_OCCASION_RESOURCE_REQUEST",
    ADD_ENTRY_OCCASION_RESOURCE_SUCCESS: "ADD_ENTRY_OCCASION_RESOURCE_SUCCESS",
    UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST: "UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST",
    UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_SUCCESS: "UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_SUCCESS",
    DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST: "DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST",
    DELETE_ENTRY_OCCASION_ATTENDEE_SUCCESS: "DELETE_ENTRY_OCCASION_ATTENDEE_SUCCESS",
    SELECT_ENTRY: "SELECT_ENTRY",
    CLEAR_ENTRIES_STATE: "CLEAR_ENTRIES_STATE",
};

const initialState = {
    entry: {},
    entries: [],
    resourceEntriesPagination: {
        data: [],
        totalRows: 0,
    },
    customerAttendeesListPagination: {
        data: [],
        totalRows: 0,
    },
    staffAttendeesListPagination: {
        data: [],
        totalRows: 0,
    },
    waitingListMembersPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ENTRIES_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_ENTRIES_SUCCESS: {
            return { ...state, entries: action.payload.response };
        }

        case actionTypes.GET_RESOURCE_ENTRIES_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_RESOURCE_ENTRIES_SUCCESS: {
            return { ...state, resourceEntriesPagination: action.payload.response };
        }

        case actionTypes.GET_ENTRY_APPOINTMENT_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_ENTRY_APPOINTMENT_SUCCESS: {
            return { ...state, entry: action.payload.response };
        }

        case actionTypes.GET_ENTRY_COURSE_OCCASION_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_ENTRY_COURSE_OCCASION_SUCCESS: {
            return { ...state, entry: action.payload.response };
        }

        case actionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_SUCCESS: {
            const type = action.payload.type;
            if (type === "customer") {
                return {
                    ...state,
                    customerAttendeesListPagination: action.payload.response,
                };
            } else if (type === "staff") {
                return {
                    ...state,
                    staffAttendeesListPagination: action.payload.response,
                };
            }

            return { ...state };
        }

        case actionTypes.GET_ENTRY_COURSE_OCCASION_WAITING_LIST_REQUEST: {
            return { ...state };
        }

        case actionTypes.GET_ENTRY_COURSE_OCCASION_WAITING_LIST_SUCCESS: {
            return {
                ...state,
                waitingListMembersPagination: action.payload.response,
            };
        }

        case serviceActionsTypes.ACCEPT_WAITING_LIST_MEMBER_SUCCESS: {
            const waitingListMember = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.waitingListMembersPagination.data,
                "id",
                waitingListMember.id
            );
            return {
                ...state,
                isLoading: false,
                waitingListMembersPagination: {
                    ...state.waitingListMembersPagination,
                    data: alteredStateArray,
                    totalRows: state.waitingListMembersPagination.totalRows - 1,
                },
            };
        }

        case serviceActionsTypes.DELETE_WAITING_LIST_MEMBER_SUCCESS: {
            const removedWaitingListMember = action.payload.response;
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.waitingListMembersPagination.data,
                "id",
                removedWaitingListMember.id
            );
            return {
                ...state,
                isLoading: false,
                waitingListMembersPagination: {
                    ...state.waitingListMembersPagination,
                    data: alteredStateArray,
                    totalRows: state.waitingListMembersPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.UPDATE_ENTRY_APPOINTMENT_REQUEST: {
            return { ...state };
        }

        case actionTypes.UPDATE_ENTRY_APPOINTMENT_SUCCESS: {
            return { ...state, entry: action.payload.response };
        }

        case actionTypes.UPDATE_ENTRY_OCCASION_REQUEST: {
            return { ...state };
        }

        case actionTypes.UPDATE_ENTRY_OCCASION_SUCCESS: {
            return { ...state, entry: action.payload.response };
        }

        case actionTypes.DELETE_ENTRY_APPOINTMENT_REQUEST: {
            return { ...state };
        }

        case actionTypes.DELETE_ENTRY_APPOINTMENT_SUCCESS: {
            return { ...state, entry: action.payload.response };
        }

        case actionTypes.DELETE_ENTRY_OCCASION_REQUEST: {
            return { ...state };
        }

        case actionTypes.DELETE_ENTRY_OCCASION_SUCCESS: {
            return { ...state, entry: action.payload.response };
        }

        case actionTypes.ADD_ENTRY_OCCASION_RESOURCE_REQUEST: {
            return { ...state };
        }

        case actionTypes.ADD_ENTRY_OCCASION_RESOURCE_SUCCESS: {
            const resourceAttendee = action.payload.response;
            const resourcePagination = state.staffAttendeesListPagination;
            if (!resourcePagination.data) return { ...state };
            const existingIndex = resourcePagination.data.findIndex((x) => x.id === resourceAttendee.id);
            if (existingIndex > -1) return { ...state };

            let alteredPagination = { ...state.staffAttendeesListPagination };
            alteredPagination.data.push(resourceAttendee);
            return { ...state, staffAttendeesListPagination: alteredPagination };
        }

        case actionTypes.DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST: {
            return { ...state };
        }

        case actionTypes.DELETE_ENTRY_OCCASION_ATTENDEE_SUCCESS: {
            const removedAttendee = action.payload.response;
            if (removedAttendee.booking) {
                const alteredStateArray = findAndSpliceArrayByProperty(
                    state.customerAttendeesListPagination.data,
                    "id",
                    removedAttendee.id
                );
                return {
                    ...state,
                    customerAttendeesListPagination: {
                        ...state.customerAttendeesListPagination,
                        data: alteredStateArray,
                        totalRows: state.customerAttendeesListPagination.totalRows - 1,
                    },
                };
            } else if (removedAttendee.resource) {
                const alteredStateArray = findAndSpliceArrayByProperty(
                    state.staffAttendeesListPagination.data,
                    "id",
                    removedAttendee.id
                );
                return {
                    ...state,
                    staffAttendeesListPagination: {
                        ...state.staffAttendeesListPagination,
                        data: alteredStateArray,
                        totalRows: state.staffAttendeesListPagination.totalRows - 1,
                    },
                };
            }

            return { ...state };
        }

        case actionTypes.UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST: {
            return { ...state };
        }

        case actionTypes.UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_SUCCESS: {
            const attendee = action.payload.response;
            const customerAttendeePagination = state.customerAttendeesListPagination;
            if (!customerAttendeePagination.data) return { ...state };
            const existingIndex = customerAttendeePagination.data.findIndex((x) => x.id === attendee.id);
            if (existingIndex < 0) return { ...state };

            let alteredPagination = { ...state.customerAttendeesListPagination };
            alteredPagination.data[existingIndex] = attendee;
            return { ...state, customerAttendeesListPagination: alteredPagination };
        }

        case actionTypes.SELECT_ENTRY: {
            return {
                ...state,
                entry: action.payload.entry,
                customerAttendeesListPagination: initialState.customerAttendeesListPagination,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_ENTRIES_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getEntries: (profileId, startTime, endTime, filterData) => ({
        type: actionTypes.GET_ENTRIES_REQUEST,
        payload: { profileId, startTime, endTime, filterData },
    }),

    getEntriesSuccess: (response) => ({
        type: actionTypes.GET_ENTRIES_SUCCESS,
        payload: { response },
    }),

    getResourceEntries: (profileId, startTime, endTime, page, perPage, filterData, mergeResult) => ({
        type: actionTypes.GET_RESOURCE_ENTRIES_REQUEST,
        payload: { profileId, startTime, endTime, page, perPage, filterData, mergeResult },
    }),

    getEntryAppointment: (entryId) => ({
        type: actionTypes.GET_ENTRY_APPOINTMENT_REQUEST,
        payload: { entryId },
    }),

    getEntryAppointmentSuccess: (response) => ({
        type: actionTypes.GET_ENTRY_APPOINTMENT_SUCCESS,
        payload: { response },
    }),

    getEntryCourseOccasion: (entryId) => ({
        type: actionTypes.GET_ENTRY_COURSE_OCCASION_REQUEST,
        payload: { entryId },
    }),

    getEntryCourseOccasionSuccess: (response) => ({
        type: actionTypes.GET_ENTRY_COURSE_OCCASION_SUCCESS,
        payload: { response },
    }),

    getEntryCourseOccasionAttendees: (entryId, page, perPage, type, status) => ({
        type: actionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_REQUEST,
        payload: { entryId, page, perPage, type, status },
    }),

    getEntryCourseOccasionWaitingListMembers: (entryId, page, perPage) => ({
        type: actionTypes.GET_ENTRY_COURSE_OCCASION_WAITING_LIST_REQUEST,
        payload: { entryId, page, perPage },
    }),

    updateEntryAppointment: (
        originalEntry,
        startTime,
        endTime,
        staffNotes,
        changedResourceId,
        notifyUser,
        callback
    ) => ({
        type: actionTypes.UPDATE_ENTRY_APPOINTMENT_REQUEST,
        payload: { originalEntry, startTime, endTime, staffNotes, changedResourceId, notifyUser, callback },
    }),

    updateEntryOccasion: (id, originalEntry, updatedEntry, callback) => ({
        type: actionTypes.UPDATE_ENTRY_OCCASION_REQUEST,
        payload: { id, originalEntry, updatedEntry, callback },
    }),

    deleteEntryAppointment: (entryId, notifyUser, cancellationMessage, callback) => ({
        type: actionTypes.DELETE_ENTRY_APPOINTMENT_REQUEST,
        payload: { entryId, notifyUser, cancellationMessage, callback },
    }),

    deleteEntryOccasion: (entryId, notifyUser, callback) => ({
        type: actionTypes.DELETE_ENTRY_OCCASION_REQUEST,
        payload: { entryId, notifyUser, callback },
    }),

    addEntryOccasionResource: (entryId, resourceId) => ({
        type: actionTypes.ADD_ENTRY_OCCASION_RESOURCE_REQUEST,
        payload: { entryId, resourceId },
    }),

    updateEntryOccasionAttendeePresence: (entryAttendeeId, isPresent, callback) => ({
        type: actionTypes.UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST,
        payload: { entryAttendeeId, isPresent, callback },
    }),

    deleteEntryOccasionAttendee: (entryAttendeeId) => ({
        type: actionTypes.DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST,
        payload: { entryAttendeeId },
    }),

    selectEntry: (entry) => ({
        type: actionTypes.SELECT_ENTRY,
        payload: { entry },
    }),

    clearEntriesState: () => ({
        type: actionTypes.CLEAR_ENTRIES_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_ENTRIES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getEntries(
                payload.profileId,
                payload.startTime,
                payload.endTime,
                payload.filterData
            );
            yield put(actions.getEntriesSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_RESOURCE_ENTRIES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getResourceEntries(
                payload.profileId,
                payload.startTime,
                payload.endTime,
                payload.page,
                payload.perPage,
                payload.filterData
            );
            if (!payload.mergeResult) {
                yield put({
                    type: actionTypes.GET_RESOURCE_ENTRIES_SUCCESS,
                    payload: { response },
                });
            } else {
                const state = yield select();
                let currentData = [...state.entries.resourceEntriesPagination.data];
                currentData.push(...response.data);
                response.data = currentData;
                yield put({
                    type: actionTypes.GET_RESOURCE_ENTRIES_SUCCESS,
                    payload: { response },
                });
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_ENTRY_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getEntryAppointment(payload.entryId);
            yield put(actions.getEntryAppointmentSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_ENTRY_COURSE_OCCASION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getEntryCourseOccasion(payload.entryId);
            yield put(actions.getEntryCourseOccasionSuccess(response));
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeEvery(actionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getEntryCourseOccasionAttendees(
                payload.entryId,
                payload.page,
                payload.perPage,
                payload.type,
                payload.status
            );

            yield put({
                type: actionTypes.GET_ENTRY_COURSE_OCCASION_ATTENDEES_SUCCESS,
                payload: { response, type: payload.type },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeEvery(actionTypes.GET_ENTRY_COURSE_OCCASION_WAITING_LIST_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getEntryCourseWaitingListMembers(
                payload.entryId,
                payload.page,
                payload.perPage
            );

            yield put({
                type: actionTypes.GET_ENTRY_COURSE_OCCASION_WAITING_LIST_SUCCESS,
                payload: { response, type: payload.type },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_ENTRY_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchEntryAppointment(payload.originalEntry.id, payload.originalEntry, {
                ...payload.originalEntry,
                startTime: payload.startTime || payload.originalEntry.startTime,
                endTime: payload.endTime || payload.originalEntry.endTime,
                staffNotes: payload.staffNotes,
                resourceId: payload.changedResourceId,
                notifyUser: payload.notifyUser,
            });
            yield put({
                type: actionTypes.UPDATE_ENTRY_APPOINTMENT_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }

            // Update local entries
            const entryId = response.id;
            const state = yield select();
            let entries = [...state.entries.entries];
            const updatedEntryIndex = entries.findIndex((x) => x.id === entryId);
            if (updatedEntryIndex > -1) {
                entries[updatedEntryIndex] = response;
                yield put(actions.getEntriesSuccess(entries));
            }

            const resourcePagination = JSON.parse(JSON.stringify(state.entries.resourceEntriesPagination));
            const resourcesUpdated = findAndUpdateResourceEntry(resourcePagination, response);
            if (resourcesUpdated) {
                yield put({
                    type: actionTypes.GET_RESOURCE_ENTRIES_SUCCESS,
                    payload: { response: resourcePagination },
                });
            }

            // Update local booking
            let booking = { ...state.bookings.booking };
            if (booking && booking.entries?.length > 0) {
                const updatedBookingEntryIndex = booking.entries.findIndex((x) => x.id === entryId);
                if (updatedBookingEntryIndex > -1) {
                    booking.entries[updatedBookingEntryIndex] = response;
                    yield put(bookingActions.getBookingSuccess(booking));
                }
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_ENTRY_OCCASION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchEntryOccasion(
                payload.id,
                payload.originalEntry,
                payload.updatedEntry
            );
            yield put({
                type: actionTypes.DELETE_ENTRY_OCCASION_SUCCESS,
                payload: { response },
            });

            // Update local entries
            const entryId = response.id;
            const state = yield select();
            let entries = [...state.entries.entries];
            const updatedEntryIndex = entries.findIndex((x) => x.id === entryId);
            if (updatedEntryIndex > -1) {
                entries[updatedEntryIndex] = response;
                yield put(actions.getEntriesSuccess(entries));
            }

            const resourcePagination = JSON.parse(JSON.stringify(state.entries.resourceEntriesPagination));
            const resourcesUpdated = findAndUpdateResourceEntry(resourcePagination, response);
            if (resourcesUpdated) {
                yield put({
                    type: actionTypes.GET_RESOURCE_ENTRIES_SUCCESS,
                    payload: { response: resourcePagination },
                });
            }

            // Update local booking
            let booking = { ...state.bookings.booking };
            if (booking && booking.entries?.length > 0) {
                const updatedBookingEntryIndex = booking.entries.findIndex((x) => x.id === entryId);
                if (updatedBookingEntryIndex > -1) {
                    booking.entries[updatedBookingEntryIndex] = response;
                    yield put(bookingActions.getBookingSuccess(booking));
                }
            }

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_ENTRY_APPOINTMENT_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteEntryAppointment(
                payload.entryId,
                payload.notifyUser,
                payload.cancellationMessage
            );
            yield put({
                type: actionTypes.DELETE_ENTRY_APPOINTMENT_SUCCESS,
                payload: { response },
            });

            const entryId = response.id;
            const state = yield select();
            let entries = [...state.entries.entries];
            let removedEntryIndex = entries.findIndex((x) => x.id === entryId);
            if (removedEntryIndex > -1) {
                entries.splice(removedEntryIndex, 1);
                yield put(actions.getEntriesSuccess(entries));
            }

            const resourcePagination = JSON.parse(JSON.stringify(state.entries.resourceEntriesPagination));
            const resourcesUpdated = findAndDeleteResourceEntry(resourcePagination, entryId);
            if (resourcesUpdated) {
                yield put({
                    type: actionTypes.GET_RESOURCE_ENTRIES_SUCCESS,
                    payload: { response: resourcePagination },
                });
            }

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_ENTRY_OCCASION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteEntryOccasion(payload.entryId, payload.notifyUser);
            yield put({
                type: actionTypes.DELETE_ENTRY_OCCASION_SUCCESS,
                payload: { response },
            });

            const entryId = response.id;
            const state = yield select();
            let entries = [...state.entries.entries];
            let removedEntryIndex = entries.findIndex((x) => x.id === entryId);
            if (removedEntryIndex > -1) {
                entries.splice(removedEntryIndex, 1);
                yield put(actions.getEntriesSuccess(entries));
            }

            const resourcePagination = JSON.parse(JSON.stringify(state.entries.resourceEntriesPagination));
            const resourcesUpdated = findAndDeleteResourceEntry(resourcePagination, entryId);
            if (resourcesUpdated) {
                yield put({
                    type: actionTypes.GET_RESOURCE_ENTRIES_SUCCESS,
                    payload: { response: resourcePagination },
                });
            }

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_ENTRY_OCCASION_RESOURCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addEntryOccasionResource(payload.entryId, payload.resourceId);
            yield put({
                type: actionTypes.ADD_ENTRY_OCCASION_RESOURCE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateEntryOccasionAttendeePresence(
                payload.entryAttendeeId,
                payload.isPresent
            );
            yield put({
                type: actionTypes.UPDATE_ENTRY_OCCASION_ATTENDEE_PRESENCE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_ENTRY_OCCASION_ATTENDEE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteEntryOccasionAttendee(payload.entryAttendeeId);
            yield put({
                type: actionTypes.DELETE_ENTRY_OCCASION_ATTENDEE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}

function findAndUpdateResourceEntry(resourcePagination, updatedEntry) {
    let resourceEntryIndex = resourcePagination.data.findIndex(
        (resource) => resource.entries.findIndex((entry) => entry.id === updatedEntry.id) > -1
    );
    if (resourceEntryIndex > -1) {
        const resource = resourcePagination.data[resourceEntryIndex];
        const entryResource = updatedEntry.resources?.length > 0 ? updatedEntry.resources[0] : null;

        resourceEntryIndex = resource.entries.findIndex((entry) => entry.id === updatedEntry.id);
        if (entryResource === null || entryResource.id === resource.id) {
            // Entry still has same resource
            resource.entries[resourceEntryIndex] = updatedEntry;
        } else {
            // Changed resource, move to changed resource
            resource.entries.splice(resourceEntryIndex, 1);
            const changedResourceIndex = resourcePagination.data.findIndex(
                (resource) => resource.id === entryResource.id
            );
            if (changedResourceIndex > -1) {
                const changedResource = resourcePagination.data[changedResourceIndex];
                changedResource.entries.push(updatedEntry);
            }
        }

        return true;
    }

    return false;
}

function findAndDeleteResourceEntry(resourcePagination, entryId) {
    let resourceEntryIndex = resourcePagination.data.findIndex(
        (resource) => resource.entries.findIndex((entry) => entry.id === entryId) > -1
    );
    if (resourceEntryIndex > -1) {
        const resource = resourcePagination.data[resourceEntryIndex];
        resourceEntryIndex = resource.entries.findIndex((entry) => entry.id === entryId);
        resource.entries.splice(resourceEntryIndex, 1);
        return true;
    }

    return false;
}
