import { put, takeLatest } from "redux-saga/effects";
import {
    getSubscriptions,
    getSubscription,
    addSubscription,
    patchSubscription,
    cancelSubscription,
    getSubscriptionTransactions,
} from "./subscriptionCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_SUBSCRIPTIONS_REQUEST: "GET_SUBSCRIPTIONS_REQUEST",
    GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
    GET_SUBSCRIPTION_REQUEST: "GET_SUBSCRIPTION_REQUEST",
    GET_SUBSCRIPTION_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
    ADD_SUBSCRIPTION_REQUEST: "ADD_SUBSCRIPTION_REQUEST",
    ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
    UPDATE_SUBSCRIPTION_REQUEST: "UPDATE_SUBSCRIPTION_REQUEST",
    UPDATE_SUBSCRIPTION_SUCCESS: "UPDATE_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_REQUEST: "CANCEL_SUBSCRIPTION_REQUEST",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
    GET_SUBSCRIPTION_TRANSACTIONS_REQUEST: "GET_SUBSCRIPTION_TRANSACTIONS_REQUEST",
    GET_SUBSCRIPTION_TRANSACTIONS_SUCCESS: "GET_SUBSCRIPTION_TRANSACTIONS_SUCCESS",
    CLEAR_SUBSCRIPTIONS_STATE: "CLEAR_SUBSCRIPTIONS_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    subscriptionTransactionsPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUBSCRIPTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SUBSCRIPTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_SUBSCRIPTION_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                subscription: action.payload.response,
            };
        }

        case actionTypes.ADD_SUBSCRIPTION_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                subscription: action.payload.response,
            };
        }

        case actionTypes.UPDATE_SUBSCRIPTION_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                subscription: action.payload.response,
            };
        }

        case actionTypes.CANCEL_SUBSCRIPTION_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                subscription: action.payload.response,
            };
        }

        case actionTypes.GET_SUBSCRIPTION_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SUBSCRIPTION_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                subscriptionTransactionsPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_SUBSCRIPTIONS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getSubscriptions: (profileId, page, perPage, search, status, paymentType) => ({
        type: actionTypes.GET_SUBSCRIPTIONS_REQUEST,
        payload: { profileId, page, perPage, search, status, paymentType },
    }),

    getSubscription: (id) => ({
        type: actionTypes.GET_SUBSCRIPTION_REQUEST,
        payload: { id },
    }),

    addSubscription: (profileId, data, callback) => ({
        type: actionTypes.ADD_SUBSCRIPTION_REQUEST,
        payload: { profileId, data, callback },
    }),

    updateSubscription: (id, originalSubscription, updatedSubscription) => ({
        type: actionTypes.UPDATE_SUBSCRIPTION_REQUEST,
        payload: { id, originalSubscription, updatedSubscription },
    }),

    cancelSubscription: (id, cancellationType, cancelLastAt, callback) => ({
        type: actionTypes.CANCEL_SUBSCRIPTION_REQUEST,
        payload: { id, cancellationType, cancelLastAt, callback },
    }),

    getSubscriptionTransactions: (id, page, perPage, search, status, paymentType, paymentStatus) => ({
        type: actionTypes.GET_SUBSCRIPTION_TRANSACTIONS_REQUEST,
        payload: { id, page, perPage, search, status, paymentType, paymentStatus },
    }),

    clearSubscriptionState: () => ({
        type: actionTypes.CLEAR_SUBSCRIPTIONS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_SUBSCRIPTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getSubscriptions(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.paymentType
            );

            yield put({
                type: actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SUBSCRIPTION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getSubscription(payload.id);

            yield put({
                type: actionTypes.GET_SUBSCRIPTION_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_SUBSCRIPTION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addSubscription(payload.profileId, payload.data);

            yield put({
                type: actionTypes.ADD_SUBSCRIPTION_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_SUBSCRIPTION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchSubscription(
                payload.id,
                payload.originalSubscription,
                payload.updatedSubscription
            );
            yield put({
                type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield cancelSubscription(
                payload.id,
                payload.cancellationType,
                payload.cancelLastAt
            );

            yield put({
                type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SUBSCRIPTION_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getSubscriptionTransactions(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.paymentType,
                payload.paymentStatus
            );

            yield put({
                type: actionTypes.GET_SUBSCRIPTION_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
