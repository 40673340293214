import { put, takeLatest } from "redux-saga/effects";
import { getOverviewStats } from "./statsCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_OVERVIEW_REQUEST: "GET_OVERVIEW_REQUEST",
    GET_OVERVIEW_SUCCESS: "GET_OVERVIEW_SUCCESS",
};

const initialState = {
    isLoading: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_OVERVIEW_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_OVERVIEW_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                overviewStats: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getOverviewStats: (profileId, perspective) => ({
        type: actionTypes.GET_OVERVIEW_REQUEST,
        payload: { profileId, perspective },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_OVERVIEW_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getOverviewStats(payload.profileId, payload.perspective);
            yield put({
                type: actionTypes.GET_OVERVIEW_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
