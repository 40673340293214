import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import * as bookingUtils from "../../../../pages/bookings/bookings/utils";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function BookingListFilter({ onStatusChanged }) {
    const classes = useStyles();
    const [status, setStatus] = useState("all");

    const handleChange = ({ target: { value } }) => {
        setStatus(value);
        onStatusChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">
                <FormattedMessage id="COMMON.FILTER.STATUS.HEADER" />
            </InputLabel>
            <Select
                labelId="status-filter-label"
                id="status-filter"
                value={status}
                onChange={handleChange}
            >
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={bookingUtils.BOOKING_STATUS_CONFIRMED}>
                    <FormattedMessage id="BOOKING.FILTER.CONFIRMED" />
                </MenuItem>
                <MenuItem value={bookingUtils.BOOKING_STATUS_UNCONFIRMED}>
                    <FormattedMessage id="BOOKING.FILTER.UNCONFIRMED" />
                </MenuItem>
                <MenuItem value={bookingUtils.BOOKING_STATUS_UNPAID}>
                    <FormattedMessage id="BOOKING.FILTER.UNPAID" />
                </MenuItem>
                <MenuItem value={bookingUtils.BOOKING_STATUS_CANCELLED}>
                    <FormattedMessage id="BOOKING.FILTER.CANCELLED" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default BookingListFilter;
