import React, { useState } from "react";
import { FormControl, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/sv";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
    formControl: {},
}));

function TransactionPaymentDateFilter({ onStartTimeChanged, onEndTimeChanged }) {
    const classes = useStyles();
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    return (
        <>
            <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast={false}
                        showTodayButton
                        ampm={false}
                        onChange={(value) => {
                            onStartTimeChanged(value);
                            setStartTime(value);
                        }}
                        value={startTime}
                        clearable
                        label={<FormattedMessage id="PAYMENT.FILTER.CREATED_AT.START_TIME" />}
                        format="yyyy-MM-DD HH:mm"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        disablePast={false}
                        showTodayButton
                        ampm={false}
                        onChange={(value) => {
                            onEndTimeChanged(value);
                            setEndTime(value);
                        }}
                        value={endTime}
                        clearable
                        label={<FormattedMessage id="PAYMENT.FILTER.CREATED_AT.END_TIME" />}
                        format="yyyy-MM-DD HH:mm"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </FormControl>
        </>
    );
}

export default TransactionPaymentDateFilter;
