import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function ResourceTypeFilter({ onTypeChanged }) {
    const classes = useStyles();
    const [type, setType] = useState("all");

    const handleChange = ({ target: { value } }) => {
        setType(value);
        onTypeChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="type-filter-label">
                <FormattedMessage id="RESOURCE.FILTER_TYPE" />
            </InputLabel>
            <Select
                labelId="type-filter-label"
                id="type-filter"
                value={type}
                onChange={handleChange}
            >
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={"staff"}>
                    <FormattedMessage id="RESOURCE.CREATE_STAFF" />
                </MenuItem>
                <MenuItem value={"space"}>
                    <FormattedMessage id="RESOURCE.CREATE_SPACE" />
                </MenuItem>
                <MenuItem value={"article"}>
                    <FormattedMessage id="RESOURCE.CREATE_ARTICLE" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default ResourceTypeFilter;
