import { put, takeLatest } from "redux-saga/effects";
import {
    getIntegration,
    getTemplates,
    createScriveTemplateService,
    patchScriveTemplateService,
    deleteScriveTemplateService,
} from "./scriveCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../../errors/errorsRedux";

export const actionTypes = {
    GET_SCRIVE_INTEGRATION_REQUEST: "GET_SCRIVE_INTEGRATION_REQUEST",
    GET_SCRIVE_INTEGRATION_SUCCESS: "GET_SCRIVE_INTEGRATION_SUCCESS",
    GET_SCRIVE_TEMPLATES_REQUEST: "GET_SCRIVE_TEMPLATES_REQUEST",
    GET_SCRIVE_TEMPLATES_SUCCESS: "GET_SCRIVE_TEMPLATES_SUCCESS",
    MANAGE_SCRIVE_TEMPLATE_SERVICE: "MANAGE_SCRIVE_TEMPLATE_SERVICE",
    MANAGE_SCRIVE_TEMPLATE_SERVICE_CANCEL: "MANAGE_SCRIVE_TEMPLATE_SERVICE_CANCEL",
    CREATE_SCRIVE_TEMPLATE_SERVICE_REQUEST: "CREATE_SCRIVE_TEMPLATE_SERVICE_REQUEST",
    CREATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS: "CREATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS",
    UPDATE_SCRIVE_TEMPLATE_SERVICE_REQUEST: "UPDATE_SCRIVE_TEMPLATE_SERVICE_REQUEST",
    UPDATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS: "UPDATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS",
    DELETE_SCRIVE_TEMPLATE_SERVICE_REQUEST: "DELETE_SCRIVE_TEMPLATE_SERVICE_REQUEST",
    DELETE_SCRIVE_TEMPLATE_SERVICE_SUCCESS: "DELETE_SCRIVE_TEMPLATE_SERVICE_SUCCESS",
};

const initialState = {
    isLoading: false,
    integration: null,
    templatesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SCRIVE_INTEGRATION_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SCRIVE_INTEGRATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.integration,
            };
        }

        case actionTypes.GET_SCRIVE_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_SCRIVE_TEMPLATES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                templatesPagination: action.payload.response,
            };
        }

        case actionTypes.MANAGE_SCRIVE_TEMPLATE_SERVICE: {
            return {
                ...state,
                managingTemplate: action.payload.template,
                managingTemplateService: action.payload.templateService,
            };
        }

        case actionTypes.MANAGE_SCRIVE_TEMPLATE_SERVICE_CANCEL: {
            return {
                ...state,
                managingTemplate: null,
                managingTemplateService: null,
            };
        }

        case actionTypes.CREATE_SCRIVE_TEMPLATE_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS: {
            let templateListPagination = state.templatesPagination;
            let index = templateListPagination.data.findIndex(
                (item) => item.scriveTemplateId === action.payload.response.scriveTemplateId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...templateListPagination.data];
                let template = alteredPaginationData[index];
                if (template.templateServices) {
                    template.templateServices.push(action.payload.response);
                } else {
                    template.templateServices = [action.payload.response];
                }
            }
            return {
                ...state,
                isLoading: false,
                managingTemplate: null,
                managingTemplateService: null,
                templatesPagination: {
                    ...state.templatesPagination,
                    data: alteredPaginationData || templateListPagination.data,
                },
            };
        }

        case actionTypes.UPDATE_SCRIVE_TEMPLATE_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS: {
            let templateListPagination = state.templatesPagination;
            let index = templateListPagination.data.findIndex(
                (item) => item.scriveTemplateId === action.payload.response.scriveTemplateId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...templateListPagination.data];
                let template = alteredPaginationData[index];
                if (template.templateServices) {
                    let templateServiceIndex = template.templateServices.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (templateServiceIndex > -1) {
                        template.templateServices[templateServiceIndex] = action.payload.response;
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingTemplate: null,
                managingTemplateService: null,
                templatesPagination: {
                    ...state.templatesPagination,
                    data: alteredPaginationData || templateListPagination.data,
                },
            };
        }

        case actionTypes.DELETE_SCRIVE_TEMPLATE_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_SCRIVE_TEMPLATE_SERVICE_SUCCESS: {
            let templateListPagination = state.templatesPagination;
            let index = templateListPagination.data.findIndex(
                (item) => item.scriveTemplateId === action.payload.response.scriveTemplateId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...templateListPagination.data];
                let template = alteredPaginationData[index];
                if (template.templateServices) {
                    let templateServiceIndex = template.templateServices.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (templateServiceIndex > -1) {
                        template.templateServices.splice(templateServiceIndex, 1);
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingTemplate: null,
                managingTemplateService: null,
                templatesPagination: {
                    ...state.templatesPagination,
                    data: alteredPaginationData || templateListPagination.data,
                },
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getScriveIntegration: (id) => ({
        type: actionTypes.GET_SCRIVE_INTEGRATION_REQUEST,
        payload: { id },
    }),
    getScriveTemplates: (id, page, perPage) => ({
        type: actionTypes.GET_SCRIVE_TEMPLATES_REQUEST,
        payload: { id, page, perPage },
    }),
    manageScriveTemplateService: (template, templateService) => ({
        type: actionTypes.MANAGE_SCRIVE_TEMPLATE_SERVICE,
        payload: { template, templateService },
    }),
    cancelManageScriveTemplateService: () => ({
        type: actionTypes.MANAGE_SCRIVE_TEMPLATE_SERVICE_CANCEL,
        payload: {},
    }),
    createScriveTemplateService: (integrationId, scriveTemplateId, data) => ({
        type: actionTypes.CREATE_SCRIVE_TEMPLATE_SERVICE_REQUEST,
        payload: { integrationId, scriveTemplateId, data },
    }),
    updateScriveTemplateService: (integrationId, originalTemplateService, updatedTemplateService) => ({
        type: actionTypes.UPDATE_SCRIVE_TEMPLATE_SERVICE_REQUEST,
        payload: { integrationId, originalTemplateService, updatedTemplateService },
    }),
    deleteScriveTemplateService: (integrationId, templateServiceId) => ({
        type: actionTypes.DELETE_SCRIVE_TEMPLATE_SERVICE_REQUEST,
        payload: { integrationId, templateServiceId },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_SCRIVE_INTEGRATION_REQUEST, function* ({ payload }) {
        try {
            const { data: integration } = yield getIntegration(payload.id);
            yield put({
                type: actionTypes.GET_SCRIVE_INTEGRATION_SUCCESS,
                payload: { integration },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_SCRIVE_TEMPLATES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getTemplates(payload.id, payload.page, payload.perPage);
            yield put({
                type: actionTypes.GET_SCRIVE_TEMPLATES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_SCRIVE_TEMPLATE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createScriveTemplateService(
                payload.integrationId,
                payload.scriveTemplateId,
                payload.data
            );
            yield put({
                type: actionTypes.CREATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_SCRIVE_TEMPLATE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchScriveTemplateService(
                payload.integrationId,
                payload.originalTemplateService,
                {
                    ...payload.originalTemplateService,
                    serviceId: payload.updatedTemplateService.serviceId || payload.originalTemplateService.serviceId,
                    deliveryMethod:
                        payload.updatedTemplateService.deliveryMethod || payload.originalTemplateService.deliveryMethod,
                    signAuthMethod:
                        payload.updatedTemplateService.signAuthMethod || payload.originalTemplateService.signAuthMethod,
                }
            );
            yield put({
                type: actionTypes.UPDATE_SCRIVE_TEMPLATE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_SCRIVE_TEMPLATE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteScriveTemplateService(
                payload.integrationId,
                payload.templateServiceId
            );
            yield put({
                type: actionTypes.DELETE_SCRIVE_TEMPLATE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
