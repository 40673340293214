import React from "react";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import ServiceCommonDetailsForm from "../ServiceCommonDetailsForm";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
    category: yup.object().nullable(),
});

function ProductPageDetails({ updateProduct }) {
    const { product, isUpdating } = useSelector((state) => state.services);

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const onFormSubmit = (values) => {
        let originalProduct = { ...product };
        originalProduct.categoryId = originalProduct?.category?.id;

        let updatedProductData = { ...product };
        updatedProductData.name = values.name;
        updatedProductData.description = values.description;
        updatedProductData.categoryId = values?.category?.id;
        updatedProductData.color = values.color;

        updateProduct(product.id, originalProduct, updatedProductData);
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="SERVICE.PRODUCT.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="SERVICE.PRODUCT.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                    {product && !product?.deletedAt && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <FormProvider {...formMethods}>
                        <Form>
                            <ServiceCommonDetailsForm service={product} />
                        </Form>
                    </FormProvider>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, serviceActions)(ProductPageDetails);
