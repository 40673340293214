import React from "react";
import { TextCell, TranslationCell, ChipCell } from "../../components/tables/MaterialTableCells";
import { FormattedMessage } from "react-intl";

export const STATUS_STARTED = "started";
export const STATUS_CANCELLED = "cancelled";
export const STATUS_FAILED = "failed";
export const STATUS_DELETED = "deleted";
export const STATUS_SUCCESS = "success";

export const PAYMENT_TYPE_CARD = "card";
export const PAYMENT_TYPE_SWISH = "swish";
export const PAYMENT_TYPE_INVOICE = "invoice";

export const PAYMENT_STATUS_UNPAID = "unpaid";
export const PAYMENT_STATUS_PARTPAID = "partpaid";
export const PAYMENT_STATUS_PAID = "paid";

export const PROCESS_STATUS_INVOICE = "invoice";
export const PROCESS_STATUS_RECEIPT = "receipt";
export const PROCESS_STATUS_REMINDER = "reminder";
export const PROCESS_STATUS_MANUAL_HANDLING_REMINDER = "manualhandlingreminder";
export const PROCESS_STATUS_LONG_TIME_SURVEILLANCE = "longtermsurveillance";
export const PROCESS_STATUS_DEBT_COLLECTION = "debtcollection";
export const PROCESS_STATUS_DELETED = "deleted";

export const REFUND_TYPE_MANUAL = "manual";
export const REFUND_TYPE_AUTOMATIC = "automatic";

export const REFUND_STATUS_SUCCESS = "success";
export const REFUND_STATUS_FAILED = "failed";

export function getTransactionListStatusCell(status) {
    return TranslationCell(getTransactionStatusTransactionId(status));
}

export function getTransactionStatusTransactionId(status) {
    if (status === STATUS_STARTED) {
        return "PAYMENT.STATUS.STARTED";
    } else if (status === STATUS_CANCELLED) {
        return "PAYMENT.STATUS.CANCELLED";
    } else if (status === STATUS_FAILED) {
        return "PAYMENT.STATUS.FAILED";
    } else if (status === STATUS_DELETED) {
        return "PAYMENT.STATUS.DELETED";
    } else if (status === STATUS_SUCCESS) {
        return "PAYMENT.STATUS.SUCCESS";
    }
    return "";
}

export function getTransactionListPaymentTypeCell(paymentType) {
    if (paymentType === PAYMENT_TYPE_CARD) {
        return TranslationCell("PAYMENT.PAYMENT_TYPE.CARD");
    } else if (paymentType === PAYMENT_TYPE_SWISH) {
        return TranslationCell("PAYMENT.PAYMENT_TYPE.SWISH");
    } else if (paymentType === PAYMENT_TYPE_INVOICE) {
        return TranslationCell("PAYMENT.PAYMENT_TYPE.INVOICE");
    }
    return TextCell();
}

export function getTransactionStatusCell(paymentStatus, status) {
    if (status === STATUS_STARTED || status === STATUS_CANCELLED) {
        return ChipCell("info", <FormattedMessage id="PAYMENT.STATUS.NOT_COMPLETED" />);
    } else if (status === STATUS_FAILED) {
        return ChipCell("error", <FormattedMessage id="PAYMENT.STATUS.FAILED" />);
    }
    if (paymentStatus === PAYMENT_STATUS_UNPAID) {
        return ChipCell("error", <FormattedMessage id="PAYMENT.PAYMENT_STATUS.UNPAID" />);
    } else if (paymentStatus === PAYMENT_STATUS_PARTPAID) {
        return ChipCell("warning", <FormattedMessage id="PAYMENT.PAYMENT_STATUS.PARTPAID" />);
    } else if (paymentStatus === PAYMENT_STATUS_PAID) {
        return ChipCell("success", <FormattedMessage id="PAYMENT.PAYMENT_STATUS.PAID" />);
    }

    return TextCell();
}

export function getTransactionRefundStatusCell(status) {
    if (status === REFUND_STATUS_SUCCESS) {
        return ChipCell("success", <FormattedMessage id="REFUND.STATUS.SUCCESS" />);
    } else if (status === REFUND_STATUS_FAILED) {
        return ChipCell("error", <FormattedMessage id="REFUND.STATUS.FAILED" />);
    }

    return TextCell();
}
