import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../components/buttons/BootstrapCancelButton";
import { actions as serviceActions } from "../../../redux/services/serviceRedux";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    descriptionTextContainer: {
        marginBottom: theme.spacing(4),
    },
    formContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    serviceId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function SelectServiceModal({
    getServices,
    onSaveClicked,
    onCloseClicked,
    show,
    titleText,
    descriptionText,
    saveButtonText,
    isLoading,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { listPagination } = useSelector((state) => state.services);
    const classes = useStyles();

    useEffect(() => {
        getServices(profile.id, 1, 500);
    }, [getServices, profile.id]);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        const chosenServiceId = parseInt(values.serviceId, 10);
        const service = listPagination.data.find((x) => x.id === chosenServiceId);
        onSaveClicked(service);
    };

    return (
        <Form>
            <Modal size="lg" show={show} onHide={onCloseClicked} style={{ zIndex: "9999999" }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {descriptionText && <div className={classes.descriptionTextContainer}>{descriptionText}</div>}
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Control as="select" ref={register} name="serviceId" isInvalid={errors.serviceId}>
                                {listPagination.data.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.serviceId?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={onCloseClicked} />
                    <BootstrapSaveButton
                        isLoading={isLoading}
                        onClick={handleSubmit(onFormSubmit)}
                        label={saveButtonText}
                    />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default connect(null, serviceActions)(SelectServiceModal);
