import axios from "axios";
import { getApiUrl } from "../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const ENTRIES_URL = "v1/admin/profiles/{profileId}/entries";
export const RESOURCE_ENTRIES_URL = "v1/admin/profiles/{profileId}/resource-entries";
export const ENTRY_APPOINTMENT_URL = "v1/admin/appointment-entries";
export const ENTRY_COURSE_OCCASION_URL = "v1/admin/course-entries";
export const ENTRY_COURSE_RESOURCE_URL = "v1/admin/course-entries/{id}/resources";
export const ENTRY_COURSE_ATTENDEE_URL = "v1/admin/course-entry-attendees/";
export const ENTRY_COURSE_WAITING_LIST_MEMBERS_URL = "v1/admin/course-entries/{id}/waiting-list/members";

export function getEntries(profileId, startTime, endTime, filterData) {
    let url =
        ENTRIES_URL.replace("{profileId}", profileId) +
        "?startTime=" +
        encodeURIComponent(startTime) +
        "&endTime=" +
        encodeURIComponent(endTime);
    if (filterData) {
        if (filterData.includeRemovedServices) {
            url += "&includeRemovedServices=true";
        }

        if (filterData.includeRemovedBookings) {
            url += "&includeRemovedEntries=true";
        }

        if (filterData.resourceIds?.length > 0) {
            let resourceIdsQuery = "";
            filterData.resourceIds.forEach((x) => (resourceIdsQuery += `&resourceIds=${x}`));
            url += resourceIdsQuery;
        }

        if (filterData.customerIds?.length > 0) {
            let customerIdsQuery = "";
            filterData.customerIds.forEach((x) => (customerIdsQuery += `&customerIds=${x}`));
            url += customerIdsQuery;
        }
    }

    return axios.get(getApiUrl() + url);
}

export function getResourceEntries(profileId, startTime, endTime, page, perPage, filterData) {
    let url =
        RESOURCE_ENTRIES_URL.replace("{profileId}", profileId) +
        "?startTime=" +
        encodeURIComponent(startTime) +
        "&endTime=" +
        encodeURIComponent(endTime) +
        "&page=" +
        page +
        "&perPage=" +
        perPage;
    if (filterData) {
        if (filterData.includeRemovedServices) {
            url += "&includeRemovedServices=true";
        }

        if (filterData.includeRemovedBookings) {
            url += "&includeRemovedEntries=true";
        }

        if (filterData.resourceIds?.length > 0) {
            let resourceIdsQuery = "";
            filterData.resourceIds.forEach((x) => (resourceIdsQuery += `&resourceIds=${x}`));
            url += resourceIdsQuery;
        }
    }
    return axios.get(getApiUrl() + url);
}

export function getEntryAppointment(entryId) {
    return axios.get(getApiUrl() + ENTRY_APPOINTMENT_URL + "/" + entryId);
}

export function patchEntryAppointment(entryId, originalEntry, changedEntry) {
    return axios.patch(
        getApiUrl() + ENTRY_APPOINTMENT_URL + "/" + entryId,
        jsonpatch.compare(originalEntry, changedEntry),
        {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        }
    );
}

export function deleteEntryAppointment(entryId, notifyUser, cancellationMessage) {
    return axios.delete(getApiUrl() + `${ENTRY_APPOINTMENT_URL}/${entryId}`, {
        data: { notifyUser, cancellationMessage },
    });
}

export function getEntryCourseOccasion(entryId) {
    return axios.get(getApiUrl() + ENTRY_COURSE_OCCASION_URL + "/" + entryId);
}

export function patchEntryOccasion(entryId, originalEntry, changedEntry) {
    return axios.patch(
        getApiUrl() + ENTRY_COURSE_OCCASION_URL + "/" + entryId,
        jsonpatch.compare(originalEntry, changedEntry),
        {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        }
    );
}

export function deleteEntryOccasion(entryId, notifyUser) {
    return axios.delete(getApiUrl() + `${ENTRY_COURSE_OCCASION_URL}/${entryId}`, {
        data: { notifyUser },
    });
}

export function getEntryCourseOccasionAttendees(entryId, page, perPage, type, status) {
    return axios.get(
        getApiUrl() +
            `${ENTRY_COURSE_OCCASION_URL}/${entryId}/attendees?page=${page}&perPage=${perPage}${
                type ? `&type=${type}` : ""
            }${status ? `&status=${status}` : ""}`
    );
}

export function addEntryOccasionResource(entryId, resourceId) {
    return axios.post(getApiUrl() + ENTRY_COURSE_RESOURCE_URL.replace("{id}", entryId), { resourceId });
}

export function updateEntryOccasionAttendeePresence(entryAttendeeId, isPresent) {
    return axios.post(`${getApiUrl()}${ENTRY_COURSE_ATTENDEE_URL}/${entryAttendeeId}/presence`, { isPresent });
}

export function deleteEntryOccasionAttendee(entryAttendeeId) {
    return axios.delete(`${getApiUrl()}${ENTRY_COURSE_ATTENDEE_URL}/${entryAttendeeId}`);
}

export function getEntryCourseWaitingListMembers(entryId, page, perPage) {
    const url = ENTRY_COURSE_WAITING_LIST_MEMBERS_URL.replace("{id}", entryId) + `?page=${page}&perPage=${perPage}`;
    return axios.get(getApiUrl() + url);
}
