import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import TransactionPageNavigator from "./TransactionPageNavigator";
import TransactionPageDetails from "./TransactionPageDetails";
import TransactionPageBookings from "./TransactionPageBookings";
import TransactionPageRefunds from "./TransactionPageRefunds";
import TransactionPageDocumentVersions from "./TransactionPageDocumentVersions";
import PageBackdrop from "../../components/spinners/PageBackdrop";
import SnackbarError from "../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function TransactionPage({ match, getTransaction, clearTransactionState, clearDisplayError, clearErrorState }) {
    const transactionId = parseInt(match.params.id, 10);
    const { transaction, isLoading, isUpdating } = useSelector((state) => state.transactions);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!transaction || transaction.id !== transactionId)) {
            getTransaction(transactionId);
        }
    }, [getTransaction, transaction, transactionId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearTransactionState();
            clearErrorState();
        };
    }, [clearTransactionState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <TransactionPageNavigator transactionId={transactionId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/transactions/${transactionId}`}
                            exact={true}
                            to={`/transactions/${transactionId}/details`}
                        />
                        <Route path={`/transactions/${transactionId}/details`} component={TransactionPageDetails} />
                        <Route path={`/transactions/${transactionId}/bookings`} component={TransactionPageBookings} />
                        <Route path={`/transactions/${transactionId}/refunds`} component={TransactionPageRefunds} />
                        <Route
                            path={`/transactions/${transactionId}/document-versions`}
                            component={TransactionPageDocumentVersions}
                        />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, { ...transactionActions, ...errorActions })(TransactionPage);
