export function getBrowserLanguage() {
    let { language } = navigator;
    if (language) {
        if (language.indexOf("-") > -1) {
            [language] = language.split("-");
        }
    }

    return language;
}

export const getDefaultPhoneCountry = () => {
    const language = getBrowserLanguage();
    if (language === "sv") return "se";
    return "gb";
};

export const getCustomerLanguageDefault = () => {
    const language = getBrowserLanguage();
    if (language === "sv") return "sv-SE";
    if (language === "da") return "da-DK";
    if (language === "fi") return "fi-FI";
    if (language === "nb") return "nb-NO";
    if (language === "en") return "en-GB";
    return "en-GB";
};

export const getCustomerLanguageOptions = (intl) => {
    return [
        {
            name: intl.formatMessage({
                id: "COMMON.LANGUAGE.SWEDISH",
            }),
            key: "sv-SE",
        },
        {
            name: intl.formatMessage({
                id: "COMMON.LANGUAGE.DANISH",
            }),
            key: "da-DK",
        },
        {
            name: intl.formatMessage({
                id: "COMMON.LANGUAGE.FINNISH",
            }),
            key: "fi-FI",
        },
        {
            name: intl.formatMessage({
                id: "COMMON.LANGUAGE.NORWEIGAN",
            }),
            key: "nb-NO",
        },
        {
            name: intl.formatMessage({
                id: "COMMON.LANGUAGE.ENGLISH",
            }),
            key: "en-GB",
        },
    ];
};
