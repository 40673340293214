import React from "react";
import { TextCell, ChipCell } from "../../components/tables/MaterialTableCells";
import { FormattedMessage } from "react-intl";

export const STATUS_ACTIVE = "active";
export const STATUS_EXPIRED = "expired";
export const STATUS_DELETED = "deleted";

export const TYPE_INVOICE = "invoice";
export const TYPE_CARD = "card";

export const INVOICE_INDIVIDUAL = "individual";
export const INVOICE_COMPANY = "company";

export function getPaymentMethodStatusCell(status) {
    if (status === STATUS_DELETED) {
        return ChipCell("error", <FormattedMessage id="PAYMENT_METHOD.STATUS.DELETED" />);
    } else if (status === STATUS_EXPIRED) {
        return ChipCell("warning", <FormattedMessage id="PAYMENT_METHOD.STATUS.EXPIRED" />);
    } else if (status === STATUS_ACTIVE) {
        return ChipCell("success", <FormattedMessage id="PAYMENT_METHOD.STATUS.ACTIVE" />);
    }
    return TextCell();
}
