import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Switch, FormControlLabel } from "@material-ui/core";
import { actions as bookingManagerActions } from "../../../redux/bookings/bookingManagerRedux";
import { Form, Col } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FormattedMessage } from "react-intl";
import { PAYMENT_MODE_OPTIONAL, PAYMENT_MODE_MANDATORY } from "../services/utils";

function DrawerAddBookingOffer({ selectedService, notifyUser, setNotifyUser, isMarkedAsPaid, setIsMarkedAsPaid }) {
    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="BOOKING.ADD.OTHER.TITLE" values={{ stepCount: 3 }} />
                </Form.Label>
                <Form.Row>
                    <Col xs={12}>
                        <FormControlLabel
                            control={<Switch color="primary" />}
                            label={<FormattedMessage id="BOOKING.ADD.OTHER.NOTIFY_USER" />}
                            labelPlacement="end"
                            checked={notifyUser}
                            onChange={(event, value) => setNotifyUser(value)}
                            style={{ userSelect: "none" }}
                        />
                    </Col>
                    {(selectedService.paymentMode === PAYMENT_MODE_OPTIONAL ||
                        selectedService.paymentMode === PAYMENT_MODE_MANDATORY) && (
                        <Col xs={12}>
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label={<FormattedMessage id="BOOKING.ADD.OTHER.MARK_AS_PAID" />}
                                labelPlacement="end"
                                checked={isMarkedAsPaid}
                                onChange={(event, value) => setIsMarkedAsPaid(value)}
                                style={{ userSelect: "none" }}
                            />
                        </Col>
                    )}
                </Form.Row>
            </Form.Group>
        </div>
    );
}

export default injectIntl(connect(null, bookingManagerActions)(DrawerAddBookingOffer));
