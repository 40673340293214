import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { Card, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { YesNoDialog, ACTION_YES } from "../../components/YesNoDialog";
import { makeStyles } from "@material-ui/core";
import { isAtLeastStaffPermission } from "../resources/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "325px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            marginBottom: "20px",
        },
    },
    avatar: {
        height: "90px",
        width: "90px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        borderRadius: "0.42rem",
    },
    avatarInfoCell: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
        <span
            ref={ref}
            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            onClick={(e) => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            {props.children}
        </span>
    );
});

function CustomerPageNavigator({ customerId, deleteCustomer }) {
    const { profile } = useSelector((state) => state.auth);
    const { customer } = useSelector((state) => state.customers);
    const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const classes = useStyles();

    const onDeleteCustomerClicked = (e) => {
        e.preventDefault();
        setMenuDropdownOpen(false);
        setShowDeleteConfirmation(true);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            deleteCustomer(customerId);
        }
        setShowDeleteConfirmation(false);
    };

    return (
        <div className={classes.root}>
            <Card>
                {/* begin::Body */}
                <Card.Body className="card-body pt-4">
                    {/* begin::Toolbar */}
                    <div className="d-flex justify-content-end">
                        <Dropdown
                            className="dropdown dropdown-inline"
                            alignRight
                            show={isMenuDropdownOpen}
                            onToggle={() => setMenuDropdownOpen(!isMenuDropdownOpen)}
                        >
                            <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                variant="transparent"
                                id="dropdown-toggle-top-user-profile"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-hor"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <ul className="navi navi-hover py-5">
                                    {!customer?.deletedAt && (
                                        <li className="navi-item" onClick={onDeleteCustomerClicked}>
                                            <a href="/" className="navi-link">
                                                <span className="navi-icon">
                                                    <i className="flaticon2-trash"></i>
                                                </span>
                                                <span className="navi-text">
                                                    <FormattedMessage id="CUSTOMER.DELETE" />
                                                </span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {/* end::Toolbar */}
                    {/* begin::User */}
                    <div className="d-flex">
                        <div>
                            <div
                                className={classes.avatar}
                                style={{
                                    backgroundImage: `url(${customer?.avatarUrl})`,
                                }}
                            ></div>
                        </div>
                        <div className={classes.avatarInfoCell}>
                            <div>
                                {customer?.firstName && (
                                    <span className="font-weight-bolder font-size-h6 text-dark-75">
                                        {`${customer?.firstName} ${customer?.surname}`}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* end::User */}
                    {/* begin::Contact */}
                    <div className="py-9">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="font-weight-bold mr-2">
                                <FormattedMessage id="CUSTOMER.CREATED_AT" />
                            </span>
                            <span className="text-muted text-hover-primary">
                                {moment(customer?.createdAt).format("YYYY-MM-DD HH:mm")}
                            </span>
                        </div>
                        {customer?.deletedAt && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="CUSTOMER.DELETED_AT" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {moment(customer?.deletedAt).format("YYYY-MM-DD HH:mm")}
                                </span>
                            </div>
                        )}
                        {customer?.deletedBy && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-danger font-weight-bold mr-2">
                                    <FormattedMessage id="CUSTOMER.DELETED_BY" />
                                </span>
                                <span className="text-danger text-hover-primary">
                                    {`${customer.deletedBy.firstName} ${customer.deletedBy.surname}`}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* end::Contact */}
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/customers/${customerId}/details`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="CUSTOMER.DETAILS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            <NavLink
                                to={`/customers/${customerId}/bookings`}
                                className="navi-link py-4"
                                activeClassName="active"
                            >
                                <span className="navi-icon mr-2">
                                    <span className="svg-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}></SVG>{" "}
                                    </span>
                                </span>
                                <span className="navi-text font-size-lg">
                                    <FormattedMessage id="CUSTOMER.BOOKINGS.TITLE" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {isAtLeastStaffPermission(profile.permissionId) && (
                        <>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/customers/${customerId}/booking-resources`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Flatten.svg")}></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="CUSTOMER.BOOKING_RESOURCES.TITLE" />
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/customers/${customerId}/transactions`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="CUSTOMER.PAYMENTS.TITLE" />
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/customers/${customerId}/subscriptions`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")}></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="CUSTOMER.SUBSCRIPTIONS.TITLE" />
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/customers/${customerId}/vouchers`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="CUSTOMER.VOUCHERS.TITLE" />
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/customers/${customerId}/memberships`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")}></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="CUSTOMER.MEMBERSHIPS.TITLE" />
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/customers/${customerId}/relatives`}
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                                                ></SVG>{" "}
                                            </span>
                                        </span>
                                        <span className="navi-text font-size-lg">
                                            <FormattedMessage id="CUSTOMER.USER_PROXIES.TITLE" />
                                        </span>
                                    </NavLink>
                                </div>
                            </div>
                        </>
                    )}
                    {/* end::Nav */}
                </Card.Body>
                {/* end::Body */}
            </Card>
            <YesNoDialog
                title={<FormattedMessage id="CUSTOMER.DELETE.TEXT" />}
                open={showDeleteConfirmation}
                onOpenChanged={() => setShowDeleteConfirmation(false)}
                onActionClicked={onDeleteDialogActionClicked}
            />
        </div>
    );
}

export default connect(null, customerActions)(CustomerPageNavigator);
