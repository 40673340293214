import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import VoucherPageNavigator from "./VoucherPageNavigator";
import VoucherPageDetails from "./VoucherPageDetails";
import VoucherPageBookingSettings from "./VoucherPageBookingSettings";
import VoucherPageCheckoutSettings from "./VoucherPageCheckoutSettings";
import ServicePageAddons from "../ServicePageAddons";
import VoucherPageServices from "./VoucherPageServices";
import VoucherPageBookings from "./VoucherPageBookings";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import SnackbarError from "../../../components/SnackbarError";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

function VoucherPage({ match, getVoucher, clearServiceState, clearDisplayError, clearErrorState }) {
    const serviceId = parseInt(match.params.id, 10);
    const { voucher, isLoading, isUpdating } = useSelector((state) => state.services);
    const { apiError, displayError } = useSelector((state) => state.errors);
    const classes = useStyles();

    useEffect(() => {
        if (!isLoading && !apiError && (!voucher || voucher.id !== serviceId)) {
            getVoucher(serviceId);
        }
    }, [getVoucher, voucher, serviceId, isLoading, apiError]);

    useEffect(() => {
        return () => {
            clearServiceState();
            clearErrorState();
        };
    }, [clearServiceState, clearErrorState]);

    return (
        <PageBackdrop isLoading={isLoading || isUpdating}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <div className={classes.root}>
                <VoucherPageNavigator serviceId={serviceId} />
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Redirect
                            from={`/services/${serviceId}/voucher`}
                            exact={true}
                            to={`/services/${serviceId}/voucher/details`}
                        />
                        <Route path={`/services/${serviceId}/voucher/details`} component={VoucherPageDetails} />
                        <Route
                            path={`/services/${serviceId}/voucher/booking-settings`}
                            component={VoucherPageBookingSettings}
                        />
                        <Route
                            path={`/services/${serviceId}/voucher/checkout-settings`}
                            component={VoucherPageCheckoutSettings}
                        />
                        <Route
                            path={`/services/${serviceId}/voucher/addons`}
                            render={(props) => <ServicePageAddons {...props} service={voucher} />}
                        />
                        <Route path={`/services/${serviceId}/voucher/services`} component={VoucherPageServices} />
                        <Route path={`/services/${serviceId}/voucher/bookings`} component={VoucherPageBookings} />
                    </Switch>
                </div>
            </div>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...serviceActions,
    ...errorActions,
})(VoucherPage);
