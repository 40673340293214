import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { actions as accessyActions } from "../../../../redux/integrations/accessy/accessyRedux";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import PageBackdrop from "../../../components/spinners/PageBackdrop";
import {
    Paper,
    makeStyles,
    Typography,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import EditKeyServiceIcon from "@material-ui/icons/EditOutlined";
import DeleteKeyServiceIcon from "@material-ui/icons/DeleteOutlined";
import ChipSuccess from "../../../components/chips/ChipSuccess";
import AccessyDeviceServiceModal from "./AccessyDeviceServiceModal";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import SnackbarError from "../../../components/SnackbarError";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    locationForm: {
        width: "75%",
    },
    keyChip: {
        marginLeft: theme.spacing(2),
    },
}));

const AccordionDetailsStyled = withStyles({
    root: {
        padding: "0px 40px 0px 40px",
    },
})(AccordionDetails);

const ListStyled = withStyles({
    root: {
        width: "100%",
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const ListItemStyled = withStyles({
    gutters: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
})(ListItem);

const KeyAccordionStyled = withStyles({
    root: {
        backgroundColor: "transparent",
    },
})(Accordion);

const KeyAccordionSummaryStyled = withStyles({
    root: {
        minHeight: "48px",
        "&$expanded": {
            minHeight: "48px",
            margin: "0px 0",
        },
    },
    content: {
        margin: "0px 0",
        alignItems: "center",
        justifyContent: "space-between",
        "&$expanded": {
            margin: "0px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

function AccessyDetailsPage({
    match,
    getAccessyIntegration,
    getAccessyAccessDevices,
    manageAccessyAccessDevice,
    cancelManageAccessyAccessDeviceService,
    createAccessyAccessDeviceService,
    updateAccessDeviceService,
    deleteAccessyAccessDeviceService,
    getServices,
    getServiceResources,
    clearDisplayError,
}) {
    const integrationId = match.params.id;
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, integration, accessDevicesPagination, managingAccessDevice } = useSelector(
        (state) => state.accessy
    );
    const { listPagination: serviceListPagination, serviceResourcesPagination } = useSelector(
        (state) => state.services
    );
    const { displayError } = useSelector((state) => state.errors);
    const [accessServiceDeletion, setAccessServiceDeletion] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        getAccessyIntegration(integrationId);
        getServices(profile.id, 1, 9999);
    }, [getAccessyIntegration, getServices, integrationId, profile.id]);

    const fetchKeys = useCallback(
        ({ pageIndex, pageSize }) => {
            getAccessyAccessDevices(integrationId, pageIndex + 1, pageSize);
        },
        [getAccessyAccessDevices, integrationId]
    );

    const onAddAccessDeviceServiceClicked = useCallback(
        (event, accessDevice) => {
            event.stopPropagation();
            manageAccessyAccessDevice(accessDevice);
        },
        [manageAccessyAccessDevice]
    );

    const onAccessDeviceEditClicked = useCallback(
        (accessDevice, accessDeviceService) => {
            manageAccessyAccessDevice(accessDevice, accessDeviceService);
        },
        [manageAccessyAccessDevice]
    );

    const onAccessDerviceServiceDeleteClicked = useCallback(
        (accessDeviceService) => {
            setAccessServiceDeletion(accessDeviceService);
        },
        [setAccessServiceDeletion]
    );

    const onAddAccessDeviceServiceModalCloseClicked = () => {
        cancelManageAccessyAccessDeviceService();
    };

    const onAccessDeviceServiceSaveClicked = (
        managingAccessDevice,
        managingAccessDeviceService,
        serviceId,
        isAllResources,
        resourceIds,
        prefixMinutes,
        postfixMinutes,
        messageToCustomer
    ) => {
        if (!managingAccessDeviceService) {
            createAccessyAccessDeviceService(
                parseInt(integrationId, 10),
                managingAccessDevice.externalId,
                serviceId,
                isAllResources,
                resourceIds,
                prefixMinutes,
                postfixMinutes,
                messageToCustomer
            );
        } else {
            updateAccessDeviceService(
                managingAccessDeviceService.id,
                managingAccessDeviceService,
                serviceId,
                isAllResources,
                resourceIds,
                prefixMinutes,
                postfixMinutes,
                messageToCustomer
            );
        }
    };

    const onDeleteDialogOpenChanged = () => {
        setAccessServiceDeletion(null);
    };

    const onDeleteDialogActionClicked = (action) => {
        if (action === ACTION_YES) {
            deleteAccessyAccessDeviceService(accessServiceDeletion.id);
        }
        setAccessServiceDeletion(null);
    };

    const keysColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="INTEGRATION.ACCESSY.DETAILS.ASSETS.LIST.HEADER" />,
                accessor: "id",
                width: "7%",
                Cell: ({ row }) => {
                    const accessDevice = row.original;
                    const accessDeviceServices = accessDevice.deviceServices;
                    const serviceCount = accessDeviceServices.length;
                    return (
                        <KeyAccordionStyled elevation={0} variant={"elevation"}>
                            <KeyAccordionSummaryStyled expandIcon={serviceCount > 0 ? <ExpandMoreIcon /> : null}>
                                <Typography variant="subtitle1">{accessDevice.name}</Typography>

                                <div>
                                    {serviceCount > 0 && (
                                        <ChipSuccess
                                            label={
                                                <FormattedMessage
                                                    id={
                                                        serviceCount === 1
                                                            ? "INTEGRATION.ACCESSY.DETAILS.ASSETS.SERVICE_COUNT_SINGLE"
                                                            : "INTEGRATION.ACCESSY.DETAILS.ASSETS.SERVICE_COUNT_MULTIPLE"
                                                    }
                                                    values={{ serviceCount }}
                                                />
                                            }
                                            style={{ marginRight: "8px" }}
                                            size="small"
                                        />
                                    )}
                                    <IconButton
                                        size="small"
                                        onClick={(event) => onAddAccessDeviceServiceClicked(event, accessDevice)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </KeyAccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <ListStyled>
                                    {accessDeviceServices.map((x, i, items) => {
                                        return (
                                            <div
                                                key={accessDevice.id + "_" + x.service.id}
                                                style={{
                                                    marginBottom: i === items.length - 1 ? "8px" : "0px",
                                                }}
                                            >
                                                <ListItemStyled>
                                                    <ListItemText
                                                        primary={x.service.name}
                                                        primaryTypographyProps={{
                                                            variant: "body1",
                                                        }}
                                                    ></ListItemText>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onAccessDerviceServiceDeleteClicked(x)}
                                                        style={{ marginRight: "8px" }}
                                                    >
                                                        <DeleteKeyServiceIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onAccessDeviceEditClicked(accessDevice, x)}
                                                    >
                                                        <EditKeyServiceIcon />
                                                    </IconButton>
                                                </ListItemStyled>
                                                {i < items.length - 1 && <Divider />}
                                            </div>
                                        );
                                    })}
                                </ListStyled>
                            </AccordionDetailsStyled>
                        </KeyAccordionStyled>
                    );
                },
            },
        ],
        [onAddAccessDeviceServiceClicked, onAccessDeviceEditClicked, onAccessDerviceServiceDeleteClicked]
    );

    return (
        <PageBackdrop isLoading={isLoading}>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Paper className={classes.content}>
                {integration && (
                    <div className={classes.locationForm}>
                        <Typography variant="h4" className={classes.header}>
                            <FormattedMessage
                                id="INTEGRATION.ACCESSY.DETAILS.TITLE"
                                values={{ location: integration.accountId }}
                            />
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            <FormattedMessage id="INTEGRATION.ACCESSY.DETAILS.DESCRIPTION" />
                        </Typography>
                        <MaterialReactTable
                            key={integrationId}
                            data={accessDevicesPagination.data}
                            fetchData={fetchKeys}
                            pageSize={10}
                            rowCount={accessDevicesPagination.totalRows}
                            isLoading={isLoading}
                            columns={keysColumns}
                            showHeader={true}
                            getCustomCellProps={() => ({
                                style: { paddingLeft: "0px", paddingRight: "8px" },
                            })}
                            className={classes.keysTable}
                        />
                        {managingAccessDevice && (
                            <AccessyDeviceServiceModal
                                show={managingAccessDevice ? true : false}
                                services={serviceListPagination.data}
                                serviceResourcesPagination={serviceResourcesPagination}
                                getServiceResources={getServiceResources}
                                onCloseClicked={onAddAccessDeviceServiceModalCloseClicked}
                                onSaveClicked={onAccessDeviceServiceSaveClicked}
                            />
                        )}
                    </div>
                )}
                <YesNoDialog
                    title={<FormattedMessage id="INTEGRATION.ACCESSY.DETAILS.ACCESS_DEVICE.SERVICE.DELETE.TITLE" />}
                    open={accessServiceDeletion ? true : false}
                    onOpenChanged={onDeleteDialogOpenChanged}
                    onActionClicked={onDeleteDialogActionClicked}
                />
            </Paper>
        </PageBackdrop>
    );
}

export default connect(null, {
    ...accessyActions,
    ...serviceActions,
    ...errorActions,
})(AccessyDetailsPage);
