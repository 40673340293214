import React, { useMemo, useCallback } from "react";
import { actions as campaignActions } from "../../../../redux/campaigns/campaignRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, AvatarCell } from "../../../components/tables/MaterialTableCells";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import {
    CAMPAIGN_STATUS_DRAFT,
    CAMPAIGN_STATUS_SCHEDULED,
    CAMPAIGN_STATUS_STARTED,
    CAMPAIGN_STATUS_FINISHED,
    CAMPAIGN_STATUS_ERROR,
} from "../utils";

const useToolbarStyles = makeStyles((theme) => ({
    alertContainer: {
        margin: theme.spacing(2),
    },
}));

function CampaignSMSPageRecipientsPreview({ getCampaignSmsPreviewRecipients }) {
    const { campaign, smsPreviewRecipientsPagination, isSmsPreviewRecipientsLoading } = useSelector(
        (state) => state.campaigns
    );
    const { displayError } = useSelector((state) => state.errors);
    const campaignId = campaign?.id;
    const classes = useToolbarStyles();

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (
                campaign &&
                (campaign.status === CAMPAIGN_STATUS_DRAFT || campaign.status === CAMPAIGN_STATUS_SCHEDULED)
            ) {
                getCampaignSmsPreviewRecipients(campaign.id, pageIndex + 1, pageSize);
            }
        },
        [campaign, getCampaignSmsPreviewRecipients]
    );

    const recipientsData = useMemo(() => smsPreviewRecipientsPagination.data, [smsPreviewRecipientsPagination.data]);
    const recipientsColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.LIST.NAME" />,
                accessor: "name",
                Cell: ({ row }) => {
                    return (
                        <Link to={`/customers/${row.original.customer.id}`}>
                            {AvatarCell(
                                row.original.customer.avatarUrl,
                                `${row.original.customer.firstName} ${row.original.customer.surname}`
                            )}
                        </Link>
                    );
                },
            },
            {
                Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.LIST.PHONE" />,
                accessor: "phoneNumber",
                Cell: ({ row }) => {
                    return TextCell(row.original.customer.phoneNumber);
                },
            },
        ],
        []
    );

    if (
        campaign?.status === CAMPAIGN_STATUS_STARTED ||
        campaign?.status === CAMPAIGN_STATUS_FINISHED ||
        campaign?.status === CAMPAIGN_STATUS_ERROR
    ) {
        return <Redirect to={`/campaigns/${campaign.id}/sms/recipients`} />;
    }

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.HEADER" />
                        </h3>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <Alert severity="info" className={classes.alertContainer}>
                        <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.ALERT_INFO" />
                    </Alert>
                    <MaterialReactTable
                        key={campaignId}
                        data={recipientsData}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={smsPreviewRecipientsPagination.totalRows}
                        isLoading={isSmsPreviewRecipientsLoading}
                        error={displayError}
                        columns={recipientsColumns}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...campaignActions, ...errorActions })(CampaignSMSPageRecipientsPreview);
