import React from "react";
import { useIntl } from "react-intl";
import { makeStyles, Typography } from "@material-ui/core";
import { getApiErrorMessageDetailed } from "../../utils/ApiUtils";

const useStyles = makeStyles(() => ({
    container: {
        position: "absolute",
        left: "50%",
        top: "50%",
        maxWidth: "400px",
        textAlign: "center",
    },
    text: {
        position: "relative",
        left: "-50%",
    },
}));

export default function MaterialErrorMessage({ error }) {
    const classes = useStyles();
    const intl = useIntl();

    let message = "";
    if (typeof error === "object" && error !== null) {
        if (error.message) return error.message;
        if (error.code) return error.code;
        if (error.response) {
            message = getApiErrorMessageDetailed(error, intl);
        }
    }

    return (
        <div className={classes.container}>
            <Typography className={classes.text} variant="body1">
                {message}
            </Typography>
        </div>
    );
}
