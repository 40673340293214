import React, { useEffect } from "react";
import { actions as parakeyActions } from "../../../../redux/integrations/parakey/parakeyRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { makeStyles, Typography } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    locationForm: {
        width: "75%",
    },
    keyChip: {
        marginLeft: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    domain: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

function ParakeyPageDetails({ getParakeyDomains, updateParakeyIntegration }) {
    const { integration, domains, isUpdating } = useSelector((state) => state.parakey);
    const classes = useStyles();

    useEffect(() => {
        if (integration && !integration.accountId) {
            getParakeyDomains(integration.id);
        }
    }, [getParakeyDomains, integration]);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        updateParakeyIntegration(integration.id, integration, values.domain);
    };

    const getSelectLocationUI = () => {
        return (
            <div>
                <Typography variant="h4" className={classes.header}>
                    <FormattedMessage id="INTEGRATION.PARAKEY.SELECT_DOMAIN.TITLE" />
                </Typography>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage id="INTEGRATION.PARAKEY.SELECT_DOMAIN.DESCRIPTION" />
                </Typography>
                <Form.Group>
                    <Form.Control
                        as="select"
                        ref={register}
                        name="domain"
                        className="form-control form-control-lg col-xl-4"
                    >
                        {domains &&
                            domains.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}>
                                        {x.name}
                                    </option>
                                );
                            })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.domain?.message}</Form.Control.Feedback>
                </Form.Group>
                <BootstrapSaveButton
                    isLoading={isUpdating}
                    onClick={handleSubmit(onFormSubmit)}
                    label={<FormattedMessage id="INTEGRATION.PARAKEY.SELECT_DOMAIN.BUTTON" />}
                />
            </div>
        );
    };

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="INTEGRATION.FORTNOX.DETAILS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        {integration && !integration.accountId && getSelectLocationUI()}
                        {integration && integration.accountId && (
                            <>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="INTEGRATION.PARAKEY.DETAILS.ACCOUNT_ID" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control form-control-lg col-xl-4"
                                        name="accountId"
                                        ref={register}
                                        defaultValue={integration?.accountId}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="INTEGRATION.PARAKEY.DETAILS.ACCOUNT_NAME" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control form-control-lg col-xl-4"
                                        name="accountName"
                                        ref={register}
                                        defaultValue={integration?.accountName}
                                    />
                                </Form.Group>
                            </>
                        )}
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, parakeyActions)(ParakeyPageDetails);
