import { put, takeLatest } from "redux-saga/effects";
import {
    createAccessyIntegration,
    getAccessyAccessDevices,
    createAccessyAccessDeviceService,
    updateAccessDeviceService,
    deleteAccessyAccessDeviceService,
} from "./accessyCrud";
import { getIntegration } from "../integrationCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../../errors/errorsRedux";

export const actionTypes = {
    GET_INTEGRATION_ACCESSY_REQUEST: "GET_INTEGRATION_ACCESSY_REQUEST",
    GET_INTEGRATION_ACCESSY_SUCCESS: "GET_INTEGRATION_ACCESSY_SUCCESS",
    CREATE_INTEGRATION_ACCESSY_REQUEST: "CREATE_INTEGRATION_ACCESSY_REQUEST",
    CREATE_INTEGRATION_ACCESSY_SUCCESS: "CREATE_INTEGRATION_ACCESSY_SUCCESS",
    GET_ACCESSY_ACCESS_DEVICES_REQUEST: "GET_ACCESSY_ACCESS_DEVICES_REQUEST",
    GET_ACCESSY_ACCESS_DEVICES_SUCCESS: "GET_ACCESSY_ACCESS_DEVICES_SUCCESS",
    MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE: "MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE",
    MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE_CANCEL: "MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE_CANCEL",
    CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST: "CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST",
    CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS: "CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS",
    UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST: "UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST",
    UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS: "UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS",
    DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST: "DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST",
    DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS: "DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS",
};

const initialState = {
    isLoading: false,
    integration: null,
    accessDevicesPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTEGRATION_ACCESSY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_ACCESSY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.CREATE_INTEGRATION_ACCESSY_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_INTEGRATION_ACCESSY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
                redirectToDetails: true,
            };
        }

        case actionTypes.GET_ACCESSY_ACCESS_DEVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_ACCESSY_ACCESS_DEVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                accessDevicesPagination: action.payload.response,
            };
        }

        case actionTypes.MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE: {
            return {
                ...state,
                managingAccessDevice: action.payload.accessDevice,
                managingAccessDeviceService: action.payload.accessDeviceService,
            };
        }

        case actionTypes.MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE_CANCEL: {
            return {
                ...state,
                managingAccessDevice: null,
                managingAccessDeviceService: null,
            };
        }

        case actionTypes.CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS: {
            const accessDeviceListPagination = state.accessDevicesPagination;
            const index = accessDeviceListPagination.data.findIndex(
                (item) => item.externalId === action.payload.response.accessDeviceExternalId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...accessDeviceListPagination.data];
                let accessDevice = alteredPaginationData[index];
                if (accessDevice.deviceServices) {
                    accessDevice.deviceServices.push(action.payload.response);
                } else {
                    accessDevice.deviceServices = [action.payload.response];
                }
            }
            return {
                ...state,
                isLoading: false,
                managingAccessDevice: null,
                managingAccessDeviceService: null,
                accessDevicesPagination: {
                    ...state.accessDevicesPagination,
                    data: alteredPaginationData || accessDeviceListPagination.data,
                },
            };
        }

        case actionTypes.UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS: {
            const accessDeviceListPagination = state.accessDevicesPagination;
            const index = accessDeviceListPagination.data.findIndex(
                (item) => item.externalId === action.payload.response.accessDeviceExternalId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...accessDeviceListPagination.data];
                let accessDevice = alteredPaginationData[index];
                if (accessDevice.deviceServices) {
                    const accessDeviceServiceIndex = accessDevice.deviceServices.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (accessDeviceServiceIndex > -1) {
                        accessDevice.deviceServices[accessDeviceServiceIndex] = action.payload.response;
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingAccessDevice: null,
                managingAccessDeviceService: null,
                accessDevicesPagination: {
                    ...state.accessDevicesPagination,
                    data: alteredPaginationData || accessDeviceListPagination.data,
                },
            };
        }

        case actionTypes.DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS: {
            const accessDeviceListPagination = state.accessDevicesPagination;
            const index = accessDeviceListPagination.data.findIndex(
                (item) => item.externalId === action.payload.response.accessDeviceExternalId
            );
            let alteredPaginationData;
            if (index > -1) {
                alteredPaginationData = [...accessDeviceListPagination.data];
                let accessDevice = alteredPaginationData[index];
                if (accessDevice.deviceServices) {
                    const accessDeviceServiceIndex = accessDevice.deviceServices.findIndex(
                        (item) => item.id === action.payload.response.id
                    );
                    if (accessDeviceServiceIndex > -1) {
                        accessDevice.deviceServices.splice(accessDeviceServiceIndex, 1);
                    }
                }
            }
            return {
                ...state,
                isLoading: false,
                managingAccessDevice: null,
                managingAccessDeviceService: null,
                accessDevicesPagination: {
                    ...state.accessDevicesPagination,
                    data: alteredPaginationData || accessDeviceListPagination.data,
                },
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false };
        }

        default:
            return state;
    }
};

export const actions = {
    getAccessyIntegration: (id) => ({
        type: actionTypes.GET_INTEGRATION_ACCESSY_REQUEST,
        payload: { id },
    }),

    createAccessyIntegration: (profileId, clientId, clientSecret, organizationId) => ({
        type: actionTypes.CREATE_INTEGRATION_ACCESSY_REQUEST,
        payload: { profileId, clientId, clientSecret, organizationId },
    }),

    getAccessyAccessDevices: (id, page, perPage) => ({
        type: actionTypes.GET_ACCESSY_ACCESS_DEVICES_REQUEST,
        payload: { id, page, perPage },
    }),

    manageAccessyAccessDevice: (accessDevice, accessDeviceService) => ({
        type: actionTypes.MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE,
        payload: { accessDevice, accessDeviceService },
    }),

    cancelManageAccessyAccessDeviceService: () => ({
        type: actionTypes.MANAGE_ACCESSY_ACCESS_DEVICE_SERVICE_CANCEL,
        payload: {},
    }),

    createAccessyAccessDeviceService: (
        integrationId,
        accessDeviceExternalId,
        serviceId,
        isAllResources,
        resourceIds,
        prefixMinutes,
        postfixMinutes,
        messageToCustomer
    ) => ({
        type: actionTypes.CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST,
        payload: {
            integrationId,
            accessDeviceExternalId,
            serviceId,
            isAllResources,
            resourceIds,
            prefixMinutes,
            postfixMinutes,
            messageToCustomer,
        },
    }),

    updateAccessDeviceService: (
        integrationId,
        originalAccessDeviceService,
        serviceId,
        isAllResources,
        resourceIds,
        prefixMinutes,
        postfixMinutes,
        messageToCustomer
    ) => ({
        type: actionTypes.UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST,
        payload: {
            integrationId,
            originalAccessDeviceService,
            serviceId,
            isAllResources,
            resourceIds,
            prefixMinutes,
            postfixMinutes,
            messageToCustomer,
        },
    }),

    deleteAccessyAccessDeviceService: (id) => ({
        type: actionTypes.DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST,
        payload: { id },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_INTEGRATION_ACCESSY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getIntegration("accessy", payload.id);

            yield put({
                type: actionTypes.GET_INTEGRATION_ACCESSY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_INTEGRATION_ACCESSY_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createAccessyIntegration(
                payload.profileId,
                payload.clientId,
                payload.clientSecret,
                payload.organizationId
            );

            yield put({
                type: actionTypes.CREATE_INTEGRATION_ACCESSY_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_ACCESSY_ACCESS_DEVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getAccessyAccessDevices(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_ACCESSY_ACCESS_DEVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createAccessyAccessDeviceService(
                payload.integrationId,
                payload.accessDeviceExternalId,
                payload.serviceId,
                payload.isAllResources,
                payload.resourceIds,
                payload.prefixMinutes ? payload.prefixMinutes * 60 : null,
                payload.postfixMinutes ? payload.postfixMinutes * 60 : null,
                payload.messageToCustomer
            );

            yield put({
                type: actionTypes.CREATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield updateAccessDeviceService(
                payload.integrationId,
                payload.serviceId,
                payload.isAllResources,
                payload.resourceIds,
                payload.prefixMinutes ? payload.prefixMinutes * 60 : null,
                payload.postfixMinutes ? payload.postfixMinutes * 60 : null,
                payload.messageToCustomer
            );
            yield put({
                type: actionTypes.UPDATE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteAccessyAccessDeviceService(payload.id);
            yield put({
                type: actionTypes.DELETE_ACCESSY_ACCESS_DERVICE_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
