import React, { useEffect, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { actions as payoutsAction } from "../../../redux/payouts/payoutsRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { FormattedMessage } from "react-intl";
import { Paper, IconButton } from "@material-ui/core";
import { LinkCell, TextCell, DateTimeCell } from "../../components/tables/MaterialTableCells";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import PayoutListToolbar from "../../components/tables/toolbar/PayoutListToolbar";

function PayoutListPage({ getPayouts, downloadPayoutDocument, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading, listPagination, isDownloadingDocument } = useSelector((state) => state.payouts);
    const { displayError } = useSelector((state) => state.errors);

    useEffect(() => {
        return () => {
            clearErrorState();
        };
    }, [clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getPayouts(profile.id, pageIndex + 1, pageSize);
        },
        [getPayouts, profile.id]
    );

    return (
        <Paper>
            <MaterialReactTable
                key={profile.id}
                data={useMemo(() => listPagination.data, [listPagination.data])}
                fetchData={fetchData}
                rowCount={listPagination.totalRows}
                isLoading={isLoading}
                error={displayError}
                renderToolbar={() => {
                    return <PayoutListToolbar />;
                }}
                columns={useMemo(
                    () => [
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.ID" />,
                            accessor: "id",
                            width: "7%",
                            Cell: (props) => {
                                return LinkCell("/payouts/" + props.value, props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.PERIOD_START" />,
                            accessor: "periodStart",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.PERIOD_END" />,
                            accessor: "periodEnd",
                            Cell: (props) => {
                                return DateTimeCell(props.value);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.PAYOUT_AMOUNT_CARD" />,
                            accessor: "payoutAmountCard",
                            Cell: ({ row }) => {
                                return TextCell(`${row.original.payoutAmountCard} ${row.original.currency}`);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.PAYOUT_AMOUNT_SWISH" />,
                            accessor: "payoutAmountSwish",
                            Cell: ({ row }) => {
                                return TextCell(`${row.original.payoutAmountSwish} ${row.original.currency}`);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.PAYOUT_AMOUNT_TOTAL" />,
                            accessor: "payoutAmountTotal",
                            Cell: ({ row }) => {
                                return TextCell(`${row.original.payoutAmountTotal} ${row.original.currency}`);
                            },
                        },
                        {
                            Header: <FormattedMessage id="PAYOUT.LIST.HEADER.DOWNLOAD" />,
                            id: "actions",
                            Cell: ({ row }) => {
                                return (
                                    <IconButton
                                        disabled={isDownloadingDocument}
                                        onClick={() =>
                                            downloadPayoutDocument(row.original.id, `Payout ${row.original.id}`)
                                        }
                                    >
                                        <CloudDownloadOutlinedIcon />
                                    </IconButton>
                                );
                            },
                        },
                    ],
                    [downloadPayoutDocument, isDownloadingDocument]
                )}
                getCustomCellProps={() => ({
                    style: { height: "64px" },
                })}
            />
        </Paper>
    );
}

export default connect(null, { ...payoutsAction, ...errorActions })(PayoutListPage);
