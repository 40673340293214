import axios from "axios";
import { getApiUrl } from "../../../app/utils/ApiUtils";

export const INTEGRATIONS_URL = "v1/admin/profiles/{profileId}/integrations";
export const BANKID_SERVICES_URL = "v1/admin/integrations/:id/bankid/services";
export const BANKID_SERVICE_DELETE_URL = "v1/admin/bankid/services/:id";

export function createBankIDIntegration(profileId) {
    const url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "/bankid";
    return axios.post(getApiUrl() + url, {});
}

export function getBankIDServices(id, page, perPage) {
    let url = BANKID_SERVICES_URL.replace(":id", id) + "?page=" + page + "&perPage=" + perPage;
    return axios.get(getApiUrl() + url);
}

export function addBankIDServicesAndCategories(id, items, userVisibleData) {
    let url = BANKID_SERVICES_URL.replace(":id", id);
    return axios.post(getApiUrl() + url, { items, userVisibleData });
}

export function deleteBankIDService(bankIDServiceId) {
    let url = BANKID_SERVICE_DELETE_URL.replace(":id", bankIDServiceId);
    return axios.delete(getApiUrl() + url);
}
