import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
    SERVICE_TYPE_APPOINTMENT,
    SERVICE_TYPE_COURSE,
    SERVICE_TYPE_VOUCHER,
    SERVICE_TYPE_MEMBERSHIP,
    SERVICE_TYPE_PRODUCT,
} from "../../../../pages/services/utils";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function ServiceTypeFilter({ onTypeChanged }) {
    const classes = useStyles();
    const [type, setType] = useState("all");

    const handleChange = ({ target: { value } }) => {
        setType(value);
        onTypeChanged(value);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="type-filter-label">
                <FormattedMessage id="SERVICE.FILTER.TYPE.HEADER" />
            </InputLabel>
            <Select labelId="type-filter-label" id="type-filter" value={type} onChange={handleChange}>
                <MenuItem value={"all"}>
                    <FormattedMessage id="COMMON.FILTER.STATUS.ALL" />
                </MenuItem>
                <MenuItem value={SERVICE_TYPE_APPOINTMENT}>
                    <FormattedMessage id="SERVICE.TYPE.APPOINTMENT" />
                </MenuItem>
                <MenuItem value={SERVICE_TYPE_COURSE}>
                    <FormattedMessage id="SERVICE.TYPE.COURSE" />
                </MenuItem>
                <MenuItem value={SERVICE_TYPE_MEMBERSHIP}>
                    <FormattedMessage id="SERVICE.TYPE.MEMBERSHIP" />
                </MenuItem>
                <MenuItem value={SERVICE_TYPE_VOUCHER}>
                    <FormattedMessage id="SERVICE.TYPE.VOUCHER" />
                </MenuItem>
                <MenuItem value={SERVICE_TYPE_PRODUCT}>
                    <FormattedMessage id="SERVICE.TYPE.PRODUCT" />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default ServiceTypeFilter;
