import React, { useMemo, useCallback, useState } from "react";
import { actions as fortnoxActions } from "../../../../redux/integrations/fortnox/fortnoxRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import {
    DateTimeCell,
    TextCell,
    TextCellEllipsis,
    LinkCellEllipsis,
} from "../../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import FortnoxServiceModal from "./FortnoxServiceModal";
import ListActionsButton from "../../../components/buttons/ListActionsButton";
import LinkIcon from "@material-ui/icons/Link";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { YesNoDialog, ACTION_YES } from "../../../components/YesNoDialog";
import FortnoxServiceListToolbar from "../../../components/tables/toolbar/FortnoxServiceListToolbar";
import { getServiceDetailsPageUrl } from "../../services/utils";
import { isAtLeastStaffPermission } from "../../resources/utils";

const useToolbarStyles = makeStyles((theme) => ({
    alertContainer: {
        margin: theme.spacing(2),
    },
}));

function FortnoxPageServices({ getFortnoxServices, addFortnoxService, updateFortnoxService, deleteFortnoxService }) {
    const { integration, fortnoxServicesPagination, isLoading, isUpdating } = useSelector((state) => state.fortnox);
    const { displayError } = useSelector((state) => state.errors);
    const { profile } = useSelector((state) => state.auth);
    const integrationId = integration?.id;
    const classes = useToolbarStyles();

    const [search, setSearch] = useState("");
    const [serviceModalMode, setServiceModalMode] = useState(null);
    const [serviceToEdit, setServiceToEdit] = useState(null);
    const [serviceToDelete, setServiceToDelete] = useState(null);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (integrationId) {
                getFortnoxServices(integrationId, pageIndex + 1, pageSize, search);
            }
        },
        [integrationId, getFortnoxServices, search]
    );

    const onAddModalSaveClicked = (serviceData) => {
        if (serviceModalMode === "add") {
            addFortnoxService(integrationId, serviceData, () => {
                setServiceModalMode(null);
                getFortnoxServices(integrationId, 1, 10);
            });
        } else if (serviceModalMode === "edit" && serviceToEdit) {
            let originalData = {
                serviceId: serviceToEdit.service.id,
                fortnoxArticleNumber: serviceToEdit.fortnoxArticleNumber,
                fortnoxAccountNumber: serviceToEdit.fortnoxAccountNumber,
                fortnoxCostCenter: serviceToEdit.fortnoxCostCenter,
            };
            updateFortnoxService(integrationId, serviceToEdit.id, originalData, serviceData, () => {
                setServiceModalMode(null);
                setServiceToEdit(null);
                getFortnoxServices(integrationId, 1, 10);
            });
        }
    };

    const onSearchChanged = useCallback((text) => {
        setSearch(text);
    }, []);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.SUBTITLE" />
                        </span>
                    </div>
                    {integration && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                label={<FormattedMessage id="COMMON.ADD" />}
                                onClick={() => setServiceModalMode("add")}
                            ></BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <Alert severity="info" className={classes.alertContainer}>
                        <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.INFO" />
                    </Alert>
                    <MaterialReactTable
                        key={integrationId}
                        data={useMemo(() => fortnoxServicesPagination.data, [fortnoxServicesPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={fortnoxServicesPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        renderToolbar={() => {
                            return <FortnoxServiceListToolbar onSearchChanged={onSearchChanged} />;
                        }}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    maxWidth: 75,
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.LIST.HEADER.SERVICE" />,
                                    accessor: "service",
                                    maxWidth: 275,
                                    Cell: (props) => {
                                        if (isAtLeastStaffPermission(profile.permissionId)) {
                                            const url = getServiceDetailsPageUrl(props.value.type, props.value.id);
                                            if (!url) return TextCellEllipsis(props.value.name);
                                            return LinkCellEllipsis(url, props.value.name);
                                        }

                                        return TextCellEllipsis(props.value.name);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.LIST.HEADER.CREATED_AT" />
                                    ),
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.LIST.HEADER.ARTICLE_NUMBER" />
                                    ),
                                    accessor: "fortnoxArticleNumber",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.LIST.HEADER.ACCOUNT_NUMBER" />
                                    ),
                                    accessor: "fortnoxAccountNumber",
                                    maxWidth: 100,
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: (
                                        <FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.LIST.HEADER.COST_CENTER" />
                                    ),
                                    accessor: "fortnoxCostCenter",
                                    maxWidth: 125,
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                                    id: "delete",
                                    Cell: ({ row }) => {
                                        return (
                                            <ListActionsButton
                                                menuItems={[
                                                    {
                                                        icon: <LinkIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.EDIT" />,
                                                        onClick: () => {
                                                            setServiceModalMode("edit");
                                                            setServiceToEdit(row.original);
                                                        },
                                                    },
                                                    {
                                                        icon: <DeleteOutlinedIcon fontSize="small" />,
                                                        label: <FormattedMessage id="COMMON.DELETE" />,
                                                        onClick: () => setServiceToDelete(row.original),
                                                    },
                                                ]}
                                            />
                                        );
                                    },
                                },
                            ],
                            [profile.permissionId]
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
            {serviceModalMode && (
                <FortnoxServiceModal
                    show={serviceModalMode ? true : false}
                    fortnoxServiceToEdit={serviceModalMode === "edit" ? serviceToEdit : null}
                    onCloseClicked={() => setServiceModalMode(null)}
                    onSaveClicked={onAddModalSaveClicked}
                />
            )}
            <YesNoDialog
                title={<FormattedMessage id="INTEGRATION.FORTNOX.SERVICES.DELETE.TEXT" />}
                open={serviceToDelete ? true : false}
                onOpenChanged={() => setServiceToDelete(null)}
                onActionClicked={(action) => {
                    if (action === ACTION_YES) {
                        deleteFortnoxService(integrationId, serviceToDelete.id);
                    }
                    setServiceToDelete(null);
                }}
            />
        </Card>
    );
}
export default connect(null, { ...fortnoxActions, ...errorActions })(FortnoxPageServices);
