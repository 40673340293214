import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Menu, MenuItem, Avatar, makeStyles, Typography, withStyles, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchField from "../../../../../app/components/SearchField";
import { actions as authActions } from "../../../../../redux/auth/authRedux";

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        textAlign: "center",
    },
    searchField: {
        width: "100%",
        padding: "8px",
    },
    profileItem: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
    },
    profileAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        margin: theme.spacing(0.3),
        marginRight: theme.spacing(1),
    },
}));

const StyledMenu = withStyles({
    paper: {
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    list: {
        padding: 0,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
));

function HeaderMenu({ getUserProfiles, searchUserProfiles, changeUserProfile }) {
    const { profile, profiles, profilesSearch } = useSelector((state) => state.auth);

    const [anchorElement, setAnchorElement] = useState(null);
    const [isSearching, setIsSearching] = useState(null);
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (!profiles || profiles.length === 0) {
            getUserProfiles();
        }
    }, [profiles, getUserProfiles]);

    const onDropdownClicked = (event) => {
        getUserProfiles();
        if (profiles?.length > 1) {
            setAnchorElement(event.currentTarget);
        }
    };

    const onProfileClicked = (profile) => {
        changeUserProfile(profile);
        handleDropdownClose();
        history.push("/overview");
    };

    const handleDropdownClose = () => {
        setAnchorElement(null);
    };

    const onSearchChanged = useCallback(
        (text) => {
            searchUserProfiles(text);
            setIsSearching(text ? true : false);
        },
        [searchUserProfiles]
    );

    let profilesToShow = !isSearching ? profiles : profilesSearch;
    if (!profilesToShow) profilesToShow = [];

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                startIcon={<Avatar alt="logo" src={profile.avatarUrl} />}
                style={{ textTransform: "none" }}
                onClick={onDropdownClicked}
                disableRipple={profilesToShow.length === 1}
            >
                <Typography className="font-weight-bold" variant="h6">
                    {profile.name}
                </Typography>
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorElement}
                keepMounted
                open={anchorElement ? true : false}
                onClose={handleDropdownClose}
            >
                <div>
                    {profiles.length > 8 && (
                        <div className={classes.searchContainer}>
                            <SearchField
                                onSearchChanged={onSearchChanged}
                                textFieldStyles={classes.searchField}
                                stopPropagation={true}
                            />
                        </div>
                    )}
                    {profilesToShow.map((profile, index) => (
                        <div key={profile.id}>
                            <MenuItem onClick={() => onProfileClicked(profile)} className={classes.profileItem}>
                                <Avatar alt="logo" src={profile.avatarUrl} className={classes.profileAvatar} />
                                <Typography className="menu-text">{profile.name}</Typography>
                            </MenuItem>
                            {index < profilesToShow.length - 1 && <Divider />}
                        </div>
                    ))}
                </div>
            </StyledMenu>
        </div>
    );
}

export default connect(null, authActions)(HeaderMenu);
