import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Controller } from "react-hook-form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { actions as serviceActions } from "../../../../redux/services/serviceRedux";

function getServiceOptions(servicePagination) {
    let options = [];
    if (!servicePagination?.data?.length) return options;
    servicePagination.data.forEach((service) => {
        options.push(getServiceOption(service));
    });
    return options;
}

function getServiceOption(service) {
    return {
        id: service.id,
        name: service.name,
        type: service.type,
    };
}

const FilterPartSelectService = ({ dataQuery, title, onSaveHasBeenClicked, getServices }) => {
    const { profile } = useSelector((state) => state.auth);
    const { isLoading: isServicesLoading, listPagination: serviceListPagination } = useSelector(
        (state) => state.services
    );

    const { register, setValue, control, watch } = useFormContext();

    useEffect(() => {
        getServices(profile.id, 1, 100);
    }, [profile.id, getServices]);

    if (!dataQuery.values || dataQuery.values.length === 0) return <></>;

    const dataQueryValue = dataQuery.values[0];

    const onServiceSearch = (search) => getServices(profile.id, 1, 100, search);

    const onServiceChanged = (services) => {
        setValue("services", services);
        setValue(
            dataQueryValue.property,
            services.map((x) => x.id)
        );
    };

    const services = watch("services") || [];
    register(dataQueryValue.property);

    return (
        <>
            <Form.Group>
                <Form.Label>{title}</Form.Label>
                <Controller
                    control={control}
                    name="services"
                    defaultValue={null}
                    render={() => (
                        <div>
                            <AsyncTypeahead
                                id="typeahead-services"
                                labelKey={"name"}
                                minLength={0}
                                clearButton={true}
                                onChange={onServiceChanged}
                                onSearch={onServiceSearch}
                                onInputChange={(input) => {
                                    if (!input) {
                                        onServiceSearch(input);
                                    }
                                }}
                                useCache={false}
                                isLoading={isServicesLoading}
                                options={getServiceOptions(serviceListPagination)}
                                selected={services}
                                multiple
                                filterBy={() => true}
                                isInvalid={dataQueryValue.mandatory && services.length === 0 && onSaveHasBeenClicked}
                                inputProps={{ style: { height: "calc(1.5em + 1.65rem + 2px)" } }}
                            />
                            {dataQueryValue.mandatory && services.length === 0 && onSaveHasBeenClicked && (
                                <div className="text-danger" style={{ fontSize: "0.9rem", marginTop: "0.25rem" }}>
                                    <FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />
                                </div>
                            )}
                        </div>
                    )}
                />
            </Form.Group>
        </>
    );
};

export default connect(null, { ...serviceActions })(FilterPartSelectService);
