import React, { useEffect } from "react";
import { actions as bookingActions } from "../../../../redux/bookings/bookingRedux";
import { connect, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import { Switch } from "@material-ui/core";
import { PAYMENT_MODE_OPTIONAL, PAYMENT_MODE_ON_ARRIVAL } from "../../services/utils";
import { isAtLeastBookingPermission } from "../../resources/utils";

function BookingPageStaffDetails({ updateBooking }) {
    const { profile } = useSelector((state) => state.auth);
    const { booking, isUpdating } = useSelector((state) => state.bookings);

    const { register, handleSubmit, control, setValue } = useForm({});

    const onFormSubmit = (values) => {
        updateBooking(booking.id, booking, values);
    };

    useEffect(() => {
        if (booking) {
            setValue("isMarkedAsPaid", booking.isMarkedAsPaid);
        }
    }, [booking, setValue]);

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom align-items-center">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="BOOKING.STAFF_DETAILS.TITLE" />
                        </h3>
                    </div>
                    {isAtLeastBookingPermission(profile.permissionId) && (
                        <div className="card-toolbar">
                            <BootstrapSaveButton isLoading={isUpdating} onClick={handleSubmit(onFormSubmit)}>
                                <FormattedMessage id="COMMON.SAVE" />
                            </BootstrapSaveButton>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>
                    <Form>
                        {booking &&
                            booking.price > 0 &&
                            (booking.service.paymentMode === PAYMENT_MODE_ON_ARRIVAL ||
                                booking.service.paymentMode === PAYMENT_MODE_OPTIONAL) && (
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="BOOKING.STAFF_DETAILS.MARKED_AS_PAID" />
                                    </Form.Label>
                                    <Controller
                                        name="isMarkedAsPaid"
                                        control={control}
                                        defaultValue={booking ? booking.isMarkedAsPaid : false}
                                        render={({ value, onChange }) => {
                                            return (
                                                <Switch checked={value} onChange={(event, value) => onChange(value)} />
                                            );
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        <FormattedMessage id="BOOKING.STAFF_DETAILS.MARKED_AS_PAID_DESCRIPTION" />
                                    </Form.Text>
                                </Form.Group>
                            )}
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="BOOKING.STAFF_DETAILS.STAFF_NOTES" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={8}
                                className="form-control-lg"
                                name="staffNotes"
                                ref={register}
                                defaultValue={booking?.staffNotes}
                            />
                        </Form.Group>
                    </Form>
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, bookingActions)(BookingPageStaffDetails);
