import axios from "axios";
import { getApiUrl } from "../../../app/utils/ApiUtils";
import * as jsonpatch from "fast-json-patch";

export const INTEGRATIONS_URL = "v1/admin/profiles/{profileId}/integrations";
export const COMPONENTS_URLS = "v1/admin/integrations/{id}/dormakaba/components";
export const COMPONENT_SERVICE_URL = "v1/admin/integrations/{id}/dormakaba/componentservices";

export function createDormakabaIntegration(profileId, apiKey, apiSecret, siteId) {
    let url = INTEGRATIONS_URL.replace("{profileId}", profileId) + "/dormakaba";
    return axios.post(getApiUrl() + url, { apiKey, apiSecret, siteId });
}

export function getDormakabaComponents(id) {
    let url = COMPONENTS_URLS.replace("{id}", id);
    return axios.get(getApiUrl() + url);
}

export function createDormakabaComponentService(
    integrationId,
    dormakabaComponentId,
    serviceId,
    prefixSeconds,
    postfixSeconds,
    messageToCustomer
) {
    let url = COMPONENT_SERVICE_URL.replace("{id}", integrationId);
    return axios.post(getApiUrl() + url, {
        dormakabaComponentId,
        serviceId,
        prefixSeconds,
        postfixSeconds,
        messageToCustomer,
    });
}

export function patchDormakabaComponentService(integrationId, originalComponentService, changedComponentService) {
    let url = COMPONENT_SERVICE_URL.replace("{id}", integrationId) + "/" + originalComponentService.id;
    return axios.patch(getApiUrl() + url, jsonpatch.compare(originalComponentService, changedComponentService), {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });
}

export function deleteDormakabaComponentService(integrationId, componentServiceId) {
    let url = COMPONENT_SERVICE_URL.replace("{id}", integrationId) + "/" + componentServiceId;
    return axios.delete(getApiUrl() + url);
}
