import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../../../redux/auth/authRedux";
import { requestPassword } from "../../../../redux/auth/authCrud";

const schema = Yup.object().shape({
    email: Yup.string()
        .email(<FormattedMessage id="FORM.ERROR.REGEX_MISMATCH" />)
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(50, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 50 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
});

function ForgotPassword(props) {
    const { email } = useParams();
    const { intl } = props;
    const [isRequested, setIsRequested] = useState(false);

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: {
            email: email || "",
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            requestPassword(values.email)
                .then(() => setIsRequested(true))
                .catch(() => {
                    setIsRequested(false);
                    setSubmitting(false);
                });
        },
    });

    return (
        <>
            {!isRequested && (
                <div className="login-form login-forgot" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">{intl.formatMessage({ id: "AUTH.FORGOTPASSWORD.TITLE" })}</h3>
                        <div className="text-muted font-weight-bold">
                            {intl.formatMessage({ id: "AUTH.FORGOTPASSWORD.SUBTITLE" })}
                        </div>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        )}
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                type="email"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "email"
                                )}`}
                                name="email"
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap flex-center">
                            <button
                                id="kt_login_forgot_submit"
                                type="submit"
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                disabled={formik.isSubmitting}
                            >
                                {intl.formatMessage({ id: "AUTH.FORGOTPASSWORD.SEND_BUTTON" })}
                            </button>
                            <Link to="/auth">
                                <button
                                    type="button"
                                    id="kt_login_forgot_cancel"
                                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                >
                                    {intl.formatMessage({ id: "AUTH.FORGOTPASSWORD.CANCEL_BUTTON" })}
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            )}
            {isRequested && (
                <div className="text-center">
                    <h4 className="my-5">
                        {intl.formatMessage({ id: "AUTH.FORGOTPASSWORD.CONFIRMAITION" })} {formik.values.email}
                    </h4>
                    <button className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
                        <Link to={`/auth/login`} className="text-light mr-2" id="kt_login_forgot">
                            {intl.formatMessage({ id: "AUTH.LOGIN.BACK_TO_LOGIN" })}
                        </Link>
                    </button>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
