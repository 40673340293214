import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { getUserProfiles, login, register, logout, getStaffInvitation, acceptStaffInvitation } from "./authCrud";
import { actionTypes as bookingManagerActionTypes } from "../bookings/bookingManagerRedux";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR",
    USER_COMPLETE_AUTH_DATA: "USER_COMPLETE_AUTH_DATA",
    CHANGE_PROFILE: "CHANGE_PROFILE",
    GET_USER_USER_PROFILES_REQUEST: "GET_USER_USER_PROFILES_REQUEST",
    GET_USER_USER_PROFILES_SUCCESS: "GET_USER_USER_PROFILES_SUCCESS",
    USER_USER_PROFILES_SEARCH_REQUEST: "USER_USER_PROFILES_SEARCH_REQUEST",
    USER_USER_PROFILES_SEARCH_SUCCESS: "USER_USER_PROFILES_SEARCH_SUCCESS",
    GET_STAFF_INVITATION_REQUEST: "GET_STAFF_INVITATION_REQUEST",
    GET_STAFF_INVITATION_SUCCESS: "GET_STAFF_INVITATION_SUCCESS",
    ACCEPT_STAFF_INVITATION_REQUEST: "ACCEPT_STAFF_INVITATION_REQUEST",
    ACCEPT_STAFF_INVITATION_SUCCESS: "ACCEPT_STAFF_INVITATION_SUCCESS",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    ERROR_AUTH: "ERROR_AUTH",
};

const initialAuthState = {
    user: undefined,
    token: undefined,
    profile: undefined,
    profiles: [],
    profilesSearch: [],
};

export const reducer = persistReducer(
    {
        storage,
        key: "admin-auth",
        whitelist: ["user", "token", "profile"],
    },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.LOGIN_REQUEST: {
                return {
                    ...state,
                    isLoading: true,
                    isSubmitting: true,
                };
            }

            case actionTypes.LOGIN_SUCCESS: {
                return {
                    ...getUserStorageObject(action.payload.user),
                    isLoading: false,
                    isSubmitting: false,
                };
            }

            case actionTypes.LOGIN_ERROR: {
                return {
                    ...state,
                    isLoading: false,
                    isSubmitting: false,
                    error: action.payload,
                };
            }

            case actionTypes.REGISTER_REQUEST: {
                return {
                    ...state,
                    isLoading: true,
                    isSubmitting: true,
                };
            }

            case actionTypes.REGISTER_SUCCESS: {
                return {
                    ...state,
                    ...getUserStorageObject(action.payload.user),
                    isLoading: false,
                    isSubmitting: false,
                };
            }

            case actionTypes.REGISTER_ERROR: {
                return {
                    ...state,
                    isLoading: false,
                    isSubmitting: false,
                    error: action.payload,
                };
            }

            case actionTypes.USER_COMPLETE_AUTH_DATA: {
                const { profile, profiles } = action.payload;
                return {
                    ...state,
                    profile: !profile
                        ? {}
                        : {
                              id: profile.id,
                              name: profile.name,
                              permissionId: profile.myPermission ? profile.myPermission.id : profile.permissionId,
                              avatarUrl: profile.avatarUrl,
                          },
                    profiles,
                };
            }

            case actionTypes.CHANGE_PROFILE: {
                const { profile } = action.payload;
                return {
                    ...state,
                    profile: !profile
                        ? {}
                        : {
                              id: profile.id,
                              name: profile.name,
                              permissionId: profile.myPermission ? profile.myPermission.id : profile.permissionId,
                              avatarUrl: profile.avatarUrl,
                          },
                };
            }

            case actionTypes.GET_USER_USER_PROFILES_SUCCESS: {
                return {
                    ...state,
                    profiles: action.payload.profiles,
                };
            }

            case actionTypes.USER_USER_PROFILES_SEARCH_SUCCESS: {
                return {
                    ...state,
                    profilesSearch: action.payload.profiles,
                };
            }

            case actionTypes.GET_STAFF_INVITATION_REQUEST: {
                return {
                    ...state,
                    isLoading: true,
                };
            }

            case actionTypes.GET_STAFF_INVITATION_SUCCESS: {
                return {
                    ...state,
                    isLoading: false,
                    staffInvitation: action.payload.response,
                };
            }

            case actionTypes.ACCEPT_STAFF_INVITATION_REQUEST: {
                return {
                    ...state,
                    isLoading: true,
                    isSubmitting: true,
                };
            }

            case actionTypes.ACCEPT_STAFF_INVITATION_SUCCESS: {
                return {
                    ...state,
                    ...getUserStorageObject(action.payload.user),
                    isLoading: false,
                    isSubmitting: false,
                };
            }

            case errorActionTypes.REGISTER_API_ERROR: {
                return { ...state, isLoading: false, isSubmitting: false };
            }

            case actionTypes.LOGOUT_SUCCESS: {
                return initialAuthState;
            }

            default:
                return state;
        }
    }
);

function getUserStorageObject(user) {
    return {
        token: user.token,
        user: {
            id: user.id,
            firstName: user.firstName,
            surname: user.surname,
            email: user.email,
            avatarUrl: user.avatarUrl,
        },
    };
}

export const actions = {
    onLoginRequest: (loginUrl, credentials, callback) => ({
        type: actionTypes.LOGIN_REQUEST,
        payload: { loginUrl, credentials, callback },
    }),
    onLoginSuccess: (user) => ({
        type: actionTypes.LOGIN_SUCCESS,
        payload: { user },
    }),
    loginError: (error) => ({
        type: actionTypes.LOGIN_ERROR,
        payload: error,
    }),

    onRegisterRequest: (email, firstname, surname, password, timeZoneId, callback) => ({
        type: actionTypes.REGISTER_REQUEST,
        payload: { email, firstname, surname, password, timeZoneId, callback },
    }),
    onRegisterSuccess: (user) => ({
        type: actionTypes.REGISTER_SUCCESS,
        payload: { user },
    }),

    registerError: (error) => ({
        type: actionTypes.REGISTER_ERROR,
        payload: error,
    }),

    getStaffInvitation: (hash) => ({
        type: actionTypes.GET_STAFF_INVITATION_REQUEST,
        payload: { hash },
    }),

    acceptStaffInvitation: (hash, email, firstname, surname, password, timeZoneId, callback) => ({
        type: actionTypes.ACCEPT_STAFF_INVITATION_REQUEST,
        payload: { hash, email, firstname, surname, password, timeZoneId, callback },
    }),

    getUserProfiles: () => ({
        type: actionTypes.GET_USER_USER_PROFILES_REQUEST,
        payload: {},
    }),

    searchUserProfiles: (search) => ({
        type: actionTypes.USER_USER_PROFILES_SEARCH_REQUEST,
        payload: { search },
    }),

    changeUserProfile: (profile) => ({
        type: actionTypes.CHANGE_PROFILE,
        payload: { profile },
    }),

    logout: () => ({
        type: actionTypes.LOGOUT_REQUEST,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.LOGIN_REQUEST, function* ({ payload }) {
        try {
            const { data: user } = yield login(payload.loginUrl, payload.credentials);
            yield put(actions.onLoginSuccess(user));

            const { data: profiles } = yield getUserProfiles();
            const defaultProfile = profiles?.length > 0 ? profiles[0] : null;
            yield put({
                type: actionTypes.USER_COMPLETE_AUTH_DATA,
                payload: { profile: defaultProfile, profiles },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(actions.loginError(error));
        }
    });

    yield takeLatest(actionTypes.REGISTER_REQUEST, function* ({ payload }) {
        try {
            const { data: user } = yield register(payload.email, payload.firstname, payload.surname, payload.password);
            yield put(actions.onRegisterSuccess(user));

            const { data: profiles } = yield getUserProfiles();
            const defaultProfile = profiles?.length > 0 ? profiles[0] : null;
            yield put({
                type: actionTypes.USER_COMPLETE_AUTH_DATA,
                payload: { profile: defaultProfile, profiles },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(actions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_USER_USER_PROFILES_REQUEST, function* () {
        try {
            const { data: profiles } = yield getUserProfiles();
            yield put({
                type: actionTypes.GET_USER_USER_PROFILES_SUCCESS,
                payload: { profiles },
            });
        } catch (error) {
            yield put({ type: actionTypes.ERROR_AUTH, payload: error });
        }
    });

    yield takeLatest(actionTypes.USER_USER_PROFILES_SEARCH_REQUEST, function* ({ payload }) {
        try {
            const state = yield select();
            let profiles = [...state.auth.profiles];
            const searchString = payload.search.toLowerCase();
            const filteredProfiles = profiles.filter((x) => x.name.toLowerCase().indexOf(searchString) > -1);
            yield put({
                type: actionTypes.USER_USER_PROFILES_SEARCH_SUCCESS,
                payload: { profiles: filteredProfiles },
            });
        } catch (error) {
            yield put({ type: actionTypes.ERROR_AUTH, payload: error });
        }
    });

    yield takeLatest(actionTypes.CHANGE_PROFILE, function* () {
        try {
            // Clear caches
            yield put({
                type: bookingManagerActionTypes.CLEAR_BOOKINGMANAGER_STATE,
                payload: { clearNewBookingCache: true },
            });
        } catch (error) {
            yield put({ type: actionTypes.ERROR_AUTH, payload: error });
        }
    });

    yield takeLatest(actionTypes.GET_STAFF_INVITATION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getStaffInvitation(payload.hash);

            yield put({
                type: actionTypes.GET_STAFF_INVITATION_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put({ type: actionTypes.ERROR_AUTH, payload: error });
        }
    });

    yield takeLatest(actionTypes.ACCEPT_STAFF_INVITATION_REQUEST, function* ({ payload }) {
        try {
            const { data: user } = yield acceptStaffInvitation(
                payload.hash,
                payload.email,
                payload.firstname,
                payload.surname,
                payload.password
            );

            yield put({
                type: actionTypes.ACCEPT_STAFF_INVITATION_SUCCESS,
                payload: { user },
            });

            const { data: profiles } = yield getUserProfiles();
            const defaultProfile = profiles?.length > 0 ? profiles[0] : null;
            yield put({
                type: actionTypes.USER_COMPLETE_AUTH_DATA,
                payload: { profile: defaultProfile, profiles },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.LOGOUT_REQUEST, function* () {
        try {
            yield logout();
            yield put({
                type: actionTypes.LOGOUT_SUCCESS,
            });
        } catch (error) {
            // Should not really happen but also clear user on error
            yield put({
                type: actionTypes.LOGOUT_SUCCESS,
            });
        }
    });
}
