import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import BootstrapCancelButton from "../../../components/buttons/BootstrapCancelButton";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    formContent: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const schema = yup.object().shape({
    serviceId: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    prefixMinutes: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    postfixMinutes: yup
        .number()
        .typeError(<FormattedMessage id="FORM.ERROR.NUMBER_TYPE" />)
        .nullable()
        .transform((value, originalValue) => (originalValue.trim() === "" ? null : value)),
    messageToCustomer: yup.string().max(160, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 160 }} />),
});

function DormakabaComponentServiceModal(props) {
    const { isLoading, managingComponent, managingComponentService } = useSelector((state) => state.dormakaba);
    const classes = useStyles();

    let defaultValues;
    if (managingComponentService) {
        defaultValues = {
            serviceId: managingComponentService.service.id,
            prefixMinutes: managingComponentService.prefixSeconds ? managingComponentService.prefixSeconds / 60 : null,
            postfixMinutes: managingComponentService.postfixSeconds
                ? managingComponentService.postfixSeconds / 60
                : null,
            messageToCustomer: managingComponentService.messageToCustomer,
        };
    }

    const { register, handleSubmit, errors } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    if (!managingComponent) return <></>;

    const onFormSubmit = (values) => {
        props.onSaveClicked(
            managingComponent,
            managingComponentService,
            parseInt(values.serviceId, 10),
            values.prefixMinutes ? parseInt(values.prefixMinutes, 10) : null,
            values.postfixMinutes ? parseInt(values.postfixMinutes, 10) : null,
            values.messageToCustomer
        );
    };

    const { services } = props;

    return (
        <Form>
            <Modal size="lg" show={props.show} onHide={props.onCloseClicked}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {!managingComponentService && (
                            <FormattedMessage
                                id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.ADD"}
                                values={{ componentName: managingComponent.name }}
                            />
                        )}
                        {managingComponentService && (
                            <FormattedMessage
                                id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.EDIT"}
                                values={{ serviceName: managingComponentService.service.name }}
                            />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.DESCRIPTION"} />
                    <div className={classes.formContent}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.SERVICE"} />
                            </Form.Label>
                            <Form.Control as="select" ref={register} name="serviceId">
                                {services &&
                                    services.map((x) => {
                                        return (
                                            <option key={x.id} value={x.id}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage
                                    id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.PREFIX_MINUTES"}
                                />
                            </Form.Label>
                            <Form.Control
                                type="number"
                                ref={register}
                                name="prefixMinutes"
                                isInvalid={errors.prefixMinutes}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.prefixMinutes?.message}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage
                                    id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.PREFIX_MINUTES_DESCRIPTION"}
                                />
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <FormattedMessage id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.POSTFIX_MINUTES"} />
                            <Form.Control
                                type="number"
                                ref={register}
                                name="postfixMinutes"
                                isInvalid={errors.postfixMinutes}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.postfixMinutes?.message}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage
                                    id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.POSTFIX_MINUTES_DESCRIPTION"}
                                />
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <FormattedMessage
                                id={"INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.MESSAGE_TO_CUSTOMER"}
                            />
                            <Form.Control
                                type="text"
                                ref={register}
                                name="messageToCustomer"
                                isInvalid={errors.messageToCustomer}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.messageToCustomer?.message}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                <FormattedMessage
                                    id="INTEGRATION.DORMAKABA.DETAILS.COMPONENT.SERVICE.MESSAGE_TO_CUSTOMER_DESCRIPTION"
                                    values={{
                                        p: (...chunks) => <p>{chunks}</p>,
                                        strong: (...chunks) => <strong>{chunks}</strong>,
                                        br: <br />,
                                    }}
                                />
                            </Form.Text>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapCancelButton onClick={props.onCloseClicked} />
                    <BootstrapSaveButton isLoading={isLoading} onClick={handleSubmit(onFormSubmit)} />
                </Modal.Footer>
            </Modal>
        </Form>
    );
}

export default DormakabaComponentServiceModal;
