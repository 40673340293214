import React, { useMemo, useCallback } from "react";
import { actions as promotionCodesAction } from "../../../redux/promotioncodes/promotionCodesRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell, LinkCell, AvatarCell } from "../../components/tables/MaterialTableCells";
import * as transactionUtils from "../transactions/utils";
import { Link } from "react-router-dom";

function PromotionCodePageTransactions({ getPromotionCodeTransactions }) {
    const { promotionCode, promotionCodeTransactionsPagination, isLoading } = useSelector(
        (state) => state.promotionCodes
    );
    const { displayError } = useSelector((state) => state.errors);
    const promotionCodeId = promotionCode?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (promotionCodeId) {
                getPromotionCodeTransactions(promotionCodeId, pageIndex + 1, pageSize);
            }
        },
        [promotionCodeId, getPromotionCodeTransactions]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PROMOTIONCODE.TRANSACTIONS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PROMOTIONCODE.TRANSACTIONS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={promotionCodeId}
                        data={useMemo(() => promotionCodeTransactionsPagination.data, [
                            promotionCodeTransactionsPagination.data,
                        ])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={promotionCodeTransactionsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/transactions/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        return transactionUtils.getTransactionListStatusCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_TYPE" />,
                                    accessor: "paymentType",
                                    Cell: (props) => {
                                        return transactionUtils.getTransactionListPaymentTypeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.SERIAL_NUMBER" />,
                                    accessor: "serialNumber",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.CUSTOMER" />,
                                    accessor: "customer",
                                    Cell: (props) => {
                                        return (
                                            <Link to={`/customers/${props.value.id}`}>
                                                {AvatarCell(
                                                    props.value.avatarUrl,
                                                    props.value.firstName + " " + props.value.surname
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.AMOUNT" />,
                                    accessor: "amount",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.REFUNDED_AMOUNT" />,
                                    accessor: "refundedAmount",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.TRANSACTION_DAY" />,
                                    accessor: "paidAt",
                                    Cell: ({ row }) => {
                                        return DateTimeCell(row.original.paidAt || row.original.createdAt);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.LIST.HEADER.PAYMENT_STATUS" />,
                                    accessor: "paymentStatus",
                                    Cell: ({ row }) => {
                                        return transactionUtils.getTransactionStatusCell(
                                            row.original.paymentStatus,
                                            row.original.status
                                        );
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...promotionCodesAction, ...errorActions })(PromotionCodePageTransactions);
