import React from "react";
import { FormattedMessage } from "react-intl";
import BootstrapSaveButton from "../../../../components/buttons/BootstrapSaveButton";

function SortServiceSaveButton({ onSaveClicked, isSaveLoading }) {
    return (
        <BootstrapSaveButton isLoading={isSaveLoading} onClick={() => onSaveClicked()}>
            <FormattedMessage id="COMMON.SAVE" />
        </BootstrapSaveButton>
    );
}

export default SortServiceSaveButton;
