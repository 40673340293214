import { put, takeLatest } from "redux-saga/effects";
import {
    getFortnoxIntegrationAuth,
    createFortnoxIntegration,
    patchFortnoxIntegration,
    getFortnoxServices,
    addFortnoxService,
    patchFortnoxService,
    deleteFortnoxService,
    getFortnoxArticles,
    getFortnoxAccounts,
    getFortnoxCostCenters,
} from "./fortnoxCrud";
import { getIntegration } from "../integrationCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../../errors/errorsRedux";
import { actionTypes as integrationActionTypes } from "../integrationRedux";
import { findAndSpliceArrayByProperty } from "../../reduxUtils";

export const actionTypes = {
    GET_INTEGRATION_FORTNOX_REQUEST: "GET_INTEGRATION_FORTNOX_REQUEST",
    GET_INTEGRATION_FORTNOX_SUCCESS: "GET_INTEGRATION_FORTNOX_SUCCESS",
    GET_INTEGRATION_FORTNOX_AUTH_REQUEST: "GET_INTEGRATION_FORTNOX_AUTH_REQUEST",
    GET_INTEGRATION_FORTNOX_AUTH_SUCCESS: "GET_INTEGRATION_FORTNOX_AUTH_SUCCESS",
    CREATE_INTEGRATION_FORTNOX_REQUEST: "CREATE_INTEGRATION_FORTNOX_REQUEST",
    CREATE_INTEGRATION_FORTNOX_SUCCESS: "CREATE_INTEGRATION_FORTNOX_SUCCESS",
    UPDATE_INTEGRATION_FORTNOX_REQUEST: "UPDATE_INTEGRATION_FORTNOX_REQUEST",
    UPDATE_INTEGRATION_FORTNOX_SUCCESS: "UPDATE_INTEGRATION_FORTNOX_SUCCESS",
    GET_FORTNOX_SERVICES_REQUEST: "GET_FORTNOX_SERVICES_REQUEST",
    GET_FORTNOX_SERVICES_SUCCESS: "GET_FORTNOX_SERVICES_SUCCESS",
    ADD_FORTNOX_SERVICE_REQUEST: "ADD_FORTNOX_SERVICE_REQUEST",
    ADD_FORTNOX_SERVICE_SUCCESS: "ADD_FORTNOX_SERVICE_SUCCESS",
    UPDATE_FORTNOX_SERVICE_REQUEST: "UPDATE_FORTNOX_SERVICE_REQUEST",
    UPDATE_FORTNOX_SERVICE_SUCCESS: "UPDATE_FORTNOX_SERVICE_SUCCESS",
    DELETE_FORTNOX_SERVICE_REQUEST: "DELETE_FORTNOX_SERVICE_REQUEST",
    DELETE_FORTNOX_SERVICE_SUCCESS: "DELETE_FORTNOX_SERVICE_SUCCESS",
    GET_FORTNOX_ARTICLES_REQUEST: "GET_FORTNOX_ARTICLES_REQUEST",
    GET_FORTNOX_ARTICLES_SUCCESS: "GET_FORTNOX_ARTICLES_SUCCESS",
    GET_FORTNOX_ACCOUNTS_REQUEST: "GET_FORTNOX_ACCOUNTS_REQUEST",
    GET_FORTNOX_ACCOUNTS_SUCCESS: "GET_FORTNOX_ACCOUNTS_SUCCESS",
    GET_FORTNOX_COST_CENTERS_REQUEST: "GET_FORTNOX_COST_CENTERS_REQUEST",
    GET_FORTNOX_COST_CENTERS_SUCCESS: "GET_FORTNOX_COST_CENTERS_SUCCESS",
    CLEAR_FORTNOX_STATE: "CLEAR_FORTNOX_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    integration: null,
    fortnoxServicesPagination: {
        data: [],
        totalRows: 0,
    },
    isFortnoxArticlesLoading: false,
    fortnoxArticlesPagination: {
        data: [],
        totalRows: 0,
    },
    isFortnoxAccountsLoading: false,
    fortnoxAccountsPagination: {
        data: [],
        totalRows: 0,
    },
    isFortnoxCostCentersLoading: false,
    fortnoxCostCentersPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTEGRATION_FORTNOX_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_FORTNOX_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.GET_INTEGRATION_FORTNOX_AUTH_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_INTEGRATION_FORTNOX_AUTH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                authRedirectUrl: action.payload.response.redirectUrl,
            };
        }

        case actionTypes.CREATE_INTEGRATION_FORTNOX_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.CREATE_INTEGRATION_FORTNOX_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
                redirectToDetails: true,
            };
        }

        case actionTypes.UPDATE_INTEGRATION_FORTNOX_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_INTEGRATION_FORTNOX_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                integration: action.payload.response,
            };
        }

        case integrationActionTypes.DELETE_INTEGRATION_REQUEST: {
            return { ...state, isLoading: true };
        }

        case integrationActionTypes.DELETE_INTEGRATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                integration: action.payload.response,
            };
        }

        case actionTypes.GET_FORTNOX_SERVICES_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_FORTNOX_SERVICES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                fortnoxServicesPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_FORTNOX_SERVICE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_FORTNOX_SERVICE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.UPDATE_FORTNOX_SERVICE_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.UPDATE_FORTNOX_SERVICE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            };
        }

        case actionTypes.DELETE_FORTNOX_SERVICE_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.DELETE_FORTNOX_SERVICE_SUCCESS: {
            const alteredStateArray = findAndSpliceArrayByProperty(
                state.fortnoxServicesPagination.data,
                "id",
                action.payload.response.id
            );

            return {
                ...state,
                isLoading: false,
                fortnoxServicesPagination: {
                    ...state.fortnoxServicesPagination,
                    data: alteredStateArray,
                    totalRows: state.fortnoxServicesPagination.totalRows - 1,
                },
            };
        }

        case actionTypes.GET_FORTNOX_ARTICLES_REQUEST: {
            return { ...state, isFortnoxArticlesLoading: true };
        }

        case actionTypes.GET_FORTNOX_ARTICLES_SUCCESS: {
            return {
                ...state,
                isFortnoxArticlesLoading: false,
                fortnoxArticlesPagination: action.payload.response,
            };
        }

        case actionTypes.GET_FORTNOX_ACCOUNTS_REQUEST: {
            return { ...state, isFortnoxAccountsLoading: true };
        }

        case actionTypes.GET_FORTNOX_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                isFortnoxAccountsLoading: false,
                fortnoxAccountsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_FORTNOX_COST_CENTERS_REQUEST: {
            return { ...state, isFortnoxCostCentersLoading: true };
        }

        case actionTypes.GET_FORTNOX_COST_CENTERS_SUCCESS: {
            return {
                ...state,
                isFortnoxCostCentersLoading: false,
                fortnoxCostCentersPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false };
        }

        case actionTypes.CLEAR_FORTNOX_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getFortnoxIntegration: (id) => ({
        type: actionTypes.GET_INTEGRATION_FORTNOX_REQUEST,
        payload: { id },
    }),

    getFortnoxIntegrationAuth: (profileId, integrationId) => ({
        type: actionTypes.GET_INTEGRATION_FORTNOX_AUTH_REQUEST,
        payload: { profileId, integrationId },
    }),

    createFortnoxIntegration: (profileId, code, integrationId) => ({
        type: actionTypes.CREATE_INTEGRATION_FORTNOX_REQUEST,
        payload: { profileId, code, integrationId },
    }),

    updateFortnoxIntegration: (id, originalIntegration, invoicesEnabled, receiptsEnabled) => ({
        type: actionTypes.UPDATE_INTEGRATION_FORTNOX_REQUEST,
        payload: { id, originalIntegration, invoicesEnabled, receiptsEnabled },
    }),

    getFortnoxServices: (id, page, perPage, search) => ({
        type: actionTypes.GET_FORTNOX_SERVICES_REQUEST,
        payload: { id, page, perPage, search },
    }),

    addFortnoxService: (id, data, callback) => ({
        type: actionTypes.ADD_FORTNOX_SERVICE_REQUEST,
        payload: { id, data, callback },
    }),

    updateFortnoxService: (id, fortnoxServiceId, originalFortnoxService, changedFortnoxService, callback) => ({
        type: actionTypes.UPDATE_FORTNOX_SERVICE_REQUEST,
        payload: { id, fortnoxServiceId, originalFortnoxService, changedFortnoxService, callback },
    }),

    deleteFortnoxService: (id, fortnoxServiceId) => ({
        type: actionTypes.DELETE_FORTNOX_SERVICE_REQUEST,
        payload: { id, fortnoxServiceId },
    }),

    getFortnoxArticles: (id, page, perPage, search) => ({
        type: actionTypes.GET_FORTNOX_ARTICLES_REQUEST,
        payload: { id, page, perPage, search },
    }),

    getFortnoxAccounts: (id, page, perPage, search) => ({
        type: actionTypes.GET_FORTNOX_ACCOUNTS_REQUEST,
        payload: { id, page, perPage, search },
    }),

    getFortnoxCostCenters: (id, page, perPage, search) => ({
        type: actionTypes.GET_FORTNOX_COST_CENTERS_REQUEST,
        payload: { id, page, perPage, search },
    }),

    clearFortnoxState: () => ({
        type: actionTypes.CLEAR_FORTNOX_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_INTEGRATION_FORTNOX_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getIntegration("fortnox", payload.id);

            yield put({
                type: actionTypes.GET_INTEGRATION_FORTNOX_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_INTEGRATION_FORTNOX_AUTH_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getFortnoxIntegrationAuth(payload.profileId, payload.integrationId);

            yield put({
                type: actionTypes.GET_INTEGRATION_FORTNOX_AUTH_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.CREATE_INTEGRATION_FORTNOX_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield createFortnoxIntegration(
                payload.profileId,
                payload.code,
                payload.integrationId
            );

            yield put({
                type: actionTypes.CREATE_INTEGRATION_FORTNOX_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_INTEGRATION_FORTNOX_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchFortnoxIntegration(payload.id, payload.originalIntegration, {
                ...payload.originalIntegration,
                invoicesEnabled: payload.invoicesEnabled,
                receiptsEnabled: payload.receiptsEnabled,
            });

            yield put({
                type: actionTypes.UPDATE_INTEGRATION_FORTNOX_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_FORTNOX_SERVICES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getFortnoxServices(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search
            );

            yield put({
                type: actionTypes.GET_FORTNOX_SERVICES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_FORTNOX_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addFortnoxService(payload.id, payload.data);

            yield put({
                type: actionTypes.ADD_FORTNOX_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.UPDATE_FORTNOX_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield patchFortnoxService(
                payload.id,
                payload.fortnoxServiceId,
                payload.originalFortnoxService,
                payload.changedFortnoxService
            );

            yield put({
                type: actionTypes.UPDATE_FORTNOX_SERVICE_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_FORTNOX_SERVICE_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteFortnoxService(payload.id, payload.fortnoxServiceId);

            yield put({
                type: actionTypes.DELETE_FORTNOX_SERVICE_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_FORTNOX_ARTICLES_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getFortnoxArticles(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search
            );
            yield put({
                type: actionTypes.GET_FORTNOX_ARTICLES_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_FORTNOX_ACCOUNTS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getFortnoxAccounts(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search
            );
            yield put({
                type: actionTypes.GET_FORTNOX_ACCOUNTS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_FORTNOX_COST_CENTERS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getFortnoxCostCenters(
                payload.id,
                payload.page,
                payload.perPage,
                payload.search
            );
            yield put({
                type: actionTypes.GET_FORTNOX_COST_CENTERS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
