import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions as resourceActions } from "../../../redux/resources/resourceRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import { useHistory } from "react-router-dom";
import SnackbarError from "../../components/SnackbarError";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    description: yup.string(),
});

function ResourceGroupPageAdd({ addResourceGroup, clearResourceState, clearDisplayError, clearErrorState }) {
    const { profile } = useSelector((state) => state.auth);
    const { resourceGroup, isUpdating } = useSelector((state) => state.resources);
    const { displayError } = useSelector((state) => state.errors);
    const [isAdded, setIsAdded] = useState(false);
    const history = useHistory();

    useEffect(() => {
        clearResourceState();
        clearErrorState();
    }, [clearResourceState, clearErrorState]);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onFormSubmit = (values) => {
        addResourceGroup(profile.id, values.name, values.description);
        setIsAdded(true);
    };

    if (isAdded && resourceGroup) {
        history.push("/resource-groups/" + resourceGroup.id);
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">
                        <FormattedMessage id="RESOURCE.GROUP.CREATE.TITLE" />
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="RESOURCE.GROUP.DETAILS.NAME" />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="form-control form-control-lg col-xl-9"
                                name="name"
                                ref={register}
                                isInvalid={errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="RESOURCE.GROUP.DETAILS.DESCRIPTION" />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className="form-control form-control-lg col-xl-9"
                                name="description"
                                ref={register}
                                isInvalid={errors.description}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <BootstrapSaveButton
                            isLoading={isUpdating}
                            x
                            onClick={handleSubmit(onFormSubmit)}
                            label={<FormattedMessage id="COMMON.ADD" />}
                        ></BootstrapSaveButton>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default connect(null, { ...resourceActions, ...errorActions })(ResourceGroupPageAdd);
