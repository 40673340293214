import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { actions as campaignActions } from "../../../../redux/campaigns/campaignRedux";
import { actions as errorActions } from "../../../../redux/errors/errorsRedux";
import { Form, Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapSaveButton from "../../../components/buttons/BootstrapSaveButton";
import SnackbarError from "../../../components/SnackbarError";
import CampaignSectionHeader from "../CampaignSectionHeader";
import CampaignSMSCommonDetailsForm from "./CampaignSMSCommonDetailsForm";
import CampaignRecipientFilterModal from "../CampaignRecipientFilterModal";
import MaterialReactTable from "../../../components/tables/MaterialReactTable";
import { TextCell, AvatarCell } from "../../../components/tables/MaterialTableCells";
import ListActionsButton from "../../../components/buttons/ListActionsButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import * as campaignUtils from "../utils";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const schema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
    title: yup
        .string()
        .required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />)
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(11, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 11 }} />)
        .matches(/^[A-Za-z0-9]+$/, { message: <FormattedMessage id="FORM.ERROR.REGEX_MISMATCH" /> }),
    message: yup.string().required(<FormattedMessage id="AUTH.VALIDATION.REQUIRED_FIELD" />),
});

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

function CampaignSMSPageAdd({
    getSmsPreviewRecipients,
    addCampaign,
    clearCampaignState,
    clearDisplayError,
    clearErrorState,
}) {
    const { profile } = useSelector((state) => state.auth);
    const { campaign, isLoading, smsPreviewRecipientsPagination, isSmsPreviewRecipientsLoading } = useSelector(
        (state) => state.campaigns
    );
    const { displayError } = useSelector((state) => state.errors);

    const [customerFilterModalMode, setCustomerFilterModalMode] = useState(null);
    const [recipientFilters, setRecipientFilters] = useState([]);
    const [redirect, setRedirect] = useState(false);

    const intl = useIntl();
    const classes = useStyles();

    const formMethods = useForm({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    useEffect(() => {
        return () => {
            clearCampaignState();
            clearErrorState();
        };
    }, [clearCampaignState, clearErrorState]);

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            getSmsPreviewRecipients(profile.id, pageIndex + 1, pageSize, recipientFilters);
        },
        [getSmsPreviewRecipients, profile.id, recipientFilters]
    );

    const onAddModalSaveClicked = (recipientFilterData) => {
        if (customerFilterModalMode === "add") {
            var updatedRecipientFilters = recipientFilters.slice();
            updatedRecipientFilters.push(recipientFilterData);
            setRecipientFilters(updatedRecipientFilters);
        }

        setCustomerFilterModalMode(null);
    };

    const onFormSubmit = (values) => {
        const data = {
            name: values.name,
            title: values.title,
            message: values.message,
        };

        recipientFilters.forEach((filter) => {
            if (!data.recipientFilters) {
                data.recipientFilters = [];
            }

            data.recipientFilters.push(filter);
        });

        addCampaign(profile.id, campaignUtils.CAMPAIGN_TYPE_SMS, data, () => {
            setRedirect(true);
        });
    };

    const recipientsFilterTableData = useMemo(() => recipientFilters, [recipientFilters]);
    const recipientsTableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.LIST.TYPE" />,
                accessor: "type",
                width: "7%",
                Cell: (props) => {
                    return TextCell(campaignUtils.getCampaignFilterNameFromType(props.value, intl));
                },
            },
            {
                Header: <FormattedMessage id="TABLE.LIST.HEADER.ACTIONS" />,
                id: "actions",
                textAlign: "center",
                Cell: ({ row }) => {
                    return (
                        <ListActionsButton
                            menuItems={[
                                {
                                    icon: <DeleteOutlinedIcon fontSize="small" />,
                                    label: <FormattedMessage id="COMMON.DELETE" />,
                                    onClick: () => {
                                        let filtersCopy = recipientFilters.slice();
                                        filtersCopy.splice(row.index, 1);
                                        setRecipientFilters(filtersCopy);
                                    },
                                },
                            ]}
                        />
                    );
                },
            },
        ],
        [intl, recipientFilters]
    );

    const previewRecipientsTableData = useMemo(() => smsPreviewRecipientsPagination.data, [
        smsPreviewRecipientsPagination.data,
    ]);

    const previewRecipientsTableColumns = useMemo(
        () => [
            {
                Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.LIST.NAME" />,
                accessor: "name",
                Cell: ({ row }) => {
                    return (
                        <Link to={`/customers/${row.original.customer.id}`}>
                            {AvatarCell(
                                row.original.customer.avatarUrl,
                                `${row.original.customer.firstName} ${row.original.customer.surname}`
                            )}
                        </Link>
                    );
                },
            },
            {
                Header: <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.LIST.PHONE" />,
                accessor: "phoneNumber",
                Cell: ({ row }) => {
                    return TextCell(row.original.customer.phoneNumber);
                },
            },
        ],
        []
    );

    if (redirect && campaign) {
        return <Redirect to={`/campaigns/${campaign.id}/sms`} />;
    }

    return (
        <div>
            <SnackbarError error={displayError} onErrorSnackBarClosed={() => clearDisplayError()}></SnackbarError>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-4">
                        <FormattedMessage id="CAMPAIGN.SMS.ADD.TITLE" />
                    </Card.Title>
                    <Card.Subtitle className="mb-0" class="body">
                        <FormattedMessage id="CAMPAIGN.SMS.ADD.DESCRIPTION" />
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body className="pt-0">
                    <FormProvider {...formMethods}>
                        <Form>
                            <CampaignSectionHeader intlId={"CAMPAIGN.DETAILS.HEADER"} />
                            <CampaignSMSCommonDetailsForm />

                            <CampaignSectionHeader
                                intlId={"CAMPAIGN.RECIPIENTS_FILTER.HEADER"}
                                onAddClicked={() => setCustomerFilterModalMode("add")}
                            />
                            <MaterialReactTable
                                key={`table-recipient-filters-${profile.id}`}
                                data={recipientsFilterTableData}
                                fetchData={() => {}}
                                rowCount={recipientFilters.length}
                                columns={recipientsTableColumns}
                                emptyMessage={<FormattedMessage id="CAMPAIGN.RECIPIENTS_FILTER.LIST.EMPTY_MESSAGE" />}
                                showFooter={false}
                                getCustomTableProps={() => ({
                                    style: { minHeight: "100px" },
                                })}
                            />

                            <CampaignSectionHeader intlId={"CAMPAIGN.RECIPIENTS_PREVIEW.HEADER"} />
                            <Alert severity="info" className={classes.alertContainer}>
                                <FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.ALERT_INFO" />
                            </Alert>
                            <MaterialReactTable
                                key={`table-preview-recipients-${profile.id}`}
                                data={previewRecipientsTableData}
                                fetchData={fetchData}
                                rowCount={smsPreviewRecipientsPagination.totalRows}
                                isLoading={isSmsPreviewRecipientsLoading}
                                columns={previewRecipientsTableColumns}
                                emptyMessage={<FormattedMessage id="CAMPAIGN.RECIPIENTS_PREVIEW.LIST.EMPTY_MESSAGE" />}
                                pageSize={10}
                            />

                            <div style={{ marginTop: "50px" }}>
                                <BootstrapSaveButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onFormSubmit)}
                                    label={<FormattedMessage id="CAMPAIGN.SAVE_AS_DRAFT" />}
                                ></BootstrapSaveButton>
                            </div>
                        </Form>
                    </FormProvider>
                </Card.Body>
            </Card>

            {customerFilterModalMode && (
                <CampaignRecipientFilterModal
                    show={customerFilterModalMode ? true : false}
                    onCloseClicked={() => setCustomerFilterModalMode(null)}
                    onSaveClicked={onAddModalSaveClicked}
                />
            )}
        </div>
    );
}

export default connect(null, {
    ...campaignActions,
    ...errorActions,
})(CampaignSMSPageAdd);
