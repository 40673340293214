import React, { useMemo, useCallback } from "react";
import { actions as customerActions } from "../../../redux/customers/customerRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { TextCell, DateTimeCell, LinkCell } from "../../components/tables/MaterialTableCells";
import { getServiceDetailsPageUrl } from "../services/utils";

function CustomerPageMemberships({ getCustomerMemberships }) {
    const { customer, customerMembershipsPagination, isLoading } = useSelector((state) => state.customers);
    const { displayError } = useSelector((state) => state.errors);
    const customerId = customer?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (customerId) {
                getCustomerMemberships(customerId, pageIndex + 1, pageSize);
            }
        },
        [customerId, getCustomerMemberships]
    );

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="CUSTOMER.MEMBERSHIPS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="CUSTOMER.MEMBERSHIPS.SUBTITLE" />
                        </span>
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={customer?.id}
                        data={useMemo(() => customerMembershipsPagination.data, [customerMembershipsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={customerMembershipsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="CUSTOMER.MEMBERSHIPS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return LinkCell("/memberships/" + props.value, props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.MEMBERSHIPS.LIST.HEADER.NAME" />,
                                    accessor: "service",
                                    Cell: (props) => {
                                        const url = getServiceDetailsPageUrl(props.value.type, props.value.id);
                                        if (!url) return TextCell(props.value.name);
                                        return LinkCell(url, props.value.name);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.MEMBERSHIPS.LIST.HEADER.CREATED_AT" />,
                                    accessor: "createdAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="CUSTOMER.MEMBERSHIPS.LIST.HEADER.VALID_TO" />,
                                    accessor: "validTo",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
            </div>
        </Card>
    );
}
export default connect(null, { ...customerActions, ...errorActions })(CustomerPageMemberships);
