export const APPOINTMENT_RESURCE_TYPE_RESOURCE = "resource";
export const APPOINTMENT_RESURCE_TYPE_GROUP = "resource_group";

export const PAYMENT_SETTINGS_AS_SERVICE = "asService";
export const PAYMENT_SETTINGS_CUSTOM = "custom";

export const RESOURCE_GROUP_SELECT_INDIVIDUAL_MODE = "selectIndividual";
export const RESOURCE_GROUP_SELECT_RANDOM_MODE = "selectRandom";
export const RESOURCE_GROUP_SELECT_COUNT_MODE = "selectCount";
export const RESOURCE_GROUP_SELECT_ALL_MODE = "selectAll";
