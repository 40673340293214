import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { actions as loginActions } from "../../../../redux/auth/authRedux";
import { ExternalLoginButtons } from "../../../components/buttons/login/ExternalLoginButtons";
import { EMAIL_LOGIN_URL } from "../../../../redux/auth/authCrud";

const schema = Yup.object().shape({
    email: Yup.string()
        .email(<FormattedMessage id="FORM.ERROR.REGEX_MISMATCH" />)
        .min(3, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 3 }} />)
        .max(50, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 50 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
    password: Yup.string()
        .min(8, <FormattedMessage id="FORM.ERROR.MIN_LENGTH" values={{ length: 8 }} />)
        .max(60, <FormattedMessage id="FORM.ERROR.MAX_LENGTH" values={{ length: 60 }} />)
        .required(<FormattedMessage id="FORM.ERROR.REQUIRED_FIELD" />),
});

function Login({ intl, isLoading, isSubmitting, error, onLoginRequest, onLoginSuccessful }) {
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: schema,
        onSubmit: ({ email, password }) => {
            onLoginRequest(EMAIL_LOGIN_URL, { email, password }, () => {
                onLoginSuccessful();
            });
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
            </div>
            {/* end::Head */}

            <ExternalLoginButtons onLoginSuccessful={onLoginSuccessful} />

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                {error && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {intl.formatMessage({
                                id: "AUTH.ERROR.INVALID_LOGIN",
                            })}
                        </div>
                    </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link
                        to={`/auth/forgot-password/${formik.values.email}`}
                        className="text-dark-50 text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                    >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>
                            <FormattedMessage id="AUTH.BUTTON.SIGN_IN" />
                        </span>
                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect((state) => state.auth, loginActions)(Login));
