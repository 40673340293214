import React, { useMemo, useCallback, useState } from "react";
import { actions as transactionActions } from "../../../redux/transactions/transactionRedux";
import { actions as errorActions } from "../../../redux/errors/errorsRedux";
import { connect, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialReactTable from "../../components/tables/MaterialReactTable";
import { DateTimeCell, TextCell } from "../../components/tables/MaterialTableCells";
import BootstrapSaveButton from "../../components/buttons/BootstrapSaveButton";
import TransactionAddRefundModal from "./TransactionAddRefundModal";
import * as transactionUtils from "./utils";

function TransactionPageRefunds({ getTransactionRefunds }) {
    const { transaction, transactionRefundsPagination, isLoading, isUpdating } = useSelector(
        (state) => state.transactions
    );
    const { displayError } = useSelector((state) => state.errors);
    const [showRefundModal, setShowRefundModal] = useState();
    const transactionId = transaction?.id;

    const fetchData = useCallback(
        ({ pageIndex, pageSize }) => {
            if (transactionId) {
                getTransactionRefunds(transactionId, pageIndex + 1, pageSize);
            }
        },
        [transactionId, getTransactionRefunds]
    );

    let existingRefundsAmount = 0;
    if (transactionRefundsPagination?.data?.length > 0) {
        const successfulRefunds = transactionRefundsPagination.data.filter(
            (x) => x.status !== transactionUtils.REFUND_STATUS_FAILED
        );
        existingRefundsAmount = successfulRefunds.reduce((n, { amount }) => n + amount, 0);
    }
    const maxRefundAmount = (transaction?.amount || 0) - existingRefundsAmount;

    return (
        <Card>
            <div className="card card-custom">
                <Card.Header className="card-header py-4 card-custom">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            <FormattedMessage id="PAYMENT.REFUNDS.TITLE" />
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            <FormattedMessage id="PAYMENT.REFUNDS.SUBTITLE" />
                        </span>
                    </div>
                    <div className="card-toolbar">
                        {maxRefundAmount > 0 && transactionRefundsPagination?.page > 0 && (
                            <BootstrapSaveButton
                                isLoading={isUpdating}
                                onClick={() => setShowRefundModal(true)}
                                label={<FormattedMessage id="PAYMENT.REFUNDS.NEW" />}
                            />
                        )}
                    </div>
                </Card.Header>
                <Card.Body style={{ padding: "0px" }}>
                    <MaterialReactTable
                        key={transactionId}
                        data={useMemo(() => transactionRefundsPagination.data, [transactionRefundsPagination.data])}
                        fetchData={fetchData}
                        pageSize={10}
                        rowCount={transactionRefundsPagination.totalRows}
                        isLoading={isLoading}
                        error={displayError}
                        columns={useMemo(
                            () => [
                                {
                                    Header: <FormattedMessage id="PAYMENT.REFUNDS.LIST.HEADER.ID" />,
                                    accessor: "id",
                                    width: "7%",
                                    Cell: (props) => {
                                        return TextCell(props.value);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.REFUNDS.LIST.HEADER.AMOUNT" />,
                                    accessor: "amount",
                                    Cell: (props) => {
                                        return TextCell(props.value + " " + props.row.original.currency);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.REFUNDS.LIST.HEADER.PROCESSED_AT" />,
                                    accessor: "processedAt",
                                    Cell: (props) => {
                                        return DateTimeCell(props.value || props.row.original.createdAt);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.REFUNDS.LIST.HEADER.CREATED_BY" />,
                                    accessor: "createdBy",
                                    Cell: (props) => {
                                        if (!props.value) return TextCell();
                                        return TextCell(`${props.value.firstName} ${props.value.surname}`);
                                    },
                                },
                                {
                                    Header: <FormattedMessage id="PAYMENT.REFUNDS.LIST.HEADER.STATUS" />,
                                    accessor: "status",
                                    Cell: (props) => {
                                        return transactionUtils.getTransactionRefundStatusCell(props.value);
                                    },
                                },
                            ],
                            []
                        )}
                        getCustomCellProps={() => ({
                            style: { height: "64px" },
                        })}
                    />
                </Card.Body>
                <TransactionAddRefundModal
                    transactionId={transactionId}
                    maxRefundAmount={maxRefundAmount}
                    show={showRefundModal}
                    onCloseClicked={() => setShowRefundModal(false)}
                    onRefundCreated={() => setShowRefundModal(false)}
                />
            </div>
        </Card>
    );
}
export default connect(null, { ...transactionActions, ...errorActions })(TransactionPageRefunds);
