import { put, takeLatest } from "redux-saga/effects";
import {
    getTransactions,
    getTransaction,
    deleteTransaction,
    downloadTransactionPdf,
    downloadTransactionDocumentVersionPdf,
    getTransactionBookings,
    getTransactionRefunds,
    addTransactionRefund,
    getTransactionDocumentVersions,
    getRefunds,
} from "./transactionCrud";
import { actions as errorActions, actionTypes as errorActionTypes } from "../errors/errorsRedux";

export const actionTypes = {
    GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
    GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
    GET_TRANSACTION_REQUEST: "GET_TRANSACTION_REQUEST",
    GET_TRANSACTION_SUCCESS: "GET_TRANSACTION_SUCCESS",
    GET_TRANSACTION_BOOKINGS_REQUEST: "GET_TRANSACTION_BOOKINGS_REQUEST",
    GET_TRANSACTION_BOOKINGS_SUCCESS: "GET_TRANSACTION_BOOKINGS_SUCCESS",
    GET_TRANSACTION_REFUNDS_REQUEST: "GET_TRANSACTION_REFUNDS_REQUEST",
    GET_TRANSACTION_REFUNDS_SUCCESS: "GET_TRANSACTION_REFUNDS_SUCCESS",
    GET_TRANSACTION_DOCUMENT_VERSIONS_REQUEST: "GET_TRANSACTION_DOCUMENT_VERSIONS_REQUEST",
    GET_TRANSACTION_DOCUMENT_VERSIONS_SUCCESS: "GET_TRANSACTION_DOCUMENT_VERSIONS_SUCCESS",
    ADD_TRANSACTION_REFUND_REQUEST: "ADD_TRANSACTION_REFUND_REQUEST",
    ADD_TRANSACTION_REFUND_SUCCESS: "ADD_TRANSACTION_REFUND_SUCCESS",
    DELETE_TRANSACTION_REQUEST: "DELETE_TRANSACTION_REQUEST",
    DELETE_TRANSACTION_SUCCESS: "DELETE_TRANSACTION_SUCCESS",
    DOWNLOAD_TRANSACTION_PDF_REQUEST: "DOWNLOAD_TRANSACTION_PDF_REQUEST",
    DOWNLOAD_TRANSACTION_PDF_SUCCESS: "DOWNLOAD_TRANSACTION_PDF_SUCCESS",
    DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_REQUEST: "DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_REQUEST",
    DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_SUCCESS: "DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_SUCCESS",
    GET_REFUNDS_REQUEST: "GET_REFUNDS_REQUEST",
    GET_REFUNDS_SUCCESS: "GET_REFUNDS_SUCCESS",
    CLEAR_TRANSACTIONS_STATE: "CLEAR_TRANSACTIONS_STATE",
};

const initialState = {
    isLoading: false,
    isUpdating: false,
    isDownloadingPdf: false,
    listPagination: {
        data: [],
        totalRows: 0,
    },
    transactionBookingsPagination: {
        data: [],
        totalRows: 0,
    },
    transactionRefundsPagination: {
        data: [],
        totalRows: 0,
    },
    transactionDocumentVersionsPagination: {
        data: [],
        totalRows: 0,
    },
    refundsListPagination: {
        data: [],
        totalRows: 0,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRANSACTIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                listPagination: action.payload.response,
            };
        }

        case actionTypes.GET_TRANSACTION_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_TRANSACTION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                transaction: action.payload.response,
            };
        }

        case actionTypes.GET_TRANSACTION_BOOKINGS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_TRANSACTION_BOOKINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                transactionBookingsPagination: action.payload.response,
            };
        }

        case actionTypes.GET_TRANSACTION_REFUNDS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_TRANSACTION_REFUNDS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                transactionRefundsPagination: action.payload.response,
            };
        }

        case actionTypes.ADD_TRANSACTION_REFUND_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.ADD_TRANSACTION_REFUND_SUCCESS: {
            const refund = action.payload.response;
            const refundArrayCopy = [...state.transactionRefundsPagination.data];
            refundArrayCopy.unshift(refund);
            return {
                ...state,
                isUpdating: false,
                transactionRefundsPagination: {
                    ...state.transactionRefundsPagination,
                    data: refundArrayCopy,
                },
                transaction: !state.transaction
                    ? state.transaction
                    : {
                          ...state.transaction,
                          refundedAmount: (state.transaction.refundedAmount || 0) + refund.amount,
                      },
            };
        }

        case actionTypes.GET_TRANSACTION_DOCUMENT_VERSIONS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_TRANSACTION_DOCUMENT_VERSIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                transactionDocumentVersionsPagination: action.payload.response,
            };
        }

        case actionTypes.DELETE_TRANSACTION_REQUEST: {
            return { ...state, isUpdating: true };
        }

        case actionTypes.DELETE_TRANSACTION_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
                transaction: action.payload.response,
            };
        }

        case actionTypes.DOWNLOAD_TRANSACTION_PDF_REQUEST: {
            return { ...state, isDownloadingPdf: true };
        }

        case actionTypes.DOWNLOAD_TRANSACTION_PDF_SUCCESS: {
            return { ...state, isDownloadingPdf: false };
        }

        case actionTypes.DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_REQUEST: {
            return { ...state, isDownloadingPdf: true };
        }

        case actionTypes.DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_SUCCESS: {
            return { ...state, isDownloadingPdf: false };
        }

        case actionTypes.GET_REFUNDS_REQUEST: {
            return { ...state, isLoading: true };
        }

        case actionTypes.GET_REFUNDS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refundsListPagination: action.payload.response,
            };
        }

        case errorActionTypes.REGISTER_API_ERROR: {
            return { ...state, isLoading: false, isUpdating: false, isDownloadingPdf: false };
        }

        case actionTypes.CLEAR_TRANSACTIONS_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};

export const actions = {
    getTransactions: (
        profileId,
        page,
        perPage,
        search,
        status,
        paymentType,
        paymentStatus,
        promotionCodeFilter,
        startTime,
        endTime
    ) => ({
        type: actionTypes.GET_TRANSACTIONS_REQUEST,
        payload: {
            profileId,
            page,
            perPage,
            search,
            status,
            paymentType,
            paymentStatus,
            promotionCodeFilter,
            startTime,
            endTime,
        },
    }),

    getTransaction: (id) => ({
        type: actionTypes.GET_TRANSACTION_REQUEST,
        payload: { id },
    }),

    getTransactionBookings: (id, page, perPage) => ({
        type: actionTypes.GET_TRANSACTION_BOOKINGS_REQUEST,
        payload: { id, page, perPage },
    }),

    getTransactionRefunds: (id, page, perPage) => ({
        type: actionTypes.GET_TRANSACTION_REFUNDS_REQUEST,
        payload: { id, page, perPage },
    }),

    addTransactionRefund: (id, amount, reason, callback) => ({
        type: actionTypes.ADD_TRANSACTION_REFUND_REQUEST,
        payload: { id, amount, reason, callback },
    }),

    getTransactionDocumentVersions: (id, page, perPage) => ({
        type: actionTypes.GET_TRANSACTION_DOCUMENT_VERSIONS_REQUEST,
        payload: { id, page, perPage },
    }),

    deleteTransaction: (id) => ({
        type: actionTypes.DELETE_TRANSACTION_REQUEST,
        payload: { id },
    }),

    downloadTransactionPdf: (id, fileName) => ({
        type: actionTypes.DOWNLOAD_TRANSACTION_PDF_REQUEST,
        payload: { id, fileName },
    }),

    downloadTransactionDocumentVersionPdf: (id, fileName) => ({
        type: actionTypes.DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_REQUEST,
        payload: { id, fileName },
    }),

    getRefunds: (profileId, page, perPage, search, startTime, endTime) => ({
        type: actionTypes.GET_REFUNDS_REQUEST,
        payload: {
            profileId,
            page,
            perPage,
            search,
            startTime,
            endTime,
        },
    }),

    clearTransactionState: () => ({
        type: actionTypes.CLEAR_TRANSACTIONS_STATE,
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.GET_TRANSACTIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getTransactions(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.status,
                payload.paymentType,
                payload.paymentStatus,
                payload.promotionCodeFilter,
                payload.startTime,
                payload.endTime
            );

            yield put({
                type: actionTypes.GET_TRANSACTIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_TRANSACTION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getTransaction(payload.id);

            yield put({
                type: actionTypes.GET_TRANSACTION_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_TRANSACTION_BOOKINGS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getTransactionBookings(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_TRANSACTION_BOOKINGS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_TRANSACTION_REFUNDS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getTransactionRefunds(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_TRANSACTION_REFUNDS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.ADD_TRANSACTION_REFUND_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield addTransactionRefund(payload.id, payload.amount, payload.reason);

            yield put({
                type: actionTypes.ADD_TRANSACTION_REFUND_SUCCESS,
                payload: { response },
            });

            if (payload.callback) {
                payload.callback();
            }
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_TRANSACTION_DOCUMENT_VERSIONS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getTransactionDocumentVersions(payload.id, payload.page, payload.perPage);

            yield put({
                type: actionTypes.GET_TRANSACTION_DOCUMENT_VERSIONS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DELETE_TRANSACTION_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield deleteTransaction(payload.id);

            yield put({
                type: actionTypes.DELETE_TRANSACTION_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DOWNLOAD_TRANSACTION_PDF_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield downloadTransactionPdf(payload.id);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${payload.fileName}.pdf`);
            document.body.appendChild(link);
            link.click();

            yield put({ type: actionTypes.DOWNLOAD_TRANSACTION_PDF_SUCCESS });
        } catch (error) {
            if (error?.response?.data) {
                // Since response is expected in arraybuffer, convert to json manually before passing along
                var decodedApiResponseString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
                var apiErrorResponseObject = JSON.parse(decodedApiResponseString);
                error.response.data = apiErrorResponseObject;
            }

            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield downloadTransactionDocumentVersionPdf(payload.id);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${payload.fileName}.pdf`);
            document.body.appendChild(link);
            link.click();

            yield put({ type: actionTypes.DOWNLOAD_TRANSACTION_DOCUMENT_VERSION_PDF_SUCCESS });
        } catch (error) {
            if (error?.response?.data) {
                // Since response is expected in arraybuffer, convert to json manually before passing along
                var decodedApiResponseString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
                var apiErrorResponseObject = JSON.parse(decodedApiResponseString);
                error.response.data = apiErrorResponseObject;
            }

            yield put(errorActions.registerError(error));
        }
    });

    yield takeLatest(actionTypes.GET_REFUNDS_REQUEST, function* ({ payload }) {
        try {
            const { data: response } = yield getRefunds(
                payload.profileId,
                payload.page,
                payload.perPage,
                payload.search,
                payload.startTime,
                payload.endTime
            );

            yield put({
                type: actionTypes.GET_REFUNDS_SUCCESS,
                payload: { response },
            });
        } catch (error) {
            yield put(errorActions.registerError(error));
        }
    });
}
